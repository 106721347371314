/* eslint-disable max-len */

export const en = {
  'form.invalid':
    'Some required fields are missing. Please fill all required fields.',

  'update.series':
    'You are about to change a scheduled goal. Do you want to update the whole series?',

  'delete.series':
    'You are about to delete a scheduled goal. Do you want to delete the whole series?',

  'list.delete': 'You are about to delete an item. Are you sure?',

  'date.format.long': 'MM/dd/yyyy hh:mm',

  'date.format.short': 'MM/dd/yyyy',

  'date.format.monthYear': 'MM/yyyy',

  'date.format.fancyMonth': 'b/yy',

  'date.starting.day': '1',

  'Loading User Goals': 'Loading User Goals',

  'We\'re sorry, we can\'t find any goals matching your search.':
    'We\'re sorry, we can\'t find any goals matching your search.',

  'Try adjusting your category and module selection, or toggle your hide completed goals.':
    'Try adjusting your category and class selection, or toggle to hide or view your completed goals.',

  'hide complete goals': 'Hide Complete Goals',

  'Search': 'Search',

  'User': 'User',

  'Categories': 'Categories',

  'Scroll down for more content ...': 'Scroll down for more content ...',

  'Home': 'Home',

  'Communications': 'Communications/Journal',

  'Reports': 'Reports',

  'Exit Participant View': 'Exit Participant View',

  'Add': 'Add',

  'Show course info': 'Show class info',

  'Activity Log': 'Activity Log',

  'Instructors': 'Instructors',

  'Modules': 'Classes',

  'Participants': 'Participants',

  'Goal Manager': 'Goal Manager',

  'Lessons': 'Lessons',

  'Quizzes': 'Learning Checks',

  'Skills': 'Skills',

  'Analytics': 'Analytics',

  'Edit Profile': 'Edit Profile',

  'Email Notifications': 'Email Notifications',

  'Logout': 'Logout',

  'Dashboard': 'Dashboard',

  'Welcome': 'Welcome',

  'Journal': 'Journal',

  'Author': 'Author',

  '- Sort by -': '- Sort by -',

  'Recent Post': 'Recent Post',

  'Recent Activity': 'Recent Activity',

  '(Completed)': '(Completed)',

  'Add Journal Entry': 'Add Journal Entry',

  'Personal Network': 'Networks/Resources',

  'Certificates': 'Certificates',

  'Learning Path': 'Learning Path',

  'Completed Badges': 'Completed Achievements',

  'Badges In Progress': 'Achievements In Progress',

  'All Challenges': 'All Challenges',

  'Filters': 'Filters',

  'Overview': 'Overview',

  'Badges': 'Achievements',

  'Earned': 'Earned',

  'In Progress': 'In Progress',

  'See Available Badges': 'See Available Achievements',

  'Current Challenge': 'Current Challenge',

  'No Challenge': 'No Challenge',

  'View Tracked Resources': 'View Tracked Resources',

  'Learning Path Detail': 'Learning Path Detail',

  'Participant Activity': 'Participant Activity',

  'Print Report': 'Print Report',

  'Total Logins': 'Total Logins',

  'Total Completed Action Steps': 'Total Completed Action Steps',

  'Percent of Skills Complete in Category':
    'Percent of Skills Complete in Category',

  'Update Products': 'Update Products',

  'Username': 'Username',

  'Password': 'Password',

  'Change Campus': 'Change Campus',

  'Add Session Report': 'Add Session Report',

  'Welcome to the My Full Life': 'Welcome to The My Full Life',

  'My Full Life is designed to help bring relevant learning to the places where you use it, when you need it, by allowing you direct access to learning content and instructors.':
    'My Full Life is a person-centered approach that provides a way to identify hopes and dreams, set goals, create action plans, and identify resources to support individuals to live full lives in their communities. Life Skill Training and Tracking for more independent living is included in the online application.',

  'Getting Started Walk-Thru': 'Getting Started Walk-Thru',

  'Access Certificates and Documents': 'Access Documents and Reports',

  'Completing Course Materials & Exams': 'Completing Goals & Action Steps',

  'Do not show this dialog again ': 'Do not show this dialog again ',

  'Welcome ': 'Welcome ',

  'Edit': 'Edit',

  'Delete': 'Delete',

  'Create Goal': 'Create Goal',

  '- Is Active -': '- Is Active -',

  'Active': 'Active',

  'Not Active': 'Not Active',

  '- Category -': '- Category -',

  'Goals': 'Goals',

  'Open': 'Open',

  'Public': 'Public',

  'Keywords': 'Keywords',

  'Description ': 'Description ',

  'Assigned Goals': 'Assigned Goals',

  'Unassigned Goals': 'Unassigned Goals',

  'Challenges': 'Challenges',

  'Assistants': 'Assistants',

  '-- Goal --': '-- Goal --',

  '-- Goal State --': '-- Goal State --',

  'Complete': 'Complete',

  'Incomplete': 'Incomplete',

  'Course Communication': 'Class Communication',

  'From': 'From',

  'Reply To': 'Reply To',

  'Subject': 'Subject',

  'Print': 'Print',

  'Description': 'Description',

  'To:': 'To:',

  'Access Denied': 'Access Denied',

  'You don\'t have permission to access this resource.':
    'You don\'t have permission to access this resource.',

  'Export': 'Export',

  'Completed': 'Completed',

  '-- Modules --': '-- Classes --',

  'Name': 'Name',

  'Category': 'Category',

  'Edit Lesson': 'Edit Lesson',

  'Lesson Name': 'Lesson Name',

  'Lesson Keywords': 'Lesson Keywords',

  'Lesson Text': 'Lesson Text',

  'Needed Materials': 'Needed Materials',

  'Preparation': 'Preparation',

  'Lesson Plan': 'Lesson Plan',

  'Action Steps': 'Action Steps',

  'Details': 'Details',

  'Resources': 'Resources',

  'Add Action Step': 'Add Action Step',

  'Plan Steps': 'Lesson Plan Steps',

  'duration': 'duration',

  'Min': 'Min',

  'Activity': 'Activity',

  'Steps': 'Steps',

  'min': 'min',

  'Add Plan': 'Add Lesson Plan',

  'Next': 'Next',

  'Linked Skills': 'Linked Skills',

  'Title': 'Title',

  'Deadline': 'Deadline',

  'Collected': 'Collected',

  'Error': 'Error',

  'Some required fields are missing. Please fill all required fields.':
    'Some required fields are missing. Please fill all required fields.',

  'Time (min)': 'Time (min)',

  'Add Plan Entry': 'Add Lesson Plan Entry',

  'Products': 'Products',

  'Add Resource': 'Add Resource',

  'Resource Type': 'Resource Type',

  'File': 'File',

  'Video': 'Video',

  'YouTube Link': 'YouTube Link',

  'Quiz': 'Learning Check',

  'Link': 'Link',

  'Collage': 'Collage',

  'Choose Files': 'Choose Files',

  'INVALID_FORM': 'INVALID_FORM',

  'YouTube Url': 'YouTube Url',

  'Movie Title': 'Movie Title',

  'Choose Video File': 'Choose Video File',

  'Link Title': 'Link Title',

  'Link URL': 'Link URL',

  'Delay (in days)': 'Delay (in days)',

  'Movie': 'Movie',

  'Type': 'Type',

  'Edit Action Step': 'Edit Action Step',

  'Delay': 'Delay',

  'Due': 'Due',

  'Day': 'Day',

  'Goal Name': 'Goal Name',

  'days': 'days',

  'Schedule': 'Schedule',

  '-- Repeat Schedule --': '-- Repeat Schedule --',

  'Never': 'Never',

  'Weekly': 'Weekly',

  'Monthly': 'Monthly',

  'Approximate Time (in min)': 'Approximate Time (in min)',

  'Due Date': 'Due Date',

  'Time Critical': 'Time Critical',

  'Goal Editing Overview': 'Goal Editing Overview',

  'My Full Life is built around achieving Goals. The first step is defining the participants’ overall objective':
    'My Full Life process is built around achieving Goals. The first step is defining the participants’ overall objective',

  'Step 1:Goal Definition': 'Step 1:Goal Definition',

  'Please select image to upload': 'Please select image to upload',

  'Error accessing the webinar': 'Error accessing the webinar',

  'Please enter username and password': 'Please enter username and password',

  'Your SSO token has expired. Please use your login and password to login into the system':
    'Your SSO token has expired. Please use your login and password to login into the system',

  'Please enter your email address': 'Please enter your email address',

  'Your password was sent to your email':
    'Your password was sent to your email',

  'Can\'t find user with that email, sorry':
    'Can\'t find user with that email, sorry',

  'Please upload users CSV file': 'Please upload users CSV file',

  'Validation Error': 'Validation Error',

  'Connection to the server returned an error.':
    'Connection to the server returned an error.',

  'Invalid Login': 'Invalid Login',

  'Invalid username or password.': 'Invalid username or password.',

  'User Suspended': 'User Suspended',

  'This user was suspended.': 'This user was suspended.',

  'Edit Achievement': 'Edit Achievement',

  'Add Achievement': 'Add Achievement',

  'Edit Challenge': 'Edit Challenge',

  'Collage Library': 'Collage Library',

  'Edit Network Entry': 'Edit Network Entry',

  'Add Network Entry': 'Add Network Entry',

  'Edit Module': 'Edit Class',

  'Add Module': 'Add Class',

  'Are you sure you want to remove the instructor from the course?':
    'Are you sure you want to remove the instructor from the course?',

  'Are you sure you want to remove the invitation?':
    'Are you sure you want to remove the invitation?',

  'Are you sure you want to remove the assistant from the course?':
    'Are you sure you want to remove the assistant from the course?',

  'Are you sure you want to remove the student from the course?':
    'Are you sure you want to remove the student from the course?',

  'Registration Confirmation ': 'Registration Confirmation ',

  'Are you sure you want to register for':
    'Are you sure you want to register for',

  'Load Draft': 'Load Draft',

  'You have some draft content. Do you want to load it?':
    'You have some draft content. Do you want to load it?',

  'Add To Product': 'Add To Product',

  'Do you want to add the content to a new or existing product?\'':
    'Do you want to add the content to a new or existing product?\'',

  'Content was successfully saved.': 'Content was successfully saved.',

  'Draft was successfully saved.': 'Draft was successfully saved.',

  'Update Series': 'Update Series',

  'The My Full Life is built around achieving Goals. The first step is defining the participants’ overall objective.':
    'The My Full Life process is built around achieving Goals. The first step is defining the participants’ overall objective.',

  'Step 2: Searching Existing Lessons': 'Step 2: Searching Existing Lessons',

  'To build a Goal using pre-configured Lessons, click this button. It will bring up a search feature of all of the Lessons to which you have access.<br> <br> <i>As a reminder, each Lesson is comprised of Action Steps which have resources, such as articles or quizzes, attached. Once Lessons are added they can then be customized to meet specific Goal objectives.</i>':
    'To build a Goal using pre-configured Lessons, click this button. It will bring up a search feature of all of the Lessons to which you have access.<br> <br> <i>As a reminder, each Lesson is comprised of Action Steps which have resources, such as articles or Learning Checks, attached. Once Lessons are added they can then be customized to meet specific Goal objectives.</i>',

  'Step 3: Building Action Steps': 'Step 3: Building Action Steps',

  'Each Goal is broken down into a series of Lessons which are comprised of Action Steps for participants to complete. Most Action Steps in the My Full Life have at least one Resource attached. Resources can include videos, files, quizzes, interactive, or external content links. <br> <br>Attaching a Skill or Skills to an Action Step enables the tracking of skills towards a measureable competency or certification. You can add an Action Step here by clicking the Add button.':
    'Each Goal is broken down into a series of Lessons which are comprised of Action Steps for participants to complete. Most Action Steps in The My Full Life have at least one Resource attached. Resources can include videos, files, Learning Checks, interactive, or external content links. <br> <br>Attaching a Skill or Skills to an Action Step enables the tracking of skills towards a measureable competency or certification. You can add an Action Step here by clicking the Add button.',

  'Step 5: Setting the Schedule': 'Step 5: Setting the Schedule',

  'For Goals that need to be repeated, a schedule can be set with different increments of time (weekly, monthly, etc.). <br> <br> <i>Any skills associated with repeating lessons will be tracked in the Skills Maintenance section of the Skills Tracking tool.</i>':
    'For Goals that need to be repeated, a schedule can be set with different increments of time (weekly, monthly, etc.). <br> <br> <i>Any skills associated with repeating lessons will be tracked in the Skills Maintenance section of the Skills Tracking tool.</i>',

  'Deactivate': 'Deactivate',

  'Are you sure you want to deactivate this goal?':
    'Are you sure you want to deactivate this goal?',

  'Deactivate Series': 'Deactivate Series',

  'Do you want to deactivate the whole series?':
    'Do you want to deactivate the whole series?',

  'Activate': 'Activate',

  'Available': 'Available',

  'Availible Badges': 'Available Achievements',

  'User Profile': 'User Profile',

  'User Info': 'User Info',

  'Instructor Info': 'Instructor Info',

  'Practice Info': '',

  'Expired': 'Expired',

  'Please select a skill category.': 'Please select a skill category.',

  'Please select a badge category.': 'Please select an Achievement category.',

  'To the availible badges. Select a category on the left.':
    'To view the available Achievements, select a category on the left.',

  'Are you sure you want to activate this goal?':
    'Are you sure you want to activate this goal?',

  'Activate Series': 'Activate Series',

  'of': 'of',

  'GOALS': 'GOALS',

  'COMPLETED': 'COMPLETED',

  'Logins': 'Logins',

  'Completed Goals': 'Completed Goals',

  'Completed Action Steps': 'Completed Action Steps',

  'Do you want to activate the whole series?':
    'Do you want to activate the whole series?',

  'Edit Skill': 'Edit Skill',

  'Add Skill': 'Add Skill',

  'Add Entry': 'Add Entry',

  'Edit Entry': 'Edit Entry',

  'Add Comment': 'Add Comment',

  'Edit Comment': 'Edit Comment',

  '-- None --': '-- None --',

  'Evaluation': 'Evaluation',

  '<h4>You are starting a new evaluation.</h4><p>Completion will overwrite any of your previous scores.</p><strong> Do you wish to continue?</strong>':
    '<h4>You are starting a new Learning Check.</h4><p>Completion will overwrite any of your previous scores.</p><strong> Do you wish to continue?</strong>',

  'You are about to delete entry from the plan. Are you sure?':
    'You are about to delete entry from the plan. Are you sure?',

  'You are about to delete plan from the lesson. Are you sure?':
    'You are about to delete plan from the lesson. Are you sure?',

  'Delete Plan': 'Delete Plan',

  'Delete Plan Entry': 'Delete Plan Entry',

  'You are about to delete a lesson from the goal. Are you sure? Note: This does not remove a lesson\'s action steps.':
    'You are about to delete a lesson from the goal. Are you sure? Note: This does not remove a lesson\'s action steps.',

  'Delete Lesson': 'Delete Lesson',

  'Add Word': 'Add Word',

  'Edit Word': 'Edit Word',

  'Delete Word': 'Delete Word',

  'You are about to delete a word from the lesson. Are you sure?':
    'You are about to delete a word from the lesson. Are you sure?',

  'Edit Product': 'Edit Product',

  'Add Product': 'Add Product',

  'Are you sure you want to suspend the user?':
    'Are you sure you want to suspend the user?',

  'Suspend': 'Suspend',

  'Are you sure you want to activate the user?':
    'Are you sure you want to activate the user?',

  'Completeing mateiarls in My Full Life':
    'Completing Goals & Action Steps in My Full Life',

  '<p>This demo will walk you through how to complete your learning materials.</p><p>To simplify the process we start by hiding any non active goals. These can be toggled at any time by clicking here.':
    '<p>This demo will walk you through how to complete Goals & Action Steps.</p><p>To simplify the process, we start by hiding any non-active goals. These can be viewed or hidden at any time by clicking here.',

  'Choose your Category':
    'Choose the content you would like to see on your screen.',

  '<p>You can start by filtering the type of content you wish to work on, by selecting a category or module.</p><p> This will narrow down the resulting materials you see in the area to the right.</p>':
    '<p>You can select the content you wish to work on, by checking or unchecking a class or category.</p><p> This will narrow down what you see in the area to the right.</p>',

  'Pick a goal to work on': 'Pick a goal to work on',

  '<p>Once you have determined the module your wish to work on, select and click a goal to see its required actionsteps.</p><p>Goals are general learning objectives for a module, and the number of goals per module will vary based on the material.</p>':
    '<p>Once you have determined what you wish to work on, select and click a goal to see the required action steps.</p><p>Goals are general learning objectives. The number of Goals in a class will vary based on the participant. Some goals will be set by you and will appear as Individual Goals.</p>',

  'Understanding Action Steps': 'Understanding Action Steps',

  '<p>The icons on the right side of an action step tell you if a step is time critical, or certifies a skill.</p><p>Note that if you do not complete an action step with a clock on it by the specified time the whole goal will be marked incomplete.</p><p>You can also hit the communications icon to leave a message for your instructor related to any action step or resource.</p>':
    '<p>The clock icon on the right side of an action step tells you if a step has a time deadline. The sunburst icon tells you if you will earn a skill by completing that step.</p><p>If you do not complete an action step with a clock on it by the specified time, the whole goal will be marked incomplete.</p><p>You can click on the communications icon to leave a message for your instructor related to any action step or resource.</p>',

  'Complteting Action Steps': 'Completing Action Steps',

  'Clicking on an action step initiates a learning resource.':
    'Clicking on an action step opens a learning resource.',

  'Completeing a Resource': 'Completing a Resource',

  '<p>Click on the green button to start, submit or complete a resource.</p><p>You can always return or retake a resouce by clicking on its action step.</p><p> Complted resources are marked in the top right with the orignal date they were completed.</p>':
    '<p>Click on the green button to start, submit or complete a resource.</p><p>You can always return or retake a resource by clicking on its action step.</p><p> Completed resources are marked in the top right with the orignal date they were completed.</p>',

  'Track Goal Progress': 'Track Goal Progress',

  '<p>Progress towards completion of a goal is shown on the title bar.</p>It is not always required to complete an entire goal to get certified. Always check to make sure you have completed all of the action steps with a linked skill.</p>':
    '<p>Progress towards completion of a goal is shown on the Goal bar.</p>Always check to make sure you have completed all of the action steps in the Goal.</p>',

  'Certifications': 'Tracked Resources',

  '<p>Any certifications you have earned are located in the Certificates section</p>':
    '<p>Any online resources completed or certificates earned are located here.</p>',

  'Quiz Results': 'Learning Check Results',

  'Quiz results, Skills Surveys and other participant files are located here.':
    'Learning Check results, Skills Surveys, and other participant files are located here.',

  'Review Materials': 'Review Materials',

  '<p>You can review any previously complteted materials by clicking on them from the list.</p>':
    '<p>Review any previously completed materials by clicking on them from this list.</p>',

  'Search Files': 'Search Files',

  'To search for specific files use the search bar.':
    'To search for specific files use the search bar.',

  'Center for Independent Futures - Professional Development Systems':
    'Center for Independent Futures - Professional Development Systems',

  'Completed Skills': 'Completed Skills',

  'Skill': 'Skill',

  'Date': 'Date',

  'Goal': 'Goal',

  'Required Skills': 'Required Skills',

  'Lesson': 'Lesson',

  'Skills Assessment': 'Skills Inventory',

  'Maintenance': 'Maintenance',

  'Recommended': 'Recommended',

  'N/A': 'N/A',

  'Comment': 'Comment',

  'No Skills Category Selected</div><p>To explore available categories select from the list on the left.</p><p>If you have any questions click the support tab in the lower right.</p>':
    'No Skills Category Selected</div><p>To explore available categories, select from the list on the left.</p><p>If you have any questions click the support tab in the lower right.</p>',

  'Assessment': 'Inventory',

  'Assessment for {{ 0 }} (by {{ 1 }})': 'Inventory for {{ 0 }} (by {{ 1 }})',

  '{{0}}% of skills completed': '{{0}}% of skills completed',

  'To explore {{ 0 }}\'s Learning Path in detail. Select a skills category on the left.':
    'To explore {{ 0 }}\'s Learning Path in detail, or to complete a Skills Inventory, select a skills category on the left.',

  'Learning Path Skills Inventory': 'Learning Path Skills Inventory',

  'View Certificate': 'View Certificate',

  'Please select a skill category to start inventory.':
    'Please select a skill category to start inventory.',

  'To evaluate {{ 0 }}\'s Learning Path. Select a skills category on the left.':
    'To evaluate {{ 0 }}\'s Learning Path, select a skills category on the left.',

  'Evaluate Skills Below': 'Evaluate Skills Below',

  'Personal': 'Personal',

  'Module': 'Class',

  'days,': 'days,',

  'Credit Hours:': 'Credit Hours:',

  'hours': 'hours',

  '{{0}} of {{1}} GOALS COMPLETED ': '{{0}} of {{1}} GOALS COMPLETED ',

  'by': 'by',

  'No Skills Completed': 'No Skills Completed',

  '-- Goal Type --': '-- Goal Type --',

  'Collect number of rings until deadline':
    'Complete number of Goals until deadline',

  'Collect as many rings as possible': 'Complete as many Goals as possible',

  'Collect number of rings': 'Complete number of Goals',

  'Add Image': 'Add Image',

  'Collages': 'Collages',

  'Person': 'Person',

  'Place': 'Place',

  'Community Network': 'Community Network',

  'Phone:': 'Phone:',

  'Email:': 'Email:',

  'Address:': 'Address:',

  'Open Registration': 'Open Registration',

  'Open From': 'Open From',

  'To': 'To',

  'Course Name': 'Course Name',

  'Assocaited Badges': 'Associated Achievements',

  'Goal Objectives': 'Goal Objectives',

  'Covered Skills': 'Covered Skills',

  '- Instructor -': '- Instructor -',

  'Registration Page': 'Registration Page',

  'Assigned Goals<': 'Assigned Goals<',

  'Sending...': 'Sending...',

  'Scheduled': 'Scheduled',

  'Sent': 'Sent',

  'email': 'email',

  'Occupation': 'Occupation',

  'Available Modules': 'Available Classes',

  'Loading Modules': 'Loading Classes',

  'Instructor Bio': 'Instructor Bio',

  'My Modules': 'My Classes',

  'Enrolled Modules': 'Enrolled Classes',

  'Register': 'Register',

  'Submit Final Content': 'Submit Final Content',

  'Add Category': 'Add Category',

  'Select Product': 'Select Product',

  '- Select Product -': '- Select Product -',

  '- New Product -': '- New Product -',

  'Rating': 'Rating',

  'Entries': 'Entries',

  'Tracked Skills': 'Tracked Skills',

  'Journal Entries': 'Journal Entries',

  'Reopened': 'Reopened',

  'Accessing User Activity': 'Accessing User Activity',

  'We\'re sorry, we can\'t find any entries matching your search.':
    'We\'re sorry, we can\'t find any entries matching your search.',

  'Try adjusting your category and module selection.':
    'Try adjusting your category and class selection.',

  'Administration': 'Administration',

  'Content Editor': 'Content Editor',

  'Campus': 'Campus',

  'Score': 'Score',

  'Required': 'Required',

  'Start': 'Start',

  'Independent Learning': 'Independent Learning',

  'Submit': 'Submit',

  'Image Library': 'Image Library',

  'Mark as Complete': 'Mark as Complete',

  'Lesson Search': 'Lesson Search',

  'Words To Know': 'Words To Know',

  'Personal Notifications': 'Personal Notifications',

  'Course notifications': 'Course notifications',

  'New goal': 'New goal',

  'Completed goal': 'Completed goal',

  'Completed skill': 'Completed skill',

  'Completed action step': 'Completed action step',

  'Completed challenge': 'Completed challenge',

  'Completed badge': 'Completed Achievement',

  'Expiring action steps': 'Expiring action steps',

  'Expired action steps': 'Expired action steps',

  'Students Notifications': 'Students Notifications',

  'Add Question': 'Add Question',

  'Single Answer': 'Single Answer',

  'Multiple Answers': 'Multiple Answers',

  'Open Answer': 'Open Answer',

  'Correct Order': 'Correct Order',

  'Match Items': 'Match Items',

  'Rated': 'Rated',

  'Open Answer (not graded)': 'Open Answer (not graded)',

  'Add Answer': 'Add Answer',

  'Add Initial Text': 'Add Initial Text',

  'Text': 'Text',

  'Match': 'Match',

  'Click to add more answers': 'Click to add more answers',

  'Reqired Score': 'Required Score',

  'Client': 'Client',

  'Taken': 'Taken',

  'Duration': 'Duration',

  'Login Page': 'Login Page',

  'Access Advanced Resources': 'Access Advanced Resources',

  'Renew API Key': 'Renew API Key',

  'Choose Campus': 'Choose Campus',

  '-- System Level --': '-- System Level --',

  'Network': 'Network',

  'Group Goals': 'Group Goals',

  'Show activity for selected users': 'Show activity for selected users',

  'How useful was this action step?': 'How useful was this action step?',

  'Not at All': 'Not at All',

  'Very': 'Very',

  'Reopen Action Step': 'Reopen Action Step',

  'Mark Action Step Complete': 'Mark Action Step Complete',

  'Import': 'Import',

  'Import Users': 'Import Users',

  'Ignored records': 'Ignored records',

  'Line: {{ 0 }}, {{ 1 }}': 'Line: {{ 0 }}, {{ 1 }}',

  'Operations to be performed:': 'Operations to be performed:',

  'new users will be added': 'new users will be added',

  'new classes will be added': 'new classes will be added',

  'existing users will be updated': 'existing users will be updated',

  'existing classes will be updated': 'existing classes will be updated',

  'Add Child': 'Add Child',

  'Question With Open Answer': 'Question With Open Answer',

  'Question With Single Answer': 'Question With Single Answer',

  'Question With Multiple Answers': 'Question With Multiple Answers',

  'Correct Order Question': 'Correct Order Question',

  'Match Items Question': 'Match Items Question',

  'Lesson Title': 'Lesson Title',

  'Delay (days)': 'Delay (days)',

  'Minimum passing score': 'Minimum passing score',

  'Make Draggable': 'Make Draggable',

  'Check for correct answer': 'Check for correct answer',

  'Validity (in days)': 'Validity (in days)',

  'Credit Hours': 'Credit Hours',

  'Users': 'Users',

  'Role': 'Role',

  'Suspended': 'Suspended',

  '- Role -': '- Role -',

  '- Campus -': '- Campus -',

  'Center for Independent Futures. All rights reserved':
    'Center for Independent Futures. All rights reserved',

  'Change Ownership': 'Change Ownership',

  'Click to edit this text': 'Click to edit this text',

  'Make Queston Drag and Drop': 'Make Queston Drag and Drop',

  'Quiz Name': 'Learning Check Name',

  'Passing Score in %': 'Passing Score in %',

  'Code': 'Code',

  'Validity': 'Validity',

  'Add Certificate Template': 'Add Certificate Template',

  'Product': 'Product',

  'Edit Campus': 'Edit Campus',

  'Campus Name': 'Campus Name',

  'Add Logo': 'Add Logo',

  'Select Products': 'Select Products',

  'Syllabi': 'Syllabi',

  'Customize': 'Customize',

  'Active Modules:': 'Active Classes:',

  'Inactive Modules:': 'Inactive Classes:',

  'Copyright Message': 'Copyright Message',

  'Manager Header Color': 'Parent or Supporter Header Color',

  'Instructor Header Color': 'Instructor Header Color',

  'Admin Header Color': 'Admin Header Color',

  'Add Campus CSS': 'Add Campus CSS',

  'Participant Header Color': 'Participant Header Color',

  '- Modules -': '- Classes -',

  'View': 'View',

  'Add Lesson': 'Add Lesson',

  'Category Name': 'Category Name',

  'Achievement': 'Achievement',

  'Lesson Description': 'Lesson Description',

  'Action Step': 'Action Step',

  'Score (%)': 'Score (%)',

  'Quiz title': 'Learning Check title',

  'Skill Name': 'Skill Name',

  'Video URL': 'Video URL',

  'Answer': 'Answer',

  'Question': 'Question',

  'YouTube Video': 'YouTube Video',

  'Graded': 'Graded',

  'Grade Resource': 'Grade Resource',

  'Edit Quiz': 'Edit Learning Check',

  'Manually Graded': 'Manually Graded',

  'Add Quiz': 'Add Learning Check',

  'Quiz Solution': 'Learning Check Solution',

  'Participant': 'Participant',

  'Individual Goals': 'Individual Goals',

  'Step 4: Customizing The Appearance': 'Step 4: Customizing The Appearance',

  'My Full Life uses visual representations for Goals throughout the system. It is important to select an icon and color that most closely represents the goal objective.':
    'My Full Life uses visual representations for Goals throughout the system. It is important to select an icon and color that most closely represents the goal objective.',

  'Backend Error': 'Backend Error',

  'Edit User': 'Edit User',

  'Add Picture': 'Add Picture',

  'User Name': 'User Name',

  'Email Address': 'Email Address',

  'System Login': 'System Login',

  'System Password': 'System Password',

  'Interface Language': 'Interface Language',

  'User Roles': 'User Roles',

  'Managers': 'Parent or Supporter',

  'Manager': 'Parent or Supporter',

  'Identification Code': 'Identification Code',

  'System Resource': 'System Resource',

  'Yes': 'Yes',

  'No': 'No',

  'Compltete with score': 'Complete with score',

  'Incomplete with score': 'Incomplete with score',

  'Jan': 'Jan',

  'Feb': 'Feb',

  'Mar': 'Mar',

  'Apr': 'Apr',

  'May': 'May',

  'Jun': 'Jun',

  'Jul': 'Jul',

  'Aug': 'Aug',

  'Sep': 'Sep',

  'Oct': 'Oct',

  'Nov': 'Nov',

  'Dec': 'Dec',

  'l_Completed': 'I have learned',

  'l_In Progress': 'I am learning',

  'l_Recommended': 'I need to learn',

  'l_Needs Support': 'Needs Support',

  'l_Not Applicable': 'No experience',

  'l_Support': 'Support',

  'l_N/A': 'No experience',

  'Learning Track': 'Categories',
};
