import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {CreateSchoolDto, School} from 'api/schools/School';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {__, localizeText} from 'i18n/localize';
import {Config} from 'Config';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {SwitchInput} from 'components/SwitchInput';
import {useApiContext} from 'api/ApiContext';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {useLogger} from 'logging/logging';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {Product} from 'api/products/Product';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';
import classNames from 'classnames';
import {AppRoute} from 'routing/AppRoute';
import {Link} from 'react-router-dom';

export interface SchoolInputModalProps {
  initialSchool?: School,
  onCancel?: () => any,
  onComplete?: (school?: School) => any,
}

export const SchoolInputModal = (props: SchoolInputModalProps) => {
  const logger = useLogger(SchoolInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {schoolsService, productsService} = useApiContext();
  const isEditing = props.initialSchool?.id !== undefined;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newSchool, setNewSchool] = useState<CreateSchoolDto>({
    name: props.initialSchool?.name ?? '',
    advancedResources: props.initialSchool?.advancedResources ?? false,
    seats: props.initialSchool?.seats ?? 0,
    config: props.initialSchool?.config,
    image: props.initialSchool?.image,
    loginUrl: props.initialSchool?.loginUrl,
  });
  const [products, setProducts] = useState<Product[]>(props.initialSchool?.products ?? []);
  const [apiKey, setApiKey] = useState<string>('');
  const [shouldRenewApiKey, setShouldRenewapiKey] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [seatsString, setSeatsString] = useState<string>(props.initialSchool?.seats?.toString() ?? '');

  const fetchApiKey = async () => {
    if (props.initialSchool?.id) {
      const fetchedKey = await schoolsService.fetchSchoolApiKey(props.initialSchool?.id);
      setApiKey(fetchedKey);
    }
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (newSchool.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (seatsString.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, seats: true}));
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSubmitting(true);

    let saveResult: SuccessResponse<School>;
    try {
      const parsedSeats = isNaN(parseInt(seatsString)) ? 0 : parseInt(seatsString);

      if (isEditing) {
        if (props.initialSchool?.id) {
          saveResult = await schoolsService.updateSchool({
            ...newSchool,
            id: props.initialSchool.id,
            products,
            renew_api_key: shouldRenewApiKey,
            seats: parsedSeats,
          });
        } else {
          logger.error('Attempted update of school without ID');
          saveResult = {ok: false, entity: undefined};
        }
      } else {
        saveResult = await schoolsService.createSchool({
          ...newSchool,
          products,
          seats: parsedSeats,
        });
      }
    } catch (e) {
      logger.error('Error saving school', e);
      saveResult = {ok: false};
    }
    setIsSubmitting(false);

    if (saveResult.ok) {
      logger.info('Saved school', newSchool);
      props.onComplete?.(saveResult.entity);
    } else {
      logger.error('Failed to save school', saveResult);
    }
  };

  useEffect(() => {
    validate();
  }, [newSchool, seatsString]);

  useEffect(() => {
    (async () => {
      await fetchApiKey();
    })();
  }, [props.initialSchool]);

  return (
    <Modal
      title={__(isEditing ? 'Edit Campus' : 'Add Campus')}
      onCloseRequested={props.onCancel}
      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
          disabled={isSubmitting}
        />
      }
      body={
        <form name="form.edit">
          <div className="row-fluid">
            <div className="span3">
              <img
                alt={''}
                src={newSchool.image ?
                  newSchool.image + '100/100' :
                  Config.defaultSchoolImage
                }
                className="circle-50 gray"
                style={{width: 90, height: 90, marginBottom: 5}}/>
            </div>
            <div className="span9">
              <div className="row-fluid">
                <input
                  value={newSchool.name}
                  onChange={(e) => setNewSchool((current) => ({
                    ...current,
                    name: e.target.value,
                  }))}
                  type="text"
                  className={classNames('span12', {'invalid': errors.name})}
                  required
                  placeholder={localizeText('Campus Name')}
                />
              </div>
              <div className="row-fluid">
                <input
                  value={seatsString}
                  onChange={(e) => setSeatsString(e.target.value)}
                  type="text"
                  className={classNames('span12', {'invalid': errors.seats})}
                  required
                  placeholder={localizeText('Seats')}
                />
              </div>
              <div className="row-fluid">
                <FileUploadInput
                  buttonText={localizeText('Add Logo')}
                  initialFiles={newSchool.image ? [newSchool.image] : []}
                  onChange={(newImage) => setNewSchool((current) => ({
                    ...current,
                    image: newImage.length > 0 ? newImage[0] : undefined,
                  }))}
                />
              </div>
              <div className="row-fluid spacer">
                <SwitchInput
                  on={newSchool.advancedResources}
                  onChange={(nowOn) => setNewSchool((current) => ({
                    ...current,
                    advancedResources: nowOn,
                  }))}
                />
                <span>{localizeText('Access Advanced Resources')}</span>
              </div>
            </div>
          </div>

          <AccessControlled feature={AppFeature.EditSystemResources}>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<Product>
                placeholder={__('Products')}
                multiple={true}
                fetch={async (filter) => {
                  const products = await productsService.fetchProducts(filter.search);
                  return (products).filter((p: any) => p !== undefined);
                }}
                value={products}
                onSelect={(selected) => {
                  setProducts(selected.map((s) => s));
                }}
                isOptionSelected={(product) => products.some((p) => p.id === product.id)}
              />
            </div>
          </AccessControlled>

          <div className="row-fluid">
            <input
              value={newSchool.loginUrl}
              onChange={(e) => setNewSchool((current) => ({
                ...current,
                loginUrl: e.target.value,
              }))}
              type="text"
              className="span12"
              placeholder={localizeText('Login Url')}
            />
          </div>

          {isEditing &&
            <>
              <div className="row-fluid">
                <div className="span12">
                  <textarea className="span12" value={apiKey} readOnly />
                  <div className="row-fluid spacer">
                    <SwitchInput on={shouldRenewApiKey} onChange={(nowOn) => setShouldRenewapiKey(nowOn)} />
                    <span>{localizeText('Renew API Key')}</span>
                  </div>
                </div>
              </div>
              {apiKey &&
                <div className="row-fluid">
                  <div className="btn-group span12">
                    <Link
                      to={AppRoute.Register.replace(':instanceKey', props.initialSchool?.key ?? '')}
                      className="btn btn-info span6"
                      target="_blank"
                    >
                      {localizeText('Registration Page')}
                    </Link>
                  </div>
                </div>
              }
            </>
          }
        </form>
      }

      footer={<InputPlaceholderMirror/>}
    />
  );
};
