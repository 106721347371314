import React, {useEffect, useState} from 'react';
import {ContextMenu} from 'components/ContextMenu';
import {ListFilter} from 'api/lists/ListFilter';
import {SearchInput} from 'components/SearchInput';
import {Student} from 'api/users/Student';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {Course} from 'api/courses/Course';
import {__} from 'i18n/localize';
import {StudentQuickView} from 'views/users/StudentQuickView';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {SyllabiList} from 'views/syllabi/SyllabiList';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {Goal} from 'api/goals/Goal';
import {GoalTargetType} from 'api/goals/GoalTargetType';
import {InViewObserver} from 'components/InViewObserver';
import {LoadingIndicator} from 'components/LoadingIndicator';

export type StudentsListViewProps = {
  hideSidePanel?: boolean,
}

export const StudentsListView = ({hideSidePanel}: StudentsListViewProps) => {
  const logger = useLogger(StudentsListView.name);
  const {usersService, coursesService, goalsService} = useApiContext();

  const [firstPageLoaded, setFirstPageLoaded] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);
  const [studentsPage, setStudentsPage] = useState(0);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [filter, setFilter] = useState<ListFilter>({filters: {}});
  const [courses, setCourses] = useState<Course[]>([]);
  const [addedGoals, setAddedGoals] = useState<{studentId?: number, syllabusId?: number, goal: Goal}[]>([]);

  const loadStudents = async () => {
    if (isLoadingStudents) {
      return;
    }

    setIsLoadingStudents(true);

    const fetchedStudents = await usersService.fetchMonitoredUsers({
      ...filter,
      start: (studentsPage * 5),
      end: (studentsPage * 5) + 5,
    });
    setStudents((current) => current.concat(fetchedStudents));
    logger.debug('Fetched students', fetchedStudents);

    if (!firstPageLoaded) {
      setFirstPageLoaded(true);
    }

    setIsLoadingStudents(false);
  };

  const loadNextStudentsPage = async () => {
    setStudentsPage((current) => current + 1);
    await loadStudents();
  };

  const resetFilter = async () => {
    setStudentsPage(0);
    setStudents([]);
    await loadStudents();
  };

  const loadCourses = async () => {
    const fetchedCourses = await coursesService.fetchTaughtCourses({});
    setCourses(fetchedCourses);
  };

  useEffect(() => {
    (async () => {
      await resetFilter();
    })();
  }, [filter]);

  useEffect(() => {
    (async () => {
      await loadCourses();
    })();
  }, []);

  const onGoalDragEnd = async (result: DropResult) => {
    const goalId = parseInt(result.draggableId);
    const targetTypeStr = result.destination?.droppableId.split('-')[0];
    const targetIdStr = result.destination?.droppableId.split('-')[1];
    const targetId = targetIdStr ? parseInt(targetIdStr) : undefined;
    const sourceType = result.source.droppableId.split('-')[0];

    if (isNaN(goalId)) {
      logger.error('Goal dragged with invalid ID');
      return;
    }
    if (targetTypeStr === undefined || sourceType === undefined) {
      logger.error('Goal dragged to/from invalid droppable area');
      return;
    }
    if (targetId === undefined || isNaN(targetId)) {
      logger.error('Goal dragged to invalid droppable area');
      return;
    }

    if (result.destination?.droppableId === result.source.droppableId) {
      logger.debug('Skipping goal dropped in same area');
      return;
    }

    let targetType: GoalTargetType | undefined;
    if (targetTypeStr === 'user') {
      targetType = GoalTargetType.User;
    } else if (targetTypeStr === 'styllabus') {
      targetType = GoalTargetType.Syllabus;
    } else {
      targetType = undefined;
    }
    if (targetType === undefined) {
      logger.error('Invalid target type');
      return;
    }

    const goalCopyResult = await goalsService.copyGoal(goalId, targetId, targetType);
    logger.debug('Goal drag result', goalCopyResult);
    setAddedGoals((current) => {
      const copy = [...current];
      copy.push({
        goal: goalCopyResult,
        syllabusId: targetType === GoalTargetType.Syllabus ? targetId : undefined,
        studentId: targetType === GoalTargetType.User ? targetId : undefined,
      });
      return copy;
    });
  };

  return (
    <DragDropContext onDragEnd={onGoalDragEnd}>
      <SidePanelPageView
        containerClassName={'column-layout'}
        firstColumnClassName={'left-column'}
        startPanelClosed={true}
        panelView={(
          hideSidePanel ? <></> :
            <SyllabiList
              addedGoals={
                addedGoals
                    .filter((g) => g.syllabusId !== undefined)
                    .map((g) => ({goal: g.goal, syllabusId: g.syllabusId!}))
              }
            />
        )}
        hideSidePanel={hideSidePanel}
        mainView={
          <div
            className={'pane'}
            style={{paddingTop: 40, background: 'url(/static/media/content-bg.7963d1e7.gif) repeat'}}
          >
            <div
              className={'body'}
              style={{marginTop: 40}}
            >
              <ContextMenu>
                <SearchInput
                  value={filter.search}
                  onChange={(newValue) => setFilter((current) => ({
                    ...current,
                    search: newValue,
                  }))}
                />

                {courses.length > 0 &&
                  <>
                    <i className="icon icon-users" />
                    <select
                      value={filter.filters.course}
                      onChange={(e) => setFilter((current) => ({
                        ...current,
                        filters: {
                          ...current.filters,
                          course: e.target.value,
                        },
                      }))}
                    >
                      <option value="">-- {__('Modules')} --</option>
                      {courses.map((course) => (
                        <option key={course.id} value={course.id}>{course.name}</option>
                      ))}
                    </select>
                  </>
                }
              </ContextMenu>

              <div>
                {students.map((student) => (
                  <StudentQuickView
                    key={student.id}
                    student={student}
                    addedGoals={addedGoals
                        .filter((g) => g.studentId === student.id)
                        .map((g) => g.goal)
                    }
                    onGoalDeleted={(goalId) => {
                      setAddedGoals((current) => current.filter((g) => g.goal.id !== goalId));
                    }}
                  />
                ))}
              </div>

              {isLoadingStudents && <LoadingIndicator/>}
              {firstPageLoaded && <InViewObserver onInView={loadNextStudentsPage}/>}
              <div style={{height: 150}}/>
            </div>
          </div>
        }
      />
    </DragDropContext>
  );
};
