/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {FormEvent, useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {useHistory, useParams} from 'react-router-dom';
import {InstanceInfo} from 'api/instances/InstanceInfo';
import {Helmet} from 'react-helmet';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {Config} from 'Config';
import {useNotificationContext} from 'contexts/NotificationContext';
import {AppRoute} from 'routing/AppRoute';
import {useAuthContext} from 'contexts/AuthContext';
import {AnalyticsService} from 'analytics/AnalyticsService';
import {AnalyticsEvent} from 'analytics/AnalyticsEvent.enum';

export const RegisterView = () => {
  const logger = useLogger(RegisterView.name);
  const {authenticateUser, registerUser} = useAuthContext();
  const {showErrorNotification} = useNotificationContext();
  const {instancesService} = useApiContext();
  const {instanceKey}: any = useParams<{instanceKey: string}>();
  const {push: pushRoute} = useHistory();

  const [instanceInfo, setInstanceInfo] = useState<InstanceInfo | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [instanceInfoError, setInstanceInfoError] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [country, setCountry] = useState<string>('US');
  const [errors, setErrors] = useState<any>({});
  const [registered, setRegistered] = useState<boolean>(false);

  // TODO: course register??
  const course = false;

  const fetchInstanceInfo = async () => {
    setIsFetching(true);
    const fetchedInfo = await instancesService.fetchInstancePublicInfo(instanceKey);
    setInstanceInfo(fetchedInfo);
    logger.debug('Fetched instance info', fetchedInfo);
    setIsFetching(false);
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (!showLogin) {
      if (name.trim().length < 1) {
        setErrors((current: any) => ({...current, name: true}));
        isValid = false;
      }
      if (email.trim().length < 1) {
        setErrors((current: any) => ({...current, email: true}));
        isValid = false;
      }
      if (password.trim().length < 1) {
        setErrors((current: any) => ({...current, password: true}));
        isValid = false;
      }
      if (organization.trim().length < 1) {
        setErrors((current: any) => ({...current, organization: true}));
        isValid = false;
      }
      if (address.trim().length < 1) {
        setErrors((current: any) => ({...current, address: true}));
        isValid = false;
      }
      if (city.trim().length < 1) {
        setErrors((current: any) => ({...current, city: true}));
        isValid = false;
      }
      if (state.trim().length < 1) {
        setErrors((current: any) => ({...current, state: true}));
        isValid = false;
      }
      if (zip.trim().length < 1) {
        setErrors((current: any) => ({...current, zip: true}));
        isValid = false;
      }
    } else {
      if (login.trim().length < 1) {
        setErrors((current: any) => ({...current, login: true}));
        isValid = false;
      }
      if (password.trim().length < 1) {
        setErrors((current: any) => ({...current, password: true}));
        isValid = false;
      }
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    if (showLogin) {
      const authResult = await authenticateUser(login, password);
      if (authResult.ok) {
        pushRoute(AppRoute.Home);
      } else {
        showErrorNotification(__('Invalid Login'), __('Invalid username or password'));
      }
    } else {
      const registerResult = await registerUser({
        name,
        email,
        password,
        phone,
        practice: organization,
        street: address,
        city, state, zip,
        country,
        locale: 1,
        key: instanceKey,
        student: false,
      });
      setRegistered(true);
    }
  };

  useEffect(() => {
    validate();
  }, [name, email, password, login, address, city, state, zip, organization, showLogin]);

  useEffect(() => {
    (async () => {
      if (instanceKey) {
        console.log(instanceKey);
        await fetchInstanceInfo();
      }
    })();
  }, [instanceKey]);

  return (
    <div className="pane city register" style={{bottom: 0}}>
      {instanceInfo?.style &&
        <Helmet>
          <link rel="stylesheet" href={`/api/public/style/${instanceInfo.key}`} />
        </Helmet>
      }

      <div className="body" style={{padding: '15px 40px'}}>
        <div className="pack">
          <div className="row-fluid">
            <div className="span12">
              <div className="form-header">
                <div className="logo">
                  <img src={Config.defaultSchoolImage} alt={'logo'} />
                </div>
                <div className="name">{instanceInfo?.name}</div>
              </div>
            </div>
          </div>
          <div className="row-fluid">
            {course &&
              <div className="span8">
                <div className="span4" style={{paddingLeft: 15}}>
                  <div className="teachers">
                    <div className="teacher clearfix" ng-repeat="user in course.instructors">
                      <div className="image block">
                        <img ng-src="{{ user.image ? user.image + '100/100' : '/images/anonymous.png' }}"
                          alt="User Avatar"/>
                        <div className="name block">{'user.name'}</div>
                        <div ng-if="user.bio" className="btn-group">
                          <a className="btn btn-inverse " tooltip-html-unsafe="{{ user.bio }}"
                            tooltip-append-to-body="false" tooltip-placement="right"
                            tooltip-trigger="click" tooltip-popup-delay="500"
                            aria-label="Instructor Bio"
                          >
                            <i className="icon icon-user" /> Instructor Bio
                          </a>
                        </div>
                      </div>
                      <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                  </div>
                  <div style={{clear: 'both'}} />
                </div>
                <div className="span8">
                  <h3>{'course.name'}</h3>
                  <div ng-if="course.description" className="description frame">
                    <div className="title">{__('Description')}</div>
                    <div className="html padding" ng-bind-html="course.description | asHtml"></div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
              </div>
            }

            {!course && !instanceInfo?.description &&
              <div className="span8">
                <h4>Welcome to the {instanceInfo?.name} educational portal.</h4>
                <p>Please register, or Log-in, to access our continuing education materials.</p>
                <div className="clearfix" />
              </div>
            }

            {instanceInfo?.description &&
              <div className="span8" dangerouslySetInnerHTML={{__html: instanceInfo.description}} />
            }

            <div className="span4 rightCol">
              <form name="form" className="login" onSubmit={submit}>
                <div className="row-fluid">
                  <div className="btn-group btn-group-lg span12" role="group">
                    <button
                      type="button"
                      aria-label="New User"
                      onClick={() => setShowLogin(false)}
                      className={classNames('btn span6', showLogin ? 'btn-default' : 'btn-info')}
                    >
                      New User
                    </button>
                    <button
                      type="button"
                      aria-label="Returning User"
                      onClick={() => setShowLogin(true)}
                      className={classNames('btn span6', showLogin ? 'btn-info' : 'btn-default')}
                    >
                      Returning User
                    </button>
                  </div>
                </div>

                {!registered && !showLogin &&
                  <div>
                    <div className="form-horizontal">
                      <div className="row-fluid">
                        <input
                          placeholder={__('Your Name')}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          aria-label={__('Your Name')}
                          className={classNames('form-control span12', {'ng-invalid': errors.name})}
                        />
                      </div>
                      <div className="row-fluid">
                        <input
                          placeholder={__('Email')}
                          aria-label={__('Email')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          className={classNames('form-control span12', {'ng-invalid': errors.email})}
                        />
                      </div>
                      <div className=" row-fluid">
                        <input
                          placeholder={__('Password')}
                          aria-label={__('Password')}
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={classNames('form-control span12', {'ng-invalid': errors.password})}
                        />
                      </div>
                      <div className=" row-fluid">
                        <input
                          placeholder={__('Phone')}
                          type="text"
                          className="span12"
                          aria-label={__('Phone')}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className=" row-fluid">
                        <input
                          placeholder={__('Orginization Name')}
                          aria-label={__('Orginization Name')}
                          value={organization}
                          onChange={(e) => setOrganization(e.target.value)}
                          type="text"
                          className={classNames('span12', {'ng-invalid': errors.organization})}
                        />
                      </div>
                      <div className="row-fluid">
                        <input
                          placeholder={__('Street')}
                          aria-label={__('Street')}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          type="text"
                          className={classNames('span12', {'ng-invalid': errors.address})}
                        />
                      </div>

                      <div className=" row-fluid">
                        <input
                          placeholder={__('City')}
                          aria-label={__('City')}
                          type="text"
                          className={classNames('span5', {'ng-invalid': errors.city})}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />

                        <input
                          placeholder={__('State')}
                          aria-label={__('State')}
                          type="text"
                          className={classNames('span3', {'ng-invalid': errors.state})}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />

                        <input
                          placeholder={__('Zip')}
                          aria-label={__('Zip')}
                          type="text"
                          className={classNames('span2', {'ng-invalid': errors.zip})}
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />

                        <select
                          className="span2"
                          value={country}
                          aria-label="Country"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Åland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia</option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">British Indian Ocean Territory</option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">Congo, The Democratic Republic of The</option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Cote D&rsquo;ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">Falkland Islands (Malvinas)</option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">French Southern Territories</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">Heard Island and Mcdonald Islands</option>
                          <option value="VA">Holy See (Vatican City State)</option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran, Islamic Republic of</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="KP">Korea, Democratic People&rsquo;s Republic of</option>
                          <option value="KR">Korea, Republic of</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">Lao People&rsquo;s Democratic Republic</option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libyan Arab Jamahiriya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">Macedonia, The Former Yugoslav Republic of</option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">Micronesia, Federated States of</option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="AN">Netherlands Antilles</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">Palestinian Territory, Occupied</option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Reunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="SH">Saint Helena</option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">Saint Vincent and The Grenadines</option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">South Georgia and The South Sandwich Islands</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syrian Arab Republic</option>
                          <option value="TW">Taiwan, Province of China</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">Tanzania, United Republic of</option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UM">United States Minor Outlying Islands</option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">Venezuela</option>
                          <option value="VN">Viet Nam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.S.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </select>
                      </div>

                      <div className=" row-fluid">
                        <button
                          aria-label={__('Register')}
                          type={'submit'}
                          className="btn btn-warning span12 btn-lg"
                          onClick={() => {
                            AnalyticsService.default().recordEvent(
                                AnalyticsEvent.NewUserRegistered,
                                instanceInfo?.name ?? '-',
                                instanceInfo?.name ?? '-',
                            );
                          }}
                        >
                          {__('Register')}
                        </button>
                      </div>
                    </div>
                  </div>
                }

                {showLogin &&
                  <div>
                    <div className="form-horizontal">
                      <div className="row-fluid">
                        <input
                          placeholder={__('Login')}
                          aria-label={__('Login')}
                          type="text"
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          className={classNames('form-control span12', {'ng-invalid': errors.login})}
                        />
                      </div>
                      <div className="row-fluid">
                        <input
                          placeholder={__('Password')}
                          aria-label={__('Password')}
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={classNames('form-control span12', {'ng-invalid': errors.password})}
                        />
                      </div>
                      <div className=" row-fluid">
                        <button
                          type={'submit'}
                          aria-label="Submit"
                          className="btn btn-warning span12 btn-lg"
                          onClick={() => {
                            AnalyticsService.default().recordEvent(
                                AnalyticsEvent.UserRegisteredForCourse,
                                'Course Registration',
                                instanceInfo?.name ?? '-',
                            );
                          }}
                        >
                          {__('Register')}
                        </button>
                      </div>
                    </div>
                  </div>
                }

                {registered &&
                  <div style={{padding: '10px 15px'}}>
                    <div className="form-header">
                      <div className="logo"><img src={instanceInfo?.logo} alt={''} /></div>
                      <div className="name">{instanceInfo?.name}</div>
                    </div>

                    <h4 style={{fontSize: 14}}>
                      You have successfully registered. Use the button bellow to sign in into the system.
                    </h4>
                    <a href="/" className="btn btn-success pull-right" target="_blank">{__('Sign In')}</a>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>

        <div className="row-fluid pack body" style={{padding: '10px 0'}}>
          <div style={{padding: '10px 15px'}}>
            <a style={{color: 'white'}} target="_blank" href="https://independentfutures.com/" rel="noreferrer">
              About us
            </a>
            {' '}| <a style={{color: 'white'}} href="docs/centric_TOS.pdf">Terms of Service</a>
            {' '}| <a style={{color: 'white'}} href="docs/centric_Privacy.pdf">Privacy policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};
