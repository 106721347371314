import {Challenge, CreateChallengeDto, UpdateChallengeDto} from 'api/challenges/Challenge';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';

/**
 * Provides API challenges functions
 */
export class ChallengesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Creates a new challenge
   *
   * @param {number} challenge
   * @return {Promise<SuccessResponse<Challenge>>}
   */
  async createChallenge(challenge: CreateChallengeDto): Promise<SuccessResponse<Challenge>> {
    const response = await this.httpClient.post(
        ApiRoute.Challenges + ApiRoute.Save,
        challenge,
    );
    return response.data;
  }

  /**
   * Updates a challenge
   *
   * @param {number} challenge
   * @return {Promise<SuccessResponse<Challenge>>}
   */
  async updateChallenge(challenge: UpdateChallengeDto): Promise<SuccessResponse<Challenge>> {
    const response = await this.httpClient.post(
        ApiRoute.Challenges + ApiRoute.Save,
        challenge,
    );
    return response.data;
  }

  /**
   * Deletes a challenge
   *
   * @param {number} id
   * @return {Promise<SuccessResponse<undefined>>}
   */
  async deleteChallenge(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(
        ApiRoute.Challenges + ApiRoute.Delete,
        {id},
    );
    return response.data;
  }

  /**
   * Fetches a given users's goals
   *
   * @param {number} userId
   * @return {Promise<Challenge[]>}
   */
  async fetchPersonalGoals(userId: number): Promise<Challenge[]> {
    const response = await this.httpClient.post('/challenges/personal', {id: userId});
    return response.data.list;
  }
}
