import {AxiosInstance} from 'axios';
import {StatEvent} from 'api/stats/StatEvent';
import {ApiRoute} from 'api/ApiRoute';
import dayjs from 'dayjs';
import {Goal} from 'api/goals/Goal';
import {Category} from 'api/categories/Category';
import {Lesson} from 'api/lessons/Lesson';
import {Skill} from 'api/skills/Skill';
import {humanTimeDiff} from 'api/util/dates';

/**
 * Provides API stats functions
 */
export class StatsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches the current user's stat events (logins, goal/step completions)
   *
   * @param {Date} dateRangeStart
   * @param {Date} dateRangeEnd
   * @return {Promise<StatEvent[]>}
   */
  async fetchStatEvents(dateRangeStart: Date, dateRangeEnd: Date): Promise<StatEvent[]> {
    const response = await this.httpClient.post(ApiRoute.StatEvents, {
      start: dateRangeStart,
      end: dateRangeEnd,
    });

    // Events are returned with timestamps for dates of midnight on the first of the month at midnight UTC.
    // We transform them here to get year + month
    return response.data.list.map((event: any) => {
      const date = dayjs(event.month);
      return {
        month: date.month(),
        year: date.year(),
        type: event.type,
        count: event.count,
      };
    });
  }

  /**
   * Fetches user goal stats
   *
   * @param {Date} dateRangeStart
   * @param {Date} dateRangeEnd
   * @return {Promise<Goal[]>}
   */
  async fetchGoalStats(dateRangeStart: Date, dateRangeEnd: Date): Promise<Goal[]> {
    const response = await this.httpClient.post(ApiRoute.StatGoals, {
      start: dateRangeStart,
      end: dateRangeEnd,
    });
    return response.data.list;
  }

  /**
   * Fetches the number of new users in the system
   *
   * @param {number} period number of days to look back
   * @param {number | undefined} schoolId
   * @return {Promise<number>}
   */
  async fetchNewUserCount(period: number, schoolId?: number): Promise<number> {
    const response = await this.httpClient.post('/users/newUserCount', {
      filter: {
        days: period,
        id: schoolId,
      },
    });
    return response.data.newUsers;
  }

  /**
   * Fetches total user counts
   */
  async fetchUserCount(): Promise<{students: number, support: number, userCount: number}> {
    const response = await this.httpClient.post('/users/userCount', {});
    return response.data;
  }

  /**
   * Fetches skill goals created in the given date range
   *
   * @param {Date} dateRangeStart
   * @param {Date} dateRangeEnd
   * @param {number | undefined} schoolId
   */
  async fetchRecentSkills(dateRangeStart: Date, dateRangeEnd: Date, schoolId?: number): Promise<Goal[]> {
    const response = await this.httpClient.post('/stats/recentSkills', {
      start: dateRangeStart,
      end: dateRangeEnd,
      id: schoolId,
    });
    return response.data.group_skills;
  }

  /**
   * Fetches goals created in the given date range
   *
   * @param {Date} dateRangeStart
   * @param {Date} dateRangeEnd
   * @param {number | undefined} schoolId
   */
  async fetchRecentGoals(dateRangeStart: Date, dateRangeEnd: Date, schoolId?: number): Promise<Goal[]> {
    const response = await this.httpClient.post('/stats/recentGoals', {
      start: dateRangeStart,
      end: dateRangeEnd,
      id: schoolId,
    });
    return response.data.group_goals;
  }

  /**
   * Groups fetched lesson goals into categories with counts
   *
   * @param {any} lessonGoals
   */
  groupLessonsByCategory(lessonGoals: any[]) {
    const groups: {
      category: Category,
      lessons: {lesson: Lesson, count: number}[]
    }[] = [];

    lessonGoals.forEach((lessonGoal: any) => {
      lessonGoal.lessons.forEach((lesson: any) => {
        let topLevelCategory = lesson.category;
        while (topLevelCategory.parent) {
          topLevelCategory = topLevelCategory.parent;
        }

        let group = groups.find((g) => g.category.id === topLevelCategory.id);
        if (!group) {
          group = {category: topLevelCategory, lessons: []};
          groups.push(group);
        }

        let theLesson = group.lessons.find((s) => s.lesson.id === lesson.id);
        if (!theLesson) {
          theLesson = {lesson, count: 0};
          group.lessons.push(theLesson);
        }

        ++theLesson.count;
      });
    });

    return groups;
  }

  /**
   * Groups fetched skills goals into categories with counts
   *
   * @param {any} skillGoals
   */
  groupSkillsByCategory(skillGoals: any[]) {
    const groups: {
      category: Category,
      skillGoals: {skill: Skill, count: number, time: number, humanTime: string}[]
    }[] = [];

    skillGoals.forEach((skillGoal) => {
      let topLevelCategory = skillGoal.ilri.category;
      while (topLevelCategory.parent) {
        topLevelCategory = topLevelCategory.parent;
      }

      let group = groups.find((g) => g.category.id === topLevelCategory.id);
      if (!group) {
        group = {category: topLevelCategory, skillGoals: []};
        groups.push(group);
      }

      let skill = group.skillGoals.find((s) => s.skill.id === skillGoal.ilri.id);
      if (!skill) {
        skill = {skill: skillGoal.ilri, count: 0, time: 0, humanTime: ''};
        group.skillGoals.push(skill);
      }

      ++skill.count;
      const timeSpent = skillGoal.completeDate - skillGoal.cdate;
      skill.time += timeSpent;
    });
    groups.forEach((group) => {
      group.skillGoals.forEach((goal) => {
        const humanDiff = humanTimeDiff(goal.time / goal.count);
        goal.humanTime = parseFloat(humanDiff.time).toFixed(2) + ' ' + humanDiff.units;
      });
    });

    return groups;
  }
}
