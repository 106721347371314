import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Button, ButtonVariant} from 'components/buttons/Button';
import classNames from 'classnames';
import {CollageLibraryItem} from 'api/collages/CollageLibraryItem';
import {InViewObserver} from 'components/InViewObserver';

export interface CollageLibraryModalProps {
  onCloseRequested?: () => any,
  onComplete?: (items: CollageLibraryItem[]) => any,
}

export const CollageLibraryModal = (props: CollageLibraryModalProps) => {
  const logger = useLogger(CollageLibraryModal.name);
  const {collagesService} = useApiContext();
  const itemsPerPage = 20;

  const [items, setItems] = useState<CollageLibraryItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<CollageLibraryItem[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isFetchingItems, setIsFetchingItems] = useState<boolean>(true);

  const fetchCollageItems = async () => {
    setIsFetchingItems(true);
    const fetchedItems = await collagesService.fetchLibraryItems({
      start: page * itemsPerPage,
      end: (page * itemsPerPage) + itemsPerPage,
    });
    logger.debug('Fetched collage items', fetchedItems);
    setItems((current) => current.concat(fetchedItems));
    setIsFetchingItems(false);
  };

  const itemIsSelected = (searchItem: CollageLibraryItem): boolean => {
    return selectedItems.find((item) => item.id === searchItem.id) !== undefined;
  };

  const selectItem = (item: CollageLibraryItem) => setSelectedItems((current) => {
    if (itemIsSelected(item)) {
      return current.filter((i) => i.id !== item.id);
    } else {
      return current.concat([item]);
    }
  });

  useEffect(() => {
    (async () => {
      await fetchCollageItems();
    })();
  }, [page]);

  return <Modal
    title={__('Collage Library')}
    onCloseRequested={props.onCloseRequested}
    className={'wide'}

    buttons={
      <Button variant={ButtonVariant.Add} onClick={() => props.onComplete?.(selectedItems)} />
    }

    body={
      <>
        <div role={'list'}>
          {items.map((item) => (
            <div
              key={item.id}
              className={classNames('gallery-image', {'selected': itemIsSelected(item)})}
              onClick={() => selectItem(item)}
              aria-label="Select Image"
            >
              <div className="select-icon"><i className="icon-check" /></div>
              <img src={ item.image?.url + '150/100/1'} alt={item.image?.name} />
            </div>
          ))}
        </div>

        <InViewObserver onInView={() => setPage((current) => current + 1)}/>
        {isFetchingItems && <LoadingIndicator/>}
      </>
    }
  />;
};
