import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {CreateProductDto, Product, ProductWithRelations, UpdateProductDto} from 'api/products/Product';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API product functions
 */
export class ProductsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Updates products in the API
   */
  async updateProducts(): Promise<any> {
    return await this.httpClient.post(ApiRoute.UpdateProducts);
  }

  /**
   * Creates a new product in the API
   *
   * @param {CreateProductDto} product
   * @return {Promise<SuccessResponse>}
   */
  async createProduct(product: CreateProductDto): Promise<SuccessResponse<Product>> {
    const response = await this.httpClient.post(ApiRoute.Products + ApiRoute.Save, product);
    return response.data;
  }

  /**
   * Updates a product in the API
   *
   * @param {UpdateProductDto} product
   * @return {Promise<SuccessResponse>}
   */
  async updateProduct(product: UpdateProductDto): Promise<SuccessResponse<Product>> {
    const response = await this.httpClient.post(ApiRoute.Products + ApiRoute.Save, product);
    return response.data;
  }

  /**
   * Deletes a product from the API
   *
   * @param {number} productId
   */
  async deleteProduct(productId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.Products + ApiRoute.Delete, {
      id: productId,
    });
    return response.data;
  }

  /**
   * Fetches products from the API
   *
   * @param {string} searchFilter
   * @return {Product[]}
   */
  async fetchProducts(searchFilter = '') {
    const response = await this.httpClient.post(ApiRoute.Products + ApiRoute.List, {
      filter: {
        search: searchFilter,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a single product by ID
   *
   * @param {number} id
   */
  async fetchProduct(id: number): Promise<ProductWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Products + ApiRoute.Get, {id});
    return response.data;
  }
}
