import React from 'react';
import {SkillWithRelations} from 'api/skills/Skill';
import {Modal} from 'components/Modal';
import classNames from 'classnames';

export interface SkillDetailsModalProps {
  skill: SkillWithRelations,
  onCloseRequested: () => any,
}

export const SkillDetailsModal = (props: SkillDetailsModalProps) => {
  const {skill} = props;

  return <Modal
    title={'Skill View'}
    onCloseRequested={props.onCloseRequested}

    body={
      <>
        <div className="row-fluid">
          <div className="span2">
            <div className="circle-50" style={{color: 'white', background: skill.category?.color}}>
              <span className={classNames('icon', skill.category?.icon)} />
            </div>
          </div>
          <h4 className="span9">
            {skill.name}
          </h4>
        </div>

        <div className="row-fluid">
          <div className="span2"/>
          <p className="span9">
            Category: {skill.category?.name }
          </p>
        </div>

        {(skill.relatedSkills?.length ?? 0) > 0 &&
          <div className="row-fluid">
            <ul>
              {skill.relatedSkills?.map((relatedSkill) => (
                <li key={relatedSkill.id}>{relatedSkill.name}</li>
              ))}
            </ul>
          </div>
        }
      </>
    }
  />;
};
