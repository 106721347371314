import React, {
  createContext,
  PropsWithChildren,
  useContext, useEffect, useState,
} from 'react';
import {useSessionContext} from 'contexts/SessionContext';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {UserRoleType} from 'api/users/UserRole';

/**
 * Provides a context for entity viewing history (ie. seen/unseen status)
 */

export interface EntityViewHistoryContextInterface {
  newestSeenJournalEntryId: number,
  newestSeenJournalCommentId: number,
  journalEntryIsSeen: (id: number) => boolean,
  journalCommentIsSeen: (id: number) => boolean,
  checkAndRecordJournalEntrySeenStatus: (id: number) => boolean,
  checkAndRecordJournalCommentSeenStatus: (id: number) => boolean,
  pendingActivityCount: number,
  fetchPendingActivity: () => any,
}

export const EntityViewHistoryContext = createContext<EntityViewHistoryContextInterface | undefined>(undefined);

export const useEntityViewHistoryContext = () => {
  const context = useContext(EntityViewHistoryContext);
  if (context === undefined) {
    throw new Error(useEntityViewHistoryContext.name + ' must be used in a provider');
  }
  return context;
};

const getHighestId = (list: number[]) => {
  return list.sort((a, b) => a < b ? 1 : -1)[0];
};

export const EntityViewHistoryContextProvider = (props: PropsWithChildren<{}>) => {
  const logger = useLogger(EntityViewHistoryContextProvider.name);
  const {sessionDetails} = useSessionContext();
  const {viewingHistoryService, journalService} = useApiContext();

  const [pendingActivityCount, setPendingActivityCount] = useState<number>(0);
  const [newestSeenJournalEntryId, setNewestSeenJournalEntryId] =
    useState<number>(getHighestId(sessionDetails?.seenEntities?.['journal.entry'] ?? []));
  const [newestSeenJournalCommentId, setNewestSeenJournalCommentId] =
    useState<number>(getHighestId(sessionDetails?.seenEntities?.['journal.comment'] ?? []));

  const fetchPendingActivity = async () => {
    const fetchedPendingActivity = await journalService.fetchPendingActivity();
    setPendingActivityCount(fetchedPendingActivity.count);
    logger.debug('Fetched pending activity', fetchedPendingActivity);
  };

  const journalEntryIsSeen = (id: number) => id <= newestSeenJournalEntryId;
  const journalCommentIsSeen = (id: number) => id <= newestSeenJournalCommentId;
  const checkAndRecordJournalEntrySeenStatus = (id: number) => {
    if (id > newestSeenJournalEntryId) {
      setNewestSeenJournalEntryId(id);
      viewingHistoryService.markSeenJournalEntries([id]);
    }
    return journalEntryIsSeen(id);
  };
  const checkAndRecordJournalCommentSeenStatus = (id: number) => {
    if (id > newestSeenJournalCommentId) {
      setNewestSeenJournalCommentId(id);
      viewingHistoryService.markSeenJournalComments([id]);
    }
    return journalCommentIsSeen(id);
  };

  useEffect(() => {
    (async () => {
      if (
        sessionDetails?.role &&
        [UserRoleType.Teacher, UserRoleType.InstructorAssistant].includes(sessionDetails?.role?.type)
      ) {
        await fetchPendingActivity();
      }
    })();
  }, [sessionDetails]);

  return (
    <EntityViewHistoryContext.Provider value={{
      pendingActivityCount,
      fetchPendingActivity,
      newestSeenJournalEntryId,
      newestSeenJournalCommentId,
      journalEntryIsSeen,
      journalCommentIsSeen,
      checkAndRecordJournalEntrySeenStatus,
      checkAndRecordJournalCommentSeenStatus,
    }}>
      {props.children}
    </EntityViewHistoryContext.Provider>
  );
};
