import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {__, localizeText} from 'i18n/localize';
import {Config} from 'Config';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useSessionContext} from 'contexts/SessionContext';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {SkillInventory} from 'api/skills/SkillInventory';
import {GoalStatus} from 'api/goals/GoalStatus';
import {SaveAssessmentDto} from 'api/assessments/SaveAssessmentDto';
import {SkillStatus} from 'api/skills/SkillStatus.enum';
import {Category} from 'api/categories/Category';

/**
 * A modal for the user to edit their skill inventory
 */

export interface SkillInventoryInputModalProps {
  onCancel?: () => any,
  onComplete?: () => any,
}

export const SkillInventoryInputModal = (props: SkillInventoryInputModalProps) => {
  const logger = useLogger(SkillInventoryInputModal.name);
  const {sessionDetails} = useSessionContext();
  const {skillsService, assessmentsService} = useApiContext();
  const userName = sessionDetails?.selectedStudent?.name ?? sessionDetails?.identity?.name ?? '';

  const [skillInventories, setSkillInventories] = useState<SkillInventory[]>([]);
  const [selectedInventoryIndex, setSelectedInventoryIndex] = useState<number | undefined>(undefined);
  const [assessment, setAssessment] = useState<SaveAssessmentDto>({categories: []});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const selectedInventory = selectedInventoryIndex !== undefined ? skillInventories[selectedInventoryIndex] : undefined;

  const submit = async () => {
    setIsSubmitting(true);
    logger.debug('Saving skill inventory', assessment);
    const result = await assessmentsService.saveAssessment(assessment);
    logger.debug('Saved skill inventory', result);
    setIsSubmitting(false);
    props.onComplete?.();
  };

  const fetchSkillInventories = async () => {
    const fetchedInventories = await skillsService.fetchUserSkillInventory(
        sessionDetails?.selectedStudent?.id ?? sessionDetails?.identity?.id ?? -1,
        true,
    );
    setSkillInventories(fetchedInventories.inventories.map((inventory) => ({
      ...inventory,
      comment: '',
      newStates: [],
    })));
    logger.debug('Fetched skill inventory', fetchedInventories);
  };

  const getSkillState = (skillId: number): SkillStatus | undefined => {
    return assessment.categories
        ?.find((c) => c.id === selectedInventory?.topLevelCategory.id)
        ?.ilries
        ?.find((i) => i.id === skillId)
        ?.state;
  };

  const updateAssessment = (skillId: number, state: SkillStatus) => {
    setAssessment((current) => {
      const newCategories = [...current.categories];
      const category = current.categories.find(
          (cat) => cat.id === selectedInventory?.topLevelCategory.id,
      );
      const skill = category?.ilries.find(
          (i) => i.id === skillId,
      );
      if (skill) {
        skill.state = state;
      }
      return {categories: newCategories};
    });
  };

  const skillNeedsSupport = (skillId: number): boolean => {
    return assessment.categories
        ?.find((c) => c.id === selectedInventory?.topLevelCategory.id)
        ?.ilries
        ?.find((i) => i.id === skillId)
        ?.support ?? false;
  };

  const setSkillNeedsSupport = (skillId: number, needsSupport: boolean) => {
    setAssessment((current) => {
      const newCategories = [...current.categories];
      const category = current.categories.find(
          (cat) => cat.id === selectedInventory?.topLevelCategory.id,
      );
      const skill = category?.ilries.find(
          (i) => i.id === skillId,
      );
      if (skill) {
        skill.support = needsSupport;
      }
      return {categories: newCategories};
    });
  };

  const commentForCategory = (categoryId: number): string => {
    return assessment.categories
        ?.find((c) => c.id === categoryId)
        ?.comment ?? '';
  };

  const setCommentForCategory = (categoryId: number, newComment: string) => {
    setAssessment((current) => {
      const newCategories = [...current.categories];
      const category = current.categories.find((cat) => cat.id === categoryId);
      if (category) {
        category.comment = newComment;
      }
      return {categories: newCategories};
    });
  };

  useEffect(() => {
    if (!skillInventories) {
      setAssessment({categories: []});
    } else {
      const initialAssessment: SaveAssessmentDto = {
        categories: skillInventories.map((inventory) => {
          const skills: {id: number, name: string, category?: Category, state?: SkillStatus, support: boolean}[] = [];
          inventory.subCategories.forEach((category) => {
            category.skillsWithGoals.forEach((skillWithGoal) => {
              skills.push({
                id: skillWithGoal.skill.id,
                name: skillWithGoal.skill.name,
                category: skillWithGoal.skill.category,
                state: (skillWithGoal.skillGoal?.state as any as SkillStatus),
                support: skillWithGoal.skillGoal?.support ?? false,
              });
            });
          });

          return {
            id: inventory.topLevelCategory.id,
            comment: '',
            ilries: skills,
            name: inventory.topLevelCategory.name,
            color: inventory.topLevelCategory.color,
            icon: inventory.topLevelCategory.icon,
          };
        }),
      };
      setAssessment(initialAssessment);
    }
  }, [skillInventories]);

  useEffect(() => {
    (async () => {
      await fetchSkillInventories();
    })();
  }, []);

  if (isSubmitting) {
    return (
      <LoadingIndicator
        fullscreen={true}
        message={Config.defaultSavingMessage}
      />
    );
  }

  return (
    <Modal
      title={localizeText('Learning Path Skills Inventory')}
      onCloseRequested={props.onCancel}
      className={'wide tall'}
      bodyPadding={0}

      buttons={
        <Button variant={ButtonVariant.Confirm} onClick={submit} />
      }

      body={
        <>
          <div className="column-layout" style={{height: 600, position: 'relative'}}>
            <div className="left-column learningPath">
              <div className="view pane">
                <div className="head">
                  <span className="badge pull-right">{sessionDetails?.identity?.name}</span>
                  <div className="text">{__('Categories')}</div>
                </div>
                <div className="categories body">
                  {skillInventories.map((inventory, i) => (
                    <div
                      key={inventory.topLevelCategory.id}
                      className={classNames(
                          'category',
                          {'selected': selectedInventory?.topLevelCategory?.id === inventory.topLevelCategory.id},
                      )}
                      onClick={() => setSelectedInventoryIndex(i)}
                    >
                      <div className="circle-20" style={{borderColor: inventory.topLevelCategory.color}}>
                        <span className={classNames('icon', inventory.topLevelCategory.icon)} />
                      </div>
                      <div className="name">{inventory.topLevelCategory.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            className="right-column learningPath"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {!selectedInventory &&
            <div className="skills">
              <div className="pane with-inner-menu " style={{top: 0}}>
                <div className="body" style={{overflowY: 'auto'}}>
                  <div className="row-fluid">
                    <div className="pack" style={{textAlign: 'center'}}>
                      <div
                        className="goal"
                        style={{
                          cursor: 'initial',
                          height: 150,
                          width: '95%',
                          position: 'relative',
                          // eslint-disable-next-line max-len
                          background: 'linear-gradient(to bottom, rgba(175,175,175,0.9), rgba(175,175,175,0) 80%) #828282',
                          marginRight: 10,
                          borderRadius: '4px 20px',
                          margin: 10,
                          boxShadow: 'inset 0 2px 6px rgba(0,0,0,0.6)',
                        }}
                      >
                        <div
                          className="icon icon-help"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -40,
                            marginLeft: -32,
                            width: 38,
                            height: 38,
                            fontSize: 40,
                            lineHeight: '40px',
                            color: '#fff',
                            textAlign: 'center',
                            border: '5px solid white',
                            borderRadius: '50%',
                            padding: 10,
                            textShadow: '0 2px 6px rgba(0,0,0,0.6)',
                            boxShadow: '0 2px 6px rgba(0,0,0,0.6)',
                            background: 'transparent',
                          }}
                        />
                      </div>

                      <div style={{padding: '10px 0', fontFamily: 'triunity condensed', color: '#4B4B4B'}}>
                        <h4>{__('Please select a skill category to start inventory.')}</h4>
                        <p>To evaluate {userName}&rsquo;s Learning Path. Select a skills category on the left.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }

            {selectedInventory &&
            <div className="skills span12">
              <div className="category">
                <div className="circle-30" style={{borderColor: selectedInventory.topLevelCategory.color}}>
                  <span className={classNames('icon', selectedInventory.topLevelCategory.icon)} />
                </div>
                {selectedInventory.topLevelCategory.name}
                <div className="completePercent">{__('Evaluate Skills Below')}</div>
                <div className="key">
                  <i className="icon-ok-circled" style={{color: 'green'}} />
                  &nbsp;
                  <span>{__('l_Completed')}</span>
                  &nbsp;&nbsp;&nbsp;
                  <i className="icon-play-circled" style={{color: 'teal'}} />
                  &nbsp;
                  <span>{__('l_In Progress')}</span>
                  &nbsp;&nbsp;&nbsp;
                  <i className="icon-plus-circled" style={{color: 'purple'}} />
                  &nbsp;
                  <span>{__('l_Recommended')}</span>
                  &nbsp;&nbsp;&nbsp;
                  <i className="icon-cancel-circled" style={{color: 'red'}} />
                  &nbsp;
                  <span>{__('l_Not Applicable')}</span>
                  &nbsp;&nbsp;&nbsp;
                  <i className="icon-cog" style={{color: '#EA9D0E'}} />
                  &nbsp;
                  <span>{__('l_Needs Support')}</span>
                </div>
              </div>

              <div className="pane with-inner-menu ">
                <filter name="assessment" className="pull-right">
                  <i className="icon icon-search" />
                  <input
                    type="text"
                    placeholder={__('Search')}
                    className="form-control long"
                  />
                </filter>
                <div className="body" style={{overflowY: 'auto'}}>
                  <div className="row-fluid">
                    {selectedInventory.subCategories.map((category) => (
                      <div role="list" key={category.category.id}>
                        <div className="title">{category.key}</div>
                        <table width="100%" cellPadding={0}>
                          <tbody>
                            {category.skillsWithGoals.map((skillWithGoal) => (
                              <tr key={skillWithGoal.skill.id}>
                                <td colSpan={5}>
                                  <div className="span12">{skillWithGoal.skill.name}</div>
                                  <table width="100%">
                                    <tr>
                                      <td
                                        className={classNames(
                                            'text-center',
                                            {'active': skillWithGoal.skillGoal?.state === GoalStatus.Maintenance},
                                        )}
                                        style={{padding: 0}}
                                      />
                                      <td
                                        className={classNames(
                                            'text-center',
                                            {'active': getSkillState(skillWithGoal.skill.id) === SkillStatus.Completed},
                                        )}
                                        style={{padding: 0}}
                                        onClick={() => updateAssessment(skillWithGoal.skill.id, SkillStatus.Completed)}
                                      >
                                        <i className="icon-ok-circled" style={{color: 'green'}} />
                                      </td>
                                      <td
                                        className={classNames(
                                            'text-center',
                                            {'active':
                                                getSkillState(skillWithGoal.skill.id) === SkillStatus.InProgress,
                                            },
                                        )}
                                        style={{padding: 0}}
                                        onClick={() => updateAssessment(skillWithGoal.skill.id, SkillStatus.InProgress)}
                                      >
                                        <i className="icon-play-circled" style={{color: 'teal'}} />
                                      </td>
                                      <td
                                        className={classNames(
                                            'text-center',
                                            {'active':
                                                getSkillState(skillWithGoal.skill.id) === SkillStatus.Recommended,
                                            },
                                        )}
                                        style={{padding: 0}}
                                        onClick={
                                          () => updateAssessment(skillWithGoal.skill.id, SkillStatus.Recommended)
                                        }
                                      >
                                        <i className="icon-plus-circled" style={{color: 'purple'}} />
                                      </td>
                                      <td
                                        className={classNames(
                                            'text-center',
                                            {'active':
                                              getSkillState(skillWithGoal.skill.id) === SkillStatus.NonApplicable,
                                            },
                                        )}
                                        style={{padding: 0}}
                                        onClick={() => updateAssessment(
                                            skillWithGoal.skill.id,
                                            SkillStatus.NonApplicable,
                                        )}
                                      >
                                        <i className="icon-cancel-circled" style={{color: 'red'}} />
                                      </td>
                                      <td style={{padding: 0}}>
                                        <input
                                          type="checkbox"
                                          checked={skillNeedsSupport(skillWithGoal.skill.id)}
                                          onChange={(e) => setSkillNeedsSupport(
                                              skillWithGoal.skill.id,
                                              e.target.checked,
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                    <div className="comment">
                      <div className="title">{__('Comment')}</div>
                      <textarea
                        value={commentForCategory(selectedInventory.topLevelCategory.id)}
                        onChange={(e) => setCommentForCategory(selectedInventory.topLevelCategory.id, e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </>
      }
    />
  );
};
