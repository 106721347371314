import React, {useState} from 'react';
import {Challenge} from 'api/challenges/Challenge';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {FormattedDate} from 'components/FormattedDate';
import {useApiContext} from 'api/ApiContext';
import {confirmDelete} from 'components/confirmDelete';
import {ChallengeInputModal} from 'views/challenges/ChallengeInputModal';
import {ChallengeGoalType} from 'api/challenges/ChallengeGoalType.enum';
import {Tooltip} from 'components/Tooltip';

export interface ChallengeQuickViewProps {
  challenge: Challenge,
  onDeleted?: () => any,
}

export const ChallengeQuickView = (props: ChallengeQuickViewProps) => {
  const {challengesService} = useApiContext();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const toggleExpanded = () => setIsExpanded((current) => !current);

  const handleDelete = () => confirmDelete(async () => {
    await challengesService.deleteChallenge(props.challenge.id);
    props.onDeleted?.();
  });

  const handleEdit = () => setShowEdit(true);

  return (
    <div
      className={classNames(
          'list-item expandable',
          {'open': isExpanded},
      )}
    >
      {showEdit &&
        <ChallengeInputModal
          initialChallenge={props.challenge}
          onCloseRequested={() => setShowEdit(false)}
        />
      }

      <div className="head clearfix" onClick={toggleExpanded}>
        {props.challenge.name}

        <div className="buttons btn-group">
          <Tooltip title={__('Edit')}>
            <a
              className="btn btn-icon btn-mini"
              onClick={handleEdit}
            >
              <i className="icon-edit" />
            </a>
          </Tooltip>
          <Tooltip title={__('Delete')}>
            <a
              className="btn btn-icon btn-mini"
              onClick={handleDelete}
            >
              <i className="icon-trash" />
            </a>
          </Tooltip>
        </div>
      </div>

      <div className="body">
        <div>
          <span>{__('Active')}</span>:
          {' '}
          <i
            className={props.challenge.completed ?
              'icon-check' :
              'icon-check-empty'
            }
          />
        </div>
        <div>
          <span>{__('Complete')}</span>
        </div>:
        {' '}
        <i
          className={props.challenge.success ?
            'icon-check' :
            'icon-check-empty'
          }
        />
      </div>
      {props.challenge.goalType === ChallengeGoalType.CollectMax &&
        <div>
          <span>{__('Goal')}</span>: {props.challenge.goal}
        </div>
      }
      {props.challenge.goalType === ChallengeGoalType.CollectNumberGoals &&
        <div>
          <span>{__('Deadline')}</span>:
          {' '}
          {props.challenge.deadline &&
            <FormattedDate timestamp={props.challenge.deadline} />
          }
        </div>
      }
      <div>
        <span>{__('Collected')}</span>: {props.challenge.collected}
      </div>
    </div>
  );
};
