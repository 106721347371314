import {
  CreateJournalEntryDto,
  JournalEntry,
  JournalEntryWithRelations,
  UpdateJournalEntryDto,
} from 'api/journal/JournalEntry';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {Comment, CreateCommentDto, UpdateCommentDto} from 'api/journal/Comment';
import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {ListFilter} from 'api/lists/ListFilter';
import {EntityListService} from 'api/lists/EntityListService';
import {FilterCourse} from 'api/goals/FilterCourse';
import {FilterCategory} from 'api/goals/FilterCategory';
import {CreateSessionReportDto} from 'api/journal/SessionReport';

/**
 * Provides API journal functions
 */
export class JournalService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches journal entries
   *
   * @param {ListFilter} filter
   * @return {Promise<JournalEntryWithRelations[]>}
   */
  async fetchEntries(filter: ListFilter): Promise<JournalEntryWithRelations[]> {
    return this.entityListService.fetchEntityList(ApiRoute.JournalEntries, filter);
  }

  /**
   * Creates a new journal entry in the current user's journal
   *
   * @param {CreateJournalEntryDto} newEntry
   * @return {Promise<SuccessResponse>}
   */
  async createJournalEntry(newEntry: CreateJournalEntryDto): Promise<SuccessResponse<JournalEntry>> {
    const response = await this.httpClient.post(
        ApiRoute.JournalEntries + ApiRoute.Save,
        newEntry,
    );
    return response.data;
  }

  /**
   * Updates a journal entry
   *
   * @param {UpdateJournalEntryDto} entry
   */
  async updateJournalEntry(entry: UpdateJournalEntryDto): Promise<SuccessResponse<JournalEntry>> {
    const response = await this.httpClient.post(ApiRoute.JournalEntries + ApiRoute.Save, entry);
    return response.data;
  }

  /**
   * Creates a new journal comment
   *
   * @param {CreateCommentDto} newComment
   */
  async createJournalComment(newComment: CreateCommentDto): Promise<SuccessResponse<Comment>> {
    const response = await this.httpClient.post(
        ApiRoute.JournalComments + ApiRoute.Save,
        newComment,
    );
    return response.data;
  }

  /**
   * Creates a new journal comment
   *
   * @param {UpdateCommentDto} comment
   */
  async updateJournalComment(comment: UpdateCommentDto): Promise<SuccessResponse<Comment>> {
    const response = await this.httpClient.post(
        ApiRoute.JournalComments + ApiRoute.Save,
        comment,
    );
    return response.data;
  }

  /**
   * Fetches the filterable categories and courses for the current user's journal view
   */
  async fetchJournalFilter(): Promise<{courses: FilterCourse[], categories: FilterCategory[]}> {
    const response = await this.httpClient.post('/categories/journalFilter', {});
    return response.data;
  }

  /**
   * Creates a session report
   *
   * @param {CreateSessionReportDto} sessionReport
   */
  async createSessionReport(sessionReport: CreateSessionReportDto): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/activity/saveSessionReport', sessionReport);
    return response.data;
  }

  /**
   * Fetches activity relevant to the current user (eg. student activity for a teacher)
   *
   * @param {ListFilter} filter
   */
  async fetchActivity(filter: ListFilter): Promise<JournalEntryWithRelations[]> {
    const response = await this.httpClient.post(
        '/journal/entries/activity',
        this.entityListService.buildFilter(filter),
    );
    return response.data.list;
  }

  /**
   * Fetches pending activity for the current user
   */
  async fetchPendingActivity(): Promise<{count: number, list: JournalEntry[]}> {
    const response = await this.httpClient.post('/journal/entries/pending');
    return response.data;
  }

  /**
   * Fetches the filterable categories and courses for the current user's activity feed
   */
  async fetchActivityFilter(): Promise<{courses: FilterCourse[], categories: FilterCategory[]}> {
    const response = await this.httpClient.post('/categories/activityFilter', {});
    return response.data;
  }

  /**
   * Marks a journal entry as reviewed
   *
   * @param {JournalEntryWithRelations} entry
   * @param {string} reviewerName
   */
  async markJournalEntryReviewed(
      entry: JournalEntryWithRelations,
      reviewerName: string,
  ): Promise<SuccessResponse<JournalEntryWithRelations>> {
    const response = await this.httpClient.post('/journal/entries/save', {
      ...entry,
      text: `Item has been reviewed by ${reviewerName}`,
    });
    return response.data;
  }
}
