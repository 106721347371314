import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {useAuthContext} from 'contexts/AuthContext';
import {SkillGoal} from 'api/skills/SkillGoal';
import {GoalStatus} from 'api/goals/GoalStatus';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {__} from 'i18n/localize';
import {EditProfileModal} from 'views/profile/EditProfileModal';
import {Goal} from 'api/goals/Goal';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {SkillInventoryModal} from 'views/plan/SkillInventoryModal';
import {SkillInventoryInputModal} from 'views/plan/SkillInventoryInputModal';
import {StringParam, useQueryParam} from 'use-query-params';
import {PlanJoyRide} from 'views/plan/PlanJoyRide';
import {UserAvatar} from 'components/UserAvatar';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {Link} from 'react-router-dom';
import {confirmModal} from 'components/confirmModal';
import {SkillRelatedLessonsModal} from 'views/skills/SkillRelatedLessonsModal';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Config} from 'Config';

/**
 * A student's plan overview
 */
export const PlanOverviewView = () => {
  const logger = useLogger(PlanOverviewView.name);
  const {goalsService, skillsService} = useApiContext();
  const {currentUser} = useAuthContext();
  const {push} = useHistory();
  const {userIsSwitched, activeUser} = useUserSwitchingContext();
  const [showJoyRide] = useQueryParam('welcome', StringParam);

  const [skills, setSkills] = useState<SkillGoal[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [showExpandedGoals, setShowExpandedGoals] = useState<boolean>(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState<boolean>(false);
  const [showSkillInventory, setShowSkillInventory] = useState<boolean>(false);
  const [showSkillInventoryEdit, setShowSkillInventoryEdit] = useState<boolean>(false);
  const [showBuildGoal, setShowBuildGoal] = useState<boolean>(false);
  const [showRelatedLessonsSkill, setShowRelatedLessonsSkill] = useState<SkillGoal | undefined>(undefined);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const user = activeUser ?? currentUser;
  const userImageSrc = activeUser ? activeUser.image?.url : currentUser?.image;
  const activeGoals = goals.filter((goal) => goal.state === GoalStatus.Active);

  const completedSkills = skills.filter((skillGoal) =>
    skillGoal.state === GoalStatus.Completed &&
    skillGoal.goals.length > 0,
  );

  let newSkills: SkillGoal[] = [];
  const inProgressSkills: SkillGoal[] = [];
  skills.forEach((skillGoal) => {
    if (skillGoal.state === GoalStatus.InProgress || skillGoal.state === GoalStatus.Recommended) {
      if (skillGoal.goals.length < 1) {
        newSkills.push(skillGoal);
      } else {
        let found = false;
        skillGoal.goals.forEach((goal) => {
          inProgressSkills.push(skillGoal);
          if (goal.state === GoalStatus.InProgress || goal.state === GoalStatus.Active) {
            found = true;
          }
        });

        if (!found) {
          newSkills.push(skillGoal);
        }
      }
    }
  });
  newSkills = newSkills.sort((a, b) => a.state < b.state ? -1 : 1);

  const navigateToProgress = () => {
    if (userIsSwitched) {
      push(AppRoute.ProfileContext.replace(':profileId', activeUser?.id.toString() ?? '') + AppRoute.PlanHistory);
    } else {
      push(AppRoute.DashboardContext + AppRoute.PlanHistory);
    }
  };

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      setIsInitializing(true);
      const fetchedSkillGoals = await skillsService.fetchUserSkillGoals(user.id);
      setSkills(fetchedSkillGoals);
      logger.debug('Fetched skill goals', fetchedSkillGoals);

      const fetchedGoals = await goalsService.fetchUserGoals(user.name);
      setGoals(fetchedGoals);
      logger.debug('Fetched user goals', fetchedGoals);
      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return <LoadingIndicator/>;
  }

  return (
    <PageView bodyClassName={'planning'}>
      {showBuildGoal &&
        <GoalInputModal
          onCloseRequested={() => setShowBuildGoal(false)}
          onCompleted={() => setShowBuildGoal(false)}
        />
      }

      {showJoyRide &&
        <PlanJoyRide />
      }

      {showEditProfileModal &&
        <EditProfileModal
          onCloseRequested={() => setShowEditProfileModal(false)}
        />
      }

      {showSkillInventory &&
        <SkillInventoryModal
          onCancel={() => setShowSkillInventory(false)}
          onEditRequested={() => {
            setShowSkillInventory(false);
            setShowSkillInventoryEdit(true);
          }}
        />
      }

      {showSkillInventoryEdit &&
        <SkillInventoryInputModal
          onCancel={() => setShowSkillInventoryEdit(false)}
          onComplete={() => setShowSkillInventoryEdit(false)}
        />
      }

      {showRelatedLessonsSkill &&
        <SkillRelatedLessonsModal
          skillId={showRelatedLessonsSkill.ilri.id}
          onCloseRequested={() => setShowRelatedLessonsSkill(undefined)}
          assignOnLessonSelected={true}
        />
      }

      <div className="row-fluid">
        <div className="pack clearfix span12 gtky">
          <div className="span6 headline">
            <h1>What do you want to learn next?</h1>
          </div>
          <div className="span6 choices pull-right">
            {newSkills.length < 1 &&
              <AccessControlled
                feature={AppFeature.DashboardDreams}
                unauthorizedView={
                  <div
                    className="explore"
                    style={{
                      marginTop: 59,
                      fontSize: 15,
                      lineHeight: '26px',
                      padding: 14,
                    }}
                  >
                    <i
                      className="icon-i-mental-health"
                      style={{
                        fontSize: 38,
                        padding: 11,
                        paddingTop: 5,
                        float: 'right',
                      }}
                    />
                    {' '}No recommended skills yet.
                    {' '}Please talk to your coach or instructor.
                  </div>
                }
              >
                <Link to={AppRoute.DashboardContext + AppRoute.Dreams}>
                  <div
                    className="explore"
                    style={{
                      marginTop: 59,
                      fontSize: 15,
                      lineHeight: '26px',
                      padding: 14,
                    }}
                  >
                    <i
                      className="icon-i-mental-health"
                      style={{
                        fontSize: 38,
                        padding: 11,
                        paddingTop: 5,
                        float: 'right',
                      }}
                    />
                    {' '}
                    No recommended skills yet. Please complete Hopes & Dreams.
                  </div>
                </Link>
              </AccessControlled>
            }


            {newSkills.length > 0 &&
              newSkills.slice(0, showExpandedGoals ? 15 : 3).map((skill) => (
                <div
                  key={skill.id}
                  className="item category pointer"
                  onClick={() => {
                    if (newSkills.length > 2) {
                      confirmModal(
                          () => setShowRelatedLessonsSkill(skill),
                          __('Are you Sure?'),
                          __('You already have a few goals open. We recomend finishinig a few before making more'),
                      );
                    } else {
                      setShowRelatedLessonsSkill(skill);
                    }
                  }}
                >
                  <div className="circle" style={{backgroundColor: skill.ilri.category.color}}>
                    <span
                      className={`icon ${skill.ilri.category.icon ?? ''}`}
                    />
                  </div>
                  <div className="name">
                    {skillsService.transformSkillTitle(skill.name)}
                  </div>
                </div>
              ))
            }

            {showExpandedGoals &&
              <div className="explore" style={{marginBottom: 15}} onClick={() => setShowBuildGoal(true)}>
                Build Custom Goal
              </div>
            }
            {newSkills.length > 3 &&
              <div className="explore" onClick={() => setShowExpandedGoals((current) => !current)}>
                {showExpandedGoals ?
                  'Limit options' :
                  'Explore more choices'
                }
              </div>
            }
          </div>

        </div>
      </div>

      <div className="row-fluid whiteBox">
        <div className="stat span2">
          <div style={{margin: '11px 0 26px'}}>
            <UserAvatar src={userImageSrc ?? Config.defaultUserImage} size={105} />
          </div>
          <div className="section">
            {user?.name}
          </div>
          {!userIsSwitched &&
            <div className="description pointer" onClick={() => setShowEditProfileModal(true)}>
              {__('Update Profile')}
            </div>
          }
        </div>

        <div className="stat span3">
          <div className="buigNum">{activeGoals.length}</div>
          <div className="section">{__('Active Goals')}</div>
          <div className="description">{__('We suggest no more than 3')}</div>
        </div>

        <div className="stat span3">
          <div className="buigNum">{completedSkills.length}</div>
          <div className="section">{__('Skills Earned')}</div>
        </div>

        <div className="stat span4">
          <div className="skillHold">
            {inProgressSkills.map((skill, i) => (
              <div
                key={i}
                className="completedSkills"
              >
                <div
                  className="small circle"
                  style={{backgroundColor: skill.ilri.category.color}}
                >
                  <span
                    className={`icon ${skill.ilri.category.icon ?? ''}`}
                  />
                </div>
                <div className="name">
                  {skill.name}
                </div>
              </div>
            ))}
          </div>
          <div className="section">{__('Skills I\'m working on')}</div>
        </div>
      </div>

      <AccessControlled feature={AppFeature.DashboardDreams}>
        <div className="lowerButton" onClick={() => push(AppRoute.DashboardContext + AppRoute.Dreams)}>
          {__('Hopes & Dreams')}
        </div>
      </AccessControlled>

      <div className="lowerButton" onClick={() => setShowSkillInventory(true)}>
        {__('Skills Inventory')}
      </div>
      <div className="lowerButton" onClick={navigateToProgress}>
        {__('Progress')}
      </div>
    </PageView>
  );
};
