export enum ActionStepType {
  User = 'USER',
  Course = 'COURSE',
  Syllabus = 'SYLLABUS',
  Lesson = 'LESSON',
  UserGoal = 'USER_GOAL',
  UnitGoal = 'UNIT_GOAL',
  SyllabusGoal = 'SYLLABUS_GOAL',
  ScheduleGoal = 'SCHEDULE_GOAL',
}
