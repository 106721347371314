import React, {ReactElement, useEffect, useRef} from 'react';
import {Modal} from 'components/Modal';
import {useReactToPrint} from 'react-to-print';
import {Button, ButtonVariant} from 'components/buttons/Button';
import styleClasses from './PrintModal.module.scss';

/**
 * A modal that will print its contents on mount and present a print button to the user
 */

export interface PrintModalProps {
  view: ReactElement,
  onCloseRequested?: () => any,
}

export const PrintModal = (props: PrintModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
  });

  useEffect(() => {
    setTimeout(
        () => handlePrint?.(),
        200,
    );
  }, []);

  return <Modal
    title={'Print'}
    onCloseRequested={props.onCloseRequested}
    className={'wide tall'}

    buttons={
      <Button
        variant={ButtonVariant.Print}
        onClick={handlePrint}
      />
    }

    body={
      <div
        ref={containerRef}
        className={styleClasses.printContainer}
      >
        <div className={'print'}>
          {props.view}
        </div>
      </div>
    }
  />;
};
