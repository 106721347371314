import {AxiosInstance} from 'axios';
import {CommunityItem, CreateCommunityItemDto, UpdateCommunityItemDto} from 'api/community/CommunityItem';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API community items functions
 */
export class CommunityItemsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Creates a new community item ("network entry")
   *
   * @param {CreateCommunityItemDto} item
   * @return {Promise<SuccessResponse<CommunityItem>>}
   */
  async createCommunityItem(item: CreateCommunityItemDto): Promise<SuccessResponse<CommunityItem>> {
    const response = await this.httpClient.post('/community/items/save', item);
    return response.data;
  }

  /**
   * Updates a community item
   *
   * @param {UpdateCommunityItemDto} item
   * @return {Promise<SuccessResponse<CommunityItem>>}
   */
  async updateCommunityItem(item: UpdateCommunityItemDto): Promise<SuccessResponse<CommunityItem>> {
    const response = await this.httpClient.post('/community/items/save', item);
    return response.data;
  }

  /**
   * Deletes a community item
   *
   * @param {number} id
   * @return {Promise<SuccessResponse<undefined>>}
   */
  async deleteCommunityItem(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/community/items/remove', {id});
    return response.data;
  }
}
