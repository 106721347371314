import React, {PropsWithChildren, useState} from 'react';
import {useSchoolContext} from 'contexts/SchoolContext';
import {Modal} from '@material-ui/core';
import {School} from 'api/schools/School';
import {Tooltip} from 'components/Tooltip';
import {__} from 'i18n/localize';

/**
 * A modal for the user to select a school out of currently authorized schools
 */

export interface SchoolSelectModalProps {
  onSelected?: (school: School | undefined) => any;
  onCancel?: () => any;
}

export const SchoolSelectModal = (
    props: PropsWithChildren<SchoolSelectModalProps>,
) => {
  const {authorizedSchools} = useSchoolContext();
  const [selectedSchoolId, setSelectedSchoolId] =
    useState<number | undefined>(undefined);

  return (
    <Modal open={true} onClose={props.onCancel}>
      <div className={'modal'}>
        <div className="modal-header">
          <div className="buttons btn-group">
            <Tooltip title={__('Save')}>
              <a
                className="btn btn-success"
                onClick={() => {
                  const school = authorizedSchools.find((school) =>
                    school.id === selectedSchoolId,
                  );
                  props.onSelected?.(school);
                }}
                aria-label="Save"
              >
                <i className="icon-ok-circled"/>
              </a>
            </Tooltip>
            <a
              className="btn btn-default"
              onClick={props.onCancel}
            >
              <i className="icon-cancel"/>
            </a>
          </div>

          <span className="title">
            <h3>Choose Campus</h3>
          </span>
        </div>
        <div
          className="modal-body"
          style={{minHeight: 500}}
        >
          <form name="form.edit">
            <div className="row-fluid">
              <label htmlFor={'school-select'} style={{display: 'none'}}>
                School
              </label>
              <select
                id={'school-select'}
                className="span-12"
                style={{marginLeft: 0}}
                value={selectedSchoolId}
                onChange={(e) => setSelectedSchoolId(parseInt(e.target.value))}
              >
                <option value={''}>-- System Level --</option>
                {authorizedSchools.map((school) => (
                  <option value={school.id} key={school.id}>
                    {school.name}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <div className="placeholder-info"/>
        </div>
      </div>
    </Modal>
  );
};
