import React, {PropsWithChildren, ReactElement} from 'react';
import {MainHeader} from 'layout/MainHeader';
import {MainMenu} from 'layout/menus/MainMenu';
import {useLocation} from 'react-router-dom';
import {SectionMenu} from 'layout/menus/SectionMenu';
import {__} from 'i18n/localize';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';

export interface MainLayoutProps {
  header?: ReactElement,
}

export const MainLayout = (props: PropsWithChildren<MainLayoutProps>) => {
  const {pathname} = useLocation();
  const {userIsSwitched} = useUserSwitchingContext();

  const routeParts = pathname.split('/');
  const section = routeParts[routeParts.length - 2];
  const page = section === 'students' ?
    'students' :
    routeParts[routeParts.length - 1];

  let pageTitle = '';
  let pageIconCode = '';
  switch (page) {
    case 'configure':
      pageTitle = __('Configure');
      pageIconCode = 'icon-users';
      break;
    case 'collage_library':
      pageTitle = __('Collage Library');
      pageIconCode = 'icon-suitcase';
      break;
    case 'instructors':
      pageTitle = __('Instructors');
      pageIconCode = 'icon-chart-line';
      break;
    case 'syllabi':
      pageTitle = __('Syllabi');
      pageIconCode = 'icon-users';
      break;
    case 'community':
      pageTitle = __('Networks & Resources');
      pageIconCode ='icon-phone';
      break;
    case 'journal':
      pageTitle = __('Communications Journal');
      pageIconCode ='icon-chat';
      break;
    case 'goals':
      pageTitle = __('Goals & Assignments');
      pageIconCode ='icon-book';
      break;
    case 'system':
      pageTitle = __('Platform Overview');
      pageIconCode ='icon-compass';
      break;
    case 'overview':
      pageTitle = __('Next Steps & Planning');
      pageIconCode ='icon-compass';
      break;
    case 'dreams':
      pageTitle = __('Hopes & Dreams');
      pageIconCode ='icon-i-mental-health';
      break;
    case 'lessons':
      pageTitle = __('Lessons');
      pageIconCode ='icon-docs';
      break;
    case 'activity':
      pageTitle = __('Activity Log');
      pageIconCode ='icon-chat';
      break;
    case 'teaching':
      pageTitle = __('Modules');
      pageIconCode ='icon-users';
      break;
    case 'courses':
      pageTitle = __('Modules');
      pageIconCode ='icon-users';
      break;
    case 'students':
      pageTitle = __('Participants');
      pageIconCode ='icon-user';
      break;
    case 'quizzes':
      pageTitle = __('Learning Checks');
      pageIconCode ='icon-pencil';
      break;
    case 'ilri':
      pageTitle = __('Skills');
      pageIconCode ='icon-sun';
      break;
    case 'stats':
      pageTitle = __('Overview');
      pageIconCode ='icon-chart-line';
      break;
    case 'users':
      pageTitle = __('Users');
      pageIconCode ='icon-users';
      break;
    case 'editor':
      pageTitle = __('Content Editor');
      pageIconCode = 'icon-folder-open';
      break;
    case 'categories':
      pageTitle = __('Categories');
      pageIconCode = 'icon-list-alt';
      break;
    case 'versions':
      pageTitle = __('Versions');
      pageIconCode ='icon-sitemap';
      break;
    case 'surveys':
      pageTitle = __('Onboarding Surveys');
      pageIconCode ='icon-i-neurology';
      break;
    case 'achievements':
      pageTitle = __('Badges');
      pageIconCode ='icon-award';
      break;
    case 'products':
      pageTitle = __('Products');
      pageIconCode ='icon-suitcase';
      break;
    case 'schools':
      pageTitle = __('Campus');
      pageIconCode ='icon-graduation-cap';
      break;
    case 'history':
      pageTitle = __('Progress');
      pageIconCode ='icon-chart-line';
      break;
    case 'lessonedit':
      pageTitle = __('Lesson editor');
      pageIconCode ='icon-chart-line';
      break;
  }

  return (
    <>
      {props.header ??
        <MainHeader
          pageTitle={pageTitle}
          pageIconCode={pageIconCode}
          menu={<MainMenu/>}
        />
      }

      <div id="content" className="view animate">
        {props.children}
      </div>

      {!userIsSwitched && <SectionMenu/>}
    </>
  );
};
