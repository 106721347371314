import {User} from 'api/users/User';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {EntityReference} from 'api/entities/EntityReference';

export interface CommunityItem {
  address?: string,
  author?: User,
  authorId?: number,
  category?: string,
  cdate: number,
  city: string,
  deleted: boolean,
  email: string,
  id: number,
  image?: FileUpload,
  mdate: number,
  name: string,
  owner: User,
  ownerId: number,
  phone?: string,
  relationship?: string,
  state?: string,
  type?: CommunityItemType,
  zip?: string,
  url?: string,
  contact?: string,
}

export enum CommunityItemType {
  Person = 'PERSON',
  Place = 'PLACE',
}

export interface CreateCommunityItemDto {
  type: CommunityItemType,
  name: string,
  phone?: string,
  email?: string,
  category?: string,
  relationship?: string,
  address?: string,
  city?: string,
  state?: string,
  zip?: string,
  url?: string,
  contact?: string,
  image?: EntityReference,
}

export interface UpdateCommunityItemDto extends CreateCommunityItemDto {
  id: number,
}
