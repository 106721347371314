import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {MovieAddResult} from 'api/file-uploads/MovieAddResult';

/**
 * Provides API file upload functions
 */
export class FileUploadsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches user files
   */
  async fetchFiles() {
    const response = await this.httpClient.post(ApiRoute.Files + ApiRoute.List);
    return response.data;
  }

  /**
   * Initiates an upload and returns the URL to which the upload should be sent
   */
  async getFileUploadUrl(): Promise<string> {
    const response = await this.httpClient.post('/upload/files/prepare');
    return response.data.url;
  }

  /**
   * Uploads a file. The URL must be fetched with the getFileUploadUrl first.
   *
   * An onProgress callback may be provided to allow progress indicators to users.
   *
   * @param {string} url
   * @param {File} file
   * @param {callback} onProgress
   */
  async uploadFile(url: string, file: File, onProgress?: (e: ProgressEvent) => any) {
    const formData = new FormData();
    formData.append('file', file);
    await this.httpClient.post(url, formData, {
      onUploadProgress: onProgress,
    });
  }

  /**
   * Fetches the status of the most recently initiated file upload
   */
  async fetchUploadStatus(): Promise<{ok: boolean, data: FileUpload}> {
    const response = await this.httpClient.post('/upload/files/status');
    return response.data;
  }

  /**
   * Adds a new Vimeo video to the API
   *
   * @param {string} name
   * @param {string} url
   */
  async addVimeoVideo(name: string, url: string): Promise<MovieAddResult> {
    const response = await this.httpClient.post('/upload/movies/addVimeo', {name, url});
    return response.data.data;
  }

  /**
   * Adds a new video link to the API
   *
   * @param {string} url
   */
  async addVideoLink(url: string): Promise<MovieAddResult> {
    const response = await this.httpClient.post('/upload/movies/addLink', {url});
    return response.data.data;
  }
}
