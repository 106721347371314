import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {MainLayout} from 'layout/MainLayout';
import {UserSwitchedRouter} from './UserSwitchedRouter';
import {PageNotFoundView} from 'views/errors/PageNotFoundView';
import {SchoolRouter} from 'routing/SchoolRouter';
import {SystemRouter} from 'routing/SystemRouter';
import {DashboardRouter} from 'routing/DashboardRouter';
import {LoginView} from 'views/LoginView';
import {RegisterView} from 'views/RegisterView';
import {HomePageRedirect} from 'routing/HomePageRedirect';

export const AppRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={AppRoute.Login} component={LoginView} />
        <Route exact path={AppRoute.Register} component={RegisterView} />

        <MainLayout>
          <Route exact path={AppRoute.Home} component={HomePageRedirect}/>
          <Route path={AppRoute.DashboardContext} component={DashboardRouter} />
          <Route path={AppRoute.SchoolContext} component={SchoolRouter} />
          <Route path={AppRoute.ProfileContext} component={UserSwitchedRouter} />
          <Route path={AppRoute.SystemContext} component={SystemRouter} />
        </MainLayout>

        <Route path={'*'} component={PageNotFoundView} />
      </Switch>
    </>
  );
};
