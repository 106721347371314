import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useApiContext} from 'api/ApiContext';
import {Appointment} from 'api/appointments/Appointment';
import {useLogger} from 'logging/logging';
import {DataGrid} from 'components/DataGrid';
import {FormattedDate} from 'components/FormattedDate';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {confirmDelete} from 'components/confirmDelete';
import {AppointmentInputModal} from 'views/appointments/AppointmentInputModal';
import {LoadingIndicator} from 'components/LoadingIndicator';

/**
 * Displays appointments scheduled for a given course
 */

export interface AppointmentsListModalProps {
  courseId?: number,
  studentId?: number,
  onCloseRequested?: () => any,
}

export const AppointmentsListModal = (props: AppointmentsListModalProps) => {
  const logger = useLogger(AppointmentsListModal.name);
  const {appointmentsService} = useApiContext();
  const {courseId, studentId} = props;

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [editingAppointment, setEditingAppointment] =
    useState<Appointment | undefined>(undefined);

  const loadAppointments = async () => {
    let fetchedAppointments: Appointment[] = [];
    if (courseId) {
      fetchedAppointments = await appointmentsService.fetchAppointmentsForCourse(courseId);
    } else if (studentId) {
      fetchedAppointments = await appointmentsService.fetchAppointmentsForStudent(studentId);
    }
    setAppointments(fetchedAppointments);
    logger.debug('Fetched appointments', fetchedAppointments);
  };

  const deleteAppointment = (id: number) => {
    confirmDelete(async () => {
      await appointmentsService.deleteAppointment(id);
      await loadAppointments();
    });
  };

  const addAppointment = () => setEditingAppointment({} as Appointment);
  const editAppointment = (appointment: Appointment) =>
    setEditingAppointment(appointment);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      await loadAppointments();
      setIsFetching(false);
    })();
  }, [courseId]);

  return <Modal
    title={__('Meetings')}
    onCloseRequested={props.onCloseRequested}
    className={'wide tall'}

    buttons={
      <Button
        variant={ButtonVariant.Add}
        onClick={addAppointment}
      />
    }

    body={
      <>
        {editingAppointment &&
          <AppointmentInputModal
            initialAppointment={editingAppointment}
            courseId={courseId}
            studentId={studentId}
            onCloseRequested={() => setEditingAppointment(undefined)}
            onComplete={async () => {
              await loadAppointments();
              setEditingAppointment(undefined);
            }}
          />
        }

        <DataGrid
          header={
            <tr>
              <td>{__('Title')}</td>
              <td>{__('Link')}</td>
              <td width={30}>{__('Date')}</td>
              <td width={35}/>
            </tr>
          }
          body={
            <>
              {isFetching &&
                <tr>
                  <td colSpan={4}>
                    <LoadingIndicator background={'none'}/>
                  </td>
                </tr>
              }

              {!isFetching && appointments.map((appointment) => (
                <tr key={appointment.id}>
                  <td>{appointment.name}</td>
                  <td>{appointment.link}</td>
                  <td>
                    <FormattedDate
                      timestamp={appointment.start}
                      format={'MM/DD/YYYY'}
                    />
                  </td>
                  <td>
                    <ButtonGroup>
                      <Button
                        variant={ButtonVariant.Edit}
                        className={'btn-mini'}
                        onClick={() => editAppointment(appointment)}
                      />
                      <Button
                        variant={ButtonVariant.Delete}
                        className={'btn-mini'}
                        onClick={() => deleteAppointment(appointment.id)}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </>
    }
  />;
};
