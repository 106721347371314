import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {SessionDetails} from 'api/sessions/SessionDetails';
import {AppInitializingView} from 'views/AppInitializingView';
import {HashRouter, Route} from 'react-router-dom';
import {NotificationContextProvider} from 'contexts/NotificationContext';
import {SessionContextProvider} from 'contexts/SessionContext';
import {AuthContextProvider} from 'contexts/AuthContext';
import {App} from 'App';
import {useLogger} from 'logging/logging';
import {SchoolContextProvider} from 'contexts/SchoolContext';
import {ThemeContextProvider} from 'contexts/ThemeContext';
import {QueryParamProvider} from 'use-query-params';
import {ServiceUnavailableView} from 'views/errors/ServiceUnavailableView';
import {UserSwitchingContextProvider} from 'contexts/UserSwitchingContext';
import 'styles/bootstrap.min.css';
import 'styles/font-awesome.min.css';
import 'styles/app.css';
import {SessionService} from 'api/sessions/SessionService';
import axios from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {ApiContextProvider} from 'api/ApiContext';
import {EntityViewHistoryContextProvider} from 'contexts/EntityViewHistoryContext';
import {AccessControlContextProvider} from 'access-control/AccessControlContext';
import {AnalyticsService} from 'analytics/AnalyticsService';

/**
 * Bootstraps the app and sets up context providers
 */

export interface AppBootstrapperProps {
  app?: ReactElement,
  initializingView?: ReactElement,
  serviceUnavailableView?: ReactElement,
  fetchSessionDetailsFunc?: () => Promise<SessionDetails | undefined>,
}

export const AppBootstrapper = (props: AppBootstrapperProps) => {
  const logger = useLogger(AppBootstrapper.name);
  const sessionService = useMemo<SessionService>(
      () => new SessionService(axios.create({baseURL: ApiRoute.BaseUrl})),
      [],
  );

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [sessionDetails, setSessionDetails] = useState<SessionDetails | undefined>(undefined);
  const [errorFetchingSession, setErrorFetchingSession] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      logger.info('My Full Life: v2.3.1');
      setIsInitializing(true);

      AnalyticsService.default().initialize();

      try {
        const sessionDetails = props.fetchSessionDetailsFunc ?
          await props.fetchSessionDetailsFunc() :
          await sessionService.fetchSessionDetails();
        logger.debug('Fetched session details', sessionDetails);
        setSessionDetails(sessionDetails);
        logger.info('Bootstrapping complete');
      } catch (e) {
        setErrorFetchingSession(true);
        logger.error('Error fetching session details', e);
      }

      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return props.initializingView ?? <AppInitializingView />;
  }

  if (errorFetchingSession) {
    return props.serviceUnavailableView ?? <ServiceUnavailableView />;
  }

  return (
    <HashRouter>

      {/* records analytics page views for all routes */}
      <Route
        path="/"
        render={({location}) => {
          AnalyticsService.default().recordPageView(location.pathname + location.search);
          return null;
        }}
      />

      <AccessControlContextProvider>
        <QueryParamProvider ReactRouterRoute={Route}>
          <NotificationContextProvider>
            <ApiContextProvider>
              <SessionContextProvider initialSessionDetails={sessionDetails}>
                <AuthContextProvider>
                  <EntityViewHistoryContextProvider>
                    <SchoolContextProvider>
                      <UserSwitchingContextProvider>
                        <ThemeContextProvider>
                          {props.app ?? <App />}
                        </ThemeContextProvider>
                      </UserSwitchingContextProvider>
                    </SchoolContextProvider>
                  </EntityViewHistoryContextProvider>
                </AuthContextProvider>
              </SessionContextProvider>
            </ApiContextProvider>
          </NotificationContextProvider>
        </QueryParamProvider>
      </AccessControlContextProvider>
    </HashRouter>
  );
};
