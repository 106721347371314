import React, {ReactElement, useEffect, useState} from 'react';
import {SwitchInput} from 'components/SwitchInput';
import {PageView} from 'layout/PageView';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {
  UpdateUserNotificationsPreferencesDto,
  UserProfile,
} from 'api/users/UserProfile';
import {useLogger} from 'logging/logging';
import {ContextMenu} from 'components/ContextMenu';
import classNames from 'classnames';
import {useNotificationContext} from 'contexts/NotificationContext';

/**
 * The user email notifications settings view
 */

export interface NotificationsSettingsViewProps {
  loadingView?: ReactElement,
}

export const NotificationsSettingsView = (
    props: NotificationsSettingsViewProps,
) => {
  const {showSuccessNotification} = useNotificationContext();
  const {usersService} = useApiContext();
  const logger = useLogger(NotificationsSettingsView.name);

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userProfile, setUserProfile] =
    useState<UserProfile | undefined>(undefined);
  const [notifications, setNotifications] =
    useState<UpdateUserNotificationsPreferencesDto>({
      achievementComplete: false,
      challengeComplete: false,
      courseComunication: false,
      goalComplete: false,
      newGoal: false,
      skillComplete: false,
      stepComplete: false,
      stepExpired: false,
      stepToExpire: false,
      studentAchievementComplete: false,
      studentChallengeComplete: false,
      studentGoalComplete: false,
      studentSkillComplete: false,
      studentStepComplete: false,
    });

  let allNotificationsOn = true;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_, value] of Object.entries(notifications)) {
    if (!value) {
      allNotificationsOn = false;
    }
  }

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      try {
        const fetchedUserProfile = await usersService.fetchUserProfile();
        logger.debug('Fetched user profile', fetchedUserProfile);
        setUserProfile(fetchedUserProfile);
      } catch (e) {
        logger.error('Error fetching user profile', e);
      }
      setIsFetching(false);
    })();
  }, []);

  useEffect(() => {
    if (userProfile?.notifications) {
      setNotifications(userProfile.notifications);
    }
  }, [userProfile]);

  const saveProfile = async () => {
    if (!userProfile) {
      return;
    }

    setIsSaving(true);
    try {
      const saveResult = await usersService.updateUserNotifications(notifications);
      showSuccessNotification(
          'Success',
          'Notifications configuration was successfully saved.',
      );
      logger.debug('Saved notifications settings', saveResult);
    } catch (e) {
      logger.error('Error saving notifications settings', e);
    }
    setIsSaving(false);
  };

  if (isFetching) {
    return props.loadingView ?? (
      <div style={{marginTop: 40}}>
        <LoadingIndicator message={'Loading settings...'}/>
      </div>
    );
  }

  return (
    <PageView>
      <ContextMenu>
        <button
          aria-label="Save settings"
          title={'Save settings'}
          disabled={isSaving}
          style={{outline: 'none'}}
          className={classNames(
              'btn',
              'btn-success',
              {disabled: isSaving},
          )}
          onClick={saveProfile}
        >
          <i className="icon-ok-circled"/>
        </button>
      </ContextMenu>

      <div className="wrap spacer">
        <table className="list spacer">
          <thead>
            <tr>
              <td colSpan={2}>Email Notifications</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="40">
                <SwitchInput
                  on={allNotificationsOn}
                  onChange={(nowOn) => {
                    setNotifications({
                      achievementComplete: nowOn,
                      challengeComplete: nowOn,
                      courseComunication: nowOn,
                      goalComplete: nowOn,
                      newGoal: nowOn,
                      skillComplete: nowOn,
                      stepComplete: nowOn,
                      stepExpired: nowOn,
                      stepToExpire: nowOn,
                      studentAchievementComplete: nowOn,
                      studentChallengeComplete: nowOn,
                      studentGoalComplete: nowOn,
                      studentSkillComplete: nowOn,
                      studentStepComplete: nowOn,
                    });
                  }}
                />
              </td>
              <td>All Notifications</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="wrap spacer">
        <table className="list">
          <thead>
            <tr>
              <td colSpan={2}>Personal Notifications</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="40">
                <SwitchInput
                  on={notifications.courseComunication ?? false}
                  label="Course notifications"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      courseComunication: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Course notifications</span></td>
            </tr>
            <tr>
              <td width="40">
                <SwitchInput
                  on={notifications.newGoal ?? false}
                  label="New goal"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      newGoal: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>New goal</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.goalComplete ?? false}
                  label="Completed goal"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      goalComplete: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Completed goal</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.skillComplete ?? false}
                  label="Completed skill"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      skillComplete: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Completed skill</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.stepComplete ?? false}
                  label="Completed action step"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      stepComplete: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Completed action step</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.challengeComplete ?? false}
                  label="Completed challenge"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      challengeComplete: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Completed challenge</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.achievementComplete ?? false}
                  label="Completed badge"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      achievementComplete: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Completed badge</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.stepToExpire ?? false}
                  label="Expiring action steps"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      stepToExpire: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Expiring action steps</span></td>
            </tr>
            <tr>
              <td>
                <SwitchInput
                  on={notifications.stepExpired ?? false}
                  label="Expired action steps"
                  onChange={(nowOn) => {
                    setNotifications((current) => ({
                      ...current,
                      stepExpired: nowOn,
                    }));
                  }}
                />
              </td>
              <td><span>Expired action steps</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </PageView>
  );
};
