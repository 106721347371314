import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {AccessControlled} from 'access-control/AccessControlled';
import {UserRoleType} from 'api/users/UserRole';
import {localizeText} from 'i18n/localize';
import {LessonWithRelations} from 'api/lessons/Lesson';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import classNames from 'classnames';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {Skill} from 'api/skills/Skill';
import {SkillDetailsModal} from 'views/skills/SkillDetailsModal';
import {PrintModal} from 'components/PrintModal';
import {PrintLessonView} from 'views/lessons/print/PrintLessonView';
import {Tooltip} from 'components/Tooltip';
import {AssignLessonGoalModal} from 'views/lessons/AssignLessonGoalModal';
import {Resource} from 'api/resources/Resource';
import {ResourceView} from 'views/resources/ResourceView';
import {LessonPlan} from 'api/lessons/LessonPlan';
import {LessonPlanPrintView} from 'views/lessons/print/LessonPlanPrintView';

export interface LessonDetailsModalProps {
  onCloseRequested?: () => any,
  lessonId: number,
}

export const LessonDetailsModal = (props: LessonDetailsModalProps) => {
  const logger = useLogger(LessonDetailsModal.name);
  const {lessonsService} = useApiContext();

  const [lesson, setLesson] = useState<LessonWithRelations | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [showDetailsSkill, setShowDetailsSkill] = useState<Skill | undefined>(undefined);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [showPrintLessonPlan, setShowPrintLessonPlan] = useState<LessonPlan | undefined>(undefined);
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [shownResource, setShownResource] = useState<Resource | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const fetchedLesson = await lessonsService.fetchLesson(props.lessonId);
      setLesson(fetchedLesson);
      logger.debug('Fetched lesson', fetchedLesson);
      setIsFetching(false);
    })();
  }, []);

  if (isFetching) {
    return <></>;
  }

  if (!isFetching && lesson === undefined) {
    return <Modal
      onCloseRequested={props.onCloseRequested}
      body={<p>Failed to load lesson.</p>}
    />;
  }

  return (
    <Modal
      onCloseRequested={props.onCloseRequested}
      className={'wide tall lesson-view'}
      title={
        <>
          {lesson?.name}
          {' '}
          <span
            className="category badge"
            style={{borderColor: lesson?.category?.color}}
          >
            {lesson?.category?.name}
          </span>

          {lesson?.version &&
          <span
            className="version badge"
            style={{borderColor: lesson.version.color}}
          >
            {lesson.version.name}
          </span>
          }
        </>
      }

      buttons={
        <>
          {showDetailsSkill &&
            <SkillDetailsModal
              skill={showDetailsSkill}
              onCloseRequested={() => setShowDetailsSkill(undefined)}
              aria-label="skills detail"
            />
          }

          {showPrint &&
            <PrintModal
              view={<PrintLessonView lesson={lesson!}/>}
              onCloseRequested={() => setShowPrint(false)}
              aria-label="print dialog"
            />
          }

          {showPrintLessonPlan &&
            <PrintModal
              view={<LessonPlanPrintView lessonPlan={showPrintLessonPlan} />}
              onCloseRequested={() => setShowPrintLessonPlan(undefined)}
              aria-label={'print lesson plan dialog'}
            />
          }

          {showAssign && lesson &&
            <AssignLessonGoalModal
              lesson={lesson}
              onCloseRequested={() => setShowAssign(false)}
            />
          }

          {shownResource &&
            <ResourceView
              resource={shownResource}
              onCloseRequested={() => setShownResource(undefined)}
            />
          }

          <Button
            text={'Print'}
            variant={ButtonVariant.Print}
            className={'btn-default'}
            onClick={() => setShowPrint(true)}
          />
          <AccessControlled
            roles={[UserRoleType.Student, UserRoleType.Teacher]}
          >
            <Button
              variant={ButtonVariant.Assign}
              iconCode={'icon-paper-plane'}
              className={'btn-info'}
              onClick={() => setShowAssign(true)}
            />
          </AccessControlled>
        </>
      }

      body={
        <>
          <h4>{localizeText('Lesson Description')}</h4>
          <div className="description spacer">
            <div
              dangerouslySetInnerHTML={{__html: lesson?.text ?? ''}}
            />
          </div>

          <h4>{localizeText('Action Steps')}</h4>
          {lesson?.steps.map((step, stepIndex) => (
            <div
              key={step.id}
              className={classNames(
                  'row-fluid steps',
                  {odd: stepIndex % 2 === 0},
                  {inactive: !step.enabled},
              )}
            >
              <div className="span1 text-center name">
                {step.enabled &&
                <div style={{color: 'forestgreen', fontSize: 10}}>
                  Active
                </div>
                }
              </div>
              <div className="span7 name">{step.title}</div>
              <div className="span2">
                {step.resources.map((resource) => {
                  let resourceIconCode = '';
                  let resourceTypeName = '';
                  switch (resource.type) {
                    case ResourceType.Movie:
                      resourceIconCode = 'icon-youtube-play';
                      resourceTypeName = 'Video';
                      break;
                    case ResourceType.Image:
                      resourceIconCode = 'icon-picture';
                      resourceTypeName = 'Video';
                      break;
                    case ResourceType.Quiz:
                      resourceIconCode = 'icon-pencil';
                      resourceTypeName = 'Learning Check';
                      break;
                    case ResourceType.File:
                      resourceIconCode = 'icon-doc-text';
                      resourceTypeName = 'File';
                      break;
                    case ResourceType.Link:
                      resourceIconCode = 'icon-link';
                      resourceTypeName = 'Link';
                      break;
                    case ResourceType.Collage:
                      resourceIconCode = 'icon-th-large';
                      resourceTypeName = 'Collage';
                      break;
                  }

                  return (
                    <Tooltip title={resource.name} key={resource.id}>
                      <div
                        className="pill pointer"
                        aria-label="View resource"
                        // TODO: show resource? original frontend does not look to open resources here
                        // onClick={() => setShownResource(resource)}
                      >
                        <i className={resourceIconCode}/>
                        {' '}
                        <span>{resourceTypeName}</span>
                      </div>
                    </Tooltip>
                  );
                })}
              </div>

              <div className="span2">
                {step.ilries.map((ilri) => (
                  <Tooltip title={ilri.name} key={ilri.id}>
                    <div
                      className="pill ng-scope ng-binding"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: ilri.category?.color,
                      }}
                      onClick={() => setShowDetailsSkill(ilri)}
                      aria-label="Sill details"
                    >
                      <i className="icon-plus"/>
                      {' '}
                      Skill Earned
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          ))}

          <div className="full-width">
            <h4>{localizeText('Materials Needed')}</h4>
            <div
              className="html"
              dangerouslySetInnerHTML={{__html: lesson?.materials ?? ''}}
            />
            <h4>{localizeText('Preparation')}</h4>
            <div
              className="html"
              dangerouslySetInnerHTML={{__html: lesson?.preparation ?? ''}}
            />
            <h4>{localizeText('Lesson Plan')}</h4>
            {lesson?.plans.map((plan) => (
              <div className="plan" key={plan.id}>
                <div className="title">
                  <div className="buttons btn-group">
                    <a
                      className="btn btn-small"
                      aria-label="Print"
                      onClick={() => setShowPrintLessonPlan(plan)}
                    >
                      <i className="icon-print"/>
                      {' '}
                      <span>{localizeText('Print')}</span>
                    </a>
                  </div>
                  <span className="text">{plan.title}</span>
                  {plan.time &&
                    <span className="duration">
                      &nbsp;({plan.time} min)
                    </span>
                  }
                </div>

                <table className="list inner-list">
                  <thead>
                    <tr>
                      <td width="25">{localizeText('Min')}</td>
                      <td width="20%">{localizeText('Category')}</td>
                      <td>{localizeText('Description')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {plan.entries.map((entry) => (
                      <tr key={entry.id}>
                        <td className="vtop">{entry.time}</td>
                        <td className="vtop">{entry.activity}</td>
                        <td
                          className="vtop html"
                          dangerouslySetInnerHTML={{__html: entry.steps}}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}

            <h4>{localizeText('Words To Know')}</h4>
            {lesson?.words.map((word) => (
              <div key={word.id} className="word list">
                <div style={{marginTop: 0}}>
                  <b>• {word.word}</b>
                </div>
                <div
                  className="html"
                  dangerouslySetInnerHTML={{__html: word.meaning}}
                />
              </div>
            ))}
          </div>
        </>
      }
    />
  );
};
