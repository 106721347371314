import {AxiosInstance} from 'axios';
import {ListFilter} from 'api/lists/ListFilter';
import {CreateTemplateDto, Template, UpdateTemplateDto} from 'api/templates/Template';
import {EntityListService} from 'api/lists/EntityListService';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API functions for certificate templates
 */
export class TemplatesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches a filtered list of templates
   *
   * @param {ListFilter} filter
   */
  async fetchTemplates(filter: ListFilter): Promise<Template[]> {
    const response = await this.httpClient.post('/templates/list', this.entityListService.buildFilter(filter));
    return response.data.list;
  }

  /**
   * Deletes a template
   *
   * @param {number} id
   */
  async deleteTemplate(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/templates/remove', {id});
    return response.data;
  }

  /**
   * Creates a new template
   * @param {CreateTemplateDto} template
   */
  async createTemplate(template: CreateTemplateDto): Promise<SuccessResponse<Template>> {
    const response = await this.httpClient.post('/templates/save', template);
    return response.data;
  }

  /**
   * Updates a template
   *
   * @param {UpdateTemplateDto} template
   */
  async updateTemplate(template: UpdateTemplateDto): Promise<SuccessResponse<Template>> {
    const response = await this.httpClient.post('/templates/save', template);
    return response.data;
  }
}
