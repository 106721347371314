import React, {useEffect, useState} from 'react';
import {Select, SelectBaseProps} from 'components/forms/Select';
import {useApiContext} from 'api/ApiContext';
import {localizeText} from 'i18n/localize';
import {User} from 'api/users/User';

export interface UserRolePickerProps extends SelectBaseProps {
  value?: number | number[],
  onChange?: (newValue: number | number[]) => any,
  roleIds?: number[],
}

export const UserPicker = (props: UserRolePickerProps) => {
  const {usersService} = useApiContext();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    (async () => {
      setUsers(await usersService.fetchUsers({
        filters: {
          roleIds: props.roleIds,
        },
      }));
    })();
  }, [props.roleIds]);

  return (
    <Select
      placeholder={props.placeholder ?? localizeText('Choose User')}
      multiple={props.multiple ?? true}
      value={
        props.multiple ?
          (props.value as number[])?.map((value) => value.toString()) :
          (props.value as number)?.toString()
      }
      options={users.map((user) => ({
        label: user.name,
        value: user.id.toString(),
      }))}
      onChange={(newValue) => {
        if (!props.multiple) {
          props.onChange?.(parseInt(newValue as string));
        } else {
          props.onChange?.(
              (newValue as string[]).map((value) => parseInt(value)),
          );
        }
      }}
    />
  );
};
