import React, {useEffect, useState} from 'react';
import {Config} from 'Config';
import {FormattedDate} from 'components/FormattedDate';
import {Tooltip} from 'components/Tooltip';
import {__, localizeText} from 'i18n/localize';
import {confirmDelete} from 'components/confirmDelete';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {SchoolInputModal} from 'views/schools/SchoolInputModal';
import {School} from 'api/schools/School';
import dayjs from 'dayjs';
import {Category} from 'api/categories/Category';
import {Skill} from 'api/skills/Skill';
import {Lesson} from 'api/lessons/Lesson';
import classNames from 'classnames';

export interface SchoolDetailsViewProps {
  schoolId: number,
  onUpdated?: () => any,
  onDeleted?: () => any,
}

export const SchoolDetailsView = (props: SchoolDetailsViewProps) => {
  const logger = useLogger(SchoolDetailsView.name);
  const {schoolsService, statsService} = useApiContext();

  const [school, setSchool] = useState<School | undefined>(undefined);
  const [newUsers, setNewUsers] = useState<number | undefined>(undefined);
  const [isFetchingStats, setIsFetchingStats] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [categoryGroupedSkillGoals, setCategoryGroupedSkillGoals] = useState<{
    category: Category,
    skillGoals: {skill: Skill, count: number, time: number, humanTime: string}[]
  }[]>([]);
  const [categoryGroupedAssignedLessons, setCategoryGroupedAssignedLessons] = useState<{
    category: Category,
    lessons: {lesson: Lesson, count: number}[]
  }[]>([]);

  const fetchSchool = async () => {
    setIsFetchingStats(true);
    const fetchedSchool = await schoolsService.fetchSchool(props.schoolId);
    logger.debug('Fetche school', fetchedSchool);
    setSchool(fetchedSchool);
    setIsFetchingStats(false);
  };

  const fetchNewUsers = async () => {
    const result = await statsService.fetchNewUserCount(90, props.schoolId);
    setNewUsers(result);
  };

  const fetchAssignedLessons = async () => {
    const result = await statsService.fetchRecentGoals(
        dayjs().subtract(3, 'months').toDate(),
        new Date(),
        props.schoolId,
    );
    setCategoryGroupedAssignedLessons(statsService.groupLessonsByCategory(result));
  };

  const fetchNewSkills = async () => {
    const result = await statsService.fetchRecentSkills(
        dayjs().subtract(3, 'months').toDate(),
        new Date(),
        props.schoolId,
    );
    setCategoryGroupedSkillGoals(statsService.groupSkillsByCategory(result));
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchSchool(),
        fetchNewUsers(),
        fetchAssignedLessons(),
        fetchNewSkills(),
      ]);
    })();
  }, [schoolsService, props.schoolId]);

  if (isFetchingStats) {
    return <LoadingIndicator/>;
  }
  if (!school) {
    return <p>Failed to load school.</p>;
  }

  return (
    <div className="lesson-view">
      {showEdit &&
        <SchoolInputModal
          initialSchool={{...school, advancedResources: school.advancedResources ?? false}}
          onCancel={() => setShowEdit(false)}
          onComplete={async () => {
            await fetchSchool();
            props.onUpdated?.();
            setShowEdit(false);
          }}
        />
      }

      <div className="container" style={{padding: '15px 35px'}}>
        <div className="row-fluid">
          <div className="span2">
            <img
              src={school.image ? school.image + '100/100' : Config.defaultSchoolImage}
              className="circle-50 gray"
              style={{width: 90, height: 90, marginBottom: 5}}
              alt={''}
            />
          </div>
          <div className="span8">
            <h3 className="title">{school.name}</h3>
            <div
              style={{
                marginTop: -26,
                fontSize: 10,
                fontStyle: 'italic',
                paddingLeft: 10,
                color: '#a5a5a5',
              }}
            >
              Created: <FormattedDate timestamp={school.cdate}/>
              {' '} Seats: {school.seats}
            </div>
          </div>
        </div>
        <div className="icons btn-group">
          <Tooltip title={__('Edit')}>
            <a className="btn btn-icon btn-mini" onClick={() => setShowEdit(true)}>
              <i className="icon-edit" />
            </a>
          </Tooltip>
          <Tooltip title={__('Delete')}>
            <a
              className="btn btn-icon btn-mini"
              onClick={() => confirmDelete(async () => {
                await schoolsService.deleteSchool(props.schoolId);
                props.onDeleted?.();
              })}
            >
              <i className="icon-trash" />
            </a>
          </Tooltip>
        </div>
        <h4>{localizeText('Engagement')}</h4>

        <div className="row-fluid">
          <div className="span3 statbox">
            <div className="statcircle">
              <i className="icon-users" />
            </div>
            <div className="mainNum">{newUsers}</div>
            <div className="title">
              {localizeText('New Users')}
            </div>

          </div>

          <div className="span3 statbox">
            <div className="statcircle">
              <i className="icon-cog-alt" />
            </div>
            <div className="mainNum">
              {/* // data*/}
              {/* eslint-disable-next-line max-len */}
              {/* // .engagement * 100 | number:2}}<sup className="units"> %</sup>*/}
            </div>
            <div className="title">
              {localizeText('User Engagement')}
            </div>
            <div className="des">
              Participants completing one action a day
            </div>
          </div>
          <div className="span3 statbox">
            <div className="statcircle">
              <i className="icon-clock" />
            </div>

            <div className="mainNum">
              0
              {/* {{ data.skillTime.time | number:0}}*/}
              {/* eslint-disable-next-line max-len */}
              {/* <sup className="units"> {{ data.skillTime.units }}</sup>*/}
            </div>
            <div className="title">
              {localizeText('Avg. Time to Skill')}
            </div>

          </div>
          <div className="span3 statbox dark">
            <div className="statcircle">
              <i className="icon-users" />
            </div>

            <div className="title">
              {localizeText('Products')}
            </div>

            <div className="scrollGroup">
              {school.products.map((product) => (
                <div className="subsection" key={product.id}>
                  {product.name}
                </div>
              ))}
            </div>

          </div>
        </div>


        <div className="row-fluid">
          <div className="span5 ">
            <h4>{localizeText('Lessons')}</h4>
            {categoryGroupedAssignedLessons.map((group) => (
              <div key={group.category.id} className="lesson-list" style={{position: 'relative'}}>
                <div className="circle-30" style={{position: 'absolute', left: 216, background: group.category.color}}>
                  <span className={classNames('icon', group.category.icon)} />
                </div>
                <div className="category-title" style={{margin: '25px 0'}}>
                  {group.category.name}
                </div>
                {group.lessons.map((lesson) => (
                  <div key={lesson.lesson.id}>
                    <span className="badge">{lesson.count}</span> {lesson.lesson.name}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="span4">
            <h4>{localizeText('Skills')}</h4>
            {categoryGroupedSkillGoals.map((group) => (
              <div key={group.category.id} className="lesson-list" style={{position: 'relative'}}>
                <div className="circle-30" style={{position: 'absolute', left: 216, background: group.category.color}}>
                  <span className={classNames('icon', group.category.icon)} />
                </div>
                <div className="category-title" style={{margin: '25px 0'}}>
                  {group.category.name}
                </div>
                {group.skillGoals.map((skillGoal) => (
                  <div key={skillGoal.skill.id}>
                    <span className="badge">{skillGoal.count}</span> {skillGoal.skill.name }
                    <span
                      className="pull-right"
                      style={{fontSize: 10, fontStyle: 'italic', paddingLeft: 10, color: '#a5a5a5'}}
                    >
                      {skillGoal.humanTime}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
