import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import classNames from 'classnames';
import {School} from 'api/schools/School';
import {useApiContext} from 'api/ApiContext';
import {SchoolInputModal} from 'views/schools/SchoolInputModal';
import {SchoolDetailsView} from 'views/schools/SchoolDetailsView';

export const SchoolsListView = () => {
  const logger = useLogger(SchoolsListView.name);
  const {schoolsService} = useApiContext();
  const [schools, setSchools] = useState<School[]>([]);
  const [viewedSchool, setViewedSchool] = useState<School|undefined>(undefined);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [showList, setShowList] = useState<boolean>(true);
  const [editSchool, setEditSchool] = useState<School | undefined>(undefined);

  const loadSchools = async () => {
    const fetchedSchools = await schoolsService.fetchSchools(searchFilter);
    setSchools(fetchedSchools);
    logger.debug('Fetched schools', fetchedSchools);
  };

  useEffect(() => {
    (async () => {
      await loadSchools();
    })();
  }, [searchFilter]);

  return (
    <div className="three-column-layout">
      {editSchool &&
        <SchoolInputModal
          initialSchool={editSchool}
          onComplete={async () => {
            await loadSchools();
            setEditSchool(undefined);
            setViewedSchool(undefined);
            setViewedSchool(
                schools.find((school) => school.id === editSchool?.id),
            );
          }}
          onCancel={() => setEditSchool(undefined)}
        />
      }

      <div className={classNames('first-column', 'slide', {away: !showList})}>
        <div className={'switcher'} onClick={() => setShowList((current) => !current)} />
        <div className="view" style={{overflowY: 'auto'}}>
          <div className="pane">
            <div className="body">
              {schools.map((school) => (
                <div
                  key={school.id}
                  className={'lesson'}
                  onClick={() => {
                    const newSchool: School | undefined = schools.find(
                        (findSchool) => findSchool.id === school.id,
                    );
                    setViewedSchool(newSchool);
                  }}
                >
                  <div className="name">
                    {school.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('right-column lesson-editor', 'slide', {away: !showList})}
        style={{overflowY: 'auto'}}
      >
        <div className="view animate">
          <ContextMenu>
            <SearchInput value={searchFilter} onChange={(newValue) => setSearchFilter(newValue)} />
            <ButtonAdd onClick={() => setEditSchool({} as School)} />
          </ContextMenu>

          {!viewedSchool &&
            <div className="lesson-view" style={{backgroundImage: 'linear-gradient(to right, #e5e5e5 0%, #f2f2f2 7%)'}}>
              <div className="instructions">
                <h1> Campus </h1>
                <ul>
                  <li>Select a Campus from the menu on the left to load Campus data.</li>
                </ul>
              </div>
              <div style={{clear: 'both'}}/>
            </div>
          }

          {viewedSchool &&
            <SchoolDetailsView
              schoolId={viewedSchool.id}
              onUpdated={async () => await loadSchools()}
              onDeleted={async () => {
                setViewedSchool(undefined);
                await loadSchools();
              }}
            />
          }
        </div>
      </div>
    </div>
  );
};
