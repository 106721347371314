import React, {useEffect, useState} from 'react';
import {Syllabus} from 'api/syllabi/Syllabus';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {__} from 'i18n/localize';
import {GoalThumbnail} from 'views/goals/GoalThumbnail';
import {useLogger} from 'logging/logging';
import {InViewObserver} from 'components/InViewObserver';
import {SyllabusInputModal} from 'views/syllabi/SyllabusInputModal';
import {confirmDelete} from 'components/confirmDelete';
import {Droppable} from 'react-beautiful-dnd';
import {Goal} from 'api/goals/Goal';
import {useApiContext} from 'api/ApiContext';
import {Tooltip} from 'components/Tooltip';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';

export interface SyllabiListProps {
  addedGoals?: {syllabusId: number, goal: Goal}[],
}

export const SyllabiList = (props: SyllabiListProps) => {
  const logger = useLogger(SyllabiList.name);
  const {syllabiService} = useApiContext();
  const pageSize = 10;

  const [syllabi, setSyllabi] = useState<Syllabus[]>([]);
  const [fetchingSyllabi, setFetchingSyllabi] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const [editSyllabus, setEditSyllabus] = useState<Syllabus | undefined>(undefined);
  const [showAddSyllabus, setShowAddSyllabus] = useState<boolean>(false);
  const [addGoalSyllabus, setAddGoalSyllabus] = useState<Syllabus | undefined>(undefined);
  const [viewedGoal, setViewedGoal] = useState<Goal | undefined>(undefined);
  const [goalToEdit, setGoalToEdit] = useState<Goal | undefined>(undefined);

  const loadSyllabi = async () => {
    setFetchingSyllabi(true);
    const fetchedSyllabi = await syllabiService.fetchSyllabi({
      start: pageSize * page,
      end: (pageSize * page) + pageSize,
      search,
    });
    setSyllabi((current) => current.concat(fetchedSyllabi));
    logger.debug('Fetched syllabi', fetchedSyllabi);
    if (fetchedSyllabi.length < pageSize) {
      setAllFetched(true);
      logger.debug('All syllabi loaded');
    }
    setFetchingSyllabi(false);
  };

  const resetSyllabi = async () => {
    setSyllabi([]);
    setAllFetched(false);
    if (page === 0) {
      await loadSyllabi();
    } else {
      setPage(0);
    }
  };

  const nextPage = async () => {
    if (!allFetched) {
      setPage((current) => current + 1);
    }
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.14), rgba(255,255,255,0.53));',
    background: isDraggingOver ?
      '#8dc73f' :
      'none',
  });

  const addedGoalsForSyllabus = (id: number) => {
    return props.addedGoals?.filter((g) => g.syllabusId === id).map((g) => g.goal) ?? [];
  };

  useEffect(() => {
    (async () => {
      await resetSyllabi();
    })();
  }, [search]);

  useEffect(() => {
    (async () => {
      if (page > 0) {
        await loadSyllabi();
      }
    })();
  }, [page]);

  return (
    <div className="pane syllabi">
      {showAddSyllabus &&
        <SyllabusInputModal
          onCloseRequested={() => setShowAddSyllabus(false)}
          onCompleted={async () => {
            await resetSyllabi();
            setShowAddSyllabus(false);
          }}
        />
      }

      {editSyllabus &&
        <SyllabusInputModal
          initialSyllabus={editSyllabus}
          onCloseRequested={() => setEditSyllabus(undefined)}
          onCompleted={async () => {
            await resetSyllabi();
            setEditSyllabus(undefined);
          }}
        />
      }

      {addGoalSyllabus &&
        <GoalInputModal
          syllabusId={addGoalSyllabus.id}
          onCompleted={async (newGoal) => {
            setSyllabi((current) => {
              const copy = [...current];
              copy.forEach((syllabus) => {
                if (syllabus.id === addGoalSyllabus.id && newGoal) {
                  syllabus.goals.push(newGoal);
                }
              });
              return copy;
            });
            setAddGoalSyllabus(undefined);
          }}
          onCloseRequested={() => setAddGoalSyllabus(undefined)}
        />
      }

      {viewedGoal &&
        <GoalDetailsModal
          goalId={viewedGoal.id}
          onCloseRequested={() => setViewedGoal(undefined)}
          onEditRequested={() => {
            setGoalToEdit(viewedGoal);
            setViewedGoal(undefined);
          }}
        />
      }

      {goalToEdit &&
        <GoalInputModal
          initialGoalId={goalToEdit.id}
          onCloseRequested={() => setGoalToEdit(undefined)}
        />
      }

      <div className="body" style={{overflowY: 'auto'}}>
        <>
          <ContextMenu>
            <SearchInput
              value={search}
              onChange={(newSearch) => setSearch(newSearch)}
            />
            <Tooltip title={__('Add Syllabus')}>
              <Button variant={ButtonVariant.Add} onClick={() => setShowAddSyllabus(true)} />
            </Tooltip>
          </ContextMenu>

          <div style={{marginTop: 40}}>
            {syllabi.map((syllabus) => (
              <Droppable key={syllabus.id} droppableId={`syllabus-${syllabus.id}`}>
                {(provided, snapshot) => (
                  <div
                    className="pack"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <div className="header">
                      <div className="buttons btn-group">
                        <Tooltip title={__('Edit')}>
                          <a className="btn btn-default" onClick={() => setEditSyllabus(syllabus)}>
                            <i className="icon-edit"/>
                          </a>
                        </Tooltip>
                        <Tooltip title={__('Delete')}>
                          <a
                            onClick={() => confirmDelete(async () => {
                              await syllabiService.deleteSyllabus(syllabus.id);
                              await resetSyllabi();
                            })}
                            className="btn btn-danger"
                          >
                            <i className="icon-trash" />
                          </a>
                        </Tooltip>
                      </div>

                      <div className="name">{syllabus.name}</div>
                      <div className="campus">{syllabus.school?.name}</div>

                      <div className="clearfix" />
                    </div>

                    <div className="body">
                      <div className="frame">
                        <div className="body">
                          {syllabi.length < 1 && fetchingSyllabi &&
                        <div className="loader" />
                          }
                          {(syllabi.length > 0 || !fetchingSyllabi) &&
                        <div>
                          {syllabus.goals.concat(addedGoalsForSyllabus(syllabus.id)).map((goal) => (
                            <GoalThumbnail
                              key={goal.id}
                              goal={goal}
                              forCourse={true}
                              draggable={true}
                              onOpenRequested={() => setViewedGoal(goal)}
                            />
                          ))}
                        </div>
                          }
                        </div>
                      </div>

                      <div className="clearfix" />

                      <div className="buttons btn-group">
                        <Tooltip title={__('Create Goal')}>
                          <a
                            className="btn btn-small btn-success"
                            onClick={() => setAddGoalSyllabus(syllabus)}
                          >
                            <i className="icon-plus" />
                          </a>
                        </Tooltip>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>

          <InViewObserver onInView={nextPage} />
        </>
      </div>
    </div>
  );
};
