import {AxiosInstance} from 'axios';
import {CreateVersionDto, UpdateVersionDto, Version, VersionWithRelations} from 'api/versions/Version';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API versions functions
 */
export class VersionsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches versions configured in the system
   *
   * @param {string} searchFilter
   */
  async fetchVersions(searchFilter = ''): Promise<Version[]> {
    const response = await this.httpClient.post(ApiRoute.VersionsList, {
      filter: {
        search: searchFilter,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a single version
   *
   * @param {number} id
   * @return {Promise<VersionWithRelations>}
   */
  async fetchVersion(id: number): Promise<VersionWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Versions + ApiRoute.Get, {id});
    return response.data;
  }

  /**
   * Deletes a version in the API
   *
   * @param {number} versionId
   * @return {Promise<SuccessResponse>}
   */
  async deleteVersion(versionId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/versions/remove', {
      id: versionId,
    });
    return response.data;
  }

  /**
   * Creates a new version in the API
   *
   * @param {CreateVersionDto} version
   * @return {Promise<SuccessResponse>}
   */
  async createVersion(version: CreateVersionDto): Promise<SuccessResponse<Version>> {
    const response = await this.httpClient.post('/versions/save', version);
    return response.data;
  }

  /**
   * Updates a version in the API
   *
   * NOTE: parent version MUST be maintained in update requests. If a version
   * with a parent is updated without a parent in the update request (ie. the
   * parent is undefined -- not null), the parent will be removed.
   *
   * @param {UpdateVersionDto} version
   * @return {Promise<SuccessResponse>}
   */
  async updateVersion(version: UpdateVersionDto): Promise<SuccessResponse<Version>> {
    const response = await this.httpClient.post('/versions/save', version);
    return response.data;
  }
}
