import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {LoadingModal} from 'components/LoadingModal';
import {CoursePublicDetails} from 'api/courses/Course';
import {__} from 'i18n/localize';
import {Config} from 'Config';
import classNames from 'classnames';

export interface CourseDetailsModalProps {
  courseId: number,
  onCloseRequested?: () => any,
}

export const CourseDetailsModal = (props: CourseDetailsModalProps) => {
  const logger = useLogger(CourseDetailsModal.name);
  const {coursesService} = useApiContext();

  const [courseDetails, setCourseDetails] = useState<CoursePublicDetails | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const fetchCourseDetails = async () => {
    setIsFetching(true);
    const fetchedDetails = await coursesService.fetchCoursePublicDetails(props.courseId);
    setCourseDetails(fetchedDetails);
    logger.debug('Fetched course details', fetchedDetails);
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      await fetchCourseDetails();
    })();
  }, [props.courseId]);

  if (isFetching) {
    return <LoadingModal onCloseRequested={props.onCloseRequested} />;
  }

  if (!courseDetails) {
    return <Modal
      onCloseRequested={props.onCloseRequested}
      body={<p>Failed to load course</p>}
    />;
  }

  return <Modal
    title={courseDetails.course.name}
    onCloseRequested={props.onCloseRequested}
    className={'wide'}
    bodyClassName={'course-edit'}
    bodyPadding={0}

    body={
      <div
        className="course preview pack"
        style={{borderRadius: 0, margin: 0, backgroundImage: 'none', boxShadow: 'none', border: 'none'}}
      >
        <div className="header clearfix">
          <div className="details">
            <div className="field">
              <span>{__('Goals')}</span>:
              {' '}
              <span className="badge ng-binding">{courseDetails.course.assignedGoals.length}</span>
            </div>
            <div className="field">
              <div
                className="label"
                style={{backgroundColor: courseDetails.course.category?.color, padding: '3px 5px'}}
              >
                {courseDetails.course.category?.name}
              </div>
            </div>
          </div>
        </div>

        <div className="body clearfix">
          <div className="left-column clearfix" style={{paddingLeft: 15}}>
            <div className="teachers clearfix">
              {courseDetails.achievements.length > 0 &&
                <div className="frame">
                  <div className="title">{__('Instructors')}</div>
                  {courseDetails.course.teachers.map((teacher) => (
                    <div className="teacher clearfix" key={teacher.id}>
                      <div className="image block">
                        <img
                          src={teacher.image?.url ? teacher.image.url + '100/100' : Config.defaultUserImage}
                          alt={''}
                        />
                        <div className="name block">{teacher.name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>

            {courseDetails.achievements.length > 0 &&
              <div className="frame">
                <div className="title">??{__('Associated Badges')}</div>
                {courseDetails.achievements.map((achievement) => (
                  <div key={achievement.id} className="teacher clearfix">
                    <div className="image block">
                      <img
                        src={achievement.image?.url ? achievement.image.url + '100/100' : '/images/logo.jpg'}
                        alt={''}
                      />
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>

          <div className="right-column clearfix">
            {courseDetails.course.description &&
            <div className="description frame">
              <div className="title">{__('Description')}</div>
              <div className="html padding" dangerouslySetInnerHTML={{__html: courseDetails.course.description}} />
            </div>
            }
            {courseDetails.course.assignedGoals.length > 0 &&
            <div className="description frame">
              <div className="title">{__('Goal Objectives')}</div>
              {courseDetails.course.assignedGoals.map((goal) => (
                <div
                  key={goal.id}
                  className={'html padding'}
                  dangerouslySetInnerHTML={{__html: goal.description ?? ''}}
                />
              ))}
            </div>
            }
            {courseDetails.skills.length > 0 &&
            <div className="frame">
              <div className="title">{__('Covered Skills')}</div>
              {courseDetails.skills.map((skill) => (
                <div key={skill.id} className="pill" style={{borderColor: skill.category?.color}}>
                  <div className="circle-20" style={{float: 'right', background: skill.category?.color}}>
                    <span className={classNames('icon', skill.category?.icon)} />
                  </div>
                  {skill.name}
                </div>
              ))}
            </div>
            }
          </div>
        </div>
      </div>
    }
  />;
};
