import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {SaveLessonWordDto} from 'api/lessons/LessonWord';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';
import classNames from 'classnames';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';

export interface LessonWordInputModalProps {
  initialWord?: SaveLessonWordDto,
  onCloseRequested?: () => any,
  onComplete?: (word: SaveLessonWordDto) => any,
}

export const LessonWordInputModal = (props: LessonWordInputModalProps) => {
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.initialWord?.id !== undefined;

  const [word, setWord] = useState<string>(props.initialWord?.word ?? '');
  const [meaning, setMeaning] = useState<string>(props.initialWord?.meaning ?? '');
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (word.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, word: true}));
    }
    if (meaning.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, meaning: true}));
    }

    return isValid;
  };

  const submit = () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }
    props.onComplete?.({id: props.initialWord?.id, word, meaning});
  };

  useEffect(() => {
    validate();
  }, [word, meaning]);

  return <Modal
    title={__(isEditing ? 'Edit Word' : 'Add Word')}
    onCloseRequested={props.onCloseRequested}
    buttons={
      <Button variant={ButtonVariant.Confirm} onClick={submit}/>
    }

    body={
      <form id={'wordForm'}>
        <div className={'row-fluid'}>
          <input
            placeholder={__('Enter Word')}
            value={word}
            onChange={(e) => setWord(e.target.value)}
            type="text"
            className={classNames('span12', {'invalid': errors.word})}
            // TODO: autocomplete words input
            // typeahead="word as word.text for word in fetchWords($viewValue)"
          />
        </div>
        <div className={'row-fluid'}>
          <RichTextInput
            placeholder={__('Word Meaning')}
            value={meaning}
            onChange={(newMeaning) => setMeaning(newMeaning)}
            required={errors.meaning}
          />
        </div>
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
