import React, {useEffect, useState} from 'react';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {QuizWithQuestions} from 'api/quizzes/Quiz';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';

export interface QuizPrintViewProps {
  quizId: number,
}

export const QuizPrintView = (props: QuizPrintViewProps) => {
  const logger = useLogger(QuizPrintView.name);
  const {quizzesService} = useApiContext();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [quiz, setQuiz] = useState<QuizWithQuestions | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const fetchedQuiz = await quizzesService.fetchQuiz(props.quizId);
      setQuiz(fetchedQuiz);
      logger.debug('Fetched quiz', fetchedQuiz);
      setIsFetching(false);
    })();
  }, []);

  if (isFetching) {
    return <></>;
  }

  return (
    <div className="quiz">
      <h1 className="underline">{quiz?.name}</h1>

      {quiz?.questions.map((question, questionIndex) => (
        <div key={question.id} className="question">
          <div className="question-number">{questionIndex + 1}.</div>
          <div
            className="heading clearfix html"
            dangerouslySetInnerHTML={{__html: question.text}}
          />
          <div className="clearfix"/>

          {[
            QuizQuestionType.Bullets,
            QuizQuestionType.Multiple,
          ].includes(question.type) &&
            <div className="answers">
              {question.answers.map((answer) => (
                <div key={answer.id} className="answer">
                  <div className="checkbox">
                    <i className="icon-check-empty"/>
                  </div>
                  <div
                    className="text html"
                    dangerouslySetInnerHTML={{__html: answer.text}}
                  />
                </div>
              ))}
            </div>
          }

          {question.type === QuizQuestionType.Order &&
            <div className="answers">
              <table>
                {question.answers.map((answer, i) => (
                  <tr key={answer.id} className="answer order">
                    <td className="letter">
                      {i + 1}.
                    </td>
                    <td
                      className="text html"
                      dangerouslySetInnerHTML={{__html: answer.text}}
                    />
                  </tr>
                ))}
              </table>
            </div>
          }

          {question.type === QuizQuestionType.Open &&
            <div className="answers no-checkbox">
              <div className="open-answer"/>
            </div>
          }

          {question.type === QuizQuestionType.Match &&
            <div className="answers">
              <div className="answer match">
                <div className="row-fluid">
                  <div className="span6">
                    <table>
                      {question.answers.map((answer, i) => (
                        <tr
                          key={answer.id}
                          className="matched"
                          // className="answer"
                        >
                          <td className="letter">{i +1}.</td>
                          <td
                            className="text html"
                            dangerouslySetInnerHTML={{__html: answer.text}}
                          />
                        </tr>
                      ))}
                    </table>
                  </div>

                  <div className="span6">
                    <table>
                      {question.answers.map((answer, i) => (
                        <tr
                          key={answer.id}
                          className="options"
                          // className="answer"
                        >
                          <td className="letter">{i + 1}.</td>
                          <td
                            className="text html"
                            dangerouslySetInnerHTML={{__html: answer.match}}
                          />
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="clearfix"/>
          <div className="break underline"/>
        </div>
      ))}
    </div>
  );
};
