import React, {useEffect, useState} from 'react';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {__} from 'i18n/localize';
import {Product, ProductWithRelations} from 'api/products/Product';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {useSchoolContext} from 'contexts/SchoolContext';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {ContextMenu} from 'components/ContextMenu';
import {Button} from 'components/buttons/Button';
import {CategoryInputModal} from 'views/categories/CategoryInputModal';
import {AchievementInputModal} from 'views/achievements/AchievementInputModal';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

export const ContentEditorDashboard = () => {
  const logger = useLogger(ContentEditorDashboard.name);
  const {productsService} = useApiContext();
  const {selectedSchool} = useSchoolContext();
  const {push: pushRoute} = useHistory();

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeProduct, setActiveProduct] = useState<ProductWithRelations | undefined>(undefined);
  const [showAddCategory, setShowAddCategory] = useState<boolean>(false);
  const [showAddAchievement, setShowAddAchievement] = useState<boolean>(false);

  const fetchProducts = async () => {
    const fetchedProducts = await productsService.fetchProducts();
    logger.debug('Fetched products', fetchedProducts);
    setProducts(fetchedProducts);
  };

  const fetchProduct = async () => {
    if (selectedProduct === '' || selectedProduct === '--new--' || selectedProduct === '--school--') {
      return;
    }
    const fetchedProduct = await productsService.fetchProduct(parseInt(selectedProduct));
    logger.debug('Fetched product', fetchedProduct);
    setActiveProduct(fetchedProduct);
  };

  useEffect(() => {
    (async () => {
      await fetchProducts();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchProduct();
    })();
  }, [selectedProduct]);

  return <>
    {showAddCategory && <CategoryInputModal onCloseRequested={() => setShowAddCategory(false)} /> }
    {showAddAchievement && <AchievementInputModal onCancel={() => setShowAddAchievement(false)} /> }

    <SidePanelPageView
      panelView={
        <>
          <div className="filter left">
            <style>{`
              .filter .fixed-width-select {
                width: 160px !important;
              }
            `}</style>
            <select
              placeholder={__('Product')}
              className="span3 fixed-width-select"
              style={{width: '160px !important', borderRadius: '10px 4px', marginRight: 5, marginLeft: 5}}
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">- {__('Select Product')} -</option>
              <AccessControlled feature={AppFeature.CreateProduct}>
                <option value="--new--">- {__('New Product')} -</option>
              </AccessControlled>
              {selectedSchool &&
                <option value="--school--">{selectedSchool.name}</option>
              }
              {!selectedSchool &&
              <AccessControlled feature={AppFeature.CreateProduct}>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>{product.name}</option>
                ))}
              </AccessControlled>
              }
            </select>
          </div>


          {/* <div>Content Structure</div>*/}

        </>
      }

      mainView={
        <div>
          <ContextMenu>
            <Button
              text={__('Add Category')}
              className={'btn-success'}
              onClick={() => setShowAddCategory(true)}
            />
            <Button
              text={__('Add Achievement')}
              className={'btn-success'}
              onClick={() => setShowAddAchievement(true)}
            />
            <Button
              text={__('Lesson Builder')}
              className={'btn-info'}
              onClick={() => pushRoute(AppRoute.SystemContext + AppRoute.LessonEditor)}
            />
          </ContextMenu>
        </div>
      }
    />
  </>;
};
