import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {GoalWithRelations} from 'api/goals/Goal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {GoalType} from 'api/goals/GoalType.enum';
import {__} from 'i18n/localize';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {LoadingModal} from 'components/LoadingModal';
import {recordError} from 'logging/error-reporting';
import {confirmDelete} from 'components/confirmDelete';
import {confirmModal} from 'components/confirmModal';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Tooltip} from 'components/Tooltip';
import {ActionStepList} from 'views/action-steps/ActionStepList';

export interface GoalDetailsModalProps {
  goalId: number,
  onCloseRequested?: () => any,
  onDeleted?: () => any,
  onDeactivated?: () => any,
  onActivated?: () => any,
  onEditRequested?: () => any,
}

export const GoalDetailsModal = (props: GoalDetailsModalProps) => {
  const logger = useLogger(GoalDetailsModal.name);
  const {goalsService} = useApiContext();

  const [goal, setGoal] = useState<GoalWithRelations | undefined>(undefined);
  const [isLoadingGoal, setIsLoadingGoal] = useState<boolean>(true);

  const loadGoal = async () => {
    setIsLoadingGoal(true);
    try {
      const fetchedGoal = await goalsService.fetchGoal(props.goalId);
      setGoal(fetchedGoal);
      logger.debug('Fetched goal', fetchedGoal);
    } catch (e) {
      logger.error('Error fetching goal', e);
      recordError(e);
    }
    setIsLoadingGoal(false);
  };

  const editGoal = () => props.onEditRequested?.();

  const deleteGoal = () => confirmDelete(async () => {
    await goalsService.deleteGoal(props.goalId);
    props.onDeleted?.();
  });

  /**
   * Toggles the goal active or inactive
   */
  const toggleGoalActive = () => {
    if (!goal) {
      return;
    }

    if (goal.active) {
      confirmModal(
          async () => {
            await goalsService.deActivateGoal(props.goalId);
            props.onDeactivated?.();
          },
          __('Deactivate'),
          __('Are you sure you want to deactivate this goal?'),
      );
    } else {
      confirmModal(
          async () => {
            await goalsService.activateGoal(props.goalId);
            props.onActivated?.();
          },
          __('Activate'),
          __('Are you sure you want to activate this goal?'),
      );
    }
  };

  useEffect(() => {
    (async () => {
      await loadGoal();
    })();
  }, []);

  if (isLoadingGoal) {
    return <LoadingModal className={'wide tall'}/>;
  }

  if (!goal) {
    return <Modal body={<p>Failed to load goal.</p>}/>;
  }

  return <Modal
    title={goal.name}
    className={'wide tall goal-view'}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <>
        {goal.type !== GoalType.Schedule &&
        <AccessControlled authorOf={goal} feature={AppFeature.EditAllGoals}>
          {goal.active &&
            <Button
              variant={ButtonVariant.Edit}
              className={'btn-success'}
              iconCode={'icon-edit'}
              onClick={editGoal}
            />
          }
          <Button
            variant={ButtonVariant.Delete}
            className={'btn-danger'}
            iconCode={'icon-trash'}
            onClick={deleteGoal}
          />
        </AccessControlled>
        }
        <AccessControlled authorOf={goal} feature={AppFeature.EditAllGoals}>
          {(!goal.active ||
            (goal.type === GoalType.User ||
              (goal.type === GoalType.Course && goal.assigned)
            )
          ) && goal.type !== GoalType.Schedule &&
            <Button
              variant={ButtonVariant.Deactivate}
              className={'btn-warning'}
              onClick={toggleGoalActive}
            />
          }
        </AccessControlled>
      </>
    }

    body={
      <>
        {goal.type === GoalType.User &&
          <div className="user-info">
            <b><span>{__('Assigned to')}: </span></b>
            <span>{goal.owner?.name}, </span>
            <b><span>{__('Owner')}: </span></b>
            <span>{goal.author?.name}, </span>
            <b><span>{__('Status')}: </span></b>
            <span className="icon">
              {!goal.completed &&
                <Tooltip title={__('Not Completed')} placement={'left'}>
                  <i className="icon-check-empty" aria-label="Goal Not complete" />
                </Tooltip>
              }
              {goal.completed &&
                <Tooltip title={__('Completed')} placement={'left'}>
                  <i className="icon-check" aria-label="Goal complete"/>
                </Tooltip>
              }
            </span>
          </div>
        }

        <div className="description spacer clearfix">
          <div className="icon" style={{borderColor: goal.color}}>
            <span className={goal.icon} />
          </div>
          <div
            className="html"
            dangerouslySetInnerHTML={{__html: goal.description ?? ''}}
          />
        </div>

        <div className="row-fluid action-steps">
          <h4>{__('Action Steps')}</h4>
          <ActionStepList steps={goal.steps ?? []} />
          <div className="clearfix"/>
        </div>
      </>
    }
  />;
};
