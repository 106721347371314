import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {__} from 'i18n/localize';
import {FileView} from 'views/resources/FileView';
import {useApiContext} from 'api/ApiContext';

enum FileUploadStatus {
  Complete,
  Pending,
  Error,
}

interface FileUploadWithStatus {
  file?: FileUpload,
  status: FileUploadStatus,
}

export interface FileUploadInputProps {
  buttonText?: string,
  multiple?: boolean,
  initialFiles?: FileUpload[],
  onChange?: (newFiles: FileUpload[]) => any,
  showError?: boolean,
}

export const FileUploadInput = (props: FileUploadInputProps) => {
  const {fileUploadsService} = useApiContext();

  const uploadQueue = useRef<File[]>([]);
  const [files, setFiles] = useState<FileUploadWithStatus[]>(props.initialFiles ?
    props.initialFiles.map((file) => ({file, status: FileUploadStatus.Complete})) :
    [],
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [viewedFile, setViewedFile] = useState<FileUpload | undefined>(undefined);

  const handleFileChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget.files.length > 0) {
      const selectedFiles = e.currentTarget.files;
      for (let i = 0; i < selectedFiles.length; ++i) {
        const file = selectedFiles[i];
        if (file) {
          uploadQueue.current.push(file);
        }
      }
      if (!isUploading) {
        await uploadNextInQueue();
      }
    }
  };

  const uploadNextInQueue = async () => {
    if (isUploading || uploadQueue.current.length < 1) {
      return;
    }

    setUploadProgress(0);
    setIsUploading(true);

    const newFile: FileUploadWithStatus = {status: FileUploadStatus.Pending};
    const file = uploadQueue.current[0];

    // gets a URL to upload to
    const uploadUrl = (await fileUploadsService.getFileUploadUrl()).replace('http://cif:8888', 'http://localhost:3000');
    if (!uploadUrl) {
      newFile.status = FileUploadStatus.Error;
      setIsUploading(false);
      return;
    }

    if (props.multiple) {
      setFiles((current) => [...current, newFile]);
    } else {
      setFiles([newFile]);
    }

    // uploads the file to the provided URL
    const formData = new FormData();
    formData.append('file', file);
    try {
      await fileUploadsService.uploadFile(
          uploadUrl,
          file,
          (e) => setUploadProgress((e.loaded / e.total) * 100),
      );
    } catch (e) {
      newFile.status = FileUploadStatus.Error;
    }
  };

  const checkUploadStatus = async () => {
    const status = await fileUploadsService.fetchUploadStatus();
    if (status.ok) {
      setFiles((current) => {
        const newFiles = [...current];
        newFiles[newFiles.length - 1].file = status.data;
        newFiles[newFiles.length - 1].status = FileUploadStatus.Complete;
        return newFiles;
      });
    } else {
      setFiles((current) => {
        const newFiles = [...current];
        newFiles[newFiles.length - 1].status = FileUploadStatus.Error;
        return newFiles;
      });
    }
  };

  const removeFile = (index: number) => {
    setFiles((current) => {
      const newFiles = [];
      for (let j = 0; j < current.length; ++j) {
        if (j !== index) {
          newFiles.push(current[j]);
        }
      }
      return newFiles;
    });
  };

  useEffect(() => {
    (async () => {
      if (uploadProgress >= 100) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1500); // API seems to have problems if status is checked immediately after upload
        });
        await checkUploadStatus();
        uploadQueue.current.shift();
        setIsUploading(false);
        setUploadProgress(0);
      }
    })();
  }, [uploadProgress]);

  useEffect(() => {
    props.onChange?.(files.filter((f) => f.file !== undefined).map((f) => f.file!));
  }, [files]);

  useEffect(() => {
    if (!isUploading) {
      uploadNextInQueue();
    }
  }, [isUploading]);

  return (
    <div className="file-upload btn-group">
      {viewedFile &&
        <FileView
          name={viewedFile.name}
          url={viewedFile.url}
          aria-label="File View"
          onCloseRequested={() => setViewedFile(undefined)}
        />
      }

      <span className="btn btn-inverse" style={{border: props.showError ? '2px solid #c00' : undefined}}>
        <span>{props.buttonText ?? 'Select Files'}</span>
        <input
          aria-label={props.buttonText ?? 'Select Files'}
          type="file"
          name="file"
          multiple={props.multiple ?? false}
          className="input"
          onChange={handleFileChanged}
        />
      </span>
      {files.length > 0 &&
        <table className="list files span12 left-offset-0">
          <tbody>
            {files.map((file, i) => (
              <tr key={i}>
                {file.file && file.status === FileUploadStatus.Complete &&
                  <td style={{padding: '0 14px'}}>
                    <a
                      onClick={() => setViewedFile(file.file)}
                      style={{whiteSpace: 'initial'}}
                      aria-label="View File"
                    >
                      {file.file.name}
                    </a>
                  </td>
                }

                {file.status === FileUploadStatus.Error &&
                  <td style={{padding: '0 14px'}}>{__('Error Uploading Resource')}</td>
                }

                {file.status === FileUploadStatus.Pending &&
                  <td className="files" style={{padding: '0 14px'}}>
                    <div className="progress progress-success progress-stripped">
                      <div className="bar" style={{width: uploadProgress + '%'}} />
                    </div>
                  </td>
                }

                {file.file && file.status === FileUploadStatus.Complete &&
                  <td width="20" style={{padding: '0 14px'}}>
                    <a
                      onClick={() => removeFile(i)}
                      className="btn"
                      aria-label="Remove File"
                    >
                      <i className="icon-cancel" />
                    </a>
                  </td>
                }

                {(file.status === FileUploadStatus.Pending || file.status === FileUploadStatus.Error) &&
                  <td width="20" style={{padding: '0 14px'}} />
                }
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
};
