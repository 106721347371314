/**
 * A user role in the API
 */
export type UserRole = {
  id: number;
  name: string;
  priority: number;
  type: UserRoleType;
};

/**
 * The API also has "role types" which indicate the more common
 * concept of user roles
 */
export enum UserRoleType {
  SystemAdmin = 'SYSADMIN',
  Editor = 'EDITOR',
  Admin = 'ADMIN',
  Teacher = 'TEACHER',
  InstructorAssistant = 'INSTRUCTOR_ASSISTANT',
  KolManager = 'KOL_MANAGER',
  Student = 'STUDENT',
  IlStudent = 'IL_STUDENT',
  Parent = 'PARENT',
  Support = 'SUPPORT',
  Ilri = 'ILRI'
}
