import {
  Achievement,
  AchievementWithRelations,
  CreateAchievementDto,
  UpdateAchievementDto,
} from 'api/achievements/Achievement';
import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {Lesson} from 'api/lessons/Lesson';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {UserAchievementRecord} from 'api/achievements/UserAchievementRecord';
import {UserAchievementRecordDetails} from 'api/achievements/UserAchievementRecordDetails';

/**
 * Provides API achievements functions
 */
export class AchievementsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches achievements from the API
   *
   * @param {string} searchFilter
   * @return {Achievement[]}
   */
  async fetchAchievements(searchFilter = ''): Promise<Achievement[]> {
    const response = await this.httpClient.post(ApiRoute.Achievements + ApiRoute.List, {
      filter: {
        search: searchFilter,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a single achievement by ID
   *
   * @param {number} id
   */
  async fetchAchievement(id: number): Promise<AchievementWithRelations> {
    const response = await this.httpClient.post('/achievements/get', {id});
    return response.data.entity;
  }

  /**
   * Fetches a user's achievements
   *
   * @param {number} userId
   */
  async fetchUserAchievements(userId: number): Promise<UserAchievementRecord[]> {
    const response = await this.httpClient.post('/users/achievements', {user: userId});
    return response.data.list;
  }

  /**
   * Fetches details about a single achievement from the API
   *
   * @param {number} achievementId
   */
  async fetchAchievementDetails(achievementId: number): Promise<{entity: AchievementWithRelations, lessons: Lesson[]}> {
    const response = await this.httpClient.post(ApiRoute.Achievements + ApiRoute.Get, {
      id: achievementId,
    });
    return response.data;
  }

  /**
   * Creates a new achievement in the API
   *
   * @param {CreateAchievementDto} achievement
   * @return {Promise<SuccessResponse>}
   */
  async createAchievement(achievement: CreateAchievementDto): Promise<SuccessResponse<Achievement>> {
    const response = await this.httpClient.post(
        ApiRoute.Achievements + ApiRoute.Save,
        achievement,
    );
    return response.data;
  }

  /**
   * Fetches an achievement record
   *
   * @param {number} id
   */
  async fetchAchievementRecord(id: number): Promise<UserAchievementRecordDetails> {
    const response = await this.httpClient.post('/users/achievementDetails', {id});
    return response.data;
  }

  /**
   * Creates a new achievement in the API
   *
   * @param {UpdateAchievementDto} achievement
   * @return {Promise<SuccessResponse>}
   */
  async updateAchievement(achievement: UpdateAchievementDto): Promise<SuccessResponse<Achievement>> {
    const response = await this.httpClient.post(
        ApiRoute.Achievements + ApiRoute.Save,
        achievement,
    );
    return response.data;
  }

  /**
   * Deletes an achievement from the API
   *
   * @param {number} achievementId
   * @return {Promise<SuccessResponse>}
   */
  async deleteAchievement(achievementId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.Achievements + ApiRoute.Delete, {
      id: achievementId,
    });
    return response.data;
  }
}
