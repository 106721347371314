import React, {useState} from 'react';
import {Comment} from 'api/journal/Comment';
import {Modal} from 'components/Modal';
import {localizeText} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {useApiContext} from 'api/ApiContext';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';

/**
 * A modal to add or edit a journal comment
 */

export interface JournalCommentInputModalProps {
  entryId?: number,
  initialComment?: Comment,
  onCloseRequested?: () => any,
  onCompleted?: (isNew: boolean, comment?: Comment) => any,
}

export const JournalCommentInputModal = (
    props: JournalCommentInputModalProps,
) => {
  const {journalService} = useApiContext();

  const [comment, setComment] =
    useState<string>(props.initialComment?.text ?? '');

  const isEditing = props.initialComment?.id !== undefined;

  const submit = async () => {
    let result;

    if (isEditing) {
      result = await journalService.updateJournalComment({
        id: props.initialComment?.id ?? 0,
        text: comment,
      });
    } else {
      result = await journalService.createJournalComment({
        entry_id: props.entryId ?? 0,
        text: comment,
      });
    }

    console.log('*** COMMENT result:', result);

    props.onCompleted?.(!isEditing, result.entity);
  };

  return (
    <Modal
      title={localizeText(isEditing ? 'Edit Comment' : 'Add Comment')}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
        />
      }

      body={
        <RichTextInput
          placeholder={'Comment'}
          value={comment}
          onChange={(newValue) => setComment(newValue)}
        />
      }

      footer={<InputPlaceholderMirror/>}
    />
  );
};
