import React, {useState} from 'react';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {Lesson, LessonWithRelations} from 'api/lessons/Lesson';
import {ApiRoute} from 'api/ApiRoute';
import {useLogger} from 'logging/logging';
import {SkillWithRelations} from 'api/skills/Skill';
import classNames from 'classnames';
import {LessonDetailsModal} from 'views/lessons/LessonDetailsModal';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {AssignLessonGoalModal} from 'views/lessons/AssignLessonGoalModal';
import {ModalAsync} from 'components/ModalAsync';
import {GoalType} from 'api/goals/GoalType.enum';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

export interface SkillRelatedLessonsModalProps {
  skillId: number,
  onCloseRequested?: () => any,
  assignOnLessonSelected?: boolean,
}

export const SkillRelatedLessonsModal = (props: SkillRelatedLessonsModalProps) => {
  const logger = useLogger(SkillRelatedLessonsModal.name);
  const {entityListService, skillsService, lessonsService, goalsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const {push: pushRoute} = useHistory();

  const [skill, setSkill] = useState<SkillWithRelations | undefined>(undefined);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [showDetailsLesson, setShowDetailsLesson] = useState<Lesson | undefined>(undefined);
  const [creatingCustomGoal, setCreatingCustomGoal] = useState<boolean>(false);
  const [assignGoalLesson, setAssignGoalLesson] = useState<LessonWithRelations | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);

  const startCustomGoal = () => setCreatingCustomGoal(true);

  const fetchSkill = async () => {
    const fetchedSkill = await skillsService.fetchSkill(props.skillId);
    setSkill(fetchedSkill);
    logger.debug('Fetched skill', fetchedSkill);
  };

  const fetchRelatedLessons = async () => {
    const fetchedLessons = await entityListService.fetchEntityList(ApiRoute.Lessons, {
      filters: {ilri: props.skillId},
    });
    setLessons(fetchedLessons);
    logger.debug('Fetched lessons', fetchedLessons);
  };

  const startAssignLessonGoal = async (lesson: Lesson) => {
    const fullLesson = await lessonsService.fetchLesson(lesson.id);
    setAssignGoalLesson(fullLesson);
  };

  const assignGoal = async (lesson: Lesson) => {
    try {
      setIsSaving(true);
      const fullLesson = await lessonsService.fetchLesson(lesson.id);
      const createResult = await goalsService.createGoal({
        name: skill?.name.replace('I know', 'I want to learn') ?? '',
        description: fullLesson.text ?? undefined,
        type: GoalType.User,
        steps: fullLesson.steps.map((lessonStep) => ({
          ...lessonStep,
          id: undefined,
          resources: lessonStep.resources.map((resource) => ({
            ...resource,
            id: undefined,
            completed: false,
            completeDate: undefined,
          })),
        })),
        category: fullLesson.category,
        color: fullLesson.category.color,
        icon: fullLesson.category.icon,
      });
      logger.debug('Created goal', createResult);
      pushRoute(AppRoute.DashboardContext + AppRoute.Goals);
    } catch (e) {
      showErrorNotification('Error', 'Failed to create goal');
      logger.error('Error creating goal', e);
    } finally {
      setIsSaving(false);
    }
  };

  return <ModalAsync
    title={__('Related Lessons')}
    onCloseRequested={props.onCloseRequested}

    init={() => Promise.all([fetchSkill(), fetchRelatedLessons()])}

    body={
      <>
        {isSaving &&
          <LoadingIndicator fullscreen={true} message={'Saving content...'}/>
        }

        {showDetailsLesson &&
          <LessonDetailsModal
            lessonId={showDetailsLesson.id}
            onCloseRequested={() => setShowDetailsLesson(undefined)}
          />
        }

        {creatingCustomGoal &&
          <GoalInputModal
            onCloseRequested={() => setCreatingCustomGoal(false)}
          />
        }

        {assignGoalLesson &&
          <AssignLessonGoalModal
            lesson={assignGoalLesson}
            onCloseRequested={() => setAssignGoalLesson(undefined)}
          />
        }

        <div className="well clearfix row-fluid" style={{width: 'auto'}}>
          <div className="span12">
            <h2>Select a lesson to build a goal</h2>
          </div>
        </div>

        <div className="well clearfix row-fluid" style={{width: 'auto'}}>

          {lessons.length < 1 &&
            <div style={{cursor: 'pointer'}} onClick={startCustomGoal} aria-label="Create Custom Goal">
              <div
                className="circle-30"
                style={{
                  float: 'left',
                  marginRight: 15,
                  borderColor: skill?.category?.color,
                }}
              >
                <span
                  className={classNames(
                      'icon-plus-circled',
                      skill?.category?.icon,
                  )}
                  style={{fontSize: 27, color: skill?.category?.color}}
                />
              </div>
              <h4>{__('Click Here to build a custom goal to learn this skill.')}</h4>
            </div>
          }

          <div style={{display: 'none'}}/>

          {lessons.map((lesson) => (
            <div key={lesson.id} className="span12">
              {lessons.map((lesson) => (
                <div
                  key={lesson.id}
                  className="pill pointer span10 clearfix"
                  onClick={() => {
                    if (!props.assignOnLessonSelected) {
                      startAssignLessonGoal(lesson);
                    } else {
                      assignGoal(lesson);
                    }
                  }}
                  aria-label="Assign Lesson"
                  style={{marginBottom: 4}}
                >
                  <div
                    className="circle-20"
                    style={{
                      float: 'left',
                      marginRight: 15,
                      backgroundColor: skill?.category?.color,
                    }}
                  >
                    <span
                      className={classNames(
                          'icon {{item.category.icon}}',
                          lesson.category?.icon,
                      )}
                    />
                  </div>
                  {lesson.name}
                </div>
              ))}

              <div
                className="btn pull-right"
                onClick={() => setShowDetailsLesson(lesson)}
                aria-label="View Lesson"
                style={{marginBottom: 4}}
              >
                <span className="icon-eye"/>
              </div>
            </div>
          ))}
        </div>
      </>
    }
  />;
};
