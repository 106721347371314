import React, {FormEvent, useState} from 'react';
import {localizeText} from 'i18n/localize';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useApiContext} from 'api/ApiContext';
import {AppRoute} from 'routing/AppRoute';
import {Link} from 'react-router-dom';

export const ForgotPasswordView = () => {
  const {showErrorNotification} = useNotificationContext();
  const {authService} = useApiContext();

  const [email, setEmail] = useState<string>('');

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (email.trim().length < 1) {
      showErrorNotification('Error', 'Please enter your email address');
      return;
    }

    const result = await authService.sendForgottenPasswordRequest(email);
    if (result.ok) {
      showErrorNotification(
          'Info',
          'Your password was sent to your email',
      );
    } else {
      showErrorNotification(
          'Error',
          'Can\'t find user with that email, sorry',
      );
    }
  };

  return (
    <div className="pane city" style={{bottom: 0}}>
      <div className="body">
        <form className="login" onSubmit={submit}>
          {/* <link*/}
          {/*  rel="stylesheet"*/}
          {/*  ng-href="/api/public/style/{{ login.instance.key }}"*/}
          {/*  ng-if="login.instance.style"*/}
          {/* />*/}

          <div
            // ng-if="!login.instance"
            style={{margin: '0 auto 25px', width: 290}}
          >
            <img src="images/my-full-logo.png" alt="My Full Life"/>
          </div>

          {false &&
          <div
            // ng-if="login.instance"
            style={{
              margin: '0 auto 25px',
              width: 290,
              textAlign: 'center',
            }}
          >
            <div
              // ng-if="login.instance.logo"
              className="instanceLogo"
            >
              <img
                // ng-src="/api/schools/logo/{{ login.instance.id }}"
                alt="{{ login.instance.name }}"
              />
            </div>
            <div className="instanceName">
              instance name
              {/* {{ login.instance.name }}*/}
            </div>
          </div>
          }

          <div className="login_pack" style={{width: 250}}>
            <h4 style={{fontSize: 14}}>
              Forgot your password?
            </h4>

            <div
              className="control-group row-fluid"
              style={{position: 'relative'}}
            >
              <div className="span9">
                <i className="icon icon-mail log"/>
                <label htmlFor={'email-input'} style={{display: 'none'}}>
                  Email
                </label>
                <input
                  id={'email-input'}
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control span12"
                  placeholder={localizeText('Enter your email address')}
                  style={{
                    marginBottom: 2,
                    borderRadius: '4px 0 0 10px',
                    paddingLeft: 30,
                  }}
                />
              </div>
              <div className="span3" style={{marginLeft: 2}}>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{height: 30, borderRadius: '0px 10px 4px 0px'}}
                >
                  {localizeText('Submit')}
                </button>
              </div>
            </div>
            <div
              className="btn-group btn-micro pull-right"
              style={{marginTop: 15}}
            >
              <Link
                to={AppRoute.Login}
                className="btn btn-inverse"
                style={{
                  marginTop: -14,
                  color: 'white',
                  marginLeft: 30,
                  textDecoration: 'none',
                }}
              >
                Sign In
              </Link>
            </div>
          </div>
        </form>

        <div
          className="login_pack"
          style={{
            width: 250,
            fontSize: 12,
            paddingBottom: 20,
            borderRadius: '10px 30px',
            textAlign: 'center',
          }}
        >
          <a
            target="_blank"
            href="http://www.independentfutures.com/" rel="noreferrer"
          >
            <img
              src="/images/centric-logo.png"
              className="pull-left"
              style={{height: 50, marginLeft: 20}}
              alt="Center For Independent Futures"
            />
          </a>

          <div>
            <a
              style={{color: 'white'}}
              target="_blank"
              href="http://www.centriclearning.pro" rel="noreferrer"
            >
              About
            </a>
            {' '}|{' '}
            <a style={{color: 'white'}} href="docs/centric_TOS.pdf">
              Terms of Service
            </a>
            {' '}|{' '}
            <a style={{color: 'white'}} href="docs/centric_Privacy.pdf">
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
