import React, {useEffect, useState} from 'react';
import {Template} from 'api/templates/Template';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import classNames from 'classnames';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {Button, ButtonVariant} from 'components/buttons/Button';

export interface TemplateInputModalProps {
  initialTemplate?: Template,
  onCloseRequested?: () => any,
  onComplete?: (template?: Template) => any,
}

export const TemplateInputModal = (props: TemplateInputModalProps) => {
  const logger = useLogger(TemplateInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {templatesService} = useApiContext();

  const [name, setName] = useState<string>(props.initialTemplate?.name ?? '');
  const [body, setBody] = useState<string>(props.initialTemplate?.data ?? '');
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (name.trim().length < 1) {
      setErrors((current: any) => ({...current, name: true}));
      isValid = false;
    }
    if (body.trim().length < 1) {
      setErrors((current: any) => ({...current, body: true}));
      isValid = false;
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    let saveResult;
    if (props.initialTemplate) {
      saveResult = await templatesService.updateTemplate({
        ...props.initialTemplate,
        name,
        data: body,
      });
      logger.debug('Updated template', saveResult);
    } else {
      saveResult = await templatesService.createTemplate({
        name,
        data: body,
      });
      logger.debug('Created template', saveResult);
    }

    props.onComplete?.(saveResult.entity);
  };

  useEffect(() => {
    validate();
  }, [name, body]);

  return <Modal
    title={__(props.initialTemplate ? 'Edit Template' : 'Add Template')}
    onCloseRequested={props.onCloseRequested}
    className={'wide'}

    buttons={
      <Button variant={ButtonVariant.Confirm} onClick={submit} />
    }

    body={
      <form name="form.edit">
        <div className="row-fluid">
          <input
            placeholder={__('Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className={classNames('span12', {'invalid': errors.name})}
          />
        </div>
        <div className="row-fluid">
          <RichTextInput
            value={body}
            onChange={(newBody) => setBody(newBody)}
            placeholder={__('Template')}
          />
        </div>
      </form>
    }
  />;
};
