import React, {useState} from 'react';
import {useNotificationContext} from 'contexts/NotificationContext';
import {AuthErrorCode} from 'api/auth/AuthErrorCode';
import {useAuthContext} from 'contexts/AuthContext';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

/**
 * The user sign in view
 */

export interface LoginViewProps {
  onSuccessfulLogin?: () => any,
}

export const LoginView = (props: LoginViewProps) => {
  const {authenticateUser} = useAuthContext();
  const {showErrorNotification} = useNotificationContext();
  const {pathname} = useLocation();
  const {push: pushRoute} = useHistory();

  const [inputUsername, setInputUsername] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const signIn = async () => {
    if (inputUsername.trim().length < 1 || inputPassword.trim().length < 1) {
      showErrorNotification('Error', 'Please enter username and password');
      return;
    }

    setIsSubmitting(true);

    const authResult = await authenticateUser(inputUsername, inputPassword);
    if (authResult.ok) {
      if (pathname === AppRoute.Login) {
        pushRoute(AppRoute.Home);
      }
      props.onSuccessfulLogin?.();
    } else {
      let errorMessage = 'Login failed.';
      if (authResult.code === AuthErrorCode.InvalidLogin) {
        errorMessage = 'Invalid username or password.';
      } else if (authResult.code === AuthErrorCode.UserSuspended) {
        errorMessage = 'This user was suspended.';
      }
      showErrorNotification('Error', errorMessage);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="pane city" style={{bottom: 0}}>
      <div className="body">
        <div id="content" style={{top: 0, bottom: 0}}>
          <div className="pane register" style={{bottom: 0, margin: '0 auto 25px'}}>
            <div className="body" style={{padding: 40}}>
              <div
                className="pack"
                style={{
                  backgroundImage: 'url(/images/clip0007.jpg)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'top center',
                }}
              >
                <div className="row-fluid">
                  <div className="span4">
                    <div className="form-header">
                      <img
                        src="/images/my-full-logo.png"
                        alt="My Full Life"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="span4">
                    <form
                      className="control-group row-fluid"
                      style={{position: 'relative'}}
                      onSubmit={async (e) => {
                        e.preventDefault();
                        await signIn();
                      }}
                    >
                      <div className={'span9'}>
                        <i className="icon icon-user log" />
                        <label htmlFor={'usernameInput'} style={{display: 'none'}}>
                              Username
                        </label>
                        <input
                          id={'usernameInput'}
                          type={'text'}
                          placeholder={'Username'}
                          className="form-control span12"
                          value={inputUsername}
                          onChange={(e) => {
                            setInputUsername(e.target.value);
                          }}
                          style={{
                            marginBottom: 2,
                            borderRadius: '4px 0 0 0',
                            paddingLeft: 30,
                          }}
                        />

                        <i className={'icon icon-key log'} style={{top: 40}}/>
                        <label htmlFor={'passwordInput'} style={{display: 'none'}}>
                              Password
                        </label>
                        <input
                          id={'passwordInput'}
                          placeholder={'Password'}
                          className="form-control span12"
                          value={inputPassword}
                          type={'password'}
                          onChange={(e) => setInputPassword(e.target.value)}
                          style={{
                            borderRadius: '0 0 0 10px',
                            paddingLeft: 30,
                          }}
                        />
                      </div>
                      <div className="span3" style={{marginLeft: 1}}>
                        <button
                          type={'submit'}
                          className={'btn btn-warning span12'}
                          style={{
                            height: 62,
                            borderRadius: '0px 20px 4px 0',
                            fontSize: 30,
                          }}
                        >
                          {isSubmitting && (
                            <i className="icon icon-arrows-cw icon-spin" />
                          )}
                          {!isSubmitting && (
                            <span>
                              <i className="icon icon-login" />
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    <Link
                      to={AppRoute.ForgotPassword}
                      className="pull-right"
                      style={{
                        color: 'white',
                        marginLeft: 30,
                        textDecoration: 'none',
                      }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="pack span3">
                    Welcome to our portal. If you are interested in My Full Life please visit
                    {' '} <a href="http://myfulllife.com" target="new"> myfulllife.com</a> for
                    {' '} more information and a 30 Day Trial.
                  </div>
                </div>
              </div>
              <div className="row-fluid pack body" style={{backgroundColor: '#4B4588', padding: '10px 0'}}>
                <a target="_blank" href="http://www.independentfutures.com/" rel="noreferrer">
                  <img
                    src="/images/centric-logo.png"
                    className="pull-left"
                    style={{height: 50, marginLeft: 20}}
                    alt="Center For Independent Futures"
                  />
                </a>
                <div style={{padding: '10px 15px', textAlign: 'center'}}>
                  <a
                    style={{color: 'white'}}
                    target="_blank"
                    href="https://independentfutures.com/"
                    rel="noreferrer"
                  >
                      About us
                  </a>
                  {' | '}
                  <a style={{color: 'white'}} href="/docs/centric_TOS.pdf">
                      Terms of Service
                  </a>
                  {' | '}
                  <a style={{color: 'white'}} href="/docs/centric_Privacy.pdf">
                      Privacy policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
