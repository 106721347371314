import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {SaveActionStepDto} from 'api/goals/ActionStep';
import classNames from 'classnames';
import {useNotificationContext} from 'contexts/NotificationContext';
import {ActionStepType} from 'api/goals/ActionStepType.enum';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {ResourceInputModal} from 'views/resources/ResourceInputModal';
import {Skill} from 'api/skills/Skill';
import dayjs from 'dayjs';
import {DateInput} from 'components/forms/DateInput';

export interface ActionStepInputModalProps {
  type: ActionStepType,
  initialStep?: SaveActionStepDto,
  onCloseRequested?: () => any,
  onComplete?: (actionStep: SaveActionStepDto) => any,
}

export const ActionStepInputModal = (props: ActionStepInputModalProps) => {
  const logger = useLogger(ActionStepInputModal.name);
  const {skillsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.initialStep?.id !== undefined;

  const [newActionStep, setNewActionStep] = useState<SaveActionStepDto>({
    title: '',
    type: props.type,
    enabled: true,
    resources: [],
    ilries: [],
    ...props.initialStep,
    delay: props.initialStep?.delay ?? 0,
  });
  const [errors, setErrors] = useState<any>({});
  const [showAddResource, setShowAddResource] = useState<boolean>(false);
  const [resourceHasError, setResourceHasError] = useState<boolean>(false);

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (newActionStep.title.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, title: true}));
    }
    if (newActionStep.delay === undefined) {
      isValid = false;
      setErrors((current: any) => ({...current, delay: true}));
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    logger.debug('Passing action step', newActionStep);
    props.onComplete?.(newActionStep);
  };

  useEffect(() => {
    validate();
  }, [newActionStep]);

  return (
    <Modal
      title={__(isEditing ? 'Edit Action Step' : 'Add Action Step')}
      onCloseRequested={props.onCloseRequested}
      className={'tall'}
      bodyClassName={'lesson-editor'}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
        />
      }

      body={
        <form name="form.edit" onSubmit={(e) => e.preventDefault()}>
          {showAddResource &&
            <ResourceInputModal
              onCloseRequested={() => setShowAddResource(false)}
              onComplete={(resource) => {
                setResourceHasError(false);
                setNewActionStep((current) => ({...current, resources: [resource]}));
                setShowAddResource(false);
              }}
            />
          }

          <div className="row-fluid">
            <div className="span12" aria-label="Directions">
              <h1><i className="icon icon-check" /> Action Step</h1>
              <ul style={{margin: 10, marginTop: 20, borderBottom: '1px solid #a8a8a8'}} >
                <li className="info"> Enter the title of your action step. Note: these should be a specific measurable
                  task like, &ldquo;Take the bus to work&rdquo; or, &ldquo;Complete this learning check&rdquo;
                </li>
                <li className="info">Most action steps in My Full Life have a leaning resource attached. If one is not
                  already attached you can attach one by hitting the add resource button.
                </li>
                <li className="info">If an action step shows the demonstration of a skill, you should add the skill.
                  Once the step is completed, it will update the skills inventory.
                </li>
                <li className="info">Certain steps may need to be completed by a given date. If this is the case, select
                  a due date and check &ldquo;Time Critical.&rdquo; Then, if this step is not completed on the time,
                  the goal will be marked incomplete. Note: This is a great tool to use with repeating goals.
                </li>
                <li className="info">When you are done, hit the green checkmark to save the action step.</li>
              </ul>
            </div>
          </div>

          <div className="row-fluid">
            <input
              placeholder={__('Action Step')}
              id="title"
              type="text"
              value={newActionStep.title}
              aria-label="Action Step"
              onChange={(e) => setNewActionStep((current) => ({
                ...current,
                title: e.target.value,
              }))}
              className={classNames('span12', {'invalid': errors.title})}
            />
            <div className="info">{__('Action Step')}</div>
          </div>
          {(newActionStep.due || newActionStep.type === ActionStepType.UserGoal) &&
            <div className="row-fluid" id="due">
              {newActionStep.due &&
                <div>
                  <div className="span3">
                    <DateInput
                      value={new Date(newActionStep.due)}
                      onChange={(newValue) => setNewActionStep((current) => ({
                        ...current,
                        due: newValue.getSeconds(),
                      }))}
                    />
                    <input
                      type="text"
                      className="span12"
                      value={dayjs(new Date(newActionStep.due)).format('MM/DD/YY')}
                      onChange={(e) => setNewActionStep((current) => ({
                        ...current,
                        due: parseInt(e.target.value),
                      }))}
                      placeholder={__('Due Date')}
                      aria-label="Due Date"
                    />
                    <div className="info">{__('Due Date')}</div>
                  </div>

                  <label className="checkbox span3">
                    <input
                      type="checkbox"
                      value="false"
                      checked={newActionStep.timeCritical}
                      onChange={(e) => setNewActionStep((current) => ({
                        ...current,
                        timeCritical: e.target.checked,
                      }))}
                      aria-label="Time Critical"
                    />
                    <span>{__('Time Critical')}</span>
                  </label>

                  <input
                    type="number"
                    min="0"
                    className="span6"
                    aria-label="Approximate Time"
                    placeholder={__('Approximate Time (in min)')}
                  />
                  <div className="info">{('Approximate Time')}</div>
                </div>
              }
            </div>
          }

          {[ActionStepType.SyllabusGoal, ActionStepType.Lesson].includes(props.type) &&
            <div className="row-fluid">
              <input
                aria-label="Delay"
                placeholder={__('Delay (in days)')}
                value={newActionStep.delay}
                onChange={(e) => setNewActionStep((current) => ({
                  ...current,
                  delay: parseInt(e.target.value),
                }))}
                type="number"
                min="0"
                className={classNames('span12', {'invalid': errors.delay})}
              />
              <div className="info">{__('Delay')}</div>
            </div>
          }
          <div className="row-fluid" />
          <AccessControlled feature={AppFeature.GoalsStepSkill}>
            <div className="row-fluid">
              <div className="span12" id="skills">
                <DynamicSelect<Skill>
                  label={__('Skills')}
                  placeholder={__('Skills')}
                  multiple={true}
                  fetch={(filter) => skillsService.fetchSkills(filter)}
                  value={newActionStep.ilries}
                  onSelect={(newSkills) => setNewActionStep((current) => ({
                    ...current,
                    ilries: newSkills,
                  }))}
                  isOptionSelected={(skill) => newActionStep.ilries.some((s) => s.id === skill.id)}
                />
              </div>
            </div>
          </AccessControlled>
          <div className="row-fluid" id="resources">
            <div className={'list'}>
              <h4>Step Resource</h4>
              {newActionStep.resources.length < 1 &&
                <Button
                  aria-label="Add Resource"
                  text={__('Add Resource')}
                  iconCode={'icon-plus-circled'}
                  className={'btn-success'}
                  onClick={() => setShowAddResource(true)}
                />
              }
              <div>
                <table className="list inner-list">
                  <thead>
                    <tr>
                      <td>{__('Name')}</td>
                      <td width={35} />
                    </tr>
                  </thead>
                  <tbody>
                    <Fragment>
                      {resourceHasError &&
                        <tr>
                          <td>{__('Error Uploading Resource')}</td>
                          <td></td>
                        </tr>
                      }

                      {!resourceHasError && newActionStep.resources.map((resource, i) => (
                        <tr key={i}>
                          <td>{resource.resource.name}</td>
                          <td>
                            <Button
                              variant={ButtonVariant.Delete}
                              onClick={() => setNewActionStep((current) => ({
                                ...current,
                                resources: [],
                              }))}
                            />
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      }
    />
  );
};
