import React, {useEffect, useState} from 'react';
import {Select, SelectBaseProps} from 'components/forms/Select';
import {useApiContext} from 'api/ApiContext';
import {localizeText} from 'i18n/localize';
import {Skill} from 'api/skills/Skill';

export interface SkillPickerProps extends SelectBaseProps {
  value?: number | number[],
  onChange?: (newValue: number | number[]) => any,
}

export const SkillPicker = (props: SkillPickerProps) => {
  const {skillsService} = useApiContext();
  const [skills, setSkills] = useState<Skill[]>([]);

  useEffect(() => {
    (async () => {
      setSkills(await skillsService.fetchSkills({}));
    })();
  }, []);

  return (
    <Select
      placeholder={props.placeholder ?? localizeText('Skill')}
      multiple={props.multiple ?? true}
      value={
        props.multiple ?
          (props.value as number[])?.map((value) => value.toString()) :
          (props.value as number)?.toString()
      }
      options={skills.map((skill) => ({
        label: skill.name,
        value: skill.id.toString(),
      }))}
      onChange={(newValue) => {
        if (!props.multiple) {
          props.onChange?.(parseInt(newValue as string));
        } else {
          props.onChange?.(
              (newValue as string[]).map((value) => parseInt(value)),
          );
        }
      }}
    />
  );
};
