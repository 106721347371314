import {Modal} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';

/**
 * A loading indicator ("spinner") to show the user that data is being fetched/sent/processed
 */

export interface LoadingIndicatorProps {
  message?: string;
  fullscreen?: boolean; // cover the entire window (ie. "splash" loader)
  background?: string;
  showDelay?: number, // delay (in ms) before showing indicator (useful to prevent quick flashing)
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const showDelay = props.showDelay ?? 300;
  const [delayMet, setDelayMet] = useState<boolean>(false);
  let timeoutHandle: any = useRef(() => null);

  useEffect(() => {
    timeoutHandle = setTimeout(() => setDelayMet(true), showDelay);
    return () => clearTimeout(timeoutHandle);
  }, []);

  if (!delayMet) {
    return <></>;
  }

  if (!props.fullscreen && !props.message) {
    return <div className={'pager-loader'}/>;
  }

  if (props.fullscreen) {
    return (
      <Modal open={true} className={'modal splash-screen'}>
        <>
          <div className="wrapper">
            <div className="message">
              <div className="body">
                <div className="row-fluid">
                  <div className="span12">
                    <div
                      className="goal"
                      style={{
                        padding: 10,
                        height: 60,
                        width: 60,
                        position: 'relative',
                        display: 'inline-block',
                        float: 'left',
                      }}
                    >
                      <div className="loading">
                        <img src="images/loaders/circle-50.gif" alt={''}/>
                      </div>
                    </div>
                    <h1 role="alert" aria-busy={true}>
                      {props.message ?? 'Saving Content'}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  } else {
    return (
      <div
        className="pack"
        style={{
          width: 320,
          margin: '0 auto',
          background: props.background,
        }}
      >
        <div
          className="goal"
          style={{
            padding: 10,
            height: 60,
            width: 60,
            position: 'relative',
            display: 'inline-block',
            float: 'left',
          }}
        >
          <div className="loading">
            <img src="images/loaders/circle-50.gif" alt={'Loading...'} />
          </div>
        </div>

        <div style={{padding: 10}}>
          <div
            className="name"
            style={{
              padding: '20px 0',
              background: 'none',
              color: '#6A6A6A',
            }}
            role="alert"
            aria-busy={true}
          >
            {props.message ?? 'Loading'}
          </div>
        </div>

        <div style={{clear: 'both'}} />
      </div>
    );
  }
};
