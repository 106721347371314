import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {ListFilter} from 'api/lists/ListFilter';
import styleClasses from './Select.module.scss';
import {SelectDropdownIndicator} from 'components/forms/SelectDropdownIndicator';

/**
 * A select input with dynamically generated options based on user search input
 *
 * This is a generic component, so a type can be supplied in order to get typed values in callbacks.
 * Eg. <DynamicSelect<Category> /> will provide typed Category arguments to the onSelect and other callbacks.
 */

export interface DynamicSelectProps<T> {
  fetch: (filter: ListFilter) => Promise<T[]>,
  fetchFilters?: Object,
  fetchOnInit?: boolean,
  value?: T[],
  onSelect?: (newValues: T[]) => any,
  generateOption?: (item: T) => {value: T, label: string},
  isOptionSelected?: (value: T) => boolean,
  onBlur?: () => any,
  multiple?: boolean,
  optionLimit?: number,
  placeholder?: string,
  clearable?: boolean,
  label?: string,
  name?: string,
  isInvalid?: boolean,
  searchable?: boolean,
  styles?: any,
}

/**
 * Default option generator for name label and full object value
 *
 * NOTE: object type must have a name property for this to work
 */
function defaultGenerateOption<T>(item: T) {
  return {
    value: item,
    label: (item as any).name ?? '',
  };
}

export const DynamicSelect = <T, >(props: DynamicSelectProps<T>) => {
  const [options, setOptions] = useState<{value: T, label: string}[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(props.fetchOnInit ?? true);

  const generateOption = props.generateOption ?? defaultGenerateOption;
  const optionLimit = props.optionLimit ?? 100;

  const fetchOptions = (searchInput = '') => {
    setIsLoading(true);
    props.fetch({
      start: 0,
      end: optionLimit,
      search: searchInput,
      filters: props.fetchFilters,
    }).then((fetchedItems) => {
      setOptions(fetchedItems.map((item) => generateOption(item)));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (props.fetchOnInit !== false) {
      fetchOptions();
    }
  }, []);

  return (
    <Select
      className={styleClasses.reactSelect}
      isClearable={!props.multiple && (props.clearable ?? true)}
      isSearchable={props.searchable ?? true}
      aria-label={props.label}
      id={props.name ?? props.label ?? props.placeholder ?? ''}
      name={props.name}
      value={props.value?.map((value) => generateOption(value))}
      placeholder={props.placeholder}
      isMulti={props.multiple ?? false}
      isLoading={isLoading}
      options={options}
      isOptionSelected={(option) => {
        const typedOption = option.value as T;
        if (typedOption) {
          return props.isOptionSelected?.(typedOption) ?? false;
        } else {
          return false;
        }
      }}
      onInputChange={(input) => fetchOptions(input)}
      menuPortalTarget={document.body} // makes menu popup overflow out of modals
      onBlur={props.onBlur}
      components={{DropdownIndicator: SelectDropdownIndicator}}

      onChange={(selected) => {
        if (selected) {
          if (props.multiple) {
            const typedSelected = (selected as {label: string, value: T}[]);
            props.onSelect?.(typedSelected.map((s) => s.value));
          } else {
            const typedSelected = (selected as {label: string, value: T});
            props.onSelect?.([(typedSelected).value]);
          }
        } else {
          props.onSelect?.([]);
        }
      }}

      styles={{
        ...props.styles,
        control: (provided) => ({
          ...provided,
          borderColor: props.isInvalid ? '#df1b17' : '#9e9e9e',
          fontSize: 14,
          marginBottom: 10,
          background: 'linear-gradient(#fff, #eee)',
          boxShadow: props.isInvalid ? '0 0 6px #d59392' : 'none',
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999, // zIndex is needed or menu shows below modals
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          display: props.multiple ? 'none' : 'inline-block',
          borderLeft: '1px solid #aaa',
          borderRadius: '0 4px 4px 0',
          backgroundClip: 'padding-box',
          background: '#ccc',
          backgroundImage:
            '-webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee))',
        }),
      }}
    />
  );
};
