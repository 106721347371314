import React, {useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {Instructor} from 'api/users/Instructor';
import {useLogger} from 'logging/logging';
import {InstructorQuickView} from 'views/instructors/InstructorQuickView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {PageView} from 'layout/PageView';

/**
 * List of instructors the user has access to
 */

export const InstructorsListView = () => {
  const logger = useLogger(InstructorsListView.name);
  const {usersService} = useApiContext();

  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    (async () => {
      const fetchedInstructors = await usersService.fetchInstructors({
        search: searchTerm,
      });
      setInstructors(fetchedInstructors);
      logger.debug('Fetched instructors', fetchedInstructors);
    })();
  }, [searchTerm]);

  return <PageView>
    <ContextMenu>
      <div
        className="pull-left infoBubble"
        style={{marginTop: 8, marginRight: 5}}
      >
        <i className="icon icon-user" />
        {' '}
        <span className="badge ">
          {instructors.length}
        </span>
      </div>
      <SearchInput
        value={searchTerm}
        onChange={(newValue) => setSearchTerm(newValue)}
      />
    </ContextMenu>

    <div style={{marginTop: 40}}>
      {instructors.map((instructor) => (
        <InstructorQuickView key={instructor.id} instructor={instructor}/>
      ))}
    </div>
  </PageView>;
};
