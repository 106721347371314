import React, {ReactElement, useState} from 'react';
import classNames from 'classnames';

export interface CollapsibleSectionProps {
  heading: string,
  body: ReactElement,
  className?: string,
}

export const CollapsibleSection = (props: CollapsibleSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <div
      className={classNames(
          'section',
          {'opened': !isCollapsed},
      )}
    >
      <h4 onClick={() => setIsCollapsed((current) => !current)}>
        {props.heading}
      </h4>
      <div className="frame">
        {props.body}
      </div>
    </div>
  );
};
