import React, {useEffect, useState} from 'react';
import {Instructor, InstructorWithRelations} from 'api/users/Instructor';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {UserAvatar} from 'components/UserAvatar';
import {Config} from 'Config';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {InstructorNotesModal} from 'views/instructors/InstructorNotesModal';
import {Tooltip} from 'components/Tooltip';
import {ImageResourceView} from 'views/resources/ImageResourceView';
import {Button, ButtonVariant} from 'components/buttons/Button';

/**
 * Shows instructor info with toggle open/close details
 */

export interface InstructorQuickViewProps {
  instructor: Instructor,
  showInvite?: boolean,
  onInvite?: () => any,
  isInvited?: boolean,
}

export const InstructorQuickView = (props: InstructorQuickViewProps) => {
  // the passed instructor prop does not contain all data needed, but is what
  // is used in list views, so we fetch details within this component.

  const logger = useLogger(InstructorQuickView.name);
  const {usersService} = useApiContext();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [isFetchingDetails, setIsFetchingDetails] = useState<boolean>(false);
  const [instructorDetails, setInstructorDetails] =
    useState<InstructorWithRelations | undefined>(undefined);
  const [showCv, setShowCv] = useState<boolean>(false);

  const toggleShowDetails = () => {
    setShowDetails((current) => !current);
  };

  const loadDetails = async () => {
    setIsFetchingDetails(true);
    const fetchedDetails = await usersService.fetchInstructor(props.instructor.id);
    setInstructorDetails(fetchedDetails);
    logger.debug('Fetched instructor', fetchedDetails);
    setIsFetchingDetails(false);
  };

  useEffect(() => {
    if (showDetails && instructorDetails === undefined) {
      (async () => {
        await loadDetails();
      })();
    }
  }, [showDetails]);

  return (
    <>
      {showCv &&
      <ImageResourceView
        url={instructorDetails?.cv?.url ?? ''}
        onCloseRequested={() => setShowCv(false)}
      />
      }

      {showNotes &&
      <InstructorNotesModal
        instructorId={props.instructor.id}
        initialNotes={instructorDetails?.properties}
        onCloseRequested={() => setShowNotes(false)}
        onComplete={async () => {
          await loadDetails();
          setShowNotes(false);
        }}
      />
      }

      <div
        className={classNames(
            'instructor pack expandable',
            {'expanded': showDetails},
        )}
      >
        <div className="header" onClick={toggleShowDetails}>
          <div className="name">{props.instructor.name}</div>
          <div className="details">
            <div className="field">
              <span>{__('Active Modules:')}</span>
              <span className="badge">
                {instructorDetails?.courses.filter((c) => c.active).length}
              </span>
            </div>
            <div className="field">
              <span>{__('Inactive Modules:')}</span>
              <span className="badge">
                {instructorDetails?.courses.filter((c) => !c.active).length}
              </span>
            </div>
          </div>

          {props.showInvite &&
            <div className="buttons btn-group">
              <Button
                variant={ButtonVariant.Confirm}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!props.isInvited) {
                    props.onInvite?.();
                  }
                }}
                tooltip={__(props.isInvited ? 'already invited' : 'invite instructor')}
                iconCode={props.isInvited ? 'icon-ok' : 'icon-plus'}
                text={props.isInvited ? undefined : __('Invite')}
                disabled={props.isInvited}
              />
            </div>
          }

          <div className="clearfix" />
        </div>

        {isFetchingDetails &&
        <div className="body">
          <div className="pager-loader"/>
        </div>
        }

        {!isFetchingDetails && instructorDetails &&
        <div className="body">
          <div className="left-column">
            <div className="image block">
              <UserAvatar
                src={instructorDetails.image ?
                  instructorDetails.image.url :
                  Config.defaultUserImage
                }
              />
            </div>

            <div className="details block">
              <table>
                <tbody>
                  <tr>
                    <th><span>Practice</span>:</th>
                    <td>{instructorDetails.practice}</td>
                  </tr>
                  <tr>
                    <th><span>Address</span>:</th>
                    <td>
                      {instructorDetails.street}
                      <br />
                      {instructorDetails.city},
                      {' '}
                      {instructorDetails.state},
                      {' '}
                      {instructorDetails.zip}
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <th><span>Email</span>:</th>
                    <td>{instructorDetails.email}</td>
                  </tr>
                  <tr>
                    <th><span>Phone</span>:</th>
                    <td>{instructorDetails.phone}</td>
                  </tr>
                  {instructorDetails.cv &&
                    <tr>
                      <th><span>CV</span>:</th>
                      <td>
                        <a onClick={() => setShowCv(true)}>View</a>
                      </td>
                    </tr>
                  }
                  {instructorDetails.keywords &&
                    <tr>
                      <th><span>Keywords</span>:</th>
                      <td>{instructorDetails.keywords}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>

            <AccessControlled feature={AppFeature.InstructorProperties}>
              <div
                className="details block"
                style={{width: '100%', boxSizing: 'border-box'}}
              >
                <div
                  className="buttons btn-group pull-right"
                  style={{marginRight: -60}}
                >
                  <Tooltip title={__('edit notes')}>
                    <a
                      className="btn btn-icon btn-mini"
                      onClick={() => setShowNotes(true)}
                      role={'button'}
                      aria-label={'edit notes'}
                    >
                      <i className="icon-edit" />
                    </a>
                  </Tooltip>
                </div>
                <div className="title">
                  <span>{__('Notes')}</span>
                </div>
                {instructorDetails.properties?.map((note, i) => (
                  <div key={i}>
                    <h6
                      style={{
                        margin: '3px 0', lineHeight: '15px', color: '#4B4A4A',
                      }}
                    >
                      {note.key}:
                    </h6>
                    <div style={{lineHeight: '15px', marginBottom: 15}}>
                      {note.value}
                    </div>
                  </div>
                ))}
              </div>
            </AccessControlled>
          </div>

          <div className="right-column">
            <div className="biography block">
              <div className="title">{__('Biography')}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: instructorDetails.biography ?? '',
                }}
              />
            </div>

            {/* TODO: achievement block */}
            {/* <div className="achievements block"*/}
            {/*     ng-if="user.achievements.length">*/}
            {/*  <div className="title" localize>Badges</div>*/}
            {/*  <div className="pill"
               ng-repeat="item in user.achievements">*/}
            {/*    <div className="circle-20" style="float:right"*/}
            {/*         ng-style="{background: item.category.color}">*/}
            {/*      <span className="icon"
               ng-class="item.category.icon"></span>*/}
            {/*    </div>*/}
            {/*    {{ item.name }}</div>*/}
            {/* </div>*/}

            {instructorDetails.courses.filter((c) => c.active).length > 0 &&
            <div className="courses active block">
              <div className="title">{__('Active Modules:')}</div>
              {instructorDetails.courses.filter((c) => c.active).map((course) => (
                <div key={course.id} className="pill">
                  <div className="circle-20" style={{float: 'right', background: course.category?.color}}>
                    <span className={classNames('icon', course.category?.icon)} />
                  </div>
                  {course.name}
                </div>
              ))}
            </div>
            }

            {instructorDetails.courses.filter((c) => !c.active).length > 0 &&
            <div className="courses inactive block">
              <div className="title">
                {__('Inactive Modules:')}
              </div>

              {instructorDetails.courses.filter((c) => !c.active).map((course) => (
                <div key={course.id} className="pill">
                  <div className="circle-20" style={{float: 'right', background: course.category?.color}}>
                    <span className={classNames('icon', course.category?.icon)} />
                  </div>
                  {course.name}
                </div>
              ))}
            </div>
            }
          </div>

          <div className="clearfix" />
        </div>
        }
      </div>
    </>
  );
};
