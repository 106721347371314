import React from 'react';
import {Modal} from 'components/Modal';
import {ResourceBase} from 'api/resources/Resource';

export interface LinkResourceViewProps {
  resource: ResourceBase,
  onCloseRequested?: () => any,
}

export const LinkResourceView = (props: LinkResourceViewProps) => {
  return <Modal
    title={props.resource.name}
    className={'wide tall'}
    onCloseRequested={props.onCloseRequested}
    body={
      <div className="iframe-container">
        <iframe className="link-frame" src={props.resource.resource.link} width="100%" height="500" />
      </div>
    }
  />;
};
