/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {PageView} from 'layout/PageView';
import {Category} from 'api/categories/Category';
import {Skill} from 'api/skills/Skill';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {humanTimeDiff} from 'api/util/dates';
import {Lesson} from 'api/lessons/Lesson';
dayjs.extend(relativeTime);
dayjs.extend(duration);

/**
 * A view containing high-level system info/stats
 */
export const StatsOverviewView = () => {
  const logger = useLogger(StatsOverviewView.name);
  const {statsService} = useApiContext();

  const [newUserCount, setNewUserCount] = useState<number | undefined>(undefined);
  const [oldUserCount, setOldUserCount] = useState<number | undefined>(undefined);
  const [isFetchingNewUserCount, setIsFetchingNewUserCount] = useState<boolean>(true);
  const newUserPercentage = (newUserCount ?? 0) / ((oldUserCount ?? 1) - (newUserCount ?? 0)) * 100;

  const [allUsersCount, setAllUsersCount] = useState<number | undefined>(undefined);
  const [studentCount, setStudentCount] = useState<number | undefined>(undefined);
  const [supportUserCount, setSupportUserCount] = useState<number | undefined>(undefined);
  const [isFetchingUserCount, setIsFetchingUserCount] = useState<boolean>(true);

  const [events, setEvents] = useState<any[]>([]);

  const [recentEventCount, setRecentEventCount] = useState<number | undefined>(undefined);
  const [isFetchingEventCount, setIsFetchingEventCount] = useState<boolean>(true);
  const [isFetchingRecentSkills, setIsFetchingRecentSkills] = useState<boolean>(true);
  const [isFetchingAssignedLessons, setIsFetchingAssignedLessons] = useState<boolean>(true);

  const [categoryGroupedSkillGoals, setCategoryGroupedSkillGoals] = useState<{
    category: Category,
    skillGoals: {skill: Skill, count: number, time: number, humanTime: string}[]
  }[]>([]);
  const [categoryGroupedAssignedLessons, setCategoryGroupedAssignedLessons] = useState<{
    category: Category,
    lessons: {lesson: Lesson, count: number}[]
  }[]>([]);

  const fetchNewUserCount = async () => {
    const statInspectionPeriod = 90; // look back 90 days
    setIsFetchingNewUserCount(true);
    const result = await statsService.fetchNewUserCount(statInspectionPeriod);
    setNewUserCount(result);
    logger.debug('Fetched new user count', result);
    const oldResult = await statsService.fetchNewUserCount(statInspectionPeriod * 2);
    setOldUserCount(oldResult);
    logger.debug('Fetched old user count', oldResult);
    setIsFetchingNewUserCount(false);
  };

  const fetchUserCount = async () => {
    setIsFetchingUserCount(true);
    const result = await statsService.fetchUserCount();
    setAllUsersCount(result.userCount);
    setStudentCount(result.students);
    setSupportUserCount(result.support);
    logger.debug('Fetched user count', result);
    setIsFetchingUserCount(false);
  };

  const fetchEvents = async () => {
    const result = await statsService.fetchStatEvents(dayjs().subtract(3, 'months').toDate(), new Date());
    logger.debug('Fetched event stats', result);
  };

  const fetchRecentSkills = async () => {
    setIsFetchingRecentSkills(true);
    const result = await statsService.fetchRecentSkills(dayjs().subtract(3, 'months').toDate(), new Date());
    setCategoryGroupedSkillGoals(statsService.groupSkillsByCategory(result));
    logger.debug('Fetched recent skills', result);
    setIsFetchingRecentSkills(false);
  };

  const fetchRecentAssignedLessons = async () => {
    setIsFetchingAssignedLessons(true);
    const result = await statsService.fetchRecentGoals(dayjs().subtract(3, 'months').toDate(), new Date());
    setCategoryGroupedAssignedLessons(statsService.groupLessonsByCategory(result));
    logger.debug('Fetched recent goals', result);
    setIsFetchingAssignedLessons(false);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchNewUserCount(),
        fetchUserCount(),
        fetchEvents(),
        fetchRecentSkills(),
        fetchRecentAssignedLessons(),
      ]);
    })();
  }, [statsService]);

  return (
    <PageView className={'dashboard'}>
      <div className="holder">
        <div className="row-fluid">
          <h3>Engagement</h3>
        </div>
        <div className="row-fluid">
          <div className="span3 statbox pointer">
            <div className="statcircle">
              <i className="icon-users" />
            </div>
            {isFetchingNewUserCount && (
              <div className="mainNum">
                <img src="/images/loaders/load.gif" style={{width: 50}} alt={''} />
              </div>
            )}
            {!isFetchingNewUserCount && (
              <>
                <div className="mainNum">{newUserCount}</div>
                <div className="title">New Users</div>
                <div className="change">{newUserPercentage.toFixed(2)}%</div>
              </>
            )}
          </div>
          <div className="span3 statbox pointer">
            <div className="statcircle">
              <i className="icon-cog-alt" />
            </div>
            <div className="mainNum">
              {/* {{engagement | number:2}}
                  <sup className="units"> %</sup>*/}
            </div>
            <div className="mainNum">
              <img
                src="/images/loaders/load.gif"
                style={{width: 50}}
                alt={''}
              />
            </div>
            <div className="title">User Engagement</div>
            <div className="des">
              Participants completing one action a day
            </div>
            <div className="change">
              {/* {{engangementChange|number:2}}%*/}
            </div>
          </div>
          <div className="span3 statbox pointer">
            <div className="statcircle">
              <i className="icon-clock" />
            </div>
            <div className="mainNum">
              {/* {{skillTime.avg.time| number:0}}
                  <sup className="units"> {{skillTime.avg.units}}</sup>*/}
            </div>
            <div className="mainNum">
              <img
                src="images/loaders/load.gif"
                style={{width: 50}}
                alt={''}
              />
            </div>
            <div className="title">Avg. Time to Skill</div>
          </div>
          <div className="span3 statbox dark pointer">
            <div className="statcircle">
              <i className="icon-users" />
            </div>
            <div className={'mainNum'}>
              <>
                {!isFetchingUserCount && allUsersCount}
                {isFetchingUserCount && <img src="images/loaders/load.gif" style={{width: 50}} alt={''} />}
              </>
            </div>
            <div className="title">Active Users</div>
            <div className="subsection">
              <span className="numbers">{studentCount}</span> Student Users
            </div>
            <div className="subsection">
              <span className="numbers">{supportUserCount}</span> Support Users
            </div>
          </div>
        </div>

        <div className="row-fluid">
          <div className="span5">
            <h3>Assigned Lessons</h3>
            {isFetchingAssignedLessons &&
            <div className="mainNum">
              <img src="images/loaders/load.gif" style={{width: 50}} alt={''} />
            </div>
            }
            {!isFetchingAssignedLessons && categoryGroupedAssignedLessons.map((group) => (
              <div key={group.category.id} className="lesson-list" style={{position: 'relative'}}>
                <div className="circle-30" style={{position: 'absolute', left: 216, background: group.category.color}}>
                  <span className={classNames('icon', group.category.icon)} />
                </div>
                <div className="category-title" style={{margin: '25px 0'}}>
                  {group.category.name}
                </div>
                {group.lessons.sort((a, b) => a.count < b.count ? 1 : -1).map((lesson) => (
                  <div key={lesson.lesson.id} className="row-fluid">
                    <div className="span1">
                      <span className="badge">{lesson.count}</span>
                    </div>
                    <div className="span7">{lesson.lesson.name}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="span5">
            <h3>Skills Earned</h3>
            {isFetchingRecentSkills && (
              <div className="mainNum">
                <img src="/images/loaders/load.gif" style={{width: 50}} alt={''} />
              </div>
            )}
            {!isFetchingRecentSkills && categoryGroupedSkillGoals
                .map((skillGroup) => (
                  <div key={skillGroup.category.id} className="lesson-list" style={{position: 'relative'}}>
                    <div
                      className="circle-30"
                      style={{position: 'absolute', left: 216, background: skillGroup.category.color}}
                    >
                      <span className={classNames('icon', skillGroup.category.icon)} />
                    </div>
                    <div className="category-title" style={{margin: '25px 0'}}>
                      {skillGroup.category.name}
                    </div>
                    {skillGroup.skillGoals.sort((a, b) => a.count < b.count ? 1 : -1).map((skillGoal) => (
                      <div key={skillGoal.skill.id} className="row-fluid">
                        <div className="span1">
                          <span className="badge">{skillGoal.count}</span>
                        </div>
                        <div className="span7">{skillGoal.skill.name}</div>
                        <div
                          className="span3"
                          style={{
                            fontSize: 10,
                            fontStyle: 'italic',
                            paddingLeft: 10,
                            color: '#a5a5a5',
                          }}
                        >
                          {skillGoal.humanTime}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
          </div>
        </div>
      </div>
    </PageView>
  );
};
