import React from 'react';
import ReactDOM from 'react-dom';
import {AppBootstrapper} from 'AppBootstrapper';
import {initializeErrorReporting} from 'logging/error-reporting';

initializeErrorReporting();

/**
 * Renders the app in the root DOM element
 */
ReactDOM.render(
    <React.StrictMode>
      <AppBootstrapper/>
    </React.StrictMode>,
    document.getElementById('root'),
);
