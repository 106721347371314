import React, {useEffect, useState} from 'react';
import {Select, SelectBaseProps} from 'components/forms/Select';
import {useApiContext} from 'api/ApiContext';
import {localizeText} from 'i18n/localize';
import {Version} from 'api/versions/Version';

export interface VersionPickerProps extends SelectBaseProps {
  value?: number | number[],
  onChange?: (newValue: number | number[]) => any,
}

export const VersionPicker = (props: VersionPickerProps) => {
  const {versionsService} = useApiContext();
  const [versions, setVersions] = useState<Version[]>([]);

  useEffect(() => {
    (async () => {
      setVersions(await versionsService.fetchVersions());
    })();
  }, []);

  return (
    <Select
      isInvalid={props.isInvalid}
      placeholder={props.placeholder ?? localizeText('Choose Version')}
      multiple={props.multiple ?? false}
      value={
        props.multiple ?
          (props.value as number[])?.map((value) => value.toString()) :
          (props.value as number)?.toString()
      }
      options={versions.map((version) => ({
        label: version.name,
        value: version.id.toString(),
      }))}
      onChange={(newValue) => {
        if (!props.multiple) {
          props.onChange?.(parseInt(newValue as string));
        } else {
          props.onChange?.(
              (newValue as string[]).map((value) => parseInt(value)),
          );
        }
      }}
    />
  );
};
