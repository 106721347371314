import React, {useEffect, useState} from 'react';
import {Challenge} from 'api/challenges/Challenge';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {ChallengeInputModal} from 'views/challenges/ChallengeInputModal';
import {useLogger} from 'logging/logging';
import {DataGrid} from 'components/DataGrid';
import {__} from 'i18n/localize';
import {FormattedDate} from 'components/FormattedDate';
import {ChallengeType} from 'api/challenges/ChallengeType.enum';
import {AccessControlled} from 'access-control/AccessControlled';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {confirmDelete} from 'components/confirmDelete';
import {useApiContext} from 'api/ApiContext';
import {ChallengeGoalType} from 'api/challenges/ChallengeGoalType.enum';
import {Tooltip} from 'components/Tooltip';

export interface ChallengesListModalProps {
  studentId?: number,
  onCloseRequested?: () => any,
}

export const ChallengesListModal = (props: ChallengesListModalProps) => {
  const logger = useLogger(ChallengesListModal.name);
  const {challengesService} = useApiContext();

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [editChallenge, setEditChallenge] = useState<Challenge | undefined>(undefined);
  const [showAddChallenge, setShowAddChallenge] = useState<boolean>(false);

  const loadChallenges = async () => {
    if (props.studentId) {
      const fetchedChallenges = await challengesService.fetchPersonalGoals(props.studentId);
      setChallenges(fetchedChallenges);
      logger.debug('Fetched challenges', fetchedChallenges);
    }
  };

  useEffect(() => {
    (async () => {
      await loadChallenges();
    })();
  }, []);

  return <Modal
    title={'Challenges'}
    className={'wide'}
    bodyStyle={{minHeight: 300}}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Add}
        onClick={() => setShowAddChallenge(true)}
      />
    }
    body={
      <>
        {showAddChallenge &&
          <ChallengeInputModal
            forStudentId={props.studentId}
            onCloseRequested={() => setShowAddChallenge(false)}
            onComplete={async () => {
              await loadChallenges();
              setShowAddChallenge(false);
            }}
          />
        }

        {editChallenge &&
          <ChallengeInputModal
            initialChallenge={editChallenge}
            onCloseRequested={() => setEditChallenge(undefined)}
            onComplete={async () => {
              await loadChallenges();
              setEditChallenge(undefined);
            }}
          />
        }

        <DataGrid
          header={
            <tr>
              <td width={30} className={'text-center'}>{__('Active')}</td>
              <td>{__('Title')}</td>
              <td width={10} />
              <td width={30}>{__('Goal')}</td>
              <td width={30}>{__('Collected')}</td>
              <td width={30}>{__('Deadline')}</td>
              <td>{__('Type')}</td>
              <td width={35} />
            </tr>
          }
          body={
            <>
              {challenges.map((challenge) => (
                <tr key={challenge.id}>
                  <td className={'text-center'}>{!challenge.completed && <i className="icon-check" />}</td>
                  <td>{challenge.name}</td>
                  <td>{challenge.success && <i className="icon-check" />}</td>
                  <td className={'text-center'}>
                    {challenge.goalType !== ChallengeGoalType.CollectMax &&
                      <div>{challenge.goal}</div>
                    }
                  </td>
                  <td className={'text-center'}>{challenge.collected}</td>
                  <td className={'text-center'}>
                    {challenge.goalType !== ChallengeGoalType.CollectNumberGoals && challenge.deadline &&
                    <div><FormattedDate timestamp={challenge.deadline} format={'MM/DD/YYYY'} /></div>
                    }
                  </td>
                  <td>
                    <div>
                      {challenge.type === ChallengeType.Course ? __('Module') : __('Personal')}
                    </div>
                  </td>
                  <td>
                    <AccessControlled canEdit={challenge}>
                      <ButtonGroup>
                        <Tooltip title={__('Edit')}>
                          <a className="btn btn-icon btn-mini" onClick={() => setEditChallenge(challenge)}>
                            <i className="icon-edit" />
                          </a>
                        </Tooltip>
                        <Tooltip title={__('Delete')}>
                          <a
                            className="btn btn-icon btn-mini"
                            onClick={() => confirmDelete(async () => {
                              await challengesService.deleteChallenge(challenge.id);
                              await loadChallenges();
                            })}
                          >
                            <i className="icon-trash" />
                          </a>
                        </Tooltip>
                      </ButtonGroup>
                    </AccessControlled>
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </>
    }
    footer={<p></p>}
  />;
};
