import React, {Fragment, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useApiContext} from 'api/ApiContext';
import {GoalType} from 'api/goals/GoalType.enum';
import {Skill} from 'api/skills/Skill';

export interface FirstGoalViewProps {
  skill: Skill;
  onComplete?: () => any,
}

export const FirstGoalView = (props: FirstGoalViewProps) => {
  const {goalsService, lessonsService} = useApiContext();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const createGoal = async () => {
    setIsSaving(true);
    const skillLessons = await lessonsService.fetchLessonsForSkill(props.skill.id);
    if (skillLessons.length < 0) {
      props.onComplete?.();
      setIsSaving(false);
    }

    const fullLesson = await lessonsService.fetchLesson(skillLessons[0].id);
    const skillLessonsSteps = fullLesson.steps.map((step) => ({
      ...step,
      id: undefined,
      resources: step.resources.map((resource) => ({...resource, id: undefined})),
    }));

    await goalsService.createGoal({
      name: props.skill.name,
      lessons: skillLessons,
      type: GoalType.User,
      steps: skillLessonsSteps,
      category: props.skill.category,
      icon: props.skill.category?.icon,
      color: props.skill.category?.color,
    });
    props.onComplete?.();
    setIsSaving(false);
  };

  return (
    <Modal
      hideHeader={true}
      bodyPadding={0}
      className={'learning'}
      bodyClassName={'body viewer analytics'}

      body={
        <Fragment>
          {isSaving &&
            <LoadingIndicator fullscreen={true} message={__('Saving Content')} />
          }

          <div className="inventory" style={{margin: '-10px -10px 0 -10px'}}>
            <div className="dreams_survey">
              <div className="headline" style={{paddingRight: 30}}>
                <h1> Here is Your First Goal!</h1>
              </div>
              <iframe
                style={{width: 640, height: 300, margin: '0 auto'}}
                src="https://player.vimeo.com/video/424880028?title=0&byline=0&portrait=0&autoplay=1" width="640"
                height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen
              />
              <div className="headline" style={{paddingRight: 30, marginTop: 20}} />
            </div>
          </div>
          <div className="viewerButtons" style={{position: 'fixed'}}>
            <div className="buttons btn-group">
              <a
                onClick={() => createGoal()}
                className="btn btn-info"
                style={{padding: '10px 15px', fontSize: 15}}
              >
                Next <i className="icon-right-open" />
              </a>
            </div>
          </div>
          <div className="skills">
            <form name="form.create">
              <div className="row-fluid">
                <div className="row-fluid">
                  <h1 className="info">{__('My  Goal:')}</h1><br />
                  <h2 style={{borderBottom: 'none'}}>{props.skill.name}</h2>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      }
    />
  );
};
