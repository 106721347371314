import React, {ReactElement, Ref} from 'react';
import {Modal as MaterialUiModal} from '@material-ui/core';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {Config} from 'Config';

/**
 * Displays children in a modal popup
 */

export interface ModalProps {
  title?: string | ReactElement,
  header?: ReactElement,
  body?: ReactElement,
  footer?: ReactElement,
  className?: string,
  bodyClassName?: string,
  onCloseRequested?: () => any,
  bodyPadding?: number,
  buttons?: ReactElement,
  hideCloseButton?: boolean,
  hideHeader?: boolean
  wrapperClassName?: string,
  bodyWrapperClassName?: string,
  bodyBackground?: string,
  disableBodyScroll?: boolean,
  bodyStyle?: React.CSSProperties,
  ref?: Ref<any>;
}

export const Modal = (props: ModalProps) => {
  return (
    <MaterialUiModal
      open={true}
      onClose={props.onCloseRequested}
      disableEnforceFocus={true}
      innerRef={props.ref}

      BackdropProps={{
        style: {
          backgroundColor: '#626262',
          opacity: 0.8,
        },
      }}

      // these enable/disable dismissing modals via escape key or clicking away
      disableEscapeKeyDown={Config.disableModalClickAway}
      disableBackdropClick={Config.disableModalClickAway}
    >
      <div className={`modal fade in ${props.className ?? ''}`}>
        <div className={props.wrapperClassName ?? ''}>
          {!props.hideHeader &&
          <div className="modal-header">
            <ButtonGroup className={'buttons'}>
              {props.buttons}

              {!props.hideCloseButton &&
              <Button
                aria-label="Close"
                variant={ButtonVariant.Cancel}
                onClick={props.onCloseRequested}
              />
              }
            </ButtonGroup>

            <span className="title">
              <h3>
                {props.title}
                {!props.title && <>&nbsp;</>}
              </h3>
            </span>
          </div>
          }

          <div className={props.bodyWrapperClassName ?? ''}>
            <div
              className={`modal-body ${props.bodyClassName ?? ''}`}
              style={{
                overflowY: props.disableBodyScroll ? 'hidden' :'auto',
                padding: props.bodyPadding,
                background: props.bodyBackground,
                ...props.bodyStyle,
              }}
            >
              {props.body}
            </div>
          </div>

          {props.footer &&
          <div
            className="modal-footer"
            style={{marginBottom: 0, padding: '12px 15px 2px'}}
          >
            {props.footer}
          </div>
          }
        </div>
      </div>
    </MaterialUiModal>
  );
};
