import React from 'react';
import {Button, ButtonProps, ButtonVariant} from 'components/buttons/Button';
import {localizeText} from 'i18n/localize';

export interface ButtonDeleteProps extends ButtonProps {}

export const ButtonDelete = (props: ButtonDeleteProps) => {
  return (
    <Button
      tooltip={props.tooltip ?? localizeText('Delete')}
      onClick={props.onClick}
      className={'btn-icon btn-mini'}
      iconCode={'icon-trash'}
      variant={ButtonVariant.Delete}
    />
  );
};
