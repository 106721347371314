import {AxiosInstance} from 'axios';
import {InstanceInfo} from 'api/instances/InstanceInfo';

/**
 * Provides API functions related to "instances" (ie. tenants/clients of the app)
 */
export class InstancesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches public info about a given instance
   *
   * @param {string} key
   */
  async fetchInstancePublicInfo(key: string): Promise<InstanceInfo> {
    // typo "instace" is in the API
    const response = await this.httpClient.post('/public/instaceInfo', {key});
    return response.data;
  }
}
