import React from 'react';

/**
 * Displays a notification that the connection to the backend service failed.
 */
export const ServiceUnavailableView = () => {
  return (
    <>
      <div>
        <div className='pane city'>
          <div className='body'>
            <div style={{margin: '0 auto 25px', width: 290}}>
              <img
                src='../../images/my-full-logo.png'
                alt='My Full Life'
              />
            </div>
            <div className='login_pack'>
              <h3 style={{fontSize: 16}}>Service Unavailable</h3>
              <p>
                We&rsquo;re sorry,<br />
                My Full Life is currently unavailable.
              </p>
            </div>
            <div
              className='login_pack'
              style={{
                width: 250,
                fontSize: 12,
                paddingBottom: 20,
                borderRadius: '10px 30px',
                textAlign: 'center',
              }}
            >
              <div style={{padding: '10px 15px'}}>
                <a
                  style={{color: 'white'}}
                  target='_blank'
                  href='http://www.centriclearning.pro'
                  rel="noreferrer"
                >
                  About
                </a>
                {' '}|{' '}
                <a style={{color: 'white'}} href='../../../public/docs/centric_TOS.pdf'>
                  Terms of Service
                </a>
                {' '}|{' '}
                <a style={{color: 'white'}} href='../../../public/docs/centric_Privacy.pdf'>
                  Privacy&nbsp;policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='footer'>
        <span>© Center for Independent Futures. All rights reserved</span>
      </div>
    </>
  );
};
