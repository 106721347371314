import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {QuizWithQuestions} from 'api/quizzes/Quiz';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {useLogger} from 'logging/logging';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {confirmModal} from 'components/confirmModal';
import {QuizMatchQuestion} from 'views/learning/quizzes/QuizMatchQuestion';
import {QuizDragDropQuestion} from 'views/learning/quizzes/QuizDragDropQuestion';
import styles from './QuizView.module.scss';
import {QuizMultipleChoiceQuestion} from 'views/learning/quizzes/QuizMultipleChoiceQuestion';
import {__} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Resource} from 'api/resources/Resource';
import {CreateQuizSolutionDto, QuizQuestionWithAnswer, QuizSolution} from 'api/quizzes/QuizSolution';
import {useApiContext} from 'api/ApiContext';
import {Tooltip} from 'components/Tooltip';
import {QuizOrderQuestion} from 'views/learning/quizzes/QuizOrderQuestion';
import {AnalyticsService} from 'analytics/AnalyticsService';
import {AnalyticsEvent} from 'analytics/AnalyticsEvent.enum';


export interface QuizViewProps {
  resource: Resource,
  onCloseRequested?: () => any,
  onCompleted?: (solution?: QuizSolution) => any,
}

export const QuizView = (props: QuizViewProps) => {
  const logger = useLogger(QuizView.name);
  const {quizzesService} = useApiContext();
  const {resource} = props;
  const quiz = resource.resource as QuizWithQuestions;

  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<QuizQuestionWithAnswer[]>(quiz.questions);
  const [draftId, setDraftId] = useState<number | undefined>(undefined);

  const start = () => {
    setIsStarted(true);
    // original frontend looks to not define the event category
    AnalyticsService.default().recordEvent(AnalyticsEvent.QuizStarted, resource.name, '-');
  };

  const submit = async () => {
    const quizSolution: CreateQuizSolutionDto = {
      ...quiz,
      quiz: quiz,
      questions: answers,
    };

    const result = await quizzesService.submitQuizSolution(quizSolution);
    logger.debug('Quiz solution submitted', {
      solution: quizSolution,
      result,
    });

    // original frontend looks to not define the event category
    AnalyticsService.default().recordEvent(AnalyticsEvent.QuizSubmitted, resource.name, '-');
    props.onCompleted?.(result.entity);
  };

  const confirmQuit = () => {
    confirmModal(
        async () => {
          if (draftId) {
            await quizzesService.deleteDraft(draftId);
          }
          props.onCloseRequested?.();
        },
        __('Delete Progress'),
        'Do you really want to cancel your work?',
    );
  };

  const saveAndClose = async () => {
    confirmModal(
        async () => {
          const quizSolution: CreateQuizSolutionDto = {
            name: quiz.name,
            quiz: {id: quiz.id},
            questions: answers,
          };
          const saveResult = await quizzesService.saveQuizDraft(quizSolution, draftId);
          logger.debug('Saved quiz draft', saveResult);
          props.onCloseRequested?.();
        },
        __('Save Your Progress'),
        __('Do you really want to save this and finish it later?'),
    );
  };

  useEffect(() => {
    (async () => {
      logger.info('Viewing quiz', props.resource);

      const fetchedDraft = await quizzesService.fetchQuizDraft(quiz.id);

      if (fetchedDraft) {
        setDraftId(fetchedDraft.id);
        logger.debug('Fetched quiz draft', fetchedDraft);
        setAnswers(fetchedDraft.questions);
        setIsStarted(true);
      } else if (!quiz.survey) {
        confirmModal(() => start(), 'Evaluation', <>
          <h4>You are starting a new Learning Check</h4>
          Completion will overwrite any of your previous scores.
          <br />
          <br />
          Do you wish to continue?
        </>,
        );
      }
    })();
  }, []);

  return (
    <div className="wrapper">
      <div className={classNames(styles.wrapper, 'body viewer quiz-view')} style={{padding: '15px 15px 50px'}}>
        {!isStarted && <div className="title">{quiz.name}</div>}

        {!isStarted &&
          answers.map((question, questionIndex) => (
            <div
              key={question.id}
              id={`cat${question.id}`}
              className="question blurry-text"
              style={{paddingBottom: questionIndex === answers.length - 1 ? 80 : 10}}
            >
              {question.type !== QuizQuestionType.Instructions &&
              <div
                className="heading clearfix html blurry-text clearfix"
                dangerouslySetInnerHTML={{__html: question.text}}
              />
              }
              {question.type === QuizQuestionType.Instructions &&
              <div
                className="html clearfix blurry-text"
                dangerouslySetInnerHTML={{__html: question.text}}
                style={{color: 'white'}}
              />
              }
              <div className="clearfix" />

              {question.type !== QuizQuestionType.Match &&
                <div className="answers">
                  {question.answers.map((answer) => (
                    <div
                      key={answer.id}
                      className={classNames(
                          'answer',
                          {'no-checkbox': !quizzesService.questionHasCheckbox(question)},
                      )}
                    >
                      <div className="checkbox">
                        <i className="icon-check-empty" />
                      </div>

                      <div className="text html" dangerouslySetInnerHTML={{__html: answer.text}} />
                    </div>
                  ))}
                </div>
              }

              {question.type === QuizQuestionType.Match &&
                <div className="answers match-answers">
                  <div className="texts">
                    {question.answers?.map((answer) => (
                      <div key={answer.id} className="answer clearfix no-checkbox">
                        <div className="text html clearfix" dangerouslySetInnerHTML={{__html: answer.text}} />
                      </div>
                    ))}
                  </div>

                  <div className="matches">
                    {question.answers?.map((answer) => (
                      <div key={answer.id} className="answer clearfix no-checkbox">
                        <div className="text html clearfix" dangerouslySetInnerHTML={{__html: answer.text}} />
                      </div>
                    ))}
                  </div>
                  <div className="clearfix" />
                </div>
              }

              <div className="clearfix" />
            </div>
          ))
        }

        {isStarted && answers.map((question, questionIndex) => (
          <div
            key={question.id}
            id={`cat${question.id}`}
            className={classNames('question animate active')}
            style={{paddingBottom: questionIndex === answers.length - 1 ? 80 : 10}}
          >
            {question.type !== QuizQuestionType.Instructions &&
              <div
                className="heading html clearfix"
                dangerouslySetInnerHTML={{__html: question.text}}
              />
            }

            {question.type === QuizQuestionType.Instructions &&
              <div
                className="html clearfix"
                dangerouslySetInnerHTML={{__html: question.text}}
              />
            }

            <div className="clearfix" />

            {!question.draggable &&
              (
                question.type === QuizQuestionType.Bullets ||
                question.type === QuizQuestionType.Multiple
              ) &&
                <QuizMultipleChoiceQuestion
                  question={question}
                  onAnswersChanged={(newAnswers) => setAnswers((current) => {
                    const newQuestions = [...current];
                    newQuestions[questionIndex].answers = newAnswers;
                    return newQuestions;
                  })}
                />
            }

            {question.draggable &&
              (question.type === QuizQuestionType.Bullets ||
                question.type === QuizQuestionType.Multiple
              ) &&
                <QuizDragDropQuestion
                  question={question}
                  onAnswersChanged={(newQuestion) => setAnswers((current) => {
                    const newQuestions = [...current];
                    newQuestions[questionIndex] = newQuestion;
                    return newQuestions;
                  })}
                />
            }

            {question.type === QuizQuestionType.Open &&
                <div className="answer">
                  <RichTextInput
                    value={question.answer}
                    onChange={(newValue) => setAnswers((current) => {
                      const newQuestions = [...current];
                      newQuestions[questionIndex].answer = newValue;
                      return newQuestions;
                    })}
                  />
                </div>
            }

            {question.type === QuizQuestionType.Order &&
              <QuizOrderQuestion
                question={question}
                onChange={(newQuestion) => setAnswers((current) => {
                  const newAnswers = [...current];
                  newAnswers.forEach((answer, i) => {
                    if (answer.id === newQuestion.id) {
                      newAnswers[i] = newQuestion;
                    }
                  });
                  return newAnswers;
                })}
              />
            }

            {question.type === QuizQuestionType.Match &&
              <QuizMatchQuestion
                question={question}
                onChange={(newQuestion) => setAnswers((current) => {
                  const newAnswers = [...current];
                  newAnswers.forEach((answer, i) => {
                    if (answer.id === newQuestion.id) {
                      newAnswers[i] = newQuestion;
                    }
                  });
                  return newAnswers;
                })}
              />
            }
          </div>
        ))}

        <div className="clearfix" style={{marginBottom: 10}} />
        <div className="clearfix" />
      </div>

      <div className="viewerButtons" style={{position: 'fixed'}}>
        <div className="buttons btn-group">
          <AccessControlled feature={AppFeature.LearningComplete} ownerOf={props.resource}>
            {!isStarted &&
                <a onClick={start} className="btn btn-success">
                  {__(props.resource.completed ? 'Retake' : 'Start')}
                </a>
            }

            {isStarted &&
                <Tooltip title={__('Submit Completed')}>
                  <a
                    onClick={submit}
                    className="btn btn-success"
                    style={{padding: '3px 20px'}}
                  >
                    <i className="icon-ok-circled" />
                  </a>
                </Tooltip>
            }
          </AccessControlled>

          <Tooltip title={__('Save and Close')}>
            <a onClick={saveAndClose} className="btn btn-warning" style={{padding: '3px 20px'}}>
              {' '}
              <i className="icon-history" />
            </a>
          </Tooltip>

          <div style={{width: 20, height: 28}} className="btn disabled ng-scope" />

          <a onClick={confirmQuit} className="btn btn-cancel" style={{padding: '3px 20px'}}>
            <i className="icon-cancel" />
          </a>
        </div>
      </div>
    </div>
  );
};
