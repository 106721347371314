import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {PageNotFoundView} from 'views/errors/PageNotFoundView';
import {PlanOverviewView} from 'views/plan/PlanOverviewView';
import {ParticipantStatsView} from 'views/plan/ParticipantStatsView';
import {NotificationsSettingsView} from 'views/dashboard/NotificationsSettingsView';
import {GoalsDashboardView} from 'views/goals/GoalsDashboardView';
import {JournalView} from 'views/journal/JournalView';
import {LessonsListView} from 'views/lessons/LessonsListView';
import {CoursesListView} from 'views/courses/CoursesListView';
import {StudentsListView} from 'views/users/StudentsListView';
import {QuizzesListView} from 'views/quizzes/QuizzesListView';
import {SkillsListView} from 'views/skills/SkillsListView';
import {CommunityView} from 'views/community/CommunityView';
import {DreamsDashboardView} from 'views/dreams/DreamsDashboardView';

/**
 * Handles routing in the dashboard (student) section of the app
 */
export const DashboardRouter = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={url + AppRoute.Dreams} />
      </Route>

      <Route
        exact
        path={path + AppRoute.Dreams}
        component={DreamsDashboardView}
      />
      <Route
        exact
        path={path + AppRoute.PlanOverview}
        component={PlanOverviewView}
      />
      <Route
        exact
        path={path + AppRoute.PlanHistory}
        component={ParticipantStatsView}
      />
      <Route
        exact
        path={path + AppRoute.NotificationsSettings}
        component={NotificationsSettingsView}
      />
      <Route
        exact
        path={path + AppRoute.Goals}
        component={GoalsDashboardView}
      />
      <Route
        exact
        path={path + AppRoute.Journal}
        component={JournalView}
      />
      <Route
        exact
        path={path + AppRoute.Lessons}
        component={LessonsListView}
      />
      <Route
        exact
        path={path + AppRoute.ActivityLog}
      >
        <JournalView forActivityLog={true} />
      </Route>
      <Route
        exact
        path={path + AppRoute.TeacherCourses}
      >
        <CoursesListView forTeacher={true} />
      </Route>
      <Route
        exact
        path={path + AppRoute.Students}
        component={StudentsListView}
      />
      <Route
        exact
        path={path + '/students/list'}
      >
        <StudentsListView hideSidePanel={true}/>
      </Route>
      <Route
        exact
        path={path + AppRoute.LearningChecks}
        component={QuizzesListView}
      />
      <Route
        exact
        path={path + AppRoute.Students}
        component={StudentsListView}
      />
      <Route
        exact
        path={path + AppRoute.Skills}
        component={SkillsListView}
      />
      <Route
        exact
        path={path + AppRoute.Community}
        component={CommunityView}
      />

      <Route path={'*'}>
        <PageNotFoundView/>
      </Route>
    </Switch>
  );
};
