/**
 * Custom analytics event types tracked in the app.
 */
export enum AnalyticsEvent {
  QuizStarted = 'Started Quiz',
  QuizSubmitted = 'Submited Quiz', // typo was in original frontend
  CollageCompleted = 'Completed collage',
  ResourceCompleted = 'Completed resource',
  NewUserRegistered = 'New User Registration',
  UserRegisteredForCourse = 'Course Registration',
}
