import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {__} from 'i18n/localize';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import classNames from 'classnames';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Skill} from 'api/skills/Skill';
import {SkillGoal} from 'api/skills/SkillGoal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {FormattedDate} from 'components/FormattedDate';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';
import {GoalStatus} from 'api/goals/GoalStatus';
import {useSessionContext} from 'contexts/SessionContext';
import {SkillInventory} from 'api/skills/SkillInventory';
import {SkillRelatedLessonsModal} from 'views/skills/SkillRelatedLessonsModal';

/**
 * A modal reporting a user's skill inventory
 */

export interface SkillInventoryModalProps {
  onCancel?: () => any,
  onEditRequested?: () => any,
}

export const SkillInventoryModal = (props: SkillInventoryModalProps) => {
  const logger = useLogger(SkillInventoryModal.name);
  const {sessionDetails} = useSessionContext();
  const {skillsService} = useApiContext();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [showDetailsGoal, setShowDetailsGoal] = useState<SkillGoal | undefined>(undefined);
  const [showDetailsSkill, setShowDetailsSkill] = useState<Skill | undefined>(undefined);
  const [skillInventories, setSkillInventories] = useState<SkillInventory[]>([]);
  const [selectedInventory, setSelectedInventory] = useState<SkillInventory | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsInitializing(true);

      const builtSkillInventories = await skillsService.fetchUserSkillInventory(
          sessionDetails?.selectedStudent?.id ?? sessionDetails?.identity?.id ?? -1,
      );
      setSkillInventories(builtSkillInventories.inventories);
      logger.debug('Built skill inventory', builtSkillInventories);

      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return (
      <Modal
        className={'wide tall'}
        onCloseRequested={props.onCancel}
        body={<LoadingIndicator />}
      />
    );
  }

  return (
    <Modal
      title={__('Skills Inventory')}
      className={'wide tall'}
      onCloseRequested={props.onCancel}
      bodyPadding={0}
      disableBodyScroll={true}

      buttons={
        <AccessControlled feature={AppFeature.MakeAssessment}>
          <Button
            variant={ButtonVariant.Edit}
            className={'btn-default'}
            tooltip={__('Skills Survey')}
            onClick={props.onEditRequested}
          />
        </AccessControlled>
      }

      body={
        <div className="column-layout mobileHeight" style={{position: 'relative'}}>
          {showDetailsSkill &&
            <SkillRelatedLessonsModal
              skillId={showDetailsSkill.id}
              onCloseRequested={() => setShowDetailsSkill(undefined)}
            />
          }

          {showDetailsGoal &&
            <GoalDetailsModal
              goalId={showDetailsGoal.id}
              onCloseRequested={() => setShowDetailsGoal(undefined)}
            />
          }

          <div className="left-column learningPath">
            <div className="view pane">
              <div className="head">
                <div className="text">
                  {__('Categories')}
                </div>
              </div>
              <div className="categories body">
                {skillInventories?.map((inventory) => (
                  <div
                    key={inventory.topLevelCategory.id}
                    className={classNames(
                        'category',
                        {selected: selectedInventory?.topLevelCategory.id === inventory.topLevelCategory.id},
                    )}
                    onClick={() => setSelectedInventory(inventory)}
                  >
                    {(inventory.stats.completedCount ?? 0) > 0 &&
                     <div className="progress">
                       <div
                         className="bar"
                         style={{
                           width: `${inventory.stats.percentComplete}%`,
                           backgroundColor: inventory.topLevelCategory.color,
                         }}
                       >
                         <div className="name">
                           <div className="circle-20">
                             <span className={`icon ${inventory.topLevelCategory.icon}`} />
                           </div>
                           {inventory.topLevelCategory.name}
                         </div>
                       </div>
                     </div>
                    }

                    <div
                      className="circle-20"
                      style={{borderColor: inventory.topLevelCategory.color}}
                    >
                      <span className={`icon ${inventory.topLevelCategory.icon}`} />
                    </div>
                    <div className="name">
                      {inventory.topLevelCategory.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="right-column learningPath">
            {!selectedInventory &&
              <div className="skills">
                <div className="pane with-inner-menu " style={{top: 0}}>
                  <div className="body">
                    <div className="row-fluid">
                      <div className="pack" style={{textAlign: 'center'}}>
                        <div
                          className={'goal'}
                          style={{
                            height: 150,
                            width: '96%',
                            position: 'relative',
                            background:
                              'linear-gradient(to bottom, rgba(175,175,175,0.9), rgba(175,175,175,0) 80%) #828282',
                            borderRadius: '4px 20px',
                            margin: 10,
                            boxShadow: 'inset 0 2px 6px rgba(0,0,0,0.6)',
                          }}
                        >
                          <div className="icon icon-help loading"/>
                        </div>

                        <div
                          style={{
                            padding: '10px 0',
                            fontFamily: 'triunity condensed',
                            color: '#4B4B4B',
                          }}
                        >
                          <h4>
                            {__('Please select a skill category.')}
                          </h4>
                          <p>
                            To explore {' '}
                            {sessionDetails?.selectedStudent?.name ?? sessionDetails?.identity?.name}&rsquo;s
                            Learning Path in detail. Select a skills category on the left.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {selectedInventory &&
              <div className="skills span12">
                <div className="category">
                  <div
                    className="circle-30"
                    style={{borderColor: selectedInventory.topLevelCategory.color}}>
                    <span className={`icon ${selectedInventory.topLevelCategory.icon}`} />
                  </div>

                  {selectedInventory.topLevelCategory.name}

                  {selectedInventory?.stats && selectedInventory.stats.completedCount > 0 &&
                    <div className="compltePercent">
                      {selectedInventory.stats.percentComplete}% of skills completed
                    </div>
                  }

                  {(selectedInventory?.stats === undefined ||
                    selectedInventory.stats.completedCount < 1) &&
                    <div className="compltePercent">
                      {__('No Skills Completed')}
                    </div>
                  }

                  <div className="key">
                    <i className="icon-ok-circled" style={{color: 'green'}}/>
                    &nbsp;
                    <span>I have learned</span>
                    &nbsp;&nbsp;&nbsp;
                    <i className="icon-play-circled" style={{color: 'teal'}}/>
                    &nbsp;
                    <span>I am learning</span>
                    &nbsp;&nbsp;&nbsp;
                    <i className="icon-plus-circled" style={{color: 'purple'}}/>
                    &nbsp;<span>I need to learn</span>
                    &nbsp;&nbsp;&nbsp;
                    <i className="icon-cancel-circled" style={{color: 'red'}}/>
                    &nbsp;
                    <span>No Experience</span>
                    &nbsp;&nbsp;&nbsp;
                    <i className="icon-cog" style={{color: '#EA9D0E'}}/>
                    &nbsp;
                    <span>Needs Support</span>
                  </div>
                </div>

                <div className="pane with-inner-menu ">
                  <div className="body" style={{overflowY: 'auto'}}>
                    <div className="row-fluid">
                      <div role="list">
                        {selectedInventory?.subCategories.map((category) => (
                          <>
                            <div className="title">{category.key}</div>
                            <table width="100%" cellPadding={0}>
                              <tbody>
                                {category.skillsWithGoals.map((skillWithGoal) => (
                                  <tr key={skillWithGoal.skill.id}>
                                    <td>
                                      {skillWithGoal.skillGoal?.state === GoalStatus.Completed &&
                                      <i
                                        className="icon-ok-circled"
                                        style={{color: 'green', fontSize: 20}}
                                      />
                                      }
                                      {skillWithGoal.skillGoal?.state === GoalStatus.InProgress &&
                                      <i
                                        className="icon-play-circled"
                                        style={{fontSize: 20, color: 'teal'}}
                                      />
                                      }
                                      {skillWithGoal.skillGoal?.state === GoalStatus.Recommended &&
                                      <i
                                        className="icon-plus-circled"
                                        style={{fontSize: 20, color: 'purple'}}
                                      />
                                      }
                                      {skillWithGoal.skillGoal?.state === GoalStatus.NonApplicable &&
                                      <i
                                        className="icon-cancel-circled"
                                        style={{fontSize: 20, color: 'red'}}
                                      />
                                      }

                                      &nbsp;{skillWithGoal.skill.name}

                                      {skillWithGoal.skillGoal?.support &&
                                      <i
                                        className="icon-cog"
                                        style={{color: '#EA9D0E', fontSize: 15}}
                                      />
                                      }
                                      {skillWithGoal.skillGoal?.completeGoal &&
                                      <div
                                        className="btn"
                                        onClick={() => setShowDetailsGoal(skillWithGoal.skillGoal?.completeGoal)}
                                      >
                                        View Goal
                                      </div>
                                      }
                                      {skillWithGoal.skillGoal?.state === GoalStatus.Recommended &&
                                      <div
                                        className="btn"
                                        onClick={() => setShowDetailsSkill(skillWithGoal.skill)}
                                      >
                                        Lessons
                                      </div>
                                      }
                                    </td>
                                    <td>
                                      {skillWithGoal.skillGoal?.completeDate &&
                                      <span>
                                        <FormattedDate timestamp={skillWithGoal.skillGoal!.completeDate!} />
                                      </span>
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    />
  );
};
