import React, {PropsWithChildren} from 'react';
import {Fade, Slide} from '@material-ui/core';

/**
 * Wraps pages in default containers, including animations
 */

export interface PageViewProps {
  className?: string,
  bodyClassName?: string,
}

export const PageView = (props: PropsWithChildren<PageViewProps>) => {
  return (
    <Slide in={true} direction={'left'} timeout={400}>
      <div>
        <Fade in={true} timeout={1000}>
          <div className={`pane ${props.className ?? ''}`}>
            <div
              className={`body ${props.bodyClassName ?? ''}`}
              style={{overflowY: 'auto'}}
            >
              {props.children}
            </div>
          </div>
        </Fade>
      </div>
    </Slide>
  );
};
