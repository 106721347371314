import React, {ReactElement} from 'react';
import {PropsWithChildren} from 'react';
import {useAuthContext} from 'contexts/AuthContext';
import {AppFeature} from 'access-control/AppFeature';
import {UserRoleType} from 'api/users/UserRole';
import {useAccessControlContext} from 'access-control/AccessControlContext';

/**
 * Controls access to components based on current user role
 */

export interface AccessControlledProps {
  unauthorizedView?: ReactElement,
  roles?: UserRoleType[],
  feature?: AppFeature | string,
  ownerOf?: any,
  authorOf?: any,
  canEdit?: any,
}

export const AccessControlled = (
    props: PropsWithChildren<AccessControlledProps>,
) => {
  const {currentUser} = useAuthContext();
  const {accessControlService} = useAccessControlContext();

  let isAuthorized = false;
  if (props.feature) {
    currentUser?.roleTypes.forEach((userRole) => {
      if (props.feature && accessControlService.roleCanAccess(userRole, props.feature)) {
        isAuthorized = true;
      }
    });
  }

  if (!isAuthorized && currentUser && props.ownerOf) {
    isAuthorized = accessControlService.userIsResourceOwner(currentUser, props.ownerOf);
  }

  if (!isAuthorized && currentUser && props.canEdit) {
    isAuthorized = accessControlService.userCanEditResource(currentUser, props.canEdit);
  }

  if (!isAuthorized && props.roles) {
    currentUser?.roleTypes.forEach((userRole) => {
      if (props.roles?.includes(userRole)) {
        isAuthorized = true;
      }
    });
  }

  if (!isAuthorized && props.authorOf && currentUser) {
    if (accessControlService.userIsResourceAuthor(currentUser, props.authorOf)) {
      isAuthorized = true;
    }
  }

  if (isAuthorized) {
    return <>{props.children}</>;
  } else {
    return props.unauthorizedView ?? <></>;
  }
};
