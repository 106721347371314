/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {FormattedDate} from 'components/FormattedDate';
import {ResourceIcon} from 'views/resources/ResourceIcon';
import {JournalEntryType} from 'api/journal/JournalEntryType';
import {UserAvatar} from 'components/UserAvatar';
import {Config} from 'Config';
import {JournalEntryAction} from 'api/journal/JournalEntryAction';
import {__, localizeText} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {JournalEntry, JournalEntryWithRelations} from 'api/journal/JournalEntry';
import {Comment} from 'api/journal/Comment';
import {JournalCommentInputModal} from 'views/journal/JournalCommentInputModal';
import {ResourceView} from 'views/resources/ResourceView';
import {Resource} from 'api/resources/Resource';
import {JournalEntryInputModal} from 'views/journal/JournalEntryInputModal';
import {Tooltip} from 'components/Tooltip';
import {useApiContext} from 'api/ApiContext';
import {useSessionContext} from 'contexts/SessionContext';
import {useLogger} from 'logging/logging';
import {useEntityViewHistoryContext} from 'contexts/EntityViewHistoryContext';
import {useAccessControlContext} from 'access-control/AccessControlContext';

export interface ActivityItemQuickViewProps {
  item: JournalEntryWithRelations,
  onFullUpdate?: () => any,
  onUpdated?: (newEntry?: JournalEntry) => any,
  onCommentAdded?: (newComment: Comment) => any,
  onCommendUpdated?: (updatedComment: Comment) => any,
  hidden?: boolean,
  forActivityLog?: boolean,
}

export const ActivityItemQuickView = (props: ActivityItemQuickViewProps) => {
  const logger = useLogger(ActivityItemQuickView.name);
  const {item} = props;
  const {journalService} = useApiContext();
  const {sessionDetails} = useSessionContext();
  const {accessControlService} = useAccessControlContext();
  const {
    newestSeenJournalEntryId,
    newestSeenJournalCommentId,
    checkAndRecordJournalEntrySeenStatus,
    checkAndRecordJournalCommentSeenStatus,
  } = useEntityViewHistoryContext();

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editComment, setEditComment] = useState<Comment | undefined>(undefined);
  const [shownResource, setShownResource] = useState<Resource | undefined>(undefined);

  // latest seen IDs need to be memoized so we can display seen status after it is updated when this component is viewed
  const newestSeenEntryId = useMemo(() => newestSeenJournalEntryId, []);
  const newestSeenCommentId = useMemo(() => newestSeenJournalCommentId, []);

  const userName = sessionDetails?.identity?.name ?? 'user';

  const showEntryResource = async () => {
    console.log(props.item);
    const userCanGradeResource = sessionDetails?.identity &&
      accessControlService.userCanAccess(sessionDetails?.identity, 'student.grade');
    const itemNeedsReview = (props.item.resource?.manuallyGraded && !props.item.resource.graded) ||
      props.item.text?.includes('Pending review');
    if (userCanGradeResource && itemNeedsReview) {
      const updateResult = await journalService.markJournalEntryReviewed(props.item, userName);
      if (updateResult.ok && updateResult.entity) {
        props.onUpdated?.(updateResult.entity);
        logger.debug('Marked journal entry reviewed', {itemId: props.item.id, userName});
      }
    }
    setShownResource(props.item.resource);
  };

  useEffect(() => {
    checkAndRecordJournalEntrySeenStatus(props.item.id);
    props.item.comments.forEach((comment) => checkAndRecordJournalCommentSeenStatus(comment.id));
  }, []);

  return (
    <div className={classNames('repeat-animation animate', {'hide': props.hidden})}>
      {showEdit &&
        <JournalEntryInputModal
          initialEntry={props.item}
          onCloseRequested={() => setShowEdit(false)}
          onComplete={(_, newEntry) => {
            props.onUpdated?.(newEntry);
            setShowEdit(false);
          }}
        />
      }

      {editComment &&
        <JournalCommentInputModal
          initialComment={editComment}
          entryId={props.item.id}
          onCloseRequested={() => setEditComment(undefined)}
          onCompleted={(isNew, newComment) => {
            setEditComment(undefined);
            if (isNew && newComment) {
              props.onCommentAdded?.(newComment);
            } else if (newComment) {
              props.onCommendUpdated?.(newComment);
            }
          }}
        />
      }

      {shownResource &&
        <ResourceView
          resource={shownResource}
          onCloseRequested={() => setShownResource(undefined)}
          onUpdated={() => {
            setShownResource(undefined);
            props.onFullUpdate?.();
          }}
        />
      }

      <div className="journal-entry" role="listitem">
        <div
          id={item.id.toString()}
          className={classNames(
              'entry-details',
              {'needs-grading': item.resource?.manuallyGraded && !item.resource.graded},
              {'not-seen': newestSeenEntryId < item.id},
          )}
        >
          <div
            className={classNames(
                {'picture': props.forActivityLog || item.author.id !== item.owner.id},
                {'resource-holder': !props.forActivityLog && item.resource !== undefined},
                {'hidden': !props.forActivityLog && item.resource === undefined && item.author.id === item.owner.id},
            )}
          >
            {(item.author.id !== item.owner.id || props.forActivityLog) &&
              <Fragment>
                {item.owner.image &&
                  <Tooltip title={item.owner.name ?? ''} placement={'right'}>
                    <img src={item.owner.image ? item.owner.image.url + '100/100' : ''} alt={''} />
                  </Tooltip>
                }
                {!item.owner.image &&
                  <Tooltip title={item.owner.name ?? ''} placement={'right'}>
                    <span className="icon-user" />
                  </Tooltip>
                }
              </Fragment>
            }
            {' '}
            {item.resource &&
              <a onClick={showEntryResource} className="resource">
                {item.resource && <ResourceIcon resource={item.resource} aria-label="Show Resource"/>}
              </a>
            }
          </div>
          <div className={`icon icon-${item.type}`}>
            {item.type === JournalEntryType.Post &&
              <i className="icon-comment" style={{color: item.color}} />
            }
            {item.type === JournalEntryType.Skill &&
              <i className="icon-sun" style={{color: item.color}} />
            }
            {item.type === JournalEntryType.Step &&
              <i className="icon-play" style={{color: item.category?.color}} />
            }
            {item.type === JournalEntryType.Goal &&
              <i className={item.goal.icon} style={{color: item.color}} />
            }
            {item.type === JournalEntryType.Course &&
              <i className="icon-book" style={{color: item.category?.color}} />
            }
            {item.type === JournalEntryType.Badge &&
              <i className="icon-certificate" style={{color: item.color}} />
            }
            {item.type === JournalEntryType.SessionReport &&
              <UserAvatar src={item.owner?.image ? item.owner.image.url : Config.defaultUserImage } />
            }
          </div>
          <div className="content-holder">
            <div className="title">
              {item.title}
              {' '}
              {item.action === JournalEntryAction.Complete &&
                <span className="action">{localizeText('Completed')}</span>
              }
              {' '}
              {item.action === JournalEntryAction.Open &&
                <span className="action">{localizeText('Reopened')}</span>
              }
            </div>

            <div className="date">
              <FormattedDate timestamp={item.cdate} format={'MM/DD/YYYY HH:mm'} />
            </div>

            {item.ilries?.length > 0 &&
              <div className="ilries">
                {item.ilries.map((ilri) => (
                  <div key={ilri.id}>
                    <div
                      className="dot"
                      style={{backgroundColor: ilri.category?.color}}
                    />
                    {ilri.name}
                  </div>
                ))}
              </div>
            }
            <div className="text" dangerouslySetInnerHTML={{__html: item.text ?? ''}} />
          </div>

          <div className="buttons">
            <Tooltip title={__('Comment')}>
              <a
                className="icon"
                onClick={() => setEditComment({} as Comment)}
                aria-label="Comment"
              >
                <i className="icon-chat-empty" />
              </a>
            </Tooltip>
            <AccessControlled authorOf={item}>
              <Tooltip title={__('Edit')}>
                <a className="icon" onClick={() => setShowEdit(true)} aria-label="Edit">
                  <i className="icon-edit" />
                </a>
              </Tooltip>
            </AccessControlled>
          </div>
          <div className="clearfix" />
        </div>

        {item.comments.map((comment) => (
          <div
            aria-label="Entry Comment"
            key={comment.id}
            className={classNames(
                'journal-entry-comment',
                {'foreign': comment.author.id !== item.owner.id},
                {'not-seen': newestSeenCommentId < comment.id},
            )}
          >
            <Tooltip title={comment.author.name}>
              <div className="picture">
                {comment.author.image &&
                  <img src={comment.author.image ? comment.author.image.url + '100/100' : ''} alt='' />
                }
                {!comment.author.image && <span className="icon-user" />}
                {' '}
                {comment.resource &&
                  <a
                    className={'resource'}
                    onClick={() => setShownResource(comment.resource)} aria-label="Show Resource"
                  >
                    {comment.resource.type === ResourceType.File &&
                      <i className="icon-doc" />
                    }
                    {comment.resource.type === ResourceType.Image &&
                      <i className="icon-picture" />
                    }
                  </a>
                }
              </div>
            </Tooltip>

            <div className="content-holder">
              <div className="date">
                <FormattedDate
                  timestamp={comment.cdate}
                  format={'MM/DD/YYYY HH:mm'}
                />
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: comment.text,
                }}
              />
            </div>

            <AccessControlled authorOf={comment}>
              <div className="buttons">
                <a
                  className="icon"
                  onClick={() => setEditComment(comment)}
                  aria-label="Edit"
                >
                  <i className="icon-edit" />
                </a>
              </div>
              <div className="clearfix" />
            </AccessControlled>
          </div>
        ))}
      </div>
    </div>
  );
};
