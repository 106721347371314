import {AxiosInstance} from 'axios';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {ApiRoute} from 'api/ApiRoute';
import {CreateSyllabusDto, Syllabus, UpdateSyllabusDto} from 'api/syllabi/Syllabus';
import {ListFilter} from 'api/lists/ListFilter';

/**
 * Provides API syllabi functions
 */
export class SyllabiService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Deletes a syllabus
   *
   * @param {number} id
   */
  async deleteSyllabus(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.Syllabi + ApiRoute.Delete, {id});
    return response.data;
  }

  /**
   * Creates a new syllabus
   *
   * @param {CreateSyllabusDto} syllabus
   * @return {Promise<SuccessResponse<Syllabus>>}
   */
  async createSyllabus(syllabus: CreateSyllabusDto): Promise<SuccessResponse<Syllabus>> {
    const response = await this.httpClient.post(ApiRoute.Syllabi + ApiRoute.Save, syllabus);
    return response.data;
  }

  /**
   * Updates a syllabus
   *
   * @param {UpdateSyllabusDto} syllabus
   * @return {Promise<SuccessResponse<Syllabus>>}
   */
  async updateSyllabus(syllabus: UpdateSyllabusDto): Promise<SuccessResponse<Syllabus>> {
    const response = await this.httpClient.post(ApiRoute.Syllabi + ApiRoute.Save, syllabus);
    return response.data;
  }

  /**
   * Fetches filtered syllabi
   *
   * @param {ListFilter} filter
   */
  async fetchSyllabi(filter?: ListFilter) {
    const response = await this.httpClient.post('/syllabi/list', {
      start: filter?.start,
      limit: filter?.end,
      filter: {search: filter?.search},
    });
    return response.data.list;
  }
}
