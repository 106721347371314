import {UserRoleType} from 'api/users/UserRole';
import {SessionIdentity} from 'api/sessions/SessionIdentity';
import {AppFeature} from 'access-control/AppFeature';
import {AclEntry} from 'access-control/AclEntry';

/**
 * Provides functions related to access control
 */
export class AccessControlService {
  /**
   * Constructs a new access control service
   *
   * @param {AclEntry[]} accessControlList
   */
  constructor(private readonly accessControlList: AclEntry[]) { }

  /**
   * Checks if a role has access to a feature
   *
   * @param {UserRoleType} role
   * @param {string} feature
   * @return {boolean}
   */
  roleCanAccess(role: UserRoleType, feature: string): boolean {
    return this.accessControlList.some((aclEntry) =>
      aclEntry. type === 'ALLOW' &&
      aclEntry.role === role &&
      aclEntry.feature === feature,
    );
  }

  /**
   * Checks if a user has access to a feature
   *
   * @param {SessionIdentity} user
   * @param {string} feature
   * @return {boolean}
   */
  userCanAccess(user: SessionIdentity, feature: string): boolean {
    let hasAccess = false;
    user.roleTypes.forEach((roleType) => {
      if (this.roleCanAccess(roleType, feature)) {
        hasAccess = true;
      }
    });
    return hasAccess;
  }

  /**
   * Checks if the given user owns the given resource
   *
   * NOTE: the resource MUST include an "ownerId" property,
   * or FALSE will always be returned.
   *
   * @param {SessionIdentity} user
   * @param {any} resource
   * @return {boolean}
   */
  userIsResourceOwner(user: SessionIdentity, resource: any): boolean {
    if (!resource.ownerId) {
      return false;
    } else {
      return user.id === resource.ownerId;
    }
  }

  /**
   * Checks if the given user is the author of the given resource
   *
   * NOTE: the resource MUST include an "authorId" property,
   * or FALSE will always be returned.
   *
   * @param {SessionIdentity} user
   * @param {any} resource
   * @return {boolean}
   */
  userIsResourceAuthor(user: SessionIdentity, resource: any): boolean {
    if (!resource.authorId) {
      return false;
    } else {
      return user.id === resource.authorId;
    }
  }

  /**
   * Checks if the given user can edit the given resource
   *
   * @param {SessionIdentity} user
   * @param {any} resource
   * @return {boolean}
   */
  userCanEditResource(user: SessionIdentity, resource: any): boolean {
    if (this.userIsResourceAuthor(user, resource)) {
      return true;
    }

    // check for school content edit access
    if (user.selectedSchoolId && user.selectedSchoolId === resource.schoolId) {
      if (this.userCanAccess(user, AppFeature.EditSchoolContent)) {
        return true;
      }
    }

    // check for system admins
    if (user.selectedSchoolId === undefined && resource.schoolId === null) {
      if (this.userCanAccess(user, AppFeature.EditSystemResources)) {
        return true;
      }
    }

    // allow all for system admins
    return user.roleTypes.includes(UserRoleType.SystemAdmin);
  }
}
