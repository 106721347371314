import React, {FormEvent, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Modal} from 'components/Modal';
import {__, localizeText} from 'i18n/localize';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {ButtonCancel} from 'components/buttons/ButtonCancel';
import classNames from 'classnames';
import {useApiContext} from 'api/ApiContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useLogger} from 'logging/logging';
import {CreateQuizQuestionDto, UpdateQuizQuestionDto} from 'api/quizzes/QuizQuestion';
import {QuizWithQuestions} from 'api/quizzes/Quiz';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {SwitchInput} from 'components/SwitchInput';
import {QuizQuestionInput} from 'views/quizzes/QuizQuestionInput';
import {QuizType} from 'api/quizzes/QuizType.enum';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {QuizQuestionAnswer} from 'api/quizzes/QuizQuestionAnswer';
import {LoadingModal} from 'components/LoadingModal';

export interface QuizInputModalProps {
  onComplete?: () => any,
  onCancel?: () => any,
  quizId?: number,
  forSurvey?: boolean,
}

export const QuizInputModal = (props: QuizInputModalProps) => {
  const logger = useLogger(QuizInputModal.name);
  const {quizzesService, surveysService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const questionMenuRef = useRef<HTMLDivElement>(null);
  const isEditing = props.quizId !== undefined;

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [showQuestionMenu, setShowQuestionMenu] = useState<boolean>(false);
  const [initialQuiz, setInitialQuiz] = useState<QuizWithQuestions | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [graded, setGraded] = useState<boolean>(false);
  const [manuallyGraded, setManuallyGraded] = useState<boolean>(false);
  const [passingScore, setPassingScore] = useState<number | undefined>(undefined);
  const [questions, setQuestions] = useState<(CreateQuizQuestionDto | UpdateQuizQuestionDto)[]>([]);
  const [errors, setErrors] = useState<any>({});


  const validate = () => {
    let isValid = true;
    setErrors({});

    if (name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (graded && !passingScore) {
      isValid = false;
      setErrors((current: any) => ({...current, passingScore: true}));
    }

    return isValid;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    if (props.quizId) {
      const dto = {
        ...initialQuiz,
        id: props.quizId,
        name,
        questions,
        survey: false,
        rated: graded,
        manuallyGraded,
        required: passingScore,
        type: QuizType.Quiz,
      };
      const result = await (props.forSurvey ? surveysService.updateSurvey(dto) : quizzesService.updateQuiz(dto));
      logger.debug('Updated quiz', result);
    } else {
      const dto = {
        name,
        questions: questions.map((q) => ({...q, hashKey: 'ok'})),
        survey: false,
        rated: graded,
        manuallyGraded,
        required: passingScore,
        type: QuizType.Quiz,
      };
      const result = await (props.forSurvey ? surveysService.createSurvey(dto) : quizzesService.createQuiz(dto));
      logger.debug('Created quiz', result);
    }

    props.onComplete?.();
  };

  const addInstructions = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Instructions,
      draggable: false,
      challenge: false,
      answers: [],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };
  const addBullets = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Bullets,
      draggable: false,
      challenge: false,
      answers: [{order: 0, correct: true} as QuizQuestionAnswer, {order: 1} as QuizQuestionAnswer],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };
  const addMultipleAnswers = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Multiple,
      draggable: false,
      challenge: false,
      answers: [],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };
  const addOpenAnswer = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Open,
      draggable: false,
      challenge: false,
      answers: [],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };
  const addOrderQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Order,
      draggable: false,
      challenge: false,
      answers: [{order: 0} as QuizQuestionAnswer, {order: 1} as QuizQuestionAnswer, {order: 2} as QuizQuestionAnswer],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };
  const addMatchOrder = () => {
    const newQuestions = [...questions];
    newQuestions.push({
      text: '',
      type: QuizQuestionType.Match,
      draggable: false,
      challenge: false,
      answers: [],
    });
    setQuestions(newQuestions);
    setShowQuestionMenu(false);
  };

  const closeOnClick = useCallback((e) => {
    if (!questionMenuRef?.current?.contains(e.target)) {
      setShowQuestionMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
    };
  }, [closeOnClick]);

  useEffect(() => {
    (async () => {
      if (props.quizId) {
        setIsFetching(true);
        const fetchedQuiz = await (
          props.forSurvey ? surveysService.fetchSurvey(props.quizId) : quizzesService.fetchQuiz(props.quizId)
        );
        setInitialQuiz(fetchedQuiz);
        logger.debug('Fetched quiz', fetchedQuiz);
        setIsFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (initialQuiz) {
      setName(initialQuiz.name);
      setGraded(initialQuiz.rated);
      setManuallyGraded(initialQuiz.manuallyGraded);
      setPassingScore(initialQuiz.required);
      setQuestions(initialQuiz.questions);
    }
  }, [initialQuiz]);

  useEffect(() => {
    validate();
  }, [name, graded, passingScore]);

  if (isFetching) {
    return <LoadingModal/>;
  }

  return (
    <Modal
      title={__(isEditing ?
        (props.forSurvey ? 'Edit Survey' : 'Edit Quiz') :
        (props.forSurvey ? 'Add Survey' : 'Add Quiz'),
      )}
      className={'full-screen'}
      bodyClassName={props.forSurvey ? 'survey' : ''}
      onCloseRequested={props.onCancel}
      hideCloseButton={true}

      buttons={
        <>
          <div
            ref={questionMenuRef}
            className={classNames('btn-group', 'dropdown', 'pull-left', {open: showQuestionMenu})}
          >
            <a
              className="btn dropdown-toggle"
              onClick={() => setShowQuestionMenu((current) => !current)}
            >
              <span>{localizeText('Add Question')}</span> <span className="caret"/>
            </a>
            <ul className="dropdown-menu pull-right" style={{borderRadius: '4px 10px'}}>
              <li>
                <a onClick={addInstructions}>
                  {localizeText('Instructions')}
                </a>
              </li>
              <li>
                <a onClick={addBullets}>
                  <i className="icon icon-check"/> <span>{localizeText('Single Answer')}</span>
                </a>
              </li>
              <li>
                <a onClick={addMultipleAnswers}>
                  <i className="icon icon-list"/> <span>{localizeText('Multiple Answers')}</span>
                </a>
              </li>
              {!props.forSurvey &&
                <li>
                  <a onClick={addOpenAnswer}>
                    <i className="icon icon-font" /> <span>{localizeText('Open Answer')}</span>
                  </a>
                </li>
              }
              <li>
                <a onClick={addOrderQuestion}>
                  <i className="icon icon-sort-number-up"/> <span>{localizeText('Correct Order')}</span>
                </a>
              </li>
              <li>
                <a onClick={addMatchOrder}>
                  {props.forSurvey && <i className="icon icon-sort-number-up"/>}
                  {!props.forSurvey && <i className="icon icon-share"/>}
                  {' '}
                  <span>{localizeText(props.forSurvey ? 'Match Order' : 'Match Items')}</span>
                </a>
              </li>
            </ul>
          </div>
          <ButtonGroup className={'pull-left'}>
            <Button
              variant={ButtonVariant.Confirm}
              type={'submit'}
              form={'quizForm'}
            />
            <ButtonCancel onClick={props.onCancel}/>
          </ButtonGroup>
        </>
      }

      body={
        <form id={'quizForm'} name="form.edit" onSubmit={handleSubmit}>
          <label className="row-fluid">
            <input
              placeholder={__(props.forSurvey ? 'Survey Name' : 'Learning Check Name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className={classNames(
                  'span12',
                  {'invalid': name.trim().length < 1},
              )}
            />
            <span style={{display: 'none'}}>{__('Name')}</span>
          </label>

          {!props.forSurvey &&
            <label className={'row-fluid'}>
              <SwitchInput on={graded} onChange={((nowChecked) => setGraded(nowChecked))} />
              <span>{__('Graded')}</span>
            </label>
          }

          {graded &&
            <div style={{padding: '10px 0'}} className={'row-fluid'}>
              <label className={'span4'}>
                <input
                  value={passingScore}
                  onChange={(e) => setPassingScore(parseInt(e.target.value))}
                  type={'number'}
                  min={0}
                  max={100}
                  className={classNames({'invalid': errors.passingScore})}
                  placeholder={__('Passing score in %')}
                />
                <span> %</span>
              </label>
              <label className={'span6'}>
                <SwitchInput on={manuallyGraded} onChange={((nowChecked) => setManuallyGraded(nowChecked))} />
                <span> Manually Graded</span>
              </label>
            </div>
          }

          {questions.map((question, i) => (
            <Fragment key={i}>
              <QuizQuestionInput
                question={question}
                forSurvey={props.forSurvey}
                numQuestions={questions.length}
                order={i}
                onChange={(newQuestion) => {
                  const newQuestions = [...questions];
                  newQuestions[i] = newQuestion;
                  setQuestions(newQuestions);
                }}
                onOrderChanged={(newOrder) => {
                  setQuestions((current) => {
                    const questionsCopy = [...current];
                    const savedQuestion = questionsCopy[newOrder];
                    questionsCopy[newOrder] = question;
                    questionsCopy[i] = savedQuestion;
                    return questionsCopy;
                  });
                }}
                onRemoved={() => {
                  const newQuestions = questions.filter((q, i2) => i2 !== i);
                  setQuestions(newQuestions);
                }}
              />
            </Fragment>
          ))}
        </form>
      }
    />
  );
};
