import React, {useEffect, useState} from 'react';
import {ProductWithRelations} from 'api/products/Product';
import {Modal} from 'components/Modal';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {Tooltip} from 'components/Tooltip';
import {Lesson} from 'api/lessons/Lesson';
import {LessonDetailsModal} from 'views/lessons/LessonDetailsModal';
import {Achievement} from 'api/achievements/Achievement';
import {AchievementDetailsModal} from 'views/achievements/AchievementDetailsModal';

export interface ProductDetailsModalProps {
  productId: number,
  onCloseRequested?: () => any,
}

export const ProductDetailsModal = (props: ProductDetailsModalProps) => {
  const logger = useLogger(ProductDetailsModal.name);
  const {productsService} = useApiContext();

  const [product, setProduct] = useState<ProductWithRelations | undefined>(undefined);
  const [viewedLesson, setViewedLesson] = useState<Lesson | undefined>(undefined);
  const [viewedAchievement, setViewedAchievement] = useState<Achievement | undefined>(undefined);

  const fetchProduct = async () => {
    const result = await productsService.fetchProduct(props.productId);
    setProduct(result);
    logger.debug('Fetched product', result);
  };

  useEffect(() => {
    (async () => {
      await fetchProduct();
    })();
  }, [props.productId, productsService]);

  return <Modal
    title={product?.name}
    onCloseRequested={props.onCloseRequested}
    className={'wide tall'}
    bodyPadding={0}

    body={
      <>
        {viewedLesson &&
          <LessonDetailsModal
            lessonId={viewedLesson.id}
            onCloseRequested={() => setViewedLesson(undefined)}
          />
        }

        {viewedAchievement &&
          <AchievementDetailsModal
            achievementId={viewedAchievement.id}
            onCloseRequested={() => setViewedAchievement(undefined)}
          />
        }

        <div className="description padding10">
          <div className="html" dangerouslySetInnerHTML={{__html: product?.description ?? ''}}/>
        </div>

        <div className="row-fluid block no-space">
          <div className="span4">
            <div className="header text-center">{__('Categories')}</div>
            <div className="body">
              {product?.categories.map((category) => (
                <div key={category.id} className="pill" style={{borderColor: category.color}}>
                  <div className="circle-20" style={{float: 'right', background: category.color}}>
                    <span className={classNames('icon', category.icon)} />
                  </div>
                  {category.name}
                </div>
              ))}
            </div>
          </div>
          <div className="span4">
            <div className="header text-center">{__('Lessons')}</div>
            <div className="body">
              {product?.lessons.map((lesson) => (
                <Tooltip title={<div dangerouslySetInnerHTML={{__html: lesson.text}} />} key={lesson.id}>
                  <div
                    key={lesson.id}
                    className="pill pointer"
                    onClick={() => setViewedLesson(lesson)}
                    style={{backgroundColor: lesson.category?.color}}
                  >
                    {lesson.name}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          <div className="span4">
            <div className="header text-center">{__('Skills')}</div>
            <div className="body">
              {product?.ilries.map((skill) => (
                <div
                  key={skill.id}
                  className="pill"
                  style={{borderColor: skill.category?.color}}
                >
                  <div className="circle-20" style={{float: 'right', background: skill.category?.color}}>
                    <span className={classNames('icon', skill.category?.icon)} />
                  </div>
                  {skill.name}
                </div>
              ))}
            </div>
          </div>

          <div className="row-fluid block no-space">
            <div className="span12">
              <div className="header text-center">{__('Badges')}</div>
              <div className="body" style={{marginBottom: 10}}>
                {product?.achievements.map((achievement) => (
                  <div
                    key={achievement.id}
                    className="pill pointer"
                    onClick={() => setViewedAchievement(achievement)}
                  >
                    {achievement.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    }

    footer={<div style={{height: 0}} />}
  />;
};
