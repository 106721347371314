import React, {ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import {Editor} from '@tinymce/tinymce-react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {Button, ButtonVariant} from 'components/buttons/Button';

/**
 * A text input with rich text (HTML) features
 */

export interface RichTextInputProps {
  value?: string,
  onChange?: (newValue: string) => any,
  tabIndex?: number,
  required?: boolean,
  label?: string,
  placeholder?: string | ReactElement,
  onBlur?: () => any,
  hideOutline?: boolean,
  alwaysRichEdit?: boolean,
}

export const RichTextInput = (props: RichTextInputProps) => {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<FileUpload | undefined>(undefined);

  const placeholder = props.placeholder ?? '';
  const isInvalid = props.required && (!props.value || props.value.length < 1);

  const handleClickOutside = useMemo(() => (e: any) => {
    const modalContainers = document.getElementsByClassName('tox-dialog');
    const toolbarOverflowContainers = document.getElementsByClassName('tox-toolbar__overflow');
    const wasEditorClick = editorContainerRef.current?.contains(e.target) ?? false;

    let wasModalClick = false;
    Array.prototype.forEach.call(modalContainers, (container) => {
      if (container.contains(e.target)) {
        wasModalClick = true;
      }
    });
    Array.prototype.forEach.call(toolbarOverflowContainers, (container) => {
      if (container.contains(e.target)) {
        wasModalClick = true;
      }
    });

    if (
      !wasEditorClick &&
      !showUpload &&
      !wasModalClick
    ) {
      setIsFocused(false);
    }
  }, [showUpload, editorContainerRef]);

  useEffect(() => {
    document.removeEventListener('mousedown', handleClickOutside);
    if (!props.alwaysRichEdit) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (!props.alwaysRichEdit) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [props.alwaysRichEdit, handleClickOutside]);

  return (
    <div
      className={classNames(
          'richtext',
          {editing: isFocused},
          {required: isInvalid},
      )}
      style={{borderBottom: props.hideOutline ? 'none' : '1px solid #ccc'}}
      tabIndex={props.tabIndex}
    >
      {showUpload &&
        <Modal
          title={__('Image Upload')}
          buttons={
            <Button
              variant={ButtonVariant.Confirm}
              onClick={() => {
                if (uploadedFile) {
                  editorRef.current?.editor?.insertContent(
                      '<img src="' + uploadedFile.publicUrl + '300/300/1" style="float: left; margin: 0 5px"/>',
                  );
                  setUploadedFile(undefined);
                }
                setShowUpload(false);
              }}
            />
          }
          body={
            <FileUploadInput
              onChange={(newFiles) => {
                if (newFiles.length > 0) {
                  setUploadedFile(newFiles[0]);
                }
              }}
            />
          }
          onCloseRequested={() => setShowUpload(false)}
        />
      }

      {props.label &&
        <label htmlFor={props.label} style={{display: 'none'}}>
          {props.label}
        </label>
      }

      {!isFocused && !props.alwaysRichEdit &&
        <>
          <div
            onClick={() => setIsFocused(true)}
            className="display"
            dangerouslySetInnerHTML={
              props.value ?
                {__html: props.value ?? ''} :
                {__html: `<span class="placeholder">${placeholder ?? ''}</span>`}
            }
          />
          <textarea
            placeholder={placeholder as string}
            aria-label={placeholder as string}
            id={props.label}
            style={{display: 'none'}}
            defaultValue={props.value}
          />
        </>
      }

      {(isFocused || props.alwaysRichEdit) &&
        <div ref={editorContainerRef}>
          <Editor
            value={props.value}
            ref={editorRef}
            init={{
              setup: (editor) => {
                editor.ui.registry.addButton(
                    'uploadImage',
                    {
                      icon: 'image',
                      onAction: function() {
                        setShowUpload(true);
                      },
                    },
                );
              },
              auto_focus: true,
              height: 500,
              menubar: false,
              plugins: [
                'lists autolink link image',
                'code fullscreen',
                'media paste code',
              ],
              style_formats: [
                {title: 'Added', inline: 'span', classes: 'new'},
                {title: 'Revise', inline: 'span', classes: 'revise'},
                {title: 'Remove', inline: 'span', classes: 'remove'},
                {title: 'Simplify', inline: 'span', classes: 'simplify'},
              ],
              toolbar:
                'styleselect removeformat | bold italic strikethrough ' +
                '| alignleft aligncenter bullist numlist outdent indent' +
                '| link code | uploadImage media',
              statusbar: false,
            }}
            onEditorChange={(newContent) => {
              props.onChange?.(newContent);
            }}
            onBlur={() => {
              props.onBlur?.();
            }}
          />
        </div>
      }
    </div>
  );
};
