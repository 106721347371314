import React from 'react';

/**
 * A user notification
 *
 * Note: this will not automatically render as a notification
 * (ie. a "toast" popup). This is only the component to layout the icon and
 * content. A notification manager must be used to make it a notification.
 */

export interface NotificationProps {
  variant?: NotificationVariant,
  iconCode?: string,
  iconStyle?: React.CSSProperties,
  title?: string,
  message?: string,
}

export enum NotificationVariant {
  Success,
  Error,
  Info,
}

export const Notification = (props: NotificationProps) => {
  const variantStyles: React.CSSProperties = {};
  let variantIconCode: string | undefined = undefined;

  if (props.variant === NotificationVariant.Success) {
    variantIconCode = 'icon-ok';
    variantStyles.color = '#0c0';
  } else if (props.variant === NotificationVariant.Error) {
    variantIconCode = 'icon-attention';
    variantStyles.color = '#c00';
  } else if (props.variant === NotificationVariant.Info) {
    variantIconCode = 'icon-info';
    variantStyles.color = '#555';
    variantStyles.margin = '10px 20px 0 -8px';
  }

  return <>
    {props.iconCode || variantIconCode &&
      <i
        className={props.iconCode ?? variantIconCode ?? ''}
        style={{
          float: 'left',
          fontSize: 42,
          color: '#0c0',
          margin: '10px 12px 0 -10px',
          ...variantStyles,
          ...props.iconStyle,
        }}
      />
    }
    <div style={{float: 'left'}}>
      <h2>{props.title}</h2>
      <p>{props.message}</p>
    </div>
  </>;
};
