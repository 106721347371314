import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {Button} from 'components/buttons/Button';
import {__} from 'i18n/localize';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {useApiContext} from 'api/ApiContext';
import {Product} from 'api/products/Product';
import {useLogger} from 'logging/logging';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import styleClasses from './LessonEditorView.module.scss';
import classNames from 'classnames';

export const LessonEditorView = () => {
  const logger = useLogger(LessonEditorView.name);
  const {push: pushRoute} = useHistory();
  const {productsService} = useApiContext();

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [draftTitle, setDraftTitle] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const loadDraft = async () => {};
  const saveDraft = async () => {
    setIsSaving(true);
    setIsSaving(false);
  };

  useEffect(() => {
    (async () => {
      const fetchedProducts = await productsService.fetchProducts();
      setProducts(fetchedProducts);
      logger.debug('Fetched products', fetchedProducts);
    })();
  }, []);

  return <PageView>
    <div className="top-bar">Lesson Builder</div>

    <ContextMenu>
      <Button
        className={'btn-warning'}
        text={__('Submit Final Content')}
      />
      <Button
        className={'btn-info'}
        text={__('Content Structure')}
        onClick={() => pushRoute(AppRoute.SystemContext + AppRoute.ContentEditor)}
      />
    </ContextMenu>

    <div className="filter left">
      <select
        placeholder={__('Product')}
        value={selectedProduct?.id ?? ''}
        onChange={(e) => {
          const productId = parseInt(e.target.value);
          if (isNaN(productId)) {
            setSelectedProduct(undefined);
          } else {
            const product = products.filter((p) => p.id === productId)[0];
            setSelectedProduct(product);
          }
        }}
        className={classNames('span3', styleClasses.productSelect)}
        style={{borderRadius: '4px 4px 4px 10px', marginRight: 5, marginLeft: 5}}
      >
        <option value="">- {__('Select Product')} -</option>
        {/* <option ng-if="school" value="--school--">{{ school.name }}</option>*/}
        <AccessControlled feature={AppFeature.CreateProduct}>
          {products.map((product) => (
            <option value={product.id} key={product.id}>
              {product.name}
            </option>
          ))}
        </AccessControlled>
      </select>

      <Button
        onClick={loadDraft}
        className={'btn-info'}
        iconCode={'icon-download-cloud'}
      />
      {' '}
      <Button
        onClick={saveDraft}
        className={'btn-success'}
        iconCode={'icon-floppy'}
        disabled={isSaving}
      />

      <input
        placeholder={__('Draft Title')}
        value={draftTitle}
        onChange={(e) => setDraftTitle(e.target.value)}
        type="text"
        className="12 cat"
        required
      />
    </div>
  </PageView>;
};
