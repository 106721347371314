import React, {useEffect, useState} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {AppRoute} from 'routing/AppRoute';
import {PageNotFoundView} from 'views/errors/PageNotFoundView';
import {useSchoolContext} from 'contexts/SchoolContext';
import {StatsOverviewView} from 'views/stats/StatsOverviewView';
import {UsersListView} from 'views/users/UsersListView';
import {InstructorsListView} from 'views/instructors/InstructorsListView';
import {CoursesListView} from 'views/courses/CoursesListView';
import {LessonsListView} from 'views/lessons/LessonsListView';
import {
  ContentEditorDashboard,
} from 'views/content-editor/ContentEditorDashboard';
import {CategoriesListView} from 'views/categories/CategoriesListView';
import {QuizzesListView} from 'views/quizzes/QuizzesListView';
import {SkillsListView} from 'views/skills/SkillsListView';
import {AchievementsListView} from 'views/achievements/AchievementsListView';
import {ProductsListView} from 'views/products/ProductsListView';
import {SyllabiListView} from 'views/schools/SyllabiListView';
import {CollageLibraryDashboard} from 'views/collages/CollageLibraryDashboard';
import {VersionListView} from 'views/versions/VersionsListView';
import {SurveysListView} from 'views/surveys/SurveysListView';
import {SchoolConfigurationView} from 'views/schools/SchoolConfigurationView';

/**
 * Handles school (ie. viewing a single school) state,
 * setting up context and handling sub-routing.
 */

export const SchoolRouter = () => {
  const {schoolId}: any = useParams();
  const {path, url} = useRouteMatch();
  const {setSelectedSchool} = useSchoolContext();

  const [isSettingActiveSchool, setIsSettingActiveSchool] =
    useState<boolean>(true);
  const [hasErrorSettingSchool, setHasErrorSettingSchool] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (schoolId) {
        setIsSettingActiveSchool(true);
        try {
          await setSelectedSchool(schoolId);
        } catch (e) {
          setHasErrorSettingSchool(true);
        }
        setIsSettingActiveSchool(false);
      }
    })();
  }, [schoolId]);

  if (isSettingActiveSchool) {
    return (
      <LoadingIndicator
        fullscreen={true}
        message={'Loading School'}
      />
    );
  }

  if (hasErrorSettingSchool) {
    return <div style={{margin: 50}}>
      <h4>Failed to load school.</h4>
    </div>;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={url + AppRoute.Stats} />
      </Route>

      <Route
        exact
        path={path + AppRoute.Stats}
        component={StatsOverviewView}
      />
      <Route
        exact
        path={path + AppRoute.Users}
        component={UsersListView}
      />
      <Route
        exact
        path={path + AppRoute.Instructors}
        component={InstructorsListView}
      />
      <Route
        exact
        path={path + AppRoute.SchoolCourses}
        component={CoursesListView}
      />
      <Route
        exact
        path={path + AppRoute.Lessons}
        component={LessonsListView}
      />
      <Route
        exact
        path={path + AppRoute.ContentEditor}
        component={ContentEditorDashboard}
      />
      <Route
        exact
        path={path + AppRoute.Categories}
        component={CategoriesListView}
      />
      <Route
        exact
        path={path + AppRoute.Versions}
        component={VersionListView}
      />
      <Route
        exact
        path={path + AppRoute.Surveys}
        component={SurveysListView}
      />
      <Route
        exact
        path={path + AppRoute.LearningChecks}
        component={QuizzesListView}
      />
      <Route
        exact
        path={path + AppRoute.Skills}
        component={SkillsListView}
      />
      <Route
        exact
        path={path + AppRoute.Achievements}
        component={AchievementsListView}
      />
      <Route
        exact
        path={path + AppRoute.Products}
        component={ProductsListView}
      />
      <Route
        exact
        path={path + AppRoute.Syllabi}
        component={SyllabiListView}
      />
      <Route
        exact
        path={path + AppRoute.CollageLibrary}
        component={CollageLibraryDashboard}
      />
      <Route
        exact
        path={path + AppRoute.Configure}
        component={SchoolConfigurationView}
      />

      <Route path={'*'}>
        <PageNotFoundView/>
      </Route>
    </Switch>
  );
};
