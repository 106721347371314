import {AxiosInstance} from 'axios';
import {Category, CategoryWithParent, CreateCategoryDto, UpdateCategoryDto} from 'api/categories/Category';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {EntityListService} from 'api/lists/EntityListService';
import {ListFilter} from 'api/lists/ListFilter';

/**
 * Provides API category functions
 */
export class CategoriesService {
  private cachedCategories: Category[] = [];

  /**
   * Constructor
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches categories configured in the API
   */
  async fetchCategories(filter: ListFilter = {}, allowCache = false): Promise<Category[]> {
    if (allowCache && this.cachedCategories.length > 0) {
      return this.cachedCategories;
    }
    const result = await this.entityListService.fetchEntityList(ApiRoute.Categories, filter);
    if (allowCache) {
      this.cachedCategories = result;
    }
    return result;
  }

  /**
   * Fetches top-level categories (i.e. categories with no parent).
   */
  fetchTopLevelCategories(): Promise<Category[]> {
    return this.entityListService.fetchEntityList(ApiRoute.Categories, {
      filters: {
        parent: null,
      },
    });
  }

  /**
   * Fetches a category by ID
   */
  async fetchCategory(id: number): Promise<CategoryWithParent> {
    const response = await this.httpClient.post(ApiRoute.Categories + ApiRoute.Get, {id});
    return response.data;
  }

  /**
   * Creates a new category
   */
  async createCategory(category: CreateCategoryDto): Promise<SuccessResponse<Category>> {
    const response = await this.httpClient.post(ApiRoute.Categories + ApiRoute.Save, category);
    return response.data;
  }

  /**
   * Updates a category
   */
  async updateCategory(category: UpdateCategoryDto): Promise<SuccessResponse<Category>> {
    const response = await this.httpClient.post(ApiRoute.Categories + ApiRoute.Save, category);
    return response.data;
  }

  /**
   * Delete a category
   */
  async deleteCategory(categoryId: number): Promise<any> {
    const response = await this.httpClient.post(ApiRoute.CategoryDelete, {
      id: categoryId,
    });
    return response.data;
  }

  /**
   * Moves the given category to the given school,
   * or system level if no school ID is provided
   */
  async changeCategorySchool(categoryId: number, schoolId?: number): Promise<{ok: boolean}> {
    const response = await this.httpClient.post(ApiRoute.CategoryChangeSchool, {
      category: categoryId,
      school: schoolId,
    });
    return response.data;
  }

  /**
   * Finds the top-most ancestor of a given category
   */
  getTopLevelAncestorCategory(category: CategoryWithParent): CategoryWithParent {
    let currentCategory = category;
    while (currentCategory.parent) {
      currentCategory = currentCategory.parent;
    }
    return currentCategory;
  }
}
