import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Course, CourseDetails} from 'api/courses/Course';
import {AppointmentsListModal} from 'views/appointments/AppointmentsListModal';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {FormattedDate} from 'components/FormattedDate';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {GoalThumbnail} from 'views/goals/GoalThumbnail';
import {Goal} from 'api/goals/Goal';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {CourseRecommendedSkill} from 'api/courses/CourseRecommendedSkill';
import {ChallengeInputModal} from 'views/challenges/ChallengeInputModal';
import {ChallengeQuickView} from 'views/challenges/ChallengeQuickView';
import {Config} from 'Config';
import {AchievementThumbnail} from 'views/achievements/AchievementThumbnail';
import {EmailQuickView} from 'views/email/EmailQuickView';
import {Achievement} from 'api/achievements/Achievement';
import {AchievementDetailsModal} from 'views/achievements/AchievementDetailsModal';
import {EmailInputModal} from 'views/email/EmailInputModal';
import {Droppable} from 'react-beautiful-dnd';
import {Skill} from 'api/skills/Skill';
import {SkillRelatedLessonsModal} from 'views/skills/SkillRelatedLessonsModal';
import {Tooltip} from 'components/Tooltip';
import {Link} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {UsersModal} from 'views/courses/UsersModal';
import {confirmDelete} from 'components/confirmDelete';
import {getProfileRoute} from 'routing/routing';

export interface CourseQuickViewProps {
  course: Course,
  onEditRequested?: () => any,
  onDeleteRequested?: () => any,
  addedGoals?: Goal[],
  newlyAssignedGoals?: Goal[],
  onUpdated?: () => any,
}

export const CourseQuickView = (props: CourseQuickViewProps) => {
  const logger = useLogger(CourseQuickView.name);
  const {coursesService, emailsService} = useApiContext();
  const {course} = props;

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showAppointments, setShowAppointments] = useState<boolean>(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
  const [details, setDetails] = useState<CourseDetails | undefined>(undefined);
  const [openGoal, setOpenGoal] = useState<Goal | undefined>(undefined);
  const [openAchievement, setOpenAchievement] = useState<Achievement | undefined>(undefined);
  const [showCreateGoal, setShowCreateGoal] = useState<boolean>(false);
  const [editGoal, setEditGoal] = useState<Goal | undefined>(undefined);
  const [recommendedSkills, setRecommendedSkills] = useState<CourseRecommendedSkill[]>([]);
  const [shownRecommendedSkill, setShownRecommendedSkill] = useState<Skill | undefined>(undefined);
  const [showAddChallenge, setShowAddChallenge] = useState<boolean>(false);
  const [studentsGoalIdFilter, setStudentsGoalIdFilter] = useState<number | undefined>(undefined);
  const [studentsGoalStatusFilter, setStudentsGoalStatusFilter] = useState<string>('');
  const [showAddEmail, setShowAddEmail] = useState<boolean>(false);
  const [showAddUserType, setShowAddUserType] = useState<number | undefined>(undefined);

  const assignedGoals = details?.course.assignedGoals.concat(props.newlyAssignedGoals ?? []) ?? [];
  const unAssignedGoals = details?.course.notAssignedGoals
      .filter((g) => !props.newlyAssignedGoals?.find((f) => f.id === g.id)) ?? [];

  const toggleShowDetails = () => setShowDetails((current) => !current);
  const openAppointments = () => setShowAppointments(true);

  const loadDetails = async () => {
    const fetchedDetails = await coursesService.fetchCourseDetails(props.course.id);
    setDetails(fetchedDetails);
    logger.debug('Fetched course details', fetchedDetails);
  };

  const loadRecommendedSkills = async () => {
    const fetchedRecommendedSkills = await coursesService.fetchCourseRecommendedSkills(props.course.id);
    setRecommendedSkills(fetchedRecommendedSkills ?? []);
    logger.debug('Fetched recommended skills', fetchedRecommendedSkills);
  };

  const loadAllDetails = async () => {
    setIsLoadingDetails(true);
    await Promise.all([
      loadDetails(),
      loadRecommendedSkills(),
    ]);
    setIsLoadingDetails(false);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.14), rgba(255,255,255,0.53));',
    background: isDraggingOver ?
      '#8dc73f' :
      'none',
  });

  useEffect(() => {
    (async () => {
      if (showDetails && details === undefined) {
        await loadAllDetails();
      }
    })();
  }, [showDetails]);

  return (
    <div
      id={`course-${course.id}`}
      className={classNames('course pack expandable', {'expanded': showDetails})}
      aria-label={`Explore course ${course.name}`}
    >
      {openGoal &&
        <GoalDetailsModal
          goalId={openGoal.id}
          onCloseRequested={() => setOpenGoal(undefined)}
          onDeleted={async () => {
            setOpenGoal(undefined);
            await loadDetails();
          }}
          onDeactivated={async () => {
            setOpenGoal(undefined);
            await loadDetails();
          }}
          onActivated={async () => {
            setOpenGoal(undefined);
            await loadDetails();
          }}
          onEditRequested={() => {
            setEditGoal(openGoal);
            setOpenGoal(undefined);
          }}
        />
      }

      {openAchievement &&
        <AchievementDetailsModal
          achievementId={openAchievement.id}
          onCloseRequested={() => setOpenAchievement(undefined)}
        />
      }

      {editGoal &&
        <GoalInputModal
          initialGoalId={editGoal.id}
          onCloseRequested={() => setEditGoal(undefined)}
        />
      }

      {showCreateGoal &&
        <GoalInputModal
          goalCourseId={course.id}
          onCloseRequested={() => setShowCreateGoal(false)}
          onCompleted={async () => {
            setShowCreateGoal(false);
            await loadDetails();
          }}
        />
      }

      {showAppointments &&
        <AppointmentsListModal
          courseId={course.id}
          onCloseRequested={() => setShowAppointments(false)}
        />
      }

      {showAddChallenge &&
        <ChallengeInputModal
          forCourse={course}
          onCloseRequested={() => setShowAddChallenge(false)}
          onComplete={async () => {
            await loadDetails();
            setShowAddChallenge(false);
          }}
        />
      }

      {showAddUserType &&
        <UsersModal
          roleId={showAddUserType}
          onCloseRequested={() => setShowAddUserType(undefined)}
          selectedUserIds={
            showAddUserType === 3 ? props.course.teachers.map((t) => t.id) :
              showAddUserType === -1 ? props.course.students.map((s) => s.id) :
               details?.course?.assistants?.map((a) => a.id) ?? []
          }
          onComplete={async (userIds) => {
            if (showAddUserType === 3) {
              await coursesService.addCourseInstructors(props.course.id, userIds);
              props.onUpdated?.();
            } else if (showAddUserType === 10) {
              await coursesService.addCourseAssistants(props.course.id, userIds);
              await loadDetails();
            } else {
              await coursesService.addCourseStudents(props.course.id, userIds);
              await loadDetails();
            }
            setShowAddUserType(undefined);
          }}
        />
      }

      {showAddEmail &&
        <EmailInputModal
          forCourseId={course.id}
          onCloseRequested={() => setShowAddEmail(false)}
          onCompleted={async (newEmail) => {
            setDetails((current) => {
              if (current && newEmail) {
                const newEmails = current?.course.emails ?? [];
                newEmails.push(newEmail);
                return {
                  ...current,
                  course: {
                    ...current.course,
                    emails: newEmails,
                  },
                };
              } else {
                return current;
              }
            });
            setShowAddEmail(false);
          }}
        />
      }

      {shownRecommendedSkill &&
        <SkillRelatedLessonsModal
          skillId={shownRecommendedSkill.id}
          onCloseRequested={() => setShownRecommendedSkill(undefined)}
        />
      }

      <div className="header" onClick={toggleShowDetails}>
        <div className="buttons btn-group">
          <Tooltip title={__('Schedule Remote Meeting')}>
            <a
              className="btn btn-icon btn-mini"
              onClick={(e) => {
                e.stopPropagation();
                openAppointments();
              }}
              aria-label="{__('Video Conference')}"
            >
              <i className="icon-videocam" /> <span>{__('Video Conference')}</span>
            </a>
          </Tooltip>
          <Tooltip title={__('Edit')}>
            <a
              className="btn btn-icon btn-mini"
              onClick={(e) => {
                e.stopPropagation();
                props.onEditRequested?.();
              }}
              aria-label="edit course info"
            >
              <i className="icon-edit" />
            </a>
          </Tooltip>
          <AccessControlled feature={AppFeature.ManageCourse}>
            <Tooltip title={__('Delete')}>
              <a
                className="btn btn-icon btn-mini"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDeleteRequested?.();
                }}
                aria-label="delete course"
              >
                <i className="icon-trash" />
              </a>
            </Tooltip>
          </AccessControlled>
        </div>

        <div className="name">
          {course.name}
          <span className="badge pull-right">
            {course.school.name}
          </span>
        </div>

        <div className="details">
          <div className="field">
            <span>{__('Participants')}</span>:
            <span className="badge ng-binding">{course.students.length}</span>
          </div>
          <div className="field">
            <span>{__('Goals')}</span>:
            <span className="badge ng-binding">
              {assignedGoals.length}
            </span>
          </div>
          <div className="field">
            <span>{__('Active')}</span>:
            <i
              className={course.active ? 'icon-check' : 'icon-check-empty'}
              aria-label={course.active ? 'course active' : 'course inactive'}
            />
          </div>
          <div className="field">
            <span>{__('Open')}</span>:
            <i
              className={course.open ? 'icon-check' : 'icon-check-empty'}
              aria-label={course.open ? 'course open' : 'course closed'}
            />
          </div>

          <div className="field">
            <div className="label" style={{backgroundColor: course.category?.color, padding: '3px 5px'}}>
              {course.category?.name}
            </div>
          </div>
        </div>

        <div className="clearfix"/>
      </div>

      {showDetails &&
        <>
          {isLoadingDetails && <LoadingIndicator/>}

          {!isLoadingDetails &&
          <div className="body clearfix">
            <div className="row-fluid">
              <span>
                <span>{__('Public')}</span>:
                <i
                  className={classNames({'icon-check': course.open}, {'icon-check-empty': !course.open})}
                  aria-label={course.open ? 'course open' : 'course closed'}
                />
              </span>

              {course.openFrom &&
                <span>
                  Open
                  <span><span>{__('From')}</span>:
                    <FormattedDate timestamp={course.openFrom} />
                  </span>
                  <span><span>{__('To')}</span>:
                    <FormattedDate timestamp={course.openTo} />
                  </span>
                </span>
              }

              <div className="btn-group pull-right">
                <AccessControlled feature={AppFeature.ManageCourse}>
                  <Link to={AppRoute.Register.replace(':instanceKey', course.school.key)} target={'_blank'}>
                    <Button
                      variant={ButtonVariant.Info}
                      className={'btn-small'}
                      iconCode={'icon-link'}
                      text={__('Registration Page')}
                    />
                  </Link>
                </AccessControlled>

                <Button
                  className={'btn-small'}
                  variant={ButtonVariant.Print}
                  text={__('Print')}
                />

                <Button
                  className={'btn-small'}
                  iconCode={'icon-download'}
                  text={__('Export')}
                  variant={ButtonVariant.Download}
                />
              </div>
            </div>

            <div className="keywords frame">
              <div className="title">{__('Keywords')}</div>
              <div className="padding">{course.keywords}</div>
            </div>
            <div className="description frame">
              <div className="title">{__('Description')}</div>
              <div
                className="html padding"
                dangerouslySetInnerHTML={{__html: course.description ?? ''}}
              />
            </div>

            <div className="row-fluid">
              {/* Left Column */}
              <div className="span3">
                <div className="goals frame">
                  <div className="title">
                    <span>{__('Unassigned Goals')}</span>
                    <div className="buttons btn-group">
                      <Button
                        variant={ButtonVariant.Add}
                        onClick={() => setShowCreateGoal(true)}
                        tooltip={__('Create goal')}
                        className="btn-mini"
                      />
                    </div>
                  </div>
                  <Droppable droppableId={`course-${course.id}-unassigned`}>
                    {(provided, snapshot) => (
                      <div
                        className="body"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {unAssignedGoals.concat(props.addedGoals ?? []).map((goal, i) => (
                          <GoalThumbnail
                            key={goal.id}
                            goal={goal}
                            forCourse={true}
                            onOpenRequested={() => setOpenGoal(goal)}
                            onDeleted={() => setDetails((current) => {
                              if (!current) {
                                return undefined;
                              } else {
                                return {
                                  ...current,
                                  course: {
                                    ...current.course,
                                    notAssignedGoals: current.course.notAssignedGoals.filter((g) => g.id !== goal.id),
                                  },
                                };
                              }
                            })}
                            draggable={true}
                            draggableIndex={i}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {details && details.course.notAssignedGoals.length > 0 &&
                    <div className="alert" style={{marginTop: 10}}>
                      {__('To assign goals to participants, drag them to the assigned goals area')}
                    </div>
                  }
                  <div className="goals frame">
                    <div className="title">{__('Assigned Goals')}</div>
                    <Droppable droppableId={`course-${course.id}-assigned`}>
                      {(provided, snapshot) => (
                        <div
                          className="body"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {assignedGoals.map((goal: Goal) => (
                            <GoalThumbnail
                              key={goal.id}
                              goal={goal}
                              forCourse={true}
                              onOpenRequested={() => setOpenGoal(goal)}
                              onDeleted={() => setDetails((current) => {
                                if (!current) {
                                  return undefined;
                                } else {
                                  return {
                                    ...current,
                                    course: {
                                      ...current.course,
                                      assignedGoals: current.course.assignedGoals.filter((g) => g.id !== goal.id),
                                    },
                                  };
                                }
                              })}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>

                  <div className="frame">
                    <div className="title">{__('Badges')}</div>
                    <div className="body">
                      {details?.achievements.map((achievement) => (
                        <AchievementThumbnail
                          key={achievement.id}
                          achievement={achievement}
                          onOpenRequested={() => setOpenAchievement(achievement)}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="frame">
                    <div className="title">{__('Recommend Skills')}</div>
                    <div className="body">
                      {recommendedSkills.map((skill) => (
                        <div
                          key={skill.id}
                          className="pill clearfix pointer"
                          onClick={() => setShownRecommendedSkill(skill.ilri)}
                          aria-label="Show skill"
                        >
                          <div
                            className="circle-20"
                            style={{
                              float: 'right',
                              background: 'rgb(235, 229, 76)',
                              backgroundColor: skill.ilri.category?.color,
                            }}
                          >
                            <span className={classNames('icon icon-clock', skill.ilri.category?.icon)} />
                          </div>
                          {skill.ilri.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="frame">
                    <div className="title">
                      <span>{__('Challenges')}</span>
                      <div className="buttons btn-group">
                        <Button
                          variant={ButtonVariant.Add}
                          tooltip={__('Add')}
                          onClick={() => setShowAddChallenge(true)}
                          className={'btn-mini'}
                        />
                      </div>
                    </div>
                    <div className="body">
                      {details?.challenges.map((challenge) => (
                        <ChallengeQuickView
                          key={challenge.id}
                          challenge={challenge}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="span9">
                <div className="frame">
                  <div className="title">
                    <div className="buttons btn-group">
                      <AccessControlled feature={AppFeature.ManageCourse}>
                        <Tooltip title={__('add instructor')}>
                          <a
                            className="btn btn-success btn-mini"
                            onClick={() => setShowAddUserType(3)}
                            aria-label="Add instructor"
                          >
                            <i className="icon-plus-circled" />
                          </a>
                        </Tooltip>
                      </AccessControlled>
                    </div>
                    <span>{__('Instructors')}</span>
                  </div>
                  <div className="body">
                    {course.teachers.map((teacher) => (
                      <div key={teacher.id} className="teacher accepted list-item">
                        <img
                          className="circle-30 image"
                          src={teacher.image?.url ? teacher.image.url + '100/100' : Config.defaultUserImage}
                          alt={''}
                        />
                        <span className="name">{teacher.name}</span>
                        <div className="buttons btn-group">
                          <AccessControlled feature={AppFeature.ManageCourse}>
                            <Tooltip title={__('remove instructor')}>
                              <a
                                className="btn btn-icon btn-mini"
                                aria-label="Remove instructor"
                                onClick={async () => {
                                  confirmDelete(
                                      async () => {
                                        await coursesService.removeCourseInstructor(props.course.id, teacher.id);
                                        props.onUpdated?.();
                                      },
                                      __('Delete'),
                                      __('Are you sure you want to remove the instructor from the course?'),
                                  );
                                }}
                              >
                                <i className="icon-cancel"/>
                              </a>
                            </Tooltip>
                          </AccessControlled>
                        </div>

                        <div className="state"/>
                      </div>
                    ))}

                    {details?.invitations.map((invitation) => (
                      <AccessControlled
                        key={invitation.id}
                        feature={AppFeature.ManageCourse}
                      >
                        <div
                          className={classNames(
                              'teacher list-item',
                              {'waiting': invitation.state === 'AWAITING'},
                              {'rejected': invitation.state === 'REJECTED'},
                          )}
                        >
                          <img
                            className="circle-30 image"
                            src={invitation.user.image.url ?
                              invitation.user.image.url + '100/100' :
                              Config.defaultUserImage
                            }
                            alt={''}
                          />
                          <span className="name">{invitation.user.name}</span>
                          <div className="buttons btn-group">
                            <Tooltip title={__('Delete')}>
                              <a
                                className="btn btn-icon btn-mini"
                                // TODO: delete invitation? Unable to find how these invitations are created
                                // (adding instructors adds them immediately, not an "invited" status)
                                ng-click="removeInvitation(course, invitation)"
                              >
                                <i className="icon-trash" />
                              </a>
                            </Tooltip>
                          </div>
                          <div className="state"/>
                        </div>
                      </AccessControlled>
                    ))}
                  </div>
                </div>

                <div className="frame">
                  <div className="title">
                    <div className="buttons btn-group">
                      <AccessControlled feature={AppFeature.ManageCourse}>
                        <Tooltip title={__('Add')}>
                          <a
                            className="btn btn-success btn-mini"
                            onClick={() => setShowAddUserType(10)}
                            aria-label="Add instructor"
                          >
                            <i className="icon-plus-circled" />
                          </a>
                        </Tooltip>
                      </AccessControlled>
                    </div>
                    <span>{__('Assistants')}</span>
                  </div>

                  <div className="body">
                    {details?.course.assistants?.map((assistant) => (
                      <div key={assistant.id} className="teacher list-item">
                        <img
                          className="circle-30 image"
                          src={assistant.image?.url ? assistant.image.url + '100/100' : Config.defaultUserImage}
                          alt={''}
                        />
                        <span className="name">{assistant.name}</span>
                        <div className="buttons btn-group">
                          <AccessControlled feature={AppFeature.ManageCourse}>
                            <Tooltip title={__('Delete')}>
                              <a
                                className="btn btn-icon btn-mini"
                                onClick={() => confirmDelete(async () => {
                                  await coursesService.removeCourseAssistant(props.course.id, assistant.id);
                                  await loadDetails();
                                }, __('Delete'), __('Are you sure you want to remove the assistant from the course?'))}
                                aria-label="Remove instructor"
                              >
                                <i className="icon-trash" />
                              </a>
                            </Tooltip>
                          </AccessControlled>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="frame" style={{marginTop: 10}}>
                    <div className="title">
                      <span>{__('Participants')}</span>
                      <div className="buttons btn-group">
                        <AccessControlled feature={AppFeature.ManageCourse}>
                          <Tooltip title={__('Add')}>
                            <a
                              className="btn btn-success btn-mini"
                              onClick={() => setShowAddUserType(-1)}
                              aria-label="Add Student"
                            >
                              <i className="icon-plus-circled" />
                            </a>
                          </Tooltip>
                        </AccessControlled>
                      </div>

                      <div className="frame-filter">
                        <select
                          value={studentsGoalIdFilter}
                          onChange={(e) => setStudentsGoalIdFilter(parseInt(e.target.value))}
                          aria-label="Filter Students by Goal"
                        >
                          <option value={undefined}>-- {__('Goal')} --</option>
                          {details?.course.assignedGoals.map((goal) => (
                            <option key={goal.id} value={goal.id}>{goal.name}</option>
                          ))}
                        </select>
                        <select
                          value={studentsGoalStatusFilter}
                          onChange={(e) => setStudentsGoalStatusFilter(e.target.value)}
                          aria-label="Filter Students by Goal State"
                        >
                          <option value="">-- {__('Goal State')} --</option>
                          <option value="complete">{__('Complete')}</option>
                          <option value="incomplete">{__('Incomplete')}</option>
                        </select>
                      </div>
                    </div>
                    <div className="body">
                      {details?.course.students.map((student) => (
                        <div className="student list-item" key={student.id}>
                          <Link to={getProfileRoute(student.id) + AppRoute.Goals}>
                            <img
                              className="circle-30 image"
                              src={student.image?.url ? student.image.url + '100/100' : Config.defaultUserImage}
                              alt={''}
                            />
                          </Link>
                          <span className="name">{student.name}</span>

                          <div className="buttons btn-group">
                            <AccessControlled feature={AppFeature.ManageCourse}>
                              <Tooltip title={__('Delete')}>
                                <a
                                  className="btn btn-icon btn-mini"
                                  onClick={() => confirmDelete(async () => {
                                    await coursesService.removeCourseStudent(props.course.id, student.id);
                                    await loadDetails();
                                  }, __('Delete'), __('Are you sure you want to remove the student from the course?'))}
                                  aria-label="Remove student from course"
                                >
                                  <i className="icon-trash" />
                                </a>
                              </Tooltip>
                            </AccessControlled>
                          </div>

                          <div className="student-goals">
                            {student.goals.map((goal) => (
                              <GoalThumbnail
                                key={goal.id}
                                goal={goal}
                                onOpenRequested={() => setOpenGoal(goal)}
                                onDeleted={() => setDetails((current) => {
                                  if (!current) {
                                    return undefined;
                                  } else {
                                    const newStudents = [...current.course.students];
                                    newStudents.forEach((s) => {
                                      if (s.id === student.id) {
                                        s.goals = s.goals.filter((g) => g.id !== goal.id);
                                      }
                                    });

                                    return {
                                      ...current,
                                      course: {
                                        ...current.course,
                                        students: newStudents,
                                      },
                                    };
                                  }
                                })}
                              />
                            ))}
                          </div>
                          <div className="student-achievements">
                            {student.achievements.map((achievement) => (
                              <AchievementThumbnail
                                achievement={achievement}
                                onOpenRequested={() => setOpenAchievement(achievement)}
                                key={achievement.id}
                                showProgress={true}
                              />
                            ))}
                          </div>

                          <div style={{clear: 'right'}} />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="frame">
                    <div className="title">
                      <span>{__('Course Communication')}</span>

                      <div className="buttons btn-group">
                        <Tooltip title={__('Add')}>
                          <a className="btn btn-success btn-mini"
                            onClick={() => setShowAddEmail(true)}
                            aria-label="Create Course Communication"
                          >
                            <i className="icon-plus-circled" />
                          </a>
                        </Tooltip>
                      </div>

                      <div className="clearfix" />
                    </div>
                    <div className="body">
                      {details?.course.emails.filter((e) => e !== null).map((email) => (
                        <EmailQuickView
                          email={email}
                          key={email.id}
                          onDelete={(email) => {
                            confirmDelete(async () => {
                              await emailsService.deleteEmail(course.id, email.id);
                              await loadDetails();
                            });
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </>
      }
    </div>
  );
};
