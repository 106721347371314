import {AxiosInstance} from 'axios';
import {CreateGoalDto, Goal, GoalWithRelations, GoalWithResources, UpdateGoalDto} from 'api/goals/Goal';
import {ListFilter} from 'api/lists/ListFilter';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {GoalTargetType} from 'api/goals/GoalTargetType';
import {FilterCourse} from 'api/goals/FilterCourse';
import {FilterCategory} from 'api/goals/FilterCategory';
import {CreateCommentDto} from 'api/goals/Comment';
import {FileUpload} from 'api/file-uploads/FileUpload';

/**
 * Provides API goals functions
 */
export class GoalsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches a user's goals from the API
   *
   * @param {string} userName - This is NOT the "username", but the user's actual
   * name
   */
  async fetchUserGoals(userName: string): Promise<Goal[]> {
    const response = await this.httpClient.post('/goals/personal', {
      filter: {
        user: userName,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a user's goal by ID
   *
   * @param {number} userId
   * @return {Promise<Goals[]>}
   */
  async fetchUserGoalsById(userId: number): Promise<Goal[]> {
    const response = await this.httpClient.post('/goals/personal', {user: userId});
    return response.data.list;
  }

  /**
   * Fetches goals for the current user
   */
  async fetchCurrentUserGoals(): Promise<Goal[]> {
    const response = await this.httpClient.post('/goals/personal');
    return response.data.list;
  }

  /**
   * Fetches user goals with their associated resources
   */
  async fetchGoalResources(): Promise<GoalWithResources[]> {
    const response = await this.httpClient.post('/goals/resources');
    return response.data;
  }

  /**
   * Fetches the current user's goals
   *
   * @param {ListFilter} filter
   * @return {Promise<GoalWithRelations>}
   */
  async fetchPersonalGoals(filter: ListFilter = {}): Promise<GoalWithRelations[]> {
    const consolidatedFilter: any = {search: filter.search};
    if (filter.filters) {
      for (const [key, value] of Object.entries(filter.filters)) {
        consolidatedFilter[key] = value;
      }
    }

    const response = await this.httpClient.post('/goals/personal', {
      filter: consolidatedFilter,
    });
    return response.data.list;
  }

  /**
   * Fetches a goal
   *
   * @param {number} id
   * @return {Promise<GoalWithRelations>}
   */
  async fetchGoal(id: number): Promise<GoalWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Goals + ApiRoute.Get, {id});
    return response.data;
  }

  /**
   * Deletes a goal
   *
   * @param {number} id
   * @return {Promise<SuccessResponse<undefined>>}
   */
  async deleteGoal(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.Goals + ApiRoute.Delete, {id});
    return response.data;
  }

  /**
   * Deactivates a goal by ID
   *
   * @param {number} id
   * @return {Promise<GoalWithRelations>}
   */
  async deActivateGoal(id: number): Promise<GoalWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Goals + '/deactivate', {id});
    return response.data;
  }

  /**
   * Activates a goal by ID
   *
   * @param {number} id
   * @return {Promise<GoalWithRelations>}
   */
  async activateGoal(id: number): Promise<GoalWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Goals + '/activate', {id});
    return response.data;
  }

  /**
   * Creates a new goal in the API
   *
   * @param {CreateGoalDto} goal
   * @return {Promise<Goal>}
   */
  async createGoal(goal: CreateGoalDto): Promise<SuccessResponse<Goal>> {
    goal.steps.forEach((step) => {
      if (step.id) {
        delete step.id;
      }
      step.resources.forEach((resource) => {
        if (resource.id) {
          delete resource.id;
        }
      });
    });

    try {
      const response = await this.httpClient.post('/goals/save', {
        ...goal,
        updateSeries: true,
      });
      return response.data;
    } catch (e) {
      return {ok: false};
    }
  }

  /**
   * Updates a goal in the API
   *
   * @param {UpdateGoalDto} goal
   * @return {Promise<Goal>}
   */
  async updateGoal(goal: UpdateGoalDto): Promise<SuccessResponse<Goal>> {
    const response = await this.httpClient.post('/goals/save', goal);
    return response.data;
  }

  /**
   * Copies a goal to another entity
   *
   * @param {number} goalId
   * @param {number} targetId
   * @param {GoalTargetType} targetType
   */
  async copyGoal(goalId: number, targetId: number, targetType: GoalTargetType): Promise<Goal> {
    const response = await this.httpClient.post('/goals/copy', {
      goal: {id: goalId},
      target: {id: targetId},
      type: targetType.toUpperCase(),
    });
    return response.data;
  }

  /**
   * Fetches the filterable courses and categories for the current user's goals
   */
  async fetchGoalsFilter(): Promise<{courses: FilterCourse[], categories: FilterCategory[]}> {
    const response = await this.httpClient.post('/categories/goalsFilter', {});
    return response.data;
  }

  /**
   * Assigns a currently unassigned goal
   *
   * @param {number} id
   */
  async assignGoal(id: number): Promise<Goal> {
    const response = await this.httpClient.post('/goals/assign', {id});
    return response.data;
  }

  /**
   * Adds a comment to an action step
   *
   * @param {CreateCommentDto} comment
   */
  async addActionStepComment(comment: CreateCommentDto): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/actionsteps/comment', comment);
    return response.data;
  }

  /**
   * Marks an action step without a resource as complete
   *
   * @param id
   * @param file
   */
  async markActionStepComplete(id: number, file?: FileUpload): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/actionsteps/update', {
      id,
      completed: true,
      file: file? [file] : [],
    });
    return response.data;
  }

  /**
   * Reopens a completed action step
   *
   * @param id
   */
  async reopenActionStep(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/actionsteps/update', {id, completed: false});
    return response.data;
  }
}
