import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {PageView} from 'layout/PageView';
import {DataGrid} from 'components/DataGrid';
import {FormattedDate} from 'components/FormattedDate';
import {__, localizeText} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {confirmDelete} from 'components/confirmDelete';
import {AchievementInputModal} from 'views/achievements/AchievementInputModal';
import {Achievement} from 'api/achievements/Achievement';
import {useApiContext} from 'api/ApiContext';
import {ButtonEdit} from 'components/buttons/ButtonEdit';
import {ButtonDelete} from 'components/buttons/ButtonDelete';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {Config} from 'Config';
import {AchievementDetailsModal} from 'views/achievements/AchievementDetailsModal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {TemplatesListModal} from 'views/templates/TemplatesListModal';


export const AchievementsListView = () => {
  const logger = useLogger(AchievementsListView.name);
  const {achievementsService} = useApiContext();

  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [shownAchievement, setShownAchievement] = useState<Achievement | undefined>(undefined);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [editAchievement, setEditAchievement] = useState<Achievement | undefined>(undefined);
  const [showTemplates, setShowTemplates] = useState<boolean>(false);

  const loadAchievements = async () => {
    const fetchedAchievements = await achievementsService.fetchAchievements(searchFilter);
    setAchievements(fetchedAchievements);
    logger.debug('Fetched achievements', fetchedAchievements);
  };

  useEffect(() => {
    (async () => {
      await loadAchievements();
    })();
  }, [searchFilter]);

  return (
    <PageView>
      {editAchievement &&
        <AchievementInputModal
          initialAchievement={editAchievement}
          onComplete={async () => {
            setEditAchievement(undefined);
            await loadAchievements();
          }}
          onCancel={() => setEditAchievement(undefined)}
        />
      }

      {shownAchievement &&
        <AchievementDetailsModal
          achievementId={shownAchievement.id}
          onCloseRequested={() => setShownAchievement(undefined)}
        />
      }

      {showTemplates &&
        <TemplatesListModal
          onCloseRequested={() => setShowTemplates(false)}
        />
      }

      <DataGrid
        header={
          <tr>
            <td width={60}/>
            <td>{localizeText('Name')}</td>
            <td>{localizeText('Validity')}</td>
            <td width={'30%'}>{localizeText('Category')}</td>
            <td>{localizeText('Date')}</td>
            <td width={35}/>
          </tr>
        }
        body={
          <>
            <ContextMenu>
              <SearchInput
                value={searchFilter}
                onChange={(newValue) => setSearchFilter(newValue)}
              />
              <Button
                variant={ButtonVariant.Add}
                onClick={() => setEditAchievement({} as Achievement)}
              />
              <Button
                className={'btn-info'}
                iconCode={'icon-award'}
                tooltip={__('Certificate Templates')}
                onClick={() => setShowTemplates(true)}
                variant={ButtonVariant.Cert}
              />
            </ContextMenu>

            {achievements.map((achievement) => (
              <tr key={achievement.id}>
                <td>
                  <img
                    src={achievement.image ?
                      achievement.image + '100/100' :
                      Config.defaultSchoolImage
                    }
                    className="circle-30 gray"
                    alt={''}
                  />
                </td>
                <td>
                  <a onClick={() => setShownAchievement(achievement)} aria-label={__('Show Achievement')}>
                    {achievement.name}
                  </a>
                </td>
                <td>
                  {achievement.validity ?
                    achievement.validity + ' days' :
                    ''
                  }
                </td>
                <td>
                  <div
                    className="label"
                    style={{
                      borderColor: achievement.category?.color,
                      boxShadow: `1px 1px 10px ${achievement.category?.color}`,
                      padding: '3px 5px',
                    }}
                  >
                    {achievement.category?.name}
                  </div>
                </td>
                <td>
                  <FormattedDate timestamp={
                    achievement.mdate ?? achievement.cdate
                  }/>
                </td>
                <td>
                  <AccessControlled canEdit={achievement}>
                    <div className="icons btn-group">
                      <ButtonEdit
                        onClick={() => setEditAchievement(achievement)}
                      />
                      <ButtonDelete
                        onClick={() => confirmDelete(async () => {
                          await achievementsService.deleteAchievement(achievement.id);
                          await loadAchievements();
                        })}
                      />
                    </div>
                  </AccessControlled>
                </td>
              </tr>
            ))}
          </>
        }
      />
    </PageView>
  );
};
