import React, {useEffect, useState} from 'react';
import {Select, SelectBaseProps} from 'components/forms/Select';
import {useApiContext} from 'api/ApiContext';
import {localizeText} from 'i18n/localize';
import {School} from 'api/schools/School';

export interface SchoolPickerProps extends SelectBaseProps {
  value?: number | number[],
  onChange?: (newValue: number | number[]) => any,
}

export const SchoolPicker = (props: SchoolPickerProps) => {
  const {schoolsService} = useApiContext();
  const [schools, setSchools] = useState<School[]>([]);

  useEffect(() => {
    (async () => {
      setSchools(await schoolsService.fetchSchools(''));
    })();
  }, []);

  return (
    <Select
      placeholder={props.placeholder ?? localizeText('Campus')}
      multiple={props.multiple ?? true}
      value={
        props.multiple ?
          (props.value as number[])?.map((value) => value.toString()) :
          (props.value as number)?.toString()
      }
      options={schools.map((school) => ({
        label: school.name,
        value: school.id.toString(),
      }))}
      onChange={(newValue) => {
        if (!props.multiple) {
          props.onChange?.(parseInt(newValue as string));
        } else {
          props.onChange?.(
              (newValue as string[]).map((value) => parseInt(value)),
          );
        }
      }}
    />
  );
};
