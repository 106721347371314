import React, {useEffect, useState} from 'react';
import {CreateVersionDto, VersionWithRelations} from 'api/versions/Version';
import {Modal} from 'components/Modal';
import {useApiContext} from 'api/ApiContext';
import {__, localizeText} from 'i18n/localize';
import {ColorPicker} from 'components/ColorPicker';
import {VersionPicker} from 'components/forms/VersionPicker';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';

export interface VersionInputModalProps {
  versionId?: number,
  parentId?: number,
  onCancel?: () => any,
  onComplete?: () => any,
}

export const VersionInputModal = (props: VersionInputModalProps) => {
  const logger = useLogger(VersionInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {versionsService} = useApiContext();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [initialVersion, setInitialVersion] = useState<VersionWithRelations | undefined>(undefined);
  const [newVersion, setNewVersion] = useState<CreateVersionDto>({
    name: '',
    color: '',
    parent: props.parentId ? {id: props.parentId} : undefined,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const isEditing = props.versionId !== undefined;

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (newVersion.name.trim().length < 1) {
      setErrors((current: any) => ({...current, name: true}));
      isValid = false;
    }
    if (newVersion.color.length < 1) {
      setErrors((current: any) => ({...current, color: true}));
      isValid = false;
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSaving(true);
    if (isEditing && props.versionId) {
      await versionsService.updateVersion({
        ...newVersion,
        id: props.versionId,
      });
    } else {
      await versionsService.createVersion(newVersion);
    }
    props.onComplete?.();
    setIsSaving(false);
  };

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (props.versionId) {
        const fetchedVersion = await versionsService.fetchVersion(props.versionId);
        setInitialVersion(fetchedVersion);
        logger.debug('Fetched version', fetchedVersion);
      }
      setIsFetching(false);
    })();
  }, [props.versionId]);

  useEffect(() => {
    if (initialVersion) {
      setNewVersion(initialVersion);
    }
  }, [initialVersion]);

  useEffect(() => {
    validate();
  }, [newVersion]);

  if (isFetching) {
    return <></>;
  }

  return (
    <Modal
      title={isEditing ? 'Edit Version' : 'Create Version'}
      className={'tall wide'}
      onCloseRequested={props.onCancel}
      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
          disabled={isSaving}
        />
      }
      body={
        <form name="form.edit" onSubmit={(e) => e.preventDefault()}>
          <div className="row-fluid">
            <input
              value={newVersion.name}
              onChange={(e) => setNewVersion((current) => ({
                ...current,
                name: e.target.value,
              }))}
              type="text"
              className={classNames('span12', {'invalid': errors.name})}
              required
              placeholder={localizeText('Version Name')}
            />
          </div>
          <VersionPicker
            multiple={false}
            placeholder={'Parent Version'}
            value={newVersion.parent?.id}
            onChange={(newValue) => setNewVersion((current) => ({
              ...current,
              parent: {id: newValue as number},
            }))}
          />
          <div className="row-fluid">
            <div style={{marginLeft: 10}} className={'pull-left'}>
              <ColorPicker
                isInvalid={errors.color}
                color={newVersion.color}
                onChange={(newColor) => setNewVersion((current) => ({
                  ...current,
                  color: newColor,
                }))}
              />
            </div>
          </div>
        </form>
      }

      footer={<InputPlaceholderMirror/>}
    />
  );
};
