import React, {useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {useLogger} from 'logging/logging';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {School} from 'api/schools/School';
import {SchoolSelectModal} from 'components/SchoolSelectModal';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmDelete} from 'components/confirmDelete';
import {CategoryInputModal} from 'views/categories/CategoryInputModal';
import {Category} from 'api/categories/Category';
import {SearchInput} from 'components/SearchInput';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {Tooltip} from 'components/Tooltip';
import {__} from 'i18n/localize';

export const CategoriesListView = () => {
  const logger = useLogger(CategoriesListView.name);
  const {categoriesService} = useApiContext();

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [categories, setCategories] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [editCategory, setEditCategory] = useState<Category | undefined>(undefined);
  const [createCategoryParent, setCreateCategoryParent] = useState<Category | undefined>(undefined);
  const [changingOwnershipCategory, setChangingOwnershipCategory] = useState<School | undefined>(undefined);

  const fetchCategories = async () => {
    setIsFetching(true);
    try {
      const fetchedCategories = await categoriesService.fetchCategories({search: searchFilter});
      logger.debug('Fetched categories', fetchedCategories);
      setCategories(fetchedCategories);
    } catch (e) {
      logger.error('Error fetching categories', e);
    }
    setIsFetching(false);
  };

  const resetCategories = async () => {
    setIsFetching(true);
    await fetchCategories();
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      await fetchCategories();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCategories();
    })();
  }, [searchFilter]);

  return (
    <PageView>
      {editCategory &&
        <CategoryInputModal
          categoryId={editCategory.id}
          onCloseRequested={() => setEditCategory(undefined)}
          onCompleted={async () => {
            setEditCategory(undefined);
            await resetCategories();
          }}
        />
      }

      {createCategoryParent &&
        <CategoryInputModal
          initialParentId={createCategoryParent.id}
          onCloseRequested={() => setCreateCategoryParent(undefined)}
          onCompleted={async () => {
            setCreateCategoryParent(undefined);
            await resetCategories();
          }}
        />
      }

      {changingOwnershipCategory &&
        <SchoolSelectModal
          onCancel={() => setChangingOwnershipCategory(undefined)}
          onSelected={async (school) => {
            await categoriesService.changeCategorySchool(
                changingOwnershipCategory.id,
                school?.id,
            );
            await fetchCategories();
            setChangingOwnershipCategory(undefined);
          }}
        />
      }

      <ContextMenu>
        <SearchInput
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
        />

        <ButtonAdd
          onClick={() => setEditCategory({} as Category)}
        />
      </ContextMenu>

      <div className={'grid'}>
        <table className={'list'}>
          <thead>
            <tr>
              <td>Name</td>
              <td width={35}/>
            </tr>
          </thead>
          <tbody>
            {!isFetching &&
                categories.map((category) => (
                  <tr key={category.id} aria-label="Category">
                    <td>
                      <div
                        className={'cell'}
                        style={{
                          width: 40 * (category.level - 1),
                          padding: 0,
                        }}
                      />
                      <div className="cell">
                        <div
                          className="circle-30"
                          style={{borderColor: category.color}}
                        >
                          <span className={`icon ${category.icon}`} />
                        </div>
                      </div>
                      <div className="cell">
                        {category.name}
                      </div>
                    </td>
                    <td>
                      <AccessControlled
                        canEdit={category}
                      >
                        <ButtonGroup className={'icons'}>
                          {category.level === 1 &&
                          <AccessControlled
                            feature={AppFeature.ChangeOwnership}
                          >
                            <Tooltip title={__('Change Ownership')}>
                              <button
                                type={'button'}
                                className="btn btn-icon btn-mini"
                                onClick={() => {
                                  setChangingOwnershipCategory(category);
                                }}
                                aria-label={__('Change Ownership')}
                              >
                                <i className="icon-link-ext" />
                              </button>
                            </Tooltip>
                          </AccessControlled>
                          }

                          <Tooltip title={__('Edit')}>
                            <button
                              type={'button'}
                              className="btn btn-icon btn-mini"
                              onClick={() => setEditCategory(category)}
                              aria-label={__('Edit')}
                            >
                              <i className="icon-edit" />
                            </button>
                          </Tooltip>

                          <Tooltip title={__('Add Child')}>
                            <button
                              type={'button'}
                              className="btn btn-icon btn-mini"
                              onClick={() => setCreateCategoryParent(category)}
                              aria-label={__('Add Child')}
                            >
                              <i className="icon-plus-circled" />
                            </button>
                          </Tooltip>

                          <Tooltip title={__('Delete')}>
                            <button
                              type={'button'}
                              className="btn btn-icon btn-mini"
                              aria-label={__('Delete')}
                              onClick={() => {
                                confirmDelete(async () => {
                                  await categoriesService.deleteCategory(category.id);
                                  await fetchCategories();
                                });
                              }}
                            >
                              <i className="icon-trash" />
                            </button>
                          </Tooltip>
                        </ButtonGroup>
                      </AccessControlled>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>
    </PageView>
  );
};
