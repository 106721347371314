import React, {useState} from 'react';
import {SaveActionStepDto} from 'api/goals/ActionStep';
import classNames from 'classnames';
import {Tooltip} from 'components/Tooltip';
import {ResourceIcon} from 'views/resources/ResourceIcon';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {SwitchInput} from 'components/SwitchInput';
import {ActionStepInputModal} from 'views/action-steps/ActionStepInputModal';
import {ActionStepType} from 'api/goals/ActionStepType.enum';
import {confirmDelete} from 'components/confirmDelete';
import {SaveResourceDto} from 'api/resources/Resource';
import {ResourceView} from 'views/resources/ResourceView';
import {Skill} from 'api/skills/Skill';
import {SkillDetailsModal} from 'views/skills/SkillDetailsModal';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

export interface SortableActionStepListProps {
  actionSteps: SaveActionStepDto[],
  actionStepType?: ActionStepType,
  onChange?: (newActionSteps: SaveActionStepDto[]) => any,
}

export const SortableActionStepList = (props: SortableActionStepListProps) => {
  const [showAddStep, setShowAddStep] = useState<boolean>(false);
  const [editStepIndex, setEditStepIndex] = useState<number | undefined>(undefined);
  const [viewedResource, setViewedResource] = useState<SaveResourceDto | undefined>(undefined);
  const [viewedSkill, setViewedSkill] = useState<Skill | undefined>(undefined);

  const getListStyle = (isDraggingOver: boolean) => ({
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.14), rgba(255,255,255,0.53));',
    background: isDraggingOver ?
      '#8dc73f' :
      'none',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    zIndex: 99999,
    ...draggableStyle,
  });

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(props.actionSteps);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }
    props.onChange?.(reorder(dropResult.source.index, dropResult.destination.index));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'list'}>
        {(provided, snapshot) => (
          <div
            className="list draggable stepList lesson-view"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {showAddStep &&
              <ActionStepInputModal
                type={props.actionStepType ?? ActionStepType.Lesson}
                onCloseRequested={() => setShowAddStep(false)}
                onComplete={(newActionStep) => {
                  props.onChange?.(props.actionSteps.concat([newActionStep]));
                  setShowAddStep(false);
                }}
              />
            }

            {editStepIndex !== undefined &&
              <ActionStepInputModal
                type={props.actionSteps[editStepIndex].type ?? ActionStepType.Lesson}
                initialStep={props.actionSteps[editStepIndex]}
                onCloseRequested={() => setEditStepIndex(undefined)}
                onComplete={(newActionStep) => {
                  const newSteps = [...props.actionSteps];
                  newSteps[editStepIndex] = newActionStep;
                  props.onChange?.(newSteps);
                  setEditStepIndex(undefined);
                }}
              />
            }

            {viewedResource &&
              <ResourceView resource={viewedResource} onCloseRequested={() => setViewedResource(undefined)} />
            }

            {viewedSkill &&
              <SkillDetailsModal skill={viewedSkill} onCloseRequested={() => setViewedSkill(undefined)}/>
            }

            <a
              className="btn btn-small btn-success"
              onClick={() => setShowAddStep(true)}
              style={{marginBottom: 10}}
              aria-label="Add Step"
            >
              <i className="icon-plus-circled" /> Add Step
            </a>

            {props.actionSteps.filter((s) => !s.deleted).map((actionStep, i) => (
              <Draggable
                key={actionStep.title}
                draggableId={actionStep.title}
                index={i}
              >
                {(provided, snapshot) => (
                  <div
                    key={i}
                    className={classNames('row-fluid steps', {'odd': i % 2 === 1}, {'inactive': !actionStep.enabled})}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                    )}
                  >
                    <div
                      className="sort-handle"
                      style={{padding: 5, float: 'left'}}
                    >
                      <i className="icon-resize-vertical" />
                    </div>
                    <div className="span1 text-center">
                      <SwitchInput
                        label="Actionstep Active"
                        on={actionStep.enabled}
                        onChange={(nowChecked) => {
                          const newSteps = [...props.actionSteps];
                          newSteps[i].enabled = nowChecked;
                          props.onChange?.(newSteps);
                        }}
                      />
                    </div>
                    <div className="span5" aria-label="Actionstep Title">{actionStep.title}</div>
                    <div className="span2" aria-label="Resources">
                      {actionStep.resources.map((resource, i) => (
                        <Tooltip title={resource.name} key={i}>
                          <div
                            className="pill pointer"
                            aria-label={resource.name}
                            onClick={() => setViewedResource(resource)}
                          >
                            <ResourceIcon resource={resource} showLabel={true} />
                          </div>
                        </Tooltip>
                      ))}

                    </div>
                    <div className="span2">
                      {actionStep.ilries.map((skill) => (
                        <Tooltip title={skill.name} key={skill.id}>
                          <div
                            className="pill ng-scope ng-binding"
                            style={{cursor: 'pointer', backgroundColor: skill.category?.color}}
                            onClick={() => setViewedSkill(skill)}
                            aria-label={skill.name}
                          >
                            <i className="icon-plus" /> Skill Earned
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                    <div className="span1 buttons">
                      <div className=" btn-group">
                        <Button
                          variant={ButtonVariant.Edit}
                          className={'btn-mini'}
                          onClick={() => setEditStepIndex(i)}
                        />
                        <Button
                          variant={ButtonVariant.Delete}
                          className={'btn-mini'}
                          onClick={() => confirmDelete(() => {
                            const newSteps = [...props.actionSteps];
                            newSteps.splice(i, 1);
                            props.onChange?.(newSteps);
                          })}
                        />
                      </div>
                    </div>

                    {actionStep.timeCritical &&
                    <div className="span1" style={{fontSize: 10, lineHeight: 16}}>
                      Due on <br />{actionStep.due}
                    </div>
                    }
                  </div>
                )}</Draggable>
            ))}

            <div className="clearfix" />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
