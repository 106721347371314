import React from 'react';
import {ResourceBase} from 'api/resources/Resource';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';

export interface ImageResourceViewProps {
  resource?: ResourceBase,
  url?: string,
  onCloseRequested?: () => any,
}

export const ImageResourceView = (props: ImageResourceViewProps) => {
  console.log(props);
  return <Modal
    title={__('Picture')}
    onCloseRequested={props.onCloseRequested}
    bodyClassName={'text-center'}
    body={
      <img src={(props.resource?.resource.url ?? props.url) + '/500/1000/1'} alt={''} />
    }
    footer={<></>}
  />;
};
