import React from 'react';

export const GoalsEmptyView = () => {
  return (
    <div
      className="pack"
      style={{textAlign: 'center', cursor: 'pointer'}}
    >
      <div
        className="goal"
        style={{
          height: 220,
          width: '100%',
          position: 'relative',
          marginRight: 10,
          borderRadius: '4px 20px',
          margin: 10,
          boxShadow: 'inset 0 2px 6px rgba(0,0,0,0.6)',
          background: 'url(/images/try_again.jpg) no-repeat top right',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />

      <div style={{padding: '10px 0', fontFamily: 'triunity condensed', color: '#4B4B4B'}}>
        <h4>We&rsquo;re sorry, we can&rsquo;t find any goals matching your search.</h4>
        <p>Try adjusting your category and course selection, or click here to go to your plan page.</p>
      </div>
    </div>
  );
};
