import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useSchoolContext} from 'contexts/SchoolContext';
import {Config} from 'Config';
import {MainMenu} from 'layout/menus/MainMenu';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
import {UserAvatar} from 'components/UserAvatar';
import {useThemeContext} from 'contexts/ThemeContext';
import {TopMenu} from 'layout/menus/TopMenu';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';

/**
 * The main header of the app
 */
export interface MainHeaderProps {
  pageTitle: string,
  pageIconCode: string,
  menu?: ReactElement,
}

export const MainHeader = (props: MainHeaderProps) => {
  const {selectedSchool} = useSchoolContext();
  const {activeUser, userIsSwitched} = useUserSwitchingContext();
  const {layoutColor} = useThemeContext();
  const {pathname} = useLocation();

  const navigationButtonRef = useRef<HTMLButtonElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);
  const [isMenuShown, setIsMenuShown] = useState<boolean>(false);

  const schoolName = selectedSchool?.name ?? Config.appName;

  const closeOnClick = useCallback((e) => {
    if (
      !navigationRef?.current?.contains(e.target) &&
      !navigationButtonRef?.current?.contains(e.target)
    ) {
      setIsMenuShown(false);
    }
  }, []);

  const closeOnEscape = useCallback((e) => {
    if (e.key === 'Escape') {
      setIsMenuShown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    document.addEventListener('keyup', closeOnEscape);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
      document.removeEventListener('keyup', closeOnEscape);
    };
  }, [closeOnClick, closeOnEscape]);

  useEffect(() => {
    setIsMenuShown(false);
  }, [pathname]);

  return (
    <>
      <header id={'header'} style={{backgroundColor: layoutColor}}>
        <div className="nav" aria-label="current page" >
          <div className="circle-20" style={{float: 'left'}}>
            <i className={`icon ${props.pageIconCode ?? ''}`} />
          </div>

          <div className="name">{props.pageTitle}</div>

          <div className="clear"/>
        </div>

        <div className="school" aria-label="Current Orginization">
          <div className="logo">
            <img
              alt={''}
              src={selectedSchool?.image?.url ?? Config.appLogoUrl}
            />
          </div>
          <div className="name">{schoolName}</div>

          <div className="clear"/>
        </div>

        {userIsSwitched && activeUser &&
          <div className="profile clearfix">
            <div className="image">
              <img
                src={activeUser.image ?
                  activeUser.image.url + '50/50' :
                  Config.defaultUserImage
                }
                className="circle-50 gray"
                alt={''}
              />
            </div>
            <div className="name">{activeUser.name}</div>
          </div>
        }

        <div className="user">
          <div className="image">
            <div className="image-bg"/>
            <button
              aria-label="Profile Menu"
              style={{outline: 'none', border: 'none', background: 'none'}}
              ref={navigationButtonRef}
              onClick={() => setIsMenuShown((current) => !current)}
            >
              <UserAvatar
                size={41}
                borderWidth={0}
                style={{top: 3, left: 3}}
                boxShadow={'none'}
              />
            </button>
          </div>
        </div>
      </header>

      <div
        id="navigation"
        ref={navigationRef}
        className={classNames(
            'animate',
            {'opened': isMenuShown},
            {'closed': !isMenuShown},
        )}
      >
        {props.menu ?? <MainMenu/>}
      </div>

      <TopMenu/>
    </>
  );
};
