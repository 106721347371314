import React, {ChangeEvent, useEffect, useState} from 'react';
import {__} from 'i18n/localize';
import classNames from 'classnames';

/**
 * A text input for search
 *
 * This input also delays user input change events briefly, so that a search is
 * not triggered with each character, but only after typing stops.
 */

export interface SearchInputProps {
  value?: string,
  onChange?: (newValue: string) => any,
  changeDelay?: number,
  role?: string,
  iconCode?: string,
  hideIcon?: boolean,
  placeholder?: string,
}

export const SearchInput = (props: SearchInputProps) => {
  const [value, setValue] = useState<string>(props.value ?? '');
  const [changeTimeoutHandle, setChangeTimeoutHandle] = useState<number | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (changeTimeoutHandle) {
      clearTimeout(changeTimeoutHandle);
    }

    const handle = window.setTimeout(
        () => {
          setChangeTimeoutHandle(null);
          props.onChange?.(value);
        },
        props.changeDelay ?? 400,
    );

    setChangeTimeoutHandle(handle);
  }, [value]);

  useEffect(() => {
    return () => {
      if (changeTimeoutHandle) {
        clearTimeout(changeTimeoutHandle);
        setChangeTimeoutHandle(null);
      }
    };
  }, []);

  return (
    <>
      {!props.hideIcon && <i className={classNames('icon', props.iconCode ?? 'icon-search')} />}
      <label htmlFor={'search-input'} style={{display: 'none'}}>
        {props.placeholder ?? __('Search')}
      </label>
      <input
        id={'search-input'}
        aria-label={props.placeholder ?? __('Search')}
        className="form-control"
        type="text"
        placeholder={props.placeholder ?? __('Search')}
        value={value}
        onChange={handleChange}
        role={props.role ?? 'search'}
      />
    </>
  );
};
