import {AxiosInstance} from 'axios';
import {SaveAssessmentDto} from 'api/assessments/SaveAssessmentDto';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {Assessment, AssessmentWithRelations} from 'api/assessments/Assessment';
import {CategoryWithParent} from 'api/categories/Category';
import {SkillAssessment} from 'api/assessments/SkillAssessment';

/**
 * Provides API assessments functions
 */
export class AssessmentsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Saves an assessment
   *
   * @param {SaveAssessmentDto} assessment
   */
  async saveAssessment(assessment: SaveAssessmentDto): Promise<SuccessResponse<Assessment>> {
    const response = await this.httpClient.post('/assessments/save', assessment);
    return response.data;
  }

  /**
   * Fetches an assessment
   *
   * @param {number} id
   */
  async fetchAssessment(id: number): Promise<AssessmentWithRelations> {
    const response = await this.httpClient.post('/assessments/get', {id});
    return response.data;
  }

  /**
   * Groups skill assessments into categories - used in skills inventory for example
   *
   * @param {SkillAssessment[]} skillAssessments
   */
  groupSkillAssessmentsByCategory(
      skillAssessments: SkillAssessment[],
  ): {category: CategoryWithParent, skillAssessments: SkillAssessment[]}[] {
    const getTopLevelAncestorCategory = (category: CategoryWithParent) => {
      let currentCategory = category;
      while (currentCategory.parent) {
        currentCategory = currentCategory.parent;
      }
      return currentCategory;
    };

    const allCategories: CategoryWithParent[] = [];
    skillAssessments.forEach((skill) => {
      if (skill.category && !allCategories.find((category) => category.id === skill.category?.id)) {
        allCategories.push(skill.category);
      }
    });
    const topLevelCategories: CategoryWithParent[] = [];
    allCategories.forEach((category) => {
      const topLevelAncestor = getTopLevelAncestorCategory(category);
      const categoryAlreadyAdded = topLevelCategories.find((category) => category.id === topLevelAncestor.id);
      if (!categoryAlreadyAdded) {
        topLevelCategories.push(topLevelAncestor);
      }
    });

    return allCategories.map((category) => ({
      category,
      skillAssessments: skillAssessments.filter((skill) => skill.category?.id === category.id),
    }));
  }
}
