import React, {useRef} from 'react';
import {Config} from 'Config';
import {useAuthContext} from 'contexts/AuthContext';
import {User} from 'api/users/User';

export interface UserAvatarProps {
  src?: string,
  user?: User,
  size?: number,
  style?: React.CSSProperties,
  borderColor?: string,
  borderWidth?: number,
  boxShadow?: string,
  className?: string,
}

export const UserAvatar = (props: UserAvatarProps) => {
  const {currentUser} = useAuthContext();
  const imageRef = useRef<HTMLImageElement>(null);

  let imageSrc = props.src;
  if (!imageSrc) {
    imageSrc = props.user?.image?.url;
  }
  if (!props.user && !imageSrc) {
    imageSrc = currentUser?.image;
  }

  return (
    <img
      ref={imageRef}
      alt={''}
      className={props.className ?? ''}

      src={imageSrc ?? Config.defaultUserImage}

      style={{
        borderRadius: '50%',
        border: '5px solid',
        borderColor: props.borderColor ?? '#c2c2c2',
        borderWidth: props.borderWidth ?? 5,
        width: props.size ? props.size - 5 : undefined,
        height: props.size ? props.size - 5 : undefined,
        boxShadow: props.boxShadow ?? '2px 2px 5px #525252',
        ...props.style,
      }}

      onError={() => {
        // fallback to default user image if original image fails
        if (imageRef.current) {
          imageRef.current.src = Config.defaultUserImage;
        }
      }}
    />
  );
};
