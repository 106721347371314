import React, {FormEvent, useEffect, useState} from 'react';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {CollapsibleSection} from 'components/CollapsibleSection';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {VersionPicker} from 'components/forms/VersionPicker';
import {Lesson, LessonWithRelations} from 'api/lessons/Lesson';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {AccordionView} from 'components/AccordionView';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {Tooltip} from 'components/Tooltip';
import {SaveLessonWordDto} from 'api/lessons/LessonWord';
import {LessonWordInputModal} from 'views/lessons/LessonWordInputModal';
import {confirmDelete} from 'components/confirmDelete';
import {SaveLessonPlanDto} from 'api/lessons/LessonPlan';
import {LessonPlanInputModal} from 'views/lessons/LessonPlanInputModal';
import {LessonPlanEntryInputModal} from 'views/lessons/LessonPlanEntryInputModal';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {ResourceIcon} from 'views/resources/ResourceIcon';
import {Resource} from 'api/resources/Resource';
import {ResourceView} from 'views/resources/ResourceView';
import {FileView} from 'views/resources/FileView';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {ActionStepInputModal} from 'views/action-steps/ActionStepInputModal';
import {SaveActionStepDto} from 'api/goals/ActionStep';
import {ActionStepType} from 'api/goals/ActionStepType.enum';
import {SortableActionStepList} from 'views/action-steps/SortableActionStepList';
import {Category} from 'api/categories/Category';

export interface LessonInputViewProps {
  lessonId?: number,
  onCancel?: () => any,
  onCompleted?: (lesson?: Lesson) => any,
}

export const LessonInputView = (props: LessonInputViewProps) => {
  const logger = useLogger(LessonInputView.name);
  const {showErrorNotification} = useNotificationContext();
  const {lessonsService} = useApiContext();

  const [initialLesson, setInitialLesson] = useState<LessonWithRelations | undefined>(undefined);
  const [isFetchingInitial, setIsFetchingInitial] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [versionId, setVersionId] = useState<number | undefined>(undefined);
  const [keywords, setKeywords] = useState<string>('');
  const [editorNotes, setEditorNotes] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [materials, setMaterials] = useState<string>('');
  const [preparation, setPreparation] = useState<string>('');
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [lessonPlans, setLessonPlans] = useState<SaveLessonPlanDto[]>([]);
  const [wordsToKnow, setWordsToKnow] = useState<SaveLessonWordDto[]>([]);
  const [actionSteps, setActionSteps] = useState<SaveActionStepDto[]>([]);

  const [editLessonPlanIndex, setEditLessonPlanIndex] = useState<number | undefined>(undefined);
  const [addingLessonPlan, setAddingLessonPlan] = useState<boolean>(false);
  const [addingEntryPlanIndex, setAddingEntryPlanIndex] = useState<number | undefined>(undefined);
  const [editEntry, setEditEntry] = useState<{planIndex: number, entryIndex: number} | undefined>(undefined);
  const [editWordIndex, setEditWordIndex] = useState<number | undefined>(undefined);
  const [addingWord, setAddingWord] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [viewedResource, setViewedResource] = useState<Resource | undefined>(undefined);
  const [viewedFile, setViewedFile] = useState<FileUpload | undefined>(undefined);
  const [showAddActionStep, setShowAddActionStep] = useState<boolean>(false);

  const loadInitialLesson = async () => {
    if (!props.lessonId) {
      return;
    }

    setIsFetchingInitial(true);
    const fetchedLesson = await lessonsService.fetchLesson(props.lessonId);
    setInitialLesson(fetchedLesson);
    logger.debug('Fetched lesson', fetchedLesson);
    setIsFetchingInitial(false);
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSubmitting(true);
    try {
      if (props.lessonId) {
        const result = await lessonsService.updateLesson({
          id: props.lessonId,
          name,
          text: description,
          category,
          version: {id: versionId!},
          editorNotes,
          keywords,
          materials,
          preparation,
          files,
          plans: lessonPlans,
          words: wordsToKnow,
          steps: actionSteps,
        });
        logger.debug('Updated lesson', result);
        props.onCompleted?.(result.entity);
      } else {
        const result = await lessonsService.createLesson({
          id: undefined,
          name,
          text: description,
          category,
          version: {id: versionId!},
          editorNotes,
          keywords,
          materials,
          preparation,
          files,
          plans: lessonPlans,
          words: wordsToKnow,
          steps: actionSteps,
        });
        logger.debug('Created lesson', result);
        props.onCompleted?.(result.entity);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (!category) {
      isValid = false;
      setErrors((current: any) => ({...current, category: true}));
    }
    if (!versionId) {
      isValid = false;
      setErrors((current: any) => ({...current, version: true}));
    }
    if (description.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, description: true}));
    }

    return isValid;
  };

  const deleteLessonPlan = (index: number) => {
    confirmDelete(
        () => {
          setLessonPlans((current) => {
            const copy = [...current];
            copy.splice(index, 1);
            return copy;
          });
        },
        __('Delete Plan'),
        __('You are about to delete plan from the lesson. Are you sure?'),
    );
  };

  const deletePlanEntry = (index: {planIndex: number, entryIndex: number}) => {
    confirmDelete(
        () => {
          setLessonPlans((current) => {
            const plansCopy = [...current];
            const entriesCopy = plansCopy[index.planIndex].entries;
            entriesCopy.splice(index.entryIndex, 1);
            plansCopy[index.planIndex].entries = entriesCopy;
            return plansCopy;
          });
        },
        __('Delete Plan Entry'),
        __('You are about to delete entry from the plan. Are you sure?'),
    );
  };

  const deleteWordToKnow = (index: number) => {
    confirmDelete(
        () => {
          setWordsToKnow((current) => {
            const copy = [...current];
            copy.splice(index, 1);
            return copy;
          });
        },
        __('Delete Word'),
        __('You are about to delete a word from the lesson. Are you sure?'),
    );
  };

  useEffect(() => {
    (async () => {
      if (props.lessonId) {
        await loadInitialLesson();
      }
    })();
  }, [props.lessonId]);

  useEffect(() => {
    if (initialLesson?.steps) {
      setActionSteps(initialLesson.steps);
    }
  }, [initialLesson]);

  useEffect(() => {
    setName(initialLesson?.name ?? '');
    setDescription(initialLesson?.text ?? '');
    setCategory(initialLesson?.category);
    setVersionId(initialLesson?.version?.id);
    setKeywords(initialLesson?.keywords ?? '');
    setPreparation(initialLesson?.preparation ?? '');
    setMaterials(initialLesson?.materials ?? '');
    setErrors(initialLesson?.editorNotes ?? '');
    setLessonPlans(initialLesson?.plans ?? []);
    setFiles(initialLesson?.files ?? []);
    setWordsToKnow(initialLesson?.words ?? []);
  }, [initialLesson]);

  useEffect(() => {
    validate();
  }, [name, description, category, versionId]);

  if (isFetchingInitial) {
    return <LoadingIndicator/>;
  }

  return <>
    {isSubmitting && <LoadingIndicator fullscreen={true} />}

    <div className="lesson-editor" style={{overflowY: 'auto', visibility: isFetchingInitial ? 'hidden' : 'visible'}}>
      <form
        id={'lessonForm'}
        name="form.edit"
        className="container"
        onSubmit={submit}
      >
        {showAddActionStep &&
          <ActionStepInputModal
            type={ActionStepType.Lesson}
            onCloseRequested={() => setShowAddActionStep(false)}
            onComplete={(actionStep) => setActionSteps((current) => {
              setShowAddActionStep(false);
              return current.concat([actionStep]);
            })}
          />
        }

        {(addingLessonPlan || editLessonPlanIndex !== undefined) &&
          <LessonPlanInputModal
            initialLessonPlan={editLessonPlanIndex !== undefined ? lessonPlans[editLessonPlanIndex] : undefined}
            onCancel={() => {
              setAddingLessonPlan(false);
              setEditLessonPlanIndex(undefined);
            }}
            onComplete={(plan, addEntries) => {
              if (addingLessonPlan) {
                setLessonPlans((current) => current.concat([plan]));
              } else if (editLessonPlanIndex !== undefined) {
                setLessonPlans((current) => {
                  const copy = [...current];
                  copy[editLessonPlanIndex] = plan;
                  return copy;
                });
              }
              setEditLessonPlanIndex(undefined);
              setAddingLessonPlan(false);
              if (addEntries) {
                setAddingEntryPlanIndex(editLessonPlanIndex ?? lessonPlans.length);
              }
            }}
          />
        }

        {(addingEntryPlanIndex !== undefined) &&
          <LessonPlanEntryInputModal
            onCloseRequested={() => setAddingEntryPlanIndex(undefined)}
            onComplete={(plan, addMore) => {
              setLessonPlans((current) => {
                const copy = [...current];
                copy[addingEntryPlanIndex].entries.push(plan);
                return copy;
              });

              const savedIndex = addingEntryPlanIndex;
              setAddingEntryPlanIndex(undefined);
              if (addMore) {
                setTimeout(
                    () => setAddingEntryPlanIndex(savedIndex),
                    100,
                );
              }
            }}
          />
        }

        {editEntry &&
          <LessonPlanEntryInputModal
            initialEntry={lessonPlans[editEntry.planIndex].entries[editEntry.entryIndex]}
            onCloseRequested={() => setEditEntry(undefined)}
            onComplete={(plan) => {
              setLessonPlans((current) => {
                const copy = [...current];
                copy[editEntry.planIndex].entries[editEntry.entryIndex] = plan;
                return copy;
              });
              setEditEntry(undefined);
            }}
          />
        }

        {(addingWord || editWordIndex !== undefined) &&
          <LessonWordInputModal
            initialWord={editWordIndex !== undefined ? wordsToKnow[editWordIndex] : undefined}
            onCloseRequested={() => {
              setEditWordIndex(undefined);
              setAddingWord(false);
            }}
            onComplete={(word) => {
              if (addingWord) {
                setWordsToKnow((current) => current.concat([word]));
              } else if (editWordIndex !== undefined) {
                setWordsToKnow((current) => {
                  const copy = [...current];
                  copy[editWordIndex] = word;
                  return copy;
                });
              }
              setEditWordIndex(undefined);
              setAddingWord(false);
            }}
          />
        }

        {viewedResource &&
          <ResourceView resource={viewedResource} onCloseRequested={() => setViewedResource(undefined)} />
        }

        {viewedFile &&
          <FileView name={viewedFile.name} url={viewedFile.url} onCloseRequested={() => setViewedFile(undefined)} />
        }

        <div className="buttons btn-group">
          <Tooltip title={__('Cancel')}>
            <button className="btn btn btn-default"
              onClick={props.onCancel}
              aria-label="cancel"
            >
              <i className="icon-cancel"/>
            </button>
          </Tooltip>
        </div>

        <div style={{position: 'fixed', bottom: 50, right: 30, zIndex: 999}}>
          <Tooltip title={__('Save')}>
            <button
              type={'submit'}
              className={classNames('btn btn-success', {'disabled': isSubmitting})}
              disabled={isSubmitting}
              aria-label="save"
            >
              <i className="icon-floppy"/>
            </button>
          </Tooltip>
        </div>

        <div className="row-fluid">
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className={classNames('span12 headline', {'invalid': errors.name})}
            aria-label={__('Lesson Name')}
          />
          <div className="info">{__('Lesson Name')}</div>
        </div>

        <div className="row-fluid no-space">
          <div className="span4 spacer">
            <div className="heading text-center">{__('Skills')}</div>
            {initialLesson?.ilries.map((skill) => (
              <div key={skill.id} className="pill" style={{borderColor: skill.category?.color}}>
                <div className="circle-20" style={{float: 'right', background: skill.category?.color}}>
                  <span className={classNames('icon', skill.category?.icon)} />
                </div>
                {skill.name}
              </div>
            ))}
          </div>

          <div className="span4 spacer">
            <div className="heading text-center">{__('Resources')}</div>
            {initialLesson?.resources.map((resource) => (
              <div key={resource.id}>
                {resource.type !== ResourceType.Collage &&
                  <div className="pill pointer" onClick={() => setViewedResource(resource)}>
                    <span className="pull-right">
                      <ResourceIcon resource={resource} />
                    </span>
                    {' '}
                    {resource.name}
                  </div>
                }
                {resource.type === ResourceType.Collage &&
                  <div className="pill pointer">
                    <span className="pull-right">
                      <i className="icon-th-large" />
                    </span> {resource.name}
                  </div>
                }
              </div>
            ))}
          </div>
          <AccessControlled feature={AppFeature.LessonFiles}>
            <div className="span4 spacer">
              <div className="heading text-center">{__('Files')}</div>
              {files.map((file) => (
                <div
                  key={file.id}
                  className="pill pointer"
                  onClick={() => setViewedFile(file)}
                  aria-label="View File"
                >
                  {file.name}
                </div>
              ))}
            </div>
          </AccessControlled>
        </div>

        <div className="row-fluid">
          <div className="span3">
            <CategoryPicker
              placeholder={''}
              selectedCategories={category ? [category] : []}
              onChange={(newCategories) => setCategory(newCategories.length > 0 ?
                newCategories[0] :
                undefined,
              )}
              isInvalid={errors.category}
            />
            <div className="info">{__('Category')}</div>

            <VersionPicker
              placeholder={''}
              value={versionId}
              onChange={(selected) => setVersionId(selected as number)}
              isInvalid={errors.version}
            />
            <div className="info">{__('Version')}</div>
          </div>

          <div className="span9">
            <textarea
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              className="span12"
              rows={1}
              aria-label="keywords"
            />
            <div className="info">{__('Lesson Keywords')}</div>
          </div>
        </div>

        <div className="row-fluid">
          <RichTextInput
            value={editorNotes}
            onChange={(newNotes) => setEditorNotes(newNotes)}
            aria-label={__('Editor Notes')}
          />
          <div className="info" style={{marginTop: 2}}>{__('Editor Notes')}</div>
        </div>
        <div className="row-fluid">
          <RichTextInput
            value={description}
            onChange={(newDescription) => setDescription(newDescription)}
            required={errors.description}
          />
          <div className="info" style={{marginTop: 2}}>{__('Lesson Description')}</div>
        </div>

        <CollapsibleSection
          heading={__('Materials Needed')}
          body={
            <div className="frame">
              <RichTextInput
                value={materials}
                onChange={(newMaterials) => setMaterials(newMaterials)}
              />
              <div className="info" style={{marginTop: 2}}>{__('Materials Needed')}</div>
            </div>
          }
        />

        <CollapsibleSection
          heading={__('Preparation')}
          body={
            <div className={'frame'}>
              <RichTextInput
                value={preparation}
                onChange={(newValue) => setPreparation(newValue)}
              />
              <div className="info" style={{marginTop: 2}}>{__('Preparation')}</div>

              <FileUploadInput
                buttonText={__('Files')}
                multiple={true}
                initialFiles={files}
                onChange={(newFiles) => setFiles(newFiles)}
              />
            </div>
          }
        />

        <div className="row-fluid">
          <div className={'span8 section'}>
            <CollapsibleSection
              heading={__('Lesson Plan')}
              body={<AccordionView
                header={<>
                  <span>{__('Lesson Plan')}</span>
                  <ButtonGroup className={'buttons'}>
                    <Button variant={ButtonVariant.Add} onClick={() => setAddingLessonPlan(true)} />
                  </ButtonGroup>
                </>}
                sections={lessonPlans.map((plan, planIndex) => ({
                  heading: <React.Fragment key={planIndex}>
                    <span>
                      {plan.title}
                      {plan.time && <i> ({plan.time} <span>{__('min')}</span>)</i>}
                    </span>
                    <ButtonGroup className={'buttons'}>
                      <Button
                        variant={ButtonVariant.Edit}
                        className={'btn-mini'}
                        tooltip={__('Edit')}
                        iconCode={'icon-edit'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditLessonPlanIndex(planIndex);
                        }}
                      />
                      <Button
                        variant={ButtonVariant.Edit}
                        className={'btn-mini'}
                        tooltip={__('Edit')}
                        iconCode={'icon-plus'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddingEntryPlanIndex(planIndex);
                        }}
                      />
                      <Button
                        variant={ButtonVariant.Delete}
                        className={'btn-mini'}
                        tooltip={__('Delete')}
                        iconCode={'icon-trash'}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteLessonPlan(planIndex);
                        }}
                      />
                    </ButtonGroup>
                  </React.Fragment>,
                  body: <div className={'content'}>
                    {plan.entries.map((entry, entryIndex) => (
                      <React.Fragment key={entryIndex}>
                        <h5 className={'title'} style={{padding: '6px 10px'}}>
                          {entry.activity}
                          {entry.time && <i> ({entry.time} <span>{__('min')}</span>)</i>}
                        </h5>
                        <ButtonGroup className={'pull-right'}>
                          <Button
                            variant={ButtonVariant.Edit}
                            className={'btn-mini'}
                            tooltip={__('Edit')}
                            iconCode={'icon-edit'}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditEntry({planIndex, entryIndex});
                            }}
                          />
                          <Button
                            variant={ButtonVariant.Delete}
                            className={'btn-mini'}
                            tooltip={__('Delete')}
                            iconCode={'icon-trash'}
                            onClick={(e) => {
                              e.stopPropagation();
                              deletePlanEntry({planIndex, entryIndex});
                            }}
                          />
                        </ButtonGroup>
                        <div className={'holder'} dangerouslySetInnerHTML={{__html: entry.steps}}/>
                      </React.Fragment>
                    ))}
                  </div>,
                }))}
              />}
            />
          </div>

          <div className="section span3">
            <CollapsibleSection
              heading={__('Words to Know')}
              body={<AccordionView
                header={<>
                  <span>{__('Words To Know')}</span>
                  <ButtonGroup className={'buttons'}>
                    <Button variant={ButtonVariant.Add} onClick={() => setAddingWord(true)} />
                  </ButtonGroup>
                </>}

                sections={wordsToKnow.map((word, i) => ({
                  heading: <React.Fragment key={i}>
                    <span>{word.word}</span>
                    <ButtonGroup className={'buttons'}>
                      <Button
                        variant={ButtonVariant.Edit}
                        className={'btn-mini'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditWordIndex(i);
                        }}
                      />
                      <Button
                        variant={ButtonVariant.Delete}
                        className={'btn-mini'}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteWordToKnow(i);
                        }}
                      />
                    </ButtonGroup>
                  </React.Fragment>,
                  body: <div className={'html'} dangerouslySetInnerHTML={{__html: word.meaning}} />,
                }),
                )}
              />}
            />
          </div>
        </div>
        <CollapsibleSection
          heading={__('Action Steps')}
          body={
            <>
              <SortableActionStepList
                actionSteps={actionSteps}
                onChange={(newActionSteps) => setActionSteps(newActionSteps)}
              />
            </>
          }
        />
      </form>
    </div>
  </>;
};
