import React, {Fragment, useEffect, useState} from 'react';
import {__} from 'i18n/localize';
import {FormattedDate} from 'components/FormattedDate';
import classNames from 'classnames';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LoadingModal} from 'components/LoadingModal';
import {Modal} from 'components/Modal';
import {UserAchievementRecordDetails} from 'api/achievements/UserAchievementRecordDetails';
import {Goal} from 'api/goals/Goal';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';

/**
 * Displays details of a user achievement record
 */

export interface AchievementRecordDetailsModalProps {
  achievementRecordId: number;
  onCloseRequested?: () => any;
}

export const AchievementRecordDetailsModal = (props: AchievementRecordDetailsModalProps) => {
  const logger = useLogger(AchievementRecordDetailsModal.name);
  const {achievementsService} = useApiContext();

  const [achievementRecord, setAchievementRecord] = useState<UserAchievementRecordDetails | undefined>(undefined);
  const [goalToShowDetails, setGoalToShowDetails] = useState<Goal | undefined>(undefined);

  const nonAchievedSkills = achievementRecord?.achievement.achievement.skills.filter(
      (skill) => !achievementRecord?.achievement.achieved.some((a) => a.ilri.id === skill.id),
  );

  const loadAchievementRecord = async () => {
    const fetchedAchievementRecord = await achievementsService.fetchAchievementRecord(props.achievementRecordId);
    setAchievementRecord(fetchedAchievementRecord);
    logger.debug('Fetched user achievement record', fetchedAchievementRecord);
  };

  useEffect(() => {
    (async () => await loadAchievementRecord())();
  }, []);

  if (!achievementRecord) {
    return <LoadingModal className={'tall wide'} />;
  }

  return (
    <Modal
      title={achievementRecord.achievement.name}
      onCloseRequested={props.onCloseRequested}
      className={'tall wide'}

      body={
        <Fragment>
          {goalToShowDetails &&
            <GoalDetailsModal
              goalId={goalToShowDetails.id}
              onCloseRequested={() => setGoalToShowDetails(undefined)}
            />
          }

          <div className="description padding10">
            <img
              alt={'Achievement Icon'}
              src={
                achievementRecord.achievement.image?.url ?
                  achievementRecord.achievement.image.url + '100/100' :
                  '/images/logo.jpg'
              }
              className="circle-50 gray pull-left"
              style={{marginRight: 10}}
            />
            <div className="html" dangerouslySetInnerHTML={{__html: achievementRecord.achievement.description}} />
          </div>
          <div className="clearfix" />

          <div className="row-fluid padding10">
            {achievementRecord.achievement.expireDate &&
              <Fragment>
                <span className="row-label">{__('Expire')}:</span>
                {' '}
                <span><FormattedDate timestamp={achievementRecord.achievement.expireDate} /></span>
              </Fragment>
            }

            {achievementRecord.achievement.achievement.creditHours &&
              <Fragment>
                <span className="row-label">{__('Credit Hours')}:</span>
                {' '}
                <span>{achievementRecord.achievement.creditHours}</span>
              </Fragment>
            }

            {achievementRecord.achievement.achievement.category &&
              <Fragment>
                <span className="row-label">{__('Category')}:</span>
                {' '}
                <span>
                  <span
                    className={classNames('icon cat', achievementRecord.achievement.achievement.category.icon)}
                    style={{backgroundColor: achievementRecord.achievement.achievement.category.color}}
                  />
                  {achievementRecord.achievement.achievement.category.name}
                </span>
              </Fragment>
            }
          </div>

          <div className="row-fluid block no-space">
            <div className="span12">
              <div className="header text-center">{__('Completed Skills')}</div>
              <div className="body">
                <table className="list">
                  <thead>
                    <tr>
                      <td>{__('Skill')}</td>
                      <td>{__('Date')}</td>
                      <td>{__('Goal')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {achievementRecord.achievement.achieved.map((achieved) => (
                      <tr key={achieved.id}>
                        <td>
                          <div
                            className="pill"
                            style={{borderColor: achieved.ilri.category?.color}}
                          >
                            <div
                              className="circle-20"
                              style={{float: 'right', background: achieved.ilri.category?.color}}
                            >
                              <span className={classNames('icon', achieved.ilri.category?.icon)} />
                            </div>
                            {achieved.ilri.name}</div>
                        </td>
                        <td><FormattedDate timestamp={achieved.cdate} /></td>
                        <td>
                          <div
                            onClick={() => setGoalToShowDetails(achieved.goal)}
                            className="pill pointer"
                            style={{backgroundColor: achieved.goal.color}}
                          >
                            {achieved.goal.name}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {(nonAchievedSkills?.length ?? 0) > 0 &&
            <div className="row-fluid block no-space">
              <div className="span12">
                <div className="header text-center">{__('Required Skills')}</div>
                <div className="body">
                  <table className="list">
                    <thead>
                      <tr>
                        <td>{__('Skill')}</td>
                        <td>{__('Lesson')}</td>
                      </tr>
                    </thead>
                    <body>
                      {achievementRecord.achievement.achieved.map((achieved) => (
                        <tr key={achieved.id}>
                          <td>
                            {nonAchievedSkills?.map((skill) => (
                              <div key={skill.id} className="pill" style={{borderColor: skill.category?.color}}>
                                <div className="circle-20" style={{float: 'right', background: skill.category?.color}}>
                                  <span className={classNames('icon', skill.category?.icon)} />
                                </div>
                                {skill.name}
                              </div>
                            ))}
                          </td>
                          <td>
                          </td>
                        </tr>
                      ))}
                    </body>
                  </table>

                </div>
              </div>
            </div>
          }
        </Fragment>
      }

      footer={<div/>}
    />
  );
};
