import React from 'react';

export const AppInitializingView = () => {
  return (
    <>
      <div>
        <div className='pane city'>
          <div className='body'>
            <div style={{margin: '0 auto 25px', width: 290}}>
              <img
                src='images/my-full-logo.png'
                alt='My Full Life'
              />
            </div>
            <div className='login_pack'>
              <div className='span12'>
                <img
                  src='images/loaders/horizontal.gif'
                  alt={'Loading...'}
                  style={{
                    float: 'left',
                    marginRight: 10,
                    marginTop: 2,
                    borderRadius: '50%',
                    background: 'white',
                    padding: 2,
                    overflow: 'hidden',
                    width: 26,
                    height: 26,
                  }}
                />
                <h4 style={{fontSize: 14}}>Application Loading</h4>
              </div>
            </div>
            <div
              className='login_pack'
              style={{
                width: 250,
                fontSize: 12,
                paddingBottom: 20,
                borderRadius: '10px 30px',
                textAlign: 'center',
              }}
            >
              <div style={{padding: '10px 15px'}}>
                <a
                  style={{color: 'white'}}
                  target='_blank'
                  href='http://www.centriclearning.pro'
                  rel="noreferrer"
                >
                  About
                </a>
                {' | '}
                <a style={{color: 'white'}} href='docs/centric_TOS.pdf'>
                  Terms of Service
                </a>
                {' | '}
                <a style={{color: 'white'}} href='docs/centric_Privacy.pdf'>
                  Privacy policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='footer'>
        <span>© Center for Independent Futures. All rights reserved</span>
      </div>
    </>
  );
};
