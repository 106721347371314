import {Menu, MenuItem} from '@material-ui/core';
import React, {PropsWithChildren} from 'react';

/**
 * A popup "right-click" menu
 */

export interface PopupMenuProps {
  items: {label: string, onClick: () => any}[];
}

export const PopupMenu = (props: PropsWithChildren<PopupMenuProps>) => {
  const [state, setState] = React.useState<{mouseX: number | null, mouseY: number | null}>({
    mouseX: null,
    mouseY: null,
  });

  const handleClick = (event: any) => {
    event.preventDefault();

    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState({mouseX: null, mouseY: null});
  };

  return (
    <span onContextMenu={handleClick} style={{cursor: 'context-menu'}}>
      {props.children}

      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null ?
            {top: state.mouseY, left: state.mouseX} :
            undefined
        }
      >
        {props.items.map((item, i) => (
          <MenuItem onClick={item.onClick} key={i}>{item.label}</MenuItem>
        ))}
      </Menu>
    </span>
  );
};
