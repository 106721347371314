import React, {useEffect, useState} from 'react';
import {__} from 'i18n/localize';
import {Tooltip} from 'components/Tooltip';
import {CollageLibraryItem} from 'api/collages/CollageLibraryItem';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {ImageResourceView} from 'views/resources/ImageResourceView';
import {confirmDelete} from 'components/confirmDelete';

export const CollageLibraryDashboard = () => {
  const logger = useLogger(CollageLibraryDashboard.name);
  const {collagesService} = useApiContext();

  const [items, setItems] = useState<CollageLibraryItem[]>([]);
  const [openItem, setOpenItem] = useState<CollageLibraryItem | undefined>(undefined);

  const fetchItems = async () => {
    const result = await collagesService.fetchLibraryItems({});
    setItems(result);
    logger.debug('Fetched collage items', result);
  };

  useEffect(() => {
    (async () => {
      await fetchItems();
    })();
  }, [collagesService]);

  return (
    <div className="pane">
      {openItem &&
        <ImageResourceView url={openItem.image?.url} onCloseRequested={() => setOpenItem(undefined)} />
      }

      <div className="head">
        <span>{__('Collage Library')}</span>

        <div className="buttons btn-group">
          <Tooltip title={__('Add')}>
            <a className="btn btn-success">
              <i className="icon-plus-circled"/>
            </a>
          </Tooltip>
        </div>
      </div>
      <div className="body" style={{overflowY: 'auto'}}>
        <div role="list">
          {items.map((item) => (
            <>
              {item.image &&
              <div
                key={item.id}
                className="gallery-image"
                onClick={() => setOpenItem(item)}
              >
                <div className="icons btn-group">
                  <Tooltip title={__('Delete')}>
                    <a
                      className="btn btn-icon btn-mini"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(async () => {
                          await collagesService.deleteLibraryItem(item.id);
                          await fetchItems();
                        });
                      }}
                    >
                      <i className="icon-trash"/>
                    </a>
                  </Tooltip>
                </div>
                <img src={item.image.url + '150/100/1'} alt={''} />
              </div>
              }
            </>
          ))}
        </div>
      </div>
    </div>

  );
};
