/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {__, localizeText} from 'i18n/localize';
import {useAuthContext} from 'contexts/AuthContext';
import {AppRoute} from 'routing/AppRoute';
import {Link} from 'react-router-dom';
import {useApiContext} from 'api/ApiContext';
import dayjs from 'dayjs';
import {useLogger} from 'logging/logging';
import {StatEvent} from 'api/stats/StatEvent';
import {Slider} from '@material-ui/core';
import {Goal} from 'api/goals/Goal';
import {GoalStatus} from 'api/goals/GoalStatus';
import {UserAvatar} from 'components/UserAvatar';
import {SkillGoal} from 'api/skills/SkillGoal';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {GoalThumbnail} from 'views/goals/GoalThumbnail';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';
import {ParticipantBadgesQuickView} from 'views/plan/ParticipantBadgesQuickView';
import {ParticipantChallengesQuickView} from 'views/plan/ParticipantChallengesQuickView';
import {UserFile} from 'api/files/UserFile';
import {ResourceIcon} from 'views/resources/ResourceIcon';
import {FormattedDate} from 'components/FormattedDate';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {Resource} from 'api/resources/Resource';
import {ResourceView} from 'views/resources/ResourceView';
import classNames from 'classnames';
import {Certificate} from 'api/certificates/Certificate';
import {Config} from 'Config';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {ParticipantStatsGraph} from 'views/plan/ParticipantStatsGraph';
import localeData from 'dayjs/plugin/localeData';
import {ParticipantStatsPrintView} from 'views/plan/ParticipantStatsPrintView';
import {PrintModal} from 'components/PrintModal';

dayjs.extend(localeData);

export const ParticipantStatsView = () => {
  const logger = useLogger(ParticipantStatsView.name);
  const {currentUser} = useAuthContext();
  const {activeUser} = useUserSwitchingContext();
  const {statsService, goalsService, skillsService, userFilesService, certificatesService} = useApiContext();

  const user = activeUser ?? currentUser;
  const userId = activeUser?.id ?? currentUser?.id ?? -1;
  const userImageSrc = activeUser ? activeUser.image?.url : currentUser?.image;

  const [events, setEvents] = useState<StatEvent[]>([]);
  const [eventsStartDate, setEventsStartDate] = useState<number>(-31);
  const [eventsEndDate, setEventsEndDate] = useState<number>(0);
  const [isFetchingEvents, setIsFetchingEvents] = useState<boolean>(true);
  const [skillGoals, setSkillGoals] = useState<SkillGoal[]>([]);
  const [isFetchingSkillGoals, setIsFetchingSkillGoals] = useState<boolean>(true);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [isFetchingGoals, setIsFetchingGoals] = useState<boolean>(true);
  const [files, setFiles] = useState<UserFile[]>([]);
  const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(true);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [showDetailsGoal, setShowDetailsGoal] = useState<Goal | undefined>(undefined);
  const [viewedResource, setViewedResource] = useState<Resource | undefined>(undefined);
  const [certificates, setCertificates] = useState<Certificate[]>([]);

  const completedGoals = goals.filter((goal) => goal.state === GoalStatus.Completed);
  const inProgressGoals = goals.filter((goal) => goal.state === GoalStatus.Active);
  const earnedSkills = skillGoals.filter(
      (skillGoal) => skillGoal.state === GoalStatus.Completed && skillGoal.goals.length > 0,
  );

  const loadSkillGoals = async () => {
    setIsFetchingSkillGoals(true);
    const fetchedSkillGoals = await skillsService.fetchUserSkillGoals(userId);
    setSkillGoals(fetchedSkillGoals);
    logger.debug('Fetched user skill goals', fetchedSkillGoals);
    setIsFetchingSkillGoals(true);
  };

  const loadEvents = async () => {
    setIsFetchingEvents(true);
    const fetchedEvents = await statsService.fetchStatEvents(
        dayjs().add(eventsStartDate, 'months').toDate(),
        dayjs().add(eventsEndDate, 'months').toDate(),
    );
    setEvents(fetchedEvents);
    logger.debug('Fetched user events', fetchedEvents);
    setIsFetchingEvents(false);
  };

  const loadGoals = async () => {
    setIsFetchingGoals(true);
    const fetchedGoals = await goalsService.fetchUserGoalsById(userId);
    setGoals(fetchedGoals);
    logger.debug('Fetched user goals', fetchedGoals);
    setIsFetchingGoals(false);
  };

  const loadFiles = async () => {
    setIsFetchingFiles(true);
    const fetchedFiles = await userFilesService.fetchUserFiles();
    setFiles(fetchedFiles);
    logger.debug('Fetched user files', fetchedFiles);
    setIsFetchingFiles(false);
  };

  const loadCertificates = async () => {
    const fetchedCertificates = await certificatesService.fetchUserCertificates(userId);
    setCertificates(fetchedCertificates);
    logger.debug('Fetched user certificates', fetchedCertificates);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        loadEvents(),
        loadGoals(),
        loadSkillGoals(),
        loadFiles(),
        loadCertificates(),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (eventsEndDate !== undefined && eventsStartDate !== undefined) {
        await loadEvents();
      }
    })();
  }, [eventsEndDate, eventsStartDate]);

  return (
    <PageView>
      {showDetailsGoal &&
        <GoalDetailsModal
          goalId={showDetailsGoal.id}
          onCloseRequested={() => setShowDetailsGoal(undefined)}
        />
      }

      {viewedResource &&
        <ResourceView
          resource={viewedResource}
          onCloseRequested={() => setViewedResource(undefined)}
        />
      }

      {showPrint &&
        <PrintModal
          onCloseRequested={() => setShowPrint(false)}
          view={
            <ParticipantStatsPrintView
              userName={user?.name ?? ''}
              pictureSrc={userImageSrc ?? Config.defaultUserImage}
              goals={{
                active: inProgressGoals,
                complete: completedGoals,
              }}
              earnedSkills={earnedSkills}
            />
          }
        />
      }

      <div className="pane">
        <div className="head" />

        <div className="body plan-overview">
          <div className="row-fluid whiteBox clearfix" style={{marginBottom: 15}}>
            <div className="stat span2">
              <div style={{marginTop: 10, marginBottom: 25}}>
                <UserAvatar
                  src={userImageSrc ?? Config.defaultUserImage}
                  size={105}
                />
              </div>
              <div className="section">
                {user?.name}
              </div>
              <div
                className="description pointer"
                style={{
                  textAlign: 'center',
                  padding: '8px 10px 6px',
                  lineHeight: '11px',
                  fontSize: '0.7em',
                  backgroundColor: '#aeaeae',
                  borderRadius: '4px 8px',
                  color: '#fff',
                  margin: 5,
                  float: 'right',
                }}
                onClick={() => setShowPrint(true)}
              >
                <i className="icon-print"/>
              </div>
              <Link
                to={AppRoute.DashboardContext + AppRoute.PlanOverview}
                className="description pointer"
                style={{
                  textAlign: 'center',
                  padding: '8px 10px 6px',
                  lineHeight: '11px',
                  fontSize: '0.7em',
                  backgroundColor: '#aeaeae',
                  borderRadius: '4px 8px',
                  color: '#fff',
                  width: '40%',
                  float: 'left',
                  margin: 5,
                }}
              >
                {localizeText('Next Steps')}
              </Link>

            </div>
            <div className="stat span3">
              <div className="buigNum">
                {isFetchingGoals && '...'}
                {!isFetchingGoals && inProgressGoals.length}
              </div>
              <div className="section">
                {localizeText('Active Goals')}
              </div>
              <div className="description">
                {localizeText('We suggest no more than 3')}
              </div>
            </div>
            <div className="span6">
              {isFetchingEvents &&
                <div style={{height: 308, position: 'relative'}}>
                  <div style={{position: 'absolute', left: '50%', top: '50%'}}>
                    <LoadingIndicator showDelay={0}/>
                  </div>
                </div>
              }
              {!isFetchingEvents &&
                <ParticipantStatsGraph
                  events={events}
                  earliestLookBackMonths={eventsStartDate}
                  latestLookBackMonths={eventsEndDate + 1}
                />
              }
              <div className="section">
                {localizeText('Participant Activity')}
              </div>
            </div>
          </div>
          <div className={'slider'}>
            <Slider
              value={[eventsStartDate, eventsEndDate]}
              step={1}
              max={0}
              min={-31}
              valueLabelDisplay={'auto'}
              valueLabelFormat={(value) => {
                const date = dayjs().add(value, 'months');
                return date.format('M/YY');
              }}
              onChange={(e, newValue) => {
                setEventsStartDate((newValue as number[])[0]);
                setEventsEndDate((newValue as number[])[1]);
              }}
            />
          </div>

          <div className="row-fluid">
            <div className="span8">
              <div>
                {earnedSkills.length > 0 &&
                  <div className="pack whiteBox" style={{textAlign: 'left'}}>
                    <div className="name">
                      {localizeText('Skills Earned in My Full Life')}
                    </div>
                    <div className="body clearfix">
                      {earnedSkills
                          .sort((a, b) => (a.completeDate ?? 0) < (b.completeDate ?? 0) ? -1 : 1)
                          .map((skill) => (
                            <div key={skill.id} className="completedSkills">
                              <div
                                className="small circle"
                                style={{backgroundColor: skill.ilri.category.color, cursor: 'default'}}
                              >
                                <span className={classNames('icon', skill.ilri.category.icon)} />
                              </div>
                              <div className="name">{skill.name}</div>
                            </div>
                          ))}
                    </div>
                  </div>
                }
                <div className="pack">
                  <div className="name">
                    {localizeText('Goals')}
                  </div>
                  <div className="body clearfix">
                    <div className="badgeSection">
                      <div className="title">
                        {localizeText('Completed')}
                      </div>
                      {completedGoals.map((goal) => (
                        <GoalThumbnail
                          key={goal.id}
                          goal={goal}
                          onOpenRequested={() => setShowDetailsGoal(goal)}
                        />
                      ))}
                    </div>
                    <div className="badgeSection">
                      <div className="title">
                        {localizeText('In Progress')}
                      </div>
                      {inProgressGoals.map((goal) => (
                        <GoalThumbnail
                          key={goal.id}
                          goal={goal}
                          onOpenRequested={() => setShowDetailsGoal(goal)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <ParticipantBadgesQuickView userId={userId}/>
                <ParticipantChallengesQuickView userId={userId}/>
              </div>
            </div>

            <div className="span4">
              <div className="pack" id="files">
                <div className="name">
                  {__('Files')}
                </div>

                <div
                  className="body user-files prison clearfix"
                  style={{maxHeight: 500, overflowY: 'auto'}}
                >
                  {files.map((file) => (
                    <div
                      key={file.id}
                      className="item active"
                      onClick={() => setViewedResource(file.resource)}
                    >
                      <ResourceIcon resource={file.resource}/>
                      <div className="date">
                        <FormattedDate timestamp={file.cdate} />
                      </div>
                      <div className="name">
                        {file.name}
                        &nbsp;
                        {file.resource.type === ResourceType.QuizSolution && file.resource.passed &&
                          <span className=" badge badge-success">Complete</span>
                        }
                        {file.resource.type === ResourceType.QuizSolution &&
                        !file.resource.passed &&
                        !file.resource.survey &&
                          <span className="badge badge-important">Incomplete</span>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="pack" id="certificates">
                <div className="name">{__('Certificates')}</div>
                <div
                  className="body user-files prison clearfix"
                  style={{maxHeight: 500, overflowY: 'auto'}}
                >
                  {certificates.map((certificate) => (
                    <div key={certificate.id} className={classNames('item pointer', {'active': certificate.active})}>
                      <i className="icon icon-award" />
                      {certificate.expired &&
                        <div className="badge-warning badge">Expired</div>
                      }
                      {!certificate.expired && certificate.daysValid && certificate.expDate &&
                        <div className="badge-info badge">
                          Valid until <FormattedDate timestamp={certificate.expDate} />
                        </div>
                      }
                      {certificate.completeDate &&
                        <div className="date">
                          <FormattedDate timestamp={certificate.completeDate} />
                        </div>
                      }
                      <div className="name">
                        {certificate.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageView>
  );
};
