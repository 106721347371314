import React from 'react';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {Resource, SaveResourceDto} from 'api/resources/Resource';

/**
 * Renders the correct icon for a given resource
 */

export interface ResourceIconProps {
  resource: Resource | SaveResourceDto,
  showLabel?: boolean,
  onClick?: () => any,
}

export const ResourceIcon = (props: ResourceIconProps) => {
  return <>
    {props.resource?.type === ResourceType.File &&
      <i className="icon-doc" />
    }
    {props.resource?.type === ResourceType.Image &&
      <i className="icon-picture" />
    }
    {props.resource.type === ResourceType.Link &&
      <i className={'icon-link'} />
    }
    {props.resource?.type === ResourceType.Collage &&
      <i className="icon-th-large" />
    }
    {props.resource?.type === ResourceType.Assessment &&
      <i className="icon-edit" />
    }
    {props.resource?.type === ResourceType.Movie &&
      <i className="icon-youtube-play" />
    }
    {props.resource?.type === ResourceType.Quiz &&
      <i className="icon-check" />
    }
    {props.resource?.type === ResourceType.QuizSolution &&
      <i className="icon-check" />
    }

    {props.showLabel &&
      <>
        {props.resource?.type === ResourceType.File &&
          <span> File</span>
        }
        {props.resource?.type === ResourceType.Image &&
          <span> Picture</span>
        }
        {props.resource.type === ResourceType.Link &&
          <span> Link</span>
        }
        {props.resource?.type === ResourceType.Collage &&
          <span> Collage</span>
        }
        {props.resource?.type === ResourceType.Assessment &&
          <span/>
        }
        {props.resource?.type === ResourceType.Movie &&
          <span> Video</span>
        }
        {props.resource?.type === ResourceType.Quiz &&
          <span> Learning Check</span>
        }
        {props.resource?.type === ResourceType.QuizSolution &&
          <span/>
        }
      </>
    }
  </>;
};
