import React, {useEffect} from 'react';
import ReactJoyride from 'react-joyride';

export interface PlanJoyRideProps {
  onComplete?: () => any,
}

export const PlanJoyRide = () => {
  useEffect(() => {
  }, []);

  return (
    <ReactJoyride
      continuous={true}
      steps={[
        {
          target: 'body',
          content: 'test',
        },
      ]}
    />
  );
};
