import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__, localizeText} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {CreateCommentDto} from 'api/goals/Comment';
import {FileUploadInput} from 'components/forms/FileUploadInput';

export interface CommentInputModalProps {
  entryId?: number,
  onCloseRequested?: () => any,
  onCompleted?: (comment: CreateCommentDto) => any,
}

export const CommentInputModal = (props: CommentInputModalProps) => {
  const [comment, setComment] = useState<CreateCommentDto>({text: ''});
  const [errors, setErrors] = useState<any>({});

  const submit = async () => {
    if (validate()) {
      props.onCompleted?.(comment);
    }
  };

  const validate = () => {
    let isValid = true;

    if (comment.text.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, text: true}));
    }

    return isValid;
  };

  useEffect(() => {
    validate();
  }, [comment]);

  return (
    <Modal
      title={localizeText('Add Comment')}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
        />
      }

      body={
        <>
          <RichTextInput
            placeholder={'Comment'}
            required={errors.text}
            value={comment.text}
            onChange={(newValue) => setComment((current) => ({...current, text: newValue}))}
          />
          <FileUploadInput
            buttonText={__('File')}
            onChange={(newFiles) => {
              setComment((current) => ({
                ...current,
                file: newFiles.length > 0 ? newFiles[0] : undefined,
              }));
            }}
          />
        </>
      }

      footer={<InputPlaceholderMirror/>}
    />
  );
};
