import React from 'react';
import {LessonWithRelations} from 'api/lessons/Lesson';

export interface PrintLessonViewProps {
  lesson: LessonWithRelations,
}

export const PrintLessonView = (props: PrintLessonViewProps) => {
  const lesson = props.lesson;

  return <>
    <table className="underline">
      <tr>
        <td className="vtop" style={{paddingLeft: 15}}>
          <h1>{lesson.name}</h1>
        </td>
        <td
          className="vbottom nowrap"
          width="10%"
          style={{fontStyle: 'italic'}}
        >
          <h3>{lesson.category.name}</h3>
        </td>
      </tr>
    </table>

    <h2>Skills</h2>
    <div>
      {lesson.ilries.map((skill, i) => (
        <span key={skill.id}>
          {skill.name}
          {' '}
          {i < lesson.ilries.length - 1 ? ', ' : ''}
        </span>
      ))}
    </div>

    <h2>Description</h2>
    <div
      className="html"
      dangerouslySetInnerHTML={{__html: lesson.text}}
    />

    <h2>Materials Needed</h2>
    <div
      className="html"
      dangerouslySetInnerHTML={{__html: lesson.materials ?? ''}}
    />

    <h2>Preparation</h2>
    <div
      dangerouslySetInnerHTML={{__html: lesson.preparation ?? ''}}
    />

    <h2>Lesson Plan</h2>
    {lesson.plans.map((lessonPlan) => (
      <div key={lessonPlan.id}>
        <h3>
          {lessonPlan.title}
          {' '}
          {lessonPlan.time && <span>({lessonPlan.time} min)</span>}
        </h3>
        {lessonPlan.entries.map((entry) => (
          <div key={entry.id}>
            <div>
              <b><i>
                {entry.activity}
                {' '}
                {entry.time && <span>({entry.time} min)</span>}
              </i></b>
            </div>
            <p
              className="html"
              dangerouslySetInnerHTML={{__html: entry.steps}}
            />
          </div>
        ))}
      </div>
    ))}

    <h2>Words to Know</h2>
    {lesson.words.map((word) => (
      <div key={word.id}>
        <div><b><i>{word.word}</i></b></div>
        <p className={'html'} dangerouslySetInnerHTML={{__html: word.meaning}} />
      </div>
    ))}

    <h2>Action Steps</h2>
    {lesson.steps.map((actionStep) => (
      <div key={actionStep.id}>
        <h3 className="underline">
          {actionStep.title}
          {actionStep.eol && <span> <i>( EOL )</i></span>}
        </h3>
        <div>
          <h4>Description</h4>
          <p className={'htlm'} dangerouslySetInnerHTML={{__html: actionStep.description ?? ''}} />
        </div>
        {actionStep.ilries.length > 0 &&
          <div>
            <h4>Skills</h4>
            {actionStep.ilries.map((skill, i) => (
              <p key={skill.id}>
                <span>
                  {skill.name}
                  {i < actionStep.ilries.length - 1 && ', '}
                </span>
              </p>
            ))}
          </div>
        }
      </div>
    ))}
  </>;
};
