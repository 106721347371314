import React from 'react';
import {useSessionContext} from 'contexts/SessionContext';
import classNames from 'classnames';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {MenuItem} from 'layout/menus/MenuItem';
import {__} from 'i18n/localize';
import {AppFeature} from 'access-control/AppFeature';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {getProfileRoute, getSchoolRoute} from 'routing/routing';
import {Tooltip} from 'components/Tooltip';
import {useAccessControlContext} from 'access-control/AccessControlContext';
import {useSchoolContext} from 'contexts/SchoolContext';
import {UserRoleType} from 'api/users/UserRole';

export const TopMenu = () => {
  const {sessionDetails} = useSessionContext();
  const {accessControlService} = useAccessControlContext();
  const {pathname} = useLocation();
  const {push: pushRoute} = useHistory();
  const {activeUser, userIsSwitched, setActiveUser} = useUserSwitchingContext();
  const {selectedSchool} = useSchoolContext();

  const baseRoute = activeUser && userIsSwitched ?
    getProfileRoute(activeUser.id) :
    AppRoute.DashboardContext;

  const menuItems: MenuItem[] = [
    {
      body: <i className="icon-i-mental-health" />,
      tooltip: __('Hopes and Dreams'),
      route: AppRoute.Dreams,
      feature: AppFeature.DashboardDreams,
    },
    {
      body: <i className="icon-compass" />,
      tooltip: __('Next Steps & Planning'),
      route: AppRoute.PlanOverview,
    },
    {
      body: <i className="icon-book" />,
      tooltip: __('Goals & Assignments'),
      route: AppRoute.Goals,
    },
    {
      body: <i className="icon-chat" />,
      tooltip: __('Communications/Journal'),
      route: AppRoute.Journal,
    },
    {
      body: <i className="icon-phone" />,
      tooltip: __('Networks/Resources'),
      route: AppRoute.Community,
      feature: AppFeature.DashboardNetwork,
    },
  ];

  const routeParts = pathname.split('/');
  const showMenu = routeParts.length > 1 &&
    ['/profile', AppRoute.DashboardContext].includes('/' + routeParts[1]);
  if (!showMenu) {
    return <></>;
  }

  return (
    <div
      style={{position: 'absolute', top: 0, left: '50%', marginLeft: -70}}
    >
      <div className="top-menu btn-group">
        {userIsSwitched &&
          <Tooltip title={__('Exit Participant View')}>
            <a
              className={classNames('btn btn-inverse')}
              onClick={async () => {
                await setActiveUser(undefined);
                if (selectedSchool && sessionDetails?.role?.type === UserRoleType.SystemAdmin) {
                  pushRoute(getSchoolRoute(selectedSchool.id) + '/users');
                } else {
                  pushRoute(AppRoute.Home);
                }
              }} aria-label="Exit Participant View"
            >
              <i className="icon-binoculars" />
            </a>
          </Tooltip>
        }

        {menuItems.map((menuItem) => {
          const showItem =
            !menuItem.feature ||
            (
              sessionDetails?.role &&
              accessControlService.roleCanAccess(sessionDetails.role.type, menuItem.feature)
            );

          if (showItem) {
            return (
              <Tooltip title={menuItem.tooltip ?? ''} key={menuItem.route}>
                <Link
                  key={menuItem.route}
                  to={baseRoute + menuItem.route}
                  className={classNames(
                      'btn btn-default',
                      {active: pathname === baseRoute + menuItem.route},
                  )} aria-label={menuItem.tooltip ?? ''}
                >
                  {menuItem.body}
                </Link>
              </Tooltip>
            );
          }
        })}
      </div>
    </div>
  );
};
