import loglevel from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import {useMemo} from 'react';

/**
 * Configure top-level logger (which tagged loggers derive from)
 */
const rootLogger = loglevel;
rootLogger.setLevel(
    (process.env.REACT_APP_LOG_LEVEL as
    | 'trace'
    | 'debug'
    | 'info'
    | 'warn'
    | 'error'
    | 'silent') ?? 'info',
);

/**
 * Apply prefix plugin (to display tags for log entries)
 */
prefix.reg(rootLogger);
prefix.apply(rootLogger);
prefix.apply(rootLogger, {
  format(level, name, timestamp) {
    return `${timestamp} [${name} - ${level}]: `;
  },
});

/**
 * Creates a tagged logger for use in React components,
 * memoized to prevent recreation on each render.
 */
export function useLogger(tag: string): loglevel.Logger {
  return useMemo(() => rootLogger.getLogger(tag), []);
}
