import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import {useLogger} from 'logging/logging';
import {SessionDetails} from 'api/sessions/SessionDetails';
import {useApiContext} from 'api/ApiContext';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * A session context provides data about the current API session
 */

export interface SessionContextInterface {
  sessionDetails: SessionDetails | undefined;
  fetchSessionDetails: () => Promise<SessionDetails | undefined>;
  setSchool: (schoolId: number | undefined) => Promise<SuccessResponse<undefined>>,
}

export const SessionContext = createContext<
  SessionContextInterface | undefined
>(undefined);

export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(
        useSessionContext.name + ' must be within a SessionContext provider',
    );
  }
  return context;
};

export interface SessionContextProviderProps {
  initialSessionDetails?: SessionDetails,
}

export const SessionContextProvider = (
    props: PropsWithChildren<SessionContextProviderProps>,
) => {
  const logger = useLogger(SessionContextProvider.name);

  const {sessionService} = useApiContext();

  const [sessionDetails, setSessionDetails] =
    useState<SessionDetails>(props.initialSessionDetails ?? {ok: false});

  const fetchSessionDetails = async (): Promise<SessionDetails | undefined> => {
    try {
      const sessionDetails = await sessionService.fetchSessionDetails();
      logger.debug('Fetched session details', sessionDetails);
      setSessionDetails(sessionDetails);
      return sessionDetails;
    } catch (e) {
      logger.error('Error fetching session details', e);
      setSessionDetails({ok: false});
      return undefined;
    }
  };

  const setSchool = async (schoolId: number | undefined) => {
    const result = await sessionService.setActiveSchool(schoolId ?? null);
    await fetchSessionDetails();
    return result;
  };

  return (
    <SessionContext.Provider
      value={{
        sessionDetails,
        fetchSessionDetails,
        setSchool,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
