/**
 * Routes provided by the API
 */
export enum ApiRoute {
  BaseUrl = '/api',

  // action routes
  List = '/list',
  Get = '/get',
  Save = '/save',
  Delete = '/remove',

  // entity base routes
  Users = '/users',
  Schools = '/schools',
  Categories = '/categories',
  Courses = '/courses',
  Goals = '/goals',
  Skills = '/ilries',
  Versions = '/versions',
  Achievements = '/achievements',
  Surveys = '/quizzes',
  Lessons = '/lessons',
  Products = '/products',
  JournalEntries = '/journal/entries',
  JournalComments = '/journal/comments',
  Appointments = '/student/appointments',
  Challenges = '/challenges',
  Quizzes = '/quizzes',
  Syllabi = '/syllabi',

  // full routes
  AuthenticateUser = '/auth/login',
  LogOut = '/auth/logout',
  SessionDetails = '/session/details',
  SetSelectedStudent = '/session/setStudent',

  StatEvents = '/stats/getEvents',
  StatGoals = '/stats/goals',

  Files = '/student/files',

  InstructorsList = '/users/instructors',
  GetInstructor = '/users/instructor',

  GetUserProfile = '/profile/get',
  SaveUserProfile = '/profile/save',
  SaveUserNotifications = '/profile/saveNotifications',
  UserLanguage = '/users/languages',

  GetUser = '/users/get',
  SaveUser = '/users/save',
  DeleteUser = '/users/remove',
  SuspendUser = '/users/suspend',
  UnSuspendUser = '/users/activate',

  SetActiveSchool = '/session/setSchool',
  SetActiveStudent = '/session/setStudent',

  UsersList = '/users/list',
  UserRolesList = '/users/roles/list',
  LessonsList = '/lessons/list ',

  SchoolsList = '/schools/list',
  SchoolsSave = '/schools/save',
  SchoolsDelete = '/schools/remove',
  SchoolsGetApiKey = '/schools/apiKey',

  CategoriesList = '/categories/list',
  CategoryDelete = '/categories/remove',
  CategoryChangeSchool = '/categories/changeOwnership',

  GoalsPersonnel = '/goals/personal',
  GoalsResource = '/goals/resources',

  SkillsList = '/ilries/list',
  SkillsSave = '/ilries/save',
  SkillsDelete = '/ilries/remove',
  UserSkills = '/users/ilri',
  UserSkillGoals = '/users/ilriGoals',

  VersionsList = '/versions/list',

  AchievementDetails = '/achievementDetails',
  AchievementsRecent = '/achievements/recent',
  AchievementsMarkAsOld = '/achievements/markAsOld',

  CreateResource = '/student/resources/createResource',
  FetchResource = '/student/resources/get',

  UpdateProducts = '/cron/products/update'
}
