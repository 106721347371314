import {UserRoleType} from 'api/users/UserRole';
import {AppRoute} from 'routing/AppRoute';
import {__} from 'i18n/localize';
import {SessionDetails} from 'api/sessions/SessionDetails';
import {getSchoolRoute} from 'routing/routing';

export interface SectionMenuItem {
  title: string,
  route: string,
}

/**
 * Gets the base section menu path for the given session
 *
 * @param {SessionDetails | undefined} sessionDetails
 * @return {string}
 */
export function basePathForSession(sessionDetails?: SessionDetails): string {
  if (!sessionDetails) {
    return AppRoute.Home;
  }

  const role = sessionDetails.role?.type;
  if (!role) {
    return AppRoute.Home;
  }

  if (role === UserRoleType.SystemAdmin) {
    if (sessionDetails.selectedSchool) {
      return getSchoolRoute(sessionDetails.selectedSchool.id);
    } else {
      return AppRoute.SystemContext;
    }
  } else if (role === UserRoleType.Admin) {
    if (sessionDetails.selectedSchool) {
      return getSchoolRoute(sessionDetails.selectedSchool.id);
    } else {
      return AppRoute.Home;
    }
  } else if (role === UserRoleType.Teacher) {
    return AppRoute.DashboardContext;
  } else {
    return AppRoute.Home;
  }
}

/**
 * Determines the menu items for the section menu based on the current session.
 *
 * @param {SessionDetails | undefined} sessionDetails
 * @return {SectionMenuItem[]}
 */
export function sectionMenuItemsForSession(
    sessionDetails?: SessionDetails,
): SectionMenuItem[] {
  const systemMenuItems = [
    {
      title: __('Overview'),
      route: AppRoute.SystemOverview,
    },
    {
      title: __('Users'),
      route: AppRoute.Users,
    },
    {
      title: __('Content Editor'),
      route: AppRoute.ContentEditor,
    },
    {
      title: __('Categories'),
      route: AppRoute.Categories,
    },
    {
      title: __('Versions'),
      route: AppRoute.Versions,
    },
    {
      title: __('Lessons'),
      route: AppRoute.Lessons,
    },
    {
      title: __('Skills'),
      route: AppRoute.Skills,
    },
    {
      title: __('Quizzes'),
      route: AppRoute.LearningChecks,
    },
    {
      title: __('Surveys'),
      route: AppRoute.Surveys,
    },
    {
      title: __('Badges'),
      route: AppRoute.Achievements,
    },
    {
      title: __('Products'),
      route: AppRoute.Products,
    },
    {
      title: __('Campus'),
      route: AppRoute.Campus,
    },
  ];
  const schoolMenuItems = [
    {
      title: __('Overview'),
      route: AppRoute.Stats,
    },
    {
      title: __('Users'),
      route: AppRoute.Users,
    },
    {
      title: __('Instructors'),
      route: AppRoute.Instructors,
    },
    {
      title: __('Classes'),
      route: AppRoute.SchoolCourses,
    },
    {
      title: __('Lessons'),
      route: AppRoute.Lessons,
    },
    {
      title: __('Content Editor'),
      route: AppRoute.ContentEditor,
    },
    {
      title: __('Categories'),
      route: AppRoute.Categories,
    },
    {
      title: __('Quizzes'),
      route: AppRoute.LearningChecks,
    },
    {
      title: __('Skills'),
      route: AppRoute.Skills,
    },
    {
      title: __('Badges'),
      route: AppRoute.Achievements,
    },
    {
      title: __('Products'),
      route: AppRoute.Products,
    },
    {
      title: __('Syllabi'),
      route: AppRoute.Syllabi,
    },
    {
      title: __('Collage Library'),
      route: AppRoute.CollageLibrary,
    },
    {
      title: __('Customize'),
      route: AppRoute.Configure,
    },
  ];

  const teacherMenuItems = [
    {
      title: __('Lessons'),
      route: AppRoute.Lessons,
    },
    {
      title: __('Activity Log'),
      route: AppRoute.ActivityLog,
    },
    {
      title: __('Classes'),
      route: AppRoute.TeacherCourses,
    },
    {
      title: __('Participants'),
      route: AppRoute.Students,
    },
    {
      title: __('Quizzes'),
      route: AppRoute.LearningChecks,
    },
    {
      title: __('Skills'),
      route: AppRoute.Skills,
    },
  ];

  if (!sessionDetails?.role?.type) {
    return [];
  }

  const role = sessionDetails.role.type;

  if (role === UserRoleType.SystemAdmin) {
    if (sessionDetails.selectedSchool) {
      return schoolMenuItems;
    } else {
      return systemMenuItems;
    }
  } else if (role === UserRoleType.Admin) {
    return schoolMenuItems;
  } else if (role === UserRoleType.Teacher) {
    return teacherMenuItems;
  } else if (role === UserRoleType.Support) {
    return [
      {
        title: __('Activity Log'),
        route: 'dashboard/activity',
      },
      {
        title: __('Participants'),
        route: 'dashboard/students/list',
      },
    ];
  } else {
    return [];
  }
}
