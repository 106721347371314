import React, {useState} from 'react';
import {SaveActionStepDto} from 'api/goals/ActionStep';
import classNames from 'classnames';
import {Skill} from 'api/skills/Skill';
import {SkillDetailsModal} from 'views/skills/SkillDetailsModal';
import {ResourceBase} from 'api/resources/Resource';
import {ResourceView} from 'views/resources/ResourceView';
import {Tooltip} from 'components/Tooltip';
import {ResourceIcon} from 'views/resources/ResourceIcon';

export interface ActionStepListProps {
  steps: SaveActionStepDto[],
}

export const ActionStepList = (props: ActionStepListProps) => {
  const {steps} = props;
  const [openSkill, setOpenSkill] = useState<Skill | undefined>(undefined);
  const [openResource, setOpenResource] = useState<ResourceBase | undefined>(undefined);

  return (
    <>
      {openSkill &&
        <SkillDetailsModal skill={openSkill} onCloseRequested={() => setOpenSkill(undefined)} />
      }

      {openResource &&
        <ResourceView resource={openResource} onCloseRequested={() => setOpenResource(undefined)} />
      }

      {steps.map((step, stepIndex) => (
        <div
          key={step.id}
          className={classNames('row-fluid steps', {'odd': stepIndex % 2 === 0}, {'inactive': !step.enabled})}
        >
          <div className="span1 text-center">
            {step.enabled &&
              <div style={{color: 'forestgreen', fontSize: 10}}>Active</div>
            }
          </div>
          <div className="span7 name">{step.title}</div>
          {step.resources?.map((resource) => (
            <div key={resource.id} className="span2" aria-label={resource.name}>
              <Tooltip title={resource.name}>
                <div className="pill pointer" onClick={() => setOpenResource(resource)}>
                  <ResourceIcon resource={resource} showLabel={true}/>
                </div>
              </Tooltip>
            </div>
          ))}

          <div className="span2">
            {step.ilries.map((skill) => (
              <Tooltip title={skill.name} key={skill.id}>
                <div
                  key={skill.id}
                  className="pill ng-scope ng-binding"
                  style={{cursor: 'pointer', backgroundColor: skill.category?.color}}
                  onClick={() => setOpenSkill(skill)}
                >
                  <i className="icon-plus" /> Skill Earned
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
