import React, {useState} from 'react';
import {__} from 'i18n/localize';
import {ChallengesListModal} from 'views/challenges/ChallengesListModal';

export interface ParticipantChallengesQuickViewProps {
  userId: number,
}

export const ParticipantChallengesQuickView = (props: ParticipantChallengesQuickViewProps) => {
  const [showAllChallenges, setShowAllChallenges] = useState<boolean>(false);

  return (
    <div>
      {showAllChallenges &&
        <ChallengesListModal
          studentId={props.userId}
          onCloseRequested={() => setShowAllChallenges(false)}
        />
      }

      <div className="row-fluid">
        <div className="pack">
          <div className="name">
            Current Challenge
            <div
              className="bottomButton pointer hideSmall"
              onClick={() => setShowAllChallenges(true)}
              // popup="challenges.list({type: 'USER', user: challenge.user})"
            >
              All Challenges
            </div>
          </div>
          <div className="challenge body clearfix ">
            <div className="items" ng-if="challenge.data.id">
              <div className="item number" ng-bind="challenge.data.collected" />
              <div className="item text">{__('of')}</div>
              <div className="item number" ng-bind="challenge.data.goal" />
              <div className="item info">
                <span className="text big">{__('GOALS')}</span><br />
                <span>{__('COMPLETED')}</span><br />
                {/* <span ng-if="challenge.data.deadline">by {{ challenge.data.deadline | utcDate }}</span>*/}
              </div>
            </div>
            <div className="items" ng-if="!challenge.data.id">
              <span className="text big">{__('No Challenge')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
