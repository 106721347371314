import React, {ReactElement} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import {Modal} from 'components/Modal';

/**
 * Shows a confirmation modal
 *
 * This is not actually a React component. Having an imperative confirm
 * function is more convenient, as no state management is needed in the
 * consuming component to show/hide the modal.
 *
 * @param {function} onConfirm
 * @param {string} title
 * @param {string} message
 */
export function confirmModal(
    onConfirm: () => any,
    title = 'Confirm',
    message: string | ReactElement = 'Please confirm your choice',
): Promise<void> {
  return new Promise((resolve) => {
    confirmAlert({
      overlayClassName: 'hide',

      // eslint-disable-next-line react/prop-types,react/display-name
      customUI: ({onClose}) => {
        return (
          <Modal
            title={title}
            onCloseRequested={onClose}
            hideCloseButton={true}

            body={
              <span>{message}</span>
            }

            footer={
              <div className="buttons btn-group">
                <button
                  className="btn btn-success btn-sucess"
                  onClick={() => {
                    onConfirm();
                    onClose();
                    resolve();
                  }}
                  aria-label="Close"
                >
                  <i className="icon-ok-circled" />
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => {
                    onClose();
                    resolve();
                  }}
                  aria-label="Cancel"
                >
                  <i className="icon-cancel" />
                </button>
              </div>
            }
          />
        );
      },
    });
  });
}
