import React, {createContext, PropsWithChildren, useContext} from 'react';
import {School} from 'api/schools/School';
import {useSessionContext} from 'contexts/SessionContext';
import {SuccessResponse} from 'api/util/SuccessResponse';

export interface SchoolContextInterface {
  authorizedSchools: School[],
  selectedSchool?: School,
  setSelectedSchool: (
    newSchoolId: number | undefined
  ) => Promise<SuccessResponse<undefined>>,
}

export const SchoolContext = createContext<SchoolContextInterface | undefined>(
    undefined,
);

export const useSchoolContext = () => {
  const context = useContext(SchoolContext);
  if (context === undefined) {
    throw new Error(
        useSchoolContext.name + ' must be used in a context provider',
    );
  }
  return context;
};

export const SchoolContextProvider = (props: PropsWithChildren<{}>) => {
  const {sessionDetails, setSchool} = useSessionContext();

  return (
    <SchoolContext.Provider
      value={{
        authorizedSchools: sessionDetails?.schools ?? [],
        selectedSchool: sessionDetails?.selectedSchool,
        setSelectedSchool: setSchool,
      }}
    >
      {props.children}
    </SchoolContext.Provider>
  );
};
