import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {Email} from 'api/email/Email';
import {dateFromTimestamp} from 'api/util/dates';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {useAuthContext} from 'contexts/AuthContext';
import {DateInput} from 'components/forms/DateInput';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useApiContext} from 'api/ApiContext';

export interface EmailInputModalProps {
  initialEmail?: Email,
  forCourseId?: number,
  onCloseRequested?: () => any,
  onCompleted?: (email?: Email) => any,
}

export const EmailInputModal = (props: EmailInputModalProps) => {
  const logger = useLogger(EmailInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {currentUser} = useAuthContext();
  const {emailsService} = useApiContext();

  const [from, setFrom] = useState<string>(props.initialEmail?.from ?? currentUser?.name ?? '');
  const [replyTo, setReplyTo] = useState<string>(props.initialEmail?.replyTo ?? currentUser?.email ?? '');
  const [subject, setSubject] = useState<string>(props.initialEmail?.subject ?? '');
  const [scheduled, setScheduled] = useState<boolean>(props.initialEmail?.scheduled ?? false);
  const [scheduledDate, setScheduledDate] =
    useState<Date | undefined>(
      props.initialEmail?.scheduleDate ? dateFromTimestamp(props.initialEmail.scheduleDate) : undefined,
    );
  const [message, setMessage] = useState<string>(props.initialEmail?.message ?? '');
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (from.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, from: true}));
    }
    if (replyTo.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, replyTo: true}));
    }
    if (subject.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, subject: true}));
    }
    if (message.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, message: true}));
    }
    if (scheduled && !scheduledDate) {
      isValid = false;
      setErrors((current: any) => ({...current, scheduledDate: true}));
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    let result = undefined;
    if (!props.initialEmail) {
      if (!props.forCourseId) {
        return;
      }

      result = await emailsService.createCourseEmail(props.forCourseId, {
        from,
        replyTo,
        subject,
        scheduled,
        scheduleDate: scheduledDate,
        message,
      });
      logger.debug('Created email', result);
    } else {
      result = await emailsService.updateEmail({
        ...props.initialEmail,
        from,
        replyTo,
        subject,
        scheduled,
        scheduleDate: scheduledDate,
        message,
      });
      logger.debug('Updated email', result);
    }

    props.onCompleted?.(result.entity);
  };

  useEffect(() => {
    validate();
  }, [from, replyTo, subject, message, scheduled, scheduledDate]);

  return <Modal
    title={__('Email')}
    onCloseRequested={props.onCloseRequested}
    className={'wide tall'}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        iconCode={''}
        text={'Send'}
        type={'submit'}
        form={'emailForm'}
      />
    }

    body={
      <form id={'emailForm'} onSubmit={submit}>
        <label className={'row-fluid'}>
          <input
            placeholder={__('From')}
            aria-label={__('From')}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            type={'text'}
            className={classNames('span12', {'invalid': errors.from})}
          />
          <span style={{display: 'none'}}>{__('From')}</span>
        </label>
        <label className={'row-fluid'}>
          <input
            placeholder={__('Reply To')}
            aria-label={__('Reply To')}
            value={replyTo}
            onChange={(e) => setReplyTo(e.target.value)}
            type={'text'}
            className={classNames('span12', {'invalid': errors.replyTo})}
          />
          <span style={{display: 'none'}}>{__('Reply To')}</span>
        </label>
        <label className={'row-fluid'}>
          <input
            placeholder={__('Subject')}
            aria-label={__('Subject')}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            type={'text'}
            className={classNames('span12', {'invalid': errors.subject})}
          />
          <span style={{display: 'none'}}>{__('Subject')}</span>
        </label>
        <div className={'row-fluid'}>
          {scheduled &&
            <label className={'span4'}>
              <DateInput
                value={scheduledDate}
                onChange={(newDate) => setScheduledDate(newDate)}
                placeholder={__('Scheduled Date')}
                aria-label={__('Scheduled Date')}
                className={classNames('span12', {'invalid': errors.scheduledDate})}
                wrapperClassName={'span12'}
              />
              <span style={{display: 'none'}}>{__('Scheduled Date')}</span>
            </label>
          }
          <label className={'span2'}>
            <input
              type={'checkbox'}
              checked={scheduled}
              aria-label="Scheduled"
              onChange={(e) => setScheduled(e.target.checked)}
            />
            Scheduled
          </label>
        </div>
        <label className={'row-fluid'}>
          <RichTextInput
            placeholder={'<i class="icon icon-edit"></i> Click to edit email message'}
            aria-label="Click to edit emial message"
            value={message}
            onChange={(newMessage) => setMessage(newMessage)}
            required={true}
          />
          <span style={{display: 'none'}}>{__('Message')}</span>
        </label>
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
