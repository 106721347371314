import dayjs from 'dayjs';

/**
 * Transforms a timestamp into a date object
 *
 * @param {number} timestamp
 * @return {Date}
 */
export function dateFromTimestamp(timestamp: number) {
  return new Date(timestamp);
}

/**
 * Gets a formatted date from the given timestamp
 *
 * @param {number} timestamp
 * @param {string} format
 * @return {string}
 */
export function formatTimestamp(
    timestamp: number,
    format: string = 'MM/DD/YY',
): string {
  return dayjs(new Date(timestamp)).format(format);
}

/**
 * Creates a human-readable time difference (eg. "2 weeks")
 *
 * @param {number} millisec
 * @param {boolean} indicator
 */
export function humanTimeDiff(millisec: number, indicator = true) {
  const seconds = (millisec / 1000).toFixed(1);
  const minutes = (millisec / (1000 * 60)).toFixed(1);
  const hours = (millisec / (1000 * 60 * 60)).toFixed(1);
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

  const timer: {time: string, units: string} = {time: '', units: ''};
  if (parseInt(seconds) < 60) {
    timer.time = seconds;
    timer.units = 'Sec';
  } else if (parseInt(minutes) < 60) {
    timer.time = minutes;
    timer.units = 'Min';
  } else if (parseInt(hours) < 24) {
    timer.time = hours;
    timer.units = 'Hrs';
  } else if (parseInt(days) < 7) {
    timer.time = days;
    timer.units = 'Days';
  } else {
    timer.time = (parseInt(days) / 7).toString();
    timer.units = 'Wks';
  }
  if (!indicator) {
    return timer;
  } else {
    return timer;
    // timer.time = $filter('number')(timer.time, 2);
    // skilltime = timer.time + ' ' + timer.units;
    // return skilltime;
  }
}
