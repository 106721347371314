import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useApiContext} from 'api/ApiContext';
import {Skill} from 'api/skills/Skill';
import {useNotificationContext} from 'contexts/NotificationContext';
import {Select} from 'components/forms/Select';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {User} from 'api/users/User';
import {useLogger} from 'logging/logging';


export interface SessionReportInputModalProps {
  onCloseRequested?: () => any,
  onCompleted?: () => any,
}

export const SessionReportInputModal = (props: SessionReportInputModalProps) => {
  const logger = useLogger(SessionReportInputModal.name);
  const {skillsService, usersService, journalService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const [studentOptions, setStudentOptions] = useState<User[]>([]);
  const [skillOptions, setSkillOptions] = useState<Skill[]>([]);
  const [studentIds, setStudentIds] = useState<number[]>([]);
  const [skillIds, setSkillIds] = useState<number[]>([]);
  const [text, setText] = useState<string>('');
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (text.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, text: true}));
    }
    if (studentIds.length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, studentIds: true}));
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    const result = await journalService.createSessionReport({
      text,
      students: studentIds.map((id) => ({id})),
      ilries: skillIds.map((id) => ({id})),
    });

    logger.debug('Created session report', result);
    if (result.ok) {
      props.onCompleted?.();
    } else {
      showErrorNotification(__('Error'), __('Failed to save session report'));
    }
  };

  const loadStudents = async () => {
    const fetchedUsers = await usersService.fetchUsers();
    setStudentOptions(fetchedUsers);
  };

  const loadSkills = async () => {
    const fetchedSkills = await skillsService.fetchSkills({});
    setSkillOptions(fetchedSkills);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        loadStudents(),
        loadSkills(),
      ]);
      validate();
    })();
  }, []);

  return <Modal
    title={__('Add Session Report')}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        type={'submit'}
        form={'sessionReportForm'}
      />
    }

    body={
      <form id={'sessionReportForm'} onSubmit={submit}>
        <label className={'row-fluid'}>
          <Select
            placeholder={__('Students')}
            options={studentOptions.map((student) => ({
              value: student.id.toString(),
              label: student.name,
            }))}
            multiple={true}
            clearable={false}
            value={studentIds.map((id) => id.toString())}
            onChange={(newValue) => {
              const newIds = (newValue as string[]).map((id) => parseInt(id));
              setStudentIds(newIds);
            }}
          />
          <span style={{display: 'none'}}>Students</span>
        </label>

        <label className={'row-fluid'}>
          <Select
            placeholder={__('Skills')}
            options={skillOptions.map((skill) => ({
              value: skill.id.toString(),
              label: skill.name,
            }))}
            multiple={true}
            clearable={false}
            value={skillIds.map((id) => id.toString())}
            onChange={(newValue) => {
              const newIds = (newValue as string[]).map((id) => parseInt(id));
              setSkillIds(newIds);
            }}
          />
          <span style={{display: 'none'}}>Skills</span>
        </label>

        <label className={'row-fluid'}>
          <RichTextInput
            placeholder={__('Comment')}
            value={text}
            onChange={(newValue) => setText(newValue)}
            required={errors.text}
          />
          <span style={{display: 'none'}}>Comment</span>
        </label>
      </form>
    }

    footer={<InputPlaceholderMirror />}
  />;
};
