import React, {useEffect} from 'react';
import {SaveResourceDto} from 'api/resources/Resource';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {FileResourceView} from 'views/resources/FileResourceView';
import {ImageResourceView} from 'views/resources/ImageResourceView';
import {QuizDetailsModal} from 'views/quizzes/QuizDetailsModal';
import {QuizSolutionDetailsModal} from 'views/quizzes/QuizSolutionDetailsModal';
import {useLogger} from 'logging/logging';
import {Modal} from 'components/Modal';
import {VideoResourceView} from 'views/resources/VideoResourceView';
import {LinkResourceView} from 'views/resources/LinkResourceView';
import {CollageModal} from 'views/collages/CollageModal';
import {Collage} from 'api/collages/Collage';
import {AssessmentResourceView} from 'views/resources/AssessmentResourceView';

/**
 * Renders the correct view for a given resource's type
 */

export interface ResourceViewProps {
  resource: SaveResourceDto,
  onCloseRequested?: () => any,
  onUpdated?: () => any,
}

export const ResourceView = (props: ResourceViewProps) => {
  const logger = useLogger(ResourceView.name);

  useEffect(() => {
    logger.debug('Viewing resource', props.resource);
  }, [props.resource]);

  switch (props.resource.type) {
    case ResourceType.File:
      return <FileResourceView resource={props.resource} onCloseRequested={props.onCloseRequested} />;
    case ResourceType.Image:
      return <ImageResourceView resource={props.resource} onCloseRequested={props.onCloseRequested} />;
    case ResourceType.Quiz:
      return <QuizDetailsModal onCloseRequested={props.onCloseRequested} quizId={props.resource.resource.id} />;
    case ResourceType.QuizSolution:
      if (props.resource.id) {
        return <QuizSolutionDetailsModal
          solutionId={props.resource.id}
          onCloseRequested={props.onCloseRequested}
          onGraded={props.onUpdated}
        />;
      } else {
        return <Modal body={<p>Failed to load resource.</p>} onCloseRequested={props.onCloseRequested}/>;
      }
    case ResourceType.Assessment:
      return <AssessmentResourceView
        assessmentId={props.resource.id ?? -1}
        onCloseRequested={props.onCloseRequested}
      />;
    case ResourceType.Movie:
      return <VideoResourceView resource={props.resource} onCloseRequested={props.onCloseRequested} />;
    case ResourceType.Link:
      return <LinkResourceView resource={props.resource} onCloseRequested={props.onCloseRequested} />;
    case ResourceType.Collage:
      return <CollageModal
        collage={props.resource as any as Collage}
        onCloseRequested={props.onCloseRequested}
      />;
    default:
      return <Modal body={<p>Failed to load resource.</p>} onCloseRequested={props.onCloseRequested}/>;
  }
};
