import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

export interface ButtonGroupProps {
  className?: string,
}

export const ButtonGroup = (props: PropsWithChildren<ButtonGroupProps>) => {
  return (
    <div className={classNames('btn-group', props.className)}>
      {props.children}
    </div>
  );
};
