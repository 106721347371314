import React, {ReactElement, useState} from 'react';
import classNames from 'classnames';

export interface AccordionViewProps {
  header?: ReactElement,
  sections?: {
    heading: ReactElement,
    body: ReactElement,
  }[],
}

export const AccordionView = (props: AccordionViewProps) => {
  const [openSection, setOpenSection] = useState<number | undefined>(undefined);

  const toggleOpenSection = (index: number) => {
    setOpenSection((current) => index === current ? undefined : index);
  };

  return (
    <div className={'list'}>
      <div className={'accordion'}>
        <div className={'title'}>
          {props.header}
        </div>
        {props.sections?.map((section, i) => (
          <div
            key={i}
            className={classNames('accordion-group', {'expanded': i === openSection}, {'colapsed': i !== openSection})}
          >
            <div className={'accordion-heading'} onClick={() => toggleOpenSection(i)}>
              <a className={'accordion-toggle'}>
                {section.heading}
              </a>
            </div>
            <div className={classNames('accordion-body collapse', {'in': i === openSection})}>
              <div className={'accordion-inner'}>
                {section.body}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
