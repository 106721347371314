import React, {useEffect, useState} from 'react';
import {Select, SelectBaseProps} from 'components/forms/Select';
import {useApiContext} from 'api/ApiContext';
import {localizeText} from 'i18n/localize';
import {Product} from 'api/products/Product';

export interface ProductPickerProps extends SelectBaseProps {
  value?: number | number[],
  onChange?: (newValue: number | number[]) => any,
}

export const ProductPicker = (props: ProductPickerProps) => {
  const {productsService} = useApiContext();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    (async () => {
      setProducts(await productsService.fetchProducts());
    })();
  }, []);

  return (
    <Select
      placeholder={props.placeholder ?? localizeText('Product')}
      multiple={props.multiple ?? true}
      value={
        props.multiple ?
          (props.value as number[])?.map((value) => value.toString()) :
          (props.value as number)?.toString()
      }
      options={products.map((product) => ({
        label: product.name,
        value: product.id.toString(),
      }))}
      onChange={(newValue) => {
        if (!props.multiple) {
          props.onChange?.(parseInt(newValue as string));
        } else {
          props.onChange?.(
              (newValue as string[]).map((value) => parseInt(value)),
          );
        }
      }}
    />
  );
};
