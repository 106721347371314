import {Appointment, CreateAppointmentDto, UpdateAppointmentDto} from 'api/appointments/Appointment';
import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API appointment functions
 */
export class AppointmentsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches appointments scheduled for a given course
   *
   * @param {number} courseId
   * @return {Promise<Appointment[]>}
   */
  async fetchAppointmentsForCourse(courseId: number): Promise<Appointment[]> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.List,
        {course: courseId},
    );
    return response.data.list;
  }

  /**
   * Fetches appointments scheduled for a given student
   *
   * @param {number} studentId
   * @return {Promise<Appointment[]>}
   */
  async fetchAppointmentsForStudent(studentId: number): Promise<Appointment[]> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.List,
        {id: studentId},
    );
    return response.data.list;
  }

  /**
   * Fetches a single appointment by ID
   *
   * @param {number} id
   * @return {Promise<Appointment>}
   */
  async fetchAppointment(id: number): Promise<Appointment> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.Get,
        {id},
    );
    return response.data;
  }

  /**
   * Creates a new appointment
   *
   * @param {CreateAppointmentDto} appointment
   * @return {Promise<SuccessResponse<Appointment>>}
   */
  async createAppointment(appointment: CreateAppointmentDto): Promise<SuccessResponse<Appointment>> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.Save,
        appointment,
    );
    return response.data;
  }

  /**
   * Updates an appointment
   *
   * @param {UpdateAppointmentDto} appointment
   * @return {Promise<SuccessResponse<Appointment>>}
   */
  async updateAppointment(appointment: UpdateAppointmentDto): Promise<SuccessResponse<Appointment>> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.Save,
        appointment,
    );
    return response.data;
  }

  /**
   * Deletes an appointment by ID
   *
   * @param {number} id
   * @return {Promise<SuccessResponse<Appointment>>}
   */
  async deleteAppointment(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(
        ApiRoute.Appointments + ApiRoute.Delete,
        {id},
    );
    return response.data;
  }
}
