import {confirmModal} from 'components/confirmModal';

/**
 * Shows a delete confirmation modal
 *
 * This is not actually a React component. Having an imperative confirm
 * function is more convenient, as no state management is needed in the
 * consuming component to show/hide the modal.
 *
 * @param {function} onConfirm
 * @param {string} title
 * @param {string} message
 */
export function confirmDelete(
    onConfirm: () => any,
    title = 'Delete',
    message = 'You are about to delete an item. Are you sure?',
) {
  confirmModal(onConfirm, title, message);
}
