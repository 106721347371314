export enum ResourceType {
  File = 'FILE',
  Quiz = 'QUIZ',
  QuizSolution = 'QUIZ_SOLUTION',
  Survey = 'SURVEY',
  Collage = 'COLLAGE',
  Image = 'IMAGE',
  Movie = 'MOVIE',
  Link = 'LINK',
  Webinar = 'WEBINAR',
  Assessment = 'ASSESSMENT',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE',
}
