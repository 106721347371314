import React from 'react';
import {SyllabiList} from 'views/syllabi/SyllabiList';
import {DragDropContext} from 'react-beautiful-dnd';

export const SyllabiListView = () => {
  const onDragEnd = () => {
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <SyllabiList />
    </DragDropContext>
  );
};
