import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react';
import {JournalService} from 'api/journal/JournalService';
import {AuthService} from 'api/auth/AuthService';
import {SessionService} from 'api/sessions/SessionService';
import {AchievementsService} from 'api/achievements/AchievementsService';
import {AppointmentsService} from 'api/appointments/AppointmentsService';
import {CategoriesService} from 'api/categories/CategoriesService';
import {ChallengesService} from 'api/challenges/ChallengesService';
import {CommunityItemsService} from 'api/community/CommunityItemsService';
import {CoursesService} from 'api/courses/CoursesService';
import {EmailsService} from 'api/email/EmailsService';
import {FileUploadsService} from 'api/file-uploads/FileUploadsService';
import {GoalsService} from 'api/goals/GoalsService';
import {LessonsService} from 'api/lessons/LessonsService';
import {ProductsService} from 'api/products/ProductsService';
import {QuizzesService} from 'api/quizzes/QuizzesService';
import {ResourcesService} from 'api/resources/ResourcesService';
import {SchoolsService} from 'api/schools/SchoolsService';
import {SkillsService} from 'api/skills/SkillsService';
import {StatsService} from 'api/stats/StatsService';
import {SurveysService} from 'api/surveys/SurveysService';
import {SyllabiService} from 'api/syllabi/SyllabiService';
import {UsersService} from 'api/users/UsersService';
import {VersionsService} from 'api/versions/VersionsService';
import {EntityListService} from 'api/lists/EntityListService';
import axios, {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {StatusCodes} from 'http-status-codes';
import {toast} from 'react-toastify';
import {Notification, NotificationVariant} from 'components/Notification';
import {InstancesService} from 'api/instances/InstancesService';
import {CollagesService} from 'api/collages/CollagesService';
import {TemplatesService} from 'api/templates/TemplatesService';
import {ViewingHistoryService} from 'api/viewing-history/ViewingHistoryService';
import {ApiLoggingService} from 'api/logging/ApiLoggingService';
import {AssessmentsService} from 'api/assessments/AssessmentsService';
import {UserFilesService} from 'api/files/UserFilesService';
import {CertificatesService} from 'api/certificates/CertificatesService';

/**
 * An API context provides API dependencies to consuming components.
 *
 * This is a form of a service locator, so components can locate services
 * needed to interact with the API without being coupled to concrete service classes.
 * (Eg. The context can be mocked in testing to provide test-double services.)
 */
export interface ApiContextInterface {
  entityListService: EntityListService,
  authService: AuthService,
  achievementsService: AchievementsService,
  appointmentsService: AppointmentsService,
  categoriesService: CategoriesService,
  challengesService: ChallengesService,
  communityItemsService: CommunityItemsService,
  coursesService: CoursesService,
  emailsService: EmailsService,
  fileUploadsService: FileUploadsService,
  goalsService: GoalsService,
  journalService: JournalService,
  lessonsService: LessonsService,
  productsService: ProductsService,
  quizzesService: QuizzesService,
  resourcesService: ResourcesService,
  schoolsService: SchoolsService,
  sessionService: SessionService,
  skillsService: SkillsService,
  statsService: StatsService,
  surveysService: SurveysService,
  syllabiService: SyllabiService,
  usersService: UsersService,
  versionsService: VersionsService,
  instancesService: InstancesService,
  collagesService: CollagesService,
  templatesService: TemplatesService,
  viewingHistoryService: ViewingHistoryService,
  apiLoggingService: ApiLoggingService,
  assessmentsService: AssessmentsService,
  userFilesService: UserFilesService,
  certificatesService: CertificatesService,
}

export const ApiContext = createContext<ApiContextInterface | undefined>(undefined);

export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApiContext must be used in a provider');
  }
  return context;
};

/**
 * Provides default API services
 *
 * @param {PropsWithChildren<{}>} props
 * @constructor
 */
export const ApiContextProvider = (props: PropsWithChildren<{}>) => {
  const httpClient = useMemo<AxiosInstance>(() => {
    const client = axios.create({
      baseURL: ApiRoute.BaseUrl,
    });

    // set up user notifications for server errors and unauthorized requests
    client.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
            toast(<Notification
              variant={NotificationVariant.Error}
              title={'Backend Error'}
              message={'Connection to the server returned an error.'}
            />);
          }
          if (error.response.status === StatusCodes.UNAUTHORIZED || error.response.status === StatusCodes.FORBIDDEN) {
            toast(<Notification
              variant={NotificationVariant.Error}
              title={'Access Denied'}
              message={'You don\'t have permission to access this resource.'}
            />);
          }
          if (error.response.status === StatusCodes.CONFLICT) {
            toast(<Notification
              variant={NotificationVariant.Error}
              title={'Validation Error'}
              message={error.response.data.message}
            />);
          }

          return Promise.reject(error);
        },
    );

    return client;
  }, []);

  const entityListService = useMemo<EntityListService>(() => new EntityListService(httpClient), []);

  return (
    <ApiContext.Provider
      value={{
        entityListService,
        authService: new AuthService(httpClient),
        achievementsService: new AchievementsService(httpClient),
        appointmentsService: new AppointmentsService(httpClient),
        categoriesService: new CategoriesService(httpClient, entityListService),
        challengesService: new ChallengesService(httpClient),
        communityItemsService: new CommunityItemsService(httpClient),
        coursesService: new CoursesService(httpClient, entityListService),
        emailsService: new EmailsService(httpClient),
        fileUploadsService: new FileUploadsService(httpClient),
        goalsService: new GoalsService(httpClient),
        journalService: new JournalService(httpClient, entityListService),
        lessonsService: new LessonsService(httpClient, entityListService),
        productsService: new ProductsService(httpClient),
        quizzesService: new QuizzesService(httpClient),
        resourcesService: new ResourcesService(httpClient),
        schoolsService: new SchoolsService(httpClient),
        sessionService: new SessionService(httpClient),
        skillsService: new SkillsService(httpClient),
        statsService: new StatsService(httpClient),
        surveysService: new SurveysService(httpClient, entityListService),
        syllabiService: new SyllabiService(httpClient),
        usersService: new UsersService(httpClient, entityListService),
        versionsService: new VersionsService(httpClient),
        instancesService: new InstancesService(httpClient),
        collagesService: new CollagesService(httpClient, entityListService),
        templatesService: new TemplatesService(httpClient, entityListService),
        viewingHistoryService: new ViewingHistoryService(httpClient),
        apiLoggingService: new ApiLoggingService(httpClient),
        assessmentsService: new AssessmentsService(httpClient),
        userFilesService: new UserFilesService(httpClient),
        certificatesService: new CertificatesService(httpClient),
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};
