import React, {useEffect, useState} from 'react';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {__, localizeText} from 'i18n/localize';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {confirmModal} from 'components/confirmModal';
import {Goal, GoalWithRelations} from 'api/goals/Goal';
import {GoalsEmptyView} from 'views/goals/GoalsEmptyView';
import {useLocalStorage} from 'hooks/useLocalStorage';
import {Course} from 'api/courses/Course';
import {GoalQuickView} from 'views/goals/GoalQuickView';
import {LearningView} from 'views/learning/LearningView';
import {ActionStepWithRelations} from 'api/goals/ActionStep';
import {CheckBox} from 'components/forms/CheckBox';
import classNames from 'classnames';
import {FilterCourse} from 'api/goals/FilterCourse';
import {FilterCategory} from 'api/goals/FilterCategory';
import {Tooltip} from 'components/Tooltip';
import {CourseDetailsModal} from 'views/courses/CourseDetailsModal';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {useSessionContext} from 'contexts/SessionContext';
import {UserRoleType} from 'api/users/UserRole';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {ActionStepCompletionModal} from 'views/learning/ActionStepCompletionModal';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {QuizSolutionDetailsModal} from 'views/quizzes/QuizSolutionDetailsModal';
import {QuizSolution} from 'api/quizzes/QuizSolution';
import {useQueryParam} from 'use-query-params';
import {WorkingOnGoalsModal} from './WorkingOnGoalsModal';

/**
 * A student's goals dashboard view
 */

const STORAGE_KEY_HIDE_COMPLETE = 'goalsDashboard-hideComplete';

export const GoalsDashboardView = () => {
  const logger = useLogger(GoalsDashboardView.name);
  const {goalsService} = useApiContext();
  const {sessionDetails} = useSessionContext();
  const {activeUser} = useUserSwitchingContext();
  const {push: pushRoute} = useHistory();

  const [firstGoal] = useQueryParam('firstGoal');
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [goals, setGoals] = useState<GoalWithRelations[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [hideCompletedGoals, setHideCompletedGoals] = useLocalStorage<boolean>(STORAGE_KEY_HIDE_COMPLETE, true);
  const [viewedGoalStep, setViewedGoalStep] = useState<ActionStepWithRelations | undefined>(undefined);
  const [isFetchingSidebar, setIsFetchingSidebar] = useState<boolean>(true);
  const [sidebarCourses, setSidebarCourses] = useState<FilterCourse[]>([]);
  const [sidebarCategories, setSidebarCategories] = useState<FilterCategory[]>([]);
  const [filterCourses, setFilterCourses] = useState<FilterCourse[]>([]);
  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([]);
  const [showDetailsCourse, setShowDetailsCourse] = useState<Course | undefined>(undefined);
  const [showCreateGoal, setShowCreateGoal] = useState<boolean>(false);
  const [shownQuizSolution, setShownQuizSolution] = useState<QuizSolution | undefined>(undefined);
  const [showFirstGoalVideo, setShowFirstGoalVideo] = useState<boolean>(false);

  const courses = goals.filter((goal) => goal.course !== undefined).map((goal) => goal.course);

  const uniqueCourses: Course[] = [];
  courses.forEach((course) => {
    if (course && !uniqueCourses.find((c) => c.id === course.id)) {
      uniqueCourses.push(course);
    }
  });

  const uniqueCoursesWithGoals: {course: Course, goals: Goal[]}[] = uniqueCourses.map((course) => {
    const courseGoals = goals.filter((g) => g.course?.id === course.id);
    return {
      course,
      goals: courseGoals,
    };
  });

  const personalGoals = goals.filter((goal) => goal.course === null);

  const loadGoals = async () => {
    setIsFetching(true);
    const fetchedGoals = await goalsService.fetchPersonalGoals({
      search: searchFilter,
      filters: {
        course: filterCourses.map((c) => c.id),
        category: filterCategories.map((c) => c.id),
        active: hideCompletedGoals,
      },
    });
    setGoals(fetchedGoals);
    logger.debug('Fetched personal goals', fetchedGoals);
    setIsFetching(false);
  };

  const fetchSidebar = async () => {
    setIsFetchingSidebar(true);
    const filterResult = await goalsService.fetchGoalsFilter();
    setSidebarCourses(filterResult.courses);
    setSidebarCategories(filterResult.categories.filter((c: any) => c !== null));
    logger.debug('Fetched goals filter', filterResult);
    setIsFetchingSidebar(false);
  };

  const isCourseFilterSelected = (course: FilterCourse) => {
    return filterCourses.filter((c) => c.id === course.id).length > 0;
  };

  const toggleCourseFilter = (course: FilterCourse) => {
    if (isCourseFilterSelected(course)) {
      setFilterCourses((current) =>
        current.filter((c) => c.id !== course.id),
      );
    } else {
      setFilterCourses((current) => [
        ...current,
        course,
      ]);
    }
  };

  const isCategoryFilterSelected = (category: FilterCategory) => {
    return filterCategories.filter((c) => c.id === category.id).length > 0;
  };

  const toggleCategoryFilter = (category: FilterCategory) => {
    if (isCategoryFilterSelected(category)) {
      setFilterCategories((current) =>
        current.filter((c) => c.id !== category.id),
      );
    } else {
      setFilterCategories((current) => [
        ...current,
        category,
      ]);
    }
  };

  const createNewGoal = async () => {
    if (sessionDetails?.role && [UserRoleType.Student, UserRoleType.IlStudent].includes(sessionDetails?.role.type)) {
      confirmModal(() => {
        pushRoute(AppRoute.DashboardContext + AppRoute.PlanOverview);
      }, 'Create New Goal', 'Do you want to create a new goal?');
    } else {
      setShowCreateGoal(true);
    }
  };

  const toggleActionStepComplete = (
      actionStepId: number,
      complete: boolean = true,
      submitted: boolean = false,
  ) => {
    setGoals((current) => {
      const goalsCopy = [...current];
      goalsCopy.forEach((goal) => {
        let goalStepsUpdated = false;

        goal.steps?.forEach((step) => {
          if (step.id === actionStepId) {
            step.completed = complete && !submitted;
            step.submited = submitted;
            step.resources.forEach((resource) => resource.completed = true);
            goalStepsUpdated = true;
          }
        });

        if (goalStepsUpdated) {
          const activeSteps = goal.steps?.filter((step) => step.enabled) ?? [];
          const goalStepCount = activeSteps.length;
          const completedSteps = activeSteps.filter((step) => step.completed) ?? [];
          goal.progress = goalStepCount > 0 ? (completedSteps.length / goalStepCount * 100) : 0;
        }
      });

      return goalsCopy;
    });
  };

  const updateGoal = (updatedGoal: Goal) => {
    setGoals((current) => {
      const goalsCopy = [...current];
      goalsCopy.forEach((goal) => {
        if (goal.id === updatedGoal.id) {
          goal.name = updatedGoal.name;
        }
      });
      return goalsCopy;
    });
  };

  const removeGoal = (removeId: number) => {
    setGoals((current) => [...current].filter((goal) => goal.id !== removeId));
  };

  useEffect(() => {
    (async () => {
      await fetchSidebar();
    })();

    if (firstGoal === 'true') {
      setShowFirstGoalVideo(true);
    }
  }, []);

  useEffect(() => {
    if (sidebarCategories) {
      setFilterCategories(sidebarCategories);
    }
  }, [sidebarCategories]);

  useEffect(() => {
    if (sidebarCourses) {
      setFilterCourses(sidebarCourses);
    }
  }, [sidebarCourses]);

  useEffect(() => {
    (async () => {
      if (!isFetchingSidebar) {
        await loadGoals();
      }
    })();
  }, [filterCourses, filterCategories, searchFilter, hideCompletedGoals]);

  return (
    <SidePanelPageView
      containerClassName={'column-layout'}
      firstColumnClassName={'left-column'}
      startPanelClosed={true}

      panelView={
        <>
          {showFirstGoalVideo && <WorkingOnGoalsModal onCloseRequested={() => setShowFirstGoalVideo(false)} />}

          {isFetchingSidebar && <LoadingIndicator background={'none'}/>}

          {!isFetchingSidebar &&
          <div className="pane with-inner-menu">
            <div className="body" id="category-filter">
              <div className="head">
                <span>Courses</span>
              </div>
              {sidebarCourses.sort((a, b) => a.name < b.name ? -1 : 1).map((course) => (
                <ul className="category" key={course.id}>
                  {(!hideCompletedGoals || course.activeGoals > 0) &&
                    <li>
                      <div className="pivot">
                        <CheckBox checked={isCourseFilterSelected(course)} onClick={() => toggleCourseFilter(course)} />
                        {course.activeGoals > 0 && <span className="active-goals">{course.activeGoals}</span>}
                        {course.name !== 'Individual' ? course.name : __('Individual Goals')}
                      </div>
                    </li>
                  }
                </ul>
              ))}

              <div className="inner-head">
                <span>{__('Learning Track')}</span>
              </div>
              {sidebarCategories.sort((a, b) => a.name < b.name ? -1 : 1).map((category) => (
                <ul className="category" key={category.id}>
                  {(!hideCompletedGoals || category.activeGoals > 0) &&
                    <li>
                      <div className="pivot">
                        <CheckBox
                          checked={isCategoryFilterSelected(category)}
                          onClick={() => toggleCategoryFilter(category)}
                        />
                        {category.activeGoals > 0 && <span className="active-goals">{category.activeGoals}</span>}
                        {category.icon &&
                          <span
                            className={classNames('icon', category.icon)}
                            style={{backgroundColor: category.color}}
                          />
                        }
                        {' '}
                        {category.name}
                      </div>
                    </li>
                  }
                </ul>
              ))}
            </div>
          </div>
          }
        </>
      }

      mainView={
        <div className="pane with-inner-menu">
          <div className="body">
            {viewedGoalStep && viewedGoalStep.resources.length > 0 &&
               <LearningView
                 resourceId={viewedGoalStep.resources[0].id}
                 onCloseRequested={() => setViewedGoalStep(undefined)}
                 onCompleted={(resource) => {
                   toggleActionStepComplete(
                       viewedGoalStep.id,
                       !resource?.manuallyGraded,
                       resource?.manuallyGraded,
                   );
                   setViewedGoalStep(undefined);
                   if (resource && resource.type === ResourceType.QuizSolution) {
                     setShownQuizSolution(resource as any as QuizSolution);
                   }
                 }}
               />
            }

            {viewedGoalStep && viewedGoalStep.resources.length < 1 &&
              <ActionStepCompletionModal
                actionStep={viewedGoalStep}
                onCloseRequested={() => setViewedGoalStep(undefined)}
                onComplete={() => {
                  toggleActionStepComplete(viewedGoalStep.id, true);
                  setViewedGoalStep(undefined);
                }}
                onReopen={() => {
                  toggleActionStepComplete(viewedGoalStep.id, false);
                  setViewedGoalStep(undefined);
                }}
              />
            }

            {shownQuizSolution &&
              <QuizSolutionDetailsModal
                solutionId={shownQuizSolution.id}
                onCloseRequested={() => setShownQuizSolution(undefined)}
              />
            }

            {showDetailsCourse &&
              <CourseDetailsModal
                courseId={showDetailsCourse.id}
                onCloseRequested={() => setShowDetailsCourse(undefined)}
              />
            }

            {showCreateGoal &&
              <GoalInputModal
                onCompleted={async () => {
                  setShowCreateGoal(false);
                  await loadGoals();
                }}
                onCloseRequested={() => setShowCreateGoal(false)}
                studentId={activeUser?.id ?? sessionDetails?.identity?.id ?? -1}
              />
            }

            <ContextMenu>
              <SearchInput value={searchFilter} onChange={(newValue) => setSearchFilter(newValue)} />
              <Tooltip title={__('Hide complete goals')}>
                <a
                  className="btn btn-mini"
                  onClick={() => setHideCompletedGoals((current) => !current)}
                  aria-label="Hide Completed Goals"
                >
                  <i className={hideCompletedGoals ? 'icon-eye-off' : 'icon-eye'} />
                </a>
              </Tooltip>
              <AccessControlled feature={AppFeature.DashboardGoalsEdit}>
                <ButtonAdd tooltip={__('Create Goal')} onClick={createNewGoal} />
              </AccessControlled>
            </ContextMenu>

            {(isFetching || isFetchingSidebar) &&
              <LoadingIndicator message={'Loading User Goals'} />
            }

            {!isFetching && !isFetchingSidebar &&
              <>
                {goals.length < 1 &&
                  <GoalsEmptyView />
                }

                {goals.length > 0 &&
                  <div role="list" style={{marginTop: 40}}>
                    {uniqueCoursesWithGoals.map((courseWithGoals, i) => (
                      <div key={i} className="goal-course repeat-animation animate">
                        <div className="course-title">
                          <div className="icons">
                            <Tooltip title={__('Show course info')}>
                              <div
                                className="info"
                                onClick={() => setShowDetailsCourse(courseWithGoals.course)}
                                aria-label="show course details"
                              >
                                <i className="icon-info" />
                              </div>
                            </Tooltip>
                          </div>
                          {courseWithGoals.course.name}
                        </div>

                        {courseWithGoals.goals.map((goal) => (
                          <GoalQuickView
                            key={goal.id}
                            goal={goal}
                            onStepSelected={(step) => setViewedGoalStep(step)}
                            onChanged={(newGoal) => newGoal ? updateGoal(newGoal) : {}}
                            onDeleted={() => removeGoal(goal.id)}
                          />
                        ))}
                      </div>
                    ))}

                    {personalGoals.length > 0 &&
                      <div className="goal-course">
                        <div className="course-title">{localizeText('Individual Goals')}</div>
                        {personalGoals.map((goal) => (
                          <GoalQuickView
                            goal={goal}
                            key={goal.id}
                            onStepSelected={(step) => setViewedGoalStep(step)}
                            onChanged={(newGoal) => newGoal ? updateGoal(newGoal) : {}}
                            onDeleted={() => removeGoal(goal.id)}
                          />
                        ))}
                      </div>
                    }
                  </div>
                }
              </>
            }
          </div>
        </div>
      }
    />
  );
};
