import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useLogger} from 'logging/logging';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {GoalRepeatInterval} from 'api/goals/GoalRepeatInterval.enum';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import classNames from 'classnames';
import {useApiContext} from 'api/ApiContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {CreateGoalDto, Goal, GoalWithRelations} from 'api/goals/Goal';
import {GoalType} from 'api/goals/GoalType.enum';
import {ActionStepInputModal} from 'views/action-steps/ActionStepInputModal';
import {LessonSearchModal} from 'views/lessons/LessonSearchModal';
import {Lesson, LessonWithRelations} from 'api/lessons/Lesson';
import {confirmDelete} from 'components/confirmDelete';
import {Tooltip} from 'components/Tooltip';
import {ActionStepType} from 'api/goals/ActionStepType.enum';
import {SortableActionStepList} from 'views/action-steps/SortableActionStepList';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {confirmModal} from 'components/confirmModal';
import {SaveActionStepDto} from 'api/goals/ActionStep';

export interface GoalInputModalProps {
  initialGoalId?: number,
  initialLesson?: LessonWithRelations,
  studentId?: number,
  goalCourseId?: number,
  syllabusId?: number,
  onCloseRequested?: () => any,
  onCompleted?: (newGoal?: Goal) => any,
}

export const GoalInputModal = (props: GoalInputModalProps) => {
  const logger = useLogger(GoalInputModal.name);
  const {goalsService, lessonsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const isEditing = props.initialGoalId !== undefined;

  const [activeTab, setActiveTab] = useState<number>(0);
  const [initialGoal, setInitialGoal] = useState<GoalWithRelations | undefined>(undefined);
  const [goalUnderEdit, setGoalUnderEdit] = useState<CreateGoalDto>({
    name: '',
    description: props.initialLesson?.text ?? undefined,
    type: props.goalCourseId ? GoalType.Course : GoalType.User,
    steps: props.initialLesson?.steps ?
      props.initialLesson.steps.map((lessonStep) => ({
        ...lessonStep,
        id: undefined,
        resources: lessonStep.resources.map((resource) => ({
          ...resource,
          id: undefined,
          completed: false,
          completeDate: undefined,
        })),
      })) :
      [],
    category: props.initialLesson ? props.initialLesson.category : undefined,
    color: props.initialLesson ? props.initialLesson.category.color : undefined,
    icon: props.initialLesson ? props.initialLesson.category.icon : undefined,
  });
  const [errors, setErrors] = useState<any>({});
  const [showAddActionStep, setShowAddActionStep] = useState<boolean>(false);
  const [showLessonSearch, setShowLessonSearch] = useState<boolean>(false);
  const [selectedLessons, setSelectedLessons] = useState<Lesson[]>(props.initialLesson ? [props.initialLesson] : []);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (goalUnderEdit.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (goalUnderEdit.category === undefined) {
      isValid = false;
      setErrors((current: any) => ({...current, category: true}));
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    let goalType = GoalType.User;
    if (props.goalCourseId) {
      goalType = GoalType.Course;
    } else if (props.syllabusId) {
      goalType = GoalType.Syllabus;
    }

    let result;
    if (!props.initialGoalId) {
      setIsSaving(true);
      result = await goalsService.createGoal({
        ...goalUnderEdit,
        type: goalType,
        course: props.goalCourseId ? {id: props.goalCourseId} : undefined,
        syllabus: props.syllabusId ? {id: props.syllabusId} : undefined,
        owner: props.studentId ? {id: props.studentId} : undefined,
      });
      logger.debug('Created new goal', result);
    } else {
      let updateSeries = false;
      if (goalUnderEdit.schedule) {
        await confirmModal(
            () => updateSeries = true,
            __('Update Series'),
            __('update.series'),
        );
      }
      setIsSaving(true);
      result = await goalsService.updateGoal({
        ...goalUnderEdit,
        id: props.initialGoalId,
        name: goalUnderEdit.name,
        category: goalUnderEdit.category,
        steps: goalUnderEdit.steps,
        lessons: goalUnderEdit.lessons,
        type: initialGoal?.type ?? goalType,
        updateSeries,
      });
      logger.debug('Updated goal', result);
    }
    setIsSaving(false);

    props.onCompleted?.(result.entity);
  };

  useEffect(() => {
    (async () => {
      if (props.initialGoalId) {
        const fetchedGoal = await goalsService.fetchGoal(props.initialGoalId);
        setInitialGoal(fetchedGoal);
        logger.debug('Fetched goal', fetchedGoal);
      } else {
        validate();
      }
    })();
  }, []);

  useEffect(() => {
    if (initialGoal) {
      setGoalUnderEdit({
        ...initialGoal,
        steps: initialGoal.steps ?? [],
        category: initialGoal.category ?? [],
      });
    }
  }, [initialGoal]);

  useEffect(() => {
    validate();
  }, [goalUnderEdit]);

  return (
    <Modal
      title={__(isEditing ? 'Edit Goal' : 'Create Goal')}
      onCloseRequested={props.onCloseRequested}
      className={'wide tall'}
      bodyClassName={'lesson-editor'}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          type={'submit'}
          form={'goalForm'}
        />
      }

      body={
        <form onSubmit={submit} id={'goalForm'}>
          {isSaving && <LoadingIndicator fullscreen={true} />}

          {showAddActionStep &&
            <ActionStepInputModal
              type={ActionStepType.UserGoal}
              onCloseRequested={() => setShowAddActionStep(false)}
            />
          }

          {showLessonSearch &&
            <LessonSearchModal
              onCloseRequested={() => setShowLessonSearch(false)}
              onLessonsSelected={async (lessons) => {
                setSelectedLessons(lessons);
                const fetchedLessons = await lessonsService.fetchMultipleLessons(lessons.map((l) => l.id));
                setGoalUnderEdit((currentGoal) => {
                  let newSteps = [...currentGoal.steps];
                  fetchedLessons.forEach((lesson) => {
                    const stepsToAdd = lesson.steps.map((lessonStep) => {
                      const newGoalStep: SaveActionStepDto = lessonStep;
                      delete newGoalStep.id;
                      newGoalStep.resources.forEach((resource) => {
                        delete resource.id;
                        resource.completed = false;
                        delete resource.completeDate;
                      });
                      return newGoalStep;
                    });
                    newSteps = newSteps.concat(stepsToAdd);
                  });
                  return {
                    ...currentGoal,
                    category: lessons[0].category,
                    color: lessons[0].category.color,
                    icon: lessons[0].category.icon,
                    steps: newSteps,
                  };
                });
                setShowLessonSearch(false);
              }}
            />
          }

          <div className="tabNav">
            <div
              className={`tabs ${activeTab === 0 ? 'active' : ''}`}
              onClick={() => setActiveTab(0)}
              aria-label={__('Description')}
            >
              <div className="name">
                {__('Description')}
              </div>
            </div>
            <div
              className={`tabs ${activeTab === 1 ? 'active' : ''}`}
              onClick={() => setActiveTab(1)}
              aria-label={__('Lessons')}
            >
              <div className="name">
                {__('Lessons')}
              </div>
            </div>
            <div
              className={`tabs ${activeTab === 2 ? 'active' : ''}`}
              onClick={() => setActiveTab(2)}
              aria-label={__('Action Steps')}
            >
              <div className="name">
                {__('Action Steps')}
              </div>
            </div>
          </div>

          {activeTab === 0 &&
            <div style={{marginTop: 50}}>
              <div className="row-fluid">
                <div className="span12">
                  <h1>
                    <i className="icon icon-info-circled"/> Goal Definition
                  </h1>
                  <ul
                    style={{
                      margin: 10,
                      marginTop: 20,
                      borderBottom: '1px solid #a8a8a8',
                    }}
                  >
                    <li className="info">Enter the Name of your goal. This
                      should be an active statement, example &ldquo;I will
                      learn how to get places on time&rdquo;.
                    </li>
                    <li className="info">Optionally, you can add in a more
                      detailed description. We find this more useful with long
                      term goals to remind yourself why you made the goal.
                    </li>
                    <li className="info">You can also set up a schedule for
                      goals to repeat, this is great for skills that you want
                      to reenforce over time.
                    </li>
                    <li className="info">Once you are satisfied with your
                      definition, hit the Next button.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row-fluid">
                <input
                  id="goal_name"
                  type="text"
                  className={classNames(
                      'span12',
                      {'invalid': errors.name},
                  )}
                  placeholder={__('Goal Name')}
                  aria-label={__('Goal Name')}
                  value={goalUnderEdit.name}
                  onChange={(e) => setGoalUnderEdit((current) => ({
                    ...current,
                    name: e.target.value,
                  }))}
                />
                <div className="info">{__('Goal Name')}</div>
              </div>
              <div
                id="description"
                className="row-fluid bottom-offset-10"
                placeholder={__('Description')}
                aria-label={__('Description')}
              >
                <div style={{borderBottom: '1px solid #ccc'}}>
                  <RichTextInput
                    placeholder={__('Description')}
                    aria-label={__('Description')}
                    value={goalUnderEdit.description}
                    onChange={(newValue) => setGoalUnderEdit((current) => ({
                      ...current,
                      description: newValue,
                    }))}
                  />
                </div>
                <div className="info" style={{marginTop: 2}}>
                  {__('Description')}
                </div>
              </div>

              <div className="row-fluid">
                <div className="span4">
                  <select
                    id="schedule"
                    value={goalUnderEdit.schedule}
                    aria-label={__('Schedule')}
                    onChange={(e) => setGoalUnderEdit((current) => ({
                      ...current,
                      schedule: (e.target.value as GoalRepeatInterval) ?? undefined,
                    }))}
                    className="span12"
                    placeholder={__('Schedule')}
                  >
                    <option value={undefined} >-- {__('Repeat Schedule')} --</option>
                    <option value="">{__('Never')}</option>
                    <option value={GoalRepeatInterval.Weekly}>
                      {__('Weekly')}
                    </option>
                    <option value={GoalRepeatInterval.Monthly}>
                      {__('Monthly')}
                    </option>
                    <option value={GoalRepeatInterval.Quarterly}>
                      {__('Quarterly')}
                    </option>
                    <option value={GoalRepeatInterval.Annually}>
                      {__('Annually')}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          }

          {activeTab === 1 &&
          <div style={{marginTop: 50}}>
            <div className="row-fluid">
              <div className="span12">
                <h1><i className="icon icon-docs"/> Lessons</h1>
                <ul
                  style={{
                    margin: 10,
                    marginTop: 20,
                    borderBottom: '1px solid #a8a8a8',
                  }}
                >
                  <li className="info">Most goals in My Full Life use a lesson
                    as a template to develop specific skills.
                  </li>
                  <li className="info">You can add a lesson to this goal by
                    hitting the Add Lesson button and selecting a lesson.
                  </li>
                  <li className="info">Once you have added a lesson, hit the
                    Next button to customize your action steps.
                  </li>
                  <li className="info">If you are making a completely custom
                    goal, select a category that best describes the area you are
                    working on, then hit the Next button to add action steps.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row-fluid">
              {(selectedLessons.length < 1 || (goalUnderEdit.lessons?.length ?? 0) < 1) &&
                <div className="span6" style={{marginBottom: 10}}>
                  <CategoryPicker
                    placeholder={__('Category')}
                    selectedCategories={goalUnderEdit.category ? [goalUnderEdit.category] : []}
                    onChange={(newCategories) => setGoalUnderEdit((current) => ({
                      ...current,
                      category: newCategories.length > 0 ? newCategories[0] : undefined,
                      color: newCategories.length > 0 ? newCategories[0].color : undefined,
                      icon: newCategories.length > 0 ? newCategories[0].icon : undefined,
                    }))}
                    isInvalid={goalUnderEdit.category?.id === undefined}
                    clearable={false}
                  />
                </div>
              }
            </div>

            {/* <div className="row-fluid" ng-if="features.autoAssign">*/}
            {/* <input type="text" className="span12"*/}
            {/*   ng-model="data._assignDate"*/}
            {/*   date-value="edit.data.assignDate"*/}
            {/*   placeholder="Auto-assign Date"*/}
            {/*   datepicker-popup="{{ dateConfig.format }}"*/}
            {/*   datepicker-options="dateConfig"*/}
            {/* />*/}
            {/* <div className="info">*/}
            {/*   {__('Auto-assign Date')}*/}
            {/* </div>*/}
            {/* </div>*/}

            <div className={'row-fluid'}>
              <div className="list lesson_list" >
                {(selectedLessons.length < 1 || (goalUnderEdit.lessons?.length ?? 0) < 1) &&
                  <Button
                    variant={ButtonVariant.Add}
                    text={' Add Lesson'}
                    onClick={() => setShowLessonSearch(true)}
                  />
                }

                {(goalUnderEdit.lessons?.length ?? 0) != 0 &&
                  <table className="list inner-list" style={{marginTop: 22, marginBottom: 10}}>
                    <tbody>
                      {(goalUnderEdit.lessons as Lesson[])?.map((lesson) => (
                        <tr key={lesson.id}>
                          <td>
                            <span >{lesson.name}</span>
                          </td>
                          <td width="30">
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }

                <table className="list inner-list" style={{marginTop: 22, marginBottom: 10}}>
                  <tbody>
                    {selectedLessons.map((lesson, i) => (
                      <tr key={lesson.id}>
                        <td>
                          <span>{lesson.name}</span>
                        </td>
                        <td width="30">
                          <div className="btn-group">
                            <Tooltip title={__('Delete')}>
                              <a
                                aria-label="Remove Lesson"
                                onClick={() => confirmDelete(
                                    () => setSelectedLessons((current) => {
                                      return current.filter((l) => l.id !== lesson.id);
                                    }),
                                    __('Delete Lesson'),
                                    // eslint-disable-next-line max-len
                                    __('You are about to delete a lesson from the goal. Are you sure? Note: This does not remove a lesson\'s action steps.'),
                                )}
                                className="btn btn-mini"
                              >
                                <i className="icon-trash" />
                              </a>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          }

          {activeTab === 2 &&
            <div style={{marginTop: 50}}>
              <div className="row-fluid">
                <div className="span12">
                  <h1><i className="icon icon-check" /> Action Steps</h1>
                  <ul style={{margin: 10, marginTop: 20, borderBottom: '1px solid #a8a8a8'}}>
                    <li className="info">
                      Action steps are specific tasks that need to be completed to accomplish a goal.
                    </li>
                    <li className="info">
                      <b>Some action steps have skills attached. We recommend leaving these active.</b>
                    </li>
                    <li className="info">In this area you can add and edit these steps to customize your goal.</li>
                    <li className="info">
                      When you are done, hit the green checkmark at the top right to update your goal.
                    </li>
                  </ul>
                </div>
              </div>
              <SortableActionStepList
                actionSteps={goalUnderEdit.steps}
                actionStepType={ActionStepType.UserGoal}
                onChange={(newActionSteps) => setGoalUnderEdit((current) => ({
                  ...current,
                  steps: newActionSteps,
                }))}
              />
            </div>
          }

          <div className="botNav">
            {activeTab > 0 &&
              <div
                className="btn btn-info pull-left"
                onClick={() => setActiveTab((current) => current - 1)}
                aria-label="Go Back"
              >
                <i className="icon icon-left-open"/> Last
              </div>
            }
            {activeTab < 2 &&
              <div
                className="btn btn-info pull-right"
                onClick={() => setActiveTab((current) => current + 1)}
                aria-label="Next"
              >
                Next <i className="icon icon-right-open"/>
              </div>
            }
          </div>
        </form>
      }
    />
  );
};
