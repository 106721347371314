import React from 'react';
import {useSchoolContext} from 'contexts/SchoolContext';
import {Config} from 'Config';

export const MainFooter = () => {
  const {selectedSchool} = useSchoolContext();

  return (
    <footer id="footer" role={'contentinfo'}>
      <span>
        © {selectedSchool?.config?.copyright ?? Config.appCopyright}
      </span>
    </footer>
  );
};
