import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {localizeText} from 'i18n/localize';
import {Product} from 'api/products/Product';
import {useApiContext} from 'api/ApiContext';
import {formatTimestamp} from 'api/util/dates';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {ProductInputModal} from 'views/products/ProductInputModal';
import {ButtonDelete} from 'components/buttons/ButtonDelete';
import {confirmDelete} from 'components/confirmDelete';
import {ButtonEdit} from 'components/buttons/ButtonEdit';
import {Tooltip} from 'components/Tooltip';
import {ProductDetailsModal} from 'views/products/ProductDetailsModal';

export const ProductsListView = () => {
  const logger = useLogger(ProductsListView.name);
  const {productsService} = useApiContext();

  const [products, setProducts] = useState<Product[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [editProduct, setEditProduct] = useState<Product | undefined>(undefined);
  const [viewedProduct, setViewedProduct] = useState<Product | undefined>(undefined);

  const loadProducts = async () => {
    const fetchedProducts = await productsService.fetchProducts(searchFilter);
    setProducts(fetchedProducts);
    logger.debug('Fetched products', fetchedProducts);
  };

  useEffect(() => {
    (async () => {
      await loadProducts();
    })();
  }, [searchFilter]);

  return (
    <PageView>
      {editProduct &&
        <ProductInputModal
          productId={editProduct.id}
          onComplete={async () => {
            await loadProducts();
            setEditProduct(undefined);
          }}
          onCancel={() => setEditProduct(undefined)}
        />
      }

      {viewedProduct &&
        <ProductDetailsModal
          productId={viewedProduct.id}
          onCloseRequested={() => setViewedProduct(undefined)}
        />
      }

      <ContextMenu>
        <SearchInput
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
        />
        <Tooltip
          title={<div className="info-tool" style={{textAlign: 'left'}}>
            <h4>Products Page</h4>
            <hr/>
            <br />
            <p>
              This page allows users to search, view and edit Products based on user level.
              Products are a group of Categories, Lessons, Skills, Quizzes, and Badges that
              allows access contol to a specific campus.
            </p>
          </div>}
        >
          <a className="btn btn-info">
            <i className="icon-info"/>
          </a>
        </Tooltip>
        <ButtonAdd
          onClick={() => setEditProduct({} as Product)}
        />
      </ContextMenu>

      <div className={'grid'}>
        <table className={'list'}>
          <thead>
            <tr>
              <td>Name</td>
              <td>Date</td>
              <td width={35}/>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td><a onClick={() => setViewedProduct(product)}>{product.name}</a></td>
                <td>{formatTimestamp(product.mdate)}</td>
                <td>
                  <AccessControlled feature={AppFeature.EditSystemResources}>
                    <ButtonGroup>
                      <ButtonEdit
                        onClick={() => setEditProduct(product)}
                      />

                      <ButtonDelete
                        tooltip={localizeText('Delete')}
                        onClick={() => confirmDelete(async () => {
                          await productsService.deleteProduct(product.id);
                          await loadProducts();
                        })}
                      />
                    </ButtonGroup>
                  </AccessControlled>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageView>
  );
};
