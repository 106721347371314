import React, {useEffect, useState} from 'react';
import {Student} from 'api/users/Student';
import {Config} from 'Config';
import {__} from 'i18n/localize';
import {Goal} from 'api/goals/Goal';
import {Achievement} from 'api/achievements/Achievement';
import {useLogger} from 'logging/logging';
import {GoalThumbnail} from 'views/goals/GoalThumbnail';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {AchievementThumbnail} from 'views/achievements/AchievementThumbnail';
import classNames from 'classnames';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {AppointmentsListModal} from 'views/appointments/AppointmentsListModal';
import {ChallengesListModal} from 'views/challenges/ChallengesListModal';
import {useHistory} from 'react-router-dom';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {AppRoute} from 'routing/AppRoute';
import {getProfileRoute} from 'routing/routing';
import {Droppable} from 'react-beautiful-dnd';
import {AchievementDetailsModal} from 'views/achievements/AchievementDetailsModal';
import {useApiContext} from 'api/ApiContext';
import {Tooltip} from 'components/Tooltip';
import {UserAchievementRecord} from 'api/achievements/UserAchievementRecord';

export interface StudentQuickViewProps {
  student: Student,
  addedGoals?: Goal[],
  onGoalDeleted?: (goalId: number) => any,
}

export const StudentQuickView = (props: StudentQuickViewProps) => {
  const logger = useLogger(StudentQuickView.name);
  const {student} = props;
  const {setActiveUser} = useUserSwitchingContext();
  const {push: pushRoute} = useHistory();
  const {goalsService, achievementsService, usersService} = useApiContext();

  const [goals, setGoals] = useState<Goal[]>([]);
  const [achievements, setAchievements] = useState<UserAchievementRecord[]>([]);
  const [isFetchingGoals, setIsFetchingGoals] = useState<boolean>(true);
  const [isFetchingAchievements, setIsFetchingAchievements] = useState<boolean>(true);
  const [viewedGoal, setViewedGoal] = useState<Goal | undefined>(undefined);
  const [editGoal, setEditGoal] = useState<Goal | undefined>(undefined);
  const [allowSkillInventory, setAllowSkillInventory] = useState<boolean>(student.allowSkillInventory);
  const [showMeetings, setShowMeetings] = useState<boolean>(false);
  const [showChallenges, setShowChallenges] = useState<boolean>(false);
  const [showCreateGoal, setShowCreateGoal] = useState<boolean>(false);
  const [viewedAchievement, setViewedAchievement] = useState<Achievement | undefined>(undefined);

  const activeGoals = goals.filter((goal) => !goal.completed);

  const loadGoals = async () => {
    setIsFetchingGoals(true);
    const fetchedGoals = await goalsService.fetchUserGoalsById(student.id);
    setGoals(fetchedGoals);
    logger.debug('Fetched goals', {
      studentId: student.id,
      goals: fetchedGoals,
    });
    setIsFetchingGoals(false);
  };

  const loadAchievements = async () => {
    setIsFetchingAchievements(true);
    const fetchedAchievements = await achievementsService.fetchUserAchievements(student.id);
    setAchievements(fetchedAchievements);
    logger.debug('Fetched achievements', fetchedAchievements);
    setIsFetchingAchievements(false);
  };

  const toggleSkillsInventory = async () => {
    const result = await usersService.toggleUserSkillInventoryAccess(student.id, !student.allowSkillInventory);
    logger.debug('Toggled skill inventory', result);
    setAllowSkillInventory((current) => !current);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        loadGoals(),
        loadAchievements(),
      ]);
    })();
  }, []);

  useEffect(() => {
    if (props.addedGoals) {
      const newAddedGoals = props.addedGoals.filter((g) => !goals.some((g2) => g2.id === g.id));
      setGoals((current) => current.concat(newAddedGoals));
    }
  }, [props.addedGoals]);

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ?
      '#8dc73f' :
      'none',
  });

  return (
    <div className="pack student">
      {viewedGoal &&
        <GoalDetailsModal
          goalId={viewedGoal.id}
          onCloseRequested={() => setViewedGoal(undefined)}
          onEditRequested={() => {
            setEditGoal(viewedGoal);
            setViewedGoal(undefined);
          }}
          onDeleted={() => {
            setGoals((current) => current.filter((g) => g.id !== viewedGoal.id));
            props.onGoalDeleted?.(viewedGoal.id);
            setViewedGoal(undefined);
          }}
        />
      }

      {viewedAchievement &&
        <AchievementDetailsModal
          achievementId={viewedAchievement.id}
          onCloseRequested={() => setViewedAchievement(undefined)}
        />
      }

      {editGoal &&
        <GoalInputModal
          initialGoalId={editGoal.id}
          onCloseRequested={() => setEditGoal(undefined)}
        />
      }

      {showMeetings &&
        <AppointmentsListModal
          studentId={student.id}
          onCloseRequested={() => setShowMeetings(false)}
        />
      }

      {showChallenges &&
        <ChallengesListModal
          onCloseRequested={() => setShowChallenges(false)}
          studentId={student.id}
        />
      }

      {showCreateGoal &&
        <GoalInputModal
          studentId={student.id}
          onCloseRequested={() => setShowCreateGoal(false)}
          onCompleted={(newGoal) => {
            if (newGoal) {
              setGoals((current) => current.concat([newGoal]));
            }
            setShowCreateGoal(false);
          }}
        />
      }

      <div className="header">
        <div className="name">{student.name}</div>
      </div>

      <Droppable droppableId={`user-${student.id}`}>
        {(provided, snapshot) => (
          <div
            className="body"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div className="details">
              <a
                onClick={async () => {
                  await setActiveUser(student.id);
                  pushRoute(getProfileRoute(student.id) + AppRoute.Goals);
                }}
              >
                <img
                  className="circle-50"
                  src={student.image?.url ? student.image.url + '100/100' : Config.defaultUserImage}
                  alt={''}
                />
              </a>
              <div style={{marginTop: 4}}>
                <span>{__('Goals')}</span>: {goals.length}
              </div>
              <div style={{marginTop: 4}}>
                <span>{__('Completed')}</span>: {goals.filter((g) => g.completed).length}
              </div>
            </div>
            <div className="goals frame">
              <div className="title">
                <span>{__('Assigned Goals')}</span>
              </div>
              <div className="body">
                {isFetchingGoals && <div className="loader" />}
                {!isFetchingGoals && activeGoals.map((goal) => (
                  <GoalThumbnail
                    key={goal.id}
                    goal={goal}
                    onOpenRequested={() => setViewedGoal(goal)}
                    onDeleted={() => {
                      setGoals((current) => current.filter((g) => g.id !== goal.id));
                      props.onGoalDeleted?.(goal.id);
                    }}
                    draggable={true}
                  />
                ))}
              </div>
            </div>

            <div className="goals frame">
              <div className="title">
                <span>{__('Badges')}</span>
              </div>
              <div className="body">
                {!isFetchingAchievements &&
              <div>
                {achievements.map((achievementRecord) => (
                  <AchievementThumbnail
                    key={achievementRecord.id}
                    achievement={achievementRecord.achievement}
                    showProgress={true}
                    onOpenRequested={() => setViewedAchievement(achievementRecord.achievement)}
                  />
                ))}
              </div>
                }
              </div>
            </div>

            <div className="clearfix" />

            <div className="buttons btn-group">
              <AccessControlled feature={AppFeature.StudentToggleSkillInventory}>
                <Tooltip title={__('Toggle access to skill inventory')} placement={'top'}>
                  <a
                    onClick={toggleSkillsInventory}
                    className={classNames(
                        'btn btn-small btn-default',
                        {'btn-default': !allowSkillInventory},
                        {'btn-success': allowSkillInventory},
                    )}
                  >
                    <i
                      className={classNames(
                          {'icon-cancel': !allowSkillInventory},
                          {'icon-ok-circled': allowSkillInventory},
                      )}
                    />
                    {' '}
                    <span>{__('Skills Inventory')}</span>
                  </a>
                </Tooltip>
              </AccessControlled>

              <Tooltip title={__('Schedule Remote Meeting')} placement={'top'}>
                <a className="btn btn-small btn-default" onClick={() => setShowMeetings(true)}>
                  <i className="icon-videocam" /> <span>{__('Video Conference')}</span>
                </a>
              </Tooltip>

              <AccessControlled feature={AppFeature.StudentDashboard}>
                <a
                  className="btn btn-small mobilehide"
                  onClick={async () => {
                    await setActiveUser(student.id);
                    pushRoute(getProfileRoute(student.id) + AppRoute.Goals);
                  }}
                >
                  <span>{__('Dashboard')}</span>
                </a>
              </AccessControlled>
              <AccessControlled feature={AppFeature.StudentChallenges}>
                <a
                  className="btn btn-small mobilehide"
                  onClick={() => setShowChallenges(true)}
                >
                  <span>{__('Challenges')}</span>
                </a>
              </AccessControlled>
              <a
                onClick={async () => {
                  await setActiveUser(student.id);
                  pushRoute(getProfileRoute(student.id) + AppRoute.PlanOverview);
                }}
                className="btn btn-small "
              >
                <span>{__('Plan')}</span>
              </a>
              <AccessControlled feature={AppFeature.StudentAddGoal}>
                <a
                  onClick={() => setShowCreateGoal(true)}
                  className="btn btn-small btn-success"
                >
                  <i className="icon-plus" /> <span>{__('Create Goal')}</span>
                </a>
              </AccessControlled>
            </div>

            <div className="clearfix" />
          </div>
        )}
      </Droppable>
    </div>
  );
};
