import React from 'react';
import {ResourceBase} from 'api/resources/Resource';
import {FileView} from 'views/resources/FileView';

export interface FileResourceViewProps {
  resource: ResourceBase,
  onCloseRequested?: () => any,
}

export const FileResourceView = (props: FileResourceViewProps) => {
  if (!props.resource.resource.url) {
    return <></>;
  }

  return (
    <FileView
      name={props.resource.resource.name}
      url={props.resource.resource.url}
      onCloseRequested={props.onCloseRequested}
    />
  );
};
