import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {Category} from 'api/categories/Category';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {DataGrid} from 'components/DataGrid';
import {useLogger} from 'logging/logging';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {ButtonEdit} from 'components/buttons/ButtonEdit';
import {ButtonDelete} from 'components/buttons/ButtonDelete';
import {Skill} from 'api/skills/Skill';
import {confirmDelete} from 'components/confirmDelete';
import {SkillInputModal} from 'views/skills/SkillInputModal';
import classNames from 'classnames';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {SkillRelatedLessonsModal} from 'views/skills/SkillRelatedLessonsModal';
import {InViewObserver} from 'components/InViewObserver';

export const SkillsListView = () => {
  const logger = useLogger(SkillsListView.name);
  const {categoriesService, skillsService} = useApiContext();
  const skillsPerPage = 20;

  const [skills, setSkills] = useState<Skill[]>([]);
  const [page, setPage] = useState<number>(0);
  const [editSkill, setEditSkill] = useState<Skill | undefined>(undefined);
  const [showRelatedLessonsSkill, setShowRelatedLessonsSkill] = useState<Skill | undefined>(undefined);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(undefined);

  const loadSkills = async () => {
    const fetchedSkills = await skillsService.fetchSkills({
      search: searchFilter,
      categoryId: selectedCategoryId,
      start: page * skillsPerPage,
      end: (page * skillsPerPage) + skillsPerPage,
    });
    setSkills((current) => current.concat(fetchedSkills));
    logger.debug('Fetched skills', fetchedSkills);
  };

  const resetSkills = async () => {
    setSkills([]);
    setPage(0);
    await loadSkills();
  };

  useEffect(() => {
    (async () => {
      setCategories(await categoriesService.fetchCategories());
    })();
  }, [categoriesService]);

  useEffect(() => {
    (async () => {
      if (page > 0) {
        await loadSkills();
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      await resetSkills();
    })();
  }, [searchFilter, selectedCategoryId]);

  return (
    <PageView>
      {editSkill &&
        <SkillInputModal
          skillId={editSkill.id}
          onCancel={() => setEditSkill(undefined)}
          onComplete={async () => {
            setEditSkill(undefined);
            await resetSkills();
          }}
        />
      }

      {showRelatedLessonsSkill &&
        <SkillRelatedLessonsModal
          skillId={showRelatedLessonsSkill.id}
          onCloseRequested={() => setShowRelatedLessonsSkill(undefined)}
        />
      }

      <ContextMenu>
        <SearchInput
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
        />

        <i className="icon icon-tag"/>
        <select
          className={`form-control ${selectedCategoryId ? 'selected' : ''}`}
          onChange={(e) =>
            setSelectedCategoryId(parseInt(e.target.value))
          }
          value={selectedCategoryId}
        >
          <option value={undefined}>{__('- Category -')}</option>
          {categories.map((category) => (
            <option
              key={category.id}
              value={category.id}
              dangerouslySetInnerHTML={
                {__html: Array(category.level).join('&nbsp;&nbsp;&nbsp;&nbsp;') + category.name}
              }
            />
          ))}
        </select>

        <Button
          variant={ButtonVariant.Info}
          tooltip={
            <div className="info-tool" style={{textAlign: 'left'}}>
              <h4>Skills Page</h4>
              <hr/>
              <br />
              <p>
                This page allows users to search, view and edit skills
                based on user level. Skills are used to track participant progress
                in specific areas. To track a specific skill it must be attached
                to an Action Step. Once that Action Step is part of an Assigned
                Goal, it will show as “In Progress” in the participant’s Skill
                Tracking section until the Action Step is completed.
              </p>
            </div>
          }
        />

        <Button
          variant={ButtonVariant.Add}
          onClick={() => setEditSkill({} as Skill)}
        />

        <AccessControlled feature={AppFeature.DashboardAdmin}>
          <Button
            tooltip={__('Skill Relationships')}
            iconCode={'icon-link'}
            onClick={() => {}}
            className={'btn-success'}
          />
        </AccessControlled>
      </ContextMenu>

      <DataGrid
        header={
          <tr>
            <td width={60}/>
            <td>{__('Name')}</td>
            <td>{__('Category')}</td>
            <td width={35}/>
          </tr>
        }
        body={
          <>
            {skills.map((skill) => (
              <tr key={skill.id}>
                <td>
                  <div
                    onClick={() => setShowRelatedLessonsSkill(skill)}
                    className="circle-30"
                    style={{
                      cursor: 'pointer',
                      borderColor: skill.category?.color,
                    }}
                  >
                    <span className={classNames(
                        'icon',
                        skill.category?.icon,
                    )}/>
                  </div>
                </td>
                <td>
                  <span
                    onClick={() => setShowRelatedLessonsSkill(skill)}
                    style={{cursor: 'pointer'}}
                  >
                    {skill.name}
                  </span>
                </td>
                <td>
                  <div
                    className="label"
                    style={{
                      borderColor: skill.category?.color,
                      boxShadow: `1px 1px 10px ${skill.category?.color}`,
                      padding: '3px 5px',
                      marginLeft: `${((skill.category?.level ?? 1) - 1) * 5}px`,
                    }}
                  >
                    {skill.category?.name}
                  </div>
                </td>
                <td>
                  <AccessControlled canEdit={skill}>
                    <ButtonGroup>
                      <ButtonEdit onClick={() => setEditSkill(skill)}/>
                      <ButtonDelete
                        onClick={() => confirmDelete(async () => {
                          await skillsService.deleteSkill(skill.id);
                          await resetSkills();
                        })}
                      />
                    </ButtonGroup>
                  </AccessControlled>
                </td>
              </tr>
            ))}
            <InViewObserver onInView={() => setPage((current) => current + 1)} />
          </>
        }
      />
    </PageView>
  );
};
