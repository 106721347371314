import React, {ReactElement, useState} from 'react';
import classNames from 'classnames';
import {PageView} from 'layout/PageView';

export interface SidePanelPageViewProps {
  panelView?: ReactElement
  mainView?: ReactElement
  containerClassName?: string
  firstColumnClassName?: string
  startPanelClosed?: boolean
  hideSidePanel?: boolean
}

export const SidePanelPageView = (props: SidePanelPageViewProps) => {
  const [showPanel, setShowPanel] = useState<boolean>(!props.startPanelClosed);

  return (
    <PageView>
      <div className={props.containerClassName ?? 'three-column-layout'}>
        {!props.hideSidePanel && (
          <div
            className={classNames(
                props.firstColumnClassName ?? 'first-column',
                'slide',
                {away: !showPanel},
            )}
            style={{position: 'fixed', marginTop: 50, marginBottom: 20}}
          >
            <div
              className={'switcher'}
              onClick={() => setShowPanel((current) => !current)}
              aria-label="Filter Switch"
            />
            <div className="view" style={{overflowY: 'auto'}} aria-label="Left Filter Area">
              <div className="pane">
                <div className="body">
                  {props.panelView}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={classNames(
              'right-column',
              'slide',
              {away: !showPanel},
          )}
          aria-label="Main Content Area"
          id="mainContent"
        >
          <div className="view animate">
            {props.mainView}
          </div>
        </div>
      </div>
    </PageView>
  );
};
