import React, {useState} from 'react';
import {Email} from 'api/email/Email';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {FormattedDate} from 'components/FormattedDate';
import {Tooltip} from 'components/Tooltip';
import {EmailInputModal} from 'views/email/EmailInputModal';

export interface EmailQuickViewProps {
  email: Email,
  onDelete?: (email: Email) => any,
}

export const EmailQuickView = (props: EmailQuickViewProps) => {
  const [email, setEmail] = useState<Email>(props.email);
  const [showExpanded, setShowExpanded] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  return (
    <div className={classNames('emails list-item expandable', {'open': showExpanded})}>
      {showEdit &&
        <EmailInputModal
          initialEmail={email}
          onCloseRequested={() => setShowEdit(false)}
          onCompleted={(newEmail) => {
            if (newEmail) {
              setEmail(newEmail);
            }
            setShowEdit(false);
          }}
        />
      }

      <div className="head"
        onClick={() => setShowExpanded((current) => !current)}
        aria-label="View Communication"
      >
        <span className="flag"><i
          className={email.sent ? 'icon-check' : ''}
          aria-label={email.sent ? 'Email sent' : 'Email Not sent'}
        /></span>
        <span className="subject">{email.subject}</span>

        <div className="buttons btn-group">
          {email.scheduled && !email.sent &&
          <>
            <Tooltip title={__('Edit')}>
              <a
                className="btn btn-icon btn-mini"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEdit(true);
                }}
                aria-label="edit Communiation"
              >
                <i className="icon-edit" />
              </a>
            </Tooltip>
            <Tooltip title={__('Delete')}>
              <a
                className="btn btn-icon btn-mini"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete?.(email);
                }}
                aria-label="Remove Communiation"
              >
                <i className="icon-cancel" />
              </a>
            </Tooltip>
          </>
          }
        </div>

        <span className="date">
          {email.sent && email.sendDate &&
            <span><span>{__('Sent')}</span>: <FormattedDate timestamp={email.sendDate} /></span>
          }
          {!email.sent && !email.scheduled && <span>{__('Sending')}...</span>}
          {!email.sent && email.scheduleDate && email.scheduled &&
            <span><span >{__('Scheduled')}</span>: <FormattedDate timestamp={email.scheduleDate} /></span>
          }
        </span>

        <div style={{clear: 'right'}} />
      </div>

      <div className="body">
        <div><span>{__('From')}</span>: {email.from}</div>
        <div><span>{__('Reply To')}</span>: {email.replyTo}</div>
        <div><span>{__('Subject')}</span>: {email.subject}</div>
        <div className="message" dangerouslySetInnerHTML={{__html: email.message}} />
      </div>
    </div>
  );
};
