import React from 'react';
import {useApiContext} from 'api/ApiContext';
import {Category} from 'api/categories/Category';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {__} from 'i18n/localize';

/**
 * A category select input with search and dynamic search-based loading
 */

export interface CategoryPickerProps {
  onChange?: (newCategories: Category[]) => any,
  selectedCategories?: Category[],
  fetchOnInit?: boolean,
  placeholder?: string,
  multiple?: boolean,
  isInvalid?: boolean,
  clearable?: boolean,
  allowCache?: boolean,
}

export const CategoryPicker = (props: CategoryPickerProps) => {
  const {categoriesService} = useApiContext();

  return (
    <DynamicSelect<Category>
      placeholder={__(props.placeholder ?? (props.multiple ? 'Categories' : 'Category'))}
      multiple={props.multiple}
      fetch={(filter) => categoriesService.fetchCategories({search: filter.search}, props.allowCache)}
      fetchOnInit={props.fetchOnInit ?? true}
      value={props.selectedCategories}
      onSelect={(newCatgories) => props.onChange?.(newCatgories)}
      isInvalid={props.isInvalid}
      clearable={props.clearable}
      isOptionSelected={(category) => props.selectedCategories?.some((c) => c.id === category.id) ?? false}
      styles={{
        option: (base: any, props: any) => {
          return {
            ...base,
            textIndent: (props.value.level - 1) * 15,
          };
        },
      }}
    />
  );
};
