import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {Category} from 'api/categories/Category';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {SkillStatus} from 'api/skills/SkillStatus.enum';
import {Modal} from 'components/Modal';
import {Skill} from 'api/skills/Skill';
import {useSessionContext} from 'contexts/SessionContext';
import classNames from 'classnames';
import {confirmModal} from 'components/confirmModal';
import {__} from 'i18n/localize';
import {SaveAssessmentDto} from 'api/assessments/SaveAssessmentDto';

export interface SkillInventorySurveyProps {
  onComplete?: (results: SkillCategoryWithStatus[]) => any,
}

export interface SkillCategoryWithStatus {
  category: Category,
  skills: {
    skill: Skill,
    status: SkillStatus,
  }[],
}

const NUM_RECOMMENDATIONS = 3;
const MIN_RECOMMENDATION_WEIGHT = 10;

export const SkillInventorySurvey = (props: SkillInventorySurveyProps) => {
  const logger = useLogger(SkillInventorySurvey.name);

  const {sessionDetails} = useSessionContext();
  const {categoriesService, skillsService, surveysService, assessmentsService} = useApiContext();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [recommendedCategoriesWithSkills, setRecommendedCategoriesWithSkills] = useState<SkillCategoryWithStatus[]>([]);
  const [statusIsUpdated, setStatusIsUpdated] = useState<boolean>(false);

  const userId = sessionDetails?.selectedStudent?.id ?? sessionDetails?.identity?.id ?? -1;

  const save = async () => {
    if (!statusIsUpdated) {
      confirmModal(
          () => {}, // we actually just use this as an alert
          __('Please Update'),
          __('In order to continue you must update your status on at least one skill.'),
      );
    }
    if (isSaving) {
      return;
    }

    confirmModal(
        submitResults,
        __('Ready to Continue'),
        __('Are you ready to go to the next step?'),
    );
  };

  const submitResults = async () => {
    setIsSaving(true);
    const assessment: SaveAssessmentDto = {categories: []};
    recommendedCategoriesWithSkills.forEach((group) => {
      assessment.categories.push({
        id: group.category.id,
        name: group.category.name,
        icon: group.category.icon,
        color: group.category.color,
        ilries: group.skills.map((skill) => ({
          id: skill.skill.id,
          state: skill.status,
          name: skill.skill.name,
          category: skill.skill.category,
        })),
      });
    });
    const saveResult = await assessmentsService.saveAssessment(assessment);
    logger.debug('Saved assessment', saveResult);
    setIsSaving(false);
    props.onComplete?.(recommendedCategoriesWithSkills);
  };

  const fetchAndProcessSurveyResult = async () => {
    const fetchedSurveyResult = await surveysService.fetchSurveyResults();
    logger.debug('Fetched survey result', fetchedSurveyResult);

    if (fetchedSurveyResult.length > 0 && fetchedSurveyResult[0].result) {
      const surveyResultData = JSON.parse(fetchedSurveyResult[0].result.replace(/'/g, '"'));
      const resultHopes = surveyResultData[0].hopes.sort((a: any, b: any) => a.value > b.value ? -1 : 1);
      const resultObstacles = surveyResultData[1].obsticles.sort((a: any, b: any) => a.value > b.value ? -1 : 1);

      // get top-weighted categories in survey result
      const topLevelCategories = await categoriesService.fetchCategories({filters: {level: 1}});
      const hopeCategories: SkillCategoryWithStatus[] = [];
      const obstacleCategories: SkillCategoryWithStatus[] = [];
      topLevelCategories.forEach((category) => {
        resultHopes.forEach((hope: any) => {
          if (
            category.name === hope.label &&
            hope.value > MIN_RECOMMENDATION_WEIGHT &&
            hopeCategories.length < NUM_RECOMMENDATIONS
          ) {
            hopeCategories.push({category, skills: []});
          }
        });
        resultObstacles.forEach((obstacle: any) => {
          if (
            category.name === obstacle.label &&
            obstacle.value > MIN_RECOMMENDATION_WEIGHT &&
            obstacleCategories.length < NUM_RECOMMENDATIONS
          ) {
            obstacleCategories.push({category, skills: []});
          }
        });
      });

      const categoryInHopes = (categoryId: number) => hopeCategories.find((c) => c.category.id === categoryId);
      const categoryInObstacles = (categoryId: number) => obstacleCategories.find((c) => c.category.id === categoryId);

      // find skills in top-weighted categories
      const allUserSkills = await skillsService.fetchUserSkills(userId);
      const allSkills = await skillsService.fetchSkills();
      allSkills.forEach((skill) => {
        // we only want "foundation" skills
        if (!skill.keywords?.includes('Foundations')) {
          return;
        }

        let category = skill.category;
        let topParentCategory;
        while (category) {
          if (category?.parent === null) {
            topParentCategory = category;
          }
          category = category?.parent;
        }
        if (!topParentCategory) {
          return;
        }

        const hopeCategory = categoryInHopes(topParentCategory.id);
        const obstacleCategory = categoryInObstacles(topParentCategory.id);
        if (hopeCategory) {
          hopeCategory.skills.push({
            skill,
            status: allUserSkills.ilri.find((userSkill) => userSkill.id === skill.id)?.state ?? SkillStatus.Recommended,
          });
        } else if (obstacleCategory) {
          obstacleCategory.skills.push({
            skill,
            status: allUserSkills.ilri.find((userSkill) => userSkill.id === skill.id)?.state ?? SkillStatus.Recommended,
          });
        }
      });

      const fullRecommendations = hopeCategories.concat(obstacleCategories);
      setRecommendedCategoriesWithSkills(fullRecommendations);
      logger.debug('Processed survey results', fullRecommendations);
    }
  };

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      await fetchAndProcessSurveyResult();
      setIsInitializing(false);
    })();
  }, []);

  return (
    <Modal
      className={'learning'}
      hideHeader={true}

      body={
        <>
          {isSaving &&
            <LoadingIndicator fullscreen={true} message={__('Saving Content')} />
          }

          <div className="viewerButtons" style={{position: 'absolute'}}>
            <div className="buttons btn-group">
              <a
                onClick={save}
                className={classNames('btn btn-info', {'disabled': isSaving})}
                style={{padding: '10px 15px', fontSize: 15}}
              >
                {!isSaving &&
                  <span>Next <i className="icon-right-open" /></span>
                }
                {isSaving &&
                  <span>Saving...</span>
                }
              </a>
            </div>
          </div>

          <div className="modal-body" style={{top: 0, bottom: 60}}>
            <div className="body viewer analytics">
              <div
                className="inventory"
                style={{margin: '-10px -10px 0 -10px', padding: 10}}
              >
                <div className="dreams_survey" style={{maxWidth: 900}}>
                  <div className="headline" style={{paddingRight: 30}}>
                    <h1>Skills Inventory</h1>
                  </div>
                  <iframe
                    style={{maxWidth: 900, height: 300, margin: '0 auto'}}
                    src="https://player.vimeo.com/video/425023728?title=0&byline=0&portrait=0&autoplay=1"
                    width="640" height="300" frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />

                  <div
                    className="headline"
                    style={{paddingRight: 30, marginTop: 20, fontSize: 20}}
                  >
                    <p>Where are you in learning the skills below?</p>
                  </div>
                </div>
              </div>
              <div
                className="body learningPath dreams_survey quiz-view"
                style={{padding: '10px 0 130px 0'}}
              >
                {isInitializing && <LoadingIndicator />}

                {!isInitializing && recommendedCategoriesWithSkills.map((group) => (
                  <div key={group.category.id} role="list">
                    {group.skills.map((skillWithStatus) => (
                      <div className="skills" key={skillWithStatus.skill.id}>
                        <div className="row-fluid">
                          <div className="span3 state">
                            <select
                              className="span12"
                              value={skillWithStatus.status}
                              onChange={(e) => {
                                setStatusIsUpdated(true);
                                const newStatus: SkillStatus = e.target.value as SkillStatus ?? SkillStatus.Recommended;
                                setRecommendedCategoriesWithSkills((current) => {
                                  const newRecommendations = [...current];
                                  newRecommendations.forEach((g) => {
                                    if (g.category.id === group.category.id) {
                                      g.skills.forEach((s) => {
                                        if (s.skill.id === skillWithStatus.skill.id) {
                                          s.status = newStatus;
                                        }
                                      });
                                    }
                                  });
                                  return newRecommendations;
                                });
                              }}
                              style={{fontSize: 16}}
                            >
                              <option value={SkillStatus.Completed}>
                                I have learned
                              </option>
                              <option value={SkillStatus.InProgress}>
                                I am learning
                              </option>
                              <option value={SkillStatus.Recommended}>
                                I want to learn
                              </option>
                            </select>
                          </div>

                          <div className="span9 skillname" style={{marginLeft: 5}}>
                            {skillWithStatus.skill.name.replace('I know', '')}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};
