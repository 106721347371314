import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {CreateQuizQuestionDto, UpdateQuizQuestionDto} from 'api/quizzes/QuizQuestion';
import {__} from 'i18n/localize';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {QuestionAnswerInput} from 'views/quizzes/QuestionAnswerInput';
import classNames from 'classnames';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {Tooltip} from 'components/Tooltip';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {SwitchInput} from 'components/SwitchInput';

export interface QuizQuestionInputProps {
  question: CreateQuizQuestionDto | UpdateQuizQuestionDto,
  onChange?: (newQuestion: CreateQuizQuestionDto| UpdateQuizQuestionDto) => any,
  numQuestions: number,
  order: number,
  onOrderChanged?: (newOrder: number) => any,
  onRemoved?: () => any,
  forSurvey?: boolean,
}

export const QuizQuestionInput = (props: QuizQuestionInputProps) => {
  const {question, order} = props;
  const orderMenuRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [showOrderMenu, setShowOrderMenu] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const closeOnClick = useCallback((e) => {
    if (!orderMenuRef.current?.contains(e.target)) {
      setShowOrderMenu(false);
    }
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
    };
  }, [closeOnClick]);

  let headText: string;
  let headIconCode: string;
  switch (question.type) {
    case QuizQuestionType.Bullets:
      headText = __('Single Answer');
      headIconCode = 'icon-check';
      break;
    case QuizQuestionType.Multiple:
      headText = __('Multiple Answer');
      headIconCode = 'icon-list';
      break;
    case QuizQuestionType.Open:
      headText = __('Open Answer (not graded)');
      headIconCode = 'icon-font';
      break;
    case QuizQuestionType.Instructions:
      headText = __('Instructions (not graded)');
      headIconCode = 'icon-font';
      break;
    case QuizQuestionType.Order:
      headText = __('Correct Order');
      headIconCode = 'icon-sort-number-up';
      break;
    case QuizQuestionType.Match:
      headText = __(props.forSurvey ? 'Match Order' : 'Matching Question');
      headIconCode = props.forSurvey ? 'icon-font' : 'icon-share';
      break;
    default:
      headText = '';
      headIconCode = '';
      break;
  }

  const addAnswer = () => {
    props.onChange?.({
      ...question,
      answers: [
        ...question.answers,
        {
          text: '',
          hope: false,
          correct: false,
          order: props.numQuestions,
          weight: 1,
          match: '',
        },
      ],
    });
  };

  const onDragEnd = (dropResult: DropResult) => {
    const originalIndex = parseInt(dropResult.draggableId);
    const newIndex = dropResult.destination?.index;
    if (isNaN(originalIndex) || newIndex === undefined) {
      return;
    }

    const newAnswers = [...question.answers];
    const tmp = newAnswers[originalIndex];
    tmp.order = newIndex;

    newAnswers[originalIndex] = newAnswers[newIndex];
    newAnswers[originalIndex].order = originalIndex;
    newAnswers[newIndex] = tmp;

    props.onChange?.({
      ...question,
      answers: newAnswers,
    });
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#8dc73f' : 'none',
  });

  const answerInputs = question.answers.map((answer, i) => (
    <Fragment key={i}>
      <QuestionAnswerInput
        answer={answer}
        forSurvey={props.forSurvey}
        questionType={question.type}
        onChange={(newAnswer) => {
          const answersCopy = [...question.answers];

          const shouldClearOtherAnswers = question.type === QuizQuestionType.Bullets &&
            !answer.correct &&
            newAnswer.correct;
          if (shouldClearOtherAnswers) {
            answersCopy.forEach((answer) => answer.correct = false);
          }

          answersCopy[i] = newAnswer;

          props.onChange?.({
            ...question,
            answers: answersCopy,
          });
        }}
        onRemoved={() => {
          const newAnswers = question.answers.filter((a, i2) => i2 !== i);
          props.onChange?.({...question, answers: newAnswers});
        }}
      />
    </Fragment>
  ));

  return (
    <div className="row-fluid">
      <div className="quiz-group span12" style={{position: 'relative'}}>
        <div
          ref={orderMenuRef}
          className={classNames('btn-group dropdown pull-right', {'open': showOrderMenu})}
          style={{position: 'absolute', top: 0, right: 0, zIndex: 9}}
        >
          <button
            className="btn dropdown-toggle pull-"
            type={'button'}
            onClick={() => setShowOrderMenu((current) => !current)}
          >
            <span>{__('Order')}</span>
            <span className="caret" />
          </button>
          <ul
            onClick={() => setShowOrderMenu(false)}
            className={'dropdown-menu pull-right'}
            style={{borderRadius: '4px 10px'}}
          >
            {[...Array(props.numQuestions)].map((e, i) => (
              <li key={i}>
                <a onClick={() => props.onOrderChanged?.(i)}> {i +1}</a>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={'row-fluid head'}
          style={{
            display: 'grid',
            gridTemplateColumns: '0 1fr 1fr 1fr',
            gridGap: 20,
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <h4>{order + 1}.) <i className={`icon ${headIconCode}`}/> <span>{headText}</span></h4>
          {props.forSurvey &&
            <Fragment>
              {question.type !== QuizQuestionType.Instructions &&
                <Fragment>
                  <div style={{paddingTop: 10}}>
                    <CategoryPicker
                      clearable={false}
                      selectedCategories={props.question.category ? [props.question.category] : []}
                      onChange={(newCategories) => props.onChange?.({
                        ...props.question,
                        category: newCategories.length > 0 ? newCategories[0] : undefined,
                      })}
                    />
                  </div>
                  <div>
                    Hope
                    <SwitchInput
                      on={question.challenge}
                      onChange={(nowOn) => props.onChange?.({
                        ...props.question,
                        challenge: nowOn,
                      })}
                    />
                    Obstacle
                  </div>
                </Fragment>
              }
            </Fragment>
          }
        </div>

        <div className="row-fluid question">
          {[QuizQuestionType.Bullets, QuizQuestionType.Multiple].includes(question.type) &&
            <div
              className={classNames(
                  'draggable',
                  {'active': question.draggable},
              )}
            >
              <Tooltip title={__('Make Question Drag and Drop')} placement={'right'}>
                <i
                  className="icon-up-hand"
                  onClick={() => props.onChange?.({...props.question, draggable: !props.question.draggable})}
                />
              </Tooltip>
            </div>
          }

          <div ref={menuRef} className={classNames('dropdown btn-group', {'open': showMenu})}>
            <a className="btn btn-icon dropdown-toggle" onClick={() => setShowMenu((current) => !current)}>
              <i className="icon-cog" />
            </a>
            <ul className={'dropdown-menu pull-right'} role="menu" onClick={() => setShowMenu(false)}>
              {![QuizQuestionType.Open, QuizQuestionType.Instructions].includes(question.type) &&
                <li>
                  <a onClick={addAnswer}>
                    <i className="icon-plus" /> <span>{__('Add Answer')}</span>
                  </a>
                </li>
              }
              {question.type === QuizQuestionType.Open &&
                <li>
                  <a
                    onClick={() => {
                      if (props.question.answers.length < 1) {
                        props.onChange?.({
                          ...props.question,
                          answers: [{
                            text: '',
                            match: '',
                            correct: false,
                            hope: false,
                            order: 0,
                            weight: 1,
                          }],
                        });
                      }
                    }}
                  >
                    <i className="icon-plus" /> <span>{__('Add Initial Text')}</span>
                  </a>
                </li>
              }
              <li>
                <a onClick={() => setShowEdit(true)}>
                  <i className="icon-edit"/> <span>{__('Edit')}</span>
                </a>
              </li>
              <li>
                <a onClick={props.onRemoved}>
                  <i className="icon-trash"/> <span>{__('Remove')}</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="text">
            <RichTextInput
              value={question.text}
              placeholder={__('Click to edit this text')}
              onChange={(newValue) => props.onChange?.({
                ...question,
                text: newValue,
              })}
              alwaysRichEdit={showEdit}
              onBlur={() => setShowEdit(false)}
              hideOutline={true}
            />
          </div>
        </div>

        {question.type === QuizQuestionType.Order &&
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'answers'}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {answerInputs}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        {question.type === QuizQuestionType.Match &&
          <div>
            <div className="row-fluid">
              <table className="list">
                <thead>
                  <tr>
                    <td width="49%">{__('Text')}</td>
                    <td width="49%">{__('Match')}</td>
                    <td width="40"/>
                  </tr>
                </thead>
                <tbody>
                  {answerInputs}
                </tbody>
              </table>
            </div>
          </div>
        }
        {![QuizQuestionType.Order, QuizQuestionType.Match].includes(question.type) && answerInputs}

        {![QuizQuestionType.Open, QuizQuestionType.Instructions].includes(question.type) &&
          <div className="row-fluid add-more">
            <div className="text" onClick={addAnswer}>
              <span className="placeholder">
                <i className="icon-plus" /> <span>{__('Click to add more answers')}</span>
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  );
};
