import React, {Fragment, useEffect, useState} from 'react';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {__} from 'i18n/localize';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useApiContext} from 'api/ApiContext';
import {JournalEntryWithRelations} from 'api/journal/JournalEntry';
import {useLogger} from 'logging/logging';
import {InViewObserver} from 'components/InViewObserver';
import {ListFilter} from 'api/lists/ListFilter';
import {ApiRoute} from 'api/ApiRoute';
import classNames from 'classnames';
import {JournalEntryInputModal} from 'views/journal/JournalEntryInputModal';
import {CommentWithRelations} from 'api/journal/Comment';
import {CheckBox} from 'components/forms/CheckBox';
import {ActivityItemQuickView} from 'views/activity/ActivityItemQuickView';
import dayjs, {Dayjs} from 'dayjs';
import {FilterCategory} from 'api/goals/FilterCategory';
import {FilterCourse} from 'api/goals/FilterCourse';
import {Tooltip} from 'components/Tooltip';
import {SessionReportInputModal} from 'views/activity/SessionReportInputModal';
import {JournalSort} from 'api/journal/JournalSort.enum';
import {JournalEntryType} from 'api/journal/JournalEntryType';
import {useAuthContext} from 'contexts/AuthContext';
import {User} from 'api/users/User';

export interface JournalViewProps {
  forActivityLog?: boolean,
}

/**
 * A user's journal view
 */
export const JournalView = (props: JournalViewProps) => {
  const logger = useLogger(JournalView.name);
  const entriesPerPage = 20;
  const {entityListService, journalService} = useApiContext();
  const {currentUser} = useAuthContext();

  const [page, setPage] = useState<number>(0);
  const [listEndReached, setListEndReached] = useState<boolean>(false);
  const [filters, setFilters] = useState<ListFilter>({filters: {}});
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [editEntry, setEditEntry] = useState<JournalEntryWithRelations | undefined>(undefined);
  const [isFetchingSidebar, setIsFetchingSidebar] = useState<boolean>(true);
  const [sidebarCourses, setSidebarCourses] = useState<FilterCourse[]>([]);
  const [sidebarCategories, setSidebarCategories] = useState<FilterCategory[]>([]);
  const [showSessionReport, setShowSessionReport] = useState<boolean>(false);
  const [dateGroupedEntries, setDateGroupedEntries] =
    useState<{date: Dayjs, entries: JournalEntryWithRelations[]}[]>([]);

  const insertEntries = (entries: JournalEntryWithRelations[]) => {
    setDateGroupedEntries((current) => {
      const newEntries = [...current];
      entries.forEach((journalEntry) => {
        const date = dayjs(journalEntry.mdate);
        let dateGroup = newEntries.find((dateGroup) => dateGroup.date.isSame(date, 'day'));
        if (!dateGroup) {
          dateGroup = {date, entries: []};
          newEntries.push(dateGroup);
        }
        dateGroup.entries.push(journalEntry);
        dateGroup.entries.sort((a, b) => a.mdate > b.mdate ? -1 : 1);
      });
      if (filters.filters.order_by !== JournalSort.RecentActivity) {
        return newEntries.sort((a, b) => a.date > b.date ? -1 : 1);
      } else {
        return newEntries;
      }
    });
  };

  const loadEntries = async () => {
    setIsFetching(true);
    const fetchedEntries = await entityListService.fetchEntityList(
      props.forActivityLog ? '/journal/entries/activity' : ApiRoute.JournalEntries,
      {
        ...filters,
        start: (page * entriesPerPage) - 1,
        end: (page * entriesPerPage) + entriesPerPage,
      },
    );
    if (fetchedEntries.length < 1) {
      setIsFetching(false);
      setListEndReached(true);
      logger.debug('End of list reached');
    } else {
      insertEntries(fetchedEntries);
      logger.info('Fetched journal entries', fetchedEntries);
      setIsFetching(false);
    }
  };

  const resetEntries = async () => {
    setDateGroupedEntries([]);
    setListEndReached(false);
    if (page > 0) {
      setPage(0);
    } else {
      await loadEntries();
    }
  };

  const fetchSidebar = async () => {
    setIsFetchingSidebar(true);
    const fetchedFilter = props.forActivityLog ?
      await journalService.fetchActivityFilter() :
      await journalService.fetchJournalFilter();
    setSidebarCourses(fetchedFilter.courses);
    setSidebarCategories(
        fetchedFilter.categories.filter((c: any) => c !== null).concat([{
          id: 0,
          name: 'Uncategorized',
          activeGoals: 0,
          color: '',
          icon: '',
        }]),
    );
    setIsFetchingSidebar(false);
  };

  const isCourseFilterSelected = (course: FilterCourse) => {
    return filters.filters.course.includes(course.id);
  };

  const toggleCourseFilter = (course: FilterCourse) => {
    if (isCourseFilterSelected(course)) {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          course: current.filters.course.filter((id: number) => id !== course.id),
        },
      }));
    } else {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          course: current.filters.course.concat([course.id]),
        },
      }));
    }
  };

  const isCategoryFilterSelected = (category: FilterCategory) => {
    return filters.filters.category.includes(category.id);
  };

  const toggleCategoryFilter = (category: FilterCategory) => {
    if (isCategoryFilterSelected(category)) {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          category: current.filters.category.filter((id: number) => id !== category.id),
        },
      }));
    } else {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          category: current.filters.category.concat([category.id]),
        },
      }));
    }
  };

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      await fetchSidebar();
      await loadEntries();
      setIsInitializing(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isInitializing && !isFetching) {
        logger.debug('Resetting journal entries');
        await resetEntries();
      }
    })();
  }, [filters]);

  useEffect(() => {
    (async () => {
      if (!isInitializing && !isFetching) {
        logger.debug('Paging journal entries');
        await loadEntries();
      }
    })();
  }, [page]);

  useEffect(() => {
    if (sidebarCategories) {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          category: sidebarCategories.map((c) => c.id),
        },
      }));
    }
  }, [sidebarCategories]);

  useEffect(() => {
    if (sidebarCourses) {
      setFilters((current) => ({
        ...current,
        filters: {
          ...current.filters,
          course: sidebarCourses.map((c) => c.id),
        },
      }));
    }
  }, [sidebarCourses]);

  return (
    <SidePanelPageView
      containerClassName={'column-layout'}
      firstColumnClassName={'left-column'}
      startPanelClosed={true}

      panelView={
        <>
          {isFetchingSidebar && <LoadingIndicator background={'none'}/>}

          {!isFetchingSidebar &&
          <div className="pane with-inner-menu">
            <div className="body" id="category-filter">
              <div className="head">
                <span>Courses</span>
              </div>
              {sidebarCourses.map((course) => (
                <ul className="category" key={course.id}>
                  <li>
                    <div className="pivot">
                      <CheckBox
                        checked={isCourseFilterSelected(course)}
                        onClick={() => toggleCourseFilter(course)}
                      />
                      {course.activeGoals > 0 && <span className="active-goals">{course.activeGoals}</span>}
                      {course.name !== 'Individual' ? course.name : 'Individual Goals'}
                    </div>
                  </li>
                </ul>
              ))}

              <div className="inner-head">
                <span>{__('Learning Track')}</span>
              </div>
              {sidebarCategories.sort((a, b) => a.name < b.name ? -1 : 1).map((category) => (
                <ul className="category" key={category.id}>
                  <li>
                    <div className="pivot">
                      <CheckBox
                        checked={isCategoryFilterSelected(category)}
                        onClick={() => toggleCategoryFilter(category)}
                      />
                      {category.activeGoals > 0 && <span className="active-goals">{category.activeGoals}</span>}
                      {category.icon &&
                        <span
                          className={classNames('icon', category.icon)}
                          style={{backgroundColor: category.color}}
                        />
                      }
                      &nbsp;
                      {category.name}
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          }
        </>
      }

      mainView={
        <>
          {editEntry &&
            <JournalEntryInputModal
              initialEntry={editEntry}
              onCloseRequested={async () => setEditEntry(undefined)}
              onComplete={async (isNew, newEntry) => {
                if (newEntry) {
                  const entryWithDummyRelations: JournalEntryWithRelations = {
                    ...newEntry,
                    owner: currentUser as any as User,
                    author: currentUser as any as User,
                    comments: [],
                  } as any as JournalEntryWithRelations;
                  insertEntries([entryWithDummyRelations]);
                }
                setEditEntry(undefined);
              }}
            />
          }

          {showSessionReport &&
            <SessionReportInputModal
              onCompleted={async () => {
                setShowSessionReport(false);
                await resetEntries();
              }}
              onCloseRequested={() => setShowSessionReport(false)}
            />
          }

          <div className="pane journal with-inner-menu">
            <div className="body">
              <ContextMenu>
                <SearchInput
                  value={filters.search}
                  onChange={(newValue) => setFilters((current) => ({
                    ...current,
                    search: newValue,
                  }))}
                />

                <i className="icon icon-edit" />
                <input
                  placeholder={__('Author')}
                  value={filters.filters.author_search}
                  onChange={(e) => setFilters((current) => ({
                    ...current,
                    filters: {
                      ...current.filters,
                      author_search: e.target.value,
                    },
                  }))}
                  type="text"
                  className="form-control"
                />

                <i className="icon icon-sort-alt-up" />
                <Tooltip title={__('Order Entries')}>
                  <select
                    value={filters.filters.order_by}
                    className="form-control"
                    onChange={(e) => setFilters((current) => ({
                      ...current,
                      filters: {
                        ...current.filters,
                        order_by: e.target.value,
                      },
                    }))}
                  >
                    <option value="">{__('- Sort by -')}</option>
                    <option value={JournalSort.RecentPost}>{__('Recent Post')}</option>
                    <option value={JournalSort.RecentActivity}>{__('Recent Activity')}</option>
                  </select>
                </Tooltip>

                <i className="icon icon-tag" />
                <select
                  className="form-control"
                  value={filters.filters.type}
                  onChange={(e) => setFilters((current) => ({
                    ...current,
                    filters: {
                      ...current.filters,
                      type: e.target.value,
                    },
                  }))}
                >
                  <option value="">{__('- Type -')}</option>
                  <option value={JournalEntryType.Goal}>{__('Goal')}</option>
                  <option value={JournalEntryType.Step}>{__('Step Completed')}</option>
                  <option value={JournalEntryType.Post}>{__('Posted Content')}</option>
                  <option value={JournalEntryType.Course}>{__('Courses')}</option>
                  <option value={JournalEntryType.SessionReport}>{__('Session Report')}</option>
                  <option value={JournalEntryType.Assessment}>{__('Skills Inventory')}</option>
                  <option value={JournalEntryType.Badge}>{__('Badge')}</option>
                  <option value={JournalEntryType.Resource}>{__('Community')}</option>
                  <option value={JournalEntryType.Skill}>{__('Skill')}</option>
                </select>

                <Tooltip title={__('View items that need grading only')}>
                  <a
                    className="btn btn-info"
                    onClick={() => setFilters(
                        (current) => ({...current, filters: {...current.filters, graded: !current.filters.graded}}),
                    )}
                  >
                    <i className={filters.filters.graded ? 'icon-eye-off' : 'icon-eye'} />
                  </a>
                </Tooltip>

                {!props.forActivityLog &&
                  <ButtonAdd onClick={() => setEditEntry({} as JournalEntryWithRelations)} />
                }

                <Tooltip title={__('Add Session Report')}>
                  <a
                    className="btn btn-success"
                    onClick={() => setShowSessionReport(true)}
                  >
                    <i className="icon icon-sitemap" />
                  </a>
                </Tooltip>
              </ContextMenu>

              {isInitializing &&
                  <div style={{marginTop: 30}}>
                    <LoadingIndicator message={'Accessing User Activity'}/>
                  </div>
              }

              {!isInitializing &&
                <>
                  <div role="list" className="inline-list">
                    {dateGroupedEntries.map((dateGroup, dateGroupIndex) => (
                      <Fragment key={dateGroup.date.toISOString()}>
                        <div className="date-header-line" aria-label={dateGroup.date.format('DD/MM/YYYY')}>
                          <div className="holder">
                            <div className="day">{dateGroup.date.format('ddd')}</div>
                            <div className="dater">{dateGroup.date.format('DD')}</div>
                            <div className="month">{dateGroup.date.format('MMM')}</div>
                            <div className="month">{dateGroup.date.format('YYYY')}</div>
                          </div>
                        </div>
                        {dateGroup.entries.map((journalEntry, entryIndex) => (
                          <Fragment key={journalEntry.id}>
                            <ActivityItemQuickView
                              item={journalEntry}
                              forActivityLog={props.forActivityLog}
                              onFullUpdate={resetEntries}
                              onUpdated={async (newEntry) => {
                                if (newEntry) {
                                  setDateGroupedEntries((current) => {
                                    const copy = [...current];
                                    copy[dateGroupIndex].entries[entryIndex].title = newEntry.title;
                                    copy[dateGroupIndex].entries[entryIndex].text = newEntry.text;
                                    return copy;
                                  });
                                }
                              }}
                              onCommentAdded={(newComment) => {
                                // the API returns the comment without relations, and the full comment with
                                // relations cannot be fetched, so we mock them for new comments
                                const commentWithMockRelations: CommentWithRelations = {
                                  ...newComment,
                                  owner: currentUser as any as User,
                                  author: currentUser as any as User,
                                } as CommentWithRelations;

                                setDateGroupedEntries((current) => {
                                  const copy = [...current];
                                  copy[dateGroupIndex].entries[entryIndex].comments.push(commentWithMockRelations);
                                  return copy;
                                });
                              }}
                              onCommendUpdated={(updatedComment) => {
                                setDateGroupedEntries((current) => {
                                  const copy = [...current];
                                  copy[dateGroupIndex].entries[entryIndex].comments.forEach((comment) => {
                                    if (comment.id === updatedComment.id) {
                                      comment.text = updatedComment.text;
                                    }
                                  });
                                  return copy;
                                });
                              }}
                            />
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}

                    {!isFetching && dateGroupedEntries.length < 1 &&
                      <div className="pack" style={{textAlign: 'center'}} aria-label="Journal Entry">
                        <div
                          style={{
                            height: 150,
                            width: 'auto',
                            position: 'relative',
                            // eslint-disable-next-line max-len
                            background: 'linear-gradient(to bottom, rgba(175,175,175,0.9), rgba(175,175,175,0) 80%) #828282',
                            marginRight: 10,
                            borderRadius: '4px 20px',
                            margin: 10,
                            boxShadow: 'inset 0 2px 6px rgba(0,0,0,0.6)',
                          }}
                        >
                          <div
                            className="icon icon-help"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -40,
                              marginLeft: -32,
                              width: 38,
                              height: 38,
                              fontSize: 40,
                              lineHeight: '40px',
                              textAlign: 'center',
                              border: '5px solid white',
                              borderRadius: '50%',
                              padding: 10,
                              textShadow: '0 2px 6px rgba(0,0,0,0.6)',
                              boxShadow: '0 2px 6px rgba(0,0,0,0.6)',
                              background: 'transparent',
                            }}
                          />
                        </div>
                        <div style={{padding: '10px 0', fontFamily: 'triunity condensed', color: '#4B4B4B'}}>
                          <h4>We&rsquo;re sorry, we can&rsquo;t find any entries matching your search.</h4>
                          <p>{__('Try adjusting your category and module selection.')}</p>
                        </div>
                      </div>
                    }
                  </div>

                  {isFetching &&
                    <div style={{padding: '20px 0 50px'}}>
                      <LoadingIndicator />
                    </div>
                  }

                  {!isFetching && !listEndReached && dateGroupedEntries.length > 0 &&
                    <InViewObserver onInView={() => setPage((current) => current + 1)} />
                  }
                </>
              }
            </div>
          </div>
        </>
      }
    />
  );
};
