import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {Quiz, QuizWithQuestions} from 'api/quizzes/Quiz';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {useLogger} from 'logging/logging';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {confirmModal} from 'components/confirmModal';
import {QuizMatchQuestion} from 'views/learning/quizzes/QuizMatchQuestion';
import {QuizDragDropQuestion} from 'views/learning/quizzes/QuizDragDropQuestion';
import styles from './QuizView.module.scss';
import {QuizMultipleChoiceQuestion} from 'views/learning/quizzes/QuizMultipleChoiceQuestion';
import {__} from 'i18n/localize';
import {Resource} from 'api/resources/Resource';
import {CreateQuizSolutionDto, QuizQuestionWithAnswer, QuizSolution} from 'api/quizzes/QuizSolution';
import {useApiContext} from 'api/ApiContext';
import {SurveyResultsBuilder} from 'api/surveys/SurveyResultsBuilder';
import {QuizOrderQuestion} from 'views/learning/quizzes/QuizOrderQuestion';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface SurveyViewProps {
  resource: Resource,
  onCloseRequested?: () => any,
  onCompleted?: (solution?: QuizSolution) => any,
}

export const SurveyView = (props: SurveyViewProps) => {
  const logger = useLogger(SurveyView.name);
  const {quizzesService, surveysService, resourcesService} = useApiContext();
  const {resource} = props;
  const quiz = resource.resource as QuizWithQuestions;
  const resultsBuilder = useMemo<SurveyResultsBuilder>(
      () => surveysService.getSurveyResultsBuilder(quiz.questions),
      [quiz.questions, surveysService],
  );

  const [draftId, setDraftId] = useState<number | undefined>(undefined);
  const [activeQuestion, setActiveQuestion] = useState<QuizQuestionWithAnswer>(quiz.questions[0]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isSurvey = quiz.survey;
  let questionAnswerReady = false;
  if (activeQuestion.type === QuizQuestionType.Match && !activeQuestion.answers.some((a) => !a.matchTo)) {
    questionAnswerReady = true;
  }
  if (
    activeQuestion.type === QuizQuestionType.Bullets &&
    activeQuestion.draggable &&
    (activeQuestion.correct?.length ?? 0) > 0
  ) {
    questionAnswerReady = true;
  }
  if (
    (activeQuestion.type === QuizQuestionType.Bullets || activeQuestion.type === QuizQuestionType.Multiple) &&
    !activeQuestion.draggable &&
    activeQuestion.answers.some((a) => a.marked)
  ) {
    questionAnswerReady = true;
  }

  const submit = async () => {
    setIsSaving(true);

    const quizSolution: CreateQuizSolutionDto = {
      name: quiz.name,
      quiz: {id: quiz.id},
      questions: resultsBuilder.getResults(),
    };
    const saveResult = await surveysService.submitSurveySolution(quizSolution);
    logger.debug('Survey solution submitted', {solution: quizSolution, saveResult});

    const completeResourceResult = await resourcesService.completeQuizResource(
      resource as any as Quiz,
      saveResult.entity,
    );
    logger.debug('Completed survey resource', completeResourceResult);

    setIsSaving(false);
    props.onCompleted?.(saveResult.entity);
  };

  const nextQuestion = async () => {
    if (questionAnswerReady) {
      const nextQuestion = resultsBuilder.getNextQuestion(activeQuestion);
      if (nextQuestion) {
        setActiveQuestion(nextQuestion);
      } else {
        await submit();
      }
    }
  };

  const confirmQuit = () => {
    confirmModal(
        async () => {
          if (draftId) {
            await quizzesService.deleteDraft(draftId);
          }
          props.onCloseRequested?.();
        },
      isSurvey ? __('Restart Survey') : __('Delete Progress'),
      isSurvey ?
        'Are you sure you want to restart? You will lose any progress to this point.' :
        'Do you really want to cancel your work?',
    );
  };

  useEffect(() => {
    (async () => {
      logger.info('Viewing survey', props.resource);

      const fetchedDraft = await quizzesService.fetchQuizDraft(quiz.id);

      if (fetchedDraft) {
        setDraftId(fetchedDraft.id);
        logger.debug('Fetched survey draft', fetchedDraft);
      }
    })();
  }, []);

  return (
    <div className="wrapper">
      {isSaving && <LoadingIndicator fullscreen={true} message={__('Saving Content')} />}

      <div
        className={classNames(styles.wrapper, 'body viewer quiz-view', {'survey': isSurvey})}
        style={{padding: '15px 15px 50px'}}
      >

        <div
          key={activeQuestion.id}
          id={`cat${activeQuestion.id}`}
          className={classNames('question animate active')}
        >
          {activeQuestion.type !== QuizQuestionType.Instructions &&
            <div className="heading html clearfix" dangerouslySetInnerHTML={{__html: activeQuestion.text}} />
          }

          {activeQuestion.type === QuizQuestionType.Instructions &&
            <div className="html clearfix" dangerouslySetInnerHTML={{__html: activeQuestion.text}} />
          }

          <div className="clearfix" />

          {!activeQuestion.draggable &&
            (
              activeQuestion.type === QuizQuestionType.Bullets ||
              activeQuestion.type === QuizQuestionType.Multiple
            ) &&
            <QuizMultipleChoiceQuestion
              question={activeQuestion}
              onAnswersChanged={(newAnswers) => setActiveQuestion((current) => ({
                ...current,
                answers: newAnswers,
              }))}
            />
          }

          {activeQuestion.draggable &&
              (activeQuestion.type === QuizQuestionType.Bullets || activeQuestion.type === QuizQuestionType.Multiple
              ) &&
              <QuizDragDropQuestion
                question={activeQuestion}
                onAnswersChanged={(newQuestion) => setActiveQuestion(newQuestion)}
              />
          }

          {activeQuestion.type === QuizQuestionType.Open &&
              <div className="answer">
                <RichTextInput
                  value={activeQuestion.answer}
                  onChange={(newValue) => setActiveQuestion((current) => ({
                    ...current,
                    answer: newValue,
                  }))}
                />
              </div>
          }

          {activeQuestion.type === QuizQuestionType.Order &&
              <QuizOrderQuestion
                question={activeQuestion}
                onChange={(newQuestion) => setActiveQuestion(newQuestion)}
              />
          }

          {activeQuestion.type === QuizQuestionType.Match &&
              <QuizMatchQuestion
                question={activeQuestion}
                onChange={(newQuestion) => setActiveQuestion(newQuestion)}
                disableAnswerShuffle={true}
              />
          }

          <div className="clearfix" style={{marginBottom: 10}} />

          <div className="advance viewerButtons" style={{bottom: 0, position: 'fixed'}}>
            <div className="buttons btn-group" style={{marginLeft: 15, float: 'left'}}>
              <div id="cat{{question.id}}" className="btn" onClick={confirmQuit}>
                <i className="icon-left-open" />
                {' '}Restart
              </div>
            </div>

            <div className=" buttons btn-group">
              <div
                id={`cat${activeQuestion.id}`}
                className={classNames('btn btn-info', {'disabled': !questionAnswerReady})}
                onClick={nextQuestion}
              >
                Next <i className="icon-right-open" />
              </div>
            </div>
          </div>

          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};
