import {AxiosInstance} from 'axios';
import {ListFilter} from 'api/lists/ListFilter';
import {Course, CourseDetails, CoursePublicDetails, CreateCourseDto, UpdateCourseDto} from 'api/courses/Course';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {CourseRecommendedSkill} from 'api/courses/CourseRecommendedSkill';
import {EntityListService} from 'api/lists/EntityListService';

/**
 * Provides API courses functions
 */
export class CoursesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches courses
   *
   * @param {ListFilter} filter
   * @return {Promise<Course[]>}
   */
  async fetchCourses(filter: ListFilter): Promise<Course[]> {
    return this.entityListService.fetchEntityList(ApiRoute.Courses, filter);
  }

  /**
   * Fetches details for a given course
   *
   * @param {number} id
   * @return {Promise<CourseDetails>}
   */
  async fetchCourseDetails(id: number): Promise<CourseDetails> {
    const response = await this.httpClient.post(ApiRoute.Courses + '/details', {id});
    return response.data;
  }

  /**
   * Fetches courses taught by the current user
   *
   * @param {ListFilter} filter
   * @return {Promise<Course[]>}
   */
  async fetchTaughtCourses(filter?: ListFilter): Promise<Course[]> {
    const response = await this.httpClient.post(
        ApiRoute.Courses + '/teaching',
        filter ? this.entityListService.buildFilter(filter) : {},
    );
    return response.data.list;
  }

  /**
   * Creates a new course
   *
   * @param {CreateCourseDto} course
   * @return {Promise<SuccessResponse<Course>>}
   */
  async createCourse(course: CreateCourseDto): Promise<SuccessResponse<Course>> {
    const response = await this.httpClient.post(
        ApiRoute.Courses + ApiRoute.Save,
        course,
    );
    return response.data;
  }

  /**
   * Updates a course
   *
   * @param {UpdateCourseDto} course
   * @return {Promise<SuccessResponse<Course>>}
   */
  async updateCourse(course: UpdateCourseDto): Promise<SuccessResponse<Course>> {
    const response = await this.httpClient.post(
        ApiRoute.Courses + ApiRoute.Save,
        course,
    );
    return response.data;
  }

  /**
   * Deletes a course
   *
   * @param {number} id
   * @return {Promise<SuccessResponse<undefined>>}
   */
  async deleteCourse(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(
        ApiRoute.Courses + ApiRoute.Delete,
        {id},
    );
    return response.data;
  }

  /**
   * Fetches recommended skills for a given course
   *
   * @param {number} courseId
   * @return {Promise<CourseRecommendedSkill[]>}
   */
  async fetchCourseRecommendedSkills(courseId: number): Promise<CourseRecommendedSkill[]> {
    const response = await this.httpClient.post(
        ApiRoute.Courses + '/recommended',
        {id: courseId},
    );
    return response.data.user_ilri;
  }

  /**
   * Fetches public details for a course
   *
   * @param {number} id
   */
  async fetchCoursePublicDetails(id: number): Promise<CoursePublicDetails> {
    const response = await this.httpClient.post('/courses/publicDetails', {id});
    return response.data;
  }

  /**
   * Adds instructors to a course
   *
   * @param {number} courseId
   * @param {number[]} instructorIds
   */
  async addCourseInstructors(courseId: number, instructorIds: number[]): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/addInstructors', {
      id: courseId,
      users: instructorIds,
    });
    return response.data;
  }

  /**
   * Removes an instructor from a course
   *
   * @param {number} courseId
   * @param {number} instructorId
   */
  async removeCourseInstructor(courseId: number, instructorId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/removeInstructor', {
      id: courseId,
      user: instructorId,
    });
    return response.data;
  }

  /**
   * Adds assistant instructors to a course
   *
   * @param {number} courseId
   * @param {number[]} assistantIds
   */
  async addCourseAssistants(courseId: number, assistantIds: number[]): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/addAssistants', {
      id: courseId,
      users: assistantIds,
    });
    return response.data;
  }

  /**
   * Removes an assistant instructor from a course
   *
   * @param {number} courseId
   * @param {number} assistantId
   */
  async removeCourseAssistant(courseId: number, assistantId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/removeAssistant', {
      id: courseId,
      user: assistantId,
    });
    return response.data;
  }

  /**
   * Adds students to a course
   *
   * @param {number} courseId
   * @param {number[]} studentIds
   */
  async addCourseStudents(courseId: number, studentIds: number[]): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/addStudents', {
      id: courseId,
      users: studentIds,
    });
    return response.data;
  }

  /**
   * Removes a student from a course
   *
   * @param {number} courseId
   * @param {number} studentId
   */
  async removeCourseStudent(courseId: number, studentId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/courses/removeStudent', {
      id: courseId,
      user: studentId,
    });
    return response.data;
  }
}
