import React, {useEffect, useState} from 'react';

/**
 * Mirrors input placeholders into another element
 */

export interface InputPlaceholderMirrorProps {
  inputSelector?: string,
  reactSelectSelector?: string,
}

export const InputPlaceholderMirror = (props: InputPlaceholderMirrorProps) => {
  const [content, setContent] = useState<string>('');

  const focusListener = (e: any) => {
    setContent(e.target.placeholder);
  };

  const reactSelectFocusListener = (e: any) => {
    setContent(e.target.id);
  };

  const unFocusListener = () => {
    setContent('');
  };

  const setup = () => {
    const inputs = document.querySelectorAll(
        props.inputSelector ?? 'input,textarea,select',
    );
    inputs.forEach((input) => {
      input.addEventListener('mouseenter', focusListener);
      input.addEventListener('focus', focusListener);
      input.addEventListener('mouseout', unFocusListener);
      input.addEventListener('blur', unFocusListener);
    });

    const reactSelectInputs = document.querySelectorAll(
        props.reactSelectSelector ?? '.react-select-input',
    );
    reactSelectInputs.forEach((input) => {
      input.addEventListener('mouseenter', reactSelectFocusListener);
      input.addEventListener('focus', reactSelectFocusListener);
      input.addEventListener('mouseout', unFocusListener);
      input.addEventListener('blur', unFocusListener);
    });
  };

  const teardown = () => {
    const inputs = document.querySelectorAll(
        props.inputSelector ?? 'input,textarea,select',
    );
    inputs.forEach((input) => {
      input.removeEventListener('mouseenter', focusListener);
      input.removeEventListener('focus', focusListener);
      input.removeEventListener('mouseout', unFocusListener);
      input.removeEventListener('blur', unFocusListener);
    });

    const reactSelectInputs = document.querySelectorAll(
        props.reactSelectSelector ?? '.react-select-input',
    );
    reactSelectInputs.forEach((input) => {
      input.removeEventListener('mouseenter', reactSelectFocusListener);
      input.removeEventListener('focus', reactSelectFocusListener);
      input.removeEventListener('mouseout', unFocusListener);
      input.removeEventListener('blur', unFocusListener);
    });
  };

  useEffect(() => {
    setup();
    return teardown;
  }, []);

  return <div className={'placeholder-info'}>{content}</div>;
};
