import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Achievement, AchievementWithRelations, CreateAchievementDto} from 'api/achievements/Achievement';
import {__, localizeText} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {Skill} from 'api/skills/Skill';
import {SwitchInput} from 'components/SwitchInput';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Product} from 'api/products/Product';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {Template} from 'api/templates/Template';
import {LoadingModal} from 'components/LoadingModal';

export interface AchievementInputModalProps {
  initialAchievement?: Achievement,
  onComplete?: (achievement?: Achievement) => any,
  onCancel?: () => any,
}

export const AchievementInputModal = (props: AchievementInputModalProps) => {
  const logger = useLogger(AchievementInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {achievementsService, skillsService, productsService, templatesService} = useApiContext();

  const isEditing = props.initialAchievement?.id !== undefined;
  const [initialAchievement, setInitialAchievement] = useState<AchievementWithRelations | undefined>(undefined);
  const [isFetchingInitial, setIsFetchingInitial] = useState<boolean>(false);
  const [achievementUnderEdit, setAchievementUnderEdit] = useState<CreateAchievementDto>({
    name: '',
    description: '',
    keywords: '',
    type: '',
    category: undefined,
    template: undefined,
    cert: false,
    skills: [],
    achievements: [],
    products: [],
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const fetchInitialAchievement = async () => {
    if (props.initialAchievement?.id) {
      setIsFetchingInitial(true);
      const result = await achievementsService.fetchAchievement(props.initialAchievement?.id);
      setInitialAchievement(result);
      logger.debug('Fetched achievement', result);
      setIsFetchingInitial(false);
    }
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (achievementUnderEdit.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (!achievementUnderEdit.category) {
      isValid = false;
      setErrors((current: any) => ({...current, category: true}));
    }
    if (achievementUnderEdit.cert && !achievementUnderEdit.template) {
      isValid = false;
      setErrors((current: any) => ({...current, template: true}));
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSubmitting(true);

    let saveResult;
    if (isEditing && props.initialAchievement?.id) {
      saveResult = await achievementsService.updateAchievement({
        ...achievementUnderEdit,
        id: props.initialAchievement.id,
      });
      logger.debug('Updated achievement', saveResult);
    } else {
      saveResult = await achievementsService.createAchievement(achievementUnderEdit);
      logger.debug('Created achievement', saveResult);
    }

    setIsSubmitting(false);
    props.onComplete?.(saveResult.entity);
  };

  useEffect(() => {
    validate();
  }, [initialAchievement, achievementUnderEdit]);

  useEffect(() => {
    (async () => {
      await fetchInitialAchievement();
    })();
  }, [props.initialAchievement?.id]);

  useEffect(() => {
    if (initialAchievement) {
      setAchievementUnderEdit({
        name: initialAchievement.name,
        category: initialAchievement.category,
        template: initialAchievement.template,
        description: initialAchievement.description,
        cert: initialAchievement.cert,
        keywords: initialAchievement.keywords,
        type: initialAchievement.type,
        skills: initialAchievement.skills,
        achievements: initialAchievement.achievements,
        products: initialAchievement.products,
        validity: initialAchievement.validity,
        creditHours: initialAchievement.creditHours,
      });
    }
  }, [initialAchievement]);

  if (isFetchingInitial) {
    return <LoadingModal/>;
  }

  return (
    <Modal
      title={props.initialAchievement ? 'Edit Achievement' : 'Add Achievement'}
      bodyClassName={'lesson-editor'}
      onCloseRequested={props.onCancel}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
          disabled={isSubmitting}
        />
      }

      body={
        <form name="form.edit">
          <div className="row-fluid">
            <input
              value={achievementUnderEdit.name}
              onChange={(e) => setAchievementUnderEdit((current) => ({
                ...current,
                name: e.target.value,
              }))}
              type="text"
              className={classNames('span12', {'invalid': errors.name})}
              required
              aria-label={localizeText('Name')}
            />
            <div className="info">
              {localizeText('Name')}
            </div>
          </div>
          <div className="row-fluid">
            <CategoryPicker
              placeholder={''}
              isInvalid={errors.category}
              clearable={false}
              selectedCategories={achievementUnderEdit.category ? [achievementUnderEdit.category] : []}
              onChange={(newCategories) => {
                setAchievementUnderEdit((current) => ({
                  ...current,
                  category: newCategories.length > 0 ? newCategories[0] : undefined,
                }));
              }}
            />
            <br/>
            <div className="info" style={{marginTop: -17}}>
              {localizeText('Category')}
            </div>
          </div>
          <div className="row-fluid">
            <DynamicSelect<Skill>
              placeholder={localizeText('Skills')}
              multiple={true}
              fetch={(filter) => skillsService.fetchSkills(filter)}
              value={achievementUnderEdit.skills}
              onSelect={(newSkills) => setAchievementUnderEdit((current) => ({
                ...current,
                skills: newSkills,
              }))}
              isOptionSelected={(skill) => achievementUnderEdit.skills.some((s) => s.id === skill.id)}
            />
            <br/>
            <div className="info">
              {localizeText('Needed Skills')}
            </div>
          </div>
          <div className="row-fluid">
            <DynamicSelect<Achievement>
              placeholder={__('Achievements')}
              multiple={true}
              label={localizeText('Needed Achievements')}
              value={achievementUnderEdit.achievements}
              fetch={async (filter) => await achievementsService.fetchAchievements(filter.search)}
              isOptionSelected={(achievement) => achievementUnderEdit.achievements.some((a) => a.id === achievement.id)}
              onSelect={(newAchievements) => setAchievementUnderEdit((current) => ({
                ...current,
                achievements: newAchievements,
              }))}
            />
            <br/>
            <div className="info">
              {localizeText('Needed Achievements')}
            </div>
          </div>
          <div className={'row-fluid'}>
            <div className="span2">
              <div className="span12 text-center ">
                <SwitchInput
                  on={achievementUnderEdit.cert}
                  label={localizeText('Certificate')}
                  onChange={(nowHasCert) => setAchievementUnderEdit((current) => ({
                    ...current,
                    cert: nowHasCert,
                  }))}
                />
              </div>
              <div className="info">{__('Certificate')}</div>
            </div>
            {achievementUnderEdit.cert &&
              <div className="span10">
                <DynamicSelect<Template>
                  isInvalid={errors.template}
                  label={localizeText('Certificate Template')}
                  value={achievementUnderEdit.template ? [achievementUnderEdit.template] : []}
                  fetch={async (filter) => await templatesService.fetchTemplates(filter)}
                  isOptionSelected={(template) => template.id === achievementUnderEdit.template?.id}
                  onSelect={(newTemplates) => setAchievementUnderEdit((current) => ({
                    ...current,
                    template: newTemplates.length > 0 ? newTemplates[0] : undefined,
                  }))}
                />
                <br/>
                <div style={{marginTop: -18}} className="info">{__('Certificate Template')}</div>
              </div>
            }
          </div>
          <div className="row-fluid">
            <textarea
              value={achievementUnderEdit.keywords}
              aria-label={localizeText('Keywords')}
              onChange={(e) => setAchievementUnderEdit((current) => ({
                ...current,
                keywords: e.target.value,
              }))}
              className="span12" rows={5}
            />
            <div className="info">{__('Keywords')}</div>
          </div>
          <div className="row-fluid">
            <RichTextInput
              label={localizeText('Description')}
              value={achievementUnderEdit.description}
              onChange={(newDescription) => setAchievementUnderEdit((current) => ({
                ...current,
                description: newDescription,
              }))}
            />
            <div style={{marginTop: 10}} className="info">{__('Description')}</div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <input
                value={achievementUnderEdit.creditHours}
                aria-label={localizeText('Credit Hours')}
                onChange={(e) => setAchievementUnderEdit((current) => ({
                  ...current,
                  creditHours: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : undefined,
                }))}
                type="text"
                className="span12"
              />
              <div className="info">{__('Credit Hours')}</div>
            </div>
            <div className="span6">
              <input
                value={achievementUnderEdit.validity}
                aria-label={localizeText('Validity (in days)')}
                onChange={(e) => setAchievementUnderEdit((current) => ({
                  ...current,
                  validity: e.target.value,
                }))}
                type="text"
                className="span12"
              />
              <div className="info">{__('Validity (in days)')}</div>
            </div>
          </div>
          <AccessControlled feature={AppFeature.EditSystemResources}>
            <div className="row-fluid">
              <DynamicSelect<Product>
                aria-label={localizeText('Products')}
                placeholder={''}
                multiple={true}
                value={achievementUnderEdit.products}
                fetch={async (filter) => await productsService.fetchProducts(filter.search)}
                isOptionSelected={(product) => achievementUnderEdit.products.some((a) => a.id === product.id)}
                onSelect={(newProducts) => setAchievementUnderEdit((current) => ({
                  ...current,
                  products: newProducts,
                }))}
              />
              <br />
              <div style={{marginTop: -15}} className="info">{__('Products')}</div>
            </div>
          </AccessControlled>
          <div className="row-fluid">
            <FileUploadInput
              buttonText={__('Add Image')}
              initialFiles={props.initialAchievement?.image ? [props.initialAchievement.image] : []}
              onChange={(newFiles) => {
                setAchievementUnderEdit((current) => ({
                  ...current,
                  image: newFiles[0],
                }));
              }}
            />
          </div>
        </form>
      }
    />
  );
};
