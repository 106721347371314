import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {localizeText} from 'i18n/localize';
import {SurveySummary} from 'api/surveys/Survey';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LearningView} from 'views/learning/LearningView';
import {Resource} from 'api/resources/Resource';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {SkillCategoryWithStatus, SkillInventorySurvey} from 'views/skills/SkillInventorySurvey';
import {PickFirstSkillView} from 'views/skills/PickFirstSkillView';
import {FirstGoalView} from 'views/goals/FirstGoalView';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {Skill} from 'api/skills/Skill';

/**
 * The user hopes and dreams view
 */
export const DreamsDashboardView = () => {
  const logger = useLogger(DreamsDashboardView.name);
  const {push: pushRoute} = useHistory();
  const {surveysService, resourcesService} = useApiContext();
  const RECOMMENDATION_LIMIT = 3;
  const RECOMMENDATION_MIN_WEIGHT = 10;

  const [surveys, setSurveys] = useState<SurveySummary[]>([]);
  const [isFetchingSurveys, setIsFetchingSurveys] = useState<boolean>(true);
  const [surveyResults, setSurveyResults] = useState<any[]>([]);
  const [isFetchingSurveyResults, setIsFetchingSurveyResults] = useState<boolean>(true);
  const [shownResource, setShownResource] = useState<Resource | undefined>(undefined);
  const [showSkillInventory, setShowSkillInventory] = useState<boolean>(false);
  const [skillInventoryResults, setSkillInventoryResults] = useState<SkillCategoryWithStatus[]>([]);
  const [showPickFirstSkill, setShowPickFirstSkill] = useState<boolean>(false);
  const [firstGoalSkill, setFirstGoalSkill] = useState<Skill | undefined>(undefined);

  const surveyResult = surveyResults.length > 0 ? JSON.parse(surveyResults[0].result.replace(/'/g, '"')) : undefined;
  let hopes = [];
  let obstacles = [];
  if (surveyResult) {
    hopes = surveyResult[0].hopes
        .filter((resultHope: any) => resultHope.value >= RECOMMENDATION_MIN_WEIGHT)
        .sort((a: any, b: any) => {
          if ( a.value > b.value ) {
            return -1;
          } else if ( a.value < b.value ) {
            return 1;
          } else {
            return 0;
          }
        })
        .splice(0, RECOMMENDATION_LIMIT);

    obstacles = surveyResult[1].obsticles // spelling is wrong in API response
        .filter((resultObstacle: any) => resultObstacle.value >= RECOMMENDATION_MIN_WEIGHT)
        .sort((a: any, b: any) => {
          if ( a.value > b.value ) {
            return -1;
          } else if ( a.value < b.value ) {
            return 1;
          } else {
            return 0;
          }
        })
        .splice(0, RECOMMENDATION_LIMIT);
  }

  const fetchSurveys = async () => {
    setIsFetchingSurveys(true);
    try {
      const fetchedSurveys = await surveysService.fetchSurveySummaries();
      setSurveys(fetchedSurveys);
      logger.debug('Fetched surveys', fetchedSurveys);
    } catch (e) {
      logger.error('Error fetching surveys', e);
    }
    setIsFetchingSurveys(false);
  };

  const fetchSurveyResults = async () => {
    setIsFetchingSurveyResults(true);
    try {
      const fetchedSurveyResults = await surveysService.fetchSurveyResults();
      setSurveyResults(fetchedSurveyResults);
      logger.debug('Fetched survey results', fetchedSurveyResults);
    } catch (e) {
      logger.error('Error fetching survey results', e);
    }
    setIsFetchingSurveyResults(false);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([fetchSurveys(), fetchSurveyResults()]);
    })();
  }, []);

  return (
    <PageView className={'dreams'} bodyClassName={'plan-overview'}>
      {(isFetchingSurveys || isFetchingSurveyResults) && <LoadingIndicator />}

      {shownResource &&
        <LearningView
          resourceId={shownResource.id}
          onCloseRequested={() => setShownResource(undefined)}
          onCompleted={() => {
            setShownResource(undefined);
            setShowSkillInventory(true);
          }}
        />
      }

      {showSkillInventory &&
        <SkillInventorySurvey
          onComplete={(results) => {
            setSkillInventoryResults(results);
            setShowPickFirstSkill(true);
            setShowSkillInventory(false);
          }}
        />
      }

      {showPickFirstSkill &&
        <PickFirstSkillView
          skillInventoryResults={skillInventoryResults}
          onComplete={(skill) => {
            setFirstGoalSkill(skill);
            setShowPickFirstSkill(false);
          }}
        />
      }

      {firstGoalSkill &&
        <FirstGoalView
          skill={firstGoalSkill}
          onComplete={() => {
            pushRoute(AppRoute.DashboardContext + AppRoute.Goals + '?firstGoal=true');
          }}
        />
      }

      <div className="row-fluid">
        <div className="span12 pack gtky">
          <div className="headline">
            <h1>Everything Starts With You!</h1>
            <p>Let&rsquo;s start a conversation to learn about your hopes and dreams.</p>
          </div>

          <div className="vidhold">
            <iframe
              src="https://player.vimeo.com/video/425167817?title=0&byline=0&portrait=0&autoplay=1"
              width="640" height="300"
              allow="autoplay; fullscreen" allowFullScreen
              frameBorder="0"
              aria-label="Getting Started Video"
            />
          </div>

          {surveys.map((survey) => (
            <div
              key={survey.id}
              className="btn btn-info"
              onClick={async () => {
                const createdResource = await resourcesService.createResource(survey);
                logger.info('Created resource', createdResource);
                setShownResource(createdResource);
              }}
            >
              {survey.name}
              {' '}
              <i className="icon-right-open"/>
            </div>
          ))}
        </div>
      </div>

      {!surveyResult &&
        <div className="row-fluid">
          <div className="span12 pack  gtky lower">
            <div className="row-fluid">
              <div className="span4 steps">
                <div className="circle">1</div> <b>Share</b><br /> what excites you in life
              </div>
              <div className="span4 steps">
                <div className="circle">2</div> <b>Identify</b><br /> your opportunities to grow
              </div>
              <div className="span4 steps">
                <div className="circle">3</div> <b>Make a Plan</b><br /> to realize your hopes and dreams!
              </div>
            </div>
          </div>
        </div>
      }

      {surveyResult &&
        <div className="row-fluid">
          <div className="pack clearfix span12 gtky lower">
            <div className="headline">
              <h1>Skills Inventory</h1>
              <p>
                Tell us about your strengths.Your answers let us know that
                these sections of the skills inventory would be the best to
                complete to get started on your individual plan.
              </p>

              <div className="body">
                <div className="span4 pill">
                  <h2>{localizeText('Hopes & Dreams')}</h2>
                  {hopes.map((hope: any, i: number) => (
                    <div key={i}>{hope.label.substring(3)}</div>
                  ))}
                </div>
                <div className="span4 pill">
                  <h2>{localizeText('Obstacles')}</h2>
                  {obstacles.map((obstacle: any, i: number) => (
                    <div key={i}>{obstacle.label.substring(3)}</div>
                  ))}
                </div>

                <AccessControlled feature={AppFeature.MakeAssessment}>
                  <div className="span3 pill btn" onClick={() => setShowSkillInventory(true)}>
                    <i className="icon icon-play pull-right" />
                    <h3>Take Skills Inventory</h3>
                  </div>
                </AccessControlled>
              </div>
            </div>
          </div>
        </div>
      }
    </PageView>
  );
};
