import {AxiosInstance} from 'axios';
import {UserFile} from 'api/files/UserFile';

/**
 * Provides API file functions
 *
 * This is distinct from _file uploads_ as file uploads refer to uploading
 * new files to the API, whereas files as referred to here means a tracked
 * file entity in the API.
 */
export class UserFilesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) { }

  /**
   * Fetches files attached to the current user
   */
  async fetchUserFiles(): Promise<UserFile[]> {
    const response = await this.httpClient.post('/student/files/list');
    return response.data.list;
  }
}
