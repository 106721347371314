import React from 'react';
import {AuthResult} from 'api/auth/AuthResult';
import {createContext, PropsWithChildren, useContext} from 'react';
import {useSessionContext} from 'contexts/SessionContext';
import {useApiContext} from 'api/ApiContext';
import {LogoutResult} from 'api/auth/LogoutResult';
import {useLogger} from 'logging/logging';
import {SessionIdentity} from 'api/sessions/SessionIdentity';
import {RegisterUserDto} from 'api/auth/RegisterUserDto';

export interface AuthContextInterface {
  isAuthenticated: boolean,
  currentUser?: SessionIdentity,
  authenticateUser: (username: string, password: string) => Promise<AuthResult>,
  logoutUser: () => Promise<LogoutResult>,
  registerUser: (user: RegisterUserDto) => Promise<{ok: boolean}>,
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  currentUser: undefined,
  authenticateUser: () => Promise.resolve({ok: false}),
  logoutUser: () => Promise.resolve({ok: true}),
  registerUser: () => Promise.resolve({ok: false}),
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = (props: PropsWithChildren<{}>) => {
  const logger = useLogger(AuthContextProvider.name);

  const {authService} = useApiContext();
  const {sessionDetails, fetchSessionDetails} = useSessionContext();

  const currentUser = sessionDetails?.identity;
  const isAuthenticated = sessionDetails?.identity?.authenticated === true;

  const authenticateUser = async (username: string, password: string) => {
    try {
      const authResult = await authService.authenticateUser(username, password);
      if (authResult.ok) {
        await fetchSessionDetails();

        // TODO: fix this causing sign in problems
        // if (sessionDetails?.ok) {
        //   window.UserVoice?.push(['identify', {
        //     email: sessionDetails?.identity.email,
        //     name: sessionDetails?.identity.name,
        //     type: sessionDetails?.role?.name,
        //   }]);
        // }
        logger.info('Authenticated user');
      } else {
        logger.info('Failed user authentication', authResult);
      }

      return authResult;
    } catch (e) {
      logger.error('Error authenticating user', e);
      return {ok: false};
    }
  };

  const logoutUser = async () => {
    try {
      const logoutResult = await authService.logoutUser();
      if (logoutResult.ok) {
        await fetchSessionDetails();
      }
      logger.info('Logged user out');
      return logoutResult;
    } catch (e) {
      logger.error('Error logging out', e);
      return {ok: false};
    }
  };

  const registerUser = async (user: RegisterUserDto) => {
    const result = await authService.registerUser(user);
    if (result.ok) {
      await fetchSessionDetails();
    }
    return result;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        authenticateUser,
        logoutUser,
        registerUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
