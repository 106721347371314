import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {AssessmentWithRelations} from 'api/assessments/Assessment';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LoadingModal} from 'components/LoadingModal';
import {SkillStatus} from 'api/skills/SkillStatus.enum';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {PrintModal} from 'components/PrintModal';
import {SkillInventoryPrintView} from 'views/plan/SkillInventoryPrintModal';
import {Category, CategoryWithParent} from 'api/categories/Category';
import {SkillAssessment} from 'api/assessments/SkillAssessment';

export interface AssessmentResourceViewProps {
  assessmentId: number,
  onCloseRequested?: () => any,
}

type AssessmentCategory = {
  category: Category,
  comment: string,
  subCategories: {category: CategoryWithParent, skillAssessments: SkillAssessment[]}[],
}

export const AssessmentResourceView = (props: AssessmentResourceViewProps) => {
  const logger = useLogger(AssessmentResourceView.name);
  const {assessmentsService, categoriesService} = useApiContext();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [assessment, setAssessment] = useState<AssessmentWithRelations | null>(null);
  const [assessmentCategories, setAssessmentCategories] = useState<AssessmentCategory[]>([]);
  const [showPrint, setShowPrint] = useState(false);

  const loadAssessment = async () => {
    const fetchedAssessment = await assessmentsService.fetchAssessment(props.assessmentId);
    setAssessment(fetchedAssessment);
    logger.debug('Fetched assessment', fetchedAssessment);

    const categoryGroupedAssessments = assessmentsService.groupSkillAssessmentsByCategory(
        fetchedAssessment?.ilries ?? [],
    );

    const topLevelCategories: Category[] = [];
    categoryGroupedAssessments.forEach((assessment) => {
      let topCategory = assessment.category;
      while (topCategory.parent) {
        topCategory = topCategory.parent;
      }
      if (!topLevelCategories.find((c) => c.id === topCategory.id)) {
        topLevelCategories.push(topCategory);
      }
    });

    setAssessmentCategories(topLevelCategories.map((topLevelCategory) => ({
      category: topLevelCategory,
      comment: fetchedAssessment.comments.find((c) => c.category.id === topLevelCategory.id)?.comment ?? '',
      subCategories: categoryGroupedAssessments.filter(
          (c) => categoriesService.getTopLevelAncestorCategory(c.category).id === topLevelCategory.id,
      ),
    })));
  };

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      await loadAssessment();
      setIsFetching(false);
    })();
  }, []);

  if (isFetching) {
    return <LoadingModal/>;
  }

  return (
    <Modal
      title={__('Inventory')}
      className={'tall wide'}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          variant={ButtonVariant.Print}
          onClick={() => setShowPrint(true)}
        />
      }

      body={
        <Fragment>
          {showPrint && assessment &&
            <PrintModal
              view={<SkillInventoryPrintView skillInventory={assessment}/>}
              onCloseRequested={() => setShowPrint(false)}
            />
          }

          <h1>Inventory for {assessment?.owner.name} by {assessment?.author.name}</h1>
          {assessmentCategories.map((assessmentCategory) => (
            <div className="category" key={assessmentCategory.category.id}>
              <div className="heading">{assessmentCategory.category.name}</div>
              <p>{assessmentCategory.comment}</p>

              {assessmentCategory.subCategories.map((subcategory) => (
                <>
                  {subcategory.skillAssessments.length > 0 &&
                  <>
                    <div className="heading">
                      {subcategory.category.parent && subcategory.category.parent.name + ' / '}
                      {subcategory.category.name}
                    </div>

                    <table className="list bottom-offset-10">
                      <thead className="light">
                        <tr>
                          <td>Name</td>
                          <td className="text-center" width="20">Needs Support</td>
                          <td className="text-center" width="20">I have learned</td>
                          <td className="text-center" width="20">I am learning</td>
                          <td className="text-center" width="20">I need to learn</td>
                          <td className="text-center" width="20">No experience</td>
                        </tr>
                      </thead>
                      <tbody>
                        {subcategory.skillAssessments.map((skillAssessment) => (
                          <tr key={skillAssessment.id}>
                            <td>{skillAssessment.name}</td>
                            <td className="text-center">
                              {skillAssessment.support && <i className="icon-check" />}
                            </td>
                            <td className="text-center">
                              {skillAssessment.state === SkillStatus.Completed && <i className="icon-check" />}
                            </td>
                            <td className="text-center">
                              {skillAssessment.state === SkillStatus.InProgress && <i className="icon-check" />}
                            </td>
                            <td className="text-center">
                              {skillAssessment.state === SkillStatus.Recommended && <i className="icon-check" />}
                            </td>
                            <td className="text-center">
                              {skillAssessment.state === SkillStatus.NonApplicable && <i className="icon-check" />}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                  }
                </>
              ))}
              {/* <p ng-bind-html="category.comment | nl2br " />*/}
            </div>
          ))}
        </Fragment>
      }
    />
  );
};
