import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface DateInputProps {
  value?: Date,
  onChange: (newValue: Date) => any,
  placeholder?: string,
  className?: string,
  wrapperClassName?: string,
  dateFormat?: string,
}

export const DateInput = (props: DateInputProps) => {
  return (
    <ReactDatePicker
      selected={props.value}
      onChange={props.onChange}
      placeholderText={props.placeholder}
      aria-label={props.placeholder}
      className={props.className ?? ''}
      wrapperClassName={props.wrapperClassName ?? ''}
      dateFormat={props.dateFormat ?? 'yyyy-MM-dd'}
    />
  );
};
