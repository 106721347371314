import React, {
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import {useSchoolContext} from 'contexts/SchoolContext';
import {useLocalStorage} from 'hooks/useLocalStorage';
import {Helmet} from 'react-helmet';
import {useSessionContext} from 'contexts/SessionContext';
import {UserRoleType} from 'api/users/UserRole';

/**
 * The theme context manages user font size settings and applies
 * theming (color, custom styles, etc) on user and/or school context changes
 */

export interface ThemeContextInterface {
  fontSize: ThemeFontSize,
  setFontSize: (newSize: ThemeFontSize) => any,
  incrementFontSize: () => any,
  decrementFontSize: () => any,
  layoutColor: string | undefined,
  isHideWelcomeRequested: boolean,
  setHideWelcomeRequested: (shouldShow: boolean) => any,
}

export enum ThemeFontSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

export const ThemeContext = createContext<ThemeContextInterface>({
  fontSize: ThemeFontSize.Medium,
  setFontSize: () => {},
  incrementFontSize: () => {},
  decrementFontSize: () => {},
  layoutColor: undefined,
  isHideWelcomeRequested: false,
  setHideWelcomeRequested: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

const STORAGE_KEY_FONT_SIZE = 'themeFontSize';
const STORAGE_KEY_HIDE_WELCOME = 'hideWelcome';

export const ThemeContextProvider = (props: PropsWithChildren<{}>) => {
  const {sessionDetails} = useSessionContext();
  const {selectedSchool} = useSchoolContext();
  const [fontSize, setFontSize] = useLocalStorage<ThemeFontSize>(
      STORAGE_KEY_FONT_SIZE,
      ThemeFontSize.Medium,
  );
  const [isHideWelcomeRequested, setHideWelcomeRequested] =
    useLocalStorage<boolean>(STORAGE_KEY_HIDE_WELCOME, false);

  const colorConfig = selectedSchool?.config ?? {
    adminColor: '#287b7b',
    teacherColor: '#8cc63e',
    studentColor: '#57B1C7',
    parentColor: '#287b7b',
  };

  let layoutColor = undefined;
  if (sessionDetails?.role) {
    if (
      [
        UserRoleType.SystemAdmin,
        UserRoleType.Admin,
        UserRoleType.Editor,
      ].includes(sessionDetails.role.type)
    ) {
      layoutColor = colorConfig.adminColor;
    } else if (
      [
        UserRoleType.Teacher,
        UserRoleType.KolManager,
        UserRoleType.InstructorAssistant,
      ].includes(sessionDetails.role.type)
    ) {
      layoutColor = colorConfig.teacherColor;
    } else if (
      [
        UserRoleType.Student,
        UserRoleType.IlStudent,
        UserRoleType.Ilri,
      ].includes(sessionDetails.role.type)
    ) {
      layoutColor = colorConfig.studentColor;
    } else if (
      [
        UserRoleType.Parent,
        UserRoleType.Support,
      ].includes(sessionDetails.role.type)
    ) {
      layoutColor = colorConfig.parentColor;
    }
  }

  let fontSizePixels = 14;
  if (fontSize === ThemeFontSize.Medium) {
    fontSizePixels = 16;
  } else if (fontSize === ThemeFontSize.Large) {
    fontSizePixels = 18;
  }

  const incrementFontSize = () => {
    if (fontSize === ThemeFontSize.Small) {
      setFontSize(ThemeFontSize.Medium);
    } else if (fontSize === ThemeFontSize.Medium) {
      setFontSize(ThemeFontSize.Large);
    }
  };

  const decrementFontSize = () => {
    if (fontSize === ThemeFontSize.Large) {
      setFontSize(ThemeFontSize.Medium);
    } else if (fontSize === ThemeFontSize.Medium) {
      setFontSize(ThemeFontSize.Small);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        fontSize,
        setFontSize,
        incrementFontSize,
        decrementFontSize,
        layoutColor,
        isHideWelcomeRequested,
        setHideWelcomeRequested,
      }}
    >
      <Helmet>
        <style>{`body {font-size: ${fontSizePixels}px}`}</style>
        {selectedSchool?.style &&
          <link rel="stylesheet" href={selectedSchool.style.url} />
        }
      </Helmet>

      {props.children}
    </ThemeContext.Provider>
  );
};
