import React from 'react';
import {Button, ButtonProps, ButtonVariant} from 'components/buttons/Button';

export interface ButtonConfirmProps extends ButtonProps {}

export const ButtonConfirm = (props: ButtonConfirmProps) => {
  return (
    <Button
      onClick={props.onClick}
      className={'btn-success'}
      iconCode={'icon-ok-circled'}
      variant={ButtonVariant.Confirm}
    />
  );
};
