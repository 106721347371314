import React, {ReactElement} from 'react';
import {InViewObserver} from 'components/InViewObserver';

/**
 * Skeleton data grid with "bottom reached" callback for pagination
 */

export interface DataGridProps {
  header: ReactElement,
  body: ReactElement,
  onBottomReached?: () => any,
  isLoading?: boolean,
}

export const DataGrid = (props: DataGridProps) => {
  return (
    <div className={'grid'}>
      <table className={'list'}>
        <thead>
          {props.header}
        </thead>
        <tbody>
          {props.body}
        </tbody>
      </table>
      {!props.isLoading && <InViewObserver onInView={props.onBottomReached} />}
    </div>
  );
};
