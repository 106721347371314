import React from 'react';
import {PageView} from 'layout/PageView';
import {Link} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

/**
 * A 404 "Page not found" view
 */
export const PageNotFoundView = () => {
  return (
    <PageView>
      <h3>Page not found.</h3>
      <p><Link to={AppRoute.Home}>Go home</Link></p>
    </PageView>
  );
};
