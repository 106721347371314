import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Category} from 'api/categories/Category';
import {useApiContext} from 'api/ApiContext';
import {Button, ButtonVariant} from 'components/buttons/Button';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {Lesson} from 'api/lessons/Lesson';
import {ApiRoute} from 'api/ApiRoute';
import {CheckBox} from 'components/forms/CheckBox';
import {Tooltip} from 'components/Tooltip';
import {useLogger} from 'logging/logging';
import {SearchInput} from 'components/SearchInput';
import {LessonDetailsModal} from 'views/lessons/LessonDetailsModal';

export interface LessonSearchModalProps {
  onCloseRequested?: () => any,
  onLessonsSelected?: (lessons: Lesson[]) => any,
}

export const LessonSearchModal = (props: LessonSearchModalProps) => {
  const logger = useLogger(LessonSearchModal.name);
  const {entityListService} = useApiContext();

  const [categories, setCategories] = useState<Category[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [showDetailsLesson, setShowDetailsLesson] = useState<Lesson | null>(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const [selectedLessons, setSelectedLessons] = useState<Lesson[]>([]);
  const [search, setSearch] = useState('');

  const loadCategories = async () => {
    const fetchedCategories = await entityListService.fetchEntityList(ApiRoute.Categories, {});
    logger.debug('Fetched categories', fetchedCategories);
    setCategories(fetchedCategories);
  };

  const loadLessons = async () => {
    try {
      const fetchedLessons = await entityListService.fetchEntityList(ApiRoute.Lessons, {
        filters: {
          categories: selectedCategoryIds,
        },
      });
      logger.debug('Fetched lessons', fetchedLessons);
      setLessons(fetchedLessons);
    } catch (e) {
      logger.error('Error fetching lessons', e);
    }
  };

  const toggleCategory = (id: number) => {
    setSelectedCategoryIds((current) => {
      let newCategoryIds = [...current];
      if (newCategoryIds.includes(id)) {
        newCategoryIds = newCategoryIds.filter((cid) => cid !== id);
      } else {
        newCategoryIds.push(id);
      }
      return newCategoryIds;
    });
  };

  const toggleLesson = (lesson: Lesson) => {
    setSelectedLessons((current) => {
      let newLessons = [...current];
      if (newLessons.find((l) => l.id === lesson.id)) {
        newLessons = newLessons.filter((l) => l.id !== lesson.id);
      } else {
        newLessons.push(lesson);
      }
      return newLessons;
    });
  };

  const submit = () => {
    props.onLessonsSelected?.(selectedLessons);
  };

  useEffect(() => {
    (async () => {
      await loadCategories();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadLessons();
    })();
  }, [selectedCategoryIds]);

  return <Modal
    title={'Lesson Search'}
    className={'tall wide'}
    onCloseRequested={props.onCloseRequested}
    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        onClick={submit}
      />
    }
    body={
      <div className="lesson-search">
        {showDetailsLesson &&
          <LessonDetailsModal
            lessonId={showDetailsLesson.id}
            onCloseRequested={() => setShowDetailsLesson(null)}
          />
        }

        <div className="head">
          <div className="text">Select Categories</div></div>
        <div className="lister" style={{overflowY: 'auto'}}>
          {categories.map((category) => (
            <div
              key={category.id}
              className={classNames('category circle', {'selected': selectedCategoryIds.includes(category.id)})}
              onClick={() => toggleCategory(category.id)}
              aria-label="select category"
            >
              <div className="main" style={{borderColor: category.color}} />
              <div className="name" >{category.name}</div>
            </div>
          ))}
          <div style={{clear: 'both', width: '100%'}} />
        </div>
        <div style={{overflowY: 'auto', position: 'absolute', top: 0, left: 251, bottom: 0, right: 0}}>
          <span style={{margin: '5px 10px', width: '90%', marginTop: 10}}>
            <SearchInput
              placeholder={__('Keywords')}
              aria-label={__('Keywords')}
              value={search}
              onChange={(newValue) => setSearch(newValue)}
              hideIcon={true}
            />
          </span>
          <table className="list header">
            <thead className="light">
              <tr>
                <td width="10">&nbsp;</td>
                <td width="60%">{__('Lesson')}</td>
                <td>{__('Category')}</td>
                <td width="40">&nbsp;</td>
              </tr>
            </thead>
          </table>
          <div className="results">
            <table className="list">
              {lessons.map((lesson) => (
                <tr key={lesson.id}>
                  <td width="10" className="icons">
                    <CheckBox
                      checked={selectedLessons.find((l) => l.id === lesson.id) !== undefined}
                      onClick={() => toggleLesson(lesson)}
                      aria-label="Select Lesson"
                    />
                  </td>
                  <td width="60%">{lesson.name}</td>
                  <td>
                    <div
                      className="label"
                      style={{
                        borderColor: lesson.category.color,
                        boxShadow: `1px 1px 10px ${lesson.category.color}`,
                        padding: '3px 5px',
                        marginLeft: (lesson.category.level-1) * 5,
                      }}
                    >
                      {lesson.category.name}
                    </div>
                  </td>
                  <td width="40">
                    <div className="btn-group">
                      <Tooltip title={__('View')}>
                        <a
                          className="btn btn-icon btn-mini"
                          aria-label={__('View')}
                          onClick={() => setShowDetailsLesson(lesson)}
                        >
                          <i className="icon-zoom-in" />
                        </a>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div style={{clear: 'both'}} />
      </div>
    }

    footer={<div style={{height: 16}}/>}
  />;
};
