import React from 'react';
import classNames from 'classnames';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import {QuizQuestionWithAnswer} from 'api/quizzes/QuizSolution';

export interface QuizDragDropQuestionProps {
  question: QuizQuestionWithAnswer,
  onAnswersChanged?: (newQuestion: QuizQuestionWithAnswer) => any,
}

export const QuizDragDropQuestion = (props: QuizDragDropQuestionProps) => {
  const answers = props.question.answers;
  const matchedAnswers = props.question.correct ?? [];
  const unMatchedAnswers = answers.filter((answer) => !matchedAnswers.some((a) => a.id === answer.id));

  const onDragEnd = (dropResult: DropResult) => {
    const dropArea = dropResult.destination?.droppableId;
    if (!dropArea) {
      return;
    }

    if (dropArea === 'options') {
      const answerIndex = props.question.correct?.findIndex(
          (answer) => answer.id.toString() === dropResult.draggableId,
      );
      if (answerIndex === undefined || answerIndex < 0) {
        return;
      }
      const newAnswers = [...props.question.correct ?? []];
      newAnswers.splice(answerIndex, 1);

      props.onAnswersChanged?.({
        ...props.question,
        correct: newAnswers,
      });
    } else {
      const answer = props.question.answers.find((answer) => answer.id.toString() === dropResult.draggableId);
      if (!answer) {
        return;
      }
      const newAnswers = [...props.question.correct ?? []];
      newAnswers.push(answer);

      props.onAnswersChanged?.({
        ...props.question,
        correct: newAnswers,
      });
    }
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#eee' : 'none',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    cursor: 'move',
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId={'options'}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{...getListStyle(snapshot.isDraggingOver)}}
              className={classNames(
                  'answers answers-list',
                  {'empty': unMatchedAnswers.length < 1},
              )}
            >
              <div className="placeholder">
                <h5>&nbsp;</h5>
              </div>

              <div className={classNames('quiz_answer', {'angular-ui-tree-empty': unMatchedAnswers.length < 1})}>
                {unMatchedAnswers.map((option, i) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id.toString()}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                        className="answer clearfix angular-ui-tree-node angular-ui-tree-handle"
                      >
                        <div
                          className="text html clearfix"
                          dangerouslySetInnerHTML={{__html: option.text}}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId={'responses'}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={classNames(
                  'correct-list',
                  {'empty': matchedAnswers?.length < 1},
              )}
            >
              <div className="placeholder">
                <h5>Drag item(s) that apply here.</h5>
              </div>
              <div className={classNames('quiz_answer', {'angular-ui-tree-empty': matchedAnswers.length < 1})}>
                {matchedAnswers.map((response, i) => (
                  <Draggable
                    key={response.id}
                    draggableId={response.id.toString()}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                        className="answer clearfix angular-ui-tree-node angular-ui-tree-handle"
                      >
                        <div
                          className="text html clearfix"
                          dangerouslySetInnerHTML={{
                            __html: response.text,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <div className="clearfix"/>
      </div>
    </DragDropContext>
  );
};
