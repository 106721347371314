import React, {Fragment, useState} from 'react';
import {Modal} from 'components/Modal';
import {Collage} from 'api/collages/Collage';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {CollageBoard} from 'views/collages/CollageBoard';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {GradeResourceModal} from 'views/resources/GradeResourceModal';

export interface CollageModalProps {
  collage: Collage,
  onCloseRequested?: () => any,
  onGraded?: () => any,
}

export const CollageModal = (props: CollageModalProps) => {
  const [currentCollage, setCurrentCollage] = useState<Collage>(props.collage);
  const [showGrade, setShowGrade] = useState<boolean>(false);

  return <Modal
    title={props.collage.name}
    className={'collage-view'}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Fragment>
        {props.collage.gradable &&
          <AccessControlled feature={AppFeature.StudentGrade}>
            <Button
              variant={ButtonVariant.Confirm}
              iconCode={'icon-check'}
              onClick={() => setShowGrade(true)}
            />
          </AccessControlled>
        }
        <Button
          variant={ButtonVariant.Print}
        />
      </Fragment>
    }

    body={
      <Fragment>
        {showGrade &&
          <GradeResourceModal
            resource={props.collage}
            onCloseRequested={() => setShowGrade(false)}
            onComplete={() => {
              setShowGrade(false);
              props.onCloseRequested?.();
            }}
          />
        }
        <CollageBoard
          collage={currentCollage}
          onChange={(newCollage) => setCurrentCollage(newCollage)}
        />
      </Fragment>
    }
  />;
};
