import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {
  basePathForSession, sectionMenuItemsForSession,
} from 'layout/menus/section-menu-items';
import {useSessionContext} from 'contexts/SessionContext';
import {useEntityViewHistoryContext} from 'contexts/EntityViewHistoryContext';

export const SectionMenu = () => {
  const routerLocation = useLocation();
  const {sessionDetails} = useSessionContext();
  const {pendingActivityCount} = useEntityViewHistoryContext();

  const basePath = basePathForSession(sessionDetails);
  const menuItems = sectionMenuItemsForSession(sessionDetails);

  return (
    <div
      style={{
        left: 0,
        right: 0,
        position: 'absolute',
        bottom: 20,
        textAlign: 'center',
        zIndex: 10,
      }}
    >
      <div className="section-menu btn-group" aria-label="Lower Menu">
        {menuItems.map((menuItem) => (
          <Link
            key={menuItem.route}
            to={basePath + menuItem.route}
            className={classNames('btn btn-default', {
              active: routerLocation.pathname === basePath + menuItem.route,
            })}
            aria-label={menuItem.title}
          >
            {menuItem.title}
            {menuItem.title === 'Activity Log' && pendingActivityCount > 0 &&
              <div className="badge badge-info" style={{top: -12, marginLeft: -1, marginRight: -5}}>
                {pendingActivityCount}
              </div>
            }
          </Link>
        ))}
      </div>
    </div>
  );
};
