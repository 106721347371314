import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {CreateQuizQuestionAnswerDto} from 'api/quizzes/QuizQuestionAnswer';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {CheckBox} from 'components/forms/CheckBox';
import {__} from 'i18n/localize';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import classNames from 'classnames';
import {Draggable} from 'react-beautiful-dnd';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {SwitchInput} from 'components/SwitchInput';

export interface QuestionAnswerInputProps {
  answer: CreateQuizQuestionAnswerDto,
  questionType: QuizQuestionType,
  onChange?: (newAnswer: CreateQuizQuestionAnswerDto) => any,
  onRemoved?: () => any,
  forSurvey?: boolean,
}

export const QuestionAnswerInput = (props: QuestionAnswerInputProps) => {
  const {answer, questionType} = props;
  const menuRef = useRef<HTMLDivElement>(null);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleMenu = () => setShowMenu((current) => !current);
  const startEdit = () => {
    setShowEdit(true);
    setShowMenu(false);
  };
  const handleRemove = () => {
    setShowMenu(false);
    props.onRemoved?.();
  };

  const closeOnClick = useCallback((e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
    };
  }, [closeOnClick]);

  const menu = (
    <Fragment>
      {props.forSurvey && questionType === QuizQuestionType.Match &&
        <span>
          Hope
          {' '}
          <SwitchInput
            on={answer.hope}
            onChange={(nowOn) => props.onChange?.({
              ...answer,
              hope: nowOn,
            })}
          />
          {' '}
          Obstacle
        </span>
      }
      <div ref={menuRef} className={classNames('dropdown btn-group', {'open': showMenu})}>
        <a className="btn btn-icon dropdown-toggle" onClick={toggleMenu}>
          <i className="icon-cog"/>
        </a>
        <ul className="dropdown-menu pull-right" role="menu">
          <li><a onClick={startEdit}>
            <i className="icon-edit" /> <span>{__('Edit')}</span>
          </a></li>
          <li><a onClick={handleRemove}>
            <i className="icon-trash" /> <span>{__('Remove')}</span>
          </a></li>
        </ul>
      </div>
    </Fragment>
  );

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  return (
    <Fragment>
      {[QuizQuestionType.Bullets, QuizQuestionType.Multiple].includes(questionType) &&
        <div className={'row-fluid answer'}>
          {!props.forSurvey &&
            <CheckBox
              checked={answer.correct}
              onClick={() => {
                if (questionType === QuizQuestionType.Bullets) {
                  props.onChange?.({...answer, correct: true});
                } else if (questionType === QuizQuestionType.Multiple) {
                  props.onChange?.({...answer, correct: !answer.correct});
                }
              }}
            />
          }
          {props.forSurvey &&
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: 10}}>
              <div>
                <CategoryPicker
                  allowCache={true}
                  selectedCategories={answer.category ? [answer.category] : []}
                  onChange={(newCategories) => props.onChange?.({
                    ...answer,
                    category: newCategories.length > 0 ? newCategories[0] : undefined,
                  })}
                />
              </div>
              <div>
                Hope
                <SwitchInput
                  on={answer.hope ?? false}
                  onChange={(nowOn) => props.onChange?.({
                    ...answer,
                    hope: nowOn,
                  })}
                />
                Obstacle
              </div>
              <div>
                <input
                  value={answer.weight}
                  onChange={(e) => props.onChange?.({
                    ...answer,
                    weight: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0,
                  })}
                />
              </div>
            </div>
          }
          {menu}
          <div className="text">
            <RichTextInput
              value={answer.text}
              onChange={(newValue) => props.onChange?.({...answer, text: newValue})}
              placeholder={__('Click to edit this text')}
              hideOutline={true}
              alwaysRichEdit={showEdit}
              onBlur={() => setShowEdit(false)}
            />
          </div>
        </div>
      }

      {[QuizQuestionType.Open, QuizQuestionType.Instructions].includes(questionType) &&
        <div className="row-fluid answer">
          {menu}
          <div className="text">
            <RichTextInput
              value={answer.text}
              onChange={(newValue) => props.onChange?.({...answer, text: newValue})}
              placeholder={__('Click to edit this text')}
              hideOutline={true}
              alwaysRichEdit={showEdit}
              onBlur={() => setShowEdit(false)}
            />
          </div>
        </div>
      }

      {questionType === QuizQuestionType.Match &&
        <tr className="answer">
          <td>
            <RichTextInput
              value={answer.text}
              onChange={(newValue) => props.onChange?.({...answer, text: newValue})}
              placeholder={__('Click to edit this text')}
              hideOutline={true}
              alwaysRichEdit={showEdit}
              onBlur={() => setShowEdit(false)}
            />
            {props.forSurvey &&
              <CategoryPicker
                allowCache={true}
                selectedCategories={answer.category ? [answer.category] : []}
                clearable={false}
                onChange={(newCategories) => props.onChange?.({
                  ...answer,
                  category: newCategories.length > 0 ? newCategories[0] : undefined,
                })}
              />
            }
          </td>
          <td>
            <RichTextInput
              value={answer.match}
              onChange={(newValue) => props.onChange?.({...answer, match: newValue})}
              placeholder={__('Click to edit this text')}
              hideOutline={true}
            />
            {props.forSurvey &&
              <input
                placeholder={__('Weight')}
                value={answer.weight}
                onChange={(e) => props.onChange?.({
                  ...answer,
                  weight: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0,
                })}
              />
            }
          </td>
          <td>
            {menu}
          </td>
        </tr>
      }

      {questionType === QuizQuestionType.Order &&
        <Draggable draggableId={props.answer.order.toString()} index={props.answer.order}>
          {(provided, snapshot) => (
            <div
              className="answer"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
              )}
            >
              <div className="row-fluid answer">
                <div className="pull-left move-handle">
                  <i className="icon-reorder" />
                </div>

                {menu}

                <div className="text">
                  <RichTextInput
                    placeholder={__('Click to edit this text')}
                    value={answer.text}
                    onChange={(newText) => props.onChange?.({...answer, text: newText})}
                    hideOutline={true}
                    alwaysRichEdit={showEdit}
                    onBlur={() => setShowEdit(false)}
                  />
                </div>

                {props.forSurvey &&
                  <Fragment>
                    <div style={{maxWidth: 400}}>
                      <CategoryPicker
                        allowCache={true}
                        selectedCategories={answer.category ? [answer.category] : []}
                        clearable={false}
                        onChange={(newCategories) => props.onChange?.({
                          ...answer,
                          category: newCategories.length > 0 ? newCategories[0] : undefined,
                        })}
                      />
                    </div>
                    <div>
                      Hope{' '}
                      <SwitchInput
                        on={answer.hope}
                        onChange={(nowOn) => props.onChange?.({
                          ...answer,
                          hope: nowOn,
                        })}
                      />
                      {' '}Obstacle
                      {' '}
                      <input
                        placeholder={__('Weight')}
                        value={answer.weight}
                        onChange={(e) => props.onChange?.({
                          ...answer,
                          weight: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0,
                        })}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>
          )}
        </Draggable>
      }
    </Fragment>
  );
};
