import {AxiosInstance} from 'axios';
import {CreateEmailDto, Email, UpdateEmailDto} from 'api/email/Email';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API email functions
 */
export class EmailsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Creates a new email
   *
   * @param {CreateEmailDto} email
   */
  async createEmail(email: CreateEmailDto): Promise<SuccessResponse<Email>> {
    const response = await this.httpClient.post('/emails/save', email);
    return response.data;
  }

  /**
   * Creates a new email and adds it to a given course
   *
   * @param {number} courseId
   * @param {CreateEmailDto} email
   */
  async createCourseEmail(courseId: number, email: CreateEmailDto): Promise<SuccessResponse<Email>> {
    const createResult = await this.createEmail(email);
    await this.httpClient.post('/courses/addEmail', {
      course: courseId,
      email,
    });
    return createResult;
  }

  /**
   * Updates an email
   *
   * @param {UpdateEmailDto} email
   */
  async updateEmail(email: UpdateEmailDto): Promise<SuccessResponse<Email>> {
    const response = await this.httpClient.post('/emails/save', email);
    return response.data;
  }

  /**
   * Deletes an email
   *
   * @param {number} courseId
   * @param {number} emailId
   */
  async deleteEmail(courseId: number, emailId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/emails/remove', {
      course: courseId,
      email: emailId,
    });
    return response.data;
  }
}
