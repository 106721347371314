import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {ResourceType} from 'api/resources/ResourceType.enum';
import classNames from 'classnames';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {useApiContext} from 'api/ApiContext';
import {Quiz} from 'api/quizzes/Quiz';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';
import {SaveResourceDto} from 'api/resources/Resource';
import {SurveySummary} from 'api/surveys/Survey';
import {AccessControlled} from 'access-control/AccessControlled';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useLogger} from 'logging/logging';

export interface ResourceInputModalProps {
  onCloseRequested?: () => any,
  onComplete?: (resource: SaveResourceDto) => any,
}

export const ResourceInputModal = (props: ResourceInputModalProps) => {
  const logger = useLogger(ResourceInputModal.name);
  const {quizzesService, surveysService, fileUploadsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const [resourceUnderEdit, setResourceUnderEdit] = useState<SaveResourceDto>({name: '', type: ResourceType.File});
  const [typeIsSet, setTypeIsSet] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [title, setTitle] = useState<string>('');
  const [file, setFile] = useState<FileUpload | undefined>(undefined);
  const [movieTitle, setMovieTitle] = useState<string>('');
  const [movieUrl, setMovieUrl] = useState<string>('');
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [collageGraded, setCollageGraded] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const validate = () => {
    setErrors({});
    let isValid = true;

    const type = resourceUnderEdit.type;
    if (!typeIsSet) {
      isValid = false;
      setErrors((current: any) => ({...current, type: true}));
    }
    if (type === ResourceType.File) {
      if (title.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, title: true}));
      }
      if (!file) {
        isValid = false;
        setErrors((current: any) => ({...current, file: true}));
      }
    }
    if (type === ResourceType.Vimeo) {
      if (movieTitle.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, movieTitle: true}));
      }
      if (movieUrl.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, movieUrl: true}));
      }
    }
    if (type === ResourceType.Youtube) {
      if (movieUrl.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, movieUrl: true}));
      }
    }
    if (type === ResourceType.Quiz) {
      if (resourceUnderEdit.resource === undefined) {
        isValid = false;
        setErrors((current: any) => ({...current, quiz: true}));
      }
    }
    if (type === ResourceType.Survey) {
      if (resourceUnderEdit.resource === undefined) {
        isValid = false;
        setErrors((current: any) => ({...current, survey: true}));
      }
    }
    if (type === ResourceType.Link) {
      if (linkTitle.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, linkTitle: true}));
      }
      if (linkUrl.trim().length < 1) {
        isValid = false;
        setErrors((current: any) => ({...current, linkUrl: true}));
      }
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSaving(true);
    const type = resourceUnderEdit.type;
    const resourceCopy = {...resourceUnderEdit};

    if (type === ResourceType.File) {
      resourceCopy.resource = file;
    } else if (type === ResourceType.Youtube) {
      resourceCopy.resource = await fileUploadsService.addVideoLink(movieUrl);
    } else if (type === ResourceType.Vimeo) {
      resourceCopy.resource = await fileUploadsService.addVimeoVideo(movieTitle, movieUrl);
    } else if (type === ResourceType.Collage) {
      resourceCopy.resource = {name: 'New Collage', rated: collageGraded, type: ResourceType.Collage};
    } else if (type === ResourceType.Link) {
      resourceCopy.resource = {name: linkTitle, link: linkUrl, type: ResourceType.Link};
    }

    setIsSaving(false);
    logger.debug('new resource', resourceCopy);
    props.onComplete?.(resourceCopy);
  };

  useEffect(() => {
    validate();
  }, [resourceUnderEdit, typeIsSet, title, movieTitle, movieUrl, linkTitle, linkUrl]);

  useEffect(() => {
    setResourceUnderEdit((current) => ({...current, resource: undefined}));
  }, [resourceUnderEdit.type]);

  return <Modal
    title={__('Add Resource')}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button variant={ButtonVariant.Confirm} onClick={submit} />
    }

    body={
      <form name="form.edit">
        {isSaving && <LoadingIndicator fullscreen={true} />}

        <div className="row-fluid">
          <select
            value={typeIsSet ? resourceUnderEdit.type : undefined}
            onChange={(e) => {
              const newType = e.target.value as ResourceType;
              if (!newType) {
                setTypeIsSet(false);
              } else {
                setTypeIsSet(true);
                setResourceUnderEdit((current) => ({...current, type: newType}));
              }
            }}
            className={classNames('span12', {'invalid': errors.type})}
            aria-label="Resource Type"
          >
            <option value={''}>{__('Resource Type')}</option>
            <option value={ResourceType.File}>{__('File')}</option>
            <option value={ResourceType.Vimeo}>{__('Vimeo Link')}</option>
            <option value={ResourceType.Youtube}>{__('YouTube Link')}</option>
            <AccessControlled feature={'goals.add.quiz'}>
              <option value={ResourceType.Quiz}>{__('Quiz')}</option>
            </AccessControlled>
            <AccessControlled feature={'goals.add.quiz'}>
              <option value={ResourceType.Survey}>{__('SURVEY')}</option>
            </AccessControlled>
            <option value={ResourceType.Link}>{__('Link')}</option>
            <option value={ResourceType.Collage}>{__('Collage')}</option>
          </select>
        </div>

        {typeIsSet && <Fragment>
          {resourceUnderEdit.type === ResourceType.File &&
            <div className="row-fluid">
              <input
                placeholder={__('Title')}
                aria-label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className={classNames('span12', {'invalid': errors.title})}
              />
              <div className={'span6'}>
                <FileUploadInput
                  buttonText={__('Choose File')}
                  initialFiles={file ? [file] : []}
                  multiple={false}
                  onChange={(newFile) => setFile(newFile[0])}
                />
              </div>
            </div>
          }

          {resourceUnderEdit.type === ResourceType.Vimeo &&
            <div className={'row-fluid'}>
              <input
                placeholder={__('Movie Title')}
                type="text"
                aria-label="Movie Title"
                className={classNames('span12', {'invalid': errors.movieTitle})}
                value={movieTitle}
                onChange={(e) => setMovieTitle(e.target.value)}
              />
              <input
                placeholder={__('Vimeo Url (ex https://vimeo.com/12345678)')}
                className={classNames('span6', {'invalid': errors.movieUrl})}
                type="text"
                aria-label="Vimeo Url"
                value={movieUrl}
                onChange={(e) => setMovieUrl(e.target.value)}
              />
            </div>
          }

          {resourceUnderEdit.type === ResourceType.Youtube &&
            <div className={'row-fluid'}>
              <input
                placeholder={__('YouTube Url')}
                className={classNames('span12', {'invalid': errors.movieUrl})}
                type="text"
                value={movieUrl}
                aria-label="YouTube URL"
                onChange={(e) => setMovieUrl(e.target.value)}
              />
            </div>
          }

          {resourceUnderEdit.type === ResourceType.Quiz &&
            <DynamicSelect<Quiz>
              placeholder={__('Learning Check')}
              label="Learning check"
              multiple={false}
              optionLimit={-1}
              fetch={async (filter) => await quizzesService.fetchQuizzes(filter.search)}
              clearable={false}
              onSelect={(newQuizzes) => {
                if (newQuizzes.length > 0) {
                  setResourceUnderEdit((current) => ({...current, resource: newQuizzes[0]}));
                } else {
                  setResourceUnderEdit((current) => ({...current, resource: undefined}));
                }
              }}
              isInvalid={errors.quiz}
            />
          }

          {resourceUnderEdit.type === ResourceType.Survey &&
            <DynamicSelect<SurveySummary>
              placeholder={__('Survey')}
              label="Survey"
              multiple={false}
              optionLimit={-1}
              clearable={false}
              fetch={async (filter) => await surveysService.fetchSurveySummaries({search: filter.search})}
              onSelect={(newSurveys) => {
                if (newSurveys.length > 0) {
                  setResourceUnderEdit((current) => ({...current, resource: newSurveys[0]}));
                } else {
                  setResourceUnderEdit((current) => ({...current, resource: undefined}));
                }
              }}
              isInvalid={errors.survey}
            />
          }

          {resourceUnderEdit.type === ResourceType.Link &&
            <Fragment>
              <div className={'row-fluid'}>
                <input
                  placeholder={__('Link Title')}
                  className={classNames('span12', {'invalid': errors.linkTitle})}
                  type="text"
                  value={linkTitle}
                  aria-label="Link Title"
                  onChange={(e) => setLinkTitle(e.target.value)}
                />
              </div>
              <div className={'row-fluid'}>
                <input
                  placeholder={__('Link URL')}
                  className={classNames('span12', {'invalid': errors.linkUrl})}
                  type="text"
                  value={linkUrl}
                  aria-label="Link URL"
                  onChange={(e) => setLinkUrl(e.target.value)}
                />
              </div>
            </Fragment>
          }

          {resourceUnderEdit.type === ResourceType.Collage &&
            <div className={'row-fluid'}>
              <label>
                <input
                  type={'checkbox'}
                  checked={collageGraded}
                  onChange={(e) => setCollageGraded(e.target.checked)}
                  aria-label="Graded"
                />
                Graded
              </label>
            </div>
          }
        </Fragment>}
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
