import React, {useEffect, useState} from 'react';
import {CollageItem} from 'api/collages/CollageItem';
import classNames from 'classnames';
import Draggable from 'react-draggable';

export interface CollageItemProps {
  item: CollageItem,
  isActive?: boolean,
  onClick?: () => any,
  onRemove?: () => any,
  onChange?: (newItem: CollageItem) => any,
}

export const CollageItemView = (props: CollageItemProps) => {
  const [isMoveDisabled, setIsMoveDisabled] = useState<boolean>(false);
  // const [isResizing, setIsResizing] = useState<boolean>(false);

  useEffect(() => {
    // const handleMouseMovement = (e: MouseEvent) => {
    //   if (isResizing) {
    //     props.onChange?.({
    //       ...props.item,
    //       width: props.item.width + e.movementX,
    //     });
    //   }
    // };

    // window.addEventListener('mousemove', handleMouseMovement);
    // return () => window.removeEventListener('mousemove', handleMouseMovement);
  }, []);

  return (
    <Draggable
      disabled={isMoveDisabled}
      position={{x: props.item.left, y: props.item.top}}
      onStop={(_, dragData) => {
        props.onChange?.({
          ...props.item,
          top: dragData.y,
          left: dragData.x,
        });
      }}
    >
      <div
        className={classNames('collage-item', {'active': props.isActive})}
        onClick={props.onClick}
        style={{
          width: props.item.width,
          height: props.item.height,
          zIndex: props.item.level,
          transform: `rotate(${props.item.angle}deg)`,
          backgroundColor: '#eee',
        }}
      >
        {props.item.type === 'image' &&
          <img className="image" src={props.item.url} alt={''} />
        }

        <div
          className="handle-resize"
          draggable={true}
          onMouseOver={() => setIsMoveDisabled(true)}
          onMouseOut={() => setIsMoveDisabled(false)}
          // onMouseDown={() => setIsResizing(true)}
          // onDragEnd={() => setIsResizing(false)}
        >
          <i className="icon-resize-full" />
        </div>
        <div className="handle-rotate"><i className="icon-share" /></div>
        <div className="button-remove" onClick={props.onRemove}><i className="icon-cancel" /></div>
      </div>
    </Draggable>
  );
};
