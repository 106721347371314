import React from 'react';
import {ResourceBase} from 'api/resources/Resource';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';

export interface VideoResourceViewProps {
  resource: ResourceBase,
  onCloseRequested?: () => any,
}

export const VideoResourceView = (props: VideoResourceViewProps) => {
  return <Modal
    title={__('Video')}
    onCloseRequested={props.onCloseRequested}
    body={
      <iframe
        width="530"
        height="298"
        src={
          (props.resource.resource.owned ? '//player.vimeo.com/video/' : '//www.youtube.com/embed/') +
          props.resource.resource.movieId
        }
        frameBorder="0"
        allowFullScreen
      />
    }
    footer={<></>}
  />;
};
