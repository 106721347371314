import React, {
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import {useApiContext} from 'api/ApiContext';
import {useSessionContext} from 'contexts/SessionContext';
import {useLogger} from 'logging/logging';
import {User} from 'api/users/User';

/**
 * Provides a context to switch users (ie. view the profile of a different user than the user currently signed in).
 *
 * The "active user" state is maintained in the API, so this context is largely
 * comprised of methods to make the appropriate API calls to manage that state.
 */

export interface UserSwitchingContextInterface {
  setActiveUser: (id?: number) => Promise<any>,
  activeUser: User | undefined,
  userIsSwitched: boolean,
}

export const UserSwitchingContext = createContext<UserSwitchingContextInterface | undefined>(undefined);

export const useUserSwitchingContext = () => {
  const context = useContext(UserSwitchingContext);
  if (context === undefined) {
    throw new Error('useUserSwitchingContext must be used in a provider');
  }
  return context;
};

export const UserSwitchingContextProvider = (props: PropsWithChildren<{}>) => {
  const logger = useLogger(UserSwitchingContextProvider.name);
  const {sessionService} = useApiContext();
  const {fetchSessionDetails, sessionDetails} = useSessionContext();

  const setActiveUser = async (id?: number) => {
    await sessionService.setActiveUser(id ?? null);
    await fetchSessionDetails();
    logger.info('Switched active user', id);
  };

  return (
    <UserSwitchingContext.Provider value={{
      setActiveUser,
      activeUser: sessionDetails?.selectedStudent,
      userIsSwitched: sessionDetails?.selectedStudent?.id !== sessionDetails?.identity?.id,
    }}>
      {props.children}
    </UserSwitchingContext.Provider>
  );
};
