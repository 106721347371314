import React from 'react';
import {Button, ButtonProps, ButtonVariant} from 'components/buttons/Button';
import {localizeText} from 'i18n/localize';

export interface ButtonEditProps extends ButtonProps {}

export const ButtonEdit = (props: ButtonEditProps) => {
  return (
    <Button
      onClick={props.onClick}
      className={'btn-icon btn-mini'}
      iconCode={'icon-edit'}
      tooltip={props.tooltip ?? localizeText('Edit')}
      variant={ButtonVariant.Edit}
    />
  );
};
