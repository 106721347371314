import React, {PropsWithChildren} from 'react';

/**
 * A "page" menu (eg. save/filter controls in the top right corner)
 */

export const ContextMenu = (props: PropsWithChildren<{}>) => {
  return (
    <div>
      <div className="filter buttons btn-group" role="search" aria-label="search">
        {props.children}
      </div>
    </div>
  );
};
