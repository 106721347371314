import {library} from '@fortawesome/fontawesome-svg-core';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {components} from 'react-select';
import React from 'react';

/**
 * A custom dropdown arrow for react-select
 */

library.add(faCaretDown);

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon="caret-down" size={'xs'} />;
};

export const SelectDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
