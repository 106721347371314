import React from 'react';
import {Button, ButtonProps, ButtonVariant} from 'components/buttons/Button';
import {localizeText} from 'i18n/localize';

export interface ButtonAddProps extends ButtonProps {}

export const ButtonAdd = (props: ButtonAddProps) => {
  return (
    <Button
      tooltip={props.tooltip ?? localizeText('Add')}
      onClick={props.onClick}
      className={'btn-success'}
      iconCode={'icon-plus-circled'}
      variant={ButtonVariant.Add}
    />
  );
};
