import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Goal} from 'api/goals/Goal';
import {Draggable} from 'react-beautiful-dnd';
import {Tooltip} from 'components/Tooltip';
import {PopupMenu} from 'components/PopupMenu';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {confirmDelete} from 'components/confirmDelete';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {arc, scaleLinear, svg, select} from 'd3';

export interface GoalThumbnailProps {
  goal: Goal,
  onOpenRequested?: () => any,
  onUpdated?: () => any,
  onDeleted?: () => any,
  draggable?: boolean,
  draggableIndex?: number,
  forCourse?: boolean,
}

const InnerThumbnail = (props: GoalThumbnailProps) => {
  const {goal} = props;
  const {goalsService} = useApiContext();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }

    const size = 48;
    const radius = size / 2;
    const border = radius / 4;
    const color = props.goal.color;
    const value = props.forCourse ? 100 : props.goal.progress;

    const scale = scaleLinear([0, 100], [0, 2 * Math.PI]);
    const arcGenerator = arc()
        .outerRadius(radius)
        .innerRadius(radius - border)
        .startAngle((d) => scale(d.startAngle))
        .endAngle((d) => scale(d.endAngle));

    const svg1 = select(svgRef.current);
    const defs = svg1.append('defs');
    const filter = defs.append('filter')
        .attr('id', `shadow_${props.goal.id}`)
        .attr('x', '-50%')
        .attr('y', '-50%')
        .attr('width', '200%')
        .attr('height', '200%');
    const feComponentTransfer = filter.append('feComponentTransfer')
        .attr('in', 'SourceAlpha');
    feComponentTransfer.append('feFuncA')
        .attr('type', 'table')
        .attr('tableValues', '1 0');
    filter.append('feGaussianBlur')
        .attr('stdDeviation', '2');
    filter.append('feOffset')
        .attr('dx', '2')
        .attr('dy', '2')
        .attr('result', 'offsetblur');
    filter.append('feFlood')
        .attr('flood-color', 'rgb(10, 10, 10)')
        .attr('result', 'color');
    filter.append('feComposite')
        .attr('in2', 'offsetblur')
        .attr('operator', 'in');
    filter.append('feComposite')
        .attr('in2', 'SourceAlpha')
        .attr('operator', 'in');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode')
        .attr('in', 'SourceGraphic');
    feMerge.append('feMergeNode');
    const linearGradient = defs.append('linearGradient')
        .attr('id', `gradient_${props.goal.id}`)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '30%')
        .attr('y2', '30%');
    linearGradient.append('stop')
        .attr('offset', '20%')
        .attr('style', 'stop-color:rgb(255,255,255);stop-opacity:1');
    linearGradient.append('stop')
        .attr('offset', '100%')
        .attr('style', 'stop-color:rgb(200,200,200);stop-opacity:1');

    const data = [
      {startAngle: 0, endAngle: 100, color: `url(#gradient_${props.goal.id})`},
      {startAngle: 0, endAngle: value, color: color},
    ];

    select(svgRef.current)
        .selectAll('path')
        .data(data)
        .enter()
        .append('path')
        .attr('d', arcGenerator as any)
        .style('fill', (d) => d.color)
        .attr('transform', 'translate(' + radius + ',' + radius + ')')
        .attr('filter', `url(#shadow_${props.goal.id})`)
        .attr('stroke', color)
        .attr('stroke-width', '1')
        .attr('stroke-opacity', '0.5');

    if (props.goal.inactive) {
      select(svgRef.current)
          .append('line')
          .attr('x1', '5%')
          .attr('y1', '85%')
          .attr('x2', '95%')
          .attr('y2', '15%')
          .attr('stroke-width', '10')
          .attr('filter', `url(#shadow_${props.goal.id})`)
          .attr('stroke', '#353535')
          .attr('stroke-opacity', '0.5');
    }
  }, [svgRef]);

  return (
    <PopupMenu
      items={[
        {label: __('View'), onClick: () => props.onOpenRequested?.()},
        {label: __('Edit'), onClick: () => setShowEdit(true)},
        {label: __('Delete'), onClick: () => confirmDelete(async () => {
          await goalsService.deleteGoal(goal.id);
          props.onDeleted?.();
        })},
      ]}
    >
      {showEdit &&
        <GoalInputModal
          initialGoalId={goal.id}
          onCloseRequested={() => setShowEdit(false)}
          onCompleted={() => setShowEdit(false)}
        />
      }

      <Tooltip
        title={goal.name ?? ''}
        style={{
          arrow: {color: 'rgba(0, 0, 0, 0.7)'},
          body: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#ccc',
            opacity: 1,
            borderRadius: 5,
            fontSize: 14,
          },
        }}
      >
        <div
          className={classNames(
              'goal small',
              {'inactive': props.goal.inactive},
              {'completed': props.goal.completed},
          )}
          onClick={props.onOpenRequested}
        >
          <svg ref={svgRef} />
          <div className="fill"/>

          <div className={classNames('icon', goal.icon)} />
        </div>
      </Tooltip>
    </PopupMenu>
  );
};

export const GoalThumbnail = (props: GoalThumbnailProps) => {
  const {goal} = props;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    zIndex: 99999,
    ...draggableStyle,
  });

  return <>
    {!props.draggable &&
      <InnerThumbnail {...props} />
    }
    {props.draggable &&
      <Draggable draggableId={goal.id.toString()} index={props.draggableIndex ?? 0}>
        {(provided, snapshot) => (
          <span
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              display: 'inline-block',
              ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
            }}
          >
            <InnerThumbnail {...props} />
          </span>
        )}
      </Draggable>
    }
  </>;
};
