import React, {
  ChangeEvent,
  FocusEvent, useEffect,
  useState,
} from 'react';
import {onlyNumbers} from '@brazilian-utils/helper-only-numbers';
import styleClasses from './TimeInput.module.scss';

/**
 * A form control to input hour, minute, and AM/PM
 */

export interface TimeInputProps {
  initialHour?: number,
  initialMinute?: number,
  onHourChange?: (newHour: number | undefined) => any,
  onMinuteChange?: (newMinute: number | undefined) => any,
}

export const TimeInput = (props: TimeInputProps) => {
  const [hour, setHour] = useState<string>(props.initialHour?.toString() ?? '12');
  const [minute, setMinute] = useState<string>(props.initialMinute?.toString() ?? '0');
  const [isPm, setIsPm] = useState<boolean>(false);

  const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.substr(0, 2);
    const numericInput = onlyNumbers(input);
    setHour(numericInput);
  };

  const handleHourBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value.length < 1) {
      setHour('01');
    } else if (e.target.value.length === 1) {
      setHour('0' + e.target.value);
    }

    if (parseInt(e.target.value) < 1) {
      setHour('01');
    } else if (parseInt(e.target.value) > 12) {
      setHour('12');
    }
  };

  const incrementHour = () => {
    const currentHour = parseInt(hour);
    const newHour = currentHour < 12 ? currentHour + 1 : 1;
    setHour(newHour.toString());
  };

  const decrementHour = () => {
    const currentHour = parseInt(hour);
    const newHour = currentHour > 1 ? currentHour - 1 : 12;
    setHour(newHour.toString());
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.substr(0, 2);
    const numericInput = onlyNumbers(input);
    setMinute(numericInput);
  };

  const handleMinuteBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value.length < 1) {
      setMinute('00');
    } else if (e.target.value.length === 1) {
      setMinute('0' + e.target.value);
    }

    if (parseInt(e.target.value) < 0) {
      setMinute('00');
    } else if (parseInt(e.target.value) > 59) {
      setMinute('59');
    }
  };

  const incrementMinute = () => {
    const currentMinute = parseInt(minute);
    const newMinute = currentMinute < 45 ? currentMinute + 15 : 0;
    setMinute(newMinute.toString());
  };

  const decrementMinute = () => {
    const currentMinute = parseInt(minute);
    const newMinute = currentMinute > 14 ? currentMinute - 15 : 45;
    setMinute(newMinute.toString());
  };

  const toggleAmPm = () => {
    setIsPm((current) => !current);
  };

  useEffect(() => {
    props.onHourChange?.(parseInt(hour));
  }, [hour]);

  useEffect(() => {
    props.onMinuteChange?.(parseInt(minute));
  }, [minute]);

  return <div className={styleClasses.container}>
    <span className={styleClasses.inputItem}>
      <button type={'button'} onClick={incrementHour} aria-label="More Hours">
        <i className={'icon-angle-up'}/>
      </button>
      <label>
        <span style={{display: 'none'}}>Hour</span>
        <input
          value={hour}
          onChange={handleHourChange}
          onBlur={handleHourBlur}
        />
      </label>
      <button type={'button'} onClick={decrementHour} aria-label="Fewer Hours">
        <i className={'icon-angle-down'}/>
      </button>
    </span>

    <span>:</span>

    <span className={styleClasses.inputItem}>
      <button type={'button'} onClick={incrementMinute} aria-label="More Minutes">
        <i className={'icon-angle-up'} />
      </button>
      <label>
        <span style={{display: 'none'}}>Minute</span>
        <input
          value={minute}
          onChange={handleMinuteChange}
          onBlur={handleMinuteBlur}
        />
      </label>
      <button type={'button'} onClick={decrementMinute} aria-label="Fewer Minutes">
        <i className={'icon-angle-down'} />
      </button>
    </span>

    <label>
      <button
        type={'button'}
        onClick={toggleAmPm}
        aria-label="AM PM"
      >
        {isPm ? 'PM' : 'AM'}
      </button>
    </label>
  </div>;
};
