import React, {useMemo, useState} from 'react';
import {Collage} from 'api/collages/Collage';
import {CollageItemView} from 'views/collages/CollageItemView';
import {CollageItem} from 'api/collages/CollageItem';

export interface CollageBoardProps {
  collage: Collage,
  onChange?: (newCollage: Collage) => any,
}

export const CollageBoard = (props: CollageBoardProps) => {
  const collageItems = useMemo<CollageItem[]>(() => {
    return JSON.parse(props.collage.data)?.objects ?? [];
  }, [props.collage]);

  const [activeItem, setActiveItem] = useState<CollageItem | undefined>(undefined);

  return (
    <div className="collage">
      {collageItems.map((dataItem, i: number) => (
        <CollageItemView
          key={i}
          item={dataItem}
          isActive={dataItem === activeItem}
          onClick={() => setActiveItem(dataItem)}
          onChange={(newItem) => {
            const newItems = [...collageItems];
            newItems[i] = newItem;
            props.onChange?.({
              ...props.collage,
              data: JSON.stringify({objects: newItems}),
            });
          }}
          onRemove={() => {
            const newItems = [...collageItems];
            newItems.splice(i, 1);
            props.onChange?.({
              ...props.collage,
              data: JSON.stringify({objects: newItems}),
            });
          }}
        />
      ))}
    </div>
  );
};
