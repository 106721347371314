import React from 'react';
import classNames from 'classnames';

export interface CheckBoxProps {
  checked?: boolean,
  onClick?: () => any,
  size?: number,
  label?:string,
}

export const CheckBox = (props: CheckBoxProps) => {
  return (
    <i
      style={{fontSize: props.size ?? 18, paddingLeft: 0}}
      onClick={props.onClick}
      aria-label={props.label}
      className={classNames(
          'checkbox',
          'icon-check-empty',
          {'icon-check': props.checked},
      )}
    />
  );
};
