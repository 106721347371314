import classNames from 'classnames';
import React from 'react';

/**
 * An "on/off" switch input
 */

export interface SwitchInputProps {
  on: boolean,
  onChange?: (nowOn: boolean) => any,
  label?: string,
}

export const SwitchInput = (props: SwitchInputProps) => {
  return (
    <button
      aria-label={props.label ?? 'Switch'}
      type={'button'}
      className={classNames(
          'btn-toggle',
          'right-offset-10',
          {active: props.on},
      )}
      onClick={() => props.onChange?.(!props.on)}
    />
  );
};
