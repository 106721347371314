import React, {useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {useAccessControlContext} from 'access-control/AccessControlContext';
import {AppFeature} from 'access-control/AppFeature';
import {AppRoute} from 'routing/AppRoute';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useSessionContext} from 'contexts/SessionContext';
import {getSchoolRoute} from './routing';
import {Redirect} from 'react-router-dom';

/**
 * Redirects to the current user's homepage based on access control and student goal/survey status
 */
export const HomePageRedirect = () => {
  const {accessControlService} = useAccessControlContext();
  const {sessionDetails} = useSessionContext();
  const {goalsService, surveysService} = useApiContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectPath, setRedirectPath] = useState<string>(AppRoute.DashboardContext + AppRoute.PlanOverview);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const sessionIdentity = sessionDetails?.identity;
      if (!sessionIdentity) {
        setIsLoading(false);
        return;
      }

      let homePath: string;
      if (accessControlService.userCanAccess(sessionIdentity, AppFeature.DashboardSysadmin)) {
        homePath = AppRoute.SystemContext + AppRoute.SystemOverview;
      } else if (
        accessControlService.userCanAccess(sessionIdentity, AppFeature.DashboardAdmin) &&
        sessionIdentity.selectedSchoolId
      ) {
        homePath = getSchoolRoute(sessionIdentity.selectedSchoolId) + AppRoute.Stats;
      } else if (accessControlService.userCanAccess(sessionIdentity, AppFeature.DashboardEditor)) {
        homePath = AppRoute.SystemContext + AppRoute.ContentEditor;
      } else if (accessControlService.userCanAccess(sessionIdentity, AppFeature.DashboardLessons)) {
        homePath = AppRoute.DashboardContext + AppRoute.Lessons;
      } else {
        const userHasGoals = (await goalsService.fetchCurrentUserGoals()).length > 0;
        if (userHasGoals) {
          homePath = AppRoute.DashboardContext + AppRoute.Goals;
        } else {
          const userHasSurveyResults = (await surveysService.fetchSurveyResults()).length > 0;
          if (
            userHasSurveyResults ||
            !accessControlService.userCanAccess(sessionIdentity, AppFeature.DashboardDreams)
          ) {
            homePath = AppRoute.DashboardContext + AppRoute.PlanOverview;
          } else {
            homePath = AppRoute.DashboardContext + AppRoute.Dreams;
          }
        }
      }

      setRedirectPath(homePath);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <LoadingIndicator/>;
  } else {
    return <Redirect to={redirectPath}/>;
  }
};
