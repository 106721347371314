/**
 * The default icons to offer in an icon picker
 */
export const iconPickerDefaultIconCodes = [
  'icon-1',
  'icon-2',
  'icon-3',
  'icon-4',
  'icon-5',
  'icon-6',
  'icon-7',
  'icon-8',
  'icon-9',
  'icon-0',
  'icon-i-womens-health',
  'icon-i-waiting-area',
  'icon-i-ultrasound',
  'icon-i-surgery',
  'icon-i-radiology',
  'icon-i-physical-therapy',
  'icon-i-pharmacy',
  'icon-i-pediatrics',
  'icon-i-pathology',
  'icon-i-outpatient',
  'icon-i-mental-health',
  'icon-i-medical-records',
  'icon-i-medical-library',
  'icon-i-mammography',
  'icon-i-laboratory',
  'icon-i-labor-delivery',
  'icon-i-immunizations',
  'icon-i-imaging-root-category',
  'icon-i-imaging-alternative-mri',
  'icon-i-imaging-alternative-mri-two',
  'icon-i-imaging-alternative-ct',
  'icon-i-family-practice',
  'icon-i-ear-nose-throat',
  'icon-i-billing',
  'icon-i-anesthesia',
  'icon-i-social-services',
  'icon-i-smoking',
  'icon-i-respiratory',
  'icon-i-registration',
  'icon-i-oncology',
  'icon-i-nutrition',
  'icon-i-nursery',
  'icon-i-neurology',
  'icon-i-internal-medicine',
  'icon-i-intensive-care',
  'icon-i-inpatient',
  'icon-i-infectious-diseases',
  'icon-i-health-education',
  'icon-i-genetics',
  'icon-i-dermatology',
  'icon-i-dental',
  'icon-i-accessibility',
  'icon-i-diabetes-education',
  'icon-i-hospital',
  'icon-i-kidney',
  'icon-stethoscope',
  'icon-medkit',
  'icon-lemon',
  'icon-blank',
  'icon-glass',
  'icon-music',
  'icon-search',
  'icon-mail',
  'icon-mail-alt',
  'icon-mail-squared',
  'icon-heart',
  'icon-heart-empty',
  'icon-star',
  'icon-star-empty',
  'icon-star-half',
  'icon-star-half-alt',
  'icon-user',
  'icon-users',
  'icon-male',
  'icon-female',
  'icon-child',
  'icon-video',
  'icon-videocam',
  'icon-picture',
  'icon-minus-circled',
  'icon-plus-circled',
  'icon-cancel-circled',
  'icon-ok-circled',
  'icon-camera',
  'icon-help',
  'icon-flag',
  'icon-share-squared',
  'icon-direction',
  'icon-folder-empty',
  'icon-volume-off',
  'icon-up-dir',
  'icon-left',
  'icon-shuffle',
  'icon-exchange',
  'icon-right',
  'icon-left-dir',
  'icon-volume-down',
  'icon-folder-open-empty',
  'icon-compass',
  'icon-pencil',
  'icon-flag-empty',
  'icon-help-circled',
  'icon-info-circled',
  'icon-flag-checkered',
  'icon-trash',
  'icon-box',
  'icon-volume-up',
  'icon-up',
  'icon-history',
  'icon-info',
  'icon-thumbs-up',
  'icon-print',
  'icon-thumbs-down',
  'icon-home',
  'icon-link',
  'icon-link-ext',
  'icon-attach',
  'icon-lock',
  'icon-lock-open',
  'icon-pin',
  'icon-eye',
  'icon-eye-off',
  'icon-tag',
  'icon-bookmark',
  'icon-share',
  'icon-forward',
  'icon-reply-all',
  'icon-reply',
  'icon-upload-cloud',
  'icon-download-cloud',
  'icon-upload',
  'icon-download',
  'icon-thumbs-down-alt',
  'icon-thumbs-up-alt',
  'icon-unlink',
  'icon-retweet',
  'icon-trash-empty',
  'icon-doc',
  'icon-docs',
  'icon-doc-text',
  'icon-keyboard',
  'icon-gamepad',
  'icon-comment',
  'icon-chat',
  'icon-comment-empty',
  'icon-chat-empty',
  'icon-bell',
  'icon-file-image',
  'icon-file-audio',
  'icon-attention-alt',
  'icon-file-video',
  'icon-attention',
  'icon-file-code',
  'icon-attention-circled',
  'icon-folder',
  'icon-location',
  'icon-folder-open',
  'icon-rss',
  'icon-phone',
  'icon-menu',
  'icon-cog',
  'icon-cog-alt',
  'icon-wrench',
  'icon-basket',
  'icon-calendar',
  'icon-mic',
  'icon-zoom-out',
  'icon-zoom-in',
  'icon-block',
  'icon-lightbulb',
  'icon-clock',
  'icon-headphones',
  'icon-signal',
  'icon-font',
  'icon-check-empty',
  'icon-user-md',
  'icon-graduation-cap',
  'icon-rocket',
  'icon-wifi',
  'icon-award',
  'icon-certificate',
  'icon-try',
  'icon-spoon',
  'icon-birthday',
  'icon-eyedropper',
  'icon-at',
  'icon-css3',
  'icon-tasks',
  'icon-filter',
  'icon-beaker',
  'icon-magic',
  'icon-cab',
  'icon-taxi',
  'icon-truck',
  'icon-bus',
  'icon-bicycle',
  'icon-money',
  'icon-dollar',
  'icon-sort',
  'icon-sort-down',
  'icon-sort-up',
  'icon-hammer',
  'icon-gauge',
  'icon-sitemap',
  'icon-spinner',
  'icon-coffee',
  'icon-food',
  'icon-beer',
  'icon-h-sigh',
  'icon-hospital',
  'icon-building',
  'icon-building-filled',
  'icon-bank',
  'icon-smile',
  'icon-frown',
  'icon-meh',
  'icon-anchor',
  'icon-terminal',
  'icon-eraser',
  'icon-puzzle',
  'icon-shield',
  'icon-extinguisher',
  'icon-bullseye',
  'icon-cube',
  'icon-cubes',
  'icon-recycle',
  'icon-tree',
  'icon-database',
  'icon-rebel',
  'icon-empire',
  'icon-bomb',
  'icon-soccer-ball',
  'icon-binoculars',
  'icon-plug',
  'icon-newspaper',
  'icon-calc',
  'icon-apple',
  'icon-bug',
  'icon-won',
  'icon-ambulance',
  'icon-paw',
  'icon-brush',
  'icon-expand',
  'icon-collapse',
  'icon-play-circled',
  'icon-stop',
  'icon-pause',
  'icon-target',
  'icon-desktop',
  'icon-laptop',
  'icon-table',
  'icon-mobile',
  'icon-inbox',
  'icon-globe',
  'icon-sun',
  'icon-cloud',
  'icon-flash',
  'icon-moon',
  'icon-umbrella',
  'icon-flight',
  'icon-fighter-jet',
  'icon-paper-plane',
  'icon-space-shuttle',
  'icon-leaf',
  'icon-header',
  'icon-scissors',
  'icon-paste',
  'icon-briefcase',
  'icon-suitcase',
  'icon-off',
  'icon-road',
  'icon-list-alt',
  'icon-qrcode',
  'icon-barcode',
  'icon-book',
  'icon-ajust',
  'icon-tint',
  'icon-toggle-off',
  'icon-toggle-on',
  'icon-check',
  'icon-circle-thin',
  'icon-circle-notch',
  'icon-dot-circled',
  'icon-asterisk',
  'icon-gift',
  'icon-fire',
  'icon-magnet',
  'icon-chart-bar',
  'icon-chart-area',
  'icon-chart-pie',
  'icon-chart-line',
  'icon-ticket',
  'icon-credit-card',
  'icon-floppy',
  'icon-megaphone',
  'icon-key',
];
