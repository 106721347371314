import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {iconPickerDefaultIconCodes} from './IconPicker_defaultIconCodes';

/**
 * Control for the user to choose an icon out of an icon set
 */

export interface IconPickerProps {
  iconCode?: string,
  iconCodeOptions?: string[],
  onChange?: (newIconCode: string) => any;
  isInvalid?: boolean,
}

export const IconPicker = (props: IconPickerProps) => {
  const selfRef = useRef<HTMLDivElement>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const closeOnClick = useCallback((e) => {
    if (
      !selfRef?.current?.contains(e.target) &&
      !selfRef?.current?.contains(e.target)
    ) {
      setShowPicker(false);
    }
  }, []);

  const closeOnEscape = useCallback((e) => {
    if (e.key === 'Escape') {
      setShowPicker(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    document.addEventListener('keyup', closeOnEscape);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
      document.removeEventListener('keyup', closeOnEscape);
    };
  }, [closeOnClick, closeOnEscape]);


  return (
    <div
      ref={selfRef}
      className={classNames(
          'icon-picker',
          {required: props.isInvalid},
      )}
    >
      <div
        title={'Choose icon'}
        className="preview"
        onClick={() => setShowPicker((current) => !current)}
        aria-label={'Choose icon'}
      >
        <i className={props.iconCode} title={'Selected icon'} aria-label={'Selected icon'}/>
        &nbsp;
      </div>

      {showPicker &&
        <div className={classNames('picker', {hidden: !showPicker})}>
          {(props.iconCodeOptions ?? iconPickerDefaultIconCodes).map((icon) => (
            <div
              key={icon}
              className={'picker-icon'}
              aria-label="icon"
              onClick={() => {
                setShowPicker(false);
                props.onChange?.(icon);
              }}
            >
              <i className={icon} title={icon} />
            </div>
          ))}
        </div>
      }
    </div>
  );
};
