import {en} from 'i18n/dictionaries/en';

let currentDictionary = en;

/**
 * Localizes a term into the current language
 *
 * @param {string} key
 * @return {string}
 */
export function localizeText(key: string): string {
  for (const [dictionaryKey, value] of Object.entries(currentDictionary)) {
    if (dictionaryKey === key) {
      return value;
    }
  }

  // default to the given key if no translation is found
  return key;
}

/**
 * Shorthand for localizeText
 *
 * @param {string} key
 * @return {string}
 */
export function __(key: string): string {
  return localizeText(key);
}

/**
 * Sets the user's current language locale
 *
 * @param {string} languageCode
 */
export function setLocale(languageCode: string) {
  if (languageCode === 'en') {
    currentDictionary = en;
  // } else if {
    // other languages go here -- currently only english available
  } else {
    // default to english
    currentDictionary = en;
  }
}
