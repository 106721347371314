import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {User} from 'api/users/User';
import {Course} from 'api/courses/Course';
import {UserAvatar} from 'components/UserAvatar';
import {LoadingModal} from 'components/LoadingModal';
import {useSessionContext} from 'contexts/SessionContext';
import {UserRoleType} from 'api/users/UserRole';
import classNames from 'classnames';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {LessonWithRelations} from 'api/lessons/Lesson';
import {useApiContext} from 'api/ApiContext';
import {useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

export interface AssignLessonGoalModalProps {
  lesson: LessonWithRelations,
  onCloseRequested?: () => any,
}

export const AssignLessonGoalModal = (props: AssignLessonGoalModalProps) => {
  const {sessionDetails} = useSessionContext();
  const {usersService, coursesService} = useApiContext();
  const {push: pushRoute} = useHistory();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [students, setStudents] = useState<User[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [showAssignStudentId, setShowAssignStudentId] = useState<number | undefined>(undefined);
  const [showAssignCourseId, setShowAssignCourseId] = useState<number | undefined>(undefined);

  const loadStudents = async () => {
    const fetchedStudents = await usersService.fetchMonitoredUsers();
    setStudents(fetchedStudents);
  };

  const loadCourses = async () => {
    const fetchedCourses = await coursesService.fetchTaughtCourses();
    setCourses(fetchedCourses);
  };

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      await Promise.all([
        loadStudents(),
        loadCourses(),
      ]);
      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return <LoadingModal/>;
  }

  return <Modal
    title={__('Assign Lesson as a Goal')}
    onCloseRequested={props.onCloseRequested}
    className={'wide'}
    bodyClassName={'lesson-editor assign-lesson'}

    body={
      <>
        {showAssignStudentId &&
          <GoalInputModal
            studentId={showAssignStudentId}
            initialLesson={props.lesson}
            onCloseRequested={() => {
              setShowAssignStudentId(undefined);
              props.onCloseRequested?.();
            }}
            onCompleted={() => {
              setShowAssignStudentId(undefined);
              props.onCloseRequested?.();
              pushRoute(AppRoute.DashboardContext + AppRoute.Students);
            }}
          />
        }
        {showAssignCourseId &&
          <GoalInputModal
            goalCourseId={showAssignCourseId}
            initialLesson={props.lesson}
            onCloseRequested={() => {
              setShowAssignCourseId(undefined);
              props.onCloseRequested?.();
            }}
            onCompleted={() => {
              setShowAssignCourseId(undefined);
              props.onCloseRequested?.();
              pushRoute(AppRoute.DashboardContext + AppRoute.TeacherCourses);
            }}
          />
        }

        <h4>{__('Choose if this goal is for an individual participant or class.')}</h4>
        <div className="row-fluid ">
          <div className="span6 frame">
            <h5>{__('Participant')}</h5>
            <div className="body" style={{overflowY: 'auto'}}>
              {students.map((student) => (
                <div
                  key={student.id}
                  className="list-item clearfix"
                  onClick={() => setShowAssignStudentId(student.id)}
                  aria-label={'assign to '+student.name}
                >
                  <UserAvatar
                    className={'circle-30 image'}
                    user={student}
                    size={35}
                    borderWidth={0}
                    boxShadow={'2px 2px 5px rgba(0, 0, 0, 0.3)'}
                  />
                  <span className="name">{student.name}</span>
                </div>
              ))}
            </div>
          </div>

          {sessionDetails?.role?.type === UserRoleType.Teacher &&
            <div className="span6 frame">
              <h5>{__('Module')}</h5>
              <div className="body" style={{overflowY: 'auto'}}>
                {courses.map((course) => (
                  <div
                    key={course.id}
                    className="list-item clearfix"
                    onClick={() => setShowAssignCourseId(course.id)}
                  >
                    <div
                      className="circle-30"
                      style={{background: course.category?.color}}
                      aria-label={'assign to '+course.name}
                    >
                      <span
                        className={classNames(
                            'icon',
                            course.category?.icon,
                        )}
                      />
                    </div>
                    <span className="name">{course.name}</span>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </>
    }
  />;
};
