/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {Template} from 'api/templates/Template';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {DataGrid} from 'components/DataGrid';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {confirmDelete} from 'components/confirmDelete';
import {TemplateInputModal} from 'views/templates/TemplateInputModal';

export interface TemplatesListModalProps {
  onCloseRequested?: () => any,
}

export const TemplatesListModal = (props: TemplatesListModalProps) => {
  const logger = useLogger(TemplatesListModal.name);
  const {templatesService} = useApiContext();

  const [templates, setTemplates] = useState<Template[]>([]);
  const [isFetchingTemplates, setIsFetchingTemplates] = useState<boolean>(true);
  const [showAddTemplate, setShowAddTemplate] = useState<boolean>(false);
  const [templateToEdit, setTemplateToEdit] = useState<Template | undefined>(undefined);

  const fetchTemplates = async () => {
    setIsFetchingTemplates(true);
    const result = await templatesService.fetchTemplates({});
    setTemplates(result);
    logger.debug('Fetched templates', result);
    setIsFetchingTemplates(false);
  };

  useEffect(() => {
    (async () => {
      await fetchTemplates();
    })();
  }, [templatesService]);

  return <Modal
    title={__('Certificate Templates')}
    className={'tall wide'}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Add}
        onClick={() => setShowAddTemplate(true)}
      />
    }

    body={
      <DataGrid
        header={
          <tr>
            <td>{__('Name')}</td>
            <td width={35} />
          </tr>
        }
        body={
          <>
            {(showAddTemplate || templateToEdit) &&
              <TemplateInputModal
                initialTemplate={templateToEdit}
                onCloseRequested={() => {
                  setShowAddTemplate(false);
                  setTemplateToEdit(undefined);
                }}
                onComplete={async () => {
                  await fetchTemplates();
                  setShowAddTemplate(false);
                  setTemplateToEdit(undefined);
                }}
              />
            }

            {templates.map((template) => (
              <tr key={template.id}>
                <td>{template.name}</td>
                <td>
                  <ButtonGroup>
                    <Button variant={ButtonVariant.Edit} onClick={() => setTemplateToEdit(template)} />
                    <Button variant={ButtonVariant.Delete} onClick={() => confirmDelete(async () => {
                      await templatesService.deleteTemplate(template.id);
                      await fetchTemplates();
                    })} />
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </>
        }
      />
    }
  />;
};
