import React, {Fragment, useState} from 'react';
import {Modal} from 'components/Modal';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {ActionStepWithRelations} from 'api/goals/ActionStep';
import {useApiContext} from 'api/ApiContext';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {useLogger} from 'logging/logging';
import {__} from 'i18n/localize';
import {Button} from 'components/buttons/Button';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {useNotificationContext} from 'contexts/NotificationContext';

export interface ActionStepCompletionModalProps {
  actionStep: ActionStepWithRelations,
  onCloseRequested?: () => any;
  onComplete?: () => any;
  onReopen?: () => any;
}

export const ActionStepCompletionModal = (props: ActionStepCompletionModalProps) => {
  const logger = useLogger(ActionStepCompletionModal.name);
  const {goalsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const [completionNotes, setCompletionNotes] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<FileUpload | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const completeActionStep = async () => {
    setIsSaving(true);
    try {
      const completeResult = await goalsService.markActionStepComplete(props.actionStep.id, uploadedFile);
      logger.debug('Completed action step', completeResult);
      props.onComplete?.();
    } catch (e) {
      showErrorNotification(__('Error'), __('Error completing action step'));
      logger.error('Error completing action step', e);
    }
    setIsSaving(false);
  };

  const reOpenActionStep = async () => {
    setIsSaving(true);
    try {
      const reopenResult = await goalsService.reopenActionStep(props.actionStep.id);
      logger.debug('Reopened action step', reopenResult);
      props.onReopen?.();
    } catch (e) {
      showErrorNotification(__('Error'), __('Error reopening action step'));
      logger.error('Error reopening action step', e);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={props.actionStep.title}
      onCloseRequested={props.onCloseRequested}

      body={
        <Fragment>
          {isSaving && <LoadingIndicator fullscreen={true} />}

          <form name="form.edit" className="text">
            <div className="row-fluid">
              <textarea
                placeholder={__('Comment')}
                value={completionNotes}
                onChange={(e) => setCompletionNotes(e.target.value)}
                className="span12 rows5"
              />
            </div>
            <div className="row-fluid">
              <FileUploadInput
                buttonText={__('File')}
                onChange={(newFiles) => setUploadedFile(newFiles[0])}
              />
            </div>
          </form>
          <div className="row-fluid buttons btn-group">
            {props.actionStep.completed &&
              <Button
                className={'btn btn-warning span12'}
                onClick={reOpenActionStep}
                text={__('Reopen')}
              />
            }
            {!props.actionStep.completed &&
              <Button
                className={'btn btn-success span12'}
                onClick={completeActionStep}
                text={__('Mark Action Step Complete')}
              />
            }
          </div>
        </Fragment>
      }
    />
  );
};
