import React, {useState} from 'react';
import {Goal, GoalWithRelations} from 'api/goals/Goal';
import classNames from 'classnames';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {FormattedDate} from 'components/FormattedDate';
import {ActionStepWithRelations} from 'api/goals/ActionStep';
import {GoalDetailsModal} from 'views/goals/GoalDetailsModal';
import {GoalInputModal} from 'views/goals/GoalInputModal';
import {CommentInputModal} from 'views/learning/CommentInputModal';
import {useApiContext} from 'api/ApiContext';

/**
 * A single goal in the goals dashboard, with toggle to show details
 */

export interface GoalQuickViewProps {
  goal: GoalWithRelations,
  key?: string | number,
  onStepSelected?: (step: ActionStepWithRelations) => any,
  onChanged?: (newGoal?: Goal) => any,
  onDeleted?: () => any,
}

export const GoalQuickView = (props: GoalQuickViewProps) => {
  const {key, goal} = props;
  const {goalsService} = useApiContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showCommentStepId, setShowCommentStepId] = useState<number | undefined>(undefined);

  const todo = goal.steps?.reduce((total, current) => {
    return current.enabled && !current.completed ? total + 1 : total;
  }, 0);

  // this looks to not be fully built in the original version (always false)
  const isToday = false;

  return (
    <div
      key={key}
      className={classNames(
          'goal-data repeat-animation animate',
          {'complete': goal.completed},
          {'inactive': goal.inactive},
          {'open': isOpen},
      )}
      role="listitem"
    >
      {showDetails &&
        <GoalDetailsModal
          goalId={props.goal.id}
          onCloseRequested={() => setShowDetails(false)}
          onEditRequested={() => {
            setShowDetails(false);
            setShowEdit(true);
          }}
          onDeleted={async () => {
            setShowDetails(false);
            props.onDeleted?.();
          }}
        />
      }

      {showEdit &&
        <GoalInputModal
          initialGoalId={props.goal.id}
          onCompleted={(newGoal) => {
            setShowEdit(false);
            props.onChanged?.(newGoal);
          }}
          onCloseRequested={() => setShowEdit(false)}
        />
      }

      {showCommentStepId &&
        <CommentInputModal
          onCloseRequested={() => setShowCommentStepId(undefined)}
          onCompleted={async (comment) => {
            await goalsService.addActionStepComment({
              ...comment,
              step: {id: showCommentStepId},
            });
            setShowCommentStepId(undefined);
          }}
        />
      }

      <div className="title" onClick={() => setIsOpen((current) => !current)}>
        <div className="pivot">
          <div className="progress">
            <div className="bar" style={{width: `${goal.progress}%`, backgroundColor: goal.color}}>
              <div className="name">
                {goal.icon &&
                    <span className={classNames('icon', goal.icon)} style={{color: goal.color}} />
                }
                {goal.name}
              </div>
            </div>
          </div>

          <div className="icons">
            {goal.timeCritical && !goal.completed && !goal.inactive &&
              <div className="clock" >
                <i className="icon-clock" />
              </div>
            }
            <div
              className="info"
              onClick={(e) => {
                e.stopPropagation();
                setShowDetails(true);
              }}
              style={{position: 'relative', zIndex: 9}}
            >
              <i className="icon-info" />
            </div>
            <div className="todo">{todo}</div>
            <div className="status" />
          </div>

          <div className="name">
            {goal.icon &&
                <span
                  className={classNames(
                      'icon',
                      goal.icon,
                  )}
                  style={{color: goal.color}}
                />
            }
            {goal.name}
          </div>
        </div>
      </div>

      <div className="steps clearfix">
        {goal.steps?.map((step) => (
          step.enabled &&
          <div
            key={step.id}
            className={classNames(
                'step clearfix',
                {'completed': step.completed},
                {'submited': step.submited},
            )}
            onClick={() => props.onStepSelected?.(step)}
          >
            <div className="status">
              <i className="icon-play" />
            </div>
            {step.resources[0]?.type === ResourceType.Movie &&
              <div className="movie">
                <img src={`https://img.youtube.com/vi/${step.resources[0].resource.movieId}/default.jpg`} alt={''} />
              </div>
            }

            {step.resources[0]?.type === ResourceType.Quiz &&
              <div className="quiz">
                <i className="icon-pencil" />
              </div>
            }

            {step.resources[0]?.type === ResourceType.File &&
              <div className="file">
                <i className="icon-doc-text" />
              </div>
            }

            {step.resources[0]?.type === ResourceType.Link &&
              <div className="file">
                <i className="icon-link" />
              </div>
            }

            {step.resources[0]?.type === ResourceType.Collage &&
              <div className="file">
                <i className="icon-brush" />
              </div>
            }

            <div className="icons">
              {isToday &&
                <div>
                  <i className="icon-clock icon-spin" style={{color: '#FF1C00'}} />
                  <i className="time">
                    &nbsp;
                    <FormattedDate timestamp={step.due ?? 0} />
                  </i>
                </div>
              }

              {!isToday && step.timeCritical &&
                <div>
                  <i className="icon-clock" />
                  <i className="time">
                    &nbsp;
                    <FormattedDate timestamp={step.due ?? 0}/>
                  </i>
                </div>
              }

              {step.submited &&
                <div>
                  <i className="icon-clock" />
                  <i className="time">
                    &nbsp;waiting for review
                  </i>
                </div>
              }

              {step.communityItems.length > 0 &&
                step.communityItems.map((communityItem) => (
                  <div
                    key={communityItem.id}
                    // popup="community.view({id: item.id})"
                    style={{cursor: 'default'}}
                  >
                    <i
                      className="icon-user"
                      // ng-class="item.type == 'PERSON' ? 'icon-user'
                      // : (item.type == 'PLACE' ? 'icon-bank' : 'icon-user')"
                      // tooltip-html-unsafe="{{item.name}}<br>{{item.phone}}
                      // <br>{{item.email}}<br>{{ item.address }}<br>
                      // {{item.city }}, {{ item.state }} {{ item.zip }}</span>"
                      // tooltip-append-to-body="true"
                      // tooltip-placement="bottom"
                      // tooltip-trigger="mouseenter"
                      // tooltip-popup-delay="500"
                    />
                  </div>
                ))
              }

              {step.ilries.length > 0 &&
                <div>
                  <i
                    className="icon-sun"
                    style={{color: '#f58a14'}}
                  />
                </div>
              }

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCommentStepId(step.id);
                }}
              >
                <i className="icon-chat-empty" />
              </div>
            </div>

            <div className="name">
              {step.title}
              {' '}
              {step.requiredTime && <i className="time">{step.requiredTime}</i>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
