import React from 'react';
import classNames from 'classnames';
import {QuizQuestionWithAnswer} from 'api/quizzes/QuizSolution';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {QuizQuestionAnswer} from 'api/quizzes/QuizQuestionAnswer';

export interface QuizMultipleChoiceQuestionProps {
  question: QuizQuestionWithAnswer,
  onAnswersChanged?: (newAnswers: QuizQuestionAnswer[]) => any,
}

export const QuizMultipleChoiceQuestion = (props: QuizMultipleChoiceQuestionProps) => {
  const isChecked = (index: number) => {
    return props.question.answers[index].marked ?? false;
  };

  const toggleChecked = (index: number) => {
    const newAnswers = [...props.question.answers];
    if (props.question.type === QuizQuestionType.Bullets) {
      newAnswers.forEach((answer) => answer.marked = false);
    }
    newAnswers[index].marked = !isChecked(index);
    props.onAnswersChanged?.(newAnswers);
  };

  return (
    <div className="answers">
      {props.question.answers.map((answer, i) => (
        <div key={answer.id} className="answer clearfix">
          <div className="checkbox">
            <a
              onClick={() => toggleChecked(i)}
              className={classNames('checkbox', {'checked': isChecked(i)})}
            >
              <i className={classNames({'icon-check': isChecked(i)}, {'icon-check-empty': !isChecked(i)})} />
            </a>
          </div>

          <div
            className="text html clearfix"
            dangerouslySetInnerHTML={{__html: answer.text}}
          />
        </div>
      ))}
    </div>
  );
};
