import {SessionDetails} from 'api/sessions/SessionDetails';
import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';

/**
 * Provides API session functions
 */
export class SessionService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches session details for the currently authenticated user
   *
   * @return {Promise<SessionDetails>}
   */
  async fetchSessionDetails(): Promise<SessionDetails> {
    const response = await this.httpClient.get<SessionDetails>(ApiRoute.SessionDetails);
    return response.data;
  }

  /**
   * Sets the active school for the current user session
   *
   * @param {number | null} schoolId
   * @return {Promise<SuccessResponse>}
   */
  async setActiveSchool(schoolId: number | null): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/session/setSchool', {
      id: schoolId,
    });
    return response.data;
  }

  /**
   * Sets the active selected student for the current session
   *
   * @param {number | null} id
   * @return {Promise<SuccessResponse>}
   */
  async setActiveUser(id: number | null): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.SetSelectedStudent, {id});
    return response.data;
  }
}
