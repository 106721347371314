import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {ListFilter} from 'api/lists/ListFilter';
import {__} from 'i18n/localize';
import {CommunityItem, CommunityItemType} from 'api/community/CommunityItem';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {DataGrid} from 'components/DataGrid';
import classNames from 'classnames';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {confirmDelete} from 'components/confirmDelete';
import {NetworkEntryModal} from 'views/community/NetworkEntryModal';
import {ExportToCsv} from 'export-to-csv';
import {Tooltip} from 'components/Tooltip';

export const CommunityView = () => {
  const logger = useLogger(CommunityView.name);
  const {entityListService, communityItemsService} = useApiContext();
  const [items, setItems] = useState<CommunityItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [filter, setFilter] = useState<ListFilter>({filters: {}});
  const [editItem, setEditItem] =
    useState<CommunityItem | undefined>(undefined);

  const loadItems = async () => {
    setIsFetching(true);
    const fetchedItems = await entityListService.fetchEntityList('/community/items', filter);
    setItems(fetchedItems);
    logger.debug('Fetched community items', fetchedItems);
    setIsFetching(false);
  };

  const exportCsv = () => {
    const exporter = new ExportToCsv({
      filename: 'network',
      useKeysAsHeaders: true,
    });
    exporter.generateCsv(items.map((item) => ({
      'Type': item.type,
      'Name': item.name,
      'Category': item.category ?? '',
      'Contact': item.contact ?? '',
      'Relation': item.relationship ?? '',
      'Url': item.url ?? '',
      'Phone': item.phone ?? '',
      'Email': item.email ?? '',
      'Address': item.address ?? '',
      'City': item.city ?? '',
      'State': item.state ?? '',
      'Zip': item.zip ?? '',
    })));
  };

  useEffect(() => {
    (async () => {
      await loadItems();
    })();
  }, [filter]);

  return <PageView>
    {editItem &&
      <NetworkEntryModal
        initialItem={editItem}
        onCloseRequested={() => setEditItem(undefined)}
        onCompleted={async () => {
          await loadItems();
          setEditItem(undefined);
        }}
      />
    }

    <ContextMenu>
      <SearchInput
        value={filter.search}
        onChange={(newValue) => setFilter((current) => ({
          ...current,
          search: newValue,
        }))}
      />

      <i className="icon icon-tag"/>
      <select
        value={filter.filters.type}
        onChange={(e) => setFilter((current) => ({
          ...current,
          filters: {
            ...current.filters,
            type: e.target.value,
          },
        }))}
        className="form-control"
      >
        <option value="">- {__('Type')} -</option>
        <option value="PERSON">Person</option>
        <option value="PLACE">Place</option>
      </select>

      <a className="btn" onClick={exportCsv}>
        <i className="icon-download" />
      </a>

      <AccessControlled feature={AppFeature.CommunityEdit}>
        <Tooltip title={__('Add')}>
          <a className="btn btn-success" onClick={() => setEditItem({} as CommunityItem)}>
            <i className="icon-plus-circled" />
          </a>
        </Tooltip>
      </AccessControlled>
    </ContextMenu>

    <DataGrid
      header={
        <tr>
          <td width={60}/>
          <td>{__('Type')}</td>
          <td>{__('Name')}</td>
          <td>{__('Email')}</td>
          <td>{__('Phone')}</td>
          <td>{__('Category')}</td>
          <td width={40} className={'text-center'}>{__('Location')}</td>
          <td width={35}/>
        </tr>
      }
      body={
        <>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                {item.image &&
                  <img
                    src={item.image.url + '100/100'}
                    className="circle-30 gray"
                    alt={''}
                  />
                }
                {!item.image &&
                  <span
                    className={classNames(
                        {'icon-user': item.type === CommunityItemType.Person},
                        {'icon-bank': item.type === CommunityItemType.Place},
                        'circle-30 gray',
                    )}
                  />
                }
              </td>
              <td>{item.type}</td>
              <td>
                {item.name}
                <br/>
                {item.relationship &&
                  <i>{item.relationship}</i>
                }
              </td>
              <td>
                {item.email &&
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                }
              </td>
              <td>{item.phone}</td>
              <td>{item.category}</td>
              <td style={{textAlign: 'center'}}>
                {item.address &&
                  <a
                    href={`http://maps.google.com/?q=${item.address} ${item.city} ${item.state} ${item.zip}`}
                    target="_blank"
                    className="icon map"
                    rel="noreferrer"
                  >
                    <i className="icon-globe"/>
                  </a>
                }
              </td>
              <td>
                <AccessControlled feature={AppFeature.CommunityEdit}>
                  <div className="icons btn-group">
                    <Tooltip title={__('Edit')}>
                      <a className="btn btn-icon btn-mini" onClick={() => setEditItem(item)}>
                        <i className="icon-edit" />
                      </a>
                    </Tooltip>
                    <Tooltip title={__('Delete')}>
                      <a
                        className="btn btn-icon btn-mini"
                        onClick={() => confirmDelete(async () => {
                          await communityItemsService.deleteCommunityItem(item.id);
                          await loadItems();
                        })}
                      >
                        <i className="icon-trash" />
                      </a>
                    </Tooltip>
                  </div>
                </AccessControlled>
              </td>
            </tr>
          ))}
        </>
      }
    />
  </PageView>;
};
