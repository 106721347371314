import React, {useEffect, useState} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {AppRoute} from 'routing/AppRoute';
import {PageNotFoundView} from 'views/errors/PageNotFoundView';
import {useSchoolContext} from 'contexts/SchoolContext';
import {StatsOverviewView} from 'views/stats/StatsOverviewView';
import {UsersListView} from 'views/users/UsersListView';
import {LessonsListView} from 'views/lessons/LessonsListView';
import {CategoriesListView} from 'views/categories/CategoriesListView';
import {QuizzesListView} from 'views/quizzes/QuizzesListView';
import {SkillsListView} from 'views/skills/SkillsListView';
import {AchievementsListView} from 'views/achievements/AchievementsListView';
import {ProductsListView} from 'views/products/ProductsListView';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {useLogger} from 'logging/logging';
import {VersionListView} from 'views/versions/VersionsListView';
import {SurveysListView} from 'views/surveys/SurveysListView';
import {SchoolsListView} from 'views/schools/SchoolsListView';
import {
  ContentEditorDashboard,
} from 'views/content-editor/ContentEditorDashboard';
import {LessonEditorView} from 'views/content-editor/LessonEditorView';

/**
 * Handles routing in the system section of the app
 */

export const SystemRouter = () => {
  const logger = useLogger(SystemRouter.name);
  const {path, url} = useRouteMatch();
  const {setSelectedSchool} = useSchoolContext();
  const {setActiveUser} = useUserSwitchingContext();

  const [isInitializing, setIsInitializing] =
    useState<boolean>(true);
  const [hasError, setHasError] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      // clear active school and user states for system-wide context
      try {
        await setSelectedSchool(undefined);
        await setActiveUser(undefined);
      } catch (e) {
        setHasError(true);
        logger.error('Error initializing system context', e);
      }
      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return (
      <LoadingIndicator
        fullscreen={true}
        message={'Initializing'}
      />
    );
  }

  if (hasError) {
    return <div style={{margin: 50}}>
      <h4>Failed to system context.</h4>
    </div>;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={url + AppRoute.SystemOverview} />
      </Route>

      <Route
        exact
        path={path + AppRoute.SystemOverview}
        component={StatsOverviewView}
      />
      <Route
        path={path + AppRoute.Users}
        component={UsersListView}
      />
      <Route
        path={path + AppRoute.ContentEditor}
        component={ContentEditorDashboard}
      />
      <Route
        path={path + AppRoute.LessonEditor}
        component={LessonEditorView}
      />
      <Route
        path={path + AppRoute.Categories}
        component={CategoriesListView}
      />
      <Route
        path={path + AppRoute.Versions}
        component={VersionListView}
      />
      <Route
        path={path + AppRoute.Lessons}
        component={LessonsListView}
      />
      <Route
        path={path + AppRoute.Skills}
        component={SkillsListView}
      />
      <Route
        path={path + AppRoute.LearningChecks}
        component={QuizzesListView}
      />
      <Route
        path={path + AppRoute.Surveys}
        component={SurveysListView}
      />
      <Route
        path={path + AppRoute.Achievements}
        component={AchievementsListView}
      />
      <Route
        path={path + AppRoute.Products}
        component={ProductsListView}
      />
      <Route
        path={path + AppRoute.Campus}
        component={SchoolsListView}
      />

      <Route path={'*'}>
        <PageNotFoundView/>
      </Route>
    </Switch>
  );
};
