import {AxiosInstance} from 'axios';
import {ListFilter} from 'api/lists/ListFilter';
import {ApiRoute} from 'api/ApiRoute';

/**
 * Provides API entity list functions
 */
export class EntityListService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches a filtered list of entities from the API
   *
   * Because the API has consistent filtering parameters,
   * any listable entity can be fetched by providing the correct API route.
   *
   * @param {string} entityRoute
   * @param {ListFilter} filter
   * @return {any[]}
   */
  async fetchEntityList(entityRoute: string, filter: ListFilter = {start: 0, end: 100}): Promise<any[]> {
    const response = await this.httpClient.post(
        entityRoute + ApiRoute.List,
        this.buildFilter(filter),
    );
    return response.data.list;
  }

  /**
   * Transforms a ListFilter into API parameters
   *
   * @param {ListFilter} filter
   * @return {any}
   */
  buildFilter(filter: ListFilter): any {
    const consolidatedFilter: any = {search: filter.search};
    if (filter.filters) {
      for (const [key, value] of Object.entries(filter.filters)) {
        consolidatedFilter[key] = value;
      }
    }
    return {
      start: filter.start,
      limit: filter.end,
      filter: consolidatedFilter,
    };
  }
}
