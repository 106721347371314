import {AxiosInstance} from 'axios';
import {CreateUserDto, UpdateUserDto, User, UserWithRelations} from 'api/users/User';
import {ApiRoute} from 'api/ApiRoute';
import {UpdateUserNotificationsPreferencesDto, UpdateUserProfileDto, UserProfile} from 'api/users/UserProfile';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {ListFilter} from 'api/lists/ListFilter';
import {Student} from 'api/users/Student';
import {Instructor, InstructorWithRelations} from 'api/users/Instructor';
import {InstructorNote} from 'api/users/InstructorNote';
import {EntityListService} from 'api/lists/EntityListService';
import {UserProperty} from 'api/users/UserProperty';
import {UserRoleType} from 'api/users/UserRole';
import {Locale} from 'api/users/Locale';
import {EntityReference} from 'api/entities/EntityReference';
import {UserImportInitializationData} from 'api/users/UserImportInitializationData';

/**
 * Provides API users functions
 */
export class UsersService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches system users
   *
   * @param {ListFilter} filter
   * @return {Promise<UserProfile[]>}
   */
  async fetchUsers(filter: ListFilter = {}): Promise<User[]> {
    const builtFilter = this.entityListService.buildFilter(filter);
    const response = await this.httpClient.post(ApiRoute.UsersList, builtFilter);
    return response.data.list;
  }

  /**
   * Fetches a user from the API
   *
   * @param {number} userId
   * @return {Promise<UserWithRelations>}
   */
  async fetchUser(userId: number): Promise<UserWithRelations> {
    const response = await this.httpClient.post(ApiRoute.GetUser, {
      id: userId,
    });
    return response.data;
  }

  /**
   * Fetches the current user's profile
   *
   * @return {Promise<UserProfile>}
   */
  async fetchUserProfile(): Promise<UserProfile> {
    const response = await this.httpClient.get<UserProfile>(ApiRoute.GetUserProfile);
    return response.data;
  }

  /**
   * Updates the current user's profile
   *
   * @param {UpdateUserProfileDto} newProfile
   * @return {Promise<SuccessResponse>}
   */
  async updateUserProfile(newProfile: UpdateUserProfileDto): Promise<SuccessResponse<UserProfile>> {
    const response = await this.httpClient.post(ApiRoute.SaveUserProfile, newProfile);
    return response.data;
  }

  /**
   * Creates a new user in the API
   *
   * @param {CreateUserDto} user
   * @return {Promise<SuccessResponse>}
   */
  async createUser(user: CreateUserDto): Promise<SuccessResponse<User>> {
    const response = await this.httpClient.post(ApiRoute.SaveUser, user);
    return response.data;
  }

  /**
   * Updates the current user's notifications settings
   *
   * @param {UpdateUserNotificationsPreferencesDto} notificationsPreferences
   */
  async updateUserNotifications(notificationsPreferences: UpdateUserNotificationsPreferencesDto): Promise<any> {
    const response = await this.httpClient.post(
        ApiRoute.SaveUserNotifications,
        {notifications: notificationsPreferences},
    );
    return response.data;
  }

  /**
   * Deletes a user from the API
   *
   * @param {number} userId
   * @return {Promise<SuccessResponse>}
   */
  async deleteUser(userId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.DeleteUser, {
      id: userId,
    });
    return response.data;
  }

  /**
   * Updates a user in the API
   *
   * @param {UpdateUserDto} user
   * @return {Promise<SuccessResponse>}
   */
  async updateUser(user: UpdateUserDto): Promise<SuccessResponse<User>> {
    const response = await this.httpClient.post(ApiRoute.SaveUser, user);
    return response.data;
  }

  /**
   * Suspends a user
   *
   * @param {number} userId
   * @return {Promise<SuccessResponse>}
   */
  async suspendUser(userId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.SuspendUser, {
      id: userId,
    });
    return response.data;
  }

  /**
   * Un-suspends a user
   *
   * @param {number} userId
   * @return {Promise<SuccessResponse>}
   */
  async unSuspendUser(userId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.UnSuspendUser, {
      id: userId,
    });
    return response.data;
  }

  /**
   * Fetches locales configured in the API
   *
   * @return {Promise<Locale>}
   */
  async fetchLocales(): Promise<Locale[]> {
    const response = await this.httpClient.get('/users/languages');
    return response.data.list;
  }

  /**
   * Fetches user's monitored by the current user
   *
   * @param {ListFilter} filter
   * @return {Promise<Student[]>}
   */
  async fetchMonitoredUsers(filter?: ListFilter): Promise<Student[]> {
    const response = await this.httpClient.post(
        '/users/monitoring',
        filter ? this.entityListService.buildFilter(filter) : {},
    );
    return response.data.list;
  }

  /**
   * Fetches instructors from the API
   *
   * @param {ListFilter} filter
   * @return {Promise<Instructor[]>}
   */
  async fetchInstructors(filter: ListFilter = {}): Promise<Instructor[]> {
    const response = await this.httpClient.post(ApiRoute.InstructorsList, {
      start: filter.start,
      limit: filter.end,
      filter: {
        search: filter.search,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a single instructor from the API
   *
   * @param {number} id
   * @return {InstructorWithRelations}
   */
  async fetchInstructor(id: number): Promise<InstructorWithRelations> {
    const response = await this.httpClient.post(ApiRoute.GetInstructor, {id});
    return response.data;
  }

  /**
   * Updates the notes for an instructor
   *
   * @param {number} instructorId
   * @param {InstructorNote[]} newNotes
   */
  async updateInstructorNotes(
      instructorId: number,
      newNotes: InstructorNote[],
  ): Promise<SuccessResponse<InstructorNote>> {
    const response = await this.httpClient.post('/users/saveProperties', {
      user: instructorId,
      properties: newNotes,
    });
    return response.data;
  }

  /**
   * Allows/disallows a user's access to the skill inventory
   *
   * @param {number} userId
   * @param {boolean} allow
   */
  async toggleUserSkillInventoryAccess(userId: number, allow: boolean): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/users/toggleSkillInventory ', {
      user: userId,
      allow,
    });
    return response.data;
  }

  /**
   * Updates a given user's extra properties
   *
   * @param {number} userId
   * @param {UserProperty[]} properties
   */
  async saveUserProperties(userId: number, properties: UserProperty[]): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/users/saveProperties', {
      user: userId,
      properties,
    });
    return response.data;
  }

  /**
   * Fetches users with student role
   *
   * @param {ListFilter} filter
   */
  async fetchStudents(filter: ListFilter = {}): Promise<User[]> {
    const mutatedFilter = filter.filters ? filter : {filters: {}};
    mutatedFilter.filters.role = UserRoleType.Student;
    return this.entityListService.fetchEntityList('/users/list', mutatedFilter);
  }

  /**
   * Fetches users with a "manager" role (ie. parent or support)
   *
   * @param {ListFilter} filter
   */
  async fetchManagers(filter: ListFilter = {}): Promise<User[]> {
    const mutatedFilter = filter.filters ? filter : {filters: {}};
    mutatedFilter.filters.roles = [UserRoleType.Parent, UserRoleType.Support];
    return this.entityListService.fetchEntityList('/users/list', mutatedFilter);
  }

  /**
   * Starts a user CSV file upload
   *
   * @param {EntityReference} file
   */
  async initializeCsvImport(file: EntityReference): Promise<UserImportInitializationData> {
    const response = await this.httpClient.post('/users/startImport', {file: [file]});
    return response.data;
  }

  /**
   * Confirms the most recent CSV import initialization and runs the actual import
   */
  async confirmCsvImport(): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/users/completeImport');
    return response.data;
  }
}
