import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {CreateSchoolDto, School, UpdateSchoolDto} from 'api/schools/School';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {SchoolStats} from 'api/schools/SchoolStats';

/**
 * Provides API school functions
 */
export class SchoolsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches schools from the API
   *
   * @param {string} searchFilter
   */
  async fetchSchools(searchFilter = '') {
    const response = await this.httpClient.post(ApiRoute.SchoolsList, {
      filter: {
        search: searchFilter,
      },
    });
    return response.data.list;
  }

  /**
   * Creates a new school in the API
   *
   * @param {CreateSchoolDto} school
   * @return {Promise<SuccessResponse>}
   */
  async createSchool(school: CreateSchoolDto): Promise<SuccessResponse<School>> {
    const response = await this.httpClient.post(ApiRoute.SchoolsSave, school);
    return response.data;
  }

  /**
   * Updates a new school in the API
   *
   * @param {UpdateSchoolDto} school
   * @return {Promise<SuccessResponse>}
   */
  async updateSchool(school: UpdateSchoolDto): Promise<SuccessResponse<School>> {
    const response = await this.httpClient.post(ApiRoute.SchoolsSave, school);
    return response.data;
  }

  /**
   * Deletes a school from the API
   *
   * @param {number} schoolId
   * @return {Promise<SuccessResponse>}
   */
  async deleteSchool(schoolId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.SchoolsDelete, {
      id: schoolId,
    });
    return response.data;
  }

  /**
   * Fetches the API key for the given school
   *
   * @param {number} schoolId
   * @return {Promise<string>}
   */
  async fetchSchoolApiKey(schoolId: number): Promise<string> {
    const response = await this.httpClient.post(ApiRoute.SchoolsGetApiKey, {
      id: schoolId,
    });
    return response.data.key;
  }

  /**
   * Fetches a school by ID
   *
   * @param {number} id
   */
  async fetchSchool(id: number): Promise<School> {
    const response = await this.httpClient.post('/schools/get', {id});
    return response.data;
  }
  /**
  * Fetches school user stats by ID
  *
  * @param {number} id
  */
  async fetchSchoolStats(id: number): Promise<SchoolStats> {
    const filter = {id};
    const response = await this.httpClient.post('/users/userCount', {filter});
    return response.data;
  }

  /**
   * Updates a school's config
   *
   * @param {School} school
   */
  async saveSchoolConfig(school: School): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/schools/saveConfig', school);
    return response.data;
  }
}
