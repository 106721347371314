import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {SaveLessonPlanDto} from 'api/lessons/LessonPlan';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';

export interface LessonPlanInputModalProps {
  onCancel?: () => any,
  onComplete?: (plan: SaveLessonPlanDto, addEntries: boolean) => any,
  initialLessonPlan?: SaveLessonPlanDto,
}

export const LessonPlanInputModal = (props: LessonPlanInputModalProps) => {
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.initialLessonPlan !== undefined;

  const [title, setTitle] = useState<string>(props.initialLessonPlan?.title ?? '');
  const [errors, setErrors] = useState<any>({});

  const submit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onComplete();
  };

  const onComplete = (addEntries = false) => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }
    props.onComplete?.({title, entries: props.initialLessonPlan?.entries ?? []}, addEntries);
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (title.trim().length < 1) {
      setErrors((current: any) => ({...current, title: true}));
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    validate();
  }, [title]);

  return <Modal
    title={__(isEditing ? 'Edit Lesson Plan' : 'Add Lesson Plan')}
    onCloseRequested={props.onCancel}
    buttons={
      <>
        <Button
          variant={ButtonVariant.Confirm}
          type={'submit'}
          form={'lessonPlanForm'}
        />
        {!isEditing &&
          <Button
            variant={ButtonVariant.Confirm}
            type={'button'}
            className={'btn-success'}
            text={__('Next')}
            onClick={() => onComplete(true)}
          />
        }
      </>
    }

    body={<>
      <form id={'lessonPlanForm'} onSubmit={submit}>
        <div className={'row-fluid'}>
          <input
            placeholder={__('Title')}
            aria-label={__('Title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={classNames('span12', {'invalid': errors.title})}
            type={'text'}
          />
        </div>
      </form>
    </>}

    footer={<InputPlaceholderMirror/>}
  />;
};
