import React from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useApiContext} from 'api/ApiContext';
import {useForm} from 'react-hook-form';
import {
  CommunityItem, CommunityItemType,
  CreateCommunityItemDto,
} from 'api/community/CommunityItem';
import {useLogger} from 'logging/logging';
import classNames from 'classnames';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';

/**
 * Modal to add or edit a community ("network entry") item
 */

export interface NetworkEntryModalProps {
  initialItem?: CommunityItem,
  onCloseRequested?: () => any,
  onCompleted?: () => any,
}

export const NetworkEntryModal = (props: NetworkEntryModalProps) => {
  const logger = useLogger(NetworkEntryModal.name);
  const {communityItemsService} = useApiContext();
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<CreateCommunityItemDto>({
    defaultValues: props.initialItem,
  });
  const isEditing = props.initialItem?.id !== undefined;
  const type = watch('type');

  const submit = async (newItem: CreateCommunityItemDto) => {
    if (isEditing && props.initialItem?.id !== undefined) {
      const updateResult = await communityItemsService.updateCommunityItem({
        ...props.initialItem,
        ...newItem,
      });
      logger.debug('Updated community item', updateResult);
    } else {
      const createResult = await communityItemsService.createCommunityItem(newItem);
      logger.debug('Created community item', createResult);
    }

    props.onCompleted?.();
  };

  return <Modal
    title={__(isEditing ? 'Edit Network Entry' : 'Add Network Entry')}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        type={'submit'}
        form={'networkEntryForm'}
      />
    }

    body={
      <form id={'networkEntryForm'} onSubmit={handleSubmit(submit)}>
        <label className={'row-fluid'}>
          <select
            placeholder={'Entry Type'}
            className={'span12'}
            {...register('type', {required: true})}
          >
            <option value={CommunityItemType.Person}>{__('Person')}</option>
            <option value={CommunityItemType.Place}>{__('Place')}</option>
          </select>
          <span style={{display: 'none'}}>Type</span>
        </label>

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Name')}
            className={classNames('span12', {'invalid': errors.name})}
            {...register('name', {required: true})}
          />
          <span style={{display: 'none'}}>Name</span>
        </label>

        {type === CommunityItemType.Place &&
          <>
            <label className={'row-fluid'}>
              <input
                type={'text'}
                placeholder={__('Contact Name')}
                className={classNames('span12')}
                {...register('contact')}
              />
              <span style={{display: 'none'}}>Contact Name</span>
            </label>

            <label className={'row-fluid'}>
              <input
                type={'text'}
                placeholder={__('Web Address')}
                className={classNames('span12')}
                {...register('url')}
              />
              <span style={{display: 'none'}}>Web Address</span>
            </label>
          </>
        }

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Phone')}
            className={classNames('span12')}
            {...register('phone')}
          />
          <span style={{display: 'none'}}>Phone</span>
        </label>

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Email')}
            className={classNames('span12')}
            {...register('email')}
          />
          <span style={{display: 'none'}}>Email</span>
        </label>

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Category')}
            className={classNames('span12')}
            {...register('category')}
          />
          <span style={{display: 'none'}}>Category</span>
        </label>

        {type === CommunityItemType.Person &&
          <label className={'row-fluid'}>
            <input
              type={'text'}
              placeholder={__('Relationship')}
              className={classNames('span12')}
              {...register('relationship')}
            />
            <span style={{display: 'none'}}>Relationship</span>
          </label>
        }

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Address')}
            className={classNames('span12')}
            {...register('address')}
          />
          <span style={{display: 'none'}}>Address</span>
        </label>

        <div className={'row-fluid'}>
          <label className={'span6'}>
            <input
              type={'text'}
              className={'span12'}
              placeholder={__('City')}
              {...register('city')}
            />
            <span style={{display: 'none'}}>City</span>
          </label>

          <label className={'span3'}>
            <input
              type={'text'}
              className={'span12'}
              placeholder={__('State')}
              {...register('state')}
            />
            <span style={{display: 'none'}}>State</span>
          </label>

          <label className={'span3'}>
            <input
              type={'text'}
              className={'span12'}
              placeholder={__('Zip')}
              {...register('zip')}
            />
            <span style={{display: 'none'}}>Zip</span>
          </label>
        </div>
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
