import React from 'react';
import {Modal} from 'components/Modal';
import {FileViewer} from 'views/resources/FileViewer';

export interface FileViewProps {
  name: string,
  url: string,
  onCloseRequested?: () => any,
}

export const FileView = (props: FileViewProps) => {
  return <Modal
    title={props.name}
    className={'full-screen'}
    onCloseRequested={props.onCloseRequested}
    body={<FileViewer url={props.url} />}
    footer={<></>}
  />;
};
