import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {useThemeContext} from 'contexts/ThemeContext';
import {Modal} from 'components/Modal';
import {useAuthContext} from 'contexts/AuthContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Link} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';

/**
 * A modal to welcome a new user
 */

export interface WelcomeModalProps {
  onCloseRequested?: () => any;
  overrideUserHideRequest?: boolean,
}

// TODO: joyride "first time" click through

export const WelcomeModal = (props: PropsWithChildren<WelcomeModalProps>) => {
  const logger = useLogger(WelcomeModal.name);
  const {goalsService} = useApiContext();
  const {currentUser} = useAuthContext();
  const {isHideWelcomeRequested, setHideWelcomeRequested} = useThemeContext();

  const [userHasGoals, setUserHasGoals] = useState<boolean>(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState<boolean>(true);

  useEffect(() => {
    if (isHideWelcomeRequested && !props.overrideUserHideRequest) {
      props.onCloseRequested?.();
    }

    (async () => {
      setIsFetchingGoals(true);
      const fetchedGoals = await goalsService.fetchGoalResources();
      setUserHasGoals(fetchedGoals.length > 0);
      logger.debug('Fetched goals', fetchedGoals);
      setIsFetchingGoals(false);
    })();
  }, []);

  return (
    <Modal
      onCloseRequested={props.onCloseRequested}
      className={'wide'}
      wrapperClassName={'welcome'}
      bodyWrapperClassName={'wrapper'}
      title={`Welcome ${currentUser?.name}`}
      body={
        <div className="row-fluid">
          <div className="span8">
            {isFetchingGoals &&
              <div style={{padding: '50px 0'}}>
                <LoadingIndicator message={'Getting started'} />
              </div>
            }

            {!isFetchingGoals &&
            <>
              <h1>Welcome to my full life</h1>
              <p>
                My full life is designed to build a custom life skills
                education based on an individual&apos;s hopes
                and dreams.
              </p>
              <h2>Getting Started Walk-Thru</h2>
              <div>
                <Link
                  to={AppRoute.DashboardContext + AppRoute.PlanOverview}
                  onClick={() => props.onCloseRequested?.()}
                  className="btn btn-info"
                >
                  First Visit
                </Link>
              </div>

              {userHasGoals && (
                <div>
                  <a className="btn btn-info">
                    Completing Course Materials & Exams
                  </a>
                </div>
              )}

              <div>
                <a className="btn btn-info">
                  Access Certificates and Documents
                </a>
              </div>
            </>
            }
          </div>
        </div>
      }

      footer={
        <div className={'placeholder-info'}>
          <label>
            <input
              type="checkbox"
              checked={isHideWelcomeRequested}
              onChange={(e) => setHideWelcomeRequested(e.target.checked)}
            />
            <span> Do not show this dialog again</span>
          </label>
        </div>
      }
    />
  );
};

WelcomeModal.STORAGE_KEY_HIDE_NEXT = 'welcome_dontShow';
