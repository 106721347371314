import React, {useMemo} from 'react';
import {AssessmentWithRelations} from 'api/assessments/Assessment';
import {useApiContext} from 'api/ApiContext';
import {SkillStatus} from 'api/skills/SkillStatus.enum';
import styleClasses from './SkillInventoryPrintModal.module.scss';
import classNames from 'classnames';

export interface SkillInventoryPrintViewProps {
  skillInventory: AssessmentWithRelations,
}

export const SkillInventoryPrintView = (props: SkillInventoryPrintViewProps) => {
  const {assessmentsService} = useApiContext();

  const categoryGroupedSkillAssessments = useMemo(
      () => assessmentsService.groupSkillAssessmentsByCategory(props.skillInventory.ilries),
      [props.skillInventory, assessmentsService],
  );

  return (
    <div className="assessment">
      <h1 className="underline">
        Inventory for {props.skillInventory.owner.name} by {props.skillInventory.author.name}
      </h1>

      {categoryGroupedSkillAssessments.map((group) => (
        <div className="category" key={group.category.key}>
          <h2>
            {group.category.parent && group.category.parent.name + ' / '}
            {group.category.name}
          </h2>
          <table className={classNames('list', styleClasses.table)}>
            <thead>
              <tr>
                <td width="100%">Name</td>
                <td className="text-center min-width">Needs Support</td>
                <td className="text-center min-width">I have  learned</td>
                <td className="text-center min-width">I am learning</td>
                <td className="text-center min-width">I need to learn</td>
                <td className="text-center min-width">No Experience</td>
              </tr>
            </thead>
            <tbody>
              {group.skillAssessments.map((skillAssessment) => (
                <tr key={skillAssessment.id}>
                  <td>{skillAssessment.name}</td>
                  <td className="text-center min-width">
                    {skillAssessment.support && <i className="icon-check" />}
                  </td>
                  <td className="text-center min-width">
                    {skillAssessment.state === SkillStatus.Completed && <i className="icon-check" />}
                  </td>
                  <td className="text-center min-width">
                    {skillAssessment.state === SkillStatus.InProgress && <i className="icon-check"/>}
                  </td>
                  <td className="text-center min-width">
                    {skillAssessment.state === SkillStatus.Recommended && <i className="icon-check"/>}
                  </td>
                  <td className="text-center min-width">
                    {skillAssessment.state === SkillStatus.NonApplicable && <i className="icon-check"/>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};
