import React, {FormEvent, useEffect, useState} from 'react';
import {Appointment} from 'api/appointments/Appointment';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {DateInput} from 'components/forms/DateInput';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {TimeInput} from 'components/forms/TimeInput/TimeInput';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {useApiContext} from 'api/ApiContext';
import {AppointmentType} from 'api/appointments/AppointmentType.enum';

export interface AppointmentInputModalProps {
  initialAppointment?: Appointment,
  courseId?: number,
  studentId?: number,
  onCloseRequested?: () => any,
  onComplete?: () => any,
}

export const AppointmentInputModal = (props: AppointmentInputModalProps) => {
  const {appointmentsService} = useApiContext();
  const isEditing = props.initialAppointment?.id !== undefined;

  const initialStartDate = props.initialAppointment?.start ?
    dayjs(props.initialAppointment.start) :
    undefined;
  const initialEndDate = props.initialAppointment ?
    dayjs(props.initialAppointment.end) :
    undefined;
  const initialLength = initialStartDate && initialEndDate ?
    initialEndDate?.diff(initialStartDate, 'hour') :
    undefined;

  const [name, setName] =
    useState<string>(props.initialAppointment?.name ?? '');
  const [link, setLink] =
    useState<string>(props.initialAppointment?.link ?? '');
  const [startDate, setStartDate] =
    useState<Date>(initialStartDate?.toDate() ?? new Date());
  const [duration, setDuration] =
    useState<number | undefined>(initialLength ?? 1);
  const [errors, setErrors] = useState<any>({});

  const validate = (): boolean => {
    setErrors({});

    let isValid = true;
    if (name.trim().length < 1) {
      setErrors((current: any) => ({...current, name: true}));
      isValid = false;
    }
    if (link.trim().length < 1) {
      setErrors((current: any) => ({...current, link: true}));
      isValid = false;
    }
    if (!duration) {
      setErrors((current: any) => ({...current, duration: true}));
      isValid = false;
    }

    return isValid;
  };

  console.log(startDate);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    if (isEditing && props.initialAppointment?.id) {
      await appointmentsService.updateAppointment({
        ...props.initialAppointment,
        name,
        link,
        start: startDate,
        end: dayjs(startDate).add(duration ?? 0, 'hours').toDate(),
      });
    } else {
      await appointmentsService.createAppointment({
        courseId: props.courseId,
        ownerId: props.studentId,
        name,
        link,
        start: startDate,
        end: dayjs(startDate).add(duration ?? 0, 'hours').toDate(),
        type: props.courseId ? AppointmentType.Course : AppointmentType.User,
      });
    }

    props.onComplete?.();
  };

  useEffect(() => {
    validate();
  }, [name, link, duration]);

  return <Modal
    title={isEditing ? __('Edit Appointment') : __('Add Appointment')}
    bodyClassName={'lesson-editor'}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        type={'submit'}
        form={'appointmentForm'}
      />
    }

    body={
      <div style={{background: 'white', padding: 10, borderRadius: '4px 10px'}}>
        <form id={'appointmentForm'} onSubmit={submit}>
          <div className="row-fluid">
            <input
              type="text"
              className={classNames('span12', {'invalid': errors.name})}
              placeholder={__('Title')}
              aria-label={__('Title')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="info ng-isolate-scope">
              {__('Meeting Title')}
            </div>
          </div>

          <div className="row-fluid">
            <input
              type="text"
              className={classNames(
                  'span12',
                  {'invalid': errors.link},
              )}
              placeholder={__('Link')}
              aria-label={__('Link')}
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <div className="info ng-isolate-scope">
              {__('Meeting Link')}- https://www.example.com
            </div>
          </div>

          <div className="row-fluid">
            <DateInput
              value={startDate}
              onChange={(newDate) => setStartDate(newDate)}
            />
            <div className="info ng-isolate-scope">
              {__('Date')}
            </div>

            <TimeInput
              initialHour={initialStartDate?.hour()}
              initialMinute={initialStartDate?.minute()}
              onHourChange={(newHour) => setStartDate((current) => {
                const copy = new Date();
                copy.setTime(current.getTime());
                copy.setHours(newHour ?? 0);
                return copy;
              })}
              onMinuteChange={(newMinute) => setStartDate((current) => {
                const copy = new Date();
                copy.setTime(current.getTime());
                copy.setMinutes(newMinute ?? 0);
                return copy;
              })}
            />

            <div className="info ng-isolate-scope">
              {__('Start time')}
            </div>
            <input
              style={{marginLeft: 0}}
              type="number"
              min="1"
              className={classNames(
                  'span5',
                  {'invalid': errors.duration},
              )}
              aria-label={__('Meeting Length (hours)')}
              value={duration?.toString()}
              onChange={(e) => {
                const intVal = parseInt(e.target.value);
                if (intVal && !isNaN(intVal)) {
                  setDuration(intVal);
                } else {
                  setDuration(undefined);
                }
              }}
              placeholder={__('Meeting Length (hours)')}
            />
            <div className="info ng-isolate-scope">
              {__('Meeting length (hours)')}
            </div>
          </div>
        </form>
      </div>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
