import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {__} from 'i18n/localize';
import {JournalEntry} from 'api/journal/JournalEntry';
import {useApiContext} from 'api/ApiContext';
import {JournalEntryType} from 'api/journal/JournalEntryType';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {useLogger} from 'logging/logging';

/**
 * A modal to add or edit a journal entry
 */

export interface JournalEntryInputModalProps {
  initialEntry?: JournalEntry,
  onCloseRequested?: () => any,
  onComplete?: (isNew: boolean, newEntry?: JournalEntry) => any,
}

export const JournalEntryInputModal = (props: JournalEntryInputModalProps) => {
  const logger = useLogger(JournalEntryInputModal.name);
  const {journalService} = useApiContext();

  const [title, setTitle] = useState<string>(props.initialEntry?.title ?? '');
  const [comment, setComment] = useState<string>(props.initialEntry?.text ?? '');

  const isEditing = props.initialEntry?.id !== undefined;
  const allowEditTitle = !isEditing || props.initialEntry?.type === JournalEntryType.Post;

  const submit = async () => {
    let saveResult;
    if (isEditing && props.initialEntry?.id) {
      saveResult = await journalService.updateJournalEntry({
        id: props.initialEntry.id,
        title,
        text: comment,
        type: JournalEntryType.Post,
      });
      logger.debug('Updated journal entry', saveResult);
    } else {
      saveResult = await journalService.createJournalEntry({
        title: title,
        text: comment,
        type: JournalEntryType.Post,
      });
      logger.debug('Created journal entry', saveResult);
    }
    props.onComplete?.(!isEditing, saveResult.entity);
  };

  useEffect(() => {
    if (props.initialEntry) {
      logger.debug('Editing journal entry', props.initialEntry);
    }
  }, []);

  return (
    <Modal
      title={__(isEditing ? 'Edit Entry' : 'Add Entry')}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          aria-label={'submit'}
          onClick={submit}
        />
      }

      body={
        <form>
          {allowEditTitle &&
            <div className="row-fluid">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id={'titleInput'}
                type="text"
                className="span12"
                placeholder={__('Entry Title')}
                required
              />
              <label htmlFor={'titleInput'} style={{display: 'none'}}>
                {__('Entry Title')}
              </label>
            </div>
          }

          <div className="row-fluid">
            <RichTextInput
              label={__('Entry Comment')}
              placeholder={__('Entry Comment')}
              value={comment}
              onChange={(newValue) => setComment(newValue)}
            />
          </div>
        </form>
      }
    />
  );
};
