import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {useApiContext} from 'api/ApiContext';
import {ButtonConfirm} from 'components/buttons/ButtonConfirm';
import {DateInput} from 'components/forms/DateInput';
import {__, localizeText} from 'i18n/localize';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {SchoolPicker} from 'components/forms/SchoolPicker';
import {UserPicker} from 'components/forms/UserPicker';
import {UserAvatar} from 'components/UserAvatar';
import {SaveUserDto, User, UserWithRelations} from 'api/users/User';
import {UserRole, UserRoleType} from 'api/users/UserRole';
import {useLogger} from 'logging/logging';
import {Select} from 'components/forms/Select';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {Tooltip} from 'components/Tooltip';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface EditUserModalProps {
  userId?: number,
  onCancel?: () => any,
  onComplete?: () => any,
}

export const EditUserModal = (props: EditUserModalProps) => {
  const logger = useLogger(EditUserModal.name);
  const {usersService, authService} = useApiContext();
  const isEditing = props.userId !== undefined;

  const [initialUser, setInitialUser] = useState<UserWithRelations | undefined>(undefined);
  const [isFetchingInitial, setIsFetchingInitial] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [newUser, setNewUser] = useState<SaveUserDto>({
    name: '',
    email: '',
    login: '',
    password: '',
    related: [],
    schools: [],
    roles: [],
    locale: {id: 1},
  });
  const [roles, setRoles] = useState<UserRole[]>([]);

  const selectedRoles = roles.filter((role) => newUser.roles.some((userRole) => userRole.id === role.id));
  const showSchools = selectedRoles.length > 0 && !selectedRoles.some(
      (role) => [UserRoleType.SystemAdmin, UserRoleType.Editor].includes(role.type),
  );
  const showBio = selectedRoles.some((role) => role.type === UserRoleType.Teacher);
  const showStudents = selectedRoles.some((role) => [UserRoleType.Parent, UserRoleType.Support].includes(role.type));
  const showManagers = !showStudents &&
    selectedRoles.some((role) => [UserRoleType.Student, UserRoleType.IlStudent].includes(role.type));

  const submit = async () => {
    let result;
    if (props.userId) {
      result = await usersService.updateUser({
        ...newUser,
        id: props.userId,
        password,
      });
    } else {
      result = await usersService.createUser({
        ...newUser,
        password,
      });
    }
    logger.debug('Saved user', result);
    props.onComplete?.();
  };

  useEffect(() => {
    (async () => {
      const fetchedRoles = await authService.fetchUserRoles();
      setRoles(fetchedRoles);
      logger.debug('Fetched roles', fetchedRoles);

      if (props.userId) {
        const fetchedUser = await usersService.fetchUser(props.userId);
        setInitialUser(fetchedUser);
        logger.debug('Fetched user', fetchedUser);
      }
      setIsFetchingInitial(false);
    })();
  }, []);

  useEffect(() => {
    if (initialUser) {
      setNewUser({
        ...initialUser,
        roles: initialUser.roles.map((role) => ({id: role.id})),
        schools: initialUser.schools.map((school) => ({id: school.id})),
        related: initialUser.related.map((related) => ({id: related.id})),
        locale: initialUser.locale,
      });
    }
  }, [initialUser]);

  return (
    <Modal
      title={isEditing ? 'Edit User' : 'Create User'}
      className={'tall'}
      onCloseRequested={props.onCancel}

      buttons={
        <ButtonConfirm onClick={submit} />
      }

      body={
        <form
          name="form.edit"
          onSubmit={async (e) => {
            e.preventDefault();
            await submit();
          }}
          autoComplete={'off'}
        >
          {isFetchingInitial && <LoadingIndicator/>}
          {!isFetchingInitial &&
          <>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <div
                className="image span2">
                <UserAvatar size={55} user={initialUser || {} as User} />
              </div>
              <FileUploadInput
                buttonText={localizeText('Add Picture')}
                initialFiles={initialUser?.image ? [initialUser?.image] : []}
                onChange={(newFiles) => {
                  setNewUser((current) => ({
                    ...current,
                    image: newFiles[0],
                  }));
                }}
              />
            </div>
            <div className="row-fluid">
              <input
                value={newUser.name}
                onChange={(e) => setNewUser((current) => ({
                  ...current,
                  name: e.target.value,
                }))}
                type="text"
                className="span12"
                required
                placeholder={localizeText('User Name')}
                autoComplete={'off'}
              />
            </div>
            <div className="row-fluid">
              <input
                type="text"
                className="span6"
                value={newUser.email}
                onChange={(e) => setNewUser((current) => ({
                  ...current,
                  email: e.target.value,
                }))}
                required
                placeholder={localizeText('Email Address')}
                autoComplete={'off'}
              />
              <div className="btn-group span6 pull-right">
                <div style={{display: 'inline-block'}}>
                  <DateInput
                    value={newUser.expDate ? new Date(newUser.expDate) : undefined}
                    placeholder={localizeText('Seat Expiration')}
                    onChange={(newValue) => setNewUser((current) => ({
                      ...current,
                      expDate: newValue.getTime(),
                    }))}
                  />
                </div>
                <Tooltip title={__('Clear')}>
                  <a
                    className="btn btn-icon btn-mini ng-scope"
                    style={{top: -5, left: -8}}
                    onClick={() => setNewUser((current) => ({
                      ...current,
                      expDate: undefined,
                    }))}
                  >
                    <i className="icon-cancel" />
                  </a>
                </Tooltip>
              </div>

              <div className="row-fluid">
                <input
                  type="text"
                  className="span6"
                  required
                  value={newUser.login}
                  onChange={(e) => setNewUser((current) => ({
                    ...current,
                    login: e.target.value,
                  }))}
                  placeholder={localizeText('System Login')}
                  autoComplete={'off'}
                />
                <input
                  type="text"
                  className="span6"
                  placeholder={localizeText('Identification Code')}
                  autoComplete={'off'}
                />
              </div>
              <div className="row-fluid">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="span6"
                  required={!props.userId}
                  placeholder={localizeText('System Password')}
                  autoComplete={'new-password'}
                />
                <select
                  className="span6"
                  value={newUser.locale.id}
                  onChange={(e) => setNewUser((current) => ({
                    ...current,
                    locale: {id: parseInt(e.target.value)},
                  }))}
                  required
                  placeholder={localizeText('Interface Language')}
                >
                  <option value={1}>English</option>
                  <option value={2}>English CIF</option>
                </select>
              </div>

              <Select
                placeholder={__('User Roles')}
                multiple={true}
                options={roles.map((role) => ({value: role.id.toString(), label: role.name}))}
                value={newUser.roles.map((role) => role.id.toString())}
                onChange={(selected) => {
                  setNewUser((current) => ({
                    ...current,
                    roles: (selected as string[])?.map((id) => ({id: parseInt(id)})),
                  }));
                }}
              />

              {showSchools &&
              <SchoolPicker
                placeholder={__('Campus')}
                value={newUser.schools.map((school) => school.id)}
                multiple={true}
                onChange={(newValue) => {
                  setNewUser((current) => ({
                    ...current,
                    schools: (newValue as number[]).map((value) => ({id: value})),
                  }));
                }}
              />
              }

              {showStudents &&
                <UserPicker
                  placeholder={__('Participants')}
                  multiple={true}
                  value={newUser.related.map((related) => related.id)}
                  onChange={(newValue) => {
                    setNewUser((current) => ({
                      ...current,
                      related: (newValue as number[]).map((value) => ({id: value})),
                    }));
                  }}
                />
              }

              {showManagers &&
                <UserPicker
                  placeholder={__('Managers')}
                  multiple={true}
                  value={newUser.related.map((related) => related.id)}
                  onChange={(newValue) => {
                    setNewUser((current) => ({
                      ...current,
                      related: (newValue as number[]).map((value) => ({id: value})),
                    }));
                  }}
                />
              }

              {showBio &&
              <>
                <div className="row-fluid">
                  <input
                    type="text"
                    className="span12"
                    value={newUser.keywords}
                    onChange={(e) => setNewUser((current) => ({
                      ...current,
                      keywords: e.target.value,
                    }))}
                    placeholder={localizeText('Keywords')}
                  />
                </div>

                <div className="row-fluid">
                  <RichTextInput
                    placeholder={__('Biography')}
                    value={newUser.biography ?? ''}
                    onChange={(newValue) => setNewUser((current) => ({
                      ...current,
                      biography: newValue,
                    }))}
                  />
                </div>

                <div className="row-fluid">
                  <FileUploadInput
                    buttonText={localizeText('Add CV')}
                  />
                </div>
              </>
              }

              {(showManagers || showStudents || showBio) &&
              <>
                <div className="row-fluid">
                  <input
                    type="text"
                    className="span6"
                    value={newUser.practice}
                    onChange={(e) => setNewUser((current) => ({
                      ...current,
                      practice: e.target.value,
                    }))}
                    placeholder={localizeText('Practice')}
                  />

                  <select
                    className="span6"
                    value={newUser.occupation}
                    onChange={(e) => setNewUser((current) => ({
                      ...current,
                      occupation: e.target.value,
                    }))}
                    placeholder={localizeText('Role')}
                  >
                    <option value={''}>
                      {localizeText('Choose Occupation')}
                    </option>
                    <option value="Dentist">
                      {localizeText('Dentist')}
                    </option>
                    <option value="Assistant">
                      {localizeText('Assistant')}
                    </option>
                    <option value="Hygienist">
                      {localizeText('Hygienist')}
                    </option>
                    <option value="Front Office">
                      {localizeText('Front Office')}
                    </option>
                    <option value="Lab Technician">
                      {localizeText('Lab Technician')}
                    </option>
                    <option value="Student">
                      {localizeText('Student')}
                    </option>
                    <option value="Other">
                      {localizeText('Other')}
                    </option>
                  </select>
                </div>

                <div className="row-fluid">
                  <input
                    placeholder={__('Street')}
                    value={newUser.street}
                    onChange={(e) => setNewUser((current) => ({...current, street: e.target.value}))}
                    type="text"
                    className="span12"
                  />
                </div>
                <div className="row-fluid">
                  <input
                    placeholder={__('City')}
                    value={newUser.city}
                    onChange={(e) => setNewUser((current) => ({...current, city: e.target.value}))}
                    type="text"
                    className="span6"
                  />
                  <input
                    placeholder={__('State')}
                    value={newUser.state}
                    onChange={(e) => setNewUser((current) => ({...current, state: e.target.value}))}
                    type="text"
                    className="span3"
                  />
                  <input
                    placeholder={__('Zip')}
                    value={newUser.zip}
                    onChange={(e) => setNewUser((current) => ({...current, zip: e.target.value}))}
                    type="text"
                    className="span3"
                  />
                </div>

                <div className="row-fluid">
                  <select
                    className="span6"
                    value={newUser.country}
                    onChange={(e) => setNewUser((current) => ({...current, country: e.target.value}))}
                  >
                    <option value="" />
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Åland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AG">Antigua</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="BN">Brunei</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D&rsquo;ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="VA">Holy See</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KR">Korea</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">Macedonia</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia</option>
                    <option value="MD">Moldova</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="PM">Saint Pierre</option>
                    <option value="VC">Saint Vincent</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">South Georgia</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syria</option>
                    <option value="TW">Taiwan</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>

                <div className="row-fluid">
                  <input
                    placeholder={__('Phone')}
                    value={newUser.phone}
                    onChange={(e) => setNewUser((current) => ({...current, phone: e.target.value}))}
                    type="text"
                    className="span12"
                  />
                </div>

                <div className="row-fluid">
                  <div className=" span6">
                    <label>
                      <input
                        type="checkbox"
                        checked={newUser.agdMember}
                        onChange={(e) => setNewUser((current) => ({...current, agdMember: e.target.checked}))}
                      />
                      <span> {__('AGD Member')}</span>
                    </label>
                  </div>
                  <div className="span6">
                    <label>
                      <input
                        type="checkbox"
                        checked={newUser.adaMember}
                        onChange={(e) => setNewUser((current) => ({...current, adaMember: e.target.checked}))}
                      />
                      <span> {__('ADA Member')}</span>
                    </label>
                  </div>
                </div>
              </>
              }
            </div>
          </>
          }
        </form>
      }

      footer={isFetchingInitial ? <></> : <InputPlaceholderMirror/>}
    />
  );
};
