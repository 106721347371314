import React, {useMemo} from 'react';
import {Line} from 'react-chartjs-2';
import {StatEvent} from 'api/stats/StatEvent';
import dayjs from 'dayjs';
import {StatEventType} from 'api/stats/StatEventType.enum';

export interface ParticipantStatsGraphProps {
  events: StatEvent[],
  earliestLookBackMonths: number,
  latestLookBackMonths: number,
}

export const ParticipantStatsGraph = (props: ParticipantStatsGraphProps) => {
  const statCountForDate = (month: number, year: number, type: StatEventType) => {
    const stat = props.events.find((event) => event.month === month && event.year === year && event.type === type);
    return stat?.count ?? 0;
  };

  const dataPoints: {month: number, year: number}[] = useMemo(() => {
    const minEventDate = dayjs().add(props.earliestLookBackMonths, 'months');
    const maxEventDate = dayjs().add(props.latestLookBackMonths, 'months');
    let iterationDate = minEventDate;
    const result: {month: number, year: number}[] = [];
    while (iterationDate.isBefore(maxEventDate)) {
      result.push({month: iterationDate.month(), year: iterationDate.year()});
      iterationDate = iterationDate.add(1, 'month');
    }
    return result;
  }, [props.earliestLookBackMonths, props.latestLookBackMonths]);

  return (
    <Line
      data={{
        labels: dataPoints.map((dataPoint) => (dataPoint.month + 1) + '-' + dataPoint.year),
        datasets: [
          {
            label: 'Logins',
            data: dataPoints.map((dataPoint) => statCountForDate(
                dataPoint.month,
                dataPoint.year,
                StatEventType.Login,
            )),
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Completed Goals',
            data: dataPoints.map((dataPoint) => statCountForDate(
                dataPoint.month,
                dataPoint.year,
                StatEventType.GoalCompleted,
            )),
            backgroundColor: ['rgba(153, 102, 255, 0.2)'],
            borderColor: ['rgba(153, 102, 255, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Completed Action Steps',
            data: dataPoints.map((dataPoint) => statCountForDate(
                dataPoint.month,
                dataPoint.year,
                StatEventType.ActionStepCompleted,
            )),
            backgroundColor: ['rgba(0, 200, 200, 0.2)'],
            borderColor: ['rgba(0, 200, 200, 1)'],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};
