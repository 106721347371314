import React, {ReactElement, MouseEvent} from 'react';
import classNames from 'classnames';
import {Icon} from 'components/Icon';
import {Tooltip} from 'components/Tooltip';

export interface ButtonProps {
  className?: string,
  iconCode?: string,
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any,
  disabled?: boolean,
  tooltip?: string | ReactElement,
  variant?: ButtonVariant,
  text?: string,
  htmlTitle?: string,
  form?: string,
  type?: 'submit' | 'button',
  'aria-label'?: string,
  style?: React.CSSProperties,
}

export enum ButtonVariant {
  Add,
  Cancel,
  Confirm,
  Delete,
  Edit,
  Print,
  Info,
  Download,
  Cert,
  Session,
  Assign,
  Deactivate,
}

export const Button = (props: ButtonProps) => {
  let variantClassName = '';
  let variantIconCode = '';
  let variantTooltip = '';

  if (props.variant === ButtonVariant.Print) {
    variantIconCode = 'icon-print';
    variantClassName = 'btn-info';
    variantTooltip = 'Print';
  } else if (props.variant === ButtonVariant.Info) {
    variantIconCode = 'icon-info';
    variantClassName = 'btn-info';
    variantTooltip = 'Info';
  } else if (props.variant === ButtonVariant.Cancel) {
    variantIconCode = 'icon-cancel';
    variantClassName = 'btn-default';
    variantTooltip = 'Cancel';
  } else if (props.variant === ButtonVariant.Confirm) {
    variantClassName = 'btn-success';
    variantIconCode = 'icon-ok-circled';
    variantTooltip = 'Confirm';
  } else if (props.variant === ButtonVariant.Session) {
    variantClassName = 'btn-success';
    variantIconCode = 'icon-ok-circled';
    variantTooltip = 'Add Session Report';
  } else if (props.variant === ButtonVariant.Edit) {
    variantIconCode = 'icon-edit';
    variantTooltip = 'Edit';
  } else if (props.variant === ButtonVariant.Add ) {
    variantClassName = 'btn-success';
    variantIconCode = 'icon-plus-circled';
    variantTooltip = 'Add';
  } else if (props.variant === ButtonVariant.Download) {
    variantIconCode = 'icon-download';
    variantClassName = 'btn-default';
    variantTooltip = 'Export';
  } else if (props.variant === ButtonVariant.Delete) {
    variantIconCode = 'icon-trash';
    variantTooltip = 'Delete';
  } else if (props.variant === ButtonVariant.Assign) {
    variantIconCode = 'paper-plane';
    variantTooltip = 'Assign';
  } else if (props.variant === ButtonVariant.Cert) {
    variantIconCode = 'icon-award';
    variantTooltip = 'Certificate Template';
  } else if (props.variant === ButtonVariant.Deactivate) {
    variantIconCode = 'icon-history';
    variantTooltip = 'Deactivate';
  }

  const iconCode = props.iconCode ?? variantIconCode;
  const tooltip = props.tooltip ?? variantTooltip ?? undefined;

  const button = <button
    title={props.htmlTitle}
    type={props.type ?? 'button'}
    onClick={(e) => props.onClick?.(e)}
    form={props.form}
    aria-label={variantTooltip}

    className={classNames(
        'btn',
        variantClassName,
        props.className,
        {disabled: props.disabled},
    )}
    style={{
      outline: 'none',
      fontSize: 12,
      ...props.style,
    }}

    // this mousedown handler prevents focus state on buttons after clicks.
    // the focus state style is not altered, as doing so is bad for usability,
    // but this prevents that style from appearing on clicks
    onMouseDown={(e) => e.preventDefault()}
  >
    {iconCode && <Icon code={iconCode}/>}
    {props.text &&
      <span style={{marginLeft: iconCode ? 5 : 0}}>{props.text}</span>
    }
  </button>;

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {button}
      </Tooltip>
    );
  } else {
    return button;
  }
};
