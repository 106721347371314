import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useSessionContext} from 'contexts/SessionContext';
import {useLogger} from 'logging/logging';
import {__, localizeText} from 'i18n/localize';
import {UserRoleType} from 'api/users/UserRole';
import {useThemeContext} from 'contexts/ThemeContext';
import {useApiContext} from 'api/ApiContext';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {UpdateUserProfileDto, UserProfile} from 'api/users/UserProfile';
import {LoadingIndicator} from 'components/LoadingIndicator';
import './EditProfileModal.css';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {UserAvatar} from 'components/UserAvatar';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {FileUpload} from 'api/file-uploads/FileUpload';

/**
 * A modal to edit the current user's profile
 */

export interface EditProfileModalProps {
  onCloseRequested?: () => any;
}

export const EditProfileModal = (
    props: PropsWithChildren<EditProfileModalProps>,
) => {
  const logger = useLogger(EditProfileModal.name);

  const {fetchSessionDetails, sessionDetails} = useSessionContext();
  const {usersService} = useApiContext();
  const {fontSize, decrementFontSize, incrementFontSize} = useThemeContext();
  const {register, handleSubmit, watch, formState: {errors, isSubmitting}} =
    useForm<UpdateUserProfileDto>();

  const [initialProfile, setInitialProfile] = useState<UserProfile | undefined>(undefined);
  const [isFetchingProfile, setIsFetchingProfile] = useState<boolean>(true);
  const [newImage, setNewImage] = useState<FileUpload | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = async (data: UpdateUserProfileDto) => {
    const newProfileData: UpdateUserProfileDto = {
      ...data,
      password: data.password && data.password.trim().length > 1 ? data.password : undefined,
    };
    if (newImage) {
      newProfileData.image = {id: newImage.id};
    }

    await usersService.updateUserProfile(newProfileData);
    await fetchSessionDetails();
    props.onCloseRequested?.();
  };

  useEffect(() => {
    (async () => {
      setIsFetchingProfile(true);

      const fetchedProfile = await usersService.fetchUserProfile();
      setInitialProfile(fetchedProfile);
      logger.debug('Fetched profile', fetchedProfile);

      setIsFetchingProfile(false);
    })();
  }, []);

  return (
    <Modal
      title={localizeText('User Profile')}
      onCloseRequested={props.onCloseRequested}
      wrapperClassName={'welcome'}
      bodyClassName={'lesson-editor'}
      bodyBackground={'#5da4a7'}
      bodyPadding={20}

      buttons={
        <Button
          aria-label={'submit'}
          variant={ButtonVariant.Confirm}
          type={'submit'}
          form={'userProfileForm'}
          disabled={isSubmitting}
        />
      }

      body={
        <>
          {isFetchingProfile &&
            <LoadingIndicator
              background={'none'}
              message={'Loading profile...'}
            />
          }

          {!isFetchingProfile &&
          <>
            <div className="header">
              <div className="name">{localizeText('User Info')}</div>
            </div>
            <div className="row-fluid text-center">
              <div className="image span12">
                <UserAvatar
                  size={95}
                  src={newImage?.url}
                />
              </div>
            </div>

            <div className="row-fluid text-center">
              <div className="span12">
                <h1 style={{marginBottom: 5}}>
                  {watch('name') ?? initialProfile?.name ?? ''}
                </h1>
                <p className="second">
                  {watch('login') ?? initialProfile?.login ?? ''}
                  {' | '}
                  {watch('email') ?? initialProfile?.email ?? ''}
                </p>
              </div>
            </div>
            <div className="header">
              <div className="name">
                {localizeText('User Font Size')}
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: 10,
                borderRadius: '4px 10px',
                color: '#555555',
              }}
            >
              <div className="row-fluid">
                <div
                  className="icon ng-scope icon-minus-circled"
                  onClick={decrementFontSize}
                  style={{padding: 5}}
                />
                <span className="span2">
                  {fontSize}
                </span>
                <div
                  className="icon ng-scope icon-plus-circled"
                  onClick={incrementFontSize}
                  style={{padding: 5}}
                />
              </div>
            </div>

            {/* <div className="secHead" ng-if="edit.data.expDate">*/}
            {/*    {localize('Seat Expiration')}*/}
            {/* </div>*/}
            {/* <div className="row-fluid" ng-if="edit.data.expDate">*/}
            {/*    <span className="span6">*/}
            {/*        /!*{{edit.data.expDate | date:'MM/dd/yy'}}*!/*/}
            {/*    </span>*/}
            {/* </div>*/}

            <form
              id="userProfileForm"
              onSubmit={handleSubmit(submit)}
              role="form"
            >
              <hr />
              <div className="header">
                <div className="name">{localizeText('User Info')}</div>
              </div>
              <div
                style={{
                  background: 'white',
                  padding: 10,
                  borderRadius: '4px 10px',
                }}
              >
                <div className="row-fluid">
                  <FileUploadInput
                    buttonText={__('Add Picture')}
                    initialFiles={initialProfile?.image ? [initialProfile.image] : []}
                    onChange={(newImages) => setNewImage(newImages[0])}
                  />
                </div>
                <div className="row-fluid">
                  <input
                    id="nameInput"
                    type="text"
                    className={`span12 ${errors.name ? 'invalid' : ''}`}
                    placeholder={localizeText('Full Name')}
                    defaultValue={initialProfile?.name}
                    {...register('name', {required: true})}
                  />
                  <label htmlFor="nameInput" className="info">
                    {localizeText('name')}
                  </label>
                </div>
                <div className="row-fluid">
                  <input
                    id="emailInput"
                    type="text"
                    className={classNames(
                        'span12',
                        {invalid: errors.email !== undefined},
                    )}
                    placeholder={localizeText('Email Address')}
                    defaultValue={initialProfile?.email}
                    {...register('email', {required: true})}
                  />
                  <label htmlFor="emailInput" className="info">
                    {localizeText('email')}
                  </label>
                </div>

                <div className="row-fluid">
                  <input
                    id="usernameInput"
                    type="text"
                    className={classNames(
                        'span12',
                        {invalid: errors.login !== undefined},
                    )}
                    defaultValue={initialProfile?.login}
                    placeholder={localizeText('User Login')}
                    {...register('login', {required: true})}
                  />
                </div>
                <div className="row-fluid">
                  <label htmlFor="usernameInput" className="info">
                    {localizeText('user id')}
                  </label>
                </div>
                <div className="row-fluid">
                  <input
                    id="passwordInput"
                    type="password"
                    className="span12"
                    placeholder={localizeText('Password')}
                    {...register('password')}
                  />
                  <label htmlFor="passwordInput" className="info">
                    {localizeText('password')}
                  </label>
                </div>
              </div>

              <hr />

              {sessionDetails?.role?.type === UserRoleType.Teacher && (
                <>
                  <div className="secHead">
                    {localizeText('Instructor Info')}
                  </div>
                  <div className="row-fluid">
                    {/* <richtext ng-model="edit.data.biography"
                          type="text"*/}
                    {/*  className="span12 second"
                        placeholder="{{'Biography'|localize}}"*/}
                    {/*  rows="5"></richtext>*/}
                  </div>
                  <div className="row-fluid">
                    {/* <upload ng-model="edit.data.cv"
                        text="{{'Add CV'|localize}}"*/}
                    {/*        className="span12"></upload>*/}
                  </div>

                  <hr />
                </>
              )}
            </form>
          </>
          }
        </>
      }

      footer={
        <>
          {!isFetchingProfile &&
            <InputPlaceholderMirror />
          }
        </>
      }
    />
  );
};
