import React, {useEffect, useRef, useState} from 'react';
import {SketchPicker} from 'react-color';
import styleClasses from './FullColorPicker.module.scss';

/**
 * A color picker with all colors available
 */

export type ColorInputProps = {
  value?: string,
  onChange?: (newColor: string) => any,
  label?: string,
  disabled?: boolean,
};

export const FullColorPicker = (props: ColorInputProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  useEffect(function onMount() {
    const handleOutsideClick = (e: any) => {
      if (!containerRef.current?.contains(e.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className={styleClasses.colorInput} ref={containerRef}>
      <input
        style={{width: '100%'}}
        placeholder={props.label}
        type={'text'}
        onFocus={() => setShowPicker(true)}
        onChange={(e) => props.onChange?.(e.target.value)}
        value={props.value}
        autoComplete={'off'}
      />
      {showPicker &&
        <SketchPicker
          disableAlpha={true}
          color={props.value ?? undefined}
          className={styleClasses.colorPicker}
          onChangeComplete={(selectedColor) => {
            props.onChange?.(selectedColor.hex);
          }}
        />
      }
    </div>
  );
};
