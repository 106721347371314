/**
 * The features available in the app (used for access control)
 */
export enum AppFeature {
  CreateSysAdmin = 'users.create.sysadmin',
  CreateUser = 'users.create',
  CreateCourse = 'courses.create',
  AddAdvancedResource = 'add.advanced.resources',
  CreateSchool = 'school.create',
  ChangeSchool = 'school.change',
  SchoolSurveys = 'school.surveys',
  CreateProduct = 'product.create',
  CreateLesson = 'lesson.create',
  LessonFiles = 'lesson.files',
  EditLesson = 'lesson.editor',
  EditAllGoals = 'goals.edit.all',
  EditGoalTemplate = 'goals.template.edit',
  GoalsAddLesson = 'goals.add.lesson',
  GoalsAddQuiz = 'goals.add.quiz',
  GoalsStepTime = 'goals.step.time',
  GoalsStepSkill = 'goals.step.skill',
  GoalsChangeRated = 'goals.change.rated',
  EditSystemResources = 'edit.system.resources',
  EditSchoolContent = 'edit.school.content',
  ManageSchool = 'school.manage',
  TeacherActivity = 'teacher.activity',
  TeacherClasses = 'teacher.classes',
  TeacherStudents = 'teacher.students',
  EditCourseGoal = 'course.goal.edit',
  ManageCourse = 'course.manage',
  DashboardSysadmin = 'dashboard.sysadmin',
  DashboardAdmin = 'dashboard.admin',
  DashboardActivity = 'dashboard.activity',
  DashboardGoals = 'dashboard.goals',
  DashboardStudying = 'dashboard.studying',
  DashboardPlan = 'dashboard.plan',
  DashboardDreams = 'dashboard.dreams',
  DashboardLearning = 'dashboard.learning',
  DashboardLessons = 'dashboard.lessons',
  StudentDashboard = 'student.dashboard',
  StudentFiles = 'student.files',
  StudentChallenges = 'student.challenges',
  StudentAddGoal = 'student.addGoal',
  StudentToggleSkillInventory = 'student.toggleSkillInventory',
  PlanOverview = 'plan.overview',
  ChangeOwnership = 'ownership.change',
  InstructorProperties = 'instructor.properties',
  DashboardGoalsEdit = 'dashboard.goals.edit',
  DashboardSkillsAdd = 'dashboard.skills.add',
  DashboardNetwork = 'dashboard.network',
  DashboardEditor = 'dashboard.editor',
  CommunityEdit = 'community.edit',
  LearningComplete = 'learning.complete',
  MakeAssessment = 'make.assessment',
  AddSessionReport = 'add.sessionReport',
  StudentGrade = 'student.grade',
}
