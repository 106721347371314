import React, {FormEvent, useEffect, useState} from 'react';
import {IconPicker} from 'components/IconPicker';
import {Category, CategoryWithParent, CreateCategoryDto} from 'api/categories/Category';
import {ColorPicker} from 'components/ColorPicker';
import {useNotificationContext} from 'contexts/NotificationContext';
import {__, localizeText} from 'i18n/localize';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {LoadingIndicator} from 'components/LoadingIndicator';
import classNames from 'classnames';

export interface CategoryInputModalProps {
  categoryId?: number,
  initialParentId?: number,
  onCloseRequested?: () => any,
  onCompleted?: (category?: Category) => any,
}

export const CategoryInputModal = (props: CategoryInputModalProps) => {
  const logger = useLogger(CategoryInputModal.name);
  const {categoriesService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.categoryId !== undefined;

  const [initialCategory, setInitialCategory] = useState<CategoryWithParent | undefined>(undefined);
  const [isFetchingInitial, setIsFetchingInitial] = useState<boolean>(true);
  const [categoryUnderEdit, setCategoryUnderEdit] = useState<CreateCategoryDto>({
    name: '',
    icon: '',
    color: '',
    parent: undefined,
  });
  const [errors, setErrors] = useState<any>({});

  const fetchInitialCategory = async () => {
    if (props.categoryId) {
      setIsFetchingInitial(true);
      const fetchedCategory = await categoriesService.fetchCategory(props.categoryId);
      setInitialCategory(fetchedCategory);
      logger.debug('Fetched category', fetchedCategory);
      setIsFetchingInitial(false);
    }
  };

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (categoryUnderEdit.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (categoryUnderEdit.icon.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, icon: true}));
    }
    if (categoryUnderEdit.color.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, color: true}));
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      showErrorNotification('Error', localizeText('form.invalid'));
      return;
    }

    if (isEditing && props.categoryId) {
      const result = await categoriesService.updateCategory({
        id: props.categoryId,
        ...categoryUnderEdit,
      });
      logger.debug('Updated category', result);
      props.onCompleted?.(result.entity);
    } else {
      const result = await categoriesService.createCategory(categoryUnderEdit);
      logger.debug('Created category', result);
      props.onCompleted?.(result.entity);
    }
  };

  useEffect(() => {
    (async () => {
      if (props.categoryId) {
        await fetchInitialCategory();
      } else {
        setIsFetchingInitial(false);
        validate();
      }
    })();
  }, []);

  useEffect(() => {
    if (initialCategory) {
      setCategoryUnderEdit(initialCategory);
    }
  }, [initialCategory]);

  useEffect(() => {
    validate();
  }, [categoryUnderEdit]);

  return (
    <Modal
      title={isEditing ? 'Edit Category' : 'Add Category'}
      className={'wide tall'}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          htmlTitle={'Submit'}
          variant={ButtonVariant.Confirm}
          type={'submit'}
          form={'categoryForm'}
        />
      }

      body={
        <>
          {isFetchingInitial && <LoadingIndicator />}

          {!isFetchingInitial &&
            <form name="form.edit" id={'categoryForm'} onSubmit={submit}>
              <div className="row-fluid">
                <input
                  placeholder={__('Category Name')}
                  aria-label={__('Category Name')}
                  type="text"
                  value={categoryUnderEdit.name}
                  onChange={(e) => setCategoryUnderEdit((current) => ({
                    ...current,
                    name: e.target.value,
                  }))}
                  className={classNames('span12', {'invalid': errors.name})}
                />
              </div>
              <div className="row-fluid" style={{marginBottom: 12}}>
                <CategoryPicker
                  placeholder={__('Parent Category')}
                  selectedCategories={categoryUnderEdit.parent ? [categoryUnderEdit.parent] : []}
                  onChange={(newCategories) => {
                    if (newCategories.length > 0) {
                      setCategoryUnderEdit((current) => ({
                        ...current,
                        parent: newCategories[0],
                      }));
                    } else {
                      setCategoryUnderEdit((current) => ({
                        ...current,
                        parent: undefined,
                      }));
                    }
                  }}
                />
                <label htmlFor={'parent-category'} style={{display: 'none'}}>{__('Parent Category')}</label>
              </div>
              <div className="row-fluid">
                <div style={{float: 'left', marginRight: 10}}>
                  <IconPicker
                    iconCode={categoryUnderEdit.icon}
                    isInvalid={errors.icon}
                    onChange={(newIconCode) => {
                      setCategoryUnderEdit((current) => ({
                        ...current,
                        icon: newIconCode,
                      }));
                    }}
                  />
                </div>
                <div style={{float: 'left'}}>
                  <ColorPicker
                    color={categoryUnderEdit.color}
                    isInvalid={errors.color}
                    onChange={(newColor) => {
                      setCategoryUnderEdit((current) => ({
                        ...current,
                        color: newColor,
                      }));
                    }}
                  />
                </div>
              </div>
            </form>
          }
        </>
      }

      footer={isFetchingInitial ? <></> : <InputPlaceholderMirror/>}
    />
  );
};
