import {AxiosInstance} from 'axios';
import {CreateLessonDto, Lesson, LessonWithRelations, UpdateLessonDto} from 'api/lessons/Lesson';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {EntityListService} from 'api/lists/EntityListService';
import {ListFilter} from 'api/lists/ListFilter';

/**
 * Provides API lessons functions
 */
export class LessonsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches a filtered list of lessons
   *
   * @param {ListFilter} filter
   */
  async fetchLessons(filter: ListFilter) {
    const response = await this.httpClient.post(
        ApiRoute.Lessons + ApiRoute.List,
        this.entityListService.buildFilter(filter),
    );
    return response.data.list;
  }

  /**
   * Fetches a lesson from the API
   *
   * @param {number} lessonId
   * @return {Promise<LessonWithRelations>}
   */
  async fetchLesson(lessonId: number): Promise<LessonWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Lessons + ApiRoute.Get, {
      id: lessonId,
    });
    return response.data;
  }

  /**
   * Fetches multiple lessons by ID
   *
   * @param {number[]} lessonIds
   */
  async fetchMultipleLessons(lessonIds: number[]): Promise<LessonWithRelations[]> {
    const response = await this.httpClient.post(ApiRoute.Lessons + ApiRoute.Get, {
      id: lessonIds,
    });
    return response.data;
  }

  /**
   * Creates a new lesson
   *
   * @param {CreateLessonDto} lesson
   * @return {Promise<SuccessResponse>}
   */
  async createLesson(lesson: CreateLessonDto): Promise<SuccessResponse<Lesson>> {
    const response = await this.httpClient.post(
        ApiRoute.Lessons + ApiRoute.Save,
        lesson,
    );
    return response.data;
  }

  /**
   * Updates a lesson
   *
   * @param {UpdateLessonDto} lesson
   * @return {Promise<SuccessResponse>}
   */
  async updateLesson(lesson: UpdateLessonDto): Promise<SuccessResponse<Lesson>> {
    const response = await this.httpClient.post(
        ApiRoute.Lessons + ApiRoute.Save,
        lesson,
    );
    return response.data;
  }

  /**
   * Fetches lessons associated with a given skill
   *
   * @param {number} skillId
   */
  fetchLessonsForSkill(skillId: number): Promise<Lesson[]> {
    return this.fetchLessons({filters: {ilri: skillId}});
  }

  /**
   * Deletes a lesson
   *
   * @param {number} id
   */
  async deleteLesson(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.Lessons + ApiRoute.Delete, {id});
    return response.data;
  }
}
