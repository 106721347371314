import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {useLogger} from 'logging/logging';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {DataGrid} from 'components/DataGrid';
import {FormattedDate} from 'components/FormattedDate';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {ButtonEdit} from 'components/buttons/ButtonEdit';
import {ButtonDelete} from 'components/buttons/ButtonDelete';
import {confirmDelete} from 'components/confirmDelete';
import {Quiz} from 'api/quizzes/Quiz';
import {QuizDetailsModal} from 'views/quizzes/QuizDetailsModal';
import {AccessControlled} from 'access-control/AccessControlled';
import {useApiContext} from 'api/ApiContext';
import {QuizInputModal} from 'views/quizzes/QuizInputModal';
import {Button, ButtonVariant} from 'components/buttons/Button';

export const QuizzesListView = () => {
  const logger = useLogger(QuizzesListView.name);
  const {quizzesService} = useApiContext();

  const pageSize = 50;
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [listStart, setListStart] = useState<number>(0);
  const [listEnded, setListEnded] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [showDetailsQuizId, setShowDetailsQuizId] = useState<number | undefined>(undefined);
  const [editQuiz, setEditQuiz] = useState<Quiz | undefined>(undefined);

  const loadQuizzes = async () => {
    const fetchedQuizzes = await quizzesService.fetchQuizzes(
        searchFilter,
        listStart,
        listStart + pageSize,
    );
    if (fetchedQuizzes.length < 1) {
      setListEnded(true);
    }
    setQuizzes((current) => current.concat(fetchedQuizzes));
    logger.debug('Fetched quizzes', fetchedQuizzes);
  };

  const resetQuizzes = async () => {
    setQuizzes([]);
    setListStart(0);
    setListEnded(false);
    await loadQuizzes();
  };

  useEffect(() => {
    (async () => {
      if (!listEnded) {
        await loadQuizzes();
      }
    })();
  }, [listStart]);

  useEffect(() => {
    resetQuizzes();
  }, [searchFilter]);

  return (
    <PageView>
      {showDetailsQuizId &&
        <QuizDetailsModal
          quizId={showDetailsQuizId}
          onCloseRequested={() => setShowDetailsQuizId(undefined)}
        />
      }

      {editQuiz &&
        <QuizInputModal
          quizId={editQuiz.id}
          onCancel={() => setEditQuiz(undefined)}
          onComplete={async () => {
            await resetQuizzes();
            setEditQuiz(undefined);
          }}
        />
      }

      <ContextMenu>
        <SearchInput
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
        />
        <Button
          variant={ButtonVariant.Add}
          onClick={() => setEditQuiz({} as Quiz)}
        />
      </ContextMenu>

      <DataGrid
        onBottomReached={() =>
          setListStart((current) => current + pageSize)
        }
        header={
          <tr>
            <td>Name</td>
            <td>Campus</td>
            <td>Date</td>
            <td width={35}/>
          </tr>
        }
        body={
          <>
            {quizzes.map((quiz) => (
              <tr key={quiz.id}>
                <td>
                  <a onClick={() => setShowDetailsQuizId(quiz.id)}>
                    {quiz.name}
                  </a>
                </td>
                <td>{quiz.school?.name ?? 'System Resource'}</td>
                <td>
                  <FormattedDate timestamp={quiz.mdate ?? quiz.cdate}/>
                </td>
                <td>
                  <AccessControlled canEdit={quiz}>
                    <ButtonGroup>
                      <ButtonEdit
                        onClick={() => setEditQuiz(quiz)}
                        className={'btn-mini'}
                      />
                      <ButtonDelete
                        onClick={() => confirmDelete(async () => {
                          await quizzesService.deleteQuiz(quiz.id);
                          await resetQuizzes();
                        })}
                        className={'btn-mini'}
                      />
                    </ButtonGroup>
                  </AccessControlled>
                </td>
              </tr>
            ))}
          </>
        }
      />
    </PageView>
  );
};
