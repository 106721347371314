import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {SaveLessonPlanEntryDto} from 'api/lessons/LessonPlan';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useNotificationContext} from 'contexts/NotificationContext';
import classNames from 'classnames';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';

export interface LessonPlanEntryInputModalProps {
  initialEntry?: SaveLessonPlanEntryDto,
  onCloseRequested?: () => any,
  onComplete?: (entry: SaveLessonPlanEntryDto, addMore: boolean) => any,
}

export const LessonPlanEntryInputModal = (props: LessonPlanEntryInputModalProps) => {
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.initialEntry !== undefined;

  const [activity, setActivity] = useState<string>(props.initialEntry?.activity ?? '');
  const [description, setDescription] = useState<string>(props.initialEntry?.steps ?? '');
  const [time, setTime] = useState<string>(props.initialEntry?.time?.toString() ?? '');
  const [errors, setErrors] = useState<any>({});

  const submit = (addMore = false) => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    let timeInt: number | undefined = parseInt(time);
    if (isNaN(timeInt)) {
      timeInt = undefined;
    }

    props.onComplete?.({activity, steps: description, time: timeInt}, addMore);
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (activity.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, activity: true}));
    }
    if (description.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, description: true}));
    }

    return isValid;
  };

  useEffect(() => {
    validate();
  }, [activity, description]);

  return <Modal
    title={__(isEditing ? 'Edit Plan Entry' : 'Add Plan Entry')}
    onCloseRequested={props.onCloseRequested}

    buttons={<>
      <Button
        variant={ButtonVariant.Confirm}
        onClick={() => submit()}
      />
      {!isEditing &&
        <Button
          variant={ButtonVariant.Confirm}
          className={'btn-success'}
          text={__('Next')}
          onClick={() => submit(true)}
        />
      }
    </>}

    body={
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row-fluid">
          <div className="span8">
            <input
              placeholder={__('Category')}
              value={activity}
              aria-label={__('Category')}
              onChange={(e) => setActivity(e.target.value)}
              type="text"
              className={classNames('span12', {'invalid': errors.activity})}
            />
          </div>
          <div className="span4">
            <input
              placeholder={__('Time (min)')}
              value={time}
              aria-label={__('Time (min)')}
              onChange={(e) => setTime(e.target.value)}
              type="text"
              className="span12"
            />
          </div>
        </div>
        <div className="row-fluid">
          <RichTextInput
            placeholder={__('Description')}
            value={description}
            onChange={(newDescription) => setDescription(newDescription)}
            required={errors.description}
          />
        </div>
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
