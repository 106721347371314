/**
 * The default color options for a color picker
 */
export const colorPickerDefaultColors = [
  '#D5F5FE',
  '#D7E6FD',
  '#CDC8FC',
  '#E0C8FC',
  '#EED2E2',
  '#F4D8DA',
  '#F7DDD6',
  '#FAE8D5',
  '#FBECD5',
  '#FEF8DD',
  '#FAF8DC',
  '#E6EDD4',
  '#AEEBFD',
  '#B0CDFC',
  '#998BFA',
  '#C290FB',
  '#E0A2C5',
  '#EBAFB0',
  '#F1BBAB',
  '#F5CFA9',
  '#F7DAAA',
  '#FEF3BB',
  '#F7F3B9',
  '#DAE8B6',
  '#88E1FB',
  '#84B3FC',
  '#654FF9',
  '#A557F9',
  '#D270A6',
  '#E38587',
  '#EA977E',
  '#B77A7E',
  '#F4C87C',
  '#FDED99',
  '#F3EF94',
  '#C6DD8F',
  '#6CD5FA',
  '#5A98FA',
  '#4032E6',
  '#8E39EF',
  '#C73E87',
  '#DB5758',
  '#E5734C',
  '#EC9F4A',
  '#F1B64D',
  '#FDE775',
  '#EFE86F',
  '#B5D267',
  '#59AFD5',
  '#3778FA',
  '#3626AF',
  '#742DB8',
  '#9F3169',
  '#D73224',
  '#E35218',
  '#EA9225',
  '#EFAE2C',
  '#FEE855',
  '#EBE54C',
  '#9ABA4C',
  '#4C97B1',
  '#3169D1',
  '#271F90',
  '#5C259A',
  '#842959',
  '#BB1907',
  '#BE3D0F',
  '#C16F1C',
  '#C48922',
  '#F2D435',
  '#D0C837',
  '#829B40',
  '#3C768C',
  '#2653A5',
  '#201974',
  '#491D79',
  '#682047',
  '#951406',
  '#96300C',
  '#985916',
  '#9A6C1B',
  '#C0A92A',
  '#A49D2B',
  '#667932',
  '#2B5564',
  '#1C3D78',
  '#151252',
  '#351558',
  '#4B1833',
  '#6B0E04',
  '#6B2208',
  '#6E4010',
  '#6F4D13',
  '#8A781E',
  '#76721F',
  '#4A5824',
  '#203E4A',
  '#142B56',
  '#100D3D',
  '#250F3F',
  '#360F24',
  '#4D0903',
  '#4F1906',
  '#502C06',
  '#50380E',
  '#655716',
  '#565215',
  '#36401A',
  '#FFFFFF',
  '#F7F7F7',
  '#E4E4E4',
  '#CBCBCB',
  '#AFAFAF',
  '#909090',
  '#717171',
  '#525252',
  '#363636',
  '#1D1D1D',
  '#090909',
  '#000000',
];
