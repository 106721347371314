/**
 * Types of questions that can be in quizzes
 */
export enum QuizQuestionType {
  Instructions = 'INSTRUCTIONS',
  Match = 'MATCH',
  Bullets = 'BULLETS',
  Multiple = 'MULTIPLE',
  Open = 'OPEN',
  Order = 'ORDER',
}
