import React from 'react';
import dayjs from 'dayjs';

/**
 * Transforms a timestamp into a formatted date
 */

export interface FormattedDateProps {
  timestamp: number,
  format?: string,
}

export const FormattedDate = (props: FormattedDateProps) => {
  const format = props.format ?? 'MM/DD/YY';

  return (
    <>
      {dayjs(new Date(props.timestamp)).format(format)}
    </>
  );
};
