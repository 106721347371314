import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {QuizWithQuestions} from 'api/quizzes/Quiz';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {PrintModal} from 'components/PrintModal';
import {QuizPrintView} from 'views/quizzes/QuizPrintView';

export interface QuizDetailsModalProps {
  quizId: number,
  onCloseRequested?: () => any,
}

export const QuizDetailsModal = (props: QuizDetailsModalProps) => {
  const logger = useLogger(QuizDetailsModal.name);
  const {quizzesService} = useApiContext();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [quiz, setQuiz] = useState<QuizWithQuestions | undefined>(undefined);
  const [showPrintView, setShowPrintView] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const fetchedQuiz = await quizzesService.fetchQuiz(props.quizId);
      setQuiz(fetchedQuiz);
      logger.debug('Fetched quiz', fetchedQuiz);
      setIsFetching(false);
    })();
  }, []);

  if (isFetching) {
    return <></>;
  }

  return <Modal
    title={__('Quiz')}
    onCloseRequested={props.onCloseRequested}
    bodyClassName={'quiz-view'}

    buttons={
      <Button
        variant={ButtonVariant.Print}
        onClick={() => setShowPrintView(true)}
      />
    }

    body={
      <>
        {showPrintView &&
          <PrintModal
            view={<QuizPrintView quizId={props.quizId} />}
            onCloseRequested={() => setShowPrintView(false)}
          />
        }

        <h1>{quiz?.name}</h1>
        {quiz?.rated &&
          <div><span>{__('Reqired Score')}</span>: {quiz.required}%</div>
        }

        {quiz?.questions.map((question) => (
          <div key={question.id} className="question">
            <div className="heading clearfix html" dangerouslySetInnerHTML={{__html: question.text}} />

            {[
              QuizQuestionType.Bullets,
              QuizQuestionType.Multiple,
            ].includes(question.type) &&
              <div className="answers">
                {question.answers.map((answer) => (
                  <div key={answer.id} className="answer">
                    <div className="checkbox">
                      <i className="icon-check-empty"/>
                    </div>
                    <div
                      className="text html clearfix"
                      dangerouslySetInnerHTML={{__html: answer.text}}
                    />
                  </div>
                ))}
              </div>
            }

            {question.type === QuizQuestionType.Order &&
            <div className="answers">
              <table>
                {question.answers.map((answer, i) => (
                  <tr key={answer.id} className="answer order">
                    <td className="letter">
                      {i + 1}.
                    </td>
                    <td
                      className="text html clearfix"
                      dangerouslySetInnerHTML={{__html: answer.text}}
                    />
                  </tr>
                ))}
              </table>
            </div>
            }

            {question.type === QuizQuestionType.Open &&
            <div className="answers no-checkbox">
              <div className="open-answer"/>
            </div>
            }

            {question.type === QuizQuestionType.Match &&
            <div className="answers">
              <div className="answer match">
                <div className="row-fluid">
                  <div className="span6">
                    <table>
                      {question.answers.map((answer, i) => (
                        <tr
                          key={answer.id}
                          className="matched"
                          // className="answer"
                        >
                          <td className="letter">{i +1}.</td>
                          <td
                            className="text html clearfix"
                            dangerouslySetInnerHTML={{__html: answer.text}}
                          />
                        </tr>
                      ))}
                    </table>
                  </div>

                  <div className="span6">
                    <table>
                      {question.answers.map((answer, i) => (
                        <tr
                          key={answer.id}
                          className="options"
                          // className="answer"
                        >
                          <td className="letter">{i + 1}.</td>
                          <td
                            className="text html clearfix"
                            dangerouslySetInnerHTML={{__html: answer.match}}
                          />
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            }

            <div className="clearfix"/>
            <div className="break underline"/>
          </div>
        ))}
      </>
    }
  />;
};
