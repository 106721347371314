import {AxiosInstance} from 'axios';
import {SurveySummary, SurveyWithQuestions} from 'api/surveys/Survey';
import {EntityListService} from 'api/lists/EntityListService';
import {ListFilter} from 'api/lists/ListFilter';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {CreateQuizSolutionDto, QuizQuestionWithAnswer, QuizSolution} from 'api/quizzes/QuizSolution';
import {CreateQuizDto, UpdateQuizDto} from 'api/quizzes/Quiz';
import {SurveyResultsBuilder} from 'api/surveys/SurveyResultsBuilder';

/**
 * Provides API survey functions
 */
export class SurveysService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches surveys from the API
   *
   * @param {ListFilter} filter
   */
  async fetchSurveySummaries(filter: ListFilter = {}): Promise<SurveySummary[]> {
    const builtFilter = this.entityListService.buildFilter(filter);
    const response = await this.httpClient.post('/quizzes/list', {
      ...builtFilter,
      survey: true,
    });
    return response.data.list;
  }

  /**
   * Fetches a survey from the API
   *
   * @param {number} surveyId
   * @return {Survey}
   */
  async fetchSurvey(surveyId: number): Promise<SurveyWithQuestions> {
    const response = await this.httpClient.post('/quizzes/get', {
      id: surveyId,
    });
    return response.data;
  }

  /**
   * Creates a new survey in the API
   *
   * @param {CreateSurveyDto} survey
   * @return {{ok: boolean}}
   */
  async createSurvey(survey: CreateQuizDto): Promise<{ok: boolean}> {
    const response = await this.httpClient.post('/quizzes/save', {
      ...survey,
      survey: true,
      type: 'QUIZ',
    });
    return response.data;
  }

  /**
   * Updates a survey in the API
   *
   * @param {UpdateSurveyDto} survey
   * @return {{ok: boolean}}
   */
  async updateSurvey(survey: UpdateQuizDto): Promise<{ok: boolean}> {
    const response = await this.httpClient.post('/quizzes/save', {
      ...survey,
      survey: true,
    });
    return response.data;
  }

  /**
   * Deletes a survey in the API
   *
   * @param {number} surveyId
   * @return {{ok: boolean}}
   */
  async deleteSurvey(surveyId: number): Promise<{ok: boolean}> {
    const response = await this.httpClient.post('/quizzes/remove', {
      id: surveyId,
    });
    return response.data;
  }

  /**
   * Fetches survey results for the current user
   */
  async fetchSurveyResults(): Promise<QuizSolution[]> {
    const response = await this.httpClient.post('/quizzes/surveyResult', {});
    return response.data.list;
  }

  /**
   * Submits a survey solution
   */
  async submitSurveySolution(solution: CreateQuizSolutionDto): Promise<SuccessResponse<any>> {
    const response = await this.httpClient.post('/quizzes/survey', solution);
    return response.data;
  }

  /**
   * Gets a survey results builder
   *
   * @param {QuizQuestionWithAnswer[]} questions
   */
  getSurveyResultsBuilder(questions: QuizQuestionWithAnswer[]): SurveyResultsBuilder {
    return new SurveyResultsBuilder(questions);
  }
}
