import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {CreateSkillDto, Skill, SkillWithRelations} from 'api/skills/Skill';
import {__} from 'i18n/localize';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {ProductPicker} from 'components/forms/ProductPicker';
import {SkillPicker} from 'components/forms/SkillPicker';
import {useApiContext} from 'api/ApiContext';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useLogger} from 'logging/logging';
import {LoadingIndicator} from 'components/LoadingIndicator';
import classNames from 'classnames';
import {useNotificationContext} from 'contexts/NotificationContext';

export interface SkillInputModalProps {
  skillId?: number,
  onCancel?: () => any,
  onComplete?: (skill?: Skill) => any,
}

export const SkillInputModal = (props: SkillInputModalProps) => {
  const logger = useLogger(SkillInputModal.name);
  const {skillsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const isEditing = props.skillId !== undefined;

  const [initialSkill, setInitialSkill] = useState<SkillWithRelations | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newSkill, setNewSkill] = useState<CreateSkillDto>({
    name: '',
    keywords: '',
    category: undefined,
    products: [],
    relatedSkills: [],
  });
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    let isValid = true;
    setErrors({});

    if (newSkill.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (newSkill.category === undefined) {
      isValid = false;
      setErrors((current: any) => ({...current, category: true}));
    }

    return isValid;
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    setIsSubmitting(true);
    let saveResult;
    if (isEditing && props.skillId) {
      saveResult = await skillsService.updateSkill({
        ...newSkill,
        id: props.skillId,
      });
      logger.debug('Updated skill', saveResult);
    } else {
      saveResult = await skillsService.createSkill(newSkill);
      logger.debug('Created skill', saveResult);
    }

    setIsSubmitting(false);
    props.onComplete?.(saveResult.entity);
  };

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (props.skillId) {
        const fetchedSkill = await skillsService.fetchSkill(props.skillId);
        setInitialSkill(fetchedSkill);
        logger.debug('Fetched skill', fetchedSkill);
      } else {
        validate();
      }
      setIsFetching(false);
    })();
  }, []);

  useEffect(() => {
    if (initialSkill) {
      setNewSkill(initialSkill);
    }
  }, [initialSkill]);

  useEffect(() => {
    validate();
  }, [newSkill]);

  if (isFetching) {
    return <></>;
  }

  return (
    <Modal
      title={isEditing ? 'Edit Skill' : 'Add Skill'}
      onCloseRequested={props.onCancel}

      buttons={
        <>
          <Button
            variant={ButtonVariant.Confirm}
            onClick={submit}
            disabled={isSubmitting}
          />
        </>
      }

      body={
        <form name="form.edit" onSubmit={(e) => e.preventDefault()}>
          {isSubmitting &&
            <LoadingIndicator
              fullscreen={true}
              message={'Saving content'}
            />
          }

          <div className="row-fluid">
            <input
              value={newSkill.name}
              onChange={(e) => setNewSkill((current) => ({
                ...current,
                name: e.target.value,
              }))}
              type="text"
              className={classNames(
                  'span12',
                  {'invalid': errors.name},
              )}
              placeholder={__('Skill')}
              required
            />
          </div>
          <div className="row-fluid">
            <textarea
              value={newSkill.keywords}
              onChange={(e) => setNewSkill((current) => ({
                ...current,
                keywords: e.target.value,
              }))}
              className="span12"
              placeholder={__('Keywords')}
              rows={5}
            />
          </div>
          <CategoryPicker
            clearable={false}
            placeholder={'Category'}
            selectedCategories={initialSkill?.category ? [initialSkill.category] : []}
            isInvalid={newSkill.category === undefined}
            onChange={(newCategories) => setNewSkill((current) => ({
              ...current,
              category: newCategories.length > 0 ? newCategories[0] : undefined,
            }))}
          />

          <AccessControlled feature={AppFeature.EditSystemResources}>
            <div className="row-fluid">
              <ProductPicker
                multiple={true}
                placeholder={__('Products')}
                value={newSkill.products?.map((p) => p.id)}
                onChange={(newValue) => setNewSkill((current) => ({
                  ...current,
                  products: (newValue as number[])?.map((id) => ({id})),
                }))}
              />
            </div>
          </AccessControlled>

          <div className="row-fluid">
            <SkillPicker
              multiple={true}
              placeholder={__('Related Skills')}
              value={newSkill.relatedSkills?.map((s) => s.id)}
              onChange={(newValue) => setNewSkill((current) => ({
                ...current,
                relatedSkills: (newValue as number[])?.map((id) => ({id})),
              }))}
            />
          </div>
        </form>
      }
      footer={
        <InputPlaceholderMirror/>
      }
    />
  );
};
