import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Syllabus} from 'api/syllabi/Syllabus';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useNotificationContext} from 'contexts/NotificationContext';
import classNames from 'classnames';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';

export interface SyllabusInputModalProps {
  initialSyllabus?: Syllabus,
  onCloseRequested?: () => any,
  onCompleted?: (syllabus?: Syllabus) => any,
}

export const SyllabusInputModal = (props: SyllabusInputModalProps) => {
  const logger = useLogger(SyllabusInputModal.name);
  const {showErrorNotification} = useNotificationContext();
  const {syllabiService} = useApiContext();
  const isEditing = props.initialSyllabus?.id !== undefined;

  const [name, setName] = useState<string>(props.initialSyllabus?.name ?? '');
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    let isValid = true;
    setErrors({});
    if (name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      showErrorNotification('Error', __('form.invalid'));
      return;
    }

    let result;
    if (isEditing && props.initialSyllabus?.id) {
      result = await syllabiService.updateSyllabus({...props.initialSyllabus, name});
      logger.debug('Updated syllabus', result);
    } else {
      result = await syllabiService.createSyllabus({name});
      logger.debug('Created syllabus', result);
    }

    props.onCompleted?.(result.entity);
  };

  useEffect(() => {
    validate();
  }, [name]);

  return <Modal
    title={__(isEditing ? 'Edit Syllabus' : 'Add Syllabus')}
    onCloseRequested={props.onCloseRequested}
    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        type={'submit'}
        form={'syllabusForm'}
      />
    }
    body={
      <form id={'syllabusForm'} onSubmit={submit} style={{minHeight: 300}}>
        <label className={'row-fluid'}>
          <input
            placeholder={__('Syllabus Name')}
            type={'text'}
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classNames('span12', {'invalid': errors.name})}
          />
          <span style={{display: 'none'}}>{__('Syllabus Name')}</span>
        </label>
      </form>
    }
    footer={<InputPlaceholderMirror/>}
  />;
};
