import {AxiosInstance} from 'axios';
import {CreateSkillDto, Skill, SkillWithRelations, UpdateSkillDto} from 'api/skills/Skill';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {Lesson} from 'api/lessons/Lesson';
import {CategoryWithParent} from 'api/categories/Category';
import {GoalStatus} from 'api/goals/GoalStatus';
import {SkillGoal} from 'api/skills/SkillGoal';
import {SkillInventory} from 'api/skills/SkillInventory';
import {UserSkillRecord} from 'api/skills/UserSkillRecord';

/**
 * Provides API skills functions
 */
export class SkillsService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Fetches skills from the API
   *
   * @param {{}} filter
   * @return {Skill[]}
   */
  async fetchSkills(
      filter: {
      start?: number,
      end?: number,
      search?: string,
      categoryId?: number,
    } = {},
  ): Promise<SkillWithRelations[]> {
    const response = await this.httpClient.post(ApiRoute.SkillsList, {
      start: filter.start,
      limit: filter.end,
      filter: {
        search: filter.search,
        category: filter.categoryId,
      },
    });
    return response.data.list;
  }

  /**
   * Fetches a single skill from the API
   *
   * @param {number} id
   * @return {SkillWithRelations}
   */
  async fetchSkill(id: number): Promise<SkillWithRelations> {
    const response = await this.httpClient.post(ApiRoute.Skills + ApiRoute.Get, {id});
    return response.data;
  }

  /**
   * Fetches skills for a given user
   *
   * @param {number} userId
   */
  async fetchUserSkills(userId: number): Promise<{ilri: UserSkillRecord[], lessons: Lesson[]}> {
    const response = await this.httpClient.post(ApiRoute.UserSkills, {user: userId});
    return response.data;
  }

  /**
   * Fetches a given user's skill goals from the API
   *
   * @param {number} userId
   */
  async fetchUserSkillGoals(userId: number): Promise<SkillGoal[]> {
    const response = await this.httpClient.post(ApiRoute.UserSkillGoals, {
      user: userId,
    });
    return response.data.ilri;
  }

  /**
   * Creates a new skill in the API
   *
   * @param {CreateSkillDto} skill
   * @return {Promise<SuccessResponse>}
   */
  async createSkill(skill: CreateSkillDto): Promise<SuccessResponse<Skill>> {
    const response = await this.httpClient.post(ApiRoute.SkillsSave, skill);
    return response.data;
  }

  /**
   * Updates a skill in the API
   *
   * @param {UpdateSkillDto} skill
   * @return {Promise<SuccessResponse>}
   */
  async updateSkill(skill: UpdateSkillDto): Promise<SuccessResponse<Skill>> {
    const response = await this.httpClient.post(ApiRoute.SkillsSave, skill);
    return response.data;
  }

  /**
   * Deletes a skill in the API
   *
   * @param {number} skillId
   * @return {Promise<SuccessResponse>}
   */
  async deleteSkill(skillId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post(ApiRoute.SkillsDelete, {
      id: skillId,
    });
    return response.data;
  }

  /**
   * Fetches a user's skill inventory
   *
   * @param {number} userId
   * @param {boolean} sortByName
   */
  async fetchUserSkillInventory(userId: number, sortByName = false): Promise<{inventories: SkillInventory[]}> {
    const allSkills = await this.fetchSkills();

    const getTopLevelAncestorCategory = (category: CategoryWithParent) => {
      let currentCategory = category;
      while (currentCategory.parent) {
        currentCategory = currentCategory.parent;
      }
      return currentCategory;
    };

    const allCategories: CategoryWithParent[] = [];
    allSkills.forEach((skill) => {
      if (skill.category && !allCategories.find((category) => category.id === skill.category?.id)) {
        allCategories.push(skill.category);
      }
    });
    const topLevelCategories: CategoryWithParent[] = [];
    allCategories.forEach((category) => {
      const topLevelAncestor = getTopLevelAncestorCategory(category);
      const categoryAlreadyAdded = topLevelCategories.find((category) => category.id === topLevelAncestor.id);
      if (!categoryAlreadyAdded) {
        topLevelCategories.push(topLevelAncestor);
      }
    });

    const categoriesWithSkills = allCategories.map((category) => ({
      category,
      skills: allSkills.filter((skill) => skill.category?.id === category.id),
    }));

    const sortValueForGoalStatus = (skillStatus: GoalStatus | undefined): number => {
      switch (skillStatus) {
        case GoalStatus.Completed: return 0;
        case GoalStatus.InProgress: return 1;
        case GoalStatus.Recommended: return 2;
        case GoalStatus.NonApplicable: return 3;
        default: return 99;
      }
    };

    const userSkillGoals = await this.fetchUserSkillGoals(userId);

    const stats = topLevelCategories.map((category) => {
      const topLevelCategory = getTopLevelAncestorCategory(category);
      const skillsInCategory = allSkills.filter(
          (s) => getTopLevelAncestorCategory(s.category!).id === topLevelCategory.id,
      );
      const completedSkillsInCategory = userSkillGoals.filter(
          (userGoal) => getTopLevelAncestorCategory(userGoal.ilri.category).id === topLevelCategory.id &&
            userGoal.state === GoalStatus.Completed,
      );

      const completedCount = completedSkillsInCategory.length;
      const percentComplete = completedCount > 0 ?
        Math.round((completedCount / skillsInCategory.length) * 100) :
        0;

      return {category, completedCount, percentComplete};
    });

    return {
      inventories: topLevelCategories.map((topLevelCategory) => ({
        topLevelCategory,
        stats: stats.find((stat) => stat.category.id === topLevelCategory.id) ??
          {completedCount: 0, percentComplete: 0},
        subCategories: categoriesWithSkills
            .filter((c) => getTopLevelAncestorCategory(c.category).id === topLevelCategory.id)
            .map((category) => ({
              key: `${topLevelCategory.name} / ${category.category.name}`,
              category: category.category,
              skillsWithGoals: category.skills.map((skill) => ({
                skill,
                skillGoal: userSkillGoals.find((skillGoal) => skillGoal.ilri.id === skill.id),
              }))
                  .sort( (a, b) =>
                    sortByName ?
                      (a.skill.name < b.skill.name ? -1 : 1) :
                      sortValueForGoalStatus(a.skillGoal?.state) < sortValueForGoalStatus(b.skillGoal?.state) ? -1 : 1,
                  ),
            })),
      })),
    };
  }

  /**
   * Replaces target phrases with alternatives in a given skill title
   *
   * @param {string} title
   * @return {string}
   */
  transformSkillTitle(title: string): string {
    return title
        .replace('I know', 'I want to learn')
        .replace('I use', 'I want to learn to use')
        .replace('I can', 'I want to learn to')
        .replace('I understand', 'I want to understand')
        .replace('I write', 'I want to write');
  }
}
