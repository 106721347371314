import React, {useEffect, useState} from 'react';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {CourseQuickView} from 'views/courses/CourseQuickView';
import {Course} from 'api/courses/Course';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {ContextMenu} from 'components/ContextMenu';
import {CourseInputModal} from 'views/courses/CourseInputModal';
import {AccessControlled} from 'access-control/AccessControlled';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {AppFeature} from 'access-control/AppFeature';
import {SyllabiList} from 'views/syllabi/SyllabiList';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {Goal} from 'api/goals/Goal';
import {SearchInput} from 'components/SearchInput';
import {__} from 'i18n/localize';
import {Category} from 'api/categories/Category';
import {GoalTargetType} from 'api/goals/GoalTargetType';
import {Instructor} from 'api/users/Instructor';
import {confirmDelete} from 'components/confirmDelete';
import {useAccessControlContext} from 'access-control/AccessControlContext';
import {useAuthContext} from 'contexts/AuthContext';

export interface CoursesListViewProps {
  forTeacher?: boolean,
}

export const CoursesListView = (props: CoursesListViewProps) => {
  const logger = useLogger(CourseQuickView.name);
  const {coursesService, categoriesService, goalsService, usersService} = useApiContext();
  const {accessControlService} = useAccessControlContext();
  const {currentUser} = useAuthContext();

  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editCourse, setEditCourse] = useState<Course | undefined>(undefined);
  const [createNewCourse, setCreateNewCourse] = useState<boolean>(false);
  const [addedGoals, setAddedGoals] = useState<{syllabusId?: number, courseId?: number, goal: Goal}[]>([]);
  const [assignedGoals, setAssignedGoals] = useState<{courseId: number, goal: Goal}[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [search, setSearch] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [filterActive, setFilterActive] = useState<string>('true');
  const [allInstructors, setAllInstructors] = useState<Instructor[]>([]);
  const [filteredInstructorId, setFilteredInstructorId] = useState<number | undefined>(undefined);

  const loadCourses = async () => {
    const fetchedCourses = props.forTeacher ?
      await coursesService.fetchTaughtCourses({search, filters: {category, active: filterActive}}) :
      await coursesService.fetchCourses({
        search,
        filters: {category, active: filterActive, teacher: filteredInstructorId},
      });
    setCourses(fetchedCourses);
    logger.debug('Fetched courses', fetchedCourses);
    setIsLoading(false);
  };

  const loadInstructors = async () => {
    if (!currentUser || !accessControlService.userCanAccess(currentUser, AppFeature.ManageCourse)) {
      return;
    }

    const fetchedInstructors = await usersService.fetchInstructors({filters: {role: 3}});
    setAllInstructors(fetchedInstructors);
    logger.debug('Fetched instructors', fetchedInstructors);
  };

  const loadCategories = async () => {
    const result = await categoriesService.fetchCategories();
    setCategories(result);
  };

  const onGoalDragEnd = async (result: DropResult) => {
    const goalId = parseInt(result.draggableId);
    const targetTypeStr = result.destination?.droppableId.split('-')[0];
    const targetIdStr = result.destination?.droppableId.split('-')[1];
    const targetId = targetIdStr ? parseInt(targetIdStr) : undefined;
    const sourceType = result.source.droppableId.split('-')[0];

    if (isNaN(goalId)) {
      logger.error('Goal dragged with invalid ID');
      return;
    }
    if (targetTypeStr === undefined || sourceType === undefined) {
      logger.error('Goal dragged to/from invalid droppable area');
      return;
    }
    if (targetId === undefined || isNaN(targetId)) {
      logger.error('Goal dragged to invalid droppable area');
      return;
    }

    if (result.destination?.droppableId === result.source.droppableId) {
      logger.debug('Skipping goal dropped in same area');
      return;
    }

    let targetType: GoalTargetType | undefined;
    if (targetTypeStr === 'syllabus') {
      targetType = GoalTargetType.Syllabus;
    } else if (targetTypeStr === 'course') {
      targetType = GoalTargetType.Course;
    } else {
      targetType = undefined;
    }

    if (targetType === undefined) {
      logger.error('Invalid goal target type');
      return;
    }

    const isGoalAssign = targetType === GoalTargetType.Course && sourceType === 'course' &&
      result.destination?.droppableId.split('-')[2] === 'assigned' &&
      result.source?.droppableId.split('-')[2] === 'unassigned';

    if (!isGoalAssign) {
      const copyResult = await goalsService.copyGoal(goalId, targetId, targetType);
      logger.debug('Goal copied', copyResult);
      setAddedGoals((current) => {
        const copy = [...current];
        copy.push({
          goal: copyResult,
          syllabusId: targetType === GoalTargetType.Syllabus ? targetId : undefined,
          courseId: targetType === GoalTargetType.Course ? targetId : undefined,
        });
        return copy;
      });
    } else {
      const assignResult = await goalsService.assignGoal(goalId);
      logger.debug('Assigned goal', assignResult);
      setAssignedGoals((current) => {
        const copy = [...current];
        copy.push({
          courseId: targetId,
          goal: assignResult,
        });
        return copy;
      });
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        loadCourses(),
        loadCategories(),
        loadInstructors(),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadCourses();
    })();
  }, [search, category, filterActive, filteredInstructorId]);

  if (isLoading) {
    return <></>;
  }

  return (
    <DragDropContext onDragEnd={onGoalDragEnd}>
      <SidePanelPageView
        containerClassName={'column-layout'}
        firstColumnClassName={'left-column'}
        startPanelClosed={true}

        panelView={
          <SyllabiList
            addedGoals={addedGoals.filter((g) => g.syllabusId !== undefined).map((g) => ({
              syllabusId: g.syllabusId!,
              goal: g.goal,
            }))}
          />
        }

        mainView={
          <div
            className={'pane'}
            style={{paddingTop: 40, background: 'url(/static/media/content-bg.7963d1e7.gif) repeat'}}
          >
            <div
              className={'body'}
              style={{marginTop: 40}}
            >
              {(editCourse || createNewCourse) &&
                <CourseInputModal
                  initialCourse={editCourse}
                  onCloseRequested={() => {
                    setCreateNewCourse(false);
                    setEditCourse(undefined);
                  }}
                  onComplete={async () => {
                    await loadCourses();
                    setCreateNewCourse(false);
                    setEditCourse(undefined);
                  }}
                />
              }

              <ContextMenu>
                <SearchInput value={search} onChange={(newSearch) => setSearch(newSearch)} />

                <AccessControlled feature={AppFeature.ManageCourse}>
                  <i className="icon filter-icon icon-user" />
                  <select
                    value={filteredInstructorId}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      setFilteredInstructorId(isNaN(newValue) ? undefined : newValue);
                    }}
                    className="form-control"
                  >
                    <option value="">- {__('Instructor')} -</option>
                    {allInstructors.map((instructor) => (
                      <option key={instructor.id} value={instructor.id}>{instructor.name}</option>
                    ))}
                  </select>
                </AccessControlled>

                <i className="icon icon-tag" />
                <select
                  className="form-control"
                  aria-label="Filter by Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" aria-label={__('Category')}>- {__('Category')} -</option>
                  {categories.map((category) => (
                    <option
                      key={category.id}
                      value={category.id}
                      dangerouslySetInnerHTML={
                        {__html: Array(category.level).join('&nbsp;&nbsp;&nbsp;&nbsp;') + category.name}
                      }
                    />
                  ))}
                </select>

                <i className="icon filter-icon icon-off" />
                <select
                  value={filterActive}
                  aria-label="Filter by Active"
                  onChange={(e) => setFilterActive(e.target.value)}
                >
                  <option value="">- {__('Is Active')} -</option>
                  <option value="true">{__('Active')}</option>
                  <option value="false">{__('Not Active')}</option>
                </select>

                <AccessControlled feature={AppFeature.ManageCourse}>
                  <ButtonAdd onClick={() => setCreateNewCourse(true)} />
                </AccessControlled>
              </ContextMenu>

              {courses.map((course) => (
                <CourseQuickView
                  key={course.id}
                  course={course}
                  onUpdated={async () => await loadCourses()}
                  onEditRequested={() => setEditCourse(course)}
                  onDeleteRequested={() => confirmDelete(async () => {
                    await coursesService.deleteCourse(course.id);
                    await loadCourses();
                  })}
                  addedGoals={addedGoals.filter((g) => g.courseId === course.id).map((g) => g.goal)}
                  newlyAssignedGoals={
                    assignedGoals.filter((g) => g.courseId === course.id)
                        .map((g) => g.goal)
                  }
                />
              ))}
            </div>
          </div>
        }
      />
    </DragDropContext>
  );
};
