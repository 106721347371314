import React from 'react';
import {LessonPlan} from 'api/lessons/LessonPlan';

export interface LessonPlanPrintViewProps {
  lessonPlan: LessonPlan;
}

export const LessonPlanPrintView = (props: LessonPlanPrintViewProps) => {
  console.log(props.lessonPlan);

  return (
    <>
      <div className="holder">
        <h1 className="underline">
          {props.lessonPlan.title}
          {' '}
          {props.lessonPlan.time && props.lessonPlan.time > 0 && <span>({props.lessonPlan.time} min)</span>}
        </h1>

        {props.lessonPlan.entries.map((entry) => (
          <div key={entry.id} className="entry">
            <h2>
              {entry.activity}
              {' '}
              {entry.time !== undefined && entry.time > 0 && <span>({entry.time} min)</span>}
            </h2>
            <p className="html" dangerouslySetInnerHTML={{__html: entry.steps}} />
          </div>
        ))}
      </div>
    </>
  );
};
