import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Modal} from 'components/Modal';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {SkillCategoryWithStatus} from 'views/skills/SkillInventorySurvey';
import {Skill} from 'api/skills/Skill';
import {confirmModal} from 'components/confirmModal';
import {__} from 'i18n/localize';
import {SkillStatus} from 'api/skills/SkillStatus.enum';

export interface PickFirstSkillViewProps {
  skillInventoryResults: SkillCategoryWithStatus[],
  onComplete?: (skill: Skill) => any,
}

export const PickFirstSkillView = (props: PickFirstSkillViewProps) => {
  const skillsList: Skill[] = useMemo<Skill[]>(() => {
    let skills: Skill[] = [];
    props.skillInventoryResults.forEach((group) => {
      const incompleteGroupSkills = group.skills
          .filter((s) => s.status !== SkillStatus.Completed)
          .map((s) => s.skill);
      skills = skills.concat(incompleteGroupSkills);
    });
    return skills.slice(0, 5);
  }, [props.skillInventoryResults]);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const initialize = async () => {
    setIsInitializing(true);
    setIsInitializing(false);
  };

  const confirmSkill = async (skill: Skill) => {
    await confirmModal(
        () => props.onComplete?.(skill),
        __('Continue'),
        __(`${skill.name.replace('I know', 'Do you want to learn')}?`),
    );
  };

  useEffect(() => {
    (async () => await initialize())();
  }, []);

  return (
    <Modal
      className={'learning'}
      hideHeader={true}
      bodyClassName={'body viewer analytics'}

      body={
        <Fragment>
          <div className="inventory" style={{margin: '-10px -10px 0 -10px'}}>
            <div className="dreams_survey">
              <div className="headline" style={{paddingRight: 30}}>
                <h1>Pick a Skill to Learn</h1>
              </div>
              <iframe
                style={{width: 640, height: 300, margin: '0 auto'}}
                src="https://player.vimeo.com/video/424880651?title=0&byline=0&portrait=0"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
              <div className="headline" style={{paddingRight: 30, marginTop: 20}}>
                <p>Select a skill for your first goal.</p>
              </div>
            </div>
          </div>

          {isInitializing && <LoadingIndicator/>}

          {!isInitializing &&
            <div className="skills">
              {skillsList.map((skill) => (
                <div
                  key={skill.id}
                  className="btn-info"
                  onClick={() => confirmSkill(skill)}
                  style={{whiteSpace: 'inherit', cursor: 'pointer'}}
                >
                  {skill.name.replace('I know', 'I want to learn')}
                </div>
              ))}
            </div>
          }
        </Fragment>
      }
    />
  );
};
