import ReactGA from 'react-ga';
import {AnalyticsEvent} from 'analytics/AnalyticsEvent.enum';

/**
 * Provides analytics functions with Google Analytics
 */
export class AnalyticsService {
  protected static defaultInstance: AnalyticsService;
  protected readonly trackingId: string;
  protected isInitialized = false;

  /**
   * Gets an analytics service with default config.
   */
  static default() {
    if (!this.defaultInstance) {
      this.defaultInstance = new AnalyticsService(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? '');
    }
    return this.defaultInstance;
  }

  /**
   * Constructs a new analytics service.
   */
  constructor(trackingId: string) {
    this.trackingId = trackingId;
  }

  /**
   * Initializes analytics.
   */
  initialize() {
    if (this.isInitialized || this.trackingId.trim().length < 1) {
      return;
    }

    const useDebugMode = (process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE === 'true') ?? false;
    ReactGA.initialize(this.trackingId, {debug: useDebugMode});
    this.isInitialized = true;
  }

  /**
   * Records a page view.
   */
  recordPageView(path: string) {
    if (this.isInitialized) {
      ReactGA.pageview(path);
    }
  }

  /**
   * Records an analytics event.
   */
  recordEvent(eventType: AnalyticsEvent, label: string, category: string) {
    if (this.isInitialized) {
      ReactGA.event({
        action: eventType,
        label,
        category,
      });
    }
  }
}
