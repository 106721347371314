import {AppFeature} from 'access-control/AppFeature';
import {AclEntry, AclEntryType} from './AclEntry';

/**
 * Default access control configuration
 */
export const DefaultAccessControlList: AclEntry[] = [
  // sysadmin access
  {type: AclEntryType.Allow, feature: AppFeature.CreateSysAdmin, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.CreateUser, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.CreateCourse, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.AddAdvancedResource, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.CreateSchool, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.ChangeSchool, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.SchoolSurveys, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.CreateProduct, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.CreateLesson, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.LessonFiles, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.EditLesson, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.EditAllGoals, role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goal.template.edit', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.change.rated', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'edit.system.resources', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'school.manage', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.activity', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.classes', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.students', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'course.goal.edit', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'course.manage', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.sysadmin', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.admin', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.studying', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'student.toggleSkillInventory', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'ownership.change', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'instructor.properties', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.skills.add', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'SYSADMIN'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'SYSADMIN'},

  // editor access
  {type: AclEntryType.Allow, feature: 'goal.template.edit', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: AppFeature.DashboardEditor, role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'goals.change.rated', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'product.create', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'edit.system.resources', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'lesson.create', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'lesson.files', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'lesson.editor', role: 'EDITOR'},
  {type: AclEntryType.Allow, feature: 'dashboard.skills.add', role: 'EDITOR'},

  // admin access
  {type: AclEntryType.Allow, feature: 'school.manage', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'lesson.files', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'add.advanced.resources', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'school.change', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'school.surveys', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.admin', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.student', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.activity', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.classes', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'teacher.students', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'course.goal.edit', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'course.manage', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'add.sessionReport', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.instructors', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.teaching', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.students.teaching', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.grade', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.studying', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'student.toggleSkillInventory', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.edit.all', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'goals.change.rated', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'users.create', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'instructor.properties', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.skills.add', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'ADMIN'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'ADMIN'},

  // teacher access
  {type: AclEntryType.Allow, feature: 'dashboard.lessons', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.stats', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.teacher', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'school.change', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.student', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'teacher.activity', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'teacher.classes', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'teacher.students', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'course.goal.edit', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'add.sessionReport', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'school.quizzes', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'school.ilri', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.teaching', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.students.teaching', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.studying', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.notifications', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.grade', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'student.toggleSkillInventory', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.edit.all', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'goals.change.rated', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'add.advanced.resources', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.skills.add', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'lesson.files', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'TEACHER'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'TEACHER'},

  // instructor assistant access
  {type: AclEntryType.Allow, feature: 'dashboard.lessons', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'dashboard.stats', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'dashboard.teacher', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'dashboard.student', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'teacher.activity', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'teacher.classes', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'teacher.students', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'course.goal.edit', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'add.sessionReport', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: AppFeature.MakeAssessment,
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.activity',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'school.quizzes', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'school.ilri', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'edit.school.content',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.teaching',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.students.teaching',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'dashboard.goals', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.studying',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.learning',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'student.challenges',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'student.notifications',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'student.toggleSkillInventory',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'goals.edit.all', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'goals.change.rated',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'edit.school.content',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'add.advanced.resources',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.goals.edit',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.skills.add',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'INSTRUCTOR_ASSISTANT'},
  {
    type: AclEntryType.Allow,
    feature: 'dashboard.goals.edit',
    role: 'INSTRUCTOR_ASSISTANT',
  },
  {type: AclEntryType.Allow, feature: 'lesson.files', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'INSTRUCTOR_ASSISTANT'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'INSTRUCTOR_ASSISTANT'},

  // kol manager access
  {type: AclEntryType.Allow, feature: 'dashboard.lessons', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.stats', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.teacher', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.student', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'teacher.activity', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'teacher.classes', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'teacher.students', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'course.goal.edit', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'course.manage', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'add.sessionReport', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'school.quizzes', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'school.ilri', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.teaching', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.instructors', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.students.teaching', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.studying', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'student.notifications', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.edit.all', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.add.lesson', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.add.quiz', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.step.time', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.step.skill', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'goals.change.rated', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'edit.school.content', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'instructor.properties', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'add.advanced.resources', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.skills.add', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'add.advanced.resources', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'lesson.files', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'KOL_MANAGER'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'KOL_MANAGER'},

  // student access
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.journal', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.studying', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.collages', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.dreams', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'step.autocomplete', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'STUDENT'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'STUDENT'},

  // il student access
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.journal', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.studying', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.learning', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.collages', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.plan', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'step.autocomplete', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.goals.edit', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.network', role: 'IL_STUDENT'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'IL_STUDENT'},

  // parent access
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'dashboard.students.list', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'student.addGoal', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'student.notifications', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'community.edit', role: 'PARENT'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'PARENT'},

  // support role access
  {type: AclEntryType.Allow, feature: 'dashboard.access', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'dashboard.activity', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'dashboard.students.list', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'student.dashboard', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'student.files', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'student.challenges', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'student.notifications', role: 'SUPPORT'},
  {type: AclEntryType.Allow, feature: 'plan.overview', role: 'SUPPORT'},

  // ilri access
  {type: AclEntryType.Allow, feature: 'plan.ilri', role: 'ILRI'},
  {type: AclEntryType.Allow, feature: AppFeature.MakeAssessment, role: 'ILRI'},
  {type: AclEntryType.Allow, feature: 'learning.complete', role: 'ILRI'},
];
