import React, {useEffect, useState} from 'react';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {AchievementThumbnail} from 'views/achievements/AchievementThumbnail';
import {SkillInventoryInputModal} from 'views/plan/SkillInventoryInputModal';
import {useLogger} from 'logging/logging';
import {UserAchievementRecord} from 'api/achievements/UserAchievementRecord';
import {AchievementRecordDetailsModal} from 'views/achievements/AchievementRecordDetailsModal';

export interface ParticipantBadgesQuickViewProps {
  userId: number,
}

export const ParticipantBadgesQuickView = (props: ParticipantBadgesQuickViewProps) => {
  const logger = useLogger(ParticipantBadgesQuickView.name);
  const {achievementsService} = useApiContext();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [achievementRecords, setAchievementRecords] = useState<UserAchievementRecord[]>([]);
  const [showSkillInventoryInput, setShowSkillInventoryInput] = useState<boolean>(false);
  const [shownAchievement, setShownAchievement] = useState<UserAchievementRecord | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const fetchedAchievements = await achievementsService.fetchUserAchievements(props.userId);
      logger.debug('Fetched user achievements', fetchedAchievements);
      setAchievementRecords(fetchedAchievements);
      setIsFetching(false);
    })();
  }, []);

  return (
    <div className="pack">
      {shownAchievement &&
        <AchievementRecordDetailsModal
          achievementRecordId={shownAchievement.id}
          onCloseRequested={() => setShownAchievement(undefined)}
        />
      }

      {showSkillInventoryInput &&
        <SkillInventoryInputModal
          onCancel={() => setShowSkillInventoryInput(false)}
        />
      }

      <div className="name">Badges</div>

      <div className="body clearfix">
        {isFetching &&
          <LoadingIndicator />
        }

        {!isFetching && <>
          <div className="badgeSection">
            <div className="title">{__('Earned')}</div>
            {achievementRecords.filter((a) => (a.progress ?? 0) >= 100).map((achievementRecord) => (
              <AchievementThumbnail
                key={achievementRecord.id}
                achievement={achievementRecord.achievement}
                onOpenRequested={() => setShownAchievement(achievementRecord)}
              />
            ))}
          </div>
          <div className="badgeSection">
            <div className="title">{__('In Progress')}</div>
            {achievementRecords.filter((a) => (a.progress ?? 0) < 100).map((achievementRecord) => (
              <AchievementThumbnail
                key={achievementRecord.id}
                achievement={achievementRecord.achievement}
                onOpenRequested={() => setShownAchievement(achievementRecord)}
              />
            ))}
          </div>
        </>}
      </div>
    </div>
  );
};
