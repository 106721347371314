import React, {useEffect, useState} from 'react';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {Lesson, LessonWithRelations} from 'api/lessons/Lesson';
import {ButtonGroup} from 'components/buttons/ButtonGroup';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {AccessControlled} from 'access-control/AccessControlled';
import {UserRoleType} from 'api/users/UserRole';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {SkillDetailsModal} from 'views/skills/SkillDetailsModal';
import {Skill} from 'api/skills/Skill';
import {AssignLessonGoalModal} from 'views/lessons/AssignLessonGoalModal';
import {PrintModal} from 'components/PrintModal';
import {PrintLessonView} from 'views/lessons/print/PrintLessonView';
import {ActionStepList} from 'views/action-steps/ActionStepList';
import {LessonPlan} from 'api/lessons/LessonPlan';
import {LessonPlanPrintView} from 'views/lessons/print/LessonPlanPrintView';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {FileView} from 'views/resources/FileView';

export interface LessonDetailsViewProps {
  lessonId: number,
  onEditRequested?: () => any,
  onDeleteRequested?: () => any,
}

export const LessonDetailsView = (props: LessonDetailsViewProps) => {
  const logger = useLogger(LessonDetailsView.name);
  const {lessonsService} = useApiContext();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [lesson, setLesson] = useState<LessonWithRelations | undefined>(undefined);
  const [showDetailsSkill, setShowDetailsSkill] = useState<Skill | undefined>(undefined);
  const [hideMaterials, setHideMaterials] = useState<boolean>(false);
  const [hidePreparation, setHidePreparation] = useState<boolean>(false);
  const [hidePlans, setHidePlans] = useState<boolean>(false);
  const [hideWords, setHideWords] = useState<boolean>(false);
  const [hideFiles, setHideFiles] = useState<boolean>(false);
  const [showAssignLesson, setShowAssignLesson] = useState<Lesson | undefined>(undefined);
  const [showPrintLesson, setShowPrintLesson] = useState<LessonWithRelations | undefined>(undefined);
  const [printLessonPlan, setPrintLessonPlan] = useState<LessonPlan | undefined>(undefined);
  const [shownFile, setShownFile] = useState<FileUpload | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (props.lessonId) {
        const fetchedLesson = await lessonsService.fetchLesson(props.lessonId);
        setLesson(fetchedLesson);
        logger.debug('Fetched lesson', fetchedLesson);
      }
      setIsFetching(false);
    })();
  }, [props.lessonId]);

  if (isFetching) {
    return <></>;
  }

  if (!isFetching && lesson === undefined) {
    return <p>Failed to load lesson.</p>;
  }

  return <>
    {shownFile &&
      <FileView
        name={shownFile.name}
        url={shownFile.url}
        onCloseRequested={() => setShownFile(undefined)}
      />
    }

    {showDetailsSkill &&
      <SkillDetailsModal
        skill={showDetailsSkill}
        onCloseRequested={() => setShowDetailsSkill(undefined)}
      />
    }

    {showAssignLesson && lesson &&
      <AssignLessonGoalModal
        lesson={lesson}
        onCloseRequested={() => setShowAssignLesson(undefined)}
      />
    }

    {showPrintLesson &&
      <PrintModal
        view={<PrintLessonView lesson={showPrintLesson}/>}
        onCloseRequested={() => setShowPrintLesson(undefined)}
      />
    }

    {printLessonPlan &&
      <PrintModal
        view={<LessonPlanPrintView lessonPlan={printLessonPlan} />}
        onCloseRequested={() => setPrintLessonPlan(undefined)}
      />
    }

    <div className="lesson-editor" style={{overflowY: 'auto'}}>
      <div className="container">
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Print}
            onClick={() => setShowPrintLesson(lesson)}
          />
          <AccessControlled canEdit={lesson}>
            <Button
              variant={ButtonVariant.Edit}
              onClick={props.onEditRequested}
            />
            <Button
              variant={ButtonVariant.Delete}
              onClick={props.onDeleteRequested}
            />
          </AccessControlled>
        </ButtonGroup>

        <div className="row-fluid">
          <h3 className="title">
            {lesson?.name}
            {' '}
            <AccessControlled
              roles={[UserRoleType.Student, UserRoleType.Teacher]}
            >
              <a
                className="btn"
                onClick={() => setShowAssignLesson(lesson)}
                aria-label="assign lesson as a goal"
                style={{marginLeft: 5}}
              >
              Assign Lesson as a Goal
              </a>
            </AccessControlled>
          </h3>
          <div
            style={{
              marginTop: -26,
              fontSize: 10,
              fontStyle: 'italic',
              paddingLeft: 10,
              color: '#a5a5a5',
            }}
          >
          Category: {lesson?.category?.name} Version: {lesson?.version?.name}
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12"/>
        </div>

        <div className="row-fluid">
          <div className="span12">
            <h4>{__('Lesson Description')}</h4>
            <div className="html fred" dangerouslySetInnerHTML={{__html: lesson?.text ?? ''}} />
          </div>
        </div>

        <div className={classNames('row-fluid section', {'opened': true})}>
          <h4>{__('Action Steps')}</h4>
          <ActionStepList steps={lesson?.steps.filter((s) => !s.deleted) ?? []}/>

          {lesson?.materials &&
            <div className={classNames('row-fluid section', {'opened': !hideMaterials})}>
              <h4 onClick={() => setHideMaterials((current) => !current)}>
                {__('Materials Needed')}
              </h4>
              <div
                className="html frame fred"
                dangerouslySetInnerHTML={{__html: lesson.materials}}
              />
            </div>
          }

          {lesson?.preparation &&
            <div className={classNames('row-fluid opened section', {'opened': !hidePreparation})}>
              <h4 onClick={() => setHidePreparation((current) => !current)}>
                {__('Preparation')}
              </h4>
              <div
                className="html frame fred"
                dangerouslySetInnerHTML={{__html: lesson.preparation}}
              />
            </div>
          }

          <div className="row-fluid">
            {lesson?.plans &&
              <div className={classNames('section span12', {'opened': !hidePlans})}>
                <h4 onClick={() => setHidePlans((current) => !current)}>
                  {__('Lesson Plan')}
                </h4>
                <div className="frame">
                  {lesson.plans.map((plan) =>
                    <div key={plan.id} className="plan">
                      <div className="title">
                        <div className="buttons btn-group">
                          <a className="btn btn-small" onClick={() => setPrintLessonPlan(plan)}>
                            <i className="icon-print"/>
                          </a>
                        </div>
                        <span className="text">{plan.title}</span>
                        {plan.time &&
                          <span className="duration">
                            &nbsp;({plan.time} min)
                          </span>
                        }
                      </div>

                      <table className="list inner-list">
                        <thead>
                          <tr>
                            <td width="25">Min</td>
                            <td width="20%">Category</td>
                            <td >Description</td>
                          </tr>
                        </thead>
                        <tbody>
                          {plan.entries.map((entry) => (
                            <tr key={entry.id}>
                              <td className="vtop">{entry.time}</td>
                              <td className="vtop">{entry.activity}</td>
                              <td
                                className="vtop html"
                                dangerouslySetInnerHTML={{__html: entry.steps}}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>,
                  )}
                </div>
              </div>
            }
          </div>

          <div className="row-fluid">
            {lesson?.words && lesson.words.length > 0 &&
              <div className={classNames('section span12', {'opened': !hideWords})}>
                <h4 onClick={() => setHideWords((current) => !current)}>
                  {__('Words To Know')}
                </h4>
                <div className="frame">
                  {lesson.words.map((word) => (
                    <div key={word.id} className="word list">
                      <div className="target" style={{marginTop: 0}}>
                        <span>• {word.word}:</span>
                      </div>
                      <div
                        className="meaning html"
                        dangerouslySetInnerHTML={{__html: word.meaning}}
                      />
                    </div>
                  ))}
                </div>
              </div>
            }

            <div className="row-fluid">
              {lesson?.files && lesson.files.length > 0 &&
                <div className={classNames('section span12', {'opened': !hideFiles})}>
                  <h4 onClick={() => setHideFiles((current) => !current)}>
                    {__('Instructor Files')}
                  </h4>
                  {lesson.files.map((file) => (
                    <div key={file.id} className="frame">
                      <div
                        className="pointer"
                        onClick={() => setShownFile(file)}
                      >
                        {file.name}
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
