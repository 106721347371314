import React, {createContext, PropsWithChildren, useContext} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Notification, NotificationVariant} from 'components/Notification';

/**
 * Provides user notification services to consumers
 * (eg. an error notification popup)
 */
export interface NotificationContextInterface {
  showNotification(title: string, message: string): void
  showSuccessNotification(title: string, message: string): void
  showErrorNotification(title: string, message: string): void
  showInfoNotification(title: string, message: string): void
}

export const NotificationContext = createContext<NotificationContextInterface>({
  showNotification() {},
  showSuccessNotification() {},
  showErrorNotification() {},
  showInfoNotification() {},
});

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = (props: PropsWithChildren<{}>) => {
  return (
    <NotificationContext.Provider
      value={{
        showNotification(title: string, message: string) {
          toast(<Notification title={title} message={message}/>);
        },
        showSuccessNotification(title: string, message: string) {
          toast(<Notification
            variant={NotificationVariant.Success}
            title={title}
            message={message}
          />);
        },
        showErrorNotification(title: string, message: string) {
          toast(<Notification
            variant={NotificationVariant.Error}
            title={title}
            message={message}
          />);
        },
        showInfoNotification(title: string, message: string) {
          toast(<Notification
            variant={NotificationVariant.Info}
            title={title}
            message={message}
          />);
        },
      }}
    >

      {props.children}

      <ToastContainer
        position={'top-right'}
        autoClose={5000}
        hideProgressBar={true}
      />
    </NotificationContext.Provider>
  );
};
