import React, {Fragment, useEffect, useState} from 'react';
import {Resource} from 'api/resources/Resource';
import {ResourceType} from 'api/resources/ResourceType.enum';
import {QuizView} from 'views/learning/quizzes/QuizView';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Modal} from 'components/Modal';
import {FormattedDate} from 'components/FormattedDate';
import {__} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {useSessionContext} from 'contexts/SessionContext';
import {UserRoleType} from 'api/users/UserRole';
import {Tooltip} from 'components/Tooltip';
import {CollageLibraryModal} from 'views/collages/CollageLibraryModal';
import {FileViewer} from 'views/resources/FileViewer';
import {CollageBoard} from 'views/collages/CollageBoard';
import {useAccessControlContext} from 'access-control/AccessControlContext';
import {SurveyView} from 'views/learning/quizzes/SurveyView';
import {useAuthContext} from 'contexts/AuthContext';
import {QuizSolution} from 'api/quizzes/QuizSolution';
import {Quiz} from 'api/quizzes/Quiz';
import {AnalyticsService} from 'analytics/AnalyticsService';
import {AnalyticsEvent} from 'analytics/AnalyticsEvent.enum';

/**
 * Loads a learning resource and renders the appropriate component for the resource type.
 */

export interface LearningViewProps {
  resourceId: number,
  onCloseRequested?: () => any,
  onCompleted?: (resource?: Resource) => any,
}

export const LearningView = (props: LearningViewProps) => {
  const logger = useLogger(LearningView.name);
  const {accessControlService} = useAccessControlContext();
  const {resourcesService} = useApiContext();
  const {userIsSwitched} = useUserSwitchingContext();
  const {sessionDetails} = useSessionContext();
  const {currentUser} = useAuthContext();

  const [resource, setResource] = useState<Resource | undefined>(undefined);
  const [isFetchingResource, setIsFetchingResource] = useState<boolean>(true);
  const [isCompleting, setIsCompleting] = useState<boolean>(false);
  const [showCollageLibrary, setShowCollageLibrary] = useState<boolean>(false);

  const allowComplete = !userIsSwitched ||
    accessControlService.roleCanAccess(sessionDetails?.role?.type ?? UserRoleType.Student, AppFeature.LearningComplete);
  const showCompleteButton = !resource?.completed &&
    resource?.type !== ResourceType.Quiz &&
    resource?.type !== ResourceType.Collage;

  const completeResource = async () => {
    setIsCompleting(true);
    const result = await resourcesService.completeResource(
        props.resourceId,
        currentUser && accessControlService.userCanAccess(currentUser, 'step.autocomplete'),
    );
    props.onCompleted?.(result.entity);
    // original frontend looks to not define the event category
    AnalyticsService.default().recordEvent(AnalyticsEvent.ResourceCompleted, resource?.name ?? '-', '-');
    setIsCompleting(false);
  };

  const completeQuizResource = async (solution: QuizSolution) => {
    setIsCompleting(true);
    if (resource) {
      const result = await resourcesService.completeQuizResource(
          resource as any as Quiz,
          solution,
          currentUser && accessControlService.userCanAccess(currentUser, 'step.autocomplete'),
      );
      props.onCompleted?.(result.entity);
    }
    setIsCompleting(false);
  };

  const openInsecureLinkIfNeeded = () => {
    const linkUrl = resource?.resource?.link as string;
    if (linkUrl !== undefined && !linkUrl?.includes('https:')) {
      window.open(resource?.resource.link, '_blank');
    }
  };

  const _submitCollage = () => {
    // original frontend looks to not define the event category
    AnalyticsService.default().recordEvent(AnalyticsEvent.CollageCompleted, resource?.name ?? '-', '-');
  };

  useEffect(() => {
    (async () => {
      setIsFetchingResource(true);
      const fetchedResource = await resourcesService.fetchResource(props.resourceId);
      setResource(fetchedResource);
      logger.info('Opening resource', fetchedResource);
      setIsFetchingResource(false);
    })();
  }, []);

  useEffect(() => {
    if (resource?.type === ResourceType.Link) {
      openInsecureLinkIfNeeded();
    }
  }, [resource]);

  if (isCompleting) {
    return <LoadingIndicator message={__('Saving Content')} fullscreen={true} />;
  }

  return (
    <Modal
      onCloseRequested={props.onCloseRequested}
      className={'learning'}
      bodyClassName={'viewer quiz-view'}
      bodyStyle={{top: 0}}
      hideHeader={true}
      bodyPadding={resource?.type === ResourceType.File ? 0 : undefined}

      body={
        <Fragment>
          {isFetchingResource && <LoadingIndicator />}

          {showCollageLibrary &&
            <CollageLibraryModal
              onCloseRequested={() => setShowCollageLibrary(false)}
            />
          }

          {!isFetchingResource && resource !== undefined &&
            <div className="modal-body" style={{top: 0, padding: 0}}>
              {resource.type === ResourceType.Quiz && !resource.resource.survey &&
                <QuizView
                  resource={resource}
                  onCloseRequested={props.onCloseRequested}
                  onCompleted={async (solution) => {
                    if (solution) {
                      await completeQuizResource(solution);
                    }
                    props.onCompleted?.(solution as any as Resource);
                  }}
                />
              }

              {resource.type === ResourceType.Quiz && resource.resource.survey &&
                <SurveyView
                  resource={resource}
                  onCloseRequested={props.onCloseRequested}
                  onCompleted={() => {
                    props.onCompleted?.();
                  }}
                />
              }

              {resource.type === ResourceType.File &&
                <FileViewer url={resource.resource.url} noPadding={true}/>
              }

              {resource.type === ResourceType.Image &&
                <img src={resource.resource.url} alt={''} />
              }

              {resource.type === ResourceType.Movie &&
                <div className="body viewer">
                  <iframe
                    width="100%" height="100%"
                    src={
                      (resource.resource.owned ? '//player.vimeo.com/video/' : '//www.youtube.com/embed/') +
                      resource.resource.movieId
                    }
                    frameBorder={0}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 9998,
                      background: 'transparent',
                      bottom: 0, right: 0, width: '50%', height: 38,
                    }}
                  />
                </div>
              }

              {resource.type === ResourceType.Link &&
                <Fragment>
                  {resource.resource.link.includes('https:') &&
                    <iframe
                      className="link-frame"
                      src={resource.resource.link}
                      style={{width: '100%', height: '100%'}}
                    />
                  }
                  {!resource.resource.link.includes('https:') &&
                    <div className="row-fluid" style={{padding: 20}}>
                      <div className="pack">
                        <div
                          className="goal"
                          style={{
                            padding: 15,
                            height: 126,
                            width: 126,
                            position: 'relative',
                            display: 'inline-block',
                            float: 'left',
                          }}
                        >
                          <div
                            className="icon icon-desktop"
                            style={{
                              position: 'absolute',
                              top: 50,
                              left: '50%',
                              marginTop: -30,
                              marginLeft: -32,
                              width: 60,
                              height: 60,
                              fontSize: 60,
                              lineHeight: '60px',
                              textAlign: 'center',
                              textShadow: '0 1px 1px #999',
                              border: 'solid 5px white',
                              padding: 20,
                              borderRadius: '50%',
                              boxShadow: '0 1px 1px #999',
                            }}
                          />
                        </div>
                        <div className="span8" style={{marginTop: 30}}>
                          <div className="name" style={{padding: '10px 0'}}>
                            Your linked content has been opened in a new browser window
                          </div>
                          <p>
                            If your content fails to load in ten seconds please
                            {' '}
                            <a href={resource.resource.link} target="Centric Content">
                              click here
                            </a>.
                          </p>
                        </div>
                        <div style={{clear: 'both'}} />
                      </div>
                    </div>
                  }
                </Fragment>
              }

              {resource.type === ResourceType.Collage &&
                <CollageBoard collage={resource.resource} />
              }
            </div>
          }

          {!isFetchingResource && resource === undefined &&
            <p>Error loading resource.</p>
          }

          {resource?.completed && resource?.completeDate &&
            <div className="completed">
              <i className="icon-ok-circled"/> Completed Resource
              <br/>
              <span style={{fontSize: 9, textAlign: 'center'}}>
                <FormattedDate timestamp={resource?.completeDate} format={'MM/DD/YYYY'} />
                &nbsp;at&nbsp;
                <FormattedDate timestamp={resource?.completeDate} format={'h:m A'} />
              </span>
            </div>
          }

          {resource?.resource.inactive &&
            <div className="completed">
              <i className="icon-attention-circled" /> Inactive Resource
            </div>
          }

          {resource && resource.type !== ResourceType.Quiz && !resource.resource.survey &&
            <div className="viewerButtons">
              <div className="buttons btn-group">
                {allowComplete && !resource.resource.inactive && resource.type === ResourceType.Collage &&
                  <Tooltip title={__('Submit')}>
                    <a
                      className="btn btn-success"
                      style={{padding: '3px 20px'}}
                      onClick={_submitCollage}
                    >
                      <i className="icon-ok-circled" />
                    </a>
                  </Tooltip>
                }

                {allowComplete && !resource.resource.inactive && resource.type === ResourceType.Collage &&
                  <>
                    <div style={{width: 20, height: 28}} className="btn disabled" />

                    <span className="btn btn-success btn-upload">
                      <span>{__('Add Image')}</span>
                      <input type="file" name="file" className="upload-field" />
                    </span>

                    <a className="btn btn-success" onClick={() => setShowCollageLibrary(true)}>
                      {__('Image Library')}
                    </a>
                  </>
                }

                {allowComplete && !resource.resource.inactive && showCompleteButton &&
                  <AccessControlled feature={AppFeature.LearningComplete} ownerOf={resource}>
                    <a onClick={completeResource} className="btn btn-success">
                      <i className="icon-ok" /> <span>{__('Mark as Complete')}</span>
                    </a>
                  </AccessControlled>
                }

                {resource.resource.type !== ResourceType.Quiz &&
                  <a
                    onClick={props.onCloseRequested}
                    className="btn btn-default"
                    style={{padding: '3px 20px'}}
                  >
                    <i className="icon-cancel"/>
                  </a>
                }
              </div>
            </div>
          }
        </Fragment>
      }
    />
  );
};
