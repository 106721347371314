import React from 'react';
import {Button, ButtonProps, ButtonVariant} from 'components/buttons/Button';

export interface ButtonCancelProps extends ButtonProps {}

export const ButtonCancel = (props: ButtonCancelProps) => {
  return (
    <Button
      onClick={props.onClick}
      className={'btn-default'}
      iconCode={'icon-cancel'}
      variant={ButtonVariant.Cancel}
    />
  );
};
