import React, {useEffect, useState} from 'react';
import {Modal, ModalProps} from 'components/Modal';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface ModalAsyncProps extends ModalProps {
  init?: () => any;
  showError?: boolean;
}

export const ModalAsync = (props: ModalAsyncProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [hadInitError, setHadInitError] = useState<boolean>(false);

  let body = props.body;
  if (!isInitialized) {
    body = <LoadingIndicator/>;
  } else if (hadInitError || props.showError) {
    body = <p>Error loading content</p>;
  }

  useEffect(() => {
    (async () => {
      try {
        await props.init?.();
      } catch (e) {
        setHadInitError(true);
      }
      setIsInitialized(true);
    })();
  }, []);

  return <Modal {...props} body={body} />;
};
