import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {LoadingIndicator} from 'components/LoadingIndicator';

/**
 * Modal to indicate loading status -- used for modals that have an
 * initialization process before rendering content
 */

export interface LoadingModalProps {
  className?: string,
  title?: string,
  onCloseRequested?: () => any,
  delay?: number, // milliseconds to delay before showing, prevents flashing for quick loads
}

export const LoadingModal = (props: LoadingModalProps) => {
  const showDelay = props.delay ?? 300;
  const [delayMet, setDelayMet] = useState<boolean>(false);
  let timeoutHandle: any = null;

  useEffect(() => {
    timeoutHandle = setTimeout(() => setDelayMet(true), showDelay);

    return () => clearTimeout(timeoutHandle);
  }, []);

  if (!delayMet) {
    return <></>;
  }

  return <Modal
    title={props.title ?? ''}
    className={props.className ?? ''}
    onCloseRequested={props.onCloseRequested}
    body={<LoadingIndicator background={'none'}/>}
  />;
};
