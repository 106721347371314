import React, {useEffect} from 'react';
import {QuizQuestionWithAnswer} from 'api/quizzes/QuizSolution';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

export interface QuizOrderQuestionProps {
  question: QuizQuestionWithAnswer,
  onChange?: (newQuestion: QuizQuestionWithAnswer) => any,
}

export const QuizOrderQuestion = (props: QuizOrderQuestionProps) => {
  const {question} = props;
  const sortedAnswers = question.answers.sort((a, b) => a.order < b.order ? -1 : 1);

  const randomizeOrder = () => {
    const randomizedAnswers = [...question.answers];
    const unusedOrders = Array.from(Array(randomizedAnswers.length).keys());
    const randomUnusedOrder = () => unusedOrders[Math.floor(Math.random() * unusedOrders.length)];
    randomizedAnswers.forEach((answer) => {
      answer.order = randomUnusedOrder();
      const usedIndex = unusedOrders.findIndex((o) => o === answer.order);
      unusedOrders.splice(usedIndex, 1);
    });
    props.onChange?.({...question, answers: randomizedAnswers});
  };

  const onDragEnd = (dropResult: DropResult) => {
    const reorder = (startIndex: number, endIndex: number) => {
      const result = [...sortedAnswers];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    if (!dropResult.destination) {
      return;
    }

    const startIndex = dropResult.source.index;
    const endIndex = dropResult.destination.index;
    const newSortedAnswers = reorder(startIndex, endIndex);
    newSortedAnswers.forEach((answer, i) => answer.order = i);
    props.onChange?.({...question, answers: newSortedAnswers});
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#eee' : 'none',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    cursor: 'move',
    ...draggableStyle,
  });

  useEffect(() => {
    randomizeOrder();
  }, []);

  return (
    <div className="answers">
      <div className={'angular-ui-tree'}>
        <div className={'angular-ui-tree-nodes'}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'answers'}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{...getListStyle(snapshot.isDraggingOver)}}
                >
                  {sortedAnswers.map((answer, i) => (
                    <Draggable
                      key={answer.id}
                      draggableId={answer.id.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          key={answer.id}
                          className="answer clearfix angular-ui-tree-node angular-ui-tree-handle"
                        >
                          <div className="text html clearfix" dangerouslySetInnerHTML={{__html: answer.text}} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};
