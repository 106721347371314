import React from 'react';
import {User} from 'api/users/User';

export interface UsersListPrintViewProps {
  users: User[],
}

export const UsersListPrintView = (props: UsersListPrintViewProps) => {
  return <>
    <table cellPadding={10} cellSpacing={0}>
      <tr>
        <th colSpan={4}><h2>Users</h2></th>
      </tr>
      <tr style={{borderTop: 'solid 1px black'}}>
        <th>Name</th>
        <th>Email</th>
        <th>User Type</th>
      </tr>
      {props.users.map((user) => (
        <tr
          key={user.id}
          className="inline-item"
          style={{borderTop: 'dotted 1px #A6A6A6'}}
        >
          <td valign="top">{user.name}</td>
          <td valign="top">{user.email}</td>
          <td valign="top">
            <table cellPadding="10">
              {user.roles.map((role) => (
                <tr key={role.id} className="inline-item">
                  <td>{role.name}</td>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      ))}
    </table>
    <hr/>
  </>;
};
