import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {colorPickerDefaultColors} from 'components/ColorPicker_defaultColors';

/**
 * Control for the user to select a color
 */

export interface ColorPickerProps {
  color?: string,
  colorOptions?: string[],
  onChange?: (newColor: string) => any,
  isInvalid?: boolean,
}

export const ColorPicker = (props: ColorPickerProps) => {
  const selfRef = useRef<HTMLDivElement>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const closeOnClick = useCallback((e) => {
    if (
      !selfRef?.current?.contains(e.target) &&
      !selfRef?.current?.contains(e.target)
    ) {
      setShowPicker(false);
    }
  }, []);

  const closeOnEscape = useCallback((e) => {
    if (e.key === 'Escape') {
      setShowPicker(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', closeOnClick);
    document.addEventListener('keyup', closeOnEscape);
    return () => {
      document.removeEventListener('mousedown', closeOnClick);
      document.removeEventListener('keyup', closeOnEscape);
    };
  }, [closeOnClick, closeOnEscape]);

  return (
    <div
      ref={selfRef}
      className={classNames('color-picker', {required: props.isInvalid})}
    >
      <div
        title={`Selected color: ${props.color ?? 'none'}`}
        className="preview"
        onClick={() => setShowPicker((current) => !current)}
        aria-label={`Selected color: ${props.color ?? 'none'}`}
        style={{backgroundColor: props.color ?? 'transparent'}}
      />

      {showPicker &&
        <div className="picker">
          {(props.colorOptions ?? colorPickerDefaultColors).map((color) => (
            <div
              key={color}
              title={color}
              className={'picker-color'}
              onClick={() => {
                setShowPicker(false);
                props.onChange?.(color);
              }}
              aria-label="Color"
              style={{background: color}}
            >
              <i className={props.color}/>
            </div>
          ))}
        </div>
      }
    </div>
  );
};
