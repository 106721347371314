import * as Sentry from '@sentry/react';

/**
 * Starts error capturing with Sentry.
 */
export function initializeErrorReporting() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

/**
 * Records an error in the reporting system.
 */
export function recordError(error: Error) {
  Sentry.captureException(error);
}
