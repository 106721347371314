import React, {useState} from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';

import {QuizQuestionWithAnswer} from 'api/quizzes/QuizSolution';
import {QuizQuestionAnswer} from 'api/quizzes/QuizQuestionAnswer';

/**
 * A drag-and-drop match quiz question
 */
const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export interface QuizMatchQuestionProps {
  question: QuizQuestionWithAnswer,
  onChange?: (newQuestion: QuizQuestionWithAnswer) => any,
  onAnswerReady?: () => any,
  onAnswerNotReady?: () => any,
  disableAnswerShuffle?: boolean,
}

export const QuizMatchQuestion = ({
  question,
  onChange,
  onAnswerReady,
  onAnswerNotReady,
  disableAnswerShuffle,
}: QuizMatchQuestionProps) => {
  const [options, setOptions] = useState<QuizQuestionAnswer[]>(
    disableAnswerShuffle ? question.answers : shuffleArray(JSON.parse(JSON.stringify(question.answers))),
  );
  const [matches, setMatches] = useState(
    disableAnswerShuffle ? question.answers : shuffleArray(JSON.parse(JSON.stringify(question.answers))),
  );

  const nonRespondedOptions = matches.filter((answer) => answer.matchTo === undefined);

  const onDragEnd = (dropResult: DropResult) => {
    const dropArea = dropResult.destination?.droppableId;
    if (!dropArea) {
      return;
    }
    const movedOptionId = parseInt(dropResult.draggableId);
    if (isNaN(movedOptionId)) {
      return;
    }
    const movedOption = matches.find((a) => a.id === movedOptionId);
    if (!movedOption) {
      return;
    }

    if (dropArea === 'options') {
      if (nonRespondedOptions.length < 1) {
        onAnswerNotReady?.();
      }
      const newAnswers = [...question.answers];
      newAnswers.forEach((answer) => {
        if (answer?.matchTo?.order === movedOptionId) {
          answer.matchTo = undefined;
        }
      });
      setMatches((current) => {
        const copy = JSON.parse(JSON.stringify(current));
        copy.forEach((match: any) => {
          if (match?.matchTo?.order === movedOptionId) {
            match.matchTo = undefined;
          }
        });
        return copy;
      });
      if (!options.some((option) => option.id === movedOptionId)) {
        setOptions((current) => current.concat([movedOption]));
      }
      onChange?.({...question, answers: newAnswers});
    } else {
      const dropAreaParts = dropArea.split('-');
      if (dropAreaParts.length > 1) {
        if (nonRespondedOptions.length === 1) {
          onAnswerReady?.();
        }

        const areaId = parseInt(dropAreaParts[1]);
        const matchedAnswerIndex = matches.findIndex((answer) => answer.id === areaId);
        const matchedAnswer = matches[matchedAnswerIndex];
        if (matchedAnswer) {
          const newAnswers = [...question.answers];
          setOptions((current) => current.filter((c) => c.id !== movedOption.id));
          setMatches((current) => {
            const copy = JSON.parse(JSON.stringify(current));
            copy.forEach((match: any) => {
              if (match.id === matchedAnswer.id) {
                match.matchTo = {text: movedOption.text, order: movedOption.order};
              }
            });
            return copy;
          });
          newAnswers.forEach((answer) => {
            if (answer?.id === matchedAnswer.id) {
              answer.matchTo = {text: movedOption.text, order: movedOption.order};
            }
          });
          onChange?.({...question, answers: newAnswers});
        }
      }
    }
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightgrey' : 'none',
  });

  const getResponseAreaStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#8dc73f' : 'none',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="answers match-answers solving">
        <Droppable droppableId={'options'}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={classNames(
                  'texts',
                  {'empty': nonRespondedOptions.length < 1},
              )}
            >
              <h5> Drag items below to the matching item on the right.</h5>

              {options.map((answer, i) => (
                <Draggable
                  key={answer.id}
                  draggableId={answer.id.toString()}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                      )}
                      className="answer no-checkbox"
                    >
                      <div
                        className="text html clearfix"
                        dangerouslySetInnerHTML={{__html: answer.text}}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              <div className="placeholder" />
            </div>
          )}
        </Droppable>

        <div className="matches">
          {matches.map((answer, i) => (
            <Droppable
              key={answer.id}
              droppableId={`response-${answer.id}`}
              isDropDisabled={answer.matchTo !== undefined}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getResponseAreaStyle(snapshot.isDraggingOver)}
                >
                  {!answer.matchTo && (
                    <div className="answer no-checkbox">
                      <div
                        className="text html clearfix"
                        dangerouslySetInnerHTML={{__html: matches[i].match}}
                      />
                      {provided.placeholder}
                    </div>
                  )}

                  {answer.matchTo && (
                    <Draggable
                      draggableId={answer.matchTo ? answer.matchTo.order.toString() : answer.id.toString()}
                      index={0}
                      key={answer.id.toString()}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className="answer no-checkbox answered"
                        >
                          <div
                            className="text html clearfix"
                            dangerouslySetInnerHTML={{__html: answer.matchTo!.text}}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                </div>
              )}
            </Droppable>
          ))}
        </div>

        <div className="clearfix"/>
      </div>
    </DragDropContext>
  );
};
