import {AxiosInstance} from 'axios';
import {SurveySummary} from 'api/surveys/Survey';
import {Resource} from 'api/resources/Resource';
import {ApiRoute} from 'api/ApiRoute';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {Quiz} from 'api/quizzes/Quiz';
import {QuizSolution} from 'api/quizzes/QuizSolution';

/**
 * Provides API resource functions
 */
export class ResourcesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Creates a new resource in the API
   *
   * @param {SurveySummary} survey
   * @return {Promise<Resource>}
   */
  async createResource(survey: SurveySummary): Promise<Resource> {
    const response = await this.httpClient.post(ApiRoute.CreateResource, survey);
    return response.data;
  }

  /**
   * Fetches a resource from the API
   *
   * @param {number} resourceId
   * @return {Resource}
   */
  async fetchResource(resourceId: number): Promise<Resource> {
    const response = await this.httpClient.post(ApiRoute.FetchResource, {
      id: resourceId,
    });
    return response.data;
  }

  /**
   * Completes a given resource
   *
   * @param {number} id
   * @param {boolean} autoComplete
   */
  async completeResource(id: number, autoComplete = false): Promise<SuccessResponse<Resource>> {
    const response = await this.httpClient.post('/student/resources/complete', {
      item: {id},
      autocomplete: autoComplete,
    });
    return response.data;
  }

  /**
   * Completes a quiz resource with the quiz solution
   *
   * @param {Quiz} quiz
   * @param {QuizSolution} solution
   * @param {boolean} autoComplete
   */
  async completeQuizResource(
      quiz: Quiz,
      solution: QuizSolution,
      autoComplete = false,
  ): Promise<SuccessResponse<Resource>> {
    const response = await this.httpClient.post('/student/resources/complete', {
      item: {...quiz, solution},
      autocomplete: autoComplete,
    });
    return response.data;
  }

  /**
   * Submits a grade for a resource
   *
   * @param resource
   * @param score
   * @param comment
   */
  async gradeResource(resource: any, score: number, comment?: string ) : Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/student/resources/grade', {
      resource,
      score: score.toString(),
      comment,
    });
    return response.data;
  }
}
