import {AxiosInstance} from 'axios';

/**
 * Provides functions related to entity "seen/not seen" status
 */
export class ViewingHistoryService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Records journal entries as seen by the user
   *
   * @param ids
   */
  async markSeenJournalEntries(ids: number[]) {
    const response = await this.httpClient.post('/session/saveSeen', {
      list: ids.map((id) => ({type: 'journal.entry', id})),
    });
    return response.data;
  }

  /**
   * Records journal comments as seen by the user
   *
   * @param ids
   */
  async markSeenJournalComments(ids: number[]) {
    const response = await this.httpClient.post('/session/saveSeen', {
      list: ids.map((id) => ({type: 'journal.comment', id})),
    });
    return response.data;
  }
}
