import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useSessionContext} from 'contexts/SessionContext';
import {useApiContext} from 'api/ApiContext';
import {FileUpload} from 'api/file-uploads/FileUpload';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useLogger} from 'logging/logging';
import {UserImportInitializationData} from 'api/users/UserImportInitializationData';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface ImportUsersModalProps {
  onComplete?: () => any,
  onCloseRequested?: () => any,
}

export const ImportUsersModal = (props: ImportUsersModalProps) => {
  const logger = useLogger(ImportUsersModal.name);
  const {sessionDetails} = useSessionContext();
  const {usersService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [activeUserCount, setActiveUserCount] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<FileUpload | undefined>(undefined);
  const [startImportResult, setStartImportResult] = useState<UserImportInitializationData | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const showReview = startImportResult && !startImportResult.error;
  const allowedSeats = sessionDetails?.selectedSchool?.seats ?? 0;
  const seatsOverfilledCount = activeUserCount + (startImportResult?.new_users ?? 0) - allowedSeats;
  const seatsOverfilled = seatsOverfilledCount > 0;

  const fetchActiveUserCount = async () => {
    const schoolId = sessionDetails?.selectedSchool?.id;
    if (schoolId) {
      const activeUsers = await usersService.fetchUsers({filters: {
        active: true,
        school: schoolId,
      }});
      logger.debug('Fetched active school users', activeUsers);
      setActiveUserCount(activeUsers.length);
    }
  };

  const initializeImport = async () => {
    if (isSubmitting) {
      return;
    }
    if (!uploadedFile) {
      showErrorNotification(__('Error'), __('Please upload users CSV file'));
      return;
    }

    setIsSubmitting(true);
    const fetchedStartImportResult = await usersService.initializeCsvImport(uploadedFile);
    setStartImportResult(fetchedStartImportResult);
    logger.debug('Initialized user CSV import', fetchedStartImportResult);
    if (fetchedStartImportResult.error) {
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(false);
  };

  const submitImport = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const confirmImportResult = await usersService.confirmCsvImport();
    logger.debug('Confirmed user CSV import', confirmImportResult);
    setIsSubmitting(false);
    props.onComplete?.();
  };

  useEffect(() => {
    (async () => {
      await fetchActiveUserCount();
      setIsInitializing(false);
    })();
  }, []);

  return (
    <Modal
      title={__('Import Users')}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Fragment>
          {!showReview &&
            <Button
              text={__('Import')}
              onClick={initializeImport}
              variant={ButtonVariant.Confirm}
              iconCode={''}
              disabled={isSubmitting}
            />
          }
          {showReview &&
            <Fragment>
              {!seatsOverfilled &&
                <Button
                  variant={ButtonVariant.Confirm}
                  onClick={submitImport}
                  disabled={isSubmitting}
                />
              }
              <Button
                text={__('Back')}
                className={'btn-default'}
                onClick={() => setStartImportResult(undefined)}
                disabled={isSubmitting}
              />
            </Fragment>
          }
        </Fragment>
      }

      body={
        <Fragment>
          {isInitializing && <LoadingIndicator/>}

          {!isInitializing &&
          <Fragment>
            {startImportResult?.error &&
            <div className="alert alert-error"><b>Error:</b>&nbsp;{startImportResult.error}</div>
            }

            {!showReview &&
            <form>
              <div className={'row-fluid'}>
                <FileUploadInput
                  buttonText={__('Add CSV File')}
                  initialFiles={uploadedFile ? [uploadedFile] : []}
                  onChange={(newFiles) => setUploadedFile(newFiles[0])}
                />
              </div>
            </form>
            }

            {showReview &&
            <div>
              {(startImportResult?.ignored?.length ?? 0) > 0 &&
              <div>
                <div className="alert alert-warning">
                  <b>Ignored records</b>:<br />
                  {startImportResult?.ignored?.map((ignored, i) => (
                    <div key={i.toString()}>
                      {__('Line')}: {ignored.line}, {ignored.error}
                    </div>
                  ))}
                </div>
              </div>
              }

              {seatsOverfilled &&
                <div>
                  Importing these users would put you {seatsOverfilledCount} seat(s) over your current seat limit.
                  {' '}Please purchase more seats, or deactivate your inactive users before you continue.
                </div>
              }

              <div>
                <div className="alert alert-success">
                  <b>{__('Operations to be performed')}:</b><br />
                  <div>{startImportResult?.new_users ?? 0} <span>{__('new users will be added')}</span></div>
                  <div>{startImportResult?.new_classes ?? 0} <span>{__('new classes will be added')}</span></div>
                  <div>{startImportResult?.updated_users ?? 0} <span>{__('existing users will be updated')}</span></div>
                  <div>
                    {startImportResult?.updated_classes ?? 0}
                    {' '}
                    <span>{__('existing classes will be updated')}</span>
                  </div>
                </div>
              </div>
            </div>
            }
          </Fragment>
          }
        </Fragment>
      }

      footer={<div style={{height: 17}}/>}
    />
  );
};
