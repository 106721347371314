import React, {useEffect, useRef} from 'react';
import {Modal} from 'components/Modal';
import {Challenge, CreateChallengeDto} from 'api/challenges/Challenge';
import {__} from 'i18n/localize';
import {useApiContext} from 'api/ApiContext';
import {Controller, useForm} from 'react-hook-form';
import {Button, ButtonVariant} from 'components/buttons/Button';
import classNames from 'classnames';
import {DateInput} from 'components/forms/DateInput';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {useLogger} from 'logging/logging';
import {ChallengeType} from 'api/challenges/ChallengeType.enum';
import {Course} from 'api/courses/Course';
import {ChallengeGoalType} from 'api/challenges/ChallengeGoalType.enum';

export interface ChallengeInputModalProps {
  initialChallenge?: Challenge,
  forCourse?: Course,
  forStudentId?: number,
  onCloseRequested?: () => any,
  onComplete?: () => any,
}

export const ChallengeInputModal = (props: ChallengeInputModalProps) => {
  const logger = useLogger(ChallengeInputModal.name);
  const {challengesService} = useApiContext();
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: {isSubmitting, errors},
  } = useForm<CreateChallengeDto>({
    defaultValues: props.initialChallenge,
  });
  const formRef = useRef<HTMLFormElement>(null);

  const isEditing = props.initialChallenge?.id !== undefined;
  const goalType = watch('goalType');

  const submit = async (newChallenge: CreateChallengeDto) => {
    if (isEditing && props.initialChallenge?.id) {
      await challengesService.updateChallenge({
        ...newChallenge,
        id: props.initialChallenge.id,
      });
    } else {
      const result = await challengesService.createChallenge({
        ...newChallenge,
        owner: props.forStudentId ? {id: props.forStudentId} : props.forCourse,
        type: props.forStudentId ? ChallengeType.User : ChallengeType.Course,
      });
      logger.debug('Created challenge', result);
    }

    props.onComplete?.();
  };

  useEffect(()=>{
    formRef.current?.submit();
  }, []);

  return <Modal
    title={__(isEditing ? 'Edit Challenge' : 'Add Challenge')}
    onCloseRequested={props.onCloseRequested}
    className={'tall'}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        disabled={isSubmitting}
        type={'submit'}
        form={'challengeForm'}
      />
    }

    body={
      <form ref={formRef} id={'challengeForm'} onSubmit={handleSubmit(submit)}>
        <div className={'row-fluid'}>
          <label>
            <input
              type={'text'}
              placeholder={__('Title')}
              aria-label={__('Title')}
              className={classNames(
                  'span12',
                  {'invalid': errors.name !== undefined},
              )}
              {...register('name', {required: true})}
            />
            <span style={{display: 'none'}}>{__('Title')}</span>
          </label>
        </div>

        <div className={'row-fluid'}>
          <label>
            <select
              className={classNames(
                  'span12',
                  {'invalid': errors.goalType !== undefined},
              )}
              {...register('goalType', {required: true})}
              aria-label="Challenge Type"
            >
              <option value={''}>-- {__('Goal Type')} --</option>
              <option value={ChallengeGoalType.CollectGoalsTilDeadline}>
                {__('Collect number of rings until deadline')}
              </option>
              <option value={ChallengeGoalType.CollectMax}>
                {__('Collect as many rings as possible')}
              </option>
              <option value={ChallengeGoalType.CollectNumberGoals}>
                {__('Collect number of rings')}
              </option>
            </select>
            <span style={{display: 'none'}}>{__('Goal Type')}</span>
          </label>
        </div>

        {goalType && goalType !== ChallengeGoalType.CollectMax &&
          <div className={'row-fluid'}>
            <label>
              <input
                type={'number'}
                aria-label={__('Goals to Collect')}
                placeholder={__('Goals to Collect')}
                className={classNames(
                    'span12',
                    {'invalid':
                        errors.goalsToCollect !== undefined,
                    },
                )}
                {...register('goal', {required: true})}
              />
              <span style={{display: 'none'}}>
                {__('Goals to Collect')}
              </span>
            </label>
          </div>
        }
        {goalType && goalType !== ChallengeGoalType.CollectNumberGoals &&
          <div className={'row-fluid'}>
            <label>
              <Controller
                control={control}
                name={'deadline'}
                render={({
                  field,
                }) =>
                  <DateInput
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={__('Deadline')}
                    aria-label={__('Deadline')}
                    className={classNames(
                        'span12',
                        {'invalid': errors.deadline !== undefined},
                    )}
                    wrapperClassName={'span12'}
                  />
                }
              />
              <span style={{display: 'none'}}>{__('Deadline')}</span>
            </label>
          </div>
        }
        <div className={'row-fluid'}>
          <Controller
            control={control}
            name={'description'}
            render={({
              field,
            }) =>
              <RichTextInput
                value={field.value}
                onChange={field.onChange}
                placeholder={__('Description')}
                aria-label={__('Description')}
              />
            }
          />
        </div>
      </form>
    }

    footer={<InputPlaceholderMirror/>}
  />;
};
