import React from 'react';
import LibSelect, {components} from 'react-select';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import styleClasses from './Select.module.scss';

library.add(faCaretDown);

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon="caret-down" size={'xs'} />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export interface SelectBaseProps {
  label?: string,
  name?: string,
  placeholder?: string,
  multiple?: boolean,
  clearable?: boolean,
  isInvalid?: boolean,
  className?: string,
  extraProps?: any,
  onBlur?: () => any,
}

export interface SelectProps extends SelectBaseProps {
  value?: string | string[],
  options: {label: string, value: any}[],
  onChange?: (newValue?: any | any[]) => any,
}

export const Select = (props: SelectProps) => {
  return (
    <LibSelect
      {...props.extraProps}
      className={classNames(
          styleClasses.reactSelect,
          {'invalid': props.isInvalid},
      )}
      isClearable={props.clearable ?? true}
      aria-label={props.label}
      id={props.name ?? props.label ?? props.placeholder ?? ''}
      name={props.name}
      isMulti={props.multiple ?? false}
      isSearchable={true}
      placeholder={props.placeholder}
      options={props.options}
      onBlur={props.onBlur}
      menuPortalTarget={document.body}

      value={
        props.multiple ?
          props.options.filter((o) => props.value?.includes(o.value)) :
          props.options.find((o) => o.value === props.value)
      }

      onChange={(selected) => {
        if (selected === null) {
          props.onChange?.(undefined);
          return;
        }

        if (!props.multiple) {
          const option = selected as {value: string, label: string};
          props.onChange?.(option.value);
        } else {
          const options = selected as any as {value: string, label: string}[];
          props.onChange?.(options.map((option) => option.value));
        }
      }}

      components={{DropdownIndicator}}

      styles={{
        menuPortal: (base) => ({...base, zIndex: 9999}),
        control: (provided) => ({
          ...provided,
          borderColor: props.isInvalid ? '#df1b17' : '#9e9e9e',
          minHeight: '30px',
          height: '30px',
          fontSize: 14,
          marginBottom: 10,
          background: 'linear-gradient(#fff, #eee)',
          boxShadow: props.isInvalid ? '0 0 6px #d59392' : 'none',
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: '33px',
          padding: '5px 6px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
          fontSize: 14,
          position: 'relative',
          top: -6,
        }),
        multiValue: (provided) => ({
          ...provided,
          position: 'relative',
          top: -10,
          borderRadius: 4,
          border: '1px solid #ccc',
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          padding: 0,
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '29px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '8px 4px',
          borderLeft: '1px solid #aaa',
          borderRadius: '0 4px 4px 0',
          backgroundClip: 'padding-box',
          background: '#ccc',
          // eslint-disable-next-line max-len
          backgroundImage: '-webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee))',
        }),
        clearIndicator: (provided ) => ({
          ...provided,
          padding: 0,
          marginRight: 4,
          width: 14,
        }),
      }}
    />
  );
};
