import React, {useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {UserRole} from 'api/users/UserRole';
import {useSchoolContext} from 'contexts/SchoolContext';
import {Button} from 'components/buttons/Button';
import {ButtonDelete} from 'components/buttons/ButtonDelete';
import {SearchInput} from 'components/SearchInput';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {DataGrid} from 'components/DataGrid';
import {EditUserModal} from 'views/users/EditUserModal';
import {confirmDelete} from 'components/confirmDelete';
import {confirmModal} from 'components/confirmModal';
import {useLogger} from 'logging/logging';
import {useNotificationContext} from 'contexts/NotificationContext';
import {__, localizeText} from 'i18n/localize';
import {CheckBox} from 'components/forms/CheckBox';
import {ExportToCsv} from 'export-to-csv';
import {UserAvatar} from 'components/UserAvatar';
import {User} from 'api/users/User';
import {Link} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {getProfileRoute} from 'routing/routing';
import {PrintModal} from 'components/PrintModal';
import {UsersListPrintView} from 'views/users/UsersListPrintView';
import {Tooltip} from 'components/Tooltip';
import {School} from 'api/schools/School';
import {useParams} from 'react-router-dom';
import {SchoolStats} from 'api/schools/SchoolStats';
import {ImportUsersModal} from 'views/users/ImportUsersModal';
import classNames from 'classnames';

export const UsersListView = () => {
  const pageSize = 50;
  const logger = useLogger(UsersListView.name);
  const {authService, usersService, schoolsService} = useApiContext();
  const {authorizedSchools} = useSchoolContext();
  const {showErrorNotification} = useNotificationContext();
  const {schoolId}: any = useParams();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [listEndReached, setListEndReached] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [createNewUser, setCreateNewUser] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<number | undefined>(undefined);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [showImportUsers, setShowImportUsers] = useState<boolean>(false);
  const [schoolStats, setSchoolStats] = useState<SchoolStats | undefined>(undefined);
  const [school, setSchool] = useState<School | undefined>(undefined);
  const [filters, setFilters] = useState<{
    active: boolean | undefined,
    search: string,
    role: number | undefined,
    school: number | undefined,
  }>({active: undefined, search: '', role: undefined, school: undefined});

  const fetchUsers = async () => {
    setIsFetching(true);
    try {
      const usersResult = await usersService.fetchUsers({
        start: pageSize * page,
        end: (pageSize * page) + pageSize,
        filters,
      });
      if (usersResult.length < pageSize) {
        setListEndReached(true);
      }
      logger.debug('Fetched users', usersResult);
      setUsers((current) => current.concat(usersResult));
    } catch (e) {
      logger.error('Error fetching users', e);
    }
    setIsFetching(false);
  };

  const resetList = async () => {
    setSelectedUserIds([]);
    setUsers([]);
    setListEndReached(false);
    if (page === 0) {
      await fetchUsers();
    } else {
      setPage(0);
    }
  };

  const suspendUser = async (userId: number) => {
    try {
      const result = await usersService.suspendUser(userId);
      if (result.ok) {
        logger.info('Suspended user', {id: userId});
        setUsers((current) => {
          const newUsers = [...current];
          newUsers.filter((user) => user.id === userId).forEach((user) => user.suspended = true);
          return newUsers;
        });
      } else {
        logger.error('Failed to suspend user');
        showErrorNotification('Error', 'Failed to suspend user');
      }
    } catch (e) {
      logger.error('Error suspending user', e);
      showErrorNotification('Error', 'Failed to suspend user');
    }
  };

  const unSuspendUser = async (userId: number) => {
    try {
      const result = await usersService.unSuspendUser(userId);
      if (result.ok) {
        logger.info('Un-suspended user', {id: userId});
        setUsers((current) => {
          const newUsers = [...current];
          newUsers.filter((user) => user.id === userId).forEach((user) => user.suspended = false);
          return newUsers;
        });
      } else {
        logger.error('Failed to un-suspend user');
        showErrorNotification('Error', 'Failed to activate user');
      }
    } catch (e) {
      logger.error('Error un-suspending user', e);
      showErrorNotification('Error', 'Failed to activate user');
    }
  };

  const toggleSelectAll = () => {
    if (!allSelected) {
      setSelectedUserIds(users.map((user) => user.id));
      setAllSelected(true);
    } else {
      setSelectedUserIds([]);
      setAllSelected(false);
    }
  };

  const exportCsv = () => {
    const exporter = new ExportToCsv({
      filename: 'users',
      useKeysAsHeaders: true,
    });
    exporter.generateCsv(users.map((user) => ({
      'id': user.id,
      'deleted': user.deleted ? 'TRUE' : 'FALSE',
      'Name': user.name,
      'Practice': user.practice ?? '',
      'Street': user.street ?? '',
      'City': user.city ?? '',
      'State': user.state ?? '',
      'Zip': user.zip ?? '',
      'Country': user.country ?? '',
      'Phone': user.phone ?? '',
      'Occupation': user.occupation ?? '',
      'AGD Member': user.agdMember ?? '',
      'ADA Member': user.adaMember ?? '',
      'Email': user.email,
      'Login': user.login,
      'Schools': '"' + user.schools.map((school, i) => {
        const postfix = i < user.schools.length - 1 ? ', ' : '';
        return school.name + postfix;
      }) + '"',
      'Roles': '"' + user.roles.map((role, i) => {
        const postfix = i < user.roles.length - 1 ? ', ' : '';
        return role.name + postfix;
      }) + '"',
    })));
  };

  const loadSchoolStats = async () => {
    const fetchedSchoolStats = await schoolsService.fetchSchoolStats(schoolId);
    logger.debug('Fetched school Stats', fetchedSchoolStats);
    setSchoolStats(fetchedSchoolStats);
  };

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      const rolesResult = await authService.fetchUserRoles();
      setRoles(rolesResult);
      await resetList();
      setIsInitializing(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      await loadSchoolStats();
      setIsInitializing(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!schoolId) {
        return;
      }

      setIsInitializing(true);
      const fetchedSchool = await schoolsService.fetchSchool(schoolId);
      logger.debug('Fetched school', fetchedSchool);
      setSchool(fetchedSchool);
      setIsInitializing(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!listEndReached && !isInitializing && !isFetching) {
        logger.debug('Fetching page');
        await fetchUsers();
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      if (!isInitializing && !isFetching) {
        logger.debug('Resetting list');
        await resetList();
      }
    })();
  }, [filters]);
  return (
    <PageView>
      {editUserId &&
        <EditUserModal
          userId={editUserId}
          onCancel={() => setEditUserId(undefined)}
          onComplete={async () => {
            setEditUserId(undefined);
            await resetList();
          }}
        />
      }

      {createNewUser &&
        <EditUserModal
          onComplete={async () => {
            setCreateNewUser(false);
            await resetList();
          }}
          onCancel={() => setCreateNewUser(false)}
        />
      }

      {showPrint &&
        <PrintModal
          view={<UsersListPrintView users={users}/>}
          onCloseRequested={() => setShowPrint(false)}
        />
      }

      {showImportUsers &&
        <ImportUsersModal
          onComplete={async () => {
            setShowImportUsers(false);
            await loadSchoolStats();
            await resetList();
          }}
          onCloseRequested={() => setShowImportUsers(false)}
        />
      }

      <div className="pane">
        <div className="body">
          {selectedUserIds.length > 0 && (
            <div className="multi">
              <Button
                iconCode={'icon-lock'}
                onClick={() => confirmModal(
                    () => {
                      selectedUserIds.forEach((userId) =>
                        suspendUser(userId),
                      );
                      resetList();
                    },
                    'Suspend',
                    `Are you sure you want to suspend ${selectedUserIds.length} users`,
                )}
              />
              {' '}
              <Button
                iconCode={'icon-lock-open'}
                onClick={() => confirmModal(
                    () => {
                      selectedUserIds.forEach((userId) =>
                        unSuspendUser(userId),
                      );
                      resetList();
                    },
                    'Activate',
                    `Are you sure you want to activate ${selectedUserIds.length} users`,
                )}
              />
              {' '}
              <Button
                iconCode={'icon-trash'}
                onClick={() => confirmModal(
                    async () => {
                      const deletionPromises = [];
                      for (let i = 0; i < selectedUserIds.length; ++i) {
                        deletionPromises.push(usersService.deleteUser(selectedUserIds[i]));
                      }
                      await Promise.all(deletionPromises);
                      await loadSchoolStats();
                      await resetList();
                    },
                    'Delete',
                    `Are you sure you want to delete ${selectedUserIds.length} users`,
                )}
              />
              {' '}
              <Button
                onClick={toggleSelectAll}
                iconCode={allSelected ? 'icon-check-empty' : 'icon-check'}
                tooltip={allSelected ? 'Deselect All' : 'Select All'}
              />
            </div>
          )}

          <ContextMenu>
            {schoolId &&
              <div className="pull-left infoBubble">
                <i className="icon icon-user" style={{padding: '5px'}} />
                <span className={classNames(
                    'badge',
                    {'badge-success': (schoolStats?.userCount ?? 0) < (school?.seats ?? 0)},
                    {'badge-warning': (schoolStats?.userCount ?? 0) >= (school?.seats ?? 0)},
                )}>
                  {schoolStats?.userCount} of {school?.seats}
                </span>
              </div>
            }

            <SearchInput
              value={filters.search}
              onChange={(newValue) => {
                setFilters((current) => ({
                  ...current,
                  search: newValue,
                }));
              }}
            />

            <i className="icon icon-users" />
            <select
              className="form-control"
              onChange={(e) => {
                setFilters((current) => ({
                  ...current,
                  role: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : undefined,
                }));
              }}
            >
              <option value="">- Role -</option>
              {roles.map((role) => (
                <option key={role.type} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>

            <i className="icon icon-cloud" />
            <select
              className="form-control"
              onChange={(e) => {
                setFilters((current) => ({
                  ...current,
                  school: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : undefined,
                }));
              }}
            >
              <option value="">{'- Campus -'}</option>
              {authorizedSchools.sort((a, b) => a.name < b.name ? -1 : 1).map((school) => (
                <option key={school.id} value={school.id}>
                  {school.name}
                </option>
              ))}
            </select>

            <i className="icon filter-icon icon-lock-open" />
            <select
              onChange={(e) => {
                let newActive: boolean | undefined = undefined;
                if (e.target.value === 'true') {
                  newActive = true;
                } else if (e.target.value === 'false') {
                  newActive = false;
                }
                setFilters((current) => ({
                  ...current,
                  active: newActive,
                }));
              }}
            >
              <option value="">- Is Active -</option>
              <option value={'true'}>Active</option>
              <option value={'false'}>Suspended</option>
            </select>

            <Tooltip title={__('Print')}>
              <a className="btn btn-info" onClick={() => setShowPrint(true)}>
                <i className="icon-print" />
              </a>
            </Tooltip>

            <a className="btn" onClick={exportCsv}>
              <i className="icon-download" />
            </a>

            {schoolId &&
              <AccessControlled feature={AppFeature.CreateUser}>
                <Tooltip title={__('import users')}>
                  <a onClick={() => setShowImportUsers(true)} className="btn btn-success">
                    <i className="icon-upload" />
                  </a>
                </Tooltip>
              </AccessControlled>
            }

            <AccessControlled feature={AppFeature.CreateUser}>
              <ButtonAdd onClick={() => setCreateNewUser(true)} />
            </AccessControlled>
          </ContextMenu>

          <DataGrid
            isLoading={isFetching}
            onBottomReached={() => setPage((current) => current + 1)}
            header={
              <tr>
                <td/>
                <td width={60}/>
                <td>{localizeText('Name')}</td>
                <td>{localizeText('Role')}</td>
                <td>{localizeText('Campus')}</td>
                <td width={40}>{localizeText('Suspended')}</td>
                <td width={35}/>
              </tr>
            }
            body={
              <>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <CheckBox
                        checked={selectedUserIds.includes(user.id)}
                        onClick={() => {
                          const index = selectedUserIds.indexOf(user.id);
                          if (index > -1) {
                            const copy = Object.assign(
                                [],
                                selectedUserIds,
                            );
                            copy.splice(index, 1);
                            setSelectedUserIds(copy);
                          } else {
                            setSelectedUserIds((current) =>
                              current.concat([user.id]),
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Link to={getProfileRoute(user.id) + AppRoute.Goals}>
                        <UserAvatar
                          user={user}
                          size={35}
                          borderWidth={3}
                          boxShadow={'1px 1px 3px #525252'}
                        />
                      </Link>
                    </td>
                    <td>
                      {user.name}
                      <br/>
                      {user.email}
                      {user.customId &&
                        <>
                          <br/>
                          {user.customId}
                        </>
                      }
                    </td>
                    <td>
                      {user.roles.map((role, i) => {
                        const postfix = i < user.roles.length - 1 ? ', ' : '';
                        return role.name + postfix;
                      })}
                    </td>
                    <td>
                      {user.schools.map((school, i) => {
                        const postfix = i < user.schools.length - 1 ? ', ' : '';
                        return school.name + postfix;
                      })}
                    </td>
                    <td>
                      {user.suspended &&
                        <i className={'icon-lock'}/>
                      }
                    </td>
                    <td>
                      <div className="icons btn-group">
                        <a
                          className="btn btn-icon btn-mini"
                          onClick={() => setEditUserId(user.id)}
                        >
                          <i className={'icon-edit'} />
                        </a>

                        {!user.suspended && (
                          <a
                            onClick={() => confirmModal(
                                () => suspendUser(user.id),
                                'Suspend',
                                'Are you sure you want to suspend the user?',
                            )}
                            className="btn btn-icon btn-mini"
                          >
                            <i className="icon-lock" />
                          </a>
                        )}

                        {user.suspended && (
                          <a
                            onClick={() => confirmModal(
                                async () => unSuspendUser(user.id),
                                'Activate',
                                'Are you sure you want to activate the user?',
                            )}
                            className="btn btn-icon btn-mini"
                          >
                            <i className="icon-lock-open" />
                          </a>
                        )}

                        <ButtonDelete
                          onClick={() => confirmDelete(async () => {
                            await usersService.deleteUser(user.id);
                            await loadSchoolStats();
                            await resetList();
                          })}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />

          {(isFetching) && <div style={{padding: '30px 0 60px'}}><LoadingIndicator/></div>}
        </div>
      </div>
    </PageView>
  );
};
