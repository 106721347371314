import {AxiosInstance} from 'axios';
import {Certificate} from 'api/certificates/Certificate';

/**
 * Provides API certificate functions
 */
export class CertificatesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) { }

  /**
   * Fetches a user's certificates
   *
   * @param {number} userId
   */
  async fetchUserCertificates(userId: number): Promise<Certificate[]> {
    const response = await this.httpClient.post('/users/certs', {id: userId});
    return response.data.list;
  }
}
