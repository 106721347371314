import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {localizeText} from 'i18n/localize';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Achievement} from 'api/achievements/Achievement';
import {useLogger} from 'logging/logging';
import {useApiContext} from 'api/ApiContext';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {Lesson} from 'api/lessons/Lesson';
import {Config} from 'Config';
import {LessonDetailsModal} from 'views/lessons/LessonDetailsModal';

/**
 * Modal to view details of one achievement
 */

export interface AchievementDetailsModalProps {
  achievementId: number,
  onCloseRequested?: () => any,
}

export const AchievementDetailsModal = (
    props: AchievementDetailsModalProps,
) => {
  const logger = useLogger(AchievementDetailsModal.name);
  const {achievementsService} = useApiContext();

  const [achievement, setAchievement] =
    useState<Achievement | undefined>(undefined);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [viewedLessonId, setViewedLessonId] =
    useState<number | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const achievementDetails =
        await achievementsService.fetchAchievementDetails(props.achievementId);
      setAchievement(achievementDetails.entity);
      setLessons(achievementDetails.lessons);
      logger.debug('Fetched achievement', achievementDetails);
      setIsFetching(false);
    })();
  }, []);

  if (isFetching) {
    return <Modal
      body={<LoadingIndicator/>}
      onCloseRequested={props.onCloseRequested}
    />;
  }

  if (!achievement && !isFetching) {
    return <Modal
      body={<p>Failed to load achievement.</p>}
      onCloseRequested={props.onCloseRequested}
    />;
  }

  return (
    <Modal
      className={'wide tall'}
      title={achievement?.name}
      onCloseRequested={props.onCloseRequested}
      buttons={
        <>
          {achievement?.cert &&
          <AccessControlled feature={AppFeature.EditSchoolContent}>
            <button className="btn btn-success" aria-label={localizeText('View Certificate')}>
              <i className="icon-zoom-in" />
              {' '}
              <span>{localizeText('View Certificate')}</span>
            </button>
          </AccessControlled>
          }
        </>
      }
      body={
        <>
          {viewedLessonId &&
            <LessonDetailsModal
              aria-label="View Lesson"
              lessonId={viewedLessonId}
              onCloseRequested={() => setViewedLessonId(undefined)}
            />
          }

          <div className="description padding10">
            <img
              alt={''}
              src={achievement?.image ?
                achievement.image + '100/100' :
                Config.defaultSchoolImage
              }
              className="circle-50 gray pull-left"
              style={{marginRight: 10}}
            />
            <div
              className="html"
              dangerouslySetInnerHTML={{__html: achievement?.description ?? ''}}
            />
          </div>

          <div className="clearfix"/>

          <div className="row-fluid padding10">
            {achievement?.validity &&
              <>
                <span className="row-label">{localizeText('Validity')}:</span>
                {' '}
                <span>{achievement.validity} days</span>
              </>
            }

            {achievement?.creditHours &&
              <>
                <span className="row-label">{localizeText('Credit Hours')}:</span>
                {' '}
                <span>{achievement.creditHours} hours</span>
              </>
            }

            {achievement?.category &&
              <>
                <span className="row-label">{localizeText('Category')}:</span>
                <span>
                  <span
                    className={`icon cat ${achievement.category.icon}`}
                    style={{backgroundColor: achievement.category.color}}
                  />
                  {achievement.category.name}
                </span>
              </>
            }
          </div>

          <div className="row-fluid block no-space">
            <div className="header text-center">
              {localizeText('Achievements')}
            </div>
          </div>

          <div className="row-fluid block no-space">
            <div className="span6">
              <div className="header text-center">
                {localizeText('Skills')}
              </div>
              <div className="body">
                {achievement?.skills.map((skill) => (
                  <div
                    key={skill.id}
                    className="pill"
                    style={{borderColor: skill.category?.color}}
                  >
                    <div
                      className="circle-20"
                      style={{
                        float: 'right',
                        background: skill.category?.color,
                      }}
                    >
                      <span className={`icon ${skill.category?.icon}`} />
                    </div>
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>

            <div className="span6">
              <div className="header text-center">
                {localizeText('Lessons')}
              </div>
              <div className="body">
                {lessons.map((lesson) => (
                  <div
                    key={lesson.id}
                    className="pill pointer"
                    onClick={() => setViewedLessonId(lesson.id)}
                  >
                    {lesson.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};
