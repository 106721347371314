import React from 'react';

export interface FileViewerProps {
  url: string;
  noPadding?: boolean;
}

export const FileViewer = (props: FileViewerProps) => {
  const padding = props.noPadding ? 0 : 10;

  return (
    <div className="iframe-container" style={{top: padding, left: padding, bottom: padding, right: padding}}>
      <iframe className="link-frame"
        src={'/viewer.html#' + props.url}
        width="100%" height="500"
        aria-label="File view"
      />
    </div>
  );
};
