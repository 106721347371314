import React, {useEffect, useState} from 'react';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {Link, useHistory} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {useApiContext} from 'api/ApiContext';
import {useAuthContext} from 'contexts/AuthContext';
import {WelcomeModal} from 'views/welcome/WelcomeModal';
import {EditProfileModal} from 'views/profile/EditProfileModal';
import {School} from 'api/schools/School';

export const MainMenu = () => {
  const {push: pushRoute} = useHistory();
  const {logoutUser} = useAuthContext();
  const {productsService, schoolsService} = useApiContext();

  const [showExpandedSchools, setShowExpandedSchools] =
    useState<boolean>(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [schools, setSchools] = useState<School[]>([]);

  useEffect(() => {
    (async () => {
      setSchools(await schoolsService.fetchSchools());
    })();
  }, [schoolsService.fetchSchools]);

  return (
    <>
      {showWelcomeModal &&
        <WelcomeModal
          overrideUserHideRequest={true}
          onCloseRequested={() => setShowWelcomeModal(false)}
        />
      }

      {showProfileModal &&
        <EditProfileModal
          onCloseRequested={() => setShowProfileModal(false)}
        />
      }

      <ul>
        <AccessControlled
          feature={AppFeature.DashboardAdmin}
          unauthorizedView={
            <li>
              <a onClick={() => setShowWelcomeModal(true)} aria-label="Welcome">
                <i className="icon-cog-asterisk"/>
                {' '}
              Welcome
              </a>
            </li>
          }
        >
          <li>
            <Link to={AppRoute.SystemContext + AppRoute.SystemOverview} aria-label="Administration">
              <i className="icon-cog-alt"/>
              {' '}
            Administration
            </Link>
          </li>
        </AccessControlled>

        {schools.length > 1 &&
        <AccessControlled feature={AppFeature.ChangeSchool}>
          <li>
            <a
              onClick={
                () => setShowExpandedSchools((current) => !current)
              }
              aria-label="Change Campus" >
              <i className={'icon-users'} />
              {' '}
              Change Campus
            </a>

            <ul
              style={{
                maxHeight: showExpandedSchools ? 200 : 0,
                overflow: 'auto',
              }}
            >
              {schools.sort((a, b) => a.name> b.name? 1 : -1).map((school) => (
                <li key={school.id}>
                  <Link
                    to={AppRoute.SchoolContext.replace(
                        ':schoolId',
                        school.id.toString(),
                    )} aria-label={school.name}>
                    {school.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </AccessControlled>
        }

        <AccessControlled feature={AppFeature.CreateProduct}>
          <li>
            <a onClick={() => productsService.updateProducts()} aria-label="update products">
              <i className='icon-arrows-cw'/>
              {' '}
          Update Products
            </a>
          </li>
        </AccessControlled>

        <li>
          <a onClick={() => {
            setShowProfileModal(true);
          }} aria-label="Edit Profile">
            <i className='icon-user'/>
            {' '}
            Edit Profile
          </a>
        </li>

        <li>
          <Link to={AppRoute.DashboardContext + AppRoute.NotificationsSettings} aria-label="Email Notifications">
            <i className='icon-comment-empty'/>
            {' '}
          Email Notifications
          </Link>
        </li>

        <li>
          <a onClick={async () => {
            await logoutUser();
            await pushRoute(AppRoute.Login);
          }} aria-label="Log Out">
            <i className='icon-off'/>
            {' '}
          Logout
          </a>
        </li>
      </ul>
    </>
  );
};
