import React, {FormEvent, useState} from 'react';
import {Modal} from 'components/Modal';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {InstructorNote} from 'api/users/InstructorNote';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {UserProperty} from 'api/users/UserProperty';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {Tooltip} from 'components/Tooltip';

/**
 * Internal component used for the repeating note inputs
 */
interface NoteInputProps {
  value: {title: string, note: string},
  onChange: (newNote: {title: string, note: string}) => any,
  onDelete: () => any,
}

const NoteInput = (props: NoteInputProps) => {
  const titleLength = props.value.title.length;
  const noteLength = props.value.note.length;

  return (
    <div className="row-fluid pack" style={{padding: '10px 0'}}>
      <div
        className="buttons btn-group pull-right"
        style={{marginRight: 5}}
      >
        {(noteLength > 0 || titleLength > 0) &&
          <Tooltip title={__('Delete Note')}>
            <a className="btn btn-icon btn-mini" onClick={props.onDelete}>
              <i className="icon-trash" />
            </a>
          </Tooltip>
        }
      </div>
      <div className="span10">
        <label>
          <span style={{display: 'none'}}>Title</span>
          <input
            type="text"
            className="span12"
            maxLength={255}
            placeholder={__('Title')}
            value={props.value.title}
            onChange={(e) => props.onChange({
              title: e.target.value,
              note: props.value.note,
            })}
          />
        </label>
      </div>
      <div className="span10" style={{position: 'relative'}}>
        <label>
          <span style={{display: 'none'}}>Note</span>
          <input
            className="span12"
            type="text"
            maxLength={255}
            placeholder={__('Note')}
            style={{paddingRight: 50}}
            value={props.value.note}
            onChange={(e) => props.onChange({
              title: props.value.title,
              note: e.target.value,
            })}
          />
        </label>

        <span
          style={{position: 'absolute', top: 5, right: 10}}
          className={classNames(
              'badge',
              {'btn-success': noteLength < 200},
              {'btn-warning': noteLength >= 200 && noteLength < 255},
              {'btn-danger': noteLength >= 255},
          )}
        >
          {255 - noteLength}
        </span>
      </div>
    </div>
  );
};

/**
 * A modal to add notes to instructors
 */

export interface InstructorNotesModalProps {
  instructorId: number,
  initialNotes?: InstructorNote[],
  onCloseRequested?: () => any,
  onComplete?: () => any,
}

export const InstructorNotesModal = (props: InstructorNotesModalProps) => {
  const logger = useLogger(InstructorNotesModal.name);
  const {usersService} = useApiContext();

  const [notes, setNotes] =
    useState<UserProperty[]>([
      ...props.initialNotes?.map((n) => ({
        id: n.id,
        title: n.key,
        note: n.value,
        removed: false,
      })) ?? [],
      {title: '', note: '', removed: false},
    ]);

  const nonRemovedNotes = notes.filter((note) => !note.removed);

  const updateNote =
    (index: number, newNote: {title: string, note: string}) => {
      setNotes((current) => {
        const copy = [...current];
        copy[index] = {...current[index], ...newNote, removed: false};
        return copy;
      });
      if (index === notes.length - 1) {
        setNotes((current) => [
          ...current,
          {title: '', note: '', removed: false},
        ]);
      }
    };

  const deleteNote = (index: number) => {
    setNotes((current) => {
      const copy = [...current];
      copy[index].removed = true;
      return copy;
    });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    const sanitizedNotes = notes.filter((note) =>
      note.title.trim().length > 1 && note.note.trim().length > 1,
    );

    const properties = sanitizedNotes.map((note) => {
      const props: any = {
        id: note.id,
        key: note.title,
        value: note.note,
      };
      if (note.removed) {
        props.removed = true;
      }
      return props;
    });

    const saveResult = await usersService.saveUserProperties(props.instructorId, properties);
    logger.debug('Saved user properties', saveResult);
    props.onComplete?.();
  };

  return (
    <Modal
      title={'User Notes'}
      className={'tall'}
      onCloseRequested={props.onCloseRequested}

      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          form={'notesForm'}
          type={'submit'}
        />
      }

      body={
        <form onSubmit={submit} id={'notesForm'}>
          {nonRemovedNotes.map((note, i) => (
            <NoteInput
              key={i}
              value={note}
              onChange={(note) => updateNote(i, note)}
              onDelete={() => deleteNote(i)}
            />
          ))}
        </form>
      }

      footer={<InputPlaceholderMirror/>}
    />
  );
};
