import React, {useEffect, useState} from 'react';
import {CreateProductDto, Product, ProductWithRelations} from 'api/products/Product';
import {Modal} from 'components/Modal';
import {DynamicSelect} from 'components/forms/DynamicSelect';
import {useApiContext} from 'api/ApiContext';
import {__, localizeText} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {useLogger} from 'logging/logging';
import {useNotificationContext} from 'contexts/NotificationContext';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import classNames from 'classnames';
import {Category} from 'api/categories/Category';
import {Lesson} from 'api/lessons/Lesson';
import {Achievement} from 'api/achievements/Achievement';
import {SurveySummary} from 'api/surveys/Survey';
import {Skill} from 'api/skills/Skill';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface ProductInputModalProps {
  productId?: number,
  onCancel?: () => any,
  onComplete?: (product?: Product) => any,
}

export const ProductInputModal = (props: ProductInputModalProps) => {
  const logger = useLogger(ProductInputModal.name);
  const {
    lessonsService,
    skillsService,
    achievementsService,
    surveysService,
    productsService,
    categoriesService,
  } = useApiContext();
  const {showErrorNotification} = useNotificationContext();

  const [initialProduct, setInitialProduct] = useState<ProductWithRelations | undefined>(undefined);
  const [isFetchingInitial, setIsFetchingInitial] = useState<boolean>(true);
  const [newProduct, setNewProduct] = useState<CreateProductDto>({
    name: '',
    description: '',
    surveys: [],
    ilries: [],
    categories: [],
    lessons: [],
    achievements: [],
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [surveys, setSurveys] = useState<SurveySummary[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const isEditing = props.productId !== undefined;
  const modalTitle = isEditing ? 'Edit Product' : 'Add Product';

  const fetchInitialProduct = async () => {
    if (!props.productId) {
      setIsFetchingInitial(false);
      return;
    }

    setIsFetchingInitial(true);
    const fetchedProduct = await productsService.fetchProduct(props.productId);
    logger.debug('Fetched product', fetchedProduct);
    setInitialProduct(fetchedProduct);
    setIsFetchingInitial(false);
  };

  const submit = async () => {
    if (!validate()) {
      showErrorNotification(__('Error'), __('form.invalid'));
      return;
    }

    setIsSubmitting(true);

    let saveResult;
    if (isEditing && props.productId) {
      saveResult = await productsService.updateProduct({
        ...newProduct,
        id: props.productId,
        categories,
        lessons,
        achievements,
        surveys,
        ilries: skills,
      });
      logger.debug('Updated product', saveResult);
    } else {
      saveResult = await productsService.createProduct({
        ...newProduct,
        categories,
        lessons,
        achievements,
        surveys,
        ilries: skills,
      });
      logger.debug('Created product', saveResult);
    }

    if (saveResult.ok) {
      props.onComplete?.(saveResult.entity);
    } else {
      showErrorNotification(__('Error'), __('Failed to save product'));
    }

    setIsSubmitting(false);
  };

  const validate = () => {
    setErrors({});
    let isValid = true;

    if (newProduct.name.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, name: true}));
    }
    if (newProduct.description.trim().length < 1) {
      isValid = false;
      setErrors((current: any) => ({...current, description: true}));
    }

    return isValid;
  };

  useEffect(() => {
    (async () => {
      await fetchInitialProduct();
    })();
  }, [props.productId]);

  useEffect(() => {
    setNewProduct({
      name: initialProduct?.name ?? '',
      description: initialProduct?.description ?? '',
      surveys: initialProduct?.surveys ?? [],
      ilries: initialProduct?.ilries ?? [],
      categories: initialProduct?.categories ?? [],
      lessons: initialProduct?.lessons ?? [],
      achievements: initialProduct?.achievements ?? [],
    });
    setSurveys(initialProduct?.surveys ?? []);
    setSkills(initialProduct?.ilries ?? []);
    setCategories(initialProduct?.categories ?? []);
    setLessons(initialProduct?.lessons ?? []);
    setAchievements(initialProduct?.achievements ?? []);
  }, [initialProduct]);

  useEffect(() => {
    validate();
  }, [newProduct]);

  return (
    <Modal
      title={modalTitle}
      onCloseRequested={props.onCancel}
      className={'tall'}

      buttons={
        <Button variant={ButtonVariant.Confirm} onClick={submit} disabled={isSubmitting} />
      }

      body={
        <form name="form.edit">
          {isFetchingInitial && <LoadingIndicator/>}

          {!isFetchingInitial &&
          <>
            <div className="row-fluid">
              <input
                placeholder={localizeText('Product')}
                value={newProduct.name}
                onChange={(e) => setNewProduct((current) => ({
                  ...current,
                  name: e.target.value,
                }))}
                type="text"
                className={classNames('span12', {'invalid': errors.name})}
                required
              />
            </div>
            <div className="row-fluid">
              <RichTextInput
                placeholder={__('Description')}
                value={newProduct.description}
                required={errors.description}
                onChange={(newDescription) => setNewProduct((current) => ({
                  ...current,
                  description: newDescription,
                }))}
              />
            </div>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<SurveySummary>
                placeholder={localizeText('Surveys')}
                multiple={true}
                fetch={(filter) => surveysService.fetchSurveySummaries({search: filter.search})}
                value={surveys}
                onSelect={(newSurveys) => setSurveys(newSurveys)}
                isOptionSelected={(survey) => surveys.some((s) => s.id === survey.id)}
              />
            </div>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<Category>
                placeholder={localizeText('Categories')}
                multiple={true}
                fetch={(filter) => categoriesService.fetchCategories({search: filter.search})}
                value={categories}
                onSelect={(newCategories) => setCategories(newCategories)}
                isOptionSelected={(category) => categories.some((c) => c.id === category.id)}
              />
            </div>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<Lesson>
                placeholder={localizeText('Lessons')}
                multiple={true}
                fetch={(filter) => lessonsService.fetchLessons(filter)}
                value={lessons}
                onSelect={(newLessons) => setLessons(newLessons)}
                isOptionSelected={(lesson) => lessons.some((l) => l.id === lesson.id)}
              />
            </div>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<Skill>
                placeholder={localizeText('Skills')}
                multiple={true}
                fetch={(filter) => skillsService.fetchSkills(filter)}
                value={skills}
                onSelect={(newSkills) => setSkills(newSkills)}
                isOptionSelected={(skill) => skills.some((s) => s.id === skill.id)}
              />
            </div>
            <div className="row-fluid" style={{marginBottom: 10}}>
              <DynamicSelect<Achievement>
                placeholder={localizeText('Badges')}
                multiple={true}
                fetch={(filter) => achievementsService.fetchAchievements(filter.search)}
                value={achievements}
                onSelect={(newAchievements) => setAchievements(newAchievements)}
                isOptionSelected={(achievement) => achievements.some((a) => a.id === achievement.id)}
              />
            </div>
          </>
          }
        </form>
      }

      footer={isFetchingInitial ? <></> : <InputPlaceholderMirror/>}
    />
  );
};
