import {Category} from 'api/categories/Category';
import {QuizQuestionWithAnswer} from 'api/quizzes/QuizSolution';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';

interface WeightedCategoryGroup {
  category: Category,
  weight: number,
  questionIndices: number[],
}

/**
 * Builds survey results as questions are completed one-by-one.
 */
export class SurveyResultsBuilder {
  private questions: QuizQuestionWithAnswer[];
  private hopeCategoryGroups: WeightedCategoryGroup[] = [];
  private challengeCategoryGroups: WeightedCategoryGroup[] = [];
  private currentQuestionIndex = 0;
  private answeredQuestionCount = 0;

  /**
   * Constructs a new survey results builder.
   */
  constructor(questions: QuizQuestionWithAnswer[]) {
    this.questions = questions;

    questions.forEach((question, questionIndex) => {
      // first two questions are always asked once at the beginning of the survey
      if (questionIndex <= 1) {
        return;
      }

      if (question.challenge) {
        const existingCategoryGroup = this.challengeCategoryGroups.find(
            (group) => group.category.id === question.category.id,
        );
        if (existingCategoryGroup) {
          existingCategoryGroup.questionIndices.push(questionIndex);
        } else {
          this.challengeCategoryGroups.push({
            category: question.category,
            questionIndices: [questionIndex],
            weight: 0,
          });
        }
      } else {
        const existingCategoryGroup = this.hopeCategoryGroups.find(
            (group) => group.category.id === question.category.id,
        );
        if (existingCategoryGroup) {
          existingCategoryGroup.questionIndices.push(questionIndex);
        } else {
          this.hopeCategoryGroups.push({
            category: question.category,
            questionIndices: [questionIndex],
            weight: 0,
          });
        }
      }
    });
  }

  /**
   * Gets the completed survey results
   */
  getResults() {
    return this.questions;
  }

  /**
   * Commits an answer to the current question and returns the next question
   */
  getNextQuestion(completedQuestion: QuizQuestionWithAnswer): QuizQuestionWithAnswer | undefined {
    this.questions[this.currentQuestionIndex] = completedQuestion;
    this.weighCategoriesWithCompletedQuestion(
        completedQuestion,
      completedQuestion.challenge ? this.challengeCategoryGroups : this.hopeCategoryGroups,
    );
    ++this.answeredQuestionCount;

    // the first two questions are always performed in order
    if (this.currentQuestionIndex === 0) {
      return this.questions[++this.currentQuestionIndex];
    }

    // survey completes after 14 questions
    if (this.answeredQuestionCount > 14) {
      return undefined;
    }

    if (this.currentQuestion().challenge) {
      return this.getQuestionFromHighestWeightedCategory(this.hopeCategoryGroups);
    } else {
      return this.getQuestionFromHighestWeightedCategory(this.challengeCategoryGroups);
    }
  }

  /**
   * Gets the current active question
   */
  private currentQuestion() {
    return this.questions[this.currentQuestionIndex];
  }

  /**
   * Gets hope category groups sorted by weight
   */
  private getWeightSortedHopeGroups() {
    return this.hopeCategoryGroups.sort((a, b) => a.weight < b.weight ? -1 : 1);
  }

  /**
   * Gets hope category groups sorted by weight
   */
  private getWeightSortedChallengeGroups() {
    return this.challengeCategoryGroups.sort((a, b) => a.weight < b.weight ? -1 : 1);
  }

  /**
   * Chooses a question from the currently highest weighted category in the given category groups
   */
  private getQuestionFromHighestWeightedCategory(
      categoryGroups: WeightedCategoryGroup[],
  ): QuizQuestionWithAnswer | undefined {
    const sortedGroups = categoryGroups.sort((a, b) => a.weight > b.weight ? -1 : 1);
    let groupIndex = 0;
    do {
      const highestWeightedGroup = sortedGroups[groupIndex];
      if (highestWeightedGroup.questionIndices.length > 0) {
        const questionIndex = highestWeightedGroup.questionIndices[0];
        highestWeightedGroup.questionIndices.shift();
        return this.questions[questionIndex];
      }
      ++groupIndex;
    } while (groupIndex < sortedGroups.length);
  }

  /**
   * Adds the weights of a completed question's answers to the weighted category groups
   */
  private weighCategoriesWithCompletedQuestion(
      question: QuizQuestionWithAnswer,
      categoryGroups: WeightedCategoryGroup[],
  ) {
    if ([QuizQuestionType.Bullets, QuizQuestionType.Multiple].includes(question.type) && !question.draggable) {
      question.answers.forEach((answer) => {
        categoryGroups.forEach((categoryGroup) => {
          if (categoryGroup.category.id === answer.category.id) {
            categoryGroup.weight += answer.weight;
          }
        });
      });
    } else if (question.type === QuizQuestionType.Bullets && question.draggable) {
      question.correct?.forEach((answer) => {
        categoryGroups.forEach((categoryGroup) => {
          if (categoryGroup.category.id === answer.category.id) {
            categoryGroup.weight += answer.weight;
          }
        });
      });
    } else if (question.type === QuizQuestionType.Order) {
      question.answers.forEach((answer) => {
        categoryGroups.forEach((categoryGroup) => {
          if (categoryGroup.category.id === answer.category.id) {
            categoryGroup.weight += answer.order > 0 ? answer.weight / answer.order : answer.weight;
          }
        });
      });
    } else if (question.type === QuizQuestionType.Match) {
      question.answers.forEach((answer) => {
        categoryGroups.forEach((categoryGroup) => {
          if (categoryGroup.category.id === answer.category.id) {
            categoryGroup.weight += answer.matchTo?.weight ?? 0;
          }
        });
      });
    }
  }
}
