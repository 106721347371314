import {AxiosInstance} from 'axios';
import {QuizQuestion} from 'api/quizzes/QuizQuestion';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import {CreateQuizDto, Quiz, QuizWithQuestions, UpdateQuizDto} from 'api/quizzes/Quiz';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {ApiRoute} from 'api/ApiRoute';
import {CreateQuizSolutionDto, QuizSolution} from 'api/quizzes/QuizSolution';

/**
 * Provides API quiz functions
 */
export class QuizzesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Determines if the given question should display a checkbox
   *
   * @param {QuizQuestion} question
   * @return {boolean}
   */
  questionHasCheckbox(question: QuizQuestion): boolean {
    return ((
      question.type === QuizQuestionType.Bullets ||
      question.type === QuizQuestionType.Multiple
    ) && !question.draggable);
  }

  /**
   * Fetches quizzes from the API
   *
   * @param {string} searchFilter
   * @param {number} start
   * @param {number} end
   * @return {Quiz[]}
   */
  async fetchQuizzes(
      searchFilter = '',
      start = 0,
      end = 20,
  ): Promise<Quiz[]> {
    const response = await this.httpClient.post('/quizzes/list', {
      filter: {
        search: searchFilter,
      },
      start,
      limit: end,
    });
    return response.data.list;
  }

  /**
   * Fetches a single quiz from the API
   *
   * @param {number} id
   * @return {QuizWithQuestions}
   */
  async fetchQuiz(id: number): Promise<QuizWithQuestions> {
    const response = await this.httpClient.post('/quizzes/get', {id});
    return response.data;
  }

  /**
   * Creates a new quiz
   *
   * @param {CreateQuizDto} quiz
   * @return {Promise<SuccessResponse<Quiz>>}
   */
  async createQuiz(quiz: CreateQuizDto): Promise<SuccessResponse<Quiz>> {
    const response = await this.httpClient.post(
        ApiRoute.Quizzes + ApiRoute.Save,
        quiz,
    );
    return response.data;
  }


  /**
   * Updates a quiz
   *
   * @param {UpdateQuizDto} quiz
   * @return {Promise<SuccessResponse<Quiz>>}
   */
  async updateQuiz(quiz: UpdateQuizDto): Promise<SuccessResponse<Quiz>> {
    const response = await this.httpClient.post(
        ApiRoute.Quizzes + ApiRoute.Save,
        quiz,
    );
    return response.data;
  }

  /**
   * Deletes a quiz in the API
   *
   * @param {number} quizId
   * @return {Promise<SuccessResponse<undefined>>}
   */
  async deleteQuiz(quizId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/quizzes/remove', {
      id: quizId,
    });
    return response.data;
  }

  /**
   * Submits a solution for a quiz
   *
   * @param {CreateQuizSolutionDto} solution
   */
  async submitQuizSolution(solution: CreateQuizSolutionDto) {
    const response = await this.httpClient.post('/quizzes/solve', solution);
    return response.data;
  }

  /**
   * Fetches a quiz solution by ID
   *
   * @param {number} id
   */
  async fetchQuizSolution(id: number): Promise<QuizSolution> {
    const response = await this.httpClient.post('/quizzes/solution', {id});
    return response.data;
  }

  /**
   * Saves a quiz draft
   *
   * @param {CreateQuizSolutionDto} draft
   * @param {number | undefined} draftId
   */
  async saveQuizDraft(draft: CreateQuizSolutionDto, draftId?: number) {
    const response = await this.httpClient.post(
        '/quizzes/saveDraft',
        {
          id: draftId,
          ...draft,
        },
    );
    return response.data;
  }

  /**
   * Fetches a quiz draft
   *
   * @param {number} quizId
   */
  async fetchQuizDraft(quizId: number): Promise<QuizSolution | undefined> {
    const response = await this.httpClient.post('/quizzes/getDraft', {quiz: quizId});
    if (response.data.id) {
      return response.data;
    } else {
      return undefined;
    }
  }

  /**
   * Deletes a quiz draft
   *
   * @param {number} draftId
   */
  async deleteDraft(draftId: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/quizzes/removeDraft', {id: draftId});
    return response.data;
  }
}
