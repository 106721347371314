import {AxiosInstance} from 'axios';

/**
 * Provides API logging functions
 */
export class ApiLoggingService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) { }

  /**
   * Adds a log entry to the API log
   *
   * @param {string} message
   */
  async logEntry(message: string): Promise<{}> {
    const response = await this.httpClient.post('/utils/log', {message});
    return response.data;
  }
}
