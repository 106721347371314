/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useLogger} from 'logging/logging';
import {Lesson} from 'api/lessons/Lesson';
import {LessonDetailsView} from 'views/lessons/LessonDetailsView';
import {SidePanelPageView} from 'layout/SidePanelPageView';
import {LessonInputView} from 'views/lessons/LessonInputView';
import {ContextMenu} from 'components/ContextMenu';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {ListFilter} from 'api/lists/ListFilter';
import {useApiContext} from 'api/ApiContext';
import {ApiRoute} from 'api/ApiRoute';
import {Category} from 'api/categories/Category';
import {SearchInput} from 'components/SearchInput';
import {Version} from 'api/versions/Version';
import {__} from 'i18n/localize';
import {confirmDelete} from 'components/confirmDelete';
import {useNotificationContext} from 'contexts/NotificationContext';

export const LessonsListView = () => {
  const logger = useLogger(LessonsListView.name);
  const {entityListService, categoriesService, versionsService, lessonsService} = useApiContext();
  const {showErrorNotification} = useNotificationContext();
  const lessonsPerPage = 40;

  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [viewedLesson, setViewedLesson] = useState<Lesson | undefined>(undefined);
  const [editLesson, setEditLesson] = useState<Lesson | undefined>(undefined);
  const [lessonsFilter, setLessonsFilter] = useState<ListFilter>({filters: {}});
  const [lessonsPage, setLessonPage] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [versions, setVersions] = useState<Version[]>([]);

  const lessonCategories: Category[] = [];
  lessons.forEach((lesson) => {
    if (!lessonCategories.find((c) => c.id === lesson.category.id)) {
      lessonCategories.push(lesson.category);
    }
  });

  const lessonsInCategory = (categoryId: number) => {
    return lessons.filter((l) => l.category.id === categoryId);
  };

  const loadCategories = async () => setCategories(await categoriesService.fetchCategories());
  const loadVersions = async () => setVersions(await versionsService.fetchVersions());


  const loadLessons = async () => {
    const fetchedLessons = await entityListService.fetchEntityList(
        ApiRoute.Lessons, lessonsFilter,
    );
    setLessons(fetchedLessons);
    logger.debug('Fetched lessons', fetchedLessons);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([loadCategories(), loadVersions()]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadLessons();
    })();
  }, [lessonsFilter]);

  return (
    <SidePanelPageView
      panelView={
        <div className="view" style={{overflowY: 'auto'}}>
          <div className="pane">
            <div className="body">
              <div className={'lesson-list'} style={{marginTop: 40}}>
                <>
                  {lessonCategories.map((category) => (
                    <div key={category.id}>
                      <div className={'circle-30'} style={{background: category.color}}>
                        <span className={classNames('icon', category.icon)} />
                      </div>
                      <div className={'category-title'}>{category.name}</div>
                      {lessonsInCategory(category.id).map((lesson) => (
                        <div
                          key={lesson.id}
                          className={'lesson'}
                          onClick={() => {
                            if (editLesson) {
                              setEditLesson(lesson);
                            } else {
                              setViewedLesson(undefined);
                              setViewedLesson(lesson);
                            }
                          }}
                        >
                          <div className="name">
                            {lesson.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              </div>
            </div>
          </div>
        </div>
      }

      mainView={
        <div className={'lesson-view'}>
          <ContextMenu>
            <SearchInput
              value={lessonsFilter.search}
              onChange={(newValue) => setLessonsFilter((current) => ({
                ...current,
                search: newValue,
              }))}
            />

            <i className="icon icon-tag"/>
            <select
              className="form-control"
              value={lessonsFilter.filters.category}
              onChange={(e) => setLessonsFilter((current) => ({
                ...current,
                filters: {
                  ...current.filters,
                  category: e.target.value,
                },
              }))}
            >
              <option value="">{__('- Category -')}</option>
              {categories.map((category) => (
                <option
                  key={category.id}
                  value={category.id}
                  dangerouslySetInnerHTML={
                    {__html: Array(category.level).join('&nbsp;&nbsp;&nbsp;&nbsp;') + category.name}
                  }
                />
              ))}
            </select>

            <i className="icon icon-flag"/>
            <select
              className="form-control"
              value={lessonsFilter.filters.version}
              onChange={(e) => setLessonsFilter((current) => ({
                ...current,
                filters: {
                  ...current.filters,
                  version: e.target.value,
                },
              }))}
            >
              <option value="">{__('- Version -')}</option>
              {versions.map((version) => (
                <option key={version.id} value={version.id}>
                  {version.name}
                </option>
              ))}
            </select>

            <Button
              variant={ButtonVariant.Add}
              onClick={() => {
                setViewedLesson(undefined);
                setEditLesson({} as Lesson);
              }}
            />
          </ContextMenu>

          {!viewedLesson &&
          <div
            className="lesson-editor"
            style={{
              backgroundImage:
                'linear-gradient(to right, #e5e5e5 0%, #f2f2f2 7%)',
            }}
          >
            <div className="instructions">
              <h1> Lessons </h1>
              <ul>
                <li>Select a lesson from the menu on the left.</li>
                <li>Scroll down the lesson page to review its action steps,
                  resources, and other information related to the lesson.
                </li>
                <li>Click the “Assign Lesson” button above to configure and
                  assign
                  the lesson to an individual or classroom
                </li>
                <li> Use the navigation menu below to move across the platform
                  within Lessons, Activity Log, Classes, and Participants
                </li>
              </ul>
              <div className="tip">TIP: click the Category icon on the top right
                to quick search a lesson based on its category (to reset back to
                the full menu list, select &ldquo;- Category -&rdquo;)
              </div>
            </div>
            <div style={{clear: 'both'}} />
          </div>
          }

          {viewedLesson &&
            <LessonDetailsView
              lessonId={viewedLesson.id}
              onEditRequested={() => {
                setViewedLesson(undefined);
                setEditLesson(viewedLesson);
              }}
              onDeleteRequested={() => confirmDelete(async () => {
                const result = await lessonsService.deleteLesson(viewedLesson.id);
                if (result.ok) {
                  setLessons((current) => current.filter((lesson) => lesson.id !== viewedLesson.id));
                  setViewedLesson(undefined);
                } else {
                  showErrorNotification(__('Error'), __('Failed to delete lesson'));
                }
              })}
            />
          }

          {editLesson &&
            <LessonInputView
              lessonId={editLesson.id}
              onCancel={() => setEditLesson(undefined)}
              onCompleted={async (lesson) => {
                await loadLessons();
                setEditLesson(undefined);
                if (lesson) {
                  setViewedLesson(lesson);
                }
              }}
            />
          }
        </div>
      }
    />
  );
};
