import React, {
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import {AccessControlService} from 'access-control/AccessControlService';
import {DefaultAccessControlList} from 'access-control/DefaultAccessControlList';

/**
 * Provides access control services to consuming components
 */

export interface AccessControlContextInterface {
  accessControlService: AccessControlService,
}

export const AccessControlContext = createContext<AccessControlContextInterface | undefined>(undefined);

export const useAccessControlContext = () => {
  const context = useContext(AccessControlContext);
  if (context === undefined) {
    throw new Error(
        `${useAccessControlContext.name} must be within an AccessControlContext provider`,
    );
  }
  return context;
};

export const AccessControlContextProvider = (
    props: PropsWithChildren<{accessControlService?: AccessControlService}>,
) => {
  return (
    <AccessControlContext.Provider
      value={{accessControlService: props.accessControlService ?? new AccessControlService(DefaultAccessControlList)}}
    >
      {props.children}
    </AccessControlContext.Provider>
  );
};
