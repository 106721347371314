import React, {PropsWithChildren, useEffect} from 'react';
import 'intersection-observer';
import {useInView} from 'react-intersection-observer'; // polyfill for older browsers (eg. IE11)

/**
 * Provides a callback when the component is scrolled into view
 */

export interface InViewObserverProps {
  onInView?: () => any,
}

export const InViewObserver = (props: PropsWithChildren<InViewObserverProps>) => {
  const {ref, inView} = useInView();

  useEffect(() => {
    (async () => {
      if (inView) {
        props.onInView?.();
      }
    })();
  }, [inView]);

  return (
    <div
      ref={ref}
      style={{height: 1, width: 1, visibility: 'hidden'}}
    />
  );
};
