import {AppRoute} from 'routing/AppRoute';
import {UserRoleType} from 'api/users/UserRole';

/**
 * Gets the route for a user profile
 *
 * @param {number} id
 * @return {string}
 */
export function getProfileRoute(id: number): string {
  return AppRoute.ProfileContext.replace(':profileId', id.toString());
}

/**
 * Gets the route for a school
 *
 * @param {number} id
 * @return {string}
 */
export function getSchoolRoute(id: number): string {
  return AppRoute.SchoolContext.replace(':schoolId', id.toString());
}

/**
 * Gets the home route for the given user role
 *
 * @param {UserRoleType} roleType
 * @return {string}
 */
export const homeRouteForRole = (roleType: UserRoleType): string => {
  switch (roleType) {
    case UserRoleType.SystemAdmin:
      return AppRoute.SystemContext + AppRoute.SystemOverview;
    case UserRoleType.Admin:
      return AppRoute.SystemContext + AppRoute.SystemOverview;
    case UserRoleType.Teacher:
      return AppRoute.DashboardContext + AppRoute.Lessons;
    default:
      return AppRoute.DashboardContext + AppRoute.Dreams;
  }
};
