import React, {useEffect, useState} from 'react';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {useParams} from 'react-router-dom';
import {School} from 'api/schools/School';
import {LoadingIndicator} from 'components/LoadingIndicator';
import classNames from 'classnames';
import {__} from 'i18n/localize';
import {FileUploadInput} from 'components/forms/FileUploadInput';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {FullColorPicker} from 'components/FullColorPicker';
import {useNotificationContext} from 'contexts/NotificationContext';

export const SchoolConfigurationView = () => {
  const logger = useLogger(SchoolConfigurationView.name);
  const {schoolId}: any = useParams();
  const {schoolsService} = useApiContext();
  const {showSuccessNotification} = useNotificationContext();

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [schoolUnderEdit, setSchoolUnderEdit] = useState<School>();

  const save = async () => {
    setIsSaving(true);
    if (schoolUnderEdit) {
      const saveResult = await schoolsService.saveSchoolConfig(schoolUnderEdit);
      showSuccessNotification(__('Success'), __( 'Customizations successfully saved'));
      logger.debug('Saved school config', saveResult);
    }
    setIsSaving(false);
  };

  const loadSchool = async () => {
    const fetchedSchool = await schoolsService.fetchSchool(schoolId);
    setSchoolUnderEdit(fetchedSchool);
    logger.debug('Fetched school', fetchedSchool);
  };

  useEffect(() => {
    (async () => {
      setIsInitializing(true);
      await loadSchool();
      setIsInitializing(false);
    })();
  }, []);

  if (isInitializing) {
    return <LoadingIndicator/>;
  }

  if (!schoolUnderEdit) {
    return <p>Failed to load school.</p>;
  }

  return (
    <PageView>
      <ContextMenu>
        <a onClick={save} className={classNames('btn btn-success', {'disabled': isSaving})}>
          <i className="icon-ok-circled" />
        </a>
      </ContextMenu>

      <form name="form.edit" style={{maxWidth: 700, margin: '0 auto'}}>
        <div className="row-fluid">
          <div className="span3">
            <img
              src={schoolUnderEdit.image ? schoolUnderEdit.image.url + '100/100' : '/images/logo.jpg'}
              className="circle-50 gray"
              style={{width: 140, height: 140, marginBottom: 15}}
            />
          </div>
          <div className="span9">
            <div className="row-fluid">
              <input
                value={schoolUnderEdit.name}
                onChange={(e) => setSchoolUnderEdit((current) => {
                  return current ? {...current, name: e.target.value} : undefined;
                })}
                type="text"
                className="span12"
                required
                placeholder={__('Campus Name')}
              />
            </div>
            <div className="row-fluid">
              <FileUploadInput
                buttonText={__('Add Logo')}
                initialFiles={schoolUnderEdit.image ? [schoolUnderEdit.image] : []}
                onChange={(newFiles) => setSchoolUnderEdit((current) => {
                  return !current ? undefined : {
                    ...current,
                    image: newFiles.length > 0 ? newFiles[0] : undefined,
                  };
                })}
              />
            </div>
            <div className="row-fluid">
              <FileUploadInput
                buttonText={__('Add Campus CSS')}
                initialFiles={schoolUnderEdit.style ? [schoolUnderEdit.style] : []}
                onChange={(newFiles) => setSchoolUnderEdit((current) => {
                  return !current ? undefined : {
                    ...current,
                    style: newFiles.length > 0 ? newFiles[0] : undefined,
                  };
                })}
              />
            </div>
            <div className="row-fluid">
              <input
                value={schoolUnderEdit.config.copyright}
                onChange={(e) => setSchoolUnderEdit((current) => {
                  return !current ? undefined : {
                    ...current,
                    config: {...current.config, copyright: e.target.value},
                  };
                })}
                type="text"
                className="span12"
                placeholder={__('Copyright Message')}
              />
            </div>
            {schoolUnderEdit.key &&
              <div className="row-fluid spacer">
                <div className="span12 btn-group">
                  <a
                    className="btn btn-info span4"
                    ui-sref="register({ key: data.key, p: 1 })"
                    target="_blank"
                  >
                    {__('Registration Page')}
                  </a>
                  <a
                    className="btn btn-info span4"
                    ui-sref="login({ key: data.key })"
                    target="_blank"
                  >
                    {__('Login Page')}
                  </a>
                </div>
              </div>
            }
            <div id="description" className=" row-fluid bottom-offset-10" placeholder={__('Description')}>
              <RichTextInput
                placeholder={__('Intro Text')}
                value={schoolUnderEdit.description}
                onChange={(newValue) => setSchoolUnderEdit((current) => {
                  return !current ? undefined : {...current, description: newValue};
                })}
              />
            </div>
          </div>
        </div>

        <div className="row-fluid">
          <div className=" span1">
            <div className="circle-30 pull-right" style={{background: schoolUnderEdit.config.studentColor}} />
          </div>
          <div className={'span11'}>
            <FullColorPicker
              label={__('Student Header Color')}
              value={schoolUnderEdit.config.studentColor}
              onChange={(newColor) => setSchoolUnderEdit((current) => {
                return !current ? undefined : {
                  ...current,
                  config: {...current.config, studentColor: newColor},
                };
              })}
            />
          </div>
        </div>
        <div className="row-fluid">
          <div className=" span1">
            <div className="circle-30 pull-right" style={{background: schoolUnderEdit.config.teacherColor}} />
          </div>
          <div className={'span11'}>
            <FullColorPicker
              label={__('Instructor Header Color')}
              value={schoolUnderEdit.config.teacherColor}
              onChange={(newColor) => setSchoolUnderEdit((current) => {
                return !current ? undefined : {
                  ...current,
                  config: {...current.config, teacherColor: newColor},
                };
              })}
            />
          </div>
        </div>
        <div className="row-fluid">
          <div className=" span1">
            <div className="circle-30 pull-right" style={{background: schoolUnderEdit.config.parentColor}} />
          </div>
          <div className={'span11'}>
            <FullColorPicker
              label={__('Manager Header Color')}
              value={schoolUnderEdit.config.parentColor}
              onChange={(newColor) => setSchoolUnderEdit((current) => {
                return !current ? undefined : {
                  ...current,
                  config: {...current.config, parentColor: newColor},
                };
              })}
            />
          </div>
        </div>
        <div className="row-fluid">
          <div className=" span1">
            <div className="circle-30 pull-right" style={{background: schoolUnderEdit.config.adminColor}} />
          </div>
          <div className={'span11'}>
            <FullColorPicker
              label={__('Admin Header Color')}
              value={schoolUnderEdit.config.adminColor}
              onChange={(newColor) => setSchoolUnderEdit((current) => {
                return !current ? undefined : {
                  ...current,
                  config: {...current.config, adminColor: newColor},
                };
              })}
            />
          </div>
        </div>
      </form>
    </PageView>
  );
};
