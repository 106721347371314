import React, {FormEvent, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {Course, CreateCourseDto} from 'api/courses/Course';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {InputPlaceholderMirror} from 'components/forms/InputPlaceholderMirror';
import {SwitchInput} from 'components/SwitchInput';
import {DateInput} from 'components/forms/DateInput';
import {CategoryPicker} from 'components/forms/CategoryPicker';
import {RichTextInput} from 'components/forms/RichTextInput/RichTextInput';
import {useApiContext} from 'api/ApiContext';
import classNames from 'classnames';

/**
 * A form to create/update a course's essential information
 */

export interface CourseInputModalProps {
  initialCourse?: Course,
  onCloseRequested?: () => any,
  onComplete?: () => any,
}

export const CourseInputModal = (props: CourseInputModalProps) => {
  const {coursesService} = useApiContext();

  const isEditing = props.initialCourse?.id !== undefined;
  const [newCourse, setNewCourse] = useState<CreateCourseDto>(
      props.initialCourse ?? {
        name: '',
        open: true,
      },
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (): boolean => {
    setErrors({});

    let isValid = true;
    if (newCourse.name.trim().length < 1) {
      setErrors((current: any) => ({...current, name: true}));
      isValid = false;
    }

    return isValid;
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    if (isEditing && props.initialCourse) {
      await coursesService.updateCourse({
        ...props.initialCourse,
        ...newCourse,
      });
    } else {
      await coursesService.createCourse(newCourse);
    }

    props.onComplete?.();
  };

  useEffect(() => {
    validate();
  }, [newCourse]);

  return <Modal
    title={isEditing ? __('Edit Class') : __('Add Class')}
    className={'wide'}
    bodyClassName={'course-edit'}
    onCloseRequested={props.onCloseRequested}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        form={'courseForm'}
        type={'submit'}
      />
    }

    body={
      <form id={'courseForm'} onSubmit={submit}>
        <div className={'row-fluid'} style={{marginBottom: 10}}>
          <span className={'open-btn'}>
            <SwitchInput
              on={newCourse.open}
              onChange={(nowChecked) => setNewCourse((current) => ({
                ...current,
                open: nowChecked,
              }))}
            />
            Open Registration
          </span>
          {newCourse.open && <>
            <span>
              <span className={'open-from-date'}>
                {__('Open From')}
                {' '}
                <DateInput
                  placeholder={__('From Date')}
                  value={newCourse.openFrom}
                  onChange={(newDate) => setNewCourse((current) => ({
                    ...current,
                    openFrom: newDate,
                  }))}
                />
              </span>
              {' '}
              <span className={'open-to-date'}>
                {__('To')}
                {' '}
                <DateInput
                  placeholder={__('To Date')}
                  value={newCourse.openTo}
                  onChange={(newDate) => setNewCourse((current) => ({
                    ...current,
                    openTo: newDate,
                  }))}
                />
              </span>
            </span>
          </>}
        </div>

        <div>
          <CategoryPicker
            placeholder={'Category'}
            selectedCategories={newCourse.category ? [newCourse.category] : []}
            onChange={(newCategories) => setNewCourse((current) => ({
              ...current,
              category: newCategories.length > 0 ? newCategories[0] : undefined,
            }))}
          />
        </div>

        <label className={'row-fluid'}>
          <input
            type={'text'}
            placeholder={__('Name')}
            aria-label={__('Name')}
            className={classNames('span12', {'invalid': errors.name})}
            value={newCourse.name}
            onChange={(e) => setNewCourse((current) => ({
              ...current,
              name: e.target.value,
            }))}
          />
          <span style={{display: 'none'}}>{__('Name')}</span>
        </label>

        <label className={'row-fluid'}>
          <input
            className={'span12'}
            type={'text'}
            placeholder={__('Keywords')}
            aria-label={__('Keywords')}
            value={newCourse.keywords}
            onChange={(e) => setNewCourse((current) => ({
              ...current,
              keywords: e.target.value,
            }))}
          />
          <span style={{display: 'none'}}>{__('Keywords')}</span>
        </label>

        <label className={'row-fluid'}>
          <RichTextInput
            placeholder={__('Description')}
            value={newCourse.description}
            onChange={(newValue) => setNewCourse((current) => ({
              ...current,
              description: newValue,
            }))}
          />
          <span style={{display: 'none'}}>{__('Description')}</span>
        </label>
      </form>
    }

    footer={<InputPlaceholderMirror />}
  />;
};
