import React, {ReactElement, useEffect} from 'react';
import {LoginView} from 'views/LoginView';
import {useAuthContext} from 'contexts/AuthContext';
import {AppRouter} from 'routing/AppRouter';
import {MainFooter} from 'layout/MainFooter';
import {useHistory, useLocation} from 'react-router-dom';
import {AppRoute} from 'routing/AppRoute';
import {ForgotPasswordView} from 'views/ForgotPasswordView';
import {RegisterView} from 'views/RegisterView';

/**
 * The main app component
 *
 * This component DOES depend on context set up in the AppBootstrapper component,
 * so the AppBootstrapper should be the root element instead of this App component.
 */

// the third-party UserVoice service is included in an external script in the index.html.
// It needs to be declared here to use it in React.
declare global {
  interface Window {
    UserVoice: any,
  }
}

export interface AppProps {
  loginView?: ReactElement,
  appRouter?: ReactElement,
}

export const App = (props: AppProps) => {
  const {isAuthenticated} = useAuthContext();
  const {push} = useHistory();
  const {pathname} = useLocation();

  useEffect(() => {
    window.UserVoice?.push(['set', {
      target: 'self',
      position: 'automatic',
      accent_color: '#458dd6',
      forum_id: '245453',
      ticket_custom_fields: {'Product': 'CIF', 'Type': 'Support Request'},
      contact_title: 'Send us a message',
      contact_enabled: true,
      post_idea_enabled: true,
      screenshot_enabled: true,
      smartvote_enabled: true,
    }]);

    window.UserVoice?.push(['addTrigger', {
      trigger_style: 'tab',
      trigger_position: 'bottom-right',
      trigger_color: 'white',
      trigger_background_color: '#458dd6',
      trigger_prevent_default_enabled: true,
      mode: 'contact',
      menu_enabled: true,
      tab_label: 'Feedback & Support',
    }]);
  }, []);

  return (
    <>
      {isAuthenticated &&
        (props.appRouter ?? <AppRouter/>)
      }
      {pathname === AppRoute.ForgotPassword &&
        <ForgotPasswordView/>
      }
      {pathname.includes('register') &&
        <RegisterView/>
      }
      {!isAuthenticated && (pathname !== AppRoute.ForgotPassword) && (!pathname.includes('register')) &&
        (props.loginView ??
          <LoginView onSuccessfulLogin={() => push(AppRoute.Home)} />
        )
      }

      <MainFooter/>
    </>
  );
};
