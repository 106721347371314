export enum JournalEntryType {
  Step = 'STEP',
  Post = 'POST',
  Goal = 'GOAL',
  Course = 'COURSE',
  SessionReport = 'SESSION_REPORT',
  Assessment = 'ASSESSMENT',
  Badge = 'BADGE',
  Resource = 'RESOURCE',
  Skill = 'SKILL',
}
