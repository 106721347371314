import React, {useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {User} from 'api/users/User';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {InstructorQuickView} from 'views/instructors/InstructorQuickView';
import {DataGrid} from 'components/DataGrid';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {UserRole} from 'api/users/UserRole';

export interface UsersModalProps {
  roleId: number;
  selectedUserIds?: number[];
  onCloseRequested?: () => any;
  onComplete?: (userIds: number[]) => any;
}

export const UsersModal = (props: UsersModalProps) => {
  const logger = useLogger(UsersModal.name);
  const {usersService, authService} = useApiContext();
  const usersPerPage = 20;

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [role, setRole] = useState<number | undefined>(props.roleId > 0 ? props.roleId : undefined);
  const [search, setSearch] = useState<string>('');
  const [roles, setRoles] = useState<UserRole[]>([]);

  const resetUsers = async () => {
    if (page > 1) {
      setUsers([]);
      setPage(1);
    } else {
      await loadUsers();
    }
  };

  const loadUsers = async () => {
    setIsLoadingUsers(true);
    if (props.roleId === 3) {
      const fetchedUsers = await usersService.fetchInstructors({
        start: (page - 1) * usersPerPage,
        end: ((page - 1) * usersPerPage) + usersPerPage,
        search,
      });
      setUsers(fetchedUsers);
      logger.debug('Fetched users', fetchedUsers);
    } else {
      const fetchedUsers = await usersService.fetchUsers({
        start: (page - 1) * usersPerPage,
        end: ((page - 1) * usersPerPage) + usersPerPage,
        search,
        filters: {
          role: role,
        },
      });
      setUsers((current) => current.concat(fetchedUsers));
      logger.debug('Fetched users', fetchedUsers);
    }
    setIsLoadingUsers(false);
  };

  const loadRoles = async () => {
    const fetchedRoles = await authService.fetchUserRoles();
    setRoles(fetchedRoles);
    logger.debug('Fetched roles', fetchedRoles);
  };

  useEffect(() => {
    (async () => {
      await resetUsers();
    })();
  }, [search, role]);

  useEffect(() => {
    (async () => {
      await loadUsers();
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      if (props.roleId === -1) {
        await loadRoles();
      }
    })();
  }, []);

  return <Modal
    title={__(props.roleId === 3 ? 'Instructors' : 'Users')}
    onCloseRequested={props.onCloseRequested}
    className={'wide tall'}

    buttons={
      <Button
        variant={ButtonVariant.Confirm}
        onClick={() => props.onComplete?.(selectedUserIds)}
      />
    }

    body={
      <>
        <ContextMenu>
          <div className="pull-left infoBubble">
            <i className="icon icon-user" /> <span className="badge ng-binding">{users.length}</span>
          </div>
          <SearchInput
            value={search}
            onChange={(newValue) => setSearch(newValue)}
          />
          {props.roleId === -1 &&
            <>
              <i className="icon icon-users" />
              <select
                value={role}
                onChange={(e) => {
                  const numVal = parseInt(e.target.value);
                  if (isNaN(numVal)) {
                    setRole(undefined);
                  } else {
                    setRole(numVal);
                  }
                }}
              >
                <option value={undefined}>{__('- Role -')}</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </>
          }
        </ContextMenu>

        {props.roleId === 3 &&
          users.map((user) => (
            <InstructorQuickView
              instructor={user}
              key={user.id}
              showInvite={true}
              onInvite={() => setSelectedUserIds((current) => [...current, user.id])}
              isInvited={props.selectedUserIds?.includes(user.id) || selectedUserIds.includes(user.id)}
            />
          ))
        }
        {props.roleId !== 3 &&
          <DataGrid
            header={
              <tr>
                <td>{__('Name')}</td>
                <td>{__('Role')}</td>
                <td width={35}/>
              </tr>
            }
            body={
              <>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.roles[0].name}</td>
                    <td>
                      <div className={'icons btn-group'}>
                        <Button
                          variant={ButtonVariant.Confirm}
                          iconCode={
                            (props.selectedUserIds?.includes(user.id) || selectedUserIds.includes(user.id)) ?
                              'icon-ok' :
                              'icon-plus'
                          }
                          tooltip={
                            (props.selectedUserIds?.includes(user.id) || selectedUserIds.includes(user.id)) ?
                              'already selected' :
                              'select'
                          }
                          onClick={() => {
                            if (props.selectedUserIds?.includes(user.id) || selectedUserIds.includes(user.id)) {
                              return;
                            } else {
                              setSelectedUserIds((current) => [...current, user.id]);
                            }
                          }}
                          disabled={props.selectedUserIds?.includes(user.id) || selectedUserIds.includes(user.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}

                {isLoadingUsers && <LoadingIndicator/>}
              </>
            }
            onBottomReached={() => setPage((current) => current + 1)}
          />
        }
      </>
    }

    footer={<div/>}
  />;
};
