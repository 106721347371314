import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {useLogger} from 'logging/logging';
import {QuizSolution} from 'api/quizzes/QuizSolution';
import {useApiContext} from 'api/ApiContext';
import {LoadingModal} from 'components/LoadingModal';
import {FormattedDate} from 'components/FormattedDate';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import classNames from 'classnames';
import {PrintModal} from 'components/PrintModal';
import {QuizSolutionPrintView} from 'views/quizzes/QuizSolutionPrintView';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {AccessControlled} from 'access-control/AccessControlled';
import {AppFeature} from 'access-control/AppFeature';
import {GradeResourceModal} from 'views/resources/GradeResourceModal';

export interface QuizSolutionDetailsModalProps {
  solutionId: number,
  onCloseRequested?: () => any,
  onGraded?: () => any,
}

export const QuizSolutionDetailsModal = (props: QuizSolutionDetailsModalProps) => {
  const logger = useLogger(QuizSolutionDetailsModal.name);
  const {quizzesService} = useApiContext();

  const [solution, setSolution] = useState<QuizSolution | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [showGrade, setShowGrade] = useState<boolean>(false);

  const fetchSolution = async () => {
    setIsFetching(true);
    const fetchedSolution = await quizzesService.fetchQuizSolution(props.solutionId);
    setSolution(fetchedSolution);
    logger.debug('Fetched quiz solution', fetchedSolution);
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      await fetchSolution();
    })();
  }, []);

  if (isFetching) {
    return <LoadingModal onCloseRequested={props.onCloseRequested} />;
  }

  if (!solution) {
    return <Modal
      onCloseRequested={props.onCloseRequested}
      body={<p>Failed to load quiz solution.</p>}
    />;
  }

  return <Modal
    title={__('Quiz Solution')}
    onCloseRequested={props.onCloseRequested}
    bodyClassName={'quiz-view'}

    buttons={
      <Fragment>
        <Button variant={ButtonVariant.Print} onClick={() => setShowPrint(true)} />
        {solution.gradable &&
          <AccessControlled feature={AppFeature.StudentGrade}>
            <Button variant={ButtonVariant.Confirm} onClick={() => setShowGrade(true)} iconCode={'icon-check'} />
          </AccessControlled>
        }
      </Fragment>
    }

    body={
      <div className="quiz solution">
        {showPrint &&
          <PrintModal
            view={<QuizSolutionPrintView solution={solution} />}
            onCloseRequested={() => setShowPrint(false)}
          />
        }

        {showGrade &&
          <GradeResourceModal
            resource={solution}
            onCloseRequested={() => setShowGrade(false)}
            onComplete={() => {
              setShowGrade(false);
              props.onGraded?.();
            }}
          />
        }

        <h1 className="underline">{solution.name}</h1>

        <div className="meta">
          {solution.gradable && !solution.graded &&
            <div className="answer correct" style={{padding: '5px 15px'}}>
              <h4>{__('Resource Submitted')}</h4>
            </div>
          }

          <span>{__('Participant')}</span>: {solution.author.name}
          <span className="comma-separated">{__('Taken')}</span>:
          {' '}
          <FormattedDate timestamp={solution.cdate} format={'MM/DD/YYYY HH:mm'}/>

          {solution.gradable && solution.graded &&
            <span>
              <span className="comma-separated">{__('Required')}</span>: {solution.required}%
              <span className="comma-separated">{__('Score')}</span>: {solution.score}%
              {solution.passed && <span className="label label-success">{__('Complete')}</span>}
              {!solution.passed && <span className="label label-important">{__('Incomplete')}</span>}
            </span>
          }
        </div>

        {solution.questions.map((question, questionIndex) => (
          <div className="question" key={question.id}>
            <div className="heading clearfix html" dangerouslySetInnerHTML={{__html: question.text}} />
            <div className="clearfix" />

            {[QuizQuestionType.Bullets, QuizQuestionType.Multiple].includes(question.type) &&
              <div className="answers">
                {question.answers.map((answer) => (
                  <div
                    key={answer.id}
                    className={classNames(
                        'answer',
                        {'correct': answer.marked && answer.correct},
                        {'incorrect': question.type === QuizQuestionType.Bullets ?
                            answer.marked && !answer.correct :
                            (answer.marked && !answer.correct) || (!answer.marked && answer.correct),
                        },
                    )}
                  >
                    <div className={classNames('checkbox', {'checked': answer.marked})}>
                      <i className={classNames(
                          {'icon-check': answer.marked},
                          {'icon-check-empty': !answer.marked},
                      )}/>
                    </div>
                    <div className="text html" dangerouslySetInnerHTML={{__html: answer.text}} />
                  </div>
                ))}
              </div>
            }

            {question.type === QuizQuestionType.Order &&
              <div className="answers no-checkbox">
                <table>
                  {question.answers.map((answer) => (
                    <tr
                      key={answer.id}
                      className={classNames(
                          'answer order',
                          {'correct': answer.order === answer.correctOrder},
                          {'incorrect': answer.order !== answer.correctOrder},
                      )}
                    >
                      <td className="letter">{questionIndex +1}.</td>
                      <td className="text html" dangerouslySetInnerHTML={{__html: answer.text}} />
                    </tr>
                  ))}
                </table>
              </div>
            }

            {question.type === QuizQuestionType.Open &&
              <div className="answers no-checkbox">
                <div className="answer">
                  <div className="text" dangerouslySetInnerHTML={{__html: question.answer ?? ''}} />
                </div>
              </div>
            }

            {question.type === QuizQuestionType.Match &&
              <div className={'no-checkbox'}>
                {question.answers.map((answer) => (
                  <div
                    key={answer.id}
                    className={classNames(
                        'answer',
                        {'correct': answer.order === answer.correctOrder},
                        {'incorrect': answer.order !== answer.correctOrder},
                    )}
                  >
                    <div className="row-fluid">
                      <div className="span6">
                        <div className="text html clearfix" dangerouslySetInnerHTML={{__html: answer.text}} />
                      </div>
                      <div className="span6">
                        <div className="text html clearfix" dangerouslySetInnerHTML={{__html: answer.match}} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        ))}
      </div>
    }
  />;
};
