import {AxiosInstance} from 'axios';
import {CollageLibraryItem} from 'api/collages/CollageLibraryItem';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {ListFilter} from 'api/lists/ListFilter';
import {EntityListService} from 'api/lists/EntityListService';

/**
 * Provides API functions for collages
 */
export class CollagesService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   * @param {EntityListService} entityListService
   */
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly entityListService: EntityListService,
  ) {}

  /**
   * Fetches collage library items
   *
   * @param {ListFilter} filter
   */
  async fetchLibraryItems(filter: ListFilter): Promise<CollageLibraryItem[]> {
    const response = await this.httpClient.post(
        '/collage/library/list',
        this.entityListService.buildFilter(filter),
    );
    return response.data.list;
  }

  /**
   * Deletes a collage library item
   */
  async deleteLibraryItem(id: number): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/collage/library/remove', {id});
    return response.data;
  }
}
