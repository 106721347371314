import React from 'react';
import {__} from 'i18n/localize';
import classNames from 'classnames';
import {SkillGoal} from 'api/skills/SkillGoal';
import {Goal} from 'api/goals/Goal';

export interface ParticipantStatsPrintViewProps {
  userName: string,
  pictureSrc: string,
  goals: {
    active: Goal[],
    complete: Goal[],
  },
  earnedSkills: SkillGoal[],
}

export const ParticipantStatsPrintView = (props: ParticipantStatsPrintViewProps) => {
  return (
    <div className="pane">
      <div className="body plan-overview">
        <div className="whiteBox clearfix" style={{marginBottom: 15}}>
          <div className="stat span2" style={{width: 140, float: 'left'}}>
            <img
              src={props.pictureSrc}
              className="circle-50 gray"
              alt={''}
              style={{width: 100, height: 100, margin: '11px 0 26px'}}
            />
            <div className="section">{props.userName}</div>
          </div>
          <div className="stat span3" style={{width: 240, float: 'left'}}>
            <div className="buigNum">{props.goals.active.length}</div>
            <div className="section">{__('Active Goals')}</div>
          </div>
        </div>
        <div className="row-fluid clearfix" style={{marginBottom: 15}}>
          {props.earnedSkills.length > 0 &&
            <div className="pack whiteBox" style={{textAlign: 'left'}}>
              <div className="name">{__('Skills Earned in My Full Life')}</div>
              <div className="body clearfix">
                {props.earnedSkills.map((skill) => (
                  <div className="completedSkills" key={skill.id}>
                    <div
                      className="small circle"
                      style={{
                        backgroundColor: skill.ilri.category.color,
                        cursor: 'default',
                      }}
                    >
                      <span className={classNames('icon', skill.ilri.category.icon)} />
                    </div>
                    <div className="name">
                      {skill.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          <div className="pack">
            <div className="name">{__('Goals')}</div>
            <div className="body clearfix">
              <div className="badgeSection">
                <div className="title">{__('Completed')}</div>
                {props.goals.complete.map((goal) => (
                  <div className="completedSkills" key={goal.id}>
                    <div className="name">
                      {goal.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="badgeSection">
                <div className="title">{__('In Progress')}</div>
                {props.goals.active.map((goal) => (
                  <div className="completedSkills" key={goal.id}>
                    <div className="name">
                      {goal.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
