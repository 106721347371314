import React, {PropsWithChildren, ReactElement} from 'react';
import {makeStyles, Tooltip as MaterialUiTooltip} from '@material-ui/core';

export interface TooltipProps {
  title: string | ReactElement,
  delay?: number,
  placement?: 'top' | 'right' | 'bottom' | 'left',
  style?: {arrow?: React.CSSProperties, body?: React.CSSProperties},
}

const useTooltipStyles = makeStyles((theme) => ({
  arrow: (props: TooltipProps) => ({
    color: theme.palette.common.black,
    ...props.style?.arrow,
  }),
  tooltip: (props: TooltipProps) => ({
    backgroundColor: theme.palette.common.black,
    borderRadius: '5px 18px',
    fontSize: 11,
    padding: '8px 8px',
    ...props.style?.body,
  }),
}));


export const Tooltip = (props: PropsWithChildren<TooltipProps>) => {
  const tooltipStyles = useTooltipStyles(props);

  return (
    <MaterialUiTooltip
      title={props.title}
      classes={tooltipStyles}
      placement={props.placement ?? 'bottom'}
      enterDelay={props.delay ?? 400}
      enterNextDelay={props.delay ?? 400}
      arrow
    >
      {props.children as ReactElement}
    </MaterialUiTooltip>
  );
};
