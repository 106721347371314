/**
 * The routes used in the frontend app
 */
export enum AppRoute {
  Home = '/',
  Login = '/login',
  ForgotPassword = '/login/forgottenPassword',
  Register = '/register/:instanceKey',

  SystemContext = '/system',
  DashboardContext = '/dashboard',
  SchoolContext = '/school/:schoolId',
  ProfileContext = '/profile/:profileId',

  SystemOverview = '/system',
  Campus = '/schools',

  Goals = '/goals',
  Dreams = '/dreams',
  Community = '/community',
  Journal = '/journal',
  NotificationsSettings = '/notifications',
  PlanOverview = '/plan/overview',
  PlanHistory = '/plan/history',

  Stats = '/stats',
  SchoolCourses = '/courses',
  TeacherCourses = '/teaching',
  Instructors = '/instructors',
  Users = '/users',
  ContentEditor = '/editor',
  LessonEditor = '/lessonedit',
  Categories = '/categories',
  Versions = '/versions',
  Lessons = '/lessons',
  Skills = '/ilri',
  LearningChecks = '/quizzes',
  Surveys = '/surveys',
  Achievements = '/achievements',
  ActivityLog = '/activity',
  Products = '/products',
  Configure = '/configure',
  Syllabi = '/syllabi',
  CollageLibrary = '/collage_library',
  Students = '/students/teaching',
}
