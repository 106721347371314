import {AxiosInstance} from 'axios';
import {ApiRoute} from 'api/ApiRoute';
import {AuthResult} from 'api/auth/AuthResult';
import {LogoutResult} from 'api/auth/LogoutResult';
import {UserRole} from 'api/users/UserRole';
import {SuccessResponse} from 'api/util/SuccessResponse';
import {RegisterUserDto} from 'api/auth/RegisterUserDto';

/**
 * Provides API auth functions
 */
export class AuthService {
  /**
   * Constructor
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(private readonly httpClient: AxiosInstance) {}

  /**
   * Checks if the client has a current authenticated session with the API
   */
  async isAuthenticated(): Promise<boolean> {
    const response = await this.httpClient.get(ApiRoute.SessionDetails);
    return response.data.identity?.authenticated === true;
  }

  /**
   * Authenticates a user with the API
   *
   * @param {string} username
   * @param {string} password
   */
  async authenticateUser(
      username: string,
      password: string,
  ): Promise<AuthResult> {
    const authenticateResponse = await this.httpClient.post<AuthResult>(
        ApiRoute.AuthenticateUser,
        {login: username, password},
    );
    return authenticateResponse.data;
  }

  /**
   * Logs out the current user session
   */
  async logoutUser(): Promise<LogoutResult> {
    const response = await this.httpClient.post<LogoutResult>(ApiRoute.LogOut);
    return response.data;
  }

  /**
   * Registers a new user with the API
   *
   * @param {RegisterUserDto} user
   */
  async registerUser(user: RegisterUserDto): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/auth/register', user);
    return response.data;
  }

  /**
   * Fetches user roles configured in the system
   */
  async fetchUserRoles(): Promise<UserRole[]> {
    const response = await this.httpClient.get(ApiRoute.UserRolesList);
    return response.data.list;
  }

  /**
   * Sends a forgotten password request to the API
   *
   * @param {string} email
   */
  async sendForgottenPasswordRequest(
      email: string,
  ): Promise<SuccessResponse<undefined>> {
    const response = await this.httpClient.post('/auth/sendPassword', {email});
    return response.data;
  }
}
