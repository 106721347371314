import React, {useEffect, useState} from 'react';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {PageView} from 'layout/PageView';
import {ContextMenu} from 'components/ContextMenu';
import {SearchInput} from 'components/SearchInput';
import {ButtonAdd} from 'components/buttons/ButtonAdd';
import {Version} from 'api/versions/Version';
import {AccessControlled} from 'access-control/AccessControlled';
import {__} from 'i18n/localize';
import {confirmDelete} from 'components/confirmDelete';
import {VersionInputModal} from 'views/versions/VersionInputModal';
import {Tooltip} from 'components/Tooltip';

export const VersionListView = () => {
  const logger = useLogger(VersionListView.name);
  const {versionsService} = useApiContext();
  const [versions, setVersions] = useState<Version[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [editVersion, setEditVersion] =
    useState<Version | undefined>(undefined);
  const [createParent, setCreateParent] =
    useState<Version | undefined>(undefined);

  const fetchVersions = async () => {
    const fetchedVersions = await versionsService.fetchVersions(searchFilter);
    setVersions(fetchedVersions);
    logger.debug('Fetched versions', fetchedVersions);
  };

  useEffect(() => {
    (async () => {
      await fetchVersions();
    })();
  }, [searchFilter]);

  return (
    <PageView>
      {createParent &&
        <VersionInputModal
          parentId={createParent.id}
          onCancel={() => setCreateParent(undefined)}
          onComplete={async () => {
            setEditVersion(undefined);
            await fetchVersions();
          }}
        />
      }

      {editVersion &&
        <VersionInputModal
          versionId={editVersion.id}
          onCancel={() => setEditVersion(undefined)}
          onComplete={async () => {
            setEditVersion(undefined);
            await fetchVersions();
          }}
        />
      }

      <ContextMenu>
        <SearchInput
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
        />
        <ButtonAdd
          onClick={() => setEditVersion({} as Version)}
        />
      </ContextMenu>


      <div className={'grid'}>
        <table className={'list'}>
          <thead>
            <tr>
              <td>Name</td>
              <td width={35}/>
            </tr>
          </thead>
          <tbody>
            {versions.map((version) => (
              <tr key={version.id}>
                <td>
                  <div
                    className="cell"
                    style={{width: 50 * (version.level - 1)}}
                  />
                  <div className="cell">
                    <div
                      className="circle-30"
                      style={{backgroundColor: version.color}}
                    />
                  </div>
                  <div className="cell">{version.name}</div>
                </td>
                <td>
                  <AccessControlled canEdit={version}>
                    <div className="icons btn-group">
                      <Tooltip title={__('Edit')}>
                        <a className="btn btn-icon btn-mini" onClick={() => setEditVersion(version)}>
                          <i className="icon-edit"/>
                        </a>
                      </Tooltip>
                      <Tooltip title={__('Add Child')}>
                        <a onClick={() => setCreateParent(version)} className="btn btn-icon btn-mini">
                          <i className="icon-plus-circled"/>
                        </a>
                      </Tooltip>
                      <Tooltip title={__('Delete')}>
                        <a
                          className="btn btn-icon btn-mini"
                          onClick={() => confirmDelete(async () => {
                            await versionsService.deleteVersion(version.id);
                            await fetchVersions();
                          })}
                        >
                          <i className="icon-trash"/>
                        </a>
                      </Tooltip>
                    </div>
                  </AccessControlled>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageView>
  );
};
