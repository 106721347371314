import React, {useEffect, useState} from 'react';
import {useUserSwitchingContext} from 'contexts/UserSwitchingContext';
import {Redirect, Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import {GoalsDashboardView} from 'views/goals/GoalsDashboardView';
import {AppRoute} from 'routing/AppRoute';
import {PlanOverviewView} from 'views/plan/PlanOverviewView';
import {ParticipantStatsView} from 'views/plan/ParticipantStatsView';
import {LoadingIndicator} from 'components/LoadingIndicator';
import {JournalView} from 'views/journal/JournalView';
import {PageNotFoundView} from 'views/errors/PageNotFoundView';
import {DreamsDashboardView} from 'views/dreams/DreamsDashboardView';
import {CommunityView} from 'views/community/CommunityView';
import {useLogger} from 'logging/logging';

/**
 * Handles user-switched (i.e. "viewing another user's profile") state,
 * setting up context and handling sub-routing.
 */

export const UserSwitchedRouter = () => {
  const logger = useLogger(UserSwitchedRouter.name);
  const {profileId} = useParams<{profileId: string}>();
  const {path, url} = useRouteMatch();
  const {setActiveUser} = useUserSwitchingContext();

  const [isSettingActiveUser, setIsSettingActiveUser] = useState(true);
  const [hasErrorSettingUser, setHasErrorSettingUser] = useState(false);

  useEffect(() => {
    (async () => {
      setHasErrorSettingUser(false);
      if (profileId) {
        try {
          setIsSettingActiveUser(true);
          const intProfileId = parseInt(profileId);
          if (!isNaN(intProfileId)) {
            await setActiveUser(intProfileId);
          } else {
            logger.warn('Invalid non-numeric profile ID provided');
            setHasErrorSettingUser(true);
          }
        } catch (e) {
          logger.error('Failed to set user-switched state', e);
          setHasErrorSettingUser(true);
        } finally {
          setIsSettingActiveUser(false);
        }
      }
    })();
  }, [profileId]);

  if (isSettingActiveUser) {
    return (
      <LoadingIndicator
        fullscreen={true}
        message={'Loading User'}
      />
    );
  }

  if (hasErrorSettingUser) {
    return <div style={{margin: 50}}>
      <h4>Failed to load user profile.</h4>
    </div>;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={url + AppRoute.Dreams} />
      </Route>

      <Route
        exact
        path={path + AppRoute.Dreams}
        component={DreamsDashboardView}
      />
      <Route
        exact
        path={path + AppRoute.PlanOverview}
        component={PlanOverviewView}
      />
      <Route
        exact
        path={path + AppRoute.PlanHistory}
        component={ParticipantStatsView}
      />
      <Route
        exact
        path={path + AppRoute.Goals}
        component={GoalsDashboardView}
      />
      <Route
        exact
        path={path + AppRoute.Journal}
        component={JournalView}
      />
      <Route
        exact
        path={path + AppRoute.Community}
        component={CommunityView}
      />

      <Route path={'*'}>
        <PageNotFoundView/>
      </Route>
    </Switch>
  );
};
