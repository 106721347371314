import React from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';

/**
 * A modal to play a video explaining how to work on goals
 */
export interface WorkingOnGoalsModalProps {
  onCloseRequested?: () => any,
}

export const WorkingOnGoalsModal = (props: WorkingOnGoalsModalProps) => {
  return (
    <Modal
      title={__('Working on Your Goals')}
      onCloseRequested={props.onCloseRequested}
      body={
        <iframe style={{width: 540, height: 300, margin: '0 auto'}}
          src="https://player.vimeo.com/video/425021387?title=0&byline=0&portrait=0&autoplay=1" width="540"
          height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
      }
    />
  );
};
