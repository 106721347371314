import React from 'react';
import {Achievement} from 'api/achievements/Achievement';
import {Config} from 'Config';
import {Tooltip} from 'components/Tooltip';

export interface AchievementThumbnailProps {
  achievement: Achievement,
  onOpenRequested?: () => any,
  showProgress?: boolean,
}

export const AchievementThumbnail = (props: AchievementThumbnailProps) => {
  const {achievement} = props;

  return (
    <Tooltip
      title={props.achievement.name}
      style={{
        arrow: {color: 'rgba(0, 0, 0, 0.7)'},
        body: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#ccc',
          opacity: 1,
          borderRadius: 5,
          fontSize: 14,
        },
      }}
    >
      <div className="achievement" onClick={props.onOpenRequested} aria-label="View Achievement">
        <img src={achievement.image?.url ? achievement.image.url + '100/100' : Config.defaultSchoolImage} alt={''} />

        {props.showProgress &&
        <div className="progress" aria-label="Progress" >
          <div className="bar" aria-label={(achievement.progress ?? 0) + '%'}
            style={{width: (achievement.progress ?? 0) + '%'}} />
        </div>
        }
      </div>
    </Tooltip>
  );
};
