import React, {useState} from 'react';
import {Modal} from 'components/Modal';
import {__} from 'i18n/localize';
import {Button, ButtonVariant} from 'components/buttons/Button';
import {useApiContext} from 'api/ApiContext';
import {useLogger} from 'logging/logging';
import {SliderInput} from 'components/forms/SliderInput';
import {LoadingIndicator} from 'components/LoadingIndicator';

export interface GradeResourceModalProps {
  resource: any,
  onCloseRequested?: () => any,
  onComplete?: () => any,
}

export const GradeResourceModal = (props: GradeResourceModalProps) => {
  const logger = useLogger(GradeResourceModal.name);
  const {resourcesService} = useApiContext();

  const [comment, setComment] = useState<string>('');
  const [grade, setGrade] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submit = async () => {
    setIsSubmitting(true);
    const result = await resourcesService.gradeResource(props.resource, grade, comment);
    logger.debug('Graded resource', result);
    setIsSubmitting(false);
    props.onComplete?.();
  };

  return (
    <Modal
      title={__('Grade Resource')}
      onCloseRequested={props.onCloseRequested}
      buttons={
        <Button
          variant={ButtonVariant.Confirm}
          onClick={submit}
        />
      }
      body={
        <form name="form.edit" className="text">
          {isSubmitting && <LoadingIndicator fullscreen={true} message={__('Saving Content')} />}

          <div className="row-fluid">
            <textarea
              placeholder={__('Comment')}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="span12 rows5"
            />
          </div>
          <div className="row-fluid">
            <span>{__('Grade')}</span>
            <div className="difficulty-slider">
              <span className="slider-label-left label">0</span>
              <span className="slider-label-right label">100</span>
              <div className="slider">
                <br/>
                <SliderInput
                  value={grade}
                  onChange={(newValue) => setGrade(newValue)}
                  step={1}
                  min={0}
                  max={100}
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  );
};
