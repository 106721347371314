import React from 'react';
import {QuizSolution} from 'api/quizzes/QuizSolution';
import {__} from 'i18n/localize';
import {FormattedDate} from 'components/FormattedDate';
import {QuizQuestionType} from 'api/quizzes/QuizQuestionType.enum';
import classNames from 'classnames';

export interface QuizSolutionPrintViewProps {
  solution: QuizSolution,
}

export const QuizSolutionPrintView = (props: QuizSolutionPrintViewProps) => {
  return (
    <div className="quiz solution">
      <h1 className="underline">{props.solution.name}</h1>

      <div className="meta">
        <span>{__('Client')}</span>: {props.solution.author.name}
        <span className="comma-separated">{__('Taken')}</span>: <FormattedDate timestamp={props.solution.cdate}/>

        {props.solution.gradable && props.solution.graded &&
          <span>
            <span className="comma-separated">{__('Required')}</span>: {props.solution.required}%
            <span className="comma-separated">{__('Score')}</span>: {props.solution.score}%
            {props.solution.passed && <span className="label label-success">{__('Complete')}</span>}
            {!props.solution.passed && <span className="label label-important">{__('Incomplete')}</span>}
          </span>
        }
      </div>

      {props.solution.questions.map((question, questionIndex) => (
        <div className="question" key={question.id}>
          <div className="heading clearfix html" dangerouslySetInnerHTML={{__html: question.text}} />
          <div className="clearfix" />

          {[QuizQuestionType.Bullets, QuizQuestionType.Multiple].includes(question.type) &&
          <div className="answers">
            {question.answers.map((answer) => (
              <div
                key={answer.id}
                className={classNames(
                    'answer',
                    {'correct': answer.marked === answer.correct},
                    {'incorrect': answer.marked !== answer.correct},
                )}
              >
                <div className={classNames('checkbox', {'checked': answer.marked})}>
                  <i className={classNames(
                      {'icon-check': answer.marked},
                      {'icon-check-empty': !answer.marked},
                  )}/>
                </div>
                <div className="text html" dangerouslySetInnerHTML={{__html: answer.text}} />
              </div>
            ))}
          </div>
          }

          {question.type === QuizQuestionType.Order &&
          <div className="answers no-checkbox">
            <table>
              {question.answers.map((answer) => (
                <tr
                  key={answer.id}
                  className={classNames(
                      'answer order',
                      // {'correct': answer.order === answer.correct},
                      // {'incorrect': answer.order !== answer.correct},
                  )}
                >
                  <td className="letter">{questionIndex +1}.</td>
                  <td className="text html" dangerouslySetInnerHTML={{__html: answer.text}} />
                </tr>
              ))}
            </table>
          </div>
          }

          {question.type === QuizQuestionType.Open &&
          <div className="answers no-checkbox">
            <div className="answer">
              <div className="text" dangerouslySetInnerHTML={{__html: question.answer ?? ''}} />
            </div>
          </div>
          }

        </div>
      ))}
    </div>
  );
};
