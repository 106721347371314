import React from 'react';
import {Slider} from '@material-ui/core';

export interface SliderInputProps {
  value?: number,
  onChange?: (newValue: number) => any,
  min?: number,
  max?: number,
  step?: number

}

export const SliderInput = (props: SliderInputProps) => {
  return (
    <Slider
      value={props.value}
      min={props.min ?? 0}
      max={props.max ?? 100}
      step={props.step ?? 1}
      onChange={(_, newValue) => props.onChange?.(newValue as number)}
      valueLabelDisplay={'on'}
    />
  );
};
