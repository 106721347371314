/**
 * Static config settings for the app
 */
export const Config = {
  appName: 'My Full Life',
  appLogoUrl: '/images/logo.jpg',
  appCopyright: 'Center for Independent Futures. All rights reserved',
  defaultUserImage: '/images/anonymous.png',
  defaultSchoolImage: '/images/logo.jpg',

  defaultSavingMessage: 'Saving Content',
  defaultLoadingMessage: 'Loading Content',

  disableModalClickAway: true,
};
