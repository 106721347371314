/**
 * Possible goal statuses in the API
 */
export enum GoalStatus {
  InProgress = 'IN_PROGRESS',
  Recommended = 'RECOMMENDED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Completed = 'COMPLETED',
  NonApplicable = 'NON_APPLICABLE',
  Maintenance = 'MAINTENANCE',
}
